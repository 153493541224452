import { ErrorInfo } from '../../interfaces/error-info.interface';
import BookingRequestsMapper from '../../mappers/booking-requests.mapper';
import { BookingRequestUiModel } from '../../ui-models/booking-request.ui-model';
import api from '../utils/api/api.service';
import errorHelper from '../utils/api/error-helper.service';
import { ApiResponse } from 'apisauce';
import { ResponseModel } from '../../interfaces/response-model.interface';

export function getBookingRequestDetails(
  requestId: string,
  moduleName?: string
): Promise<BookingRequestUiModel | ErrorInfo> {
  return api
    .getBookingRequestDetails(requestId)
    .then((response: ApiResponse<ResponseModel<BookingRequestModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return BookingRequestsMapper.mapToUI(data.data);
        } else {
          return errorHelper(
            0,
            '',
            moduleName || 'BOOKING_MODULE',
            (api.getBookingRequestDetails as any).displayName
          );
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          moduleName || 'BOOKING_MODULE',
          (api.getBookingRequestDetails as any).displayName
        );
      }
    });
}
