import React, { useEffect, useState } from 'react';
import styles from './landlord-info.module.scss';
import { PUBLIC_PATH } from '../../../../../shared/config/routes-config';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import { trackEvent } from '../../../../../shared/services/utils/tracking/tracking.service';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { ConfirmModal } from '../../components/confirm-modal/confirm-modal.component';
import EdiText from 'react-editext';

export interface LandlordInfoProps {
  data: {
    landlordName: string;
    landlordId: string;
    landlordEmail: string;
    landlordPhone: string;
    propertyId: string;
    landlordShare: number;
    payouts: string[];
  };
  eventHandlers: {
    addLandLord: (payoutstoUpdate: string[]) => void;
    updateLandlordShare: (share: number) => void;
    deleteLandLord: () => void;
  };
}
export const LandLordInfo: React.FC<LandlordInfoProps> = ({ data, eventHandlers }) => {
  const isMobileView = useWindowResizer();
  const [disabledActions, setDisabledActions] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [confirmModalMessage, setConfirmModalMessage] = useState<string>('');

  return (
    <div className={styles['landlord-info-container']}>
      {data.landlordId ? (
        <>
          <div className={styles['landlord-info-container-title']}>Landlord Info</div>
          <div className={styles['landlord-info-container-content']}>
            {/* <div className={styles['landlord-info-container-content-info']}>
              <label>id :</label>
              <p> {data.landlordId}</p>
            </div> */}
            <div className={styles['landlord-info-container-content-info']}>
              <label>Name :</label> <p>{data.landlordName}</p>
            </div>
            <div className={styles['landlord-info-container-content-info']}>
              <label>Email :</label> <p>{data.landlordEmail}</p>
            </div>
            <div className={styles['landlord-info-container-content-info']}>
              <label>Phone :</label> <p>{data.landlordPhone}</p>
            </div>
            <div className={styles['landlord-info-container-content-info']} />
            <div className={styles['landlord-info-container-content-info']}>
              <label>Homeowner Share :</label>{' '}
              <EdiText
                className={styles['landlord-info-container-content__edit-text']}
                type="text"
                onEditingStart={(value) => {
                  setDisabledActions(true);
                }}
                onCancel={() => {
                  setDisabledActions(false);
                }}
                value={data.landlordShare + '%'}
                onSave={(val: string) => {
                  setDisabledActions(false);
                  eventHandlers.updateLandlordShare(Number(val.replace('%', '')));
                }}
              />
            </div>
          </div>

          <div className={styles['landlord-info-container-actions']}>
            <Button
              onClick={() => {
                if (data.payouts && data.payouts.length > 0) {
                  setShowConfirmModal(true);
                  setConfirmModalMessage(
                    'Do you want to update payouts assigned to this Homeowner also?'
                  );
                } else {
                  eventHandlers.addLandLord(false);
                }
              }}
              tracking={{
                action: 'cancel',
                category: '',
                label: ''
              }}
              size={isMobileView ? ButtonSize.full : ButtonSize.medium}
              disabled={data.propertyId === '' || disabledActions}
            >
              {' '}
              Update Homeowner
            </Button>

            <Button
              onClick={() => {
                setShowDeleteModal(true);
              }}
              tracking={{
                action: 'open',
                category: 'blocked-date',
                label: 'add-blocked-date-modal'
              }}
              type={ButtonType.primaryInverted}
              size={ButtonSize.medium}
              disabled={disabledActions}
            >
              Delete Homeowner
            </Button>
          </div>
        </>
      ) : (
        <div>
          <img
            src={`${PUBLIC_PATH}/assets/jpeg/add-guest.jpeg`}
            alt="assign Homeowner"
            className={styles['landlord-info-container-content-image']}
          />

          <Button
            onClick={() => {
              eventHandlers.addLandLord(false);
              trackEvent('add', {
                category: '',
                label: ''
              });
            }}
            tracking={{
              action: 'cancel',
              category: '',
              label: ''
            }}
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
            disabled={data.propertyId === '' || disabledActions}
          >
            {' '}
            Assign Homeowner
          </Button>
          {data.propertyId === '' && (
            <label className={styles['landlord-info-container-content--hint']}>
              you can't assign a Homeowner to a unit that doesn't have property
            </label>
          )}
        </div>
      )}

      <ConfirmModal
        config={{ showModal: showConfirmModal, message: confirmModalMessage }}
        eventHandlers={{
          denyAction: () => {
            setShowConfirmModal(false);
            eventHandlers.addLandLord(false);
          },
          confirmAction: () => {
            setShowConfirmModal(false);
            eventHandlers.addLandLord(true);
            trackEvent('add', {
              category: '',
              label: ''
            });
          }
        }}
      />

      <ConfirmModal
        config={{
          showModal: showDeleteModal,
          message: 'Are you sure you want to delete this Homeowner?'
        }}
        eventHandlers={{
          denyAction: () => {
            setShowDeleteModal(false);
          },
          confirmAction: () => {
            setShowDeleteModal(false);
            eventHandlers.deleteLandLord();
          }
        }}
      />
    </div>
  );
};
