import { AmenityModel } from '../models/amenity.model';
import { AmenityUiModel } from '../ui-models/amenity.ui-model';

function mapToUI(amenityData: AmenityModel): AmenityUiModel {
  return {
    id: amenityData.id || '',
    name: amenityData.name,
    image: amenityData.image,
    createdAt: amenityData.createdAt,
    createdById: amenityData.createdById,
    createdByUsername: amenityData.createdByUsername,
    modifiedAt: amenityData.modifiedAt,
    modifiedById: amenityData.modifiedById,
    modifiedByUsername: amenityData.modifiedByUsername,
    cAmenityId: amenityData.cAmenityId,
    cAmenityRoomId: amenityData.cAmenityRoomId
  };
}

function mapToModel(amenityData: AmenityUiModel): AmenityModel {
  return {
    id: amenityData.id,
    name: amenityData.name,
    image: amenityData.image,
    createdAt: amenityData.createdAt,
    createdById: amenityData.createdById,
    createdByUsername: amenityData.createdByUsername,
    modifiedAt: amenityData.modifiedAt,
    modifiedById: amenityData.modifiedById,
    modifiedByUsername: amenityData.modifiedByUsername,
    cAmenityId: amenityData.cAmenityId,
    cAmenityRoomId: amenityData.cAmenityRoomId
  };
}

function mapToUIList(amenitiesList: AmenityModel[]): AmenityUiModel[] {
  return amenitiesList.map(mapToUI);
}

function mapToModelList(amenitiesList: AmenityUiModel[]): AmenityModel[] {
  return amenitiesList.map(mapToModel);
}

export default {
  mapToUI,
  mapToModel,
  mapToUIList,
  mapToModelList
};
