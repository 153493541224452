import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../shared/redux/reducers';
import _ from 'lodash';
import { InputText } from '../../shared/components/core-ui/input-text/input-text.component';
import styles from './configuration.module.scss';
import {
  getConfigurationRequest,
  updateConfigurationRequest
} from '../shared/services/data.service';
import { ConfigurationModel } from '../../shared/models/configuration.model';
import { ErrorInfo, isErrorInfo } from '../../shared/interfaces/error-info.interface';
import Button, {
  ButtonSize,
  ButtonType
} from '../../shared/components/core-ui/button/button.component';
import { handleInputChange } from 'react-select/src/utils';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export type ConfigurationContainerProps = ReturnType<typeof mapStateToProps>;

export const ConfigurationContainer: React.FC<ConfigurationContainerProps> = ({
  isAuthenticated
}) => {
  const [configData, setConfigData]: any[] = useState([]);
  const [newData, setNewData]: any[] = useState([]);
  const [updatedConfig, setUpdatedConfig]: any[] = useState([]);
  useEffect(() => {
    getConfigurationData();
  }, []);

  useEffect(() => {
    const sections: string[] = [];
    const newData: any[] = [];

    sections?.push(configData[0]?.category);
    configData.find((item: any) => {
      if (!sections?.includes(item?.category)) {
        sections.push(item?.category);
      }
    });

    sections?.map((section: any, index) => {
      newData[index] = [];
      configData?.map((item: any) => {
        if (item?.category === section) {
          newData[index].push(item);
        } else return;
      });
    });
    setNewData(newData);
  }, [configData]);

  const getConfigurationData = () => {
    getConfigurationRequest().then((response: ConfigurationModel[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setConfigData(response);
      }
    });
  };
  const updateConfigurationData = () => {
    console.log(updatedConfig);

    updateConfigurationRequest(updatedConfig).then((response: ConfigurationModel[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        getConfigurationData();
      }
    });
  };
  const handleOnChange = (key: string, value: string) => {
    const existingProduct =
      updatedConfig &&
      updatedConfig?.find((product: { key: string; value: string }) => product.key === key);

    if (updatedConfig.indexOf(existingProduct) === -1) {
      setUpdatedConfig([...updatedConfig, { key, value }]);
    } else {
      updatedConfig[updatedConfig.indexOf(existingProduct)] = {
        key: existingProduct.key,
        value
      };
    }
  };
  return (
    <div className={styles['config_section']}>
      {newData?.map((section: any[], index: number) => (
        <>
          <div>{section[0]?.category}</div>
          <div className={styles['config_section-container']}>
            {section?.map((item: any, index: number) => (
              <>
                <div className={styles['config_section-container--group']}>
                  <div className={styles['config_section-container--group_label']}>
                    {item?.label}
                  </div>
                  {item?.type !== 'html' ? (
                    <div className={styles['config_section-container--group_field']}>
                      <InputText
                        data={{
                          defaultValue: item?.value
                        }}
                        config={{ type: item?.type }}
                        eventHandlers={{
                          onChangeHandler: (value) => handleOnChange(item?.key, value)
                        }}
                      />
                    </div>
                  ) : (
                    <div className={styles['config_section-container--group_field']}>
                      <CKEditor
                        editor={ClassicEditor}
                        data={item?.value}
                        onReady={(editor: any) => {
                          // You can store the "editor" and use when it is needed.
                          console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event: any, editor: any) => {
                          const newData: string = editor.getData();
                          handleOnChange(item?.key, newData);
                        }}
                      />
                    </div>
                  )}
                </div>
              </>
            ))}
          </div>
        </>
      ))}
      <Button type={ButtonType.default} size={ButtonSize.medium} onClick={updateConfigurationData}>
        Update
      </Button>
    </div>
  );
};

export default connect(mapStateToProps)(ConfigurationContainer);

function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}
