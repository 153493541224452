import { ConfigurationModel } from '../models/configuration.model';
import { ConfigurationUiModel } from '../ui-models/configuration.ui.model';

function mapToUI(configurationData: ConfigurationModel): ConfigurationUiModel {
  return {
    key: configurationData.key,
    label: configurationData.label,
    category: configurationData.category,
    type: configurationData.type,
    value: configurationData.value
  };
}
function mapToUIList(configurationDataList: ConfigurationModel[]): ConfigurationUiModel[] {
  return configurationDataList.map(mapToUI);
}

export default {
  mapToUI,
  mapToUIList
};
