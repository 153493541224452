import React from 'react';
import styles from './slider.module.scss';
import Swiper from 'react-id-swiper';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
/**
 * SliderProps interface
 */
export interface SliderProps {
  data: {
    sliderItems: { imgSrc: string; id: string }[];
  };
  eventHandlers: {
    deleteHandler: (id: string) => void;
  };
}

/**
 * functional component Slider
 * @param data holding component data
 */
export const Slider: React.FC<SliderProps> = ({ data, eventHandlers }) => {
  const sliderParams = {
    slidesPerView: 5,
    spaceBetween: 30,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    breakpoints: {
      320: {
        slidesPerView: 5,
        spaceBetween: 15
      },
      576: {
        slidesPerView: 5,
        spaceBetween: 15
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 30
      }
    }
  };
  return (
    <>
      <div className={styles['slider']}>
        <Swiper {...sliderParams}>
          {data.sliderItems.map((item) => (
            <div className={styles['slider__item']}>
              <div
                className={styles['slider__item__delete-wrapper']}
                onClick={() => {
                  eventHandlers.deleteHandler(item.id);
                }}
              >
                <HighlightOffIcon enableBackground={'white'} fontSize={'inherit'} color={'error'} />
              </div>
              <img src={item.imgSrc} alt={'showroom'} className={styles['slider__item__image']} />
            </div>
          ))}
        </Swiper>
      </div>
    </>
  );
};
