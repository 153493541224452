import React, { useState } from 'react';
import styles from './add-guest.module.scss';
import Button, {
  ButtonSize
} from '../../../../../shared/components/core-ui/button/button.component';
import {
  InputText,
  InputTextErrorMode,
  InputTextType
} from '../../../../../shared/components/core-ui/input-text/input-text.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import PhoneInput from 'react-phone-input-2';
import { UserUIModel } from '../../../../../shared/ui-models/user.ui-model';
import { ErrorInfo, isErrorInfo } from '../../../../../shared/interfaces/error-info.interface';
import { store } from 'react-notifications-component';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { getModuleName } from '../../../../shared/services/reservation.service';
import { getUserByPhone } from '../../../../../shared/services/data/user.data';
export interface AddGuestProps {
  eventHandlers: {
    closeHandler: () => void;
    addGuestHandler: (guestData: {
      name: string;
      phoneNumber: string;
      email: string;
      gender: string;
      id: string;
    }) => void;
  };
}

/**
 * functional component AddGuest
 * @param {AddGuestProps}
 */
export const AddGuest: React.FC<AddGuestProps> = ({ eventHandlers }) => {
  const [showGuestData, setShowGuestData] = useState<boolean>(false);
  const [newGuestName, setNewGuestName] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });

  const [newGuestEmail, setNewGuestEmail] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [newGuestPhone, setNewGuestPhone] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [gender, setGender] = useState<string>('male');
  const [user, setUser] = useState<UserUIModel>();

  const [searchNumber, setSearchNumber] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [showSearchResult, setShowSearchResult] = useState<boolean>(false);
  const [assignUser, setAssignUser] = useState<boolean>(false);
  const [userExist, setUserExist] = useState<boolean>(false);
  const isMobileView = useWindowResizer();
  const { t } = useTranslation();

  const handleNewGuestSubmit = () => {
    const newGuest: {
      name: string;
      phoneNumber: string;
      email: string;
      gender: string;
      id: string;
    } = {
      name: newGuestName.value,
      email: newGuestEmail.value,
      phoneNumber: newGuestPhone.value,
      id: user?.id || '',
      gender
    };
    console.log(newGuest);
    eventHandlers.addGuestHandler(newGuest);
  };
  const handleGetUserByPhoneNumber = (phoneNumber: string) => {
    getUserByPhone(phoneNumber, getModuleName()).then((response: UserUIModel | ErrorInfo) => {
      setShowSearchResult(true);
      setAssignUser(false);
      if (!isErrorInfo(response)) {
        setUser(response);
        setNewGuestEmail({ value: response?.email || '', isValid: true });
        setNewGuestName({ value: response?.name || '', isValid: true });
        setNewGuestPhone({ value: response?.phone || '', isValid: true });
        setUserExist(true);
      } else {
        setAssignUser(true);
        setNewGuestPhone({ value: searchNumber.value || '', isValid: true });
        store.addNotification({
          id: 'get-user-failed',
          title: t(
            'UNIT_TYPE_MODULE.LIST_CONTAINER.ASSIGN_LANDLORD_MODAL.NOTIFICATIONS.SEARCH_FAILED.TITLE'
          ),
          message: t(
            'UNIT_TYPE_MODULE.LIST_CONTAINER.ASSIGN_LANDLORD_MODAL.NOTIFICATIONS.SEARCH_FAILED.MESSAGE'
          ),
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
      }
    });
  };
  return (
    <>
      {!showGuestData && (
        <div className={styles['add-guest__search']}>
          <div className={styles['add-guest__description']}>
            search by phone number
            <div className={styles['add-guest__search--input']}>
              <PhoneInput
                country={'eg'}
                inputProps={{
                  name: 'search by phone number',
                  required: true
                }}
                value={searchNumber.value}
                onChange={(phone) => {
                  phone
                    ? setSearchNumber({ value: `+${phone}`, isValid: true })
                    : setSearchNumber({ value: `${phone}`, isValid: false });
                }}
              />
              <Button
                onClick={() => {
                  handleGetUserByPhoneNumber(searchNumber.value);
                }}
                tracking={{
                  action: 'search',
                  category: 'guest',
                  label: 'add-guest'
                }}
                size={isMobileView ? ButtonSize.full : ButtonSize.medium}
              >
                <SearchIcon
                  fontSize={'large'}
                  color="inherit"
                  className={styles['add-guest__search-action']}
                />
              </Button>
            </div>
          </div>

          {showSearchResult && (
            <>
              {!assignUser ? (
                <div className={styles['user-info-container-content']}>
                  <div className={styles['user-info-container-content-info']}>
                    <label>id :</label>
                    <p> {user?.id}</p>
                  </div>
                  <div className={styles['user-info-container-content-info']}>
                    <label>name :</label> <p>{user?.name}</p>
                  </div>
                  <div className={styles['user-info-container-content-info']}>
                    <label>email :</label> <p>{user?.email}</p>
                  </div>
                  <div className={styles['user-info-container-content-info']}>
                    <label>phone :</label> <p>{user?.phone}</p>
                  </div>
                </div>
              ) : (
                <div className={styles['user-info-container-content-info--hint']}>
                  this user doesn't exist please confirm and create new user to complete the
                  reservation
                </div>
              )}
              <Button
                onClick={() => setShowGuestData(true)}
                tracking={{
                  action: 'submit',
                  category: 'guest',
                  label: 'add-guest'
                }}
                size={isMobileView ? ButtonSize.full : ButtonSize.medium}
              >
                confirm
              </Button>
            </>
          )}
        </div>
      )}
      {showGuestData && (
        <div className={styles['add-guest']}>
          <div className={styles['add-guest__title']}>Add guest</div>
          <div className={styles['add-guest__description']}>
            Full Name
            <InputText
              data={{
                value: newGuestName.value,
                errorMessage: 'Full name is required',
                placeholder: 'Full Name'
              }}
              config={{
                maxRows: 1,
                autoFocus: true,
                required: true,
                showErrorMode: InputTextErrorMode.instant
              }}
              eventHandlers={{
                onChangeHandler: (value: string, isValid: boolean) => {
                  setNewGuestName({ value, isValid });
                }
              }}
            />
          </div>

          <div className={styles['add-guest__description']}>
            Email
            <InputText
              data={{
                value: newGuestEmail.value,
                errorMessage: 'Email is required',
                placeholder: 'Email'
              }}
              config={{
                maxRows: 1,
                required: true,
                showErrorMode: InputTextErrorMode.instant
              }}
              eventHandlers={{
                onChangeHandler: (value: string, isValid: boolean) => {
                  setNewGuestEmail({ value, isValid });
                }
              }}
            />
          </div>
          <div className={styles['add-guest__description']}>
            Phone Number
            <PhoneInput
              country={'eg'}
              inputProps={{
                name: 'phone number',
                required: true
              }}
              value={newGuestPhone.value}
              onChange={(phone) => {
                phone
                  ? setNewGuestPhone({ value: `+${phone}`, isValid: true })
                  : setNewGuestPhone({ value: `${phone}`, isValid: false });
              }}
              disabled={userExist}
            />
          </div>
          <div className={styles['add-guest__gender-container']}>
            <div className={styles['add-guest__gender-container__label']}>Gender</div>
            <div className={styles['add-guest__gender-container__choices']}>
              <div className={styles['add-guest__gender-container__choices__item']}>
                <span
                  className={`
                      ${styles['add-guest__gender-container__choices__item__label']}
                      ${
                        gender === 'male'
                          ? styles['add-guest__gender-container__choices__item__label--active']
                          : ''
                      }
                      `}
                  onClick={() => setGender('male')}
                >
                  Male
                </span>
              </div>
              <div className={styles['add-guest__gender-container__choices__item']}>
                <span
                  className={`
                      ${styles['add-guest__gender-container__choices__item__label']}
                      ${
                        gender === 'female'
                          ? styles['add-guest__gender-container__choices__item__label--active']
                          : ''
                      }
                      `}
                  onClick={() => setGender('female')}
                >
                  Female
                </span>
              </div>
            </div>
          </div>
          <div className={styles['add-guest--actions']}>
            <Button
              onClick={() => {
                eventHandlers.closeHandler();
              }}
              tracking={{
                action: 'close',
                category: 'guests',
                label: 'add-guest'
              }}
              size={isMobileView ? ButtonSize.full : ButtonSize.medium}
            >
              Cancel
            </Button>
            <Button
              disabled={!newGuestEmail.isValid || !newGuestPhone.isValid || !newGuestName.isValid}
              onClick={handleNewGuestSubmit}
              tracking={{
                action: 'submit',
                category: 'guest',
                label: 'add-guest'
              }}
              size={isMobileView ? ButtonSize.full : ButtonSize.medium}
            >
              proceed
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
