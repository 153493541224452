import React, { useState } from 'react';
import styles from './comments-thread.module.scss';
import { format } from 'timeago.js';
import { PUBLIC_PATH } from '../../../../../shared/config/routes-config';
import Button, {
  ButtonSize
} from '../../../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { TextareaAutosize } from '@material-ui/core';

export interface CommentsThreadProps {
  data: {
    commentsList: {
      id: string;
      email: string;
      name: string;
      message: string;
      createdAt: Date;
    }[];
  };
  eventHandlers: {
    handleCommentSubmit: () => void;
  };
}

/**
 * functional component CommentsThreadProps
 * @param {CommentsThreadProps}
 */
export const CommentsThread: React.FC<CommentsThreadProps> = ({ eventHandlers, data }) => {
  const [newComment, setNewComment] = useState<string>('');
  const isMobileView = useWindowResizer();
  console.log(data.commentsList);
  return (
    <div className={styles['comments-thread']}>
      <div className={styles['comments-thread__group']}>
        {data.commentsList && data.commentsList.length > 0 ? (
          data.commentsList.map((comment) => (
            <div className={styles['comments-thread__content']}>
              <img
                className={styles['comments-thread__image']}
                src={`${PUBLIC_PATH}/assets/pngs/male-avatar.png`}
              />
              <div className={styles['comments-thread__avatar']}>
                <div className={styles['comments-thread__avatar__name']} as={comment.name}>
                  {comment.name}
                </div>
                <div className={styles['comments-thread__time-ago']}>
                  <div>{format(comment.createdAt)}</div>
                </div>
                <div className={styles['comments-thread__message']}>{comment.message}</div>
                {/* <Comment.Actions>
            <Comment.Action>Reply</Comment.Action>
          </Comment.Actions> */}
              </div>
            </div>
          ))
        ) : (
          <div className={styles['comments-thread__no-records']}>
            <img src={`${PUBLIC_PATH}/assets/svgs/no-data.svg`} alt="no-data" /> No previous
            messages
          </div>
        )}

        <TextareaAutosize
          maxRows={7}
          rows={2}
          value={newComment}
          placeholder="Write something"
          onChange={(event) => {
            setNewComment(event.target.value);
          }}
        />
        <br />
        <Button
          onClick={() => {
            eventHandlers.handleCommentSubmit(newComment);
            setNewComment('');
          }}
          size={!isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};
