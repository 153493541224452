import React from 'react';

// MaterialUI imports
import { makeStyles } from '@material-ui/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { isMobile } from 'react-device-detect';
import styles from './gender-picker.module.scss';
import { PropertyGender } from '../../../enums/property-gender.enum';
import { PUBLIC_PATH } from '../../../config/routes-config';
import { isMobileView, isTabletView } from '../../../services/helpers/ui-helper/ui-helper.service';

/**
 * interface GenderPickerProps
 */
interface GenderPickerProps {
  eventHandlers: {
    itemSelectedHandler: (gender: PropertyGender) => void;
  };
  config?: {
    hideMixed: boolean;
  };
  data: {
    selectedItemValue: PropertyGender | undefined;
  };
}

/**
 * GenderPicker component renders single or multi options toggle
 * @param {GenderPickerProps} - holding data, config and eventHandlers props
 */

const useStyles = makeStyles({
  toggleGroup: {
    width: '100%',
    flexFlow: isMobileView() || isTabletView() ? 'wrap' : 'unset'
  },
  toggleButton: {
    backgroundColor: '#f1f4f5',
    borderRadius: '0.5rem',
    fontFamily: 'Arial Regular, Arial, sans-serif',
    color: '#37454d',
    textAlign: 'left',
    justifyContent: 'flex-start',
    lineHeight: 'normal',
    width: '100%',
    // height: 50,
    margin: '1rem',
    textTransform: 'capitalize',

    '&:first-child': {
      marginLeft: '0'
    },
    '&:last-child': {
      marginRight: isMobileView() ? '1rem' : '0'
    }
  },
  label: {
    fontSize: isMobile ? '1.4rem' : '1.6rem',
    fontFamily: 'Nunito',
    color: '#37454d',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '4rem',
      marginRight: '1rem'
    }
  },
  selected: {
    border: `1px solid #f38831`,
    position: 'relative',
    backgroundColor: '#f38831 !important',
    fontWeight: 700
  }
});

export const GenderPickerComponent: React.FC<GenderPickerProps> = ({
  config,
  data,
  eventHandlers
}) => {
  const classes = useStyles();
  return (
    <ToggleButtonGroup
      className={classes.toggleGroup}
      onChange={(event: React.MouseEvent<HTMLElement, MouseEvent>, qType: PropertyGender) => {
        eventHandlers.itemSelectedHandler(qType);
      }}
      size="medium"
      value={data.selectedItemValue}
      exclusive
    >
      <ToggleButton
        value={PropertyGender.MALE}
        className={`${classes.label} ${classes.toggleButton} ${
          PropertyGender.MALE === data.selectedItemValue ? classes.selected : ''
        }`}
      >
        <img src={`${PUBLIC_PATH}/assets/pngs/male-avatar.png`} alt="icon" />
        <div className={styles['gender-picker__text-wrapper']}>
          <span>Male</span>
        </div>
      </ToggleButton>
      <ToggleButton
        value={PropertyGender.FEMALE}
        className={`${classes.label} ${classes.toggleButton} ${
          PropertyGender.FEMALE === data.selectedItemValue ? classes.selected : ''
        }`}
      >
        <img src={`${PUBLIC_PATH}/assets/pngs/female-avatar.png`} alt="icon" />
        <div className={styles['gender-picker__text-wrapper']}>
          <span>Female</span>
        </div>
      </ToggleButton>
      {(!config || !config.hideMixed) && (
        <ToggleButton
          value={PropertyGender.MIXED}
          className={`${classes.label} ${classes.toggleButton} ${
            PropertyGender.MIXED === data.selectedItemValue ? classes.selected : ''
          }`}
        >
          <img src={`${PUBLIC_PATH}/assets/pngs/mixed-avatar.png`} alt="icon" />
          <div className={styles['gender-picker__text-wrapper']}>
            <span>Mixed</span>
          </div>
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  );
};
