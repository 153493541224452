import React from 'react';
import styles from './payment-details.module.scss';

import { UnitTypeUiModel } from '../../../../../shared/ui-models/unit-type.ui-model';
import { BookOfferResponseModel } from '../../../../../shared/ui-models/book.ui-model';
import { FeeType } from '../../../../../shared/enums/amount-type.enum';
import NumberFormat from 'react-number-format';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { CURRENCY } from '../../../../../shared/enums/currency.enum';

export interface PaymentDetailsProps {
  data: {
    unitType: UnitTypeUiModel;
    bookOffer: BookOfferResponseModel;
    checkInDate: Date;
    checkOutDate: Date;
    guestDetails?: {
      name: string;
      phoneNumber: string;
      email: string;
    };
  };
}

/**
 * functional component  PaymentDetails
 * @param { PaymentDetailsProps}
 */
export const PaymentDetails: React.FC<PaymentDetailsProps> = ({ data }) => {
  return (
    <div className={styles['payment-details']}>
      <div className={styles['payment-details__dates']}>
        <div>
          <div className={styles['payment-details__dates__label']}>Check in date</div>
          <div className={styles['payment-details__dates__value']}>
            {new Date(data.checkInDate).toDateString()}
          </div>
        </div>
        <ArrowForward color="inherit" fontSize="large" />
        <div>
          <div className={styles['payment-details__dates__label']}>Check out date</div>
          <div className={styles['payment-details__dates__value']}>
            {new Date(data.checkOutDate).toDateString()}
          </div>
        </div>
      </div>
      {data.bookOffer && data.bookOffer.oneTimePayment && (
        <div className={styles['payment-details__prices']}>
          <div className={styles['payment-details__prices__header']}>
            {data.bookOffer.recurringPayment && data.bookOffer.recurringPayment.length > 0
              ? 'One Time Payment'
              : 'Payments'}
          </div>
          {data.bookOffer.oneTimePayment.map((item) => (
            <div className={styles['payment-details__prices__item']}>
              <div className={styles['payment-details__prices__item__name']}>
                {item.feeName === 'Unit Price' && item.originalPrice && item.duration
                  ? item.originalPrice / item.duration
                  : item.feeName}{' '}
                {item.feeType === FeeType.PERCENTAGE
                  ? `(${item.percentValue}%)`
                  : item.duration
                  ? item.mode === 'daily'
                    ? ` x ${item.duration} night(s)`
                    : item.mode === 'monthly'
                    ? ` x ${item.duration} month(s)`
                    : data.unitType?.reservationMode === 'daily'
                    ? ` x ${item.duration} night(s)`
                    : ` x ${item.duration} month(s)`
                  : ''}
              </div>
              {item.feeType !== FeeType.PERCENTAGE &&
                item.originalPrice &&
                item.originalPrice !== item.feeValue && (
                  <div className={styles['payment-details__prices__item__discount']}>
                    <NumberFormat
                      value={item.originalPrice}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={
                        item.currency
                          ? CURRENCY[item.currency] + ' '
                          : data.unitType?.currency
                          ? CURRENCY[data.unitType?.currency] + ' '
                          : 'EGP '
                      }
                    />
                  </div>
                )}
              <div className={styles['payment-details__prices__item__total']}>
                <NumberFormat
                  value={item.feeValue}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={
                    item.currency
                      ? CURRENCY[item.currency] + ' '
                      : data.unitType.currency
                      ? CURRENCY[data.unitType.currency] + ' '
                      : 'EGP '
                  }
                />
              </div>
            </div>
          ))}
          {data.bookOffer.oneTimePayment.length ? (
            <div className={styles['payment-details__prices__item']}>
              <div className={styles['payment-details__prices__item__name']}>
                <b>Total</b>
              </div>
              <div className={styles['payment-details__prices__item__sum']}>
                <NumberFormat
                  value={data.bookOffer.oneTimePayment.reduce(
                    (prev, cur) => prev + Number(cur.feeValue),
                    0
                  )}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={
                    data.bookOffer.oneTimePayment.length > 0 &&
                    data.bookOffer.oneTimePayment[0].currency
                      ? CURRENCY[data.bookOffer.oneTimePayment[0].currency] + ' '
                      : data.unitType?.currency
                      ? CURRENCY[data.unitType?.currency] + ' '
                      : 'EGP '
                  }
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
      {data.bookOffer && data.bookOffer.recurringPayment && (
        <div className={styles['payment-details__prices']}>
          <div className={styles['payment-details__prices__header']}>Monthly Payment</div>
          {data.bookOffer.recurringPayment.map((item) => (
            <div className={styles['payment-details__prices__item']}>
              <div className={styles['payment-details__prices__item__name']}>
                {item.feeName}{' '}
                {item.feeType === FeeType.PERCENTAGE ? `(${item.percentValue}%)` : ''}
                {item.startAtMonth ? `(starting from month ${item.startAtMonth})` : ''}
              </div>
              {item.feeType !== FeeType.PERCENTAGE &&
                item.originalPrice &&
                item.originalPrice !== item.feeValue && (
                  <div className={styles['payment-details__prices__item__discount']}>
                    <NumberFormat
                      value={item.originalPrice}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={
                        item.currency
                          ? CURRENCY[item.currency] + ' '
                          : data.unitType?.currency
                          ? CURRENCY[data.unitType?.currency] + ' '
                          : 'EGP '
                      }
                    />
                  </div>
                )}
              <div className={styles['payment-details__prices__item__total']}>
                <NumberFormat
                  value={item.feeValue}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={
                    item.currency
                      ? CURRENCY[item.currency] + ' '
                      : data.unitType?.currency
                      ? CURRENCY[data.unitType?.currency] + ' '
                      : 'EGP '
                  }
                />
              </div>
            </div>
          ))}
          {data.bookOffer.recurringPayment.length ? (
            <div className={styles['payment-details__prices__item']}>
              <div className={styles['payment-details__prices__item__name']}>
                <b>Total</b>
              </div>
              <div className={styles['payment-details__prices__item__sum']}>
                <NumberFormat
                  value={data.bookOffer.recurringPayment.reduce(
                    (prev, cur) => prev + Number(cur.feeValue),
                    0
                  )}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={
                    data.bookOffer.recurringPayment.length > 0 &&
                    data.bookOffer.recurringPayment[0].currency
                      ? CURRENCY[data.bookOffer.recurringPayment[0].currency] + ' '
                      : data.unitType?.currency
                      ? CURRENCY[data.unitType?.currency] + ' '
                      : 'EGP '
                  }
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
      {data.guestDetails && (
        <div className={styles['payment-details__guest-wrapper']}>
          <div className={styles['payment-details__guest']}>
            <div className={styles['payment-details__guest__label']}>Name</div>
            <div className={styles['payment-details__guest__value']}>{data.guestDetails.name}</div>
          </div>

          <div className={styles['payment-details__guest']}>
            <div className={styles['payment-details__guest__label']}>Phone number</div>
            <div className={styles['payment-details__guest__value']}>
              {data.guestDetails.phoneNumber}
            </div>
          </div>
          <div className={styles['payment-details__guest']}>
            <div className={styles['payment-details__guest__label']}>Email</div>
            <div className={styles['payment-details__guest__value']}>{data.guestDetails.email}</div>
          </div>
          <div className={styles['payment-details__guest']}></div>
        </div>
      )}
    </div>
  );
};
