import React, { useCallback, useState, useEffect } from 'react';
import styles from './calendar.module.scss';
import ReactCalendar from 'react-calendar';
import Dropdown from 'react-dropdown';
import moment from 'moment';
import { CustomPriceModel } from '../../../ui-models/unit-type.ui-model';
import NumberFormat from 'react-number-format';
const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(tomorrow.getDate() + 1);
/**
 * CalendarProps interface
 */
export interface CalendarProps {
  eventHandlers: {
    onChange: (date: Date | Date[]) => void;
  };
  data?: {
    calenderView?: boolean;
    disbaleDate?: Date[];
    addInitValue?: boolean;
    showDateInput?: boolean;
    basePriceTag?: number;
    customPriceTags?: CustomPriceModel[];
    selectedDate?: Date[];
  };
}

/**
 * functional component Calendar
 * @param data holding component data
 */
export const Calendar: React.FC<CalendarProps> = ({ eventHandlers, data }) => {
  const monthOptions = [
    { value: '0', label: 'Jan' },
    { value: '1', label: 'Feb' },
    { value: '2', label: 'Mar' },
    { value: '3', label: 'Apr' },
    { value: '4', label: 'May' },
    { value: '5', label: 'June' },
    { value: '6', label: 'July' },
    { value: '7', label: 'Aug' },
    { value: '8', label: 'Sep' },
    { value: '9', label: 'Oct' },
    { value: '10', label: 'Nov' },
    { value: '11', label: 'Dec' }
  ];
  const yearOptions = (() => {
    const max = new Date().getFullYear() + 9;
    const min = max - 9;
    const years: string[] = [];

    for (let i = max; i >= min; i--) {
      years.push(i.toString());
    }
    return years.reverse();
  })();
  const [value, onChange] = useState(data?.addInitValue ? tomorrow : null);
  const [monthValue, setMonthValue] = useState(monthOptions[tomorrow.getMonth()]);
  const [yearValue, setYearValue] = useState(tomorrow.getFullYear().toFixed());
  const [activeStartDate, setActiveStartDate] = useState(tomorrow);
  const [isActive, setIsActive] = useState(false);
  const [selectedDate, setSelectedDate] = useState([]);

  function onYearOrMonthChange(month?: any, year?: any) {
    month
      ? setActiveStartDate(new Date(Number(year ? year : yearValue), Number(month.value), 1))
      : setActiveStartDate(new Date(Number(year), Number(monthValue.value), 1));
  }
  useEffect(() => {
    if (data?.selectedDate?.length > 0) {
      setSelectedDate(data?.selectedDate);
    } else setSelectedDate([]);
  }, [data]);

  return (
    <div>
      {data && data.calenderView ? (
        <div className={styles['calendar']}>
          <div
            className={`${styles['calendar__calendar-container']} ${styles['calendar__calendar-container--calender-view']}`}
            style={{ position: 'relative', width: '100%' }}
          >
            <div className={styles['calendar__calendar-container__dropdowns-container']}>
              <div className={styles['calendar__calendar-container__dropdowns-container__item']}>
                <Dropdown
                  options={monthOptions}
                  value={monthValue}
                  onChange={(val: any) => {
                    setMonthValue(val);
                    onYearOrMonthChange(val);
                  }}
                  placeholder="Select an option"
                />
              </div>
              <div className={styles['calendar__calendar-container__dropdowns-container__item']}>
                <Dropdown
                  options={yearOptions}
                  value={yearValue}
                  onChange={(val: any) => {
                    setYearValue(val.value);
                    onYearOrMonthChange(null, val.value);
                  }}
                  placeholder="Select an option"
                />
              </div>
            </div>
            <ReactCalendar
              tileDisabled={(date: any) => {
                if (data && Array.isArray(data.disbaleDate)) {
                  return data.disbaleDate
                    .map((date) => {
                      return new Date(date.toDateString()).toString();
                    })
                    .includes(new Date(date.date.toDateString()).toString());
                }
                return false;
              }}
              onChange={(val: any) => {
                if (
                  Array.isArray(val) &&
                  val.length === 2 &&
                  val[0].toDateString().toString() === val[1].toDateString().toString()
                ) {
                  onChange(null as any);
                  eventHandlers.onChange(null as any);
                } else {
                  onChange(val);
                  eventHandlers.onChange(val);
                }
              }}
              selectRange={true}
              next2Label={null}
              prev2Label={null}
              navigationLabel={() => null}
              allowPartialRange={true}
              tileContent={({ activeStartDate, date, view }) => {
                if (
                  view === 'month' &&
                  data &&
                  data.customPriceTags &&
                  data.customPriceTags.length > 0
                ) {
                  const matchedCustomPrice = data.customPriceTags.find(
                    (customPrice) =>
                      date <= new Date(moment(customPrice.to).format('MM-DD-YYYY')) &&
                      date >= new Date(moment(customPrice.from).format('MM-DD-YYYY'))
                  );
                  if (matchedCustomPrice) {
                    return (
                      <p
                        className={`${styles['calendar__calendar-container__price-tag']} ${styles['calendar__calendar-container__price-tag--custom-price']}`}
                      >
                        <NumberFormat
                          // value={
                          //   Math.abs(Number(matchedCustomPrice.price)) > 999
                          //     ? Math.sign(Number(matchedCustomPrice.price)) *
                          //         (Math.abs(Number(matchedCustomPrice.price)) / 1000).toFixed(1) +
                          //       'k'
                          //     : Math.sign(Number(matchedCustomPrice.price)) *
                          //         Math.abs(Number(matchedCustomPrice.price)) +
                          //       ''
                          // }
                          value={matchedCustomPrice.price}
                          thousandSeparator={true}
                          displayType={'text'}
                          prefix={'EGP '}
                        />
                      </p>
                    );
                  } else {
                    if (data.basePriceTag) {
                      return (
                        <p className={styles['calendar__calendar-container__price-tag']}>
                          <NumberFormat
                            value={data.basePriceTag}
                            thousandSeparator={true}
                            // value={
                            //   Math.abs(Number(data.basePriceTag)) > 999
                            //     ? Math.sign(Number(data.basePriceTag)) *
                            //         (Math.abs(Number(data.basePriceTag)) / 1000).toFixed(1) +
                            //       'k'
                            //     : Math.sign(Number(data.basePriceTag)) * Math.abs(Number(data.basePriceTag)) +
                            //       ''
                            // }
                            displayType={'text'}
                            prefix={'EGP '}
                          />
                        </p>
                      );
                    } else {
                      return null;
                    }
                  }
                } else {
                  if (data.basePriceTag) {
                    return (
                      <p className={styles['calendar__calendar-container__price-tag']}>
                        <NumberFormat
                          value={data.basePriceTag}
                          thousandSeparator={true}
                          // value={
                          //   Math.abs(Number(data.basePriceTag)) > 999
                          //     ? Math.sign(Number(data.basePriceTag)) *
                          //         (Math.abs(Number(data.basePriceTag)) / 1000).toFixed(1) +
                          //       'k'
                          //     : Math.sign(Number(data.basePriceTag)) * Math.abs(Number(data.basePriceTag)) +
                          //       ''
                          // }
                          displayType={'text'}
                          prefix={'EGP '}
                        />
                      </p>
                    );
                  } else {
                    return null;
                  }
                }
              }}
              activeStartDate={activeStartDate}
              tileClassName={({ date }) => {
                const reservationDate =
                  Array.isArray(data.disbaleDate) &&
                  data.disbaleDate.find((disabledDate) => {
                    return (
                      date.toDateString().toString() === disabledDate.toDateString().toString()
                    );
                  });
                if (reservationDate) {
                  return styles['calendar__booked'];
                }
                return '';
              }}
              minDate={
                // tslint:disable-next-line: prettier
                ((Array.isArray(value) && value.length === 1) || value) && data?.disbaleDate?.length
                  ? (() => {
                      if (data && Array.isArray(data.disbaleDate)) {
                        const sortDisableDate = data.disbaleDate.sort(
                          (a, b) => b.getTime() - a.getTime()
                        );
                        const firstBiggerDate = sortDisableDate.find(
                          (sortedDate) =>
                            sortedDate.getTime() <
                            (Array.isArray(value) ? value[0]?.getTime() : value?.getTime())
                        );
                        return firstBiggerDate;
                      }
                    })()
                  : undefined
              }
              maxDate={
                // tslint:disable-next-line: prettier
                ((Array.isArray(value) && value.length === 1) || value) && data?.disbaleDate?.length
                  ? (() => {
                      if (data && Array.isArray(data.disbaleDate)) {
                        const sortDisableDate = data.disbaleDate.sort(
                          (a, b) => a.getTime() - b.getTime()
                        );
                        const firstBiggerDate = sortDisableDate.find(
                          (sortedDate) =>
                            sortedDate.getTime() >
                            (Array.isArray(value) ? value[0]?.getTime() : value?.getTime())
                        );
                        return firstBiggerDate;
                      }
                    })()
                  : undefined
              }
              onActiveStartDateChange={({ activeStartDate }) => {
                setYearValue(String(activeStartDate.getFullYear()));
                const findMonth = monthOptions.find((opt) => {
                  return opt.value === String(activeStartDate.getMonth());
                });
                if (findMonth) {
                  setMonthValue(findMonth);
                  onYearOrMonthChange(findMonth, String(activeStartDate.getFullYear()));
                }
              }}
            />
          </div>
        </div>
      ) : (
        <div className={styles['calendar']}>
          <div className={styles['calendar__input']} onClick={() => setIsActive(!isActive)}>
            {/* Dates: <b>From → To</b> */}

            <div className={styles['calendar__input--date-view']}>
              {selectedDate?.length > 0
                ? `${new Date(selectedDate[0]).toDateString()}-${new Date(
                    selectedDate[1]
                  ).toDateString()}`
                : 'Select Date'}
            </div>
          </div>
          {isActive ? (
            <div className={styles['calendar__calendar-container']}>
              <div className={styles['calendar__calendar-container__dropdowns-container']}>
                <div className={styles['calendar__calendar-container__dropdowns-container__item']}>
                  <Dropdown
                    options={monthOptions}
                    value={monthValue}
                    onChange={(val: any) => {
                      setMonthValue(val);
                      onYearOrMonthChange(val);
                    }}
                    placeholder="Select an option"
                  />
                </div>
                <div className={styles['calendar__calendar-container__dropdowns-container__item']}>
                  <Dropdown
                    options={yearOptions}
                    value={yearValue}
                    onChange={(val: any) => {
                      setYearValue(val.value);
                      onYearOrMonthChange(null, val.value);
                    }}
                    placeholder="Select an option"
                  />
                </div>
              </div>
              <ReactCalendar
                tileDisabled={(date: any) => {
                  if (data && data.disbaleDate) {
                    return data.disbaleDate.includes(date);
                  }
                  return false;
                }}
                onChange={(val: any) => {
                  onChange(val);
                  eventHandlers.onChange(val as Date[]);
                  setIsActive(!isActive);
                  setSelectedDate(val);
                }}
                value={value}
                selectRange={true}
                next2Label={null}
                prev2Label={null}
                navigationLabel={() => null}
                activeStartDate={activeStartDate}
                onActiveStartDateChange={({ activeStartDate }) => {
                  setYearValue(String(activeStartDate.getFullYear()));
                  const findMonth = monthOptions.find((opt) => {
                    return opt.value === String(activeStartDate.getMonth());
                  });
                  if (findMonth) {
                    setMonthValue(findMonth);
                    onYearOrMonthChange(findMonth, String(activeStartDate.getFullYear()));
                  }
                }}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};
