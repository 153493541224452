import { ApiResponse } from 'apisauce';
import { getModuleName } from '../../../UnitTypeModule/shared/services/unit-type.service';
import { ErrorInfo } from '../../interfaces/error-info.interface';
import { ResponseModel } from '../../interfaces/response-model.interface';
import UnitTypeMapper from '../../mappers/unit-type.mapper';
import unitTypeRoomsMapper from '../../mappers/unitTypeRooms.mapper';
import { UnitTypeModel, RoomsModel } from '../../models/unit-types.model';
import {
  UnitTypeUiModel,
  UnitTypeUiModelResponse,
  RoomsUiModelResponse
} from '../../ui-models/unit-type.ui-model';
import api from '../utils/api/api.service';
import errorHelper from '../utils/api/error-helper.service';

export function getUnitTypeDetails(
  unitTypeId: string,
  containerName: string
): Promise<UnitTypeUiModel | ErrorInfo> {
  return api
    .getUnitType(unitTypeId)
    .then((response: ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return UnitTypeMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', containerName, (api.getUnitType as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (api.getUnitType as any).displayName
        );
      }
    });
}

export function addNewUnitType(
  unitTypeData: UnitTypeUiModel
): Promise<UnitTypeUiModel | ErrorInfo> {
  const mappedUnitType = UnitTypeMapper.mapToModel(unitTypeData);
  return api
    .createUnitType(mappedUnitType)
    .then((response: ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return UnitTypeMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.createUnitType as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.createUnitType as any).displayName
        );
      }
    });
}

export function getAllUnitTypes(
  containerName: string,
  offset: number,
  limit: number,
  query: {}
): Promise<UnitTypeUiModelResponse | ErrorInfo> {
  return api
    .getUnitTypes(limit.toString(), offset.toString(), query)
    .then((response: ApiResponse<ResponseModel<UnitTypeUiModelResponse>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data?.data) {
          return { unitList: UnitTypeMapper?.mapToUIList(data?.data), count: data?.count || 0 };
        } else {
          return errorHelper(0, '', containerName, (api.getUnitTypes as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response?.status),
          response.data && response?.data?.errorCode
            ? String(response?.data?.errorCode || '')
            : response.problem,
          containerName,
          (api.getUnitTypes as any).displayName
        );
      }
    });
}
export function getUnitTypeRooms(
  containerName: string
): Promise<RoomsUiModelResponse[] | ErrorInfo> {
  return api
    .getAllRooms()
    .then((response: ApiResponse<ResponseModel<RoomsUiModelResponse[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data?.data) {
          return unitTypeRoomsMapper.mapToUIList(data?.data);
        } else {
          return errorHelper(0, '', containerName, (api.getUnitTypes as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response?.status),
          response.data && response?.data?.errorCode
            ? String(response?.data?.errorCode || '')
            : response.problem,
          containerName,
          (api.getUnitTypes as any).displayName
        );
      }
    });
}

export function searchInUnitTypes(
  containerName: string,
  data: {
    landlordId?: string;
    checkInDate?: number;
    checkOutDate?: number;
  }
): Promise<UnitTypeUiModel[] | ErrorInfo> {
  return api
    .searchInUnitTypes(data)
    .then((response: ApiResponse<ResponseModel<UnitTypeModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return UnitTypeMapper.mapToUIList(data.data);
        } else {
          return errorHelper(0, '', containerName, (api.getUnitTypes as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          containerName,
          (api.getUnitTypes as any).displayName
        );
      }
    });
}
