import React from 'react';
import { useOccupancyAndAverageDailyRateData } from './OccupancyAndAverageDailyRateData';
import { OccupancyAndAverageDailyRate } from '../../../shared/models/kpis.model';

export const OccupancyAndAverageDailyRateDataContext = React.createContext<OccupancyAndAverageDailyRate[] | null>(null);

export const OccupancyAndAverageDailyRateDataProvider = function ({ children, monthsCount, payload }) {
  const data = useOccupancyAndAverageDailyRateData(monthsCount, payload);

  return (
    <OccupancyAndAverageDailyRateDataContext.Provider value={data}>
      {children}
    </OccupancyAndAverageDailyRateDataContext.Provider>
  );
};
