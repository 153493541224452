import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './areas-list.module.scss';
import { store } from 'react-notifications-component';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import { Button as MIButton, Menu, MenuItem } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { ErrorInfo, isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { RootState } from '../../../shared/redux/reducers';
import { trackPage, trackEvent } from '../../../shared/services/utils/tracking/tracking.service';
import {
  addNewArea,
  deleteArea,
  updateArea,
  uploadSponsoredImage,
  uploadDefaultImage
} from '../../shared/services/data.service';
import { AreaUiModel } from '../../../shared/ui-models/area.ui-model';
import { AddAreaModal } from './components/add-area-modal/add-area-modal.component';
import { EditAreaModal } from './components/edit-area-modal/edit-area-modal.component';
import { DeleteAreaModal } from './components/delete-area-modal/delete-area-modal.component';
import { getAllAreas } from '../../../shared/services/data/lookups.data';
import { getModuleName } from '../../shared/services/area.service';
import { PUBLIC_PATH } from '../../../shared/config/routes-config';
import useWindowResizer from '../../../shared/hooks/window-resizer/window-resizer.hook';
import { ImageType } from 'react-images-uploading';
import { AreaModel } from '../../../shared/models/area.model';

export type AreasListContainerProps = ReturnType<typeof mapStateToProps>;

/**
 * functional component AreasListContainer
 * @param {AreasListContainerProps} holding question text
 */
export const AreasListContainer: React.FC<AreasListContainerProps> = ({ isAuthenticated }) => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [areasList, setAreasList] = useState<AreaUiModel[]>([]);
  const [areaToUpdate, setAreaToUpdate] = useState<AreaUiModel>();
  const [areaToDelete, setAreaToDelete] = useState<AreaUiModel>();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  useEffect(() => {
    trackPage('areas-list');
    getAllAreas(getModuleName()).then((response: AreaUiModel[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setAreasList(response);
      }
    });
    return () => {
      store.removeNotification('add-area-failed');
      store.removeNotification('add-area-success');
      store.removeNotification('edit-area-failed');
      store.removeNotification('edit-area-success');
      store.removeNotification('delete-area-failed');
      store.removeNotification('delete-area-success');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * function to handle user menu clicked
   * @param event mouse click event
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    trackEvent('actions|open-menu', {
      category: 'areas-list',
      label: 'areas'
    });
  };
  /**
   * function to handle closing user menu
   */
  const handleClose = (event: any) => {
    if (event && event.stopPropagation) event.stopPropagation();
    if (event && event.preventDefault) event.preventDefault();
    setAnchorEl(null);
  };
  const handleNewAreaSubmit = (newAreaName: string, order: number) => {
    store.removeNotification('add-area-failed');
    store.removeNotification('add-area-success');
    addNewArea({ name: newAreaName, order }).then((response: AreaUiModel | ErrorInfo) => {
      if (isErrorInfo(response)) {
        if (response.status === 401 || response.status === 403) {
          store.addNotification({
            id: 'add-area-failed',
            title: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_AREA_PERMISSION.TITLE'),
            message: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_AREA_PERMISSION.MESSAGE'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        } else {
          store.addNotification({
            id: 'add-area-failed',
            title: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_AREA_FAILED.TITLE'),
            message: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_AREA_FAILED.MESSAGE'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        }
      } else {
        store.addNotification({
          title: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_AREA_SUCCESS.TITLE'),
          message: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_AREA_SUCCESS.MESSAGE'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        setAreasList([...areasList, response]);
      }
    });
  };
  const uploadDefaultImageHandler = (areaId: string, image: ImageType) => {
    if (image && image.file) {
      uploadDefaultImage(image.file, areaId).then((response: AreaModel | ErrorInfo) => {
        if (isErrorInfo(response)) {
          if (response.status === 401 || response.status === 403) {
            store.addNotification({
              id: 'edit-area-failed',
              title: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AREA_PERMISSION.TITLE'),
              message: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AREA_PERMISSION.MESSAGE'),
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut']
            });
          } else {
            store.addNotification({
              id: 'edit-area-failed',
              title: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AREA_FAILED.TITLE'),
              message: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AREA_FAILED.MESSAGE'),
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut']
            });
          }
        } else {
          store.addNotification({
            title: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AREA_SUCCESS.TITLE'),
            message: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AREA_SUCCESS.MESSAGE'),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          const updateAreaList = areasList.map((area: AreaUiModel) => {
            if (area.id === response.id) {
              return response;
            } else {
              return area;
            }
          });
          setAreasList(updateAreaList);
        }
      });
    }
  };
  const uploadSponsoredIImageHandler = (areaId: string, image: ImageType) => {
    if (image && image.file) {
      uploadSponsoredImage(image.file, areaId).then((response: AreaModel | ErrorInfo) => {
        if (isErrorInfo(response)) {
          if (response.status === 401 || response.status === 403) {
            store.addNotification({
              id: 'edit-area-failed',
              title: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AREA_PERMISSION.TITLE'),
              message: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AREA_PERMISSION.MESSAGE'),
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut']
            });
          } else {
            store.addNotification({
              id: 'edit-area-failed',
              title: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AREA_FAILED.TITLE'),
              message: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AREA_FAILED.MESSAGE'),
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut']
            });
          }
        } else {
          store.addNotification({
            title: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AREA_SUCCESS.TITLE'),
            message: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AREA_SUCCESS.MESSAGE'),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          const updateAreaList = areasList.map((area: AreaUiModel) => {
            if (area.id === response.id) {
              return response;
            } else {
              return area;
            }
          });
          setAreasList(updateAreaList);
        }
      });
    }
  };
  const handleEditAreaSubmit = (areaId: string, area: AreaUiModel) => {
    store.removeNotification('edit-area-failed');
    store.removeNotification('edit-area-success');
    updateArea(areaId, area).then((response: AreaUiModel | ErrorInfo) => {
      if (isErrorInfo(response)) {
        if (response.status === 401 || response.status === 403) {
          store.addNotification({
            id: 'edit-area-failed',
            title: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AREA_PERMISSION.TITLE'),
            message: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AREA_PERMISSION.MESSAGE'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        } else {
          store.addNotification({
            id: 'edit-area-failed',
            title: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AREA_FAILED.TITLE'),
            message: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AREA_FAILED.MESSAGE'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        }
      } else {
        store.addNotification({
          title: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AREA_SUCCESS.TITLE'),
          message: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AREA_SUCCESS.MESSAGE'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        const updateAreasList = areasList.map((area: AreaUiModel) => {
          if (area.id === response.id) {
            return response;
          } else {
            return area;
          }
        });
        setAreasList(updateAreasList);
      }
    });
  };

  const handleDeleteAreaSubmit = (areaId: string) => {
    store.removeNotification('delete-area-failed');
    store.removeNotification('delete-area-success');
    deleteArea(areaId).then((response: {} | ErrorInfo) => {
      if (isErrorInfo(response)) {
        if (response.status === 401 || response.status === 403) {
          store.addNotification({
            id: 'delete-area-failed',
            title: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_AREA_PERMISSION.TITLE'),
            message: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_AREA_PERMISSION.MESSAGE'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        } else {
          store.addNotification({
            id: 'delete-area-failed',
            title: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_AREA_FAILED.TITLE'),
            message: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_AREA_FAILED.MESSAGE'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        }
      } else {
        store.addNotification({
          title: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_AREA_SUCCESS.TITLE'),
          message: t('AREA_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_AREA_SUCCESS.MESSAGE'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        const updateAreasList = areasList.filter((area: AreaUiModel) => area.id !== areaId);
        setAreasList(updateAreasList);
      }
    });
  };

  return (
    <div className={styles['areas-list-container']}>
      <div className={styles['areas-list-container__title']}>
        {t('AREA_MODULE.LIST_CONTAINER.TITLE')}
      </div>
      <div
        className={styles['areas-list-container__add-new']}
        onClick={() => {
          setShowAddModal(true);
        }}
      >
        <AddCircleIcon fontSize={'inherit'} color={'inherit'} />
      </div>
      <div className={styles['areas-list-container__list-wrapper']}>
        {areasList.map((area) => (
          <div className={styles['areas-list-container__area-item']}>
            <div className={styles['areas-list-container__area-item__image-wrapper']}>
              <img
                className={styles['areas-list-container__area-item__image-wrapper__image']}
                alt="property"
                src={area?.defaultImage || `${PUBLIC_PATH}/assets/pngs/amenity-default.png`}
              />
            </div>
            <div className={styles['areas-list-container__area-item__header']}>
              <div
                className={`${styles['areas-list-container__area-item__header__title']} ${
                  !area.name
                    ? styles['areas-list-container__area-item__header__title--untitled']
                    : ''
                } `}
              >
                {area.name || t('AREA_MODULE.LIST_CONTAINER.UNTITLED_AREA')}
                {/* <p className={styles['areas-list-container__area-item__header__title__created-by']}>
                  {t('AREA_MODULE.LIST_CONTAINER.MODIFIED_BY')}
                  <span>{area.modifiedByUsername}</span>
                </p>
                <p className={styles['areas-list-container__area-item__header__title__created-by']}>
                  {t('AREA_MODULE.LIST_CONTAINER.MODIFIED_AT')}
                  <span>{area.modifiedAt}</span>
                </p> */}
              </div>
              {isMobileView && (
                <>
                  <MIButton aria-controls="area-menu" aria-haspopup="true" onClick={handleClick}>
                    <MoreVertIcon
                      className={styles['areas-list-container__area-item__header__icon']}
                    />
                  </MIButton>
                  <Menu
                    id="area-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        width: '16ch'
                      }
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        setAreaToUpdate(area);
                        setShowEditModal(true);
                      }}
                    >
                      <span
                        className={
                          styles['areas-list-container__area-item__content__responses__link-text']
                        }
                      >
                        {t('AREA_MODULE.LIST_CONTAINER.EDIT_LINK')}
                      </span>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setAreaToDelete(area);
                        setShowDeleteModal(true);
                      }}
                    >
                      <span
                        className={
                          styles['areas-list-container__area-item__content__responses__link-text']
                        }
                      >
                        {t('AREA_MODULE.LIST_CONTAINER.DELETE_LINK')}
                      </span>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </div>

            {!isMobileView && (
              <div className={styles['areas-list-container__area-item__actions']}>
                <MIButton
                  aria-controls="area-menu"
                  aria-haspopup="true"
                  onClick={() => {
                    setAreaToUpdate(area);
                    setShowEditModal(true);
                  }}
                >
                  <EditIcon fontSize={'large'} color="inherit" />
                </MIButton>
                <MIButton
                  aria-controls="area-menu"
                  aria-haspopup="true"
                  onClick={() => {
                    setAreaToDelete(area);
                    setShowDeleteModal(true);
                  }}
                >
                  <DeleteIcon fontSize="large" color={'error'} />
                </MIButton>
              </div>
            )}
          </div>
        ))}
        {/* <DataGrid apiRef={apiRef} className={classes.root} rows={areasList} columns={columns} /> */}
      </div>
      <AddAreaModal
        config={{ showModal: showAddModal }}
        eventHandlers={{
          cancelHandler: () => {
            setShowAddModal(false);
          },
          addAreaHandler: (areaName, order) => {
            setShowAddModal(false);
            handleNewAreaSubmit(areaName, order);
          }
        }}
      />
      <EditAreaModal
        config={{ showModal: showEditModal }}
        data={{ area: areaToUpdate }}
        eventHandlers={{
          cancelHandler: () => {
            setShowEditModal(false);
            setAreaToUpdate(undefined);
          },
          editAreaHandler: (areaId: string, area: AreaUiModel) => {
            setShowEditModal(false);
            setAreaToUpdate(undefined);
            handleEditAreaSubmit(areaId, area);
          },
          submitDefaultImageHandler: (id: string, file: ImageType) => {
            setShowEditModal(false);
            setAreaToUpdate(undefined);
            uploadDefaultImageHandler(id, file);
          },
          submitSponsoredImageHandler: (id: string, file: ImageType) => {
            setShowEditModal(false);
            setAreaToUpdate(undefined);
            uploadSponsoredIImageHandler(id, file);
          }
        }}
      />
      <DeleteAreaModal
        config={{ showModal: showDeleteModal }}
        data={{ area: areaToDelete }}
        eventHandlers={{
          cancelHandler: () => {
            setShowDeleteModal(false);
            setAreaToDelete(undefined);
          },
          deleteAreaHandler: (area: AreaUiModel) => {
            setShowDeleteModal(false);
            setAreaToDelete(undefined);
            if (area && area.id) {
              handleDeleteAreaSubmit(area.id);
            }
          }
        }}
      />
    </div>
  );
};

export default connect(mapStateToProps)(AreasListContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}
