import React, { useState, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import Select, { ValueType } from 'react-select';
import { Expenses } from '../../../models/unit-types.model';
import { Payout } from '../../../models/payout.model';
import _ from 'lodash';
import { InputText } from '../../core-ui/input-text/input-text.component';
import styles from './expenses-payout-list-filter.module.scss';
import Button, { ButtonSize } from '../../core-ui/button/button.component';
import useWindowResizer from '../../../hooks/window-resizer/window-resizer.hook';

export interface ExpensesListFilterContainerProps {
  data: Expenses[] | Payout[];
  eventHandlers: {
    search: (data: {
      room: string;
      description: string;
      project: string;
      area: string;
      category: string;
      paymentDateFrom: Date;
      paymentDateTo: Date;
      amount: string;
    }) => void;
    getInitialList: () => void;
  };
}
export const ExpensesPayoutListFilterContainer: React.FC<ExpensesListFilterContainerProps> = ({
  data,
  eventHandlers
}) => {
  const isMobileView = useWindowResizer();
  const [description, setDescription] = useState<string>('');
  const [room, setRoom] = useState<{ value: string; label: string }>({
    value: '',
    label: ''
  });
  const [project, setProject] = useState<{ value: string; label: string }>({
    value: '',
    label: ''
  });
  const [area, setArea] = useState<{ value: string; label: string }>({ value: '', label: '' });
  const [category, setCategory] = useState<{ value: string; label: string }>({
    value: '',
    label: ''
  });
  const [paymentDateFrom, setPaymentDateFrom] = useState<Date>();
  const [paymentDateTo, setPaymentDateTo] = useState<Date>();
  const [amount, setAmount] = useState<{ value: string; label: string }>({
    value: '',
    label: ''
  });
  const [initialAmountOptions, setInitialAmountOptions] = useState<
    {
      value: string;
      label: string;
      roomId: string;
      areaId: string;
      projectId: string;
      category: string;
    }[]
  >([]);
  const [projectsList, setProjectsList] = useState<
    {
      value: string;
      label: string;
      roomId: string;
      areaId: string;
      category: string;
      amount: string;
    }[]
  >([]);
  const [initialProjectsList, setInitialProjectsList] = useState<
    {
      value: string;
      label: string;
      roomId: string;
      areaId: string;
      category: string;
      amount: string;
    }[]
  >([]);
  const [areaOptions, setAreaOptions] = useState<
    {
      value: string;
      label: string;
      projectId: string;
      roomId: string;
      category: string;
      amount: string;
    }[]
  >([]);

  const [roomsOptions, setRoomsOptions] = useState<
    {
      value: string;
      label: string;
      projectId: string;
      areaId: string;
      category: string;
      amount: string;
    }[]
  >([]);
  const [initialRoomsOptions, setInitialRoomsOptions] = useState<
    {
      value: string;
      label: string;
      projectId: string;
      areaId: string;
      category: string;
      amount: string;
    }[]
  >([]);
  const [categoryOptions, setCategoryOptions] = useState<
    {
      value: string;
      label: string;
      roomId: string;
      areaId: string;
      projectId: string;
      amount: string;
    }[]
  >([]);
  const [initialCategoryOptions, setInitialCategoryOptions] = useState<
    {
      value: string;
      label: string;
      roomId: string;
      areaId: string;
      projectId: string;
      amount: string;
    }[]
  >([]);
  const [amountOptions, setAmountOptions] = useState<
    {
      value: string;
      label: string;
      roomId: string;
      areaId: string;
      projectId: string;
      category: string;
    }[]
  >([]);

  useEffect(() => {
    if (data?.length > 0) {
      filterDataWithoutDuplicate(data);
    }
  }, [data]);

  const searchExpenses = () => {
    const query: any = {};
    if (room.label && room.label !== '') query.roomName = room?.label;
    if (description !== '') query.description = description;
    if (project.label !== '') query.projectName = project?.label;
    if (area.label !== '') query.areaName = area?.label;
    if (category.label && category.label !== '') query.category = category?.label;
    if (paymentDateFrom) query.from = paymentDateFrom?.getTime();
    if (paymentDateTo) query.to = paymentDateTo?.getTime();
    if (amount.label !== '') query.amount = amount?.label;
    eventHandlers.search(query);
  };

  const clearSearch = () => {
    setDescription('');
    setProject({ value: '', label: '' });
    setCategory({ value: '', label: '' });
    setAmount({ value: '', label: '' });
    setRoom({ value: '', label: '' });
    setArea({ value: '', label: '' });
    setPaymentDateFrom(undefined);
    setPaymentDateTo(undefined);
    eventHandlers.getInitialList();
  };
  // initiate filter items options
  const filterDataWithoutDuplicate = (data: Expenses[] | Payout[]) => {
    const area: {
      value: string;
      label: string;
      projectId: string;
      roomId: string;
      category: string;
      amount: string;
    }[] = [];

    const rooms: {
      value: string;
      label: string;
      projectId: string;
      areaId: string;
      category: string;
      amount: string;
    }[] = [];

    const projects: {
      value: string;
      label: string;
      roomId: string;
      areaId: string;
      category: string;
      amount: string;
    }[] = [];

    const category: {
      value: string;
      label: string;
      roomId: string;
      areaId: string;
      projectId: string;
      amount: string;
    }[] = [];

    const amount: {
      value: string;
      label: string;
      roomId: string;
      areaId: string;
      projectId: string;
      category: string;
    }[] = [];

    data?.map((unit) => {
      if (unit?.areaId !== undefined && unit?.areaName !== undefined) {
        area.push({
          value: unit?.areaId,
          label: unit?.areaName,
          projectId: unit?.projectId,
          roomId: unit?.roomId,
          category: unit?.category || '',
          amount: unit?.amount?.toString()
        });
      }
      if (
        unit?.roomId !== undefined &&
        unit?.roomName !== undefined &&
        unit?.projectId !== undefined
      ) {
        rooms.push({
          value: unit.roomId,
          label: unit.roomName,
          projectId: unit?.projectId,
          areaId: unit.areaId || '',
          category: unit?.category || '',
          amount: unit?.amount?.toString()
        });
      }
      if (
        unit?.projectId !== undefined &&
        unit?.projectName !== undefined &&
        unit?.areaId !== undefined
      ) {
        projects.push({
          value: unit?.projectId,
          label: unit?.projectName,
          roomId: unit.roomId,
          areaId: unit.areaId,
          category: unit?.category || '',
          amount: unit?.amount?.toString()
        });
      }
      if (unit?.category !== undefined && unit?.roomId !== undefined) {
        category.push({
          value: unit?.category,
          label: unit?.category,
          roomId: unit.roomId,
          areaId: unit.areaId || '',
          projectId: unit?.projectId,
          amount: unit?.amount?.toString()
        });
      }
      if (unit?.amount && unit?.category !== undefined) {
        amount.push({
          value: unit?.amount.toString(),
          label: unit?.amount.toString(),
          roomId: unit?.roomId,
          areaId: unit?.areaId || '',
          projectId: unit?.projectId,
          category: unit?.category
        });
      }
    });

    if (area.length > 0) {
      setAreaOptions(_.uniqBy(area, 'value'));
    }
    if (rooms.length > 0) {
      setRoomsOptions(_.uniqBy(rooms, 'value'));
      setInitialRoomsOptions(_.uniqBy(rooms, 'value'));
    }
    if (projects.length > 0) {
      setProjectsList(_.uniqBy(projects, 'value'));
      setInitialProjectsList(_.uniqBy(projects, 'value'));
    }
    if (category.length > 0) {
      setCategoryOptions(_.uniqBy(category, 'value'));
      setInitialCategoryOptions(_.uniqBy(category, 'value'));
    }
    if (amount.length > 0) {
      setAmountOptions(_.uniqBy(amount, 'value'));
      setInitialAmountOptions(_.uniqBy(amount, 'value'));
    }
  };

  return (
    <div className={styles['filter-list-container__expenses-item']}>
      <div className={styles['filter-list-container__expenses-item__form-field']}>
        <div className={styles['filter-list-container__expenses-item__form-field__label']}>
          Description
        </div>
        <InputText
          data={{
            value: description
          }}
          config={{
            maxRows: 1,
            autoFocus: false
          }}
          eventHandlers={{
            onChangeHandler: (value: string, isValid: boolean) => {
              setDescription(value);
            }
          }}
        />
      </div>

      <div className={styles['filter-list-container__expenses-item__form-field']}>
        <div className={styles['filter-list-container__expenses-item__form-field__label']}>
          area Name
        </div>
        <Select
          value={{ value: area.value, label: area.label }}
          options={areaOptions?.map((user) => ({
            value: user?.value,
            label: user?.label
          }))}
          className={styles['choose-unit-type__form-field__select']}
          onChange={(value: ValueType<{ value: string; label: string }, false>) => {
            setArea(value ? value : { value: '', label: '' });
            setProjectsList(
              initialProjectsList?.filter((project) => project?.areaId?.includes(value?.value))
            );
            setProject({ value: '', label: '' });
            setCategory({ value: '', label: '' });
            setAmount({ value: '', label: '' });
            setRoom({ value: '', label: '' });
          }}
        />
      </div>
      <div className={styles['filter-list-container__expenses-item__form-field']}>
        <div className={styles['filter-list-container__expenses-item__form-field__label']}>
          project Name
        </div>
        <Select
          value={{ value: project.value, label: project.label }}
          className={styles['choose-unit-type__form-field__select']}
          options={projectsList.map((unitType) => ({
            value: unitType.value,
            label: unitType.label
          }))}
          onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
            setProject(
              value ? { value: value.value, label: value.label } : { value: '', label: '' }
            );
            setCategory({ value: '', label: '' });
            setAmount({ value: '', label: '' });
            setRoom({ value: '', label: '' });
            setRoomsOptions(
              initialRoomsOptions?.filter((unit) => unit?.projectId?.includes(value?.value))
            );
          }}
        />
      </div>
      <div className={styles['filter-list-container__expenses-item__form-field']}>
        <div className={styles['filter-list-container__expenses-item__form-field__label']}>
          Room Name
        </div>
        <Select
          value={{ value: room.value, label: room.label }}
          className={styles['choose-unit-type__form-field__select']}
          options={roomsOptions.map((room) => ({
            value: room.value,
            label: room.label
          }))}
          onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
            setRoom(
              value ? { value: value.value || '', label: value.label } : { value: '', label: '' }
            );
            setCategory({ value: '', label: '' });
            setAmount({ value: '', label: '' });
            setCategoryOptions(
              initialCategoryOptions?.filter((unit) => unit?.roomId?.includes(value?.value))
            );
          }}
        />
      </div>
      <div className={styles['filter-list-container__expenses-item__form-field']}>
        <div className={styles['filter-list-container__expenses-item__form-field__label']}>
          payment Date From
        </div>
        <ReactDatePicker
          selected={paymentDateFrom}
          onChange={(date) => {
            setPaymentDateFrom(date as Date);
          }}
        />
      </div>
      <div className={styles['filter-list-container__expenses-item__form-field']}>
        <div className={styles['filter-list-container__expenses-item__form-field__label']}>
          payment Date to
        </div>
        <ReactDatePicker
          selected={paymentDateTo}
          minDate={paymentDateFrom}
          onChange={(date) => {
            setPaymentDateTo(date as Date);
          }}
        />
      </div>
      <div className={styles['filter-list-container__expenses-item__form-field']}>
        <div className={styles['filter-list-container__expenses-item__form-field__label']}>
          category
        </div>
        <Select
          value={{ value: category?.value, label: category?.label }}
          className={styles['choose-unit-type__form-field__select']}
          options={categoryOptions.map((category) => ({
            value: category.value,
            label: category.label
          }))}
          onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
            setCategory(
              value ? { value: value.value || '', label: value.label } : { value: '', label: '' }
            );
            setAmount({ value: '', label: '' });
            setAmountOptions(
              initialAmountOptions?.filter((unit) => unit?.category?.includes(value?.value))
            );
          }}
        />
      </div>
      <div className={styles['filter-list-container__expenses-item__form-field']}>
        <div className={styles['filter-list-container__expenses-item__form-field__label']}>
          amount
        </div>
        <Select
          value={{ value: amount?.value, label: amount?.label }}
          className={styles['choose-unit-type__form-field__select']}
          options={amountOptions.map((amount) => ({
            value: amount.value,
            label: amount.label
          }))}
          onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
            setAmount(
              value ? { value: value.value || '', label: value.label } : { value: '', label: '' }
            );
          }}
        />
      </div>
      <div className={styles['filter-list-container__expenses-item__button-container']}>
        <Button
          onClick={() => searchExpenses()}
          className={'btn'}
          tracking={{
            action: 'close',
            category: 'unit-types',
            label: 'add-unit-type-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          search
        </Button>
        <Button
          onClick={() => clearSearch()}
          className={'btn'}
          tracking={{
            action: 'clear',
            category: 'unit-types',
            label: 'add-unit-type-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          clear
        </Button>
      </div>
    </div>
  );
};
