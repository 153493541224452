import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './financial-report.module.scss';
import ReactDatePicker from 'react-datepicker';
import { CSVLink } from 'react-csv';

import { isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { RootState } from '../../../shared/redux/reducers';
import { trackPage } from '../../../shared/services/utils/tracking/tracking.service';
import useWindowResizer from '../../../shared/hooks/window-resizer/window-resizer.hook';
import {
  InputText,
  InputTextType
} from '../../../shared/components/core-ui/input-text/input-text.component';
import Button, { ButtonSize } from '../../../shared/components/core-ui/button/button.component';
import { useHistory } from 'react-router';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { getFinancialReport } from '../../shared/services/data.service';
import { FormElementData } from '../../../shared/interfaces/form-element-data.interface';
import { PUBLIC_PATH } from '../../../shared/config/routes-config';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
    }
  }
});
const useSelectedRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
      // backgroundColor: '#ffa358'
    }
  }
});

export type FinancialReportContainerProps = ReturnType<typeof mapStateToProps>;

/**
 * functional component FinancialReportContainer
 * @param {FinancialReportContainerProps} holding question text
 */
export const FinancialReportContainer: React.FC<FinancialReportContainerProps> = ({
  isAuthenticated
}) => {
  const isMobileView = useWindowResizer();
  // const { t } = useTranslation();
  const [records, setRecords] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [csvHeader, setCsvHeaders] = useState<{ label: string; key: string }>();
  const [startDate, setStartDate] = useState<FormElementData<Date>>({
    value: new Date(),
    isValid: false
  });
  const [endDate, setEndDate] = useState<FormElementData<Date>>({
    value: undefined,
    isValid: true
  });
  const [usdConversionRate, setUsdConversionRate] = useState<FormElementData<number>>({
    value: 0,
    isValid: false
  });

  const history = useHistory();

  useEffect(() => {
    trackPage('financial-report');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function Row(props: { record: any }) {
    const { record } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const selectedClasses = useSelectedRowStyles();

    return (
      <>
        <TableRow>
          {Object.keys(record).map((recordItem) => (
            <TableCell>
              {typeof record[recordItem] === 'number'
                ? record[recordItem] === 0
                  ? '-'
                  : recordItem.indexOf('%') > -1
                  ? (record[recordItem] * 100).toFixed(0) + '%'
                  : new Intl.NumberFormat('en-GB', {
                      maximumFractionDigits: 2
                    }).format(record[recordItem])
                : record[recordItem]}
            </TableCell>
          ))}
        </TableRow>
        <TableRow>
          {/* <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Guests List
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Phone</TableCell>
                      <TableCell>Gender</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reservation.history.map((historyRow) => (
                      <TableRow key={historyRow.date}>
                        <TableCell component="th" scope="row">
                          {historyRow.date}
                        </TableCell>
                        <TableCell>{historyRow.customerId}</TableCell>
                        <TableCell align="right">{historyRow.amount}</TableCell>
                        <TableCell align="right">
                          {Math.round(historyRow.amount * row.price * 100) / 100}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell> */}
        </TableRow>
      </>
    );
  }
  return (
    <div className={styles['financial-report-container']}>
      <div className={styles['financial-report-container__title']}>
        <span>Financial Report</span>
      </div>
      <div className={styles['financial-report-container__payment-item']}>
        <div className={styles['financial-report-container__payment-item__form-field']}>
          <div className={styles['financial-report-container__payment-item__form-field__label']}>
            Start Date
          </div>
          <ReactDatePicker
            selected={startDate.value}
            // minDate={moment().toDate()}
            onChange={(date) => {
              setStartDate({ value: date as Date, isValid: true });
              setEndDate({
                value: date as Date,
                isValid: true
              });
            }}
          />
        </div>
        <div className={styles['financial-report-container__payment-item__form-field']}>
          <div className={styles['financial-report-container__payment-item__form-field__label']}>
            End Date
          </div>
          <ReactDatePicker
            selected={endDate.value}
            // minDate={moment().toDate()}
            minDate={startDate.value}
            onChange={(date) => setEndDate({ value: date as Date, isValid: true })}
          />
        </div>
        <div className={styles['financial-report-container__payment-item__form-field']}>
          <div className={styles['financial-report-container__payment-item__form-field__label']}>
            USD Conversion Rate
          </div>
          <InputText
            data={{
              value: usdConversionRate.value
            }}
            config={{
              maxRows: 1,
              autoFocus: false,
              type: InputTextType.number
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setUsdConversionRate({ value: Number(value), isValid });
              }
            }}
          />
        </div>
        <div className={styles['financial-report-container__payment-item__button-container']}>
          <Button
            disabled={!usdConversionRate.value || !startDate.value || !endDate.value}
            onClick={() => {
              if (usdConversionRate.value && startDate.value && endDate.value) {
                setIsLoading(true);
                getFinancialReport({
                  conversionRate: Number(usdConversionRate.value),
                  startDate: startDate.value.getTime(),
                  endDate: endDate.value.getTime()
                }).then((response) => {
                  setIsLoading(false);
                  if (!isErrorInfo(response)) {
                    console.log(response);
                    setRecords(response);
                    if (response && response.length > 0) {
                      setCsvHeaders(
                        Object.keys(response[0])?.map((record) => ({
                          label: record,
                          key: record
                        }))
                      );
                    }
                  }
                });
              }
            }}
            className={styles['financial-report-container__payment-item__button-container__btn']}
            tracking={{
              action: 'close',
              category: 'unit-types',
              label: 'add-unit-type-modal'
            }}
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          >
            Generate
          </Button>
        </div>
      </div>
      {isLoading ? (
        <div className={styles['financial-report-container__loader']}>
          <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
        </div>
      ) : (
        <>
          {records && records.length > 0 && (
            <div className={styles['financial-report-container__export']}>
              <div className={styles['financial-report-container__export__btn-container']}>
                <CSVLink data={records} headers={csvHeader}>
                  Export CSV
                </CSVLink>
              </div>
            </div>
          )}
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="table">
              <TableHead>
                <TableRow>
                  {records &&
                    records[0] &&
                    Object.keys(records[0]).map((record) => <TableCell>{record}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {records.map((record, index) => (
                  <Row key={index} record={record} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(FinancialReportContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}
