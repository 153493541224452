import { UnitTypeGroupModel } from '../models/unit-type-group..model';
import { UnitTypeGroupUiModel } from '../ui-models/unit-type-group.ui-model';

function mapToUI(unitTypeGroupData: UnitTypeGroupModel): UnitTypeGroupUiModel {
  return {
    id: unitTypeGroupData.id || '',
    name: unitTypeGroupData.name
  };
}

function mapToModel(unitTypeGroupData: UnitTypeGroupUiModel): UnitTypeGroupModel {
  return {
    id: unitTypeGroupData.id,
    name: unitTypeGroupData.name
  };
}

function mapToUIList(unitTypeGroupsList: UnitTypeGroupModel[]): UnitTypeGroupUiModel[] {
  return unitTypeGroupsList.map(mapToUI);
}

export default {
  mapToUI,
  mapToModel,
  mapToUIList
};
