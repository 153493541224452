import { ApiResponse } from 'apisauce';
import { ErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { ResponseModel } from '../../../shared/interfaces/response-model.interface';
import AmenityMapper from '../../../shared/mappers/amenity.mapper';
import { AmenityModel } from '../../../shared/models/amenity.model';
import api from '../../../shared/services/utils/api/api.service';
import errorHelper from '../../../shared/services/utils/api/error-helper.service';
import { AmenityUiModel } from '../../../shared/ui-models/amenity.ui-model';
import { getModuleName } from './amenity.service';

export function addNewAmenity(amenityDate: AmenityUiModel): Promise<AmenityUiModel | ErrorInfo> {
  const mappedAmenity = AmenityMapper.mapToModel(amenityDate);
  return api
    .createAmenity(mappedAmenity)
    .then((response: ApiResponse<ResponseModel<AmenityModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return AmenityMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.createAmenity as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.createAmenity as any).displayName
        );
      }
    });
}

export function updateAmenity(
  amenityId: string,
  amenityDate: AmenityUiModel
): Promise<AmenityUiModel | ErrorInfo> {
  const mappedAmenity = AmenityMapper.mapToModel(amenityDate);
  return api
    .updateAmenity(amenityId, mappedAmenity)
    .then((response: ApiResponse<ResponseModel<AmenityModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return AmenityMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.updateAmenity as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.updateAmenity as any).displayName
        );
      }
    });
}

export function deleteAmenity(areaId: string): Promise<{} | ErrorInfo> {
  return api.deleteAmenity(areaId).then((response: ApiResponse<ResponseModel<{}>, ErrorInfo>) => {
    if (response.ok) {
      const { data } = response;
      if (data && data.data) {
        return {};
      } else {
        return errorHelper(0, '', getModuleName(), (api.deleteAmenity as any).displayName);
      }
    } else {
      return errorHelper(
        Number(response.status),
        response.data && response.data.errorCode
          ? String(response.data.errorCode || '')
          : response.problem,
        getModuleName(),
        (api.deleteAmenity as any).displayName
      );
    }
  });
}

export function uploadAmenityImage(
  file: File,
  amenityId: string
): Promise<AmenityUiModel | ErrorInfo> {
  const data = new FormData();
  data.append('asset', file);
  data.append('amenityId', amenityId);

  return api
    .uploadAmenityImage(data)
    .then((response: ApiResponse<ResponseModel<AmenityModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return AmenityMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.uploadAmenityImage as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.uploadAmenityImage as any).displayName
        );
      }
    });
}
