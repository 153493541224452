import React, { useEffect, useState } from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import styles from './sortable-gallery.module.scss';

export interface SortableSliderProps {
  data: {
    sliderItems: { imgSrc: string; id: string }[];
  };
  eventHandlers: {
    deleteHandler: (id: string) => void;
    sortHandler: (item: { imgSrc: string; id: string }[]) => void;
  };
}

const SortableItem = SortableElement(({ value, onRemove }) => (
  <img {...value} alt="img" className={styles['sortable-gallery_photo-frame']} />
));

const SortableList = SortableContainer(({ items, onRemove }) => {
  return (
    <div className={styles['sortable-gallery_photo-container']}>
      {items?.map((value: any, index: number) => (
        <div className={styles['sortable-gallery']}>
          <SortableItem key={`item-${index}`} index={index} value={value} onRemove={onRemove} />
          <div
            className={styles['sortable-gallery_remove-icon']}
            onClick={(event) => {
              console.log('delete triggered');
              if (event && event.stopPropagation) event.stopPropagation();
              onRemove(value.id);
            }}
          >
            <span>X</span>
          </div>
        </div>
      ))}
    </div>
  );
});

const SortableGalleryComp: React.FC<SortableSliderProps> = ({ data, eventHandlers }) => {
  const [photos, setPhotos] = useState<any>([]);
  useEffect(() => {
    setPhotos(
      (data.sliderItems as any[]).map((photo) => ({
        ...photo,
        src: photo.imgSrc,
        id: photo.id,
        height: 200,
        width: 200
      }))
    );
  }, [data]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setPhotos(arrayMoveImmutable(photos, oldIndex, newIndex));
    eventHandlers.sortHandler(arrayMoveImmutable(photos, oldIndex, newIndex));
  };

  return (
    <div className={styles['sortable-gallery']}>
      <SortableList
        items={photos}
        onSortEnd={onSortEnd}
        axis="xy"
        onRemove={(id: string) => eventHandlers.deleteHandler(id)}
      />
    </div>
  );
};
export default SortableGalleryComp;
