import { BookingRequestModel } from '../models/booking-request.model';
import { BookingRequestUiModel } from '../ui-models/booking-request.ui-model';

function mapToUI(bookingRequestData: BookingRequestModel): BookingRequestUiModel {
  return {
    requestId: bookingRequestData.id,
    otp: bookingRequestData.otp,
    status: bookingRequestData.status,
    bookedAt: bookingRequestData.bookedAt,
    screenedAt: bookingRequestData.screenedAt,
    reservationId: bookingRequestData.reservationID,
    guestId: bookingRequestData.guestID,
    availableRooms: bookingRequestData.availableRooms,
    messagesList: bookingRequestData.messagesList,
    area: bookingRequestData.area,
    areaId: bookingRequestData.areaId,
    propertyName: bookingRequestData.propertyName,
    bookingInfo: {
      checkInDate: bookingRequestData.checkInDate,
      checkOutDate: bookingRequestData.checkOutDate,
      numberOfGuests: bookingRequestData.numberOfGuests,
      unitTypeId: bookingRequestData.unitTypeId
    },
    guestInfo: {
      email: bookingRequestData.email,
      gender: bookingRequestData.gender,
      moreInfo: bookingRequestData.moreInfo,
      name: bookingRequestData.name,
      phoneNumber: bookingRequestData.phoneNumber,
      socialLink: bookingRequestData.socialLink
    },
    offerInfo: {
      oneTime: bookingRequestData.oneTimePayment,
      recurring: bookingRequestData.recurringPayment
    }
  };
}

function mapToUIList(bookingRequestsList: BookingRequestModel[]): BookingRequestUiModel[] {
  return bookingRequestsList.map(mapToUI);
}

export default {
  mapToUI,
  mapToUIList
};
