import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, {
  ButtonSize
} from '../../../../../../shared/components/core-ui/button/button.component';
import {
  InputText,
  InputTextErrorMode,
  InputTextType
} from '../../../../../../shared/components/core-ui/input-text/input-text.component';
import Modal from '../../../../../../shared/components/core-ui/modal/modal.component';
import useWindowResizer from '../../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { FormElementData } from '../../../../../../shared/interfaces/form-element-data.interface';
import { CreatePayOutDto, Payout } from '../../../../../../shared/models/payout.model';
import {
  ExpensesCategoryType,
  UnitTypeUiModel
} from '../../../../../../shared/ui-models/unit-type.ui-model';
import styles from './create-payout-modal.module.scss';
import Select, { ValueType } from 'react-select';
import ReactDatePicker from 'react-datepicker';
import Dropzone from 'react-dropzone-uploader';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

export interface PayoutModalProps {
  config: {
    showModal: boolean;
    unitType?: UnitTypeUiModel;
  };
  eventHandlers: {
    closeHandler: () => void;
    addPayoutHandler: (unitPayout: CreatePayOutDto) => void;
  };
}

/**
 * functional component CreatePayoutModal
 * @param {PayoutModalProps}
 */
export const CreatePayoutModal: React.FC<PayoutModalProps> = ({ config, eventHandlers }) => {
  const [description, setDescription] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [unitTypeID, setUnitTypeID] = useState<FormElementData<string>>({
    value: config.unitType?.id || '',
    isValid: false
  });
  const [amount, setAmount] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [room, setRoom] = useState<string | undefined>('');
  const [file, setFile] = useState<File>();

  const [payDate, setPayDate] = useState<FormElementData<Date | null>>({
    value: new Date(),
    isValid: true
  });
  const [roomOptions, setRoomOptions] = useState<{ value: string | undefined; label: string }[]>(
    []
  );
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  const handleNewUnitTypeSubmit = () => {
    eventHandlers.addPayoutHandler({
      description: description.value,
      unitTypeId: Number(unitTypeID.value),
      roomId: room || '',
      paymentDate: payDate.value?.getTime() || Date.now(),
      landlordId: config.unitType?.landlordId || '',
      amount: Number(amount.value) || 0,
      file
    });
  };
  useEffect(() => {
    const rooms = config?.unitType?.units
      ? config.unitType.units.map((room) => ({ label: room.shortName, value: room.unitId }))
      : [];
    setRoomOptions(rooms);
  }, []);

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.closeHandler();
        }
      }}
    >
      <div className={styles['create-payout-modal']}>
        <div className={styles['create-payout-modal__title']}>Create Payout</div>
        <div className={styles['create-payout-modal__description']}>
          Enter Description :
          <InputText
            data={{
              defaultValue: '',
              placeholder: 'description'
            }}
            config={{
              maxRows: 1,
              autoFocus: true,
              showErrorMode: InputTextErrorMode.onFirstBlur
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setDescription({ value, isValid });
              }
            }}
          />
        </div>
        <div className={styles['create-payout-modal__description']}>
          Enter unit type :
          <InputText
            data={{
              defaultValue: unitTypeID.value,
              placeholder: 'unit'
            }}
            config={{
              maxRows: 1,
              disabled: true
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setUnitTypeID({ value, isValid });
              }
            }}
          />
        </div>
        <div className={styles['create-payout-modal__description']}>
          Enter amount :
          <InputText
            data={{
              defaultValue: amount.value,
              placeholder: 'amount'
            }}
            config={{
              maxRows: 1,
              type: InputTextType.number,
              required: true
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setAmount({ value, isValid });
              }
            }}
          />
        </div>
        <div className={styles['create-payout-modal__description']}>
          select room:
          <Select
            className={styles['create-payout-modal__select']}
            options={roomOptions}
            onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
              setRoom(value ? value.value : '');
            }}
          />
        </div>
        <div className={styles['add-guest-modal__description']}>
          pay day:
          <ReactDatePicker
            selected={payDate.value}
            onChange={(date) => setPayDate({ value: date as Date, isValid: true })}
          />
        </div>
        <Dropzone
          classNames={{
            input: styles['create-payout-modal__dropzone']
          }}
          inputContent={() => (
            <div className={styles['create-payout-modal__dropzone__upload-container']}>
              <CloudUploadIcon fontSize={'large'} />
              Upload Identification Document
            </div>
          )}
          accept="*"
          maxFiles={1}
          multiple={false}
          onChangeStatus={(file) => {
            if (file.meta.status !== 'removed') {
              setFile(file.file);
            }
          }}
        />
      </div>
      <div className={styles['create-payout-modal--actions']}>
        <Button
          onClick={() => {
            eventHandlers.closeHandler();
          }}
          tracking={{
            action: 'close',
            category: 'unit-types',
            label: 'create-payout-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          Close
        </Button>
        <Button
          disabled={
            !description.isValid || description.value === '' || !room || !payDate || !amount
          }
          onClick={handleNewUnitTypeSubmit}
          tracking={{
            action: 'submit',
            category: 'unit-type',
            label: 'create-payout-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          submit
        </Button>
      </div>
    </Modal>
  );
};
