import React, { useEffect, useState, useRef } from 'react';
import {
  trackEvent,
  trackPage
} from '../../../../../shared/services/utils/tracking/tracking.service';
import { getAllUsers } from '../../../../../shared/services/data/lookups.data';
import { getModuleName } from '../../../../shared/services/unit-type.service';
import { UserUIModel } from '../../../../../shared/ui-models/user.ui-model';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { store } from 'react-notifications-component';

import { addUser } from '../../../../shared/services/data.service';
import {
  InputText,
  InputTextErrorMode,
  InputTextType
} from '../../../../../shared/components/core-ui/input-text/input-text.component';
import { useTranslation } from 'react-i18next';
import styles from './assign-partner-modal.module.scss';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { ErrorInfo, isErrorInfo } from '../../../../../shared/interfaces/error-info.interface';
import { Button as MIButton, Menu, MenuItem } from '@material-ui/core';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import { UnitTypeUiModel } from '../../../../../shared/ui-models/unit-type.ui-model';
import { UserType } from '../../../../../shared/enums/user-type.enum';
import PhoneInput from 'react-phone-input-2';
import { getUserByPhone } from '../../../../../shared/services/data/user.data';
import ReCAPTCHA from 'react-google-recaptcha';
import { GOOGLE_CAPTCHA_KEY } from '../../../../../shared/config/routes-config';

const captchaKEy = GOOGLE_CAPTCHA_KEY;

const genderOption = [
  { label: 'Male', value: 'male' },
  { label: 'FeMale', value: 'female' }
];

export interface AssignPartnerModalProps {
  config: {
    showModal: boolean;
  };
  eventHandlers: {
    closeHandler: () => void;
    chooseUser: (unitTypeModel: UnitTypeUiModel) => void;
  };
}

/**
 * functional component AssignPartnerModal
 * @param {AssignPartnerModalProps}
 */
export const AssignPartnerModal: React.FC<AssignPartnerModalProps> = ({
  config,
  eventHandlers
}) => {
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();
  const [user, setUser] = useState<UserUIModel>();

  const [searchNumber, setSearchNumber] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [name, setName] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [email, setEmail] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [phone, setPhone] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [share, setShare] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });

  const [showSearchResult, setShowSearchResult] = useState<boolean>(false);
  const [assignUser, setAssignUser] = useState<boolean>(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleAddUser = async (createUser: Partial<UserUIModel>) => {
    store.removeNotification('add-user-failed');
    const token = await recaptchaRef?.current?.executeAsync();
    addUser({ ...createUser, token: token || '' }).then((response: UserUIModel | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        eventHandlers.chooseUser({
          partnerId: response.id,
          partnerEmail: response.email,
          partnerPhone: response.phone,
          partnerUsername: response.name,
          partnerShare: Number(share.value)
        });
        eventHandlers.closeHandler();
      } else {
        store.addNotification({
          id: 'add-user-failed',
          title: t(
            'UNIT_TYPE_MODULE.LIST_CONTAINER.ASSIGN_PARTNER_MODAL.NOTIFICATIONS.CREATION_FAILED.TITLE'
          ),
          message: t(
            'UNIT_TYPE_MODULE.LIST_CONTAINER.ASSIGN_PARTNER_MODAL.NOTIFICATIONS.CREATION_FAILED.MESSAGE'
          ),
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
      }
    });
  };

  const handleGetUserByPhoneNumber = (phoneNumber: string) => {
    getUserByPhone(phoneNumber, getModuleName()).then((response: UserUIModel | ErrorInfo) => {
      setShowSearchResult(true);
      if (!isErrorInfo(response)) {
        setUser(response);
        setAssignUser(false);
      } else {
        setAssignUser(true);
        store.addNotification({
          id: 'get-user-failed',
          title: t(
            'UNIT_TYPE_MODULE.LIST_CONTAINER.ASSIGN_PARTNER_MODAL.NOTIFICATIONS.SEARCH_FAILED.TITLE'
          ),
          message: t(
            'UNIT_TYPE_MODULE.LIST_CONTAINER.ASSIGN_PARTNER_MODAL.NOTIFICATIONS.SEARCH_FAILED.MESSAGE'
          ),
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
      }
    });
  };
  return (
    <Modal
      config={{
        showModal: config.showModal,
        fullScreen: false,
        showClose: true,
        className: 'partner-Modal'
      }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.closeHandler();
        }
      }}
    >
      <div className={styles['assign-partner-container__add-user']}>
        <div className={styles['assign-partner-container__add-user__title']}>Add New User</div>
        <div className={styles['assign-partner-container__add-user__description']}>
          <div className={styles['assign-partner-container__add-user__description--search']}>
            search by phone number
            <div
              className={styles['assign-partner-container__add-user__description--search--input']}
            >
              <PhoneInput
                country={'eg'}
                inputProps={{
                  name: 'search by phone number',
                  required: true
                }}
                value={searchNumber.value}
                onChange={(phone) => {
                  phone
                    ? setSearchNumber({ value: `+${phone}`, isValid: true })
                    : setSearchNumber({ value: `${phone}`, isValid: false });
                }}
              />
              <Button
                onClick={() => {
                  handleGetUserByPhoneNumber(searchNumber.value);
                }}
                tracking={{
                  action: 'search',
                  category: 'guest',
                  label: 'add-guest'
                }}
                size={isMobileView ? ButtonSize.full : ButtonSize.medium}
              >
                <SearchIcon
                  fontSize={'large'}
                  color="inherit"
                  className={
                    styles['assign-partner-container__add-user__description--search-action']
                  }
                />
              </Button>
            </div>
          </div>
        </div>{' '}
        {user && !assignUser && showSearchResult && (
          <div>
            <div className={styles['partner-info-container-content']}>
              <div className={styles['partner-info-container-content-info']}>
                <label>id :</label>
                <p> {user?.id}</p>
              </div>
              <div className={styles['partner-info-container-content-info']}>
                <label>name :</label> <p>{user?.name}</p>
              </div>
              <div className={styles['partner-info-container-content-info']}>
                <label>email :</label> <p>{user?.email}</p>
              </div>
              <div className={styles['partner-info-container-content-info']}>
                <label>phone :</label> <p>{user?.phone}</p>
              </div>

              <div>
                <InputText
                  data={{
                    defaultValue: '',
                    errorMessage: 'Partner Share is required',
                    placeholder: 'Partner Share %'
                  }}
                  config={{
                    maxRows: 1,
                    maxLength: 100,
                    validationRegex: '^[1-9][0-9]?$|^100$',
                    type: InputTextType.number,
                    autoFocus: true,
                    required: true,
                    showErrorMode: InputTextErrorMode.instant
                  }}
                  eventHandlers={{
                    onChangeHandler: (value: string, isValid: boolean) => {
                      setShare({ value, isValid });
                    }
                  }}
                />
              </div>
            </div>
            <div className={styles['assign-partner-container__add-user--actions']}>
              <Button
                onClick={() => {
                  eventHandlers.chooseUser({
                    partnerId: user.id,
                    partnerEmail: user.email,
                    partnerPhone: user.phone,
                    partnerUsername: user.name,
                    partnerShare: Number(share.value)
                  });
                }}
                tracking={{
                  action: 'assign',
                  category: 'guest',
                  label: 'assign-guest-modal'
                }}
                size={isMobileView ? ButtonSize.full : ButtonSize.medium}
              >
                confirm
              </Button>
            </div>
          </div>
        )}
        {assignUser && showSearchResult && (
          <>
            <div className={styles['assign-partner-container__add-user__assign']}>
              <div className={styles['assign-partner-container__add-user__description']}>
                Name
                <InputText
                  data={{
                    defaultValue: '',
                    errorMessage: 'name is required',
                    placeholder: 'Name'
                  }}
                  config={{
                    maxRows: 1,
                    autoFocus: true,
                    required: true,
                    showErrorMode: InputTextErrorMode.instant
                  }}
                  eventHandlers={{
                    onChangeHandler: (value: string, isValid: boolean) => {
                      setName({ value, isValid });
                    }
                  }}
                />
              </div>
              <div className={styles['assign-partner-container__add-user__description']}>
                Email
                <InputText
                  data={{
                    defaultValue: '',
                    errorMessage: 'Email is required',
                    placeholder: 'Email'
                  }}
                  config={{
                    maxRows: 1,
                    required: true,
                    showErrorMode: InputTextErrorMode.instant
                  }}
                  eventHandlers={{
                    onChangeHandler: (value: string, isValid: boolean) => {
                      setEmail({ value, isValid });
                    }
                  }}
                />
              </div>
              <div className={styles['assign-partner-container__add-user__description']}>
                Phone Number
                <PhoneInput
                  country={'eg'}
                  inputProps={{
                    name: 'Phone number',
                    required: true
                  }}
                  value={phone.value}
                  onChange={(phone) => {
                    setPhone({ value: `+${phone}`, isValid: true });
                  }}
                />
              </div>
              <div className={styles['assign-partner-container__add-user__description']}>
                Share
                <InputText
                  data={{
                    defaultValue: '',
                    errorMessage: 'Partner Share is required',
                    placeholder: 'Partner Share %'
                  }}
                  config={{
                    maxRows: 1,
                    maxLength: 100,
                    validationRegex: '^[1-9][0-9]?$|^100$',
                    type: InputTextType.number,
                    autoFocus: true,
                    required: true,
                    showErrorMode: InputTextErrorMode.instant
                  }}
                  eventHandlers={{
                    onChangeHandler: (value: string, isValid: boolean) => {
                      setShare({ value, isValid });
                    }
                  }}
                />
              </div>
            </div>

            <div className={styles['assign-partner-container__add-user--actions']}>
              <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={captchaKEy || ''} />
              <Button
                disabled={!name.isValid || !email.isValid || !phone.isValid}
                onClick={() => {
                  handleAddUser({
                    name: name.value,
                    email: email.value,
                    phone: phone.value,
                    role: UserType.PARTNER_MANAGER
                  });
                }}
                tracking={{
                  action: 'submit',
                  category: 'guest',
                  label: 'add-guest-modal'
                }}
                size={isMobileView ? ButtonSize.full : ButtonSize.medium}
              >
                Add User
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
