import { ApiResponse } from 'apisauce';
import { ErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { ResponseModel } from '../../../shared/interfaces/response-model.interface';
import UnitTypeMapper from '../../../shared/mappers/unit-type.mapper';
import { CreatePayOutDto, Payout, SearchPayOutDto } from '../../../shared/models/payout.model';
import { BulkUpdate, Expenses, UnitTypeModel } from '../../../shared/models/unit-types.model';
import UserMapper from '../../../shared/mappers/user.mapper';
import { UserModel } from '../../../shared/models/user.model';
import { UserUIModel } from '../../../shared/ui-models/user.ui-model';

import api from '../../../shared/services/utils/api/api.service';
import errorHelper from '../../../shared/services/utils/api/error-helper.service';
import { UnitTypeUiModel } from '../../../shared/ui-models/unit-type.ui-model';
import { CreateExpenses } from '../../containers/edit-unit-type/expenses-list/components/create-reservation/create-expense-modal.component';
import { getModuleName } from './unit-type.service';

export function updateUnitTypeData(
  unitTypeId: string,
  unitTypeData: Partial<UnitTypeUiModel>
): Promise<UnitTypeUiModel | ErrorInfo> {
  const mappedUnitType = UnitTypeMapper.mapToModel(unitTypeData);
  return api
    .updateUnitType(unitTypeId, mappedUnitType)
    .then((response: ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return UnitTypeMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.updateUnitType as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.updateUnitType as any).displayName
        );
      }
    });
}

export function syncUnitTypeChannel(unitTypeId: string): Promise<UnitTypeUiModel | ErrorInfo> {
  return api
    .syncUnitTypeChannel(unitTypeId)
    .then((response: ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return UnitTypeMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.syncUnitTypeChannel as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.syncUnitTypeChannel as any).displayName
        );
      }
    });
}

export function deleteUnitTypeShowroomImage(
  unitTypeId: number,
  imageId: string,
  roomId: string
): Promise<UnitTypeUiModel | ErrorInfo> {
  // const data = new FormData();
  // data.append('imageId', imageId);
  // data.append('unitTypeId', unitTypeId);
  // data.append('roomId', unitTypeId);

  return api
    .deleteUnitTypeShowroomImage({
      unitTypeId,
      imageId,
      roomId
    })
    .then((response: ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return UnitTypeMapper.mapToUI(data.data);
        } else {
          return errorHelper(
            0,
            '',
            getModuleName(),
            (api.deleteUnitTypeShowroomImage as any).displayName
          );
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.deleteUnitTypeShowroomImage as any).displayName
        );
      }
    });
}

export function uploadUnitTypeShowRoomImage(
  roomName: string,
  files: any,
  unitTypeId: string
): Promise<UnitTypeUiModel | ErrorInfo> {
  const data = new FormData();
  files.forEach((file: any) => {
    data.append('asset', file);
  });
  data.append('roomName', roomName);
  data.append('unitTypeId', unitTypeId);

  return api
    .uploadUnitTypeShowRoomImage(data)
    .then((response: ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return UnitTypeMapper.mapToUI(data.data);
        } else {
          return errorHelper(
            0,
            '',
            getModuleName(),
            (api.uploadUnitTypeShowRoomImage as any).displayName
          );
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.uploadUnitTypeShowRoomImage as any).displayName
        );
      }
    });
}

export function uploadUnitTypeImage(
  files: any,
  unitTypeId: string
): Promise<UnitTypeUiModel | ErrorInfo> {
  console.log('assets', files);
  const data = new FormData();
  files.forEach((file: any) => {
    data.append('asset', file);
  });
  data.append('unitTypeId', unitTypeId);

  return api
    .uploadUnitTypeImage(data)
    .then((response: ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return UnitTypeMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.uploadUnitTypeImage as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.uploadUnitTypeImage as any).displayName
        );
      }
    });
}

export function deleteUnitTypeImage(
  unitTypeId: number,
  imageId: string
): Promise<UnitTypeUiModel | ErrorInfo> {
  // const data = new FormData();
  // data.append('imageId', imageId);
  // data.append('unitTypeId', unitTypeId);
  return api
    .deleteUnitTypeImage({ unitTypeId, imageId })
    .then((response: ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return UnitTypeMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.deleteUnitTypeImage as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.deleteUnitTypeImage as any).displayName
        );
      }
    });
}
export function createExpenses(expense: CreateExpenses): Promise<Expenses | ErrorInfo> {
  return api
    .createExpenses(expense)
    .then((response: ApiResponse<ResponseModel<Expenses>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return data.data;
        } else {
          return errorHelper(0, '', getModuleName(), (api.createExpenses as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.createExpenses as any).displayName
        );
      }
    });
}
export function deleteExpense(expenseId: string, uniTypeID: string): Promise<Expenses | ErrorInfo> {
  return api
    .deleteExpenses(expenseId, uniTypeID)
    .then((response: ApiResponse<ResponseModel<Expenses>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return data.data;
        } else {
          return errorHelper(0, '', getModuleName(), (api.deleteExpenses as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.deleteExpenses as any).displayName
        );
      }
    });
}
export function createPayouts(payout: CreatePayOutDto, file: File): Promise<Payout | ErrorInfo> {
  const data = new FormData();

  data.append('asset', file);
  data.append('unitTypeId', payout.unitTypeId ? String(payout.unitTypeId) : '');
  data.append('HomeownerId', payout.landlordId ? String(payout.landlordId) : '');
  data.append('roomId', payout.roomId);
  data.append('amount', payout.amount ? String(payout.amount) : '');
  data.append('paymentDate', payout.paymentDate ? String(payout.paymentDate) : '');
  data.append('description', payout.description ? String(payout.description) : '');

  return api.createPayout(data).then((response: ApiResponse<ResponseModel<Payout>, ErrorInfo>) => {
    if (response.ok) {
      const { data } = response;
      if (data && data.data) {
        return data.data;
      } else {
        return errorHelper(0, '', getModuleName(), (api.createPayout as any).displayName);
      }
    } else {
      return errorHelper(
        Number(response.status),
        response.data && response.data.errorCode
          ? String(response.data.errorCode || '')
          : response.problem,
        getModuleName(),
        (api.createPayout as any).displayName
      );
    }
  });
}
export function deletePayout(payoutId: string): Promise<Payout | ErrorInfo> {
  return api
    .deletePayout(payoutId)
    .then((response: ApiResponse<ResponseModel<Payout>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return data.data;
        } else {
          return errorHelper(0, '', getModuleName(), (api.deletePayout as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.deletePayout as any).displayName
        );
      }
    });
}

export function addUser(userUIModel: UserUIModel): Promise<UserUIModel | ErrorInfo> {
  const mappedUser = UserMapper.mapToModel(userUIModel);
  return api
    .addUser(mappedUser)
    .then((response: ApiResponse<ResponseModel<UserModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return UserMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.addUser as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.addUser as any).displayName
        );
      }
    });
}
export function searchPayouts(payout: SearchPayOutDto): Promise<Payout[] | ErrorInfo> {
  return api
    .searchPayouts(payout)
    .then((response: ApiResponse<ResponseModel<Payout[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return data.data;
        } else {
          return errorHelper(0, '', getModuleName(), (api.searchPayouts as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.searchPayouts as any).displayName
        );
      }
    });
    
}
export function bulkUpdateUnitTypeData(
  unitTypeData: Partial<UnitTypeUiModel[]>
): Promise<BulkUpdate | ErrorInfo> {
  const mappedUnitType = unitTypeData.map((unit)=>UnitTypeMapper.mapToModel(unit || {}));
  return api
    .bulkUpdateUnitType( mappedUnitType)
    .then((response: ApiResponse<ResponseModel<BulkUpdate>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          // return data.data.map((unit)=>UnitTypeMapper.mapToUI(unit));
          return data?.data;
        } else {
          return errorHelper(0, '', getModuleName(), (api.updateUnitType as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.updateUnitType as any).displayName
        );
      }
    });
}
export function getAllBulkUpdateUnitTypeData(): Promise<BulkUpdate[] | ErrorInfo> {
  return api
    .getAllBulkUpdateUnitTypeData()
    .then((response: ApiResponse<ResponseModel<BulkUpdate[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response as any;
        if (data && data.data && data.data?.data) {
          // return data.data.map((unit)=>UnitTypeMapper.mapToUI(unit));
          return data?.data?.data;
        } else {
          return errorHelper(0, '', getModuleName(), (api.updateUnitType as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.updateUnitType as any).displayName
        );
      }
    });
}