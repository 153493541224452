import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './edit-amenity-modal.module.scss';
import { VALIDATION_REGEX } from '../../../../../shared/components/validation.config';
import Select, { ValueType } from 'react-select';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import { trackEvent } from '../../../../../shared/services/utils/tracking/tracking.service';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import {
  InputText,
  InputTextErrorMode
} from '../../../../../shared/components/core-ui/input-text/input-text.component';
import { AmenityUiModel } from '../../../../../shared/ui-models/amenity.ui-model';
import ImageUploader from '../../../../../shared/components/core-ui/image-uploader/image-uploader.component';
import { ImageType } from 'react-images-uploading';
import { AmenityChannelUiModel } from '../../../../../shared/ui-models/amenity-channel.ui-model';
import { AmenityModel } from '../../../../../shared/models/amenity.model';

export interface EditAmenityModalProps {
  data: {
    amenity: AmenityUiModel | undefined;
    cAmenityList: AmenityChannelUiModel[];
    cAmenityRoomList: AmenityChannelUiModel[];
  };
  config: {
    showModal: boolean;
  };
  eventHandlers: {
    cancelHandler: () => void;
    editAmenityHandler: (amenityId: string, amenity: AmenityUiModel) => void;
    submitImageHandler: (amenityId: string, image: ImageType) => void;
  };
}

/**
 * functional component EditAmenityModal
 * @param {EditAmenityModalProps}
 */
export const EditAmenityModal: React.FC<EditAmenityModalProps> = ({
  data,
  config,
  eventHandlers
}) => {
  const [newAmenityName, setNewAmenityName] = useState<FormElementData<string>>({
    value: '',
    isValid: false,
    changed: false
  });
  const [newAmenityImage, setNewAmenityImage] = useState<FormElementData<ImageType | null>>({
    value: null,
    isValid: false,
    changed: false
  });
  const [newAmenityChannelId, setNewAmenityChannelId] = useState<
    FormElementData<string | undefined>
  >({
    value: undefined,
    isValid: false,
    changed: false
  });
  const [newAmenityChannelRoomId, setNewAmenityChannelRoomId] = useState<
    FormElementData<string | undefined>
  >({
    value: undefined,
    isValid: false,
    changed: false
  });
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  useEffect(() => {
    setNewAmenityName({ value: data?.amenity?.name || '', isValid: false, changed: false });
    setNewAmenityChannelId({
      value: data?.amenity?.cAmenityId || undefined,
      isValid: false,
      changed: false
    });
    setNewAmenityChannelRoomId({
      value: data?.amenity?.cAmenityRoomId || undefined,
      isValid: false,
      changed: false
    });
    if (data.amenity) {
      setNewAmenityImage({
        value: { dataURL: data?.amenity?.image },
        isValid: false,
        changed: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.amenity]);

  const handleNewAmenitySubmit = () => {
    if (data.amenity && data.amenity.id) {
      let newAmenity: AmenityModel = {};
      let changeOcurred = false;
      if (
        newAmenityName.value !== '' &&
        newAmenityName.isValid &&
        newAmenityName.value !== data?.amenity?.name
      ) {
        changeOcurred = true;
        newAmenity = { ...newAmenity, name: newAmenityName.value };
      }
      if (
        newAmenityChannelId.value &&
        newAmenityChannelId.isValid &&
        newAmenityChannelId.value !== data?.amenity?.name
      ) {
        changeOcurred = true;
        newAmenity = { ...newAmenity, cAmenityId: newAmenityChannelId.value };
      }
      if (
        newAmenityChannelRoomId.value &&
        newAmenityChannelRoomId.isValid &&
        newAmenityChannelRoomId.value !== data?.amenity?.name
      ) {
        changeOcurred = true;
        newAmenity = { ...newAmenity, cAmenityRoomId: newAmenityChannelRoomId.value };
      }
      if (changeOcurred) {
        eventHandlers.editAmenityHandler(data.amenity?.id, newAmenity);
      }

      if (
        newAmenityImage.changed &&
        newAmenityImage &&
        newAmenityImage.value &&
        newAmenityImage.value.file
      ) {
        eventHandlers.submitImageHandler(data.amenity?.id, newAmenityImage.value);
      }
    }
  };

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.cancelHandler();
        }
      }}
    >
      <div className={styles['edit-amenity-modal']}>
        <div className={styles['edit-amenity-modal__title']}>
          {t('AMENITY_MODULE.LIST_CONTAINER.EDIT_AMENITY_MODAL.TITLE')}
        </div>
        <div className={styles['edit-amenity-modal__description']}>
          {t('AMENITY_MODULE.LIST_CONTAINER.EDIT_AMENITY_MODAL.NAME')}
        </div>
        <InputText
          data={{
            value: newAmenityName.value,
            errorMessage: t(
              'AMENITY_MODULE.LIST_CONTAINER.EDIT_AMENITY_MODAL.INVALID_AMENITY_NAME'
            ),
            placeholder: t('AMENITY_MODULE.LIST_CONTAINER.EDIT_AMENITY_MODAL.NAME_PLACEHOLDER')
          }}
          config={{
            maxRows: 1,
            autoFocus: true,
            validationRegex: VALIDATION_REGEX.AREA_NAME,
            showErrorMode: InputTextErrorMode.instant
          }}
          eventHandlers={{
            onChangeHandler: (value: string, isValid: boolean) => {
              setNewAmenityName({ value, isValid, changed: true });
            },
            onEnterPressedHandler: handleNewAmenitySubmit
          }}
        />
        <div className={styles['edit-amenity-modal__description']}>
          {t('AMENITY_MODULE.LIST_CONTAINER.EDIT_AMENITY_MODAL.IMAGE')}
        </div>
        <ImageUploader
          config={{
            enableCrop: true,
            canvasSize: 'sm',
            cropAspectRatio: { width: 150, height: 100 }
          }}
          data={{
            image: newAmenityImage.value
          }}
          eventHandlers={{
            fileUploadHandler: (file, width, height) => {
              trackEvent('image-sm-uploaded', {
                category: 'amenity',
                label: 'edit-amenity'
              });
              setNewAmenityImage({ value: file || null, changed: true, isValid: true });
            },
            imageRemovedHandler: () => {
              setNewAmenityImage({
                value: null,
                isValid: false,
                changed: false
              });
            }
          }}
        />
        {data && data.cAmenityList && data.cAmenityList.length > 0 && (
          <>
            {' '}
            <div className={styles['edit-amenity-modal__description']}>
              Channel Manager (Property)
            </div>
            <Select
              className={styles['edit-amenity-modal__details-container__select']}
              value={
                newAmenityChannelId.value
                  ? {
                      value: newAmenityChannelId.value,
                      label: data?.cAmenityList.find(
                        (item) => item.AmenityID === newAmenityChannelId.value
                      )?.value
                    }
                  : ''
              }
              options={data?.cAmenityList.map((amenity) => ({
                label: amenity.value,
                value: amenity.AmenityID
              }))}
              onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
                console.log(value);
                setNewAmenityChannelId({ value: value?.value, isValid: true, changed: true });
              }}
            />
          </>
        )}

        {data && data.cAmenityList && data.cAmenityList.length > 0 && (
          <>
            {' '}
            <div className={styles['edit-amenity-modal__description']}>Channel Manager (Room)</div>
            <Select
              className={styles['edit-amenity-modal__details-container__select']}
              value={
                newAmenityChannelRoomId.value
                  ? {
                      value: newAmenityChannelRoomId.value,
                      label: data?.cAmenityRoomList.find(
                        (item) => item.AmenityID === newAmenityChannelRoomId.value
                      )?.value
                    }
                  : ''
              }
              options={data?.cAmenityRoomList.map((amenity) => ({
                label: amenity.value,
                value: amenity.AmenityID
              }))}
              onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
                setNewAmenityChannelRoomId({
                  value: value?.value,
                  isValid: true,
                  changed: true
                });
              }}
            />
          </>
        )}
      </div>
      <div className={styles['edit-amenity-modal--actions']}>
        <Button
          onClick={() => {
            eventHandlers.cancelHandler();
            trackEvent('cancel', {
              category: 'amenity',
              label: 'edit-amenity-modal'
            });
          }}
          tracking={{
            action: 'cancel',
            category: 'amenity',
            label: 'edit-amenity-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          type={ButtonType.primaryInverted}
        >
          {t('AMENITY_MODULE.LIST_CONTAINER.EDIT_AMENITY_MODAL.CANCEL_BUTTON')}
        </Button>
        <Button
          disabled={
            !newAmenityName.changed &&
            !newAmenityChannelId.changed &&
            !newAmenityChannelRoomId.changed &&
            !newAmenityImage.changed
          }
          onClick={handleNewAmenitySubmit}
          tracking={{
            action: 'submit',
            category: 'amenities',
            label: 'edit-amenity-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          {t('AMENITY_MODULE.LIST_CONTAINER.EDIT_AMENITY_MODAL.SUBMIT_BUTTON')}
        </Button>
      </div>
    </Modal>
  );
};
