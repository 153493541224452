import api from '../utils/api/api.service';
import errorHelper from '../utils/api/error-helper.service';
import UserMapper from '../../mappers/user.mapper';
import { UserUIModel } from '../../ui-models/user.ui-model';
import { ErrorInfo } from '../../interfaces/error-info.interface';
import { ApiResponse } from 'apisauce';
import { ResponseModel } from '../../interfaces/response-model.interface';
import { UserModel } from '../../models/user.model';
import { UserType } from '../../enums/user-type.enum';

export function getUserByPhone(
  phoneNumber: string,
  moduleName: string
): Promise<UserUIModel | ErrorInfo> {
  // const mappedUser = UserMapper.mapToModel(userUIModel);
  return api
    .searchUser({
      phone: [phoneNumber]
    })
    .then((response: ApiResponse<ResponseModel<UserModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data && data.data.length > 0) {
          return UserMapper.mapToUI(data.data[0]);
        } else {
          return errorHelper(0, '', moduleName, (api.searchUser as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          moduleName,
          (api.searchUser as any).displayName
        );
      }
    });
}

export function getUserByEmail(
  email: string,
  moduleName: string
): Promise<UserUIModel | ErrorInfo> {
  // const mappedUser = UserMapper.mapToModel(userUIModel);
  return api
    .searchUser({
      email: [email]
    })
    .then((response: ApiResponse<ResponseModel<UserModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data && data.data.length > 0) {
          return UserMapper.mapToUI(data.data[0]);
        } else {
          return errorHelper(0, '', moduleName, (api.searchUser as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          moduleName,
          (api.searchUser as any).displayName
        );
      }
    });
}

export function searchUsers(
  queryData: {
    phone: string[];
    role: UserType[];
  },
  moduleName: string
): Promise<UserUIModel[] | ErrorInfo> {
  // const mappedUser = UserMapper.mapToModel(userUIModel);
  return api
    .searchUser(queryData)
    .then((response: ApiResponse<ResponseModel<UserModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return UserMapper.mapToUIList(data.data);
        } else {
          return errorHelper(0, '', moduleName, (api.searchUsers as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          moduleName,
          (api.searchUser as any).displayName
        );
      }
    });
}

export function updateUser(
  user: UserUIModel,
  moduleName: string
): Promise<UserUIModel | ErrorInfo> {
  return api.updateUser(user).then((response: ApiResponse<ResponseModel<UserModel>, ErrorInfo>) => {
    if (response.ok) {
      const { data } = response;
      if (data && data.data) {
        return UserMapper.mapToUI(data.data);
      } else {
        return errorHelper(0, '', moduleName, (api.updateUser as any).displayName);
      }
    } else {
      return errorHelper(
        Number(response.status),
        response.data && response.data.errorCode
          ? String(response.data.errorCode || '')
          : response.problem,
        moduleName,
        (api.updateUser as any).displayName,
        response.data?.message
      );
    }
  });
}
