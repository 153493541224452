import { RoomsModel } from '../models/unit-types.model';
import { RoomsUiModelResponse } from '../ui-models/unit-type.ui-model';

function mapToUI(roomsData: RoomsModel): RoomsUiModelResponse {
  return {
    value: roomsData.unitId,
    label: roomsData.shortName
  };
}
function mapToUIList(unitTypeRoomsList: RoomsModel[]): RoomsUiModelResponse[] {
  return unitTypeRoomsList.map(mapToUI);
}
export default {
  mapToUI,
  mapToUIList
};
