import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './edit-general-info.module.scss';
import { store } from 'react-notifications-component';
import Chip from '@material-ui/core/Chip';
import {
  InputText,
  InputTextType
} from '../../../../../shared/components/core-ui/input-text/input-text.component';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import Button, {
  ButtonSize
} from '../../../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { UnitTypeUiModel } from '../../../../../shared/ui-models/unit-type.ui-model';
import { PUBLIC_PATH } from '../../../../../shared/config/routes-config';
import { UNIT_TYPE_BEDS } from '../../../../../shared/config/cm-unit-type-beds.config';
import { UnitTypeGroupUiModel } from '../../../../../shared/ui-models/unit-type-group.ui-model';
import {
  Select as MiSelect,
  MenuItem,
  FormControl,
  makeStyles,
  Theme,
  createStyles
} from '@material-ui/core';
import Select, { ValueType } from 'react-select';
import { UnitTypeBedsUiModel } from '../../../../../shared/ui-models/unit-type-beds.ui-model';

export interface EditGeneralInfoProps {
  data: {
    unitType: UnitTypeUiModel | undefined;
    unitTypeGroupsList: UnitTypeGroupUiModel[];
  };
  config: {
    isLoading: boolean;
  };
  eventHandlers: {
    submitHandler: (data: Partial<UnitTypeUiModel>) => void;
  };
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      '& > *': {
        fontSize: '1.6rem',
        margin: theme.spacing(0.5),
        marginBottom: '1rem'
      }
    },
    formControl: {
      // margin: theme.spacing(1),

      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  })
);

/**
 * functional component EditGeneralInfo
 * @param {EditGeneralInfoProps} holding question text
 */
export const EditGeneralInfo: React.FC<EditGeneralInfoProps> = ({
  data,
  config,
  /*  */ eventHandlers
}) => {
  const [newUnitMeters, setNewUnitMeters] = useState<FormElementData<string>>({
    value: '',
    isValid: false,
    changed: false
  });
  const [newMaxGuests, setNewMaxGuests] = useState<FormElementData<number>>({
    value: 0,
    isValid: false,
    changed: false
  });
  const [newBedroomsNumber, setNewBedroomsNumber] = useState<FormElementData<number>>({
    value: 0,
    isValid: false,
    changed: false
  });

  const [newBathroomNumber, setNewBathroomsNumber] = useState<FormElementData<number>>({
    value: 0,
    isValid: false,
    changed: false
  });

  const [newViewLink, setNewViewLink] = useState<FormElementData<string>>({
    value: '',
    isValid: false,
    changed: false
  });

  const [newHouseRules, setNewHouseRules] = useState<FormElementData<string | undefined>>({
    value: undefined,
    isValid: false,
    changed: false
  });
  const [newOnboardingMessage, setNewOnboardingMessage] = useState<
    FormElementData<string | undefined>
  >({
    value: undefined,
    isValid: false,
    changed: false
  });

  const [newDescription, setNewDescription] = useState<FormElementData<string | undefined>>({
    value: undefined,
    isValid: false,
    changed: false
  });

  const [newName, setNewName] = useState<FormElementData<string | undefined>>({
    value: undefined,
    isValid: false,
    changed: false
  });
  const [newUnitTypeGroup, setNewUnitTypeGroup] = useState<FormElementData<string>>({
    value: '',
    isValid: false,
    changed: false
  });
  const [newBeds, setNewBeds] = useState<UnitTypeBedsUiModel[]>([]);
  const [newBedsChanged, setNewBedsChanged] = useState<boolean>(false);
  const classes = useStyles();

  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  useEffect(() => {
    return () => {
      store.removeNotification('general-info-failed');
      store.removeNotification('general-info-success');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNewUnitMeters({
      value: data.unitType?.unitMeters || '',
      isValid: false,
      changed: false
    });
    setNewBedroomsNumber({
      value: data.unitType?.bedrooms || 0,
      isValid: false,
      changed: false
    });
    if (data.unitType?.beds) {
      setNewBeds(data.unitType?.beds);
    } else {
      const newBedsArr: UnitTypeBedsUiModel[] = [];
      for (
        let i = 0;
        i < (data && data.unitType && data.unitType.bedrooms ? data.unitType.bedrooms : 0);
        i++
      ) {
        newBedsArr[i] = {
          roomName: `Bedroom ${i + 1}`,
          bedsCount: []
        };
      }
      setNewBeds(newBedsArr);
    }
    setNewBathroomsNumber({
      value: data.unitType?.bathrooms || 0,
      isValid: false,
      changed: false
    });
    setNewViewLink({
      value: data.unitType?.viewLink || '',
      isValid: false,
      changed: false
    });
    setNewMaxGuests({
      value: data.unitType?.maxGuests || 0,
      isValid: false,
      changed: false
    });
    setNewName({
      value: data.unitType?.name || '',
      isValid: false,
      changed: false
    });
    setNewUnitTypeGroup({
      value: data.unitType?.unitTypeGroupId || '',
      isValid: false,
      changed: false
    });
    if (data.unitType) {
      setNewDescription({
        value: data.unitType.description || '',
        isValid: false,
        changed: false
      });
      setNewHouseRules({
        value: data.unitType.houseRules || '',
        isValid: false,
        changed: false
      });
      setNewOnboardingMessage({
        value: data.unitType.onboardingMessage || '',
        isValid: false,
        changed: false
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.unitType]);

  const handleGeneralInfoSubmit = () => {
    const updatedData: {
      name?: string;
      description?: string;
      maxGuests?: number;
      unitMeters?: string;
      beds?: UnitTypeBedsUiModel[];
      bedrooms?: number;
      bathrooms?: number;
      viewLink?: string;
      houseRules?: string;
      unitTypeGroupId?: string;
      onboardingMessage?: string;
    } = {};
    if (newUnitMeters.changed && newUnitMeters.value) {
      updatedData['unitMeters'] = newUnitMeters.value;
    }
    if (newBedroomsNumber.changed && newBedroomsNumber.value) {
      updatedData['bedrooms'] = newBedroomsNumber.value;
    }
    if (newBedsChanged) {
      updatedData['beds'] = newBeds;
    }
    if (newBathroomNumber.changed && newBathroomNumber.value) {
      updatedData['bathrooms'] = newBathroomNumber.value;
    }
    if (newHouseRules.changed && newHouseRules.value) {
      updatedData['houseRules'] = newHouseRules.value;
    }
    if (newOnboardingMessage.changed && newOnboardingMessage.value) {
      updatedData['onboardingMessage'] = newOnboardingMessage.value;
    }
    if (newViewLink.changed && newViewLink.value) {
      updatedData['viewLink'] = newViewLink.value;
    }
    if (newName.changed && newName.value) {
      updatedData['name'] = newName.value;
    }
    if (newDescription.changed && newDescription.value) {
      updatedData['description'] = newDescription.value;
    }
    if (newMaxGuests.changed && newMaxGuests.value) {
      updatedData['maxGuests'] = newMaxGuests.value;
    }
    if (newUnitTypeGroup.changed && newUnitTypeGroup.value) {
      updatedData['unitTypeGroupId'] = newUnitTypeGroup.value;
    }
    eventHandlers.submitHandler(updatedData);
  };

  return (
    <div className={styles['general-info-container']}>
      {config.isLoading ? (
        <div className={styles['general-info-container__loader']}>
          <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
        </div>
      ) : (
        <>
          <div className={styles['general-info-container__sub-title']}>
            {t('UNIT_TYPE_MODULE.EDIT_GENERAL_CONTAINER.TITLE')}
          </div>
          <div className={styles['general-info-container__wrapper']}>
            {t('UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.UNIT_TYPE_NAME')}
            <div className={styles['general-info-container__field']}>
              <InputText
                config={{
                  autoFocus: false,
                  type: InputTextType.text,
                  // minLength: 6,
                  required: true,
                  disabled: false
                }}
                data={{
                  placeholder: t(
                    'UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.UNIT_TYPE_NAME_PLACEHOLDER'
                  ),
                  errorMessage: t(
                    'AUTH_MODULE.RESET_PASSWORD_CONTAINER.UNIT_TYPE_NAME_ERROR_MESSAGE'
                  ),
                  value: newName.value || ''
                }}
                eventHandlers={{
                  onChangeHandler: (value, isValid) => {
                    if (isValid) {
                      setNewName({
                        value,
                        isValid: true,
                        changed: true
                      });
                    }
                  }
                }}
              />
            </div>
            {newDescription.value !== undefined && (
              <>
                {t('UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.UNIT_TYPE_DESCRIPTION')}
                <div className={styles['general-info-container__field']}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={newDescription.value}
                    onReady={(editor: any) => {
                      // You can store the "editor" and use when it is needed.
                      // console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event: any, editor: any) => {
                      const newData: string = editor.getData();
                      setNewDescription({ value: newData, isValid: true, changed: true });
                    }}

                    // onBlur={(event, editor) => {
                    //   console.log('Blur.', editor);
                    // }}
                    // onFocus={(event, editor) => {
                    //   console.log('Focus.', editor);
                    // }}
                  />
                </div>
              </>
            )}
            {t('UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.NUMBER_OF_GUESTS')}
            <div className={styles['general-info-container__field']}>
              <InputText
                config={{
                  autoFocus: false,
                  type: InputTextType.number
                }}
                data={{
                  placeholder: t(
                    'UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.NUMBER_OF_GUESTS_PLACEHOLDER'
                  ),
                  errorMessage: t(
                    'AUTH_MODULE.RESET_PASSWORD_CONTAINER.UNIT_TYPE_NAME_ERROR_MESSAGE'
                  ),
                  value: newMaxGuests.value || 0
                }}
                eventHandlers={{
                  onChangeHandler: (value, isValid) => {
                    if (isValid) {
                      setNewMaxGuests({
                        value: Number(value),
                        isValid: true,
                        changed: true
                      });
                    }
                  }
                }}
              />
            </div>
            Accommodation Type
            <div className={styles['general-info-container__field']}>
              <FormControl variant="outlined" className={classes.formControl}>
                <MiSelect
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={newUnitTypeGroup.value}
                  onChange={(
                    event: React.ChangeEvent<{
                      name?: string | undefined;
                      value: unknown;
                    }>
                  ) => {
                    setNewUnitTypeGroup({
                      value: event.target.value as string,
                      isValid: true,
                      changed: true
                    });
                  }}
                >
                  {data.unitTypeGroupsList.map((unitGroup, index) => (
                    <MenuItem value={unitGroup.id} key={index}>
                      {unitGroup.name}
                    </MenuItem>
                  ))}
                </MiSelect>
              </FormControl>
            </div>
            {t('UNIT_TYPE_MODULE.EDIT_GENERAL_CONTAINER.UNIT_METERS')}
            <div className={styles['general-info-container__field']}>
              <InputText
                config={{
                  autoFocus: false,
                  type: InputTextType.text
                  // minLength: 6,
                  // required: true
                }}
                data={{
                  placeholder: t('UNIT_TYPE_MODULE.EDIT_GENERAL_CONTAINER.UNIT_METERS_PLACEHOLDER'),
                  // errorMessage: t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.PROPERTY_NAME_ERROR_MESSAGE'),
                  value: newUnitMeters.value
                }}
                eventHandlers={{
                  onChangeHandler: (value, isValid) => {
                    if (isValid) {
                      setNewUnitMeters({
                        value,
                        isValid: true,
                        changed: true
                      });
                    }
                  }
                }}
              />
            </div>
            {t('UNIT_TYPE_MODULE.EDIT_GENERAL_CONTAINER.BEDROOMS_NUMBERS')}
            <div className={styles['general-info-container__field']}>
              <InputText
                config={{
                  autoFocus: false,
                  type: InputTextType.number
                  // minLength: 6,
                  // required: true
                }}
                data={{
                  placeholder: t(
                    'UNIT_TYPE_MODULE.EDIT_GENERAL_CONTAINER.SECURITY_DEPOSIT_PLACEHOLDER'
                  ),
                  // errorMessage: t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.PROPERTY_NAME_ERROR_MESSAGE'),
                  value: newBedroomsNumber.value
                }}
                eventHandlers={{
                  onChangeHandler: (value, isValid) => {
                    if (isValid) {
                      setNewBedroomsNumber({
                        value: Number(value),
                        isValid: true,
                        changed: true
                      });
                      const newBedsArr: UnitTypeBedsUiModel[] = [];
                      for (let i = 0; i < Number(value); i++) {
                        if (i < newBeds.length) {
                          newBedsArr[i] = { ...newBeds[i] };
                        } else {
                          newBedsArr[i] = {
                            roomName: `Bedroom ${i + 1}`,
                            bedsCount: []
                          };
                        }
                      }
                      setNewBeds(newBedsArr);
                      setNewBedsChanged(true);
                    }
                  }
                }}
              />
            </div>
            {newBedroomsNumber.value > 0 && (
              <div className={styles['general-info-container__beds']}>
                <b>Beds in Rooms</b>
                {newBeds &&
                  newBeds.map((roomsBed, index) => (
                    <div className={styles['general-info-container__beds__room']}>
                      {roomsBed.roomName}
                      <div className={classes.root}>
                        {newBeds[index].bedsCount.length === 0 ? (
                          <p className={styles['general-info-container__beds__room__message']}>
                            No Beds selected for this room yet!
                          </p>
                        ) : (
                          newBeds[index].bedsCount.map((item, innerIndex) => {
                            const foundItem = UNIT_TYPE_BEDS.find((bed) => bed.value === item.id);
                            const elementToRender = [];
                            for (let i = 0; i < item.count; i++) {
                              elementToRender.push(
                                <Chip
                                  label={foundItem?.label}
                                  color="primary"
                                  onDelete={() => {
                                    const updatedNewBeds = [...newBeds];
                                    console.log('before', updatedNewBeds);
                                    if (updatedNewBeds[index].bedsCount[innerIndex].count > 1) {
                                      updatedNewBeds[index].bedsCount[innerIndex].count =
                                        updatedNewBeds[index].bedsCount[innerIndex].count - 1;
                                    } else {
                                      updatedNewBeds[index].bedsCount.splice(innerIndex, 1);
                                    }
                                    console.log('after', updatedNewBeds);
                                    setNewBeds(updatedNewBeds);
                                    setNewBedsChanged(true);
                                  }}
                                />
                              );
                            }
                            return elementToRender;
                          })
                        )}
                      </div>

                      <Select
                        hideSelectedOptions={false}
                        options={UNIT_TYPE_BEDS}
                        // value={newBeds[index].bedsCount.map((item) => {
                        //   const foundItem = UNIT_TYPE_BEDS.find((bed) => bed.value === item.id);
                        //   console.log(foundItem);
                        //   return foundItem;
                        // })}
                        onChange={(
                          value: ValueType<{ value: string | undefined; label: string }, false>
                        ) => {
                          const updatedNewBeds = [...newBeds];
                          console.log(value);
                          const foundBedCountIndex = updatedNewBeds[index].bedsCount.findIndex(
                            (item) => value.value === item.id
                          );
                          if (foundBedCountIndex > -1) {
                            const updatedBedsCount = [...updatedNewBeds[index].bedsCount];
                            updatedBedsCount[foundBedCountIndex].count =
                              updatedBedsCount[foundBedCountIndex].count + 1;
                            updatedNewBeds[index].bedsCount = updatedBedsCount;
                          } else {
                            updatedNewBeds[index] = {
                              ...updatedNewBeds[index],
                              bedsCount: [
                                ...updatedNewBeds[index].bedsCount,
                                {
                                  id: value.value,
                                  count: 1
                                }
                              ]
                            };
                          }
                          setNewBeds(updatedNewBeds);
                          setNewBedsChanged(true);
                        }}
                      />
                    </div>
                  ))}
              </div>
            )}
            {t('UNIT_TYPE_MODULE.EDIT_GENERAL_CONTAINER.BATHROOMS_NUMBERS')}
            <div className={styles['general-info-container__field']}>
              <InputText
                config={{
                  autoFocus: false,
                  type: InputTextType.number
                  // minLength: 6,
                  // required: true
                }}
                data={{
                  placeholder: t(
                    'UNIT_TYPE_MODULE.EDIT_GENERAL_CONTAINER.SECURITY_DEPOSIT_PLACEHOLDER'
                  ),
                  // errorMessage: t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.PROPERTY_NAME_ERROR_MESSAGE'),
                  value: newBathroomNumber.value
                }}
                eventHandlers={{
                  onChangeHandler: (value, isValid) => {
                    if (isValid) {
                      setNewBathroomsNumber({
                        value: Number(value),
                        isValid: true,
                        changed: true
                      });
                    }
                  }
                }}
              />
            </div>
            {newHouseRules.value !== undefined && (
              <>
                {t('UNIT_TYPE_MODULE.EDIT_GENERAL_CONTAINER.HOUSE_RULES')}
                <div className={styles['general-info-container__field']}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={newHouseRules.value}
                    onReady={(editor: any) => {
                      // You can store the "editor" and use when it is needed.
                      // console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event: any, editor: any) => {
                      const newData: string = editor.getData();
                      setNewHouseRules({ value: newData, isValid: true, changed: true });
                    }}
                  />
                </div>
              </>
            )}
            {newOnboardingMessage.value !== undefined && (
              <>
                {t('UNIT_TYPE_MODULE.EDIT_GENERAL_CONTAINER.ONBOARDING')}
                <div className={styles['general-info-container__field']}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={newOnboardingMessage.value}
                    onReady={(editor: any) => {
                      // You can store the "editor" and use when it is needed.
                      // console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event: any, editor: any) => {
                      const newData: string = editor.getData();
                      setNewOnboardingMessage({ value: newData, isValid: true, changed: true });
                    }}
                  />
                </div>
              </>
            )}
            <div className={styles['general-info-container__actions']}>
              <Button
                disabled={
                  (newBedroomsNumber.value === 0 || !newBedroomsNumber.changed) &&
                  (newUnitMeters.value === '' || !newUnitMeters.changed) &&
                  (newBathroomNumber.value === 0 || !newBathroomNumber.changed) &&
                  (newHouseRules.value === '' || !newHouseRules.changed) &&
                  (newOnboardingMessage.value === '' || !newOnboardingMessage.changed) &&
                  (newViewLink.value === '' || !newViewLink.changed) &&
                  (newName.value === '' || !newName.changed) &&
                  (newDescription.value === '' || !newDescription.changed) &&
                  (newMaxGuests.value === 0 || !newMaxGuests.changed) &&
                  (newUnitTypeGroup.value === '' || !newUnitTypeGroup.changed) &&
                  !newBedsChanged
                }
                onClick={handleGeneralInfoSubmit}
                tracking={{
                  action: 'submit',
                  category: 'unit-type',
                  label: 'edit-general-info'
                }}
                size={isMobileView ? ButtonSize.full : ButtonSize.medium}
              >
                {t('UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.UPDATE_GENERAL_INFO')}
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default EditGeneralInfo;
