import React, { useContext, useEffect, useRef, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import { withVisibility } from './collapsible-button-chart';
import { getOccupancyAndAverageDailyRate } from '../shared/services/data.service';
import { isErrorInfo } from '../../shared/interfaces/error-info.interface';
import { KPIChartsSearch, OccupancyAndAverageDailyRate } from '../../shared/models/kpis.model';
import { OccupancyAndAverageDailyRateDataContext } from '../shared/services/OccupancyAndAverageDailyRate-provider';

const OccupancyRateChart: React.FC <{query: KPIChartsSearch }> = ({query}) => {
  const data = useContext<OccupancyAndAverageDailyRate[] | null>(OccupancyAndAverageDailyRateDataContext);

  const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
    chart: {
      type: 'spline',
      marginRight: 10
    },
    title: {
      text: 'occupancy rate',
      align: 'left'
    },
    xAxis: {
      type: 'linear',
      categories: [
        'Jan',
        'Feb',
        'March',
        'Apr',
        'May',
        'June',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ]
    },
    yAxis: {
      type: 'linear'
    },
    tooltip: {},
    series: [
      {
        name: 'Occupancy',
        data: []
      }
    ],
    plotOptions: {
      series: {
        color: '#f38831',
        marker: {
          enabled: false
        }
      }
    }
  });
  const chartComponentRef = useRef(null);
  
  const getOccupancyAndAverageDailyRates = (response: OccupancyAndAverageDailyRate[]) => {
    chartComponentRef?.current?.chart?.hideLoading();
    const categories = response.map((revenue) => revenue.month.slice(0, 3).toUpperCase());
    const egpMonthsRevenue = response.map((revenue) => revenue.rate * 100);
    const chartUpdatedOptions = {
      ...chartOptions,
      xAxis: {
        ...chartOptions.xAxis,
        categories
      },
      series: [{ ...chartOptions.series[0], data: egpMonthsRevenue }]
    };
    setChartOptions(chartUpdatedOptions);
  };

  useEffect(() => {
    if(data) {
      getOccupancyAndAverageDailyRates(data);
    } else {
      chartComponentRef?.current?.chart?.showLoading();

    }
  }, [data]);
  return <HighchartsReact ref={chartComponentRef} highcharts={Highcharts} options={chartOptions} />;
};

export default withVisibility(OccupancyRateChart);
