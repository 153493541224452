import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import EdiText from 'react-editext';
import { useHistory } from 'react-router';
import styles from './user-search.module.scss';
import { store } from 'react-notifications-component';
import SearchIcon from '@material-ui/icons/Search';
import EditIcon from '@material-ui/icons/Edit';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Countdown from 'react-countdown';
import BathtubIcon from '@material-ui/icons/Bathtub';
import ArrowForward from '@material-ui/icons/ArrowForward';
import WarningIcon from '@material-ui/icons/Warning';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import { Button as MIButton, Menu, MenuItem } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { ErrorInfo, isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { RootState } from '../../../shared/redux/reducers';
import PhoneInput from 'react-phone-input-2';
import { trackPage, trackEvent } from '../../../shared/services/utils/tracking/tracking.service';
import { getModuleName } from '../../shared/services/user.service';
import { PUBLIC_PATH, APP_ROUTES } from '../../../shared/config/routes-config';
import useWindowResizer from '../../../shared/hooks/window-resizer/window-resizer.hook';
import { ImageType } from 'react-images-uploading';
import { AreaModel } from '../../../shared/models/area.model';
import { AddUserModal } from './components/add-user-modal/add-user-modal.component';
import { FormElementData } from '../../../shared/interfaces/form-element-data.interface';
import {
  getUserByPhone,
  updateUser,
  getUserByEmail
} from '../../../shared/services/data/user.data';
import { UserUIModel } from '../../../shared/ui-models/user.ui-model';
import Button, { ButtonSize } from '../../../shared/components/core-ui/button/button.component';
import { GenderPickerComponent } from '../../../shared/components/core-ui/gender-picker/gender-picker.component';
import { ReservationUiModel } from '../../../shared/ui-models/reservation.ui-model';
import { BookingRequestUiModel } from '../../../shared/ui-models/booking-request.ui-model';
import { ReservationStatus } from '../../../shared/enums/reservation-status';
import { BookingRequestStatus } from '../../../shared/enums/booking-request-status.enum';
import { getUserReservations, getUserBookingRequests, getUserLockStatus, unlockUser } from '../../shared/services/data.service';
import { getBookingRequests } from '../../../BookingModule/shared/services/data.service';
import { searchInUnitTypes } from '../../../shared/services/data/unit-type.data';
import { UnitTypeUiModel } from '../../../shared/ui-models/unit-type.ui-model';
import { ReservationMode } from '../../../shared/enums/reservation-mode.enum';
import {
  InputText,
  InputTextType
} from '../../../shared/components/core-ui/input-text/input-text.component';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
    }
  }
});
const useSelectedRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
      // backgroundColor: '#ffa358'
    }
  }
});

export type UserSearchContainerProps = ReturnType<typeof mapStateToProps>;

/**
 * functional component UserSearchContainer
 * @param {UserSearchContainerProps} holding question text
 */
export const UserSearchContainer: React.FC<UserSearchContainerProps> = ({ isAuthenticated }) => {
  const [user, setUser] = useState<UserUIModel>();
  const [isUserLocker, setIsUserLocker] = useState<boolean>();
  const [emailValue, setEmailValue] = useState<UserUIModel>();
  const [searchNumber, setSearchNumber] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [searchEmail, setSearchEmail] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [userReservations, setUserReservations] = useState<ReservationUiModel[]>();
  const [userBookingRequests, setUserBookingRequests] = useState<BookingRequestUiModel[]>();
  const [userUnits, setUserUnits] = useState<UnitTypeUiModel[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingReservations, setIsLoadingReservations] = useState<boolean>(false);
  const [isLoadingBookings, setIsLoadingBookings] = useState<boolean>(false);
  const [isLoadingUnits, setIsLoadingUnits] = useState<boolean>(false);
  const [showNotFound, setShowNotFound] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();
  const history = useHistory();

  useEffect(() => {
    trackPage('user-search');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const counterRenderer = ({
    hours,
    minutes,
    seconds
  }: {
    hours: number;
    minutes: number;
    seconds: number;
  }) => {
    // Render a countdown
    return (
      <b className={styles['user-search-container__reservation-item__status--highlighted']}>
        {hours}:{minutes}:{seconds}
      </b>
    );
  };
  function getLockStatus (userId: string) {
    getUserLockStatus(userId).then((response)=>{
      if(!isErrorInfo(response)){
        setIsUserLocker(response.isEmailLocked);
      }
    });
  }
  function unlockUserEmail(userId: string) {
    unlockUser(userId).then((response)=>{
      if(!isErrorInfo(response)){
        store.addNotification({
          id: 'unlock-user-success',
          title: 'successfully unlocked user',
          message: 'user now can login again',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut']
        });
        getLockStatus(userId);
      }
    });
  }
  function ReservationRow(props: { reservation: ReservationUiModel }) {
    const { reservation } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const selectedClasses = useSelectedRowStyles();

    return (
      <>
        <TableRow
          className={
            reservation.status === ReservationStatus.CHECKED_IN_STARTED
              ? selectedClasses.root
              : classes.root
          }
          onClick={() => {
            history.push(
              (APP_ROUTES.RESERVATION_MODULE.CHILDREN as RouteConfig).EDIT.FULL_PATH.replace(
                ':id',
                reservation?.id as string
              )
            );
          }}
        >
          <TableCell component="th" scope="row">
            {reservation.id}
          </TableCell>
          <TableCell>{reservation.unitName}</TableCell>
          <TableCell>
            <div
              className={`${styles['user-search-container__reservation-item__status']} ${
                styles[`user-search-container__reservation-item__status--${reservation?.status}`]
              }`}
            >
              {reservation.status ? reservation.status.replace('_', ' ') : ''}{' '}
              {reservation.status === ReservationStatus.CONFIRMATION_PENDING ||
              reservation.status === ReservationStatus.CHECKED_IN_STARTED ? (
                <Countdown
                  date={
                    new Date(
                      new Date(Number(reservation.checkinExpiresAt)).getTime() + 60 * 60 * 24 * 1000
                    )
                  }
                  renderer={counterRenderer}
                />
              ) : (
                ''
              )}
            </div>
            {reservation.guestIds.length === 0 && (
              <div
                className={`${styles['user-search-container__reservation-item__status']} ${
                  styles[`user-search-container__reservation-item__status--canceled`]
                }`}
              >
                {reservation.guestIds.length === 0 ? 'Guest Data incomplete' : ''}
              </div>
            )}
          </TableCell>
          <TableCell>{new Date(reservation.checkInDate).toDateString()}</TableCell>
          <TableCell>{new Date(reservation.checkOutDate).toDateString()}</TableCell>
        </TableRow>
        <TableRow></TableRow>
      </>
    );
  }
  function BookingRow(props: { reservation: BookingRequestUiModel }) {
    const { reservation } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const selectedClasses = useSelectedRowStyles();

    return (
      <>
        <TableRow
          className={
            reservation.status === BookingRequestStatus.IN_SCREENING
              ? selectedClasses.root
              : classes.root
          }
        >
          {/* <TableCell>{reservation.guestInfo.name}</TableCell> */}

          <TableCell>
            <div
              className={`${styles['user-search-container__reservation-item__status']} ${
                styles[`user-search-container__reservation-item__status--${reservation?.status}`]
              }`}
            >
              {reservation.status ? reservation.status.replace('_', ' ') : ''}{' '}
              {reservation.status === BookingRequestStatus.IN_SCREENING ? (
                <Countdown
                  date={
                    new Date(new Date(Number(reservation.bookedAt)).getTime() + 60 * 60 * 24 * 1000)
                  }
                  renderer={counterRenderer}
                />
              ) : (
                ''
              )}
            </div>
          </TableCell>
          <TableCell>
            {new Date(Number(reservation.bookingInfo.checkInDate)).toDateString()}
          </TableCell>
          <TableCell>
            {new Date(Number(reservation.bookingInfo.checkOutDate)).toDateString()}
          </TableCell>
          <TableCell component="th" scope="row">
            {reservation.requestId}
          </TableCell>
        </TableRow>
        <TableRow></TableRow>
      </>
    );
  }

  function updateUserData(userData: Partial<UserUIModel>) {
    const userDataWithID: UserUIModel = { ...userData, id: user?.id };
    updateUser(userDataWithID).then((response: UserUIModel | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setUser(response);
      } else {
        setUser({ ...user });
        setEmailValue(user?.email);
        if (response.status === 403) {
          store.addNotification({
            id: 'update-user-failed',
            title: 'Permission not allowed',
            message: "Seems like you don't have the right permissions to update users",
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        } else if (response.status === 400) {
          store.addNotification({
            id: 'update-user-failed',
            title: 'Invalid data entered!',
            message: response.errorMessage || 'please try again later, or contact administrator',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        } else {
          store.addNotification({
            id: 'update-user-failed',
            title: 'Something went wrong',
            message:
              response.message ||
              response.errorMessage ||
              'please try again later, or contact administrator',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        }
      }
    });
  }

  const handleGetUserByPhoneNumber = () => {
    if (searchNumber && searchNumber.value !== '') {
      setIsLoading(true);
      setUserReservations([]);
      setUserBookingRequests([]);
      setUserUnits([]);
      getUserByPhone(searchNumber.value, getModuleName()).then(
        (response: UserUIModel | ErrorInfo) => {
          setIsLoading(false);
          if (!isErrorInfo(response)) {
            getLockStatus(response.id as string);
            setShowNotFound(false);
            setUser(response);
            setEmailValue(response.email);
            setIsLoadingReservations(true);
            getUserReservations(response.id).then((response: ReservationUiModel[] | ErrorInfo) => {
              setIsLoadingReservations(false);
              if (!isErrorInfo(response)) {
                setUserReservations(response);
              }
            });
            setIsLoadingBookings(true);
            getUserBookingRequests(response.id).then(
              (response: BookingRequestUiModel[] | ErrorInfo) => {
                setIsLoadingBookings(false);
                if (!isErrorInfo(response)) {
                  setUserBookingRequests(response);
                }
              }
            );
            setIsLoadingUnits(true);
            searchInUnitTypes(getModuleName(), { landlordId: response.id }).then(
              (response: UnitTypeUiModel[] | ErrorInfo) => {
                setIsLoadingUnits(false);
                if (!isErrorInfo(response)) {
                  setUserUnits(response);
                }
              }
            );
          } else {
            if (response.status === 404) {
              setShowNotFound(true);
            }
            setUser(undefined);
          }
        }
      );
    }
  };
  

  const handleGetUserByEmail = () => {
    if (searchEmail && searchEmail.value !== '') {
      setIsLoading(true);
      setUserReservations([]);
      setUserBookingRequests([]);
      setUserUnits([]);
      getUserByEmail(searchEmail.value, getModuleName()).then(
        (response: UserUIModel | ErrorInfo) => {
          setIsLoading(false);
          if (!isErrorInfo(response)) {
            setShowNotFound(false);
            getLockStatus(response.id as string);
            setUser(response);
            setEmailValue(response.email);
            setIsLoadingReservations(true);
            getUserReservations(response.id).then((response: ReservationUiModel[] | ErrorInfo) => {
              setIsLoadingReservations(false);
              if (!isErrorInfo(response)) {
                setUserReservations(response);
              }
            });
            setIsLoadingBookings(true);
            getUserBookingRequests(response.id).then(
              (response: BookingRequestUiModel[] | ErrorInfo) => {
                setIsLoadingBookings(false);
                if (!isErrorInfo(response)) {
                  setUserBookingRequests(response);
                }
              }
            );
            setIsLoadingUnits(true);
            searchInUnitTypes(getModuleName(), { landlordId: response.id }).then(
              (response: UnitTypeUiModel[] | ErrorInfo) => {
                setIsLoadingUnits(false);
                if (!isErrorInfo(response)) {
                  setUserUnits(response);
                }
              }
            );
          } else {
            if (response.status === 404) {
              setShowNotFound(true);
            }
            setUser(undefined);
            setEmailValue(undefined);
          }
        }
      );
    }
  };

  return (
    <div className={styles['user-search-container']}>
      <div className={styles['user-search-container__title']}>
        {t('USER_MODULE.SEARCH_CONTAINER.TITLE')}
      </div>
      <div className={styles['user-search-container__search']}>
        <PhoneInput
          country={'eg'}
          inputProps={{
            name: 'Search by phone number',
            required: true
          }}
          value={searchNumber.value}
          onChange={(phone) => {
            phone
              ? setSearchNumber({ value: `+${phone}`, isValid: true })
              : setSearchNumber({ value: `${phone}`, isValid: false });
          }}
        />
        <Button
          className={styles['user-search-container__search__btn']}
          onClick={() => {
            handleGetUserByPhoneNumber();
          }}
          loading={isLoading}
          tracking={{
            action: 'search',
            category: 'user',
            label: 'search-user'
          }}
          size={ButtonSize.medium}
        >
          <SearchIcon></SearchIcon>
        </Button>
      </div>
      <div className={styles['user-search-container__search']}>OR</div>
      <div className={styles['user-search-container__search']}>
        <InputText
          data={{
            placeholder: 'Search by email address',
            value: searchEmail.value
          }}
          config={{
            type: InputTextType.email
          }}
          eventHandlers={{
            onChangeHandler: (value, isValid) => {
              if (isValid) {
                setSearchEmail({ value, isValid });
              }
            }
          }}
        />
        <Button
          className={styles['user-search-container__search__btn']}
          onClick={() => {
            handleGetUserByEmail();
          }}
          loading={isLoading}
          tracking={{
            action: 'search',
            category: 'user',
            label: 'search-user'
          }}
          size={ButtonSize.medium}
        >
          <SearchIcon></SearchIcon>
        </Button>
      </div>
      {!showNotFound && !user && (
        <div className={styles['user-search-container__content']}>
          <div className={styles['user-search-container__content__illustration']}>
            <img
              src={`${PUBLIC_PATH}/assets/jpeg/pointing-up.webp`}
              className={styles['user-search-container__content__illustration__image']}
              alt="no-data"
            />
            Type phone number to search or Add new
          </div>
        </div>
      )}
      {showNotFound && (
        <div className={styles['user-search-container__content']}>
          <div className={styles['user-search-container__content__illustration']}>
            <img
              src={`${PUBLIC_PATH}/assets/jpeg/no-results-found.jpg`}
              className={styles['user-search-container__content__illustration__image']}
              alt="no-data"
            />
            Phone number not in our records, click below to create user
            <br />
            <Button
              className={styles['user-search-container__search__btn']}
              onClick={() => {
                setShowAddModal(true);
              }}
              tracking={{
                action: 'add',
                category: 'user',
                label: 'add-user'
              }}
              size={ButtonSize.medium}
            >
              Add User
            </Button>
          </div>
        </div>
      )}
      {user && (
        <div className={styles['user-search-container__details-wrapper']}>
          <div className={styles['user-search-container__details-wrapper__user-details']}>
            <div className={styles['user-search-container__content__title']}>Account details</div>
            <div className={styles['user-search-container__details-wrapper__user-details__guest']}>
              <div
                className={
                  styles['user-search-container__details-wrapper__user-details__guest__label']
                }
              >
                # ID
              </div>
              <div
                className={
                  styles['user-search-container__details-wrapper__user-details__guest__value']
                }
              >
                {user.id}
              </div>
            </div>
            <div className={styles['user-search-container__details-wrapper__user-details__guest']}>
              <div
                className={
                  styles['user-search-container__details-wrapper__user-details__guest__label']
                }
              >
                Name
              </div>
              <div
                className={
                  styles['user-search-container__details-wrapper__user-details__guest__value']
                }
              >
                <EdiText
                  type="text"
                  value={user.name}
                  onSave={(val) => {
                    updateUserData({ name: val });
                  }}
                />
              </div>
            </div>
            <div className={styles['user-search-container__details-wrapper__user-details__guest']}>
              <div
                className={
                  styles['user-search-container__details-wrapper__user-details__guest__label']
                }
              >
                Phone number
              </div>
              <div
                className={
                  styles['user-search-container__details-wrapper__user-details__guest__value']
                }
              >
                <EdiText
                  type="text"
                  value={user.phone || ''}
                  onSave={(val) => {
                    updateUserData({ phone: val });
                  }}
                />
              </div>
            </div>
            <div className={styles['user-search-container__details-wrapper__user-details__guest']}>
              <div
                className={
                  styles['user-search-container__details-wrapper__user-details__guest__label']
                }
              >
                Role
              </div>
              <div
                className={
                  styles['user-search-container__details-wrapper__user-details__guest__value']
                }
              >
                {user.role}
              </div>
            </div>
            <div className={styles['user-search-container__details-wrapper__user-details__guest']}>
              <div
                className={
                  styles['user-search-container__details-wrapper__user-details__guest__label']
                }
              >
                Email
              </div>
              <div
                className={
                  styles['user-search-container__details-wrapper__user-details__guest__value']
                }
              >
                <EdiText
                  type="text"
                  value={emailValue}
                  onSave={(val) => {
                    updateUserData({ email: val });
                    setEmailValue(val);
                  }}
                />
                {/* {user.email} */}
              </div>
            </div>
            <div>
              <div
                className={
                  styles['user-search-container__details-wrapper__user-details__guest__label']
                }
              >
                Gender
              </div>
              <div
                className={
                  styles['user-search-container__details-wrapper__user-details__guest__value']
                }
              >
                <GenderPickerComponent
                  data={{ selectedItemValue: user.gender }}
                  config={{ hideMixed: true }}
                  eventHandlers={{
                    itemSelectedHandler: (genderChanged) => {
                      updateUserData({ gender: genderChanged });
                    }
                  }}
                />
              </div>
            </div>
            <div>
              <div
                className={
                  styles['user-search-container__details-wrapper__user-details__guest__label']
                }
              >
                Account Status
              </div>
              <div
                className={
                  styles['user-search-container__details-wrapper__user-details__guest__value']
                }
              >
                {isUserLocker == false ? <LockOpenIcon /> : <></>}
                {isUserLocker == true ? (
                  <div  className={
                    styles['user-search-container__unlock']
                  }>
                    <LockIcon/>
                    <Button
                      onClick={() => {
                        unlockUserEmail(user.id as string);
                      }}
                      tracking={{
                        action: 'unlock',
                        category: 'user',
                        label: 'unlock-user'
                      }}
                      size={ButtonSize.medium}
                    >
                      unlock
                    </Button>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          {userUnits && userUnits.length > 0 && (
            <div className={styles['user-search-container__details-wrapper__user-details']}>
              <div className={styles['user-search-container__content__title']}>
                Homeowner Units
                {` ${userUnits && userUnits.length ? '(' + userUnits.length + ')' : ''}`}
              </div>
              {isLoadingUnits ? (
                <div className={styles['user-search-container__loader']}>
                  <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
                </div>
              ) : (
                <div className={styles['user-search-container__table']}>
                  {userUnits &&
                    userUnits.map((unitType) => (
                      <div
                        className={`${styles['user-search-container__unit-type-item']} ${
                          unitType.isListed
                            ? styles['user-search-container__unit-type-item--published']
                            : ''
                        }`}
                      >
                        <div
                          className={styles['user-search-container__unit-type-item__image-wrapper']}
                        >
                          <img
                            className={`${
                              styles['user-search-container__unit-type-item__image-wrapper__image']
                            } ${
                              unitType.isListed
                                ? ''
                                : styles[
                                    'user-search-container__unit-type-item__image-wrapper__image--unpublished'
                                  ]
                            }`}
                            alt="unit-type"
                            src={
                              unitType?.coverImage ||
                              `${PUBLIC_PATH}/assets/pngs/unit-type-default.png`
                            }
                          />
                        </div>
                        <div className={styles['user-search-container__unit-type-item__header']}>
                          <div
                            className={`${
                              styles['user-search-container__unit-type-item__header__title']
                            } ${
                              !unitType.name
                                ? styles[
                                    'user-search-container__unit-type-item__header__title--untitled'
                                  ]
                                : ''
                            } `}
                          >
                            {unitType.name ||
                              t('UNIT_TYPE_MODULE.LIST_CONTAINER.UNTITLED_UNIT_TYPE')}
                            {/* <p
                  className={
                    styles['user-search-container__unit-type-item__header__title__created-by']
                  }
                >
                  {t('UNIT_TYPE_MODULE.LIST_CONTAINER.MODIFIED_BY')}
                  <span>{unitType.modifiedByUsername}</span>
                </p> */}
                          </div>
                          {!unitType.propertyName && (
                            <div
                              className={
                                styles[
                                  'user-search-container__unit-type-item__header-details--error'
                                ]
                              }
                            >
                              <WarningIcon
                                className={
                                  styles[
                                    'user-search-container__unit-type-item__header-details--error_icon'
                                  ]
                                }
                              />
                              Unit Type is not assigned to any Projects
                            </div>
                          )}
                          <div
                            className={
                              styles['user-search-container__unit-type-item__header-details']
                            }
                          >
                            <div
                              className={
                                styles[
                                  'user-search-container__unit-type-item__header-details_property-details'
                                ]
                              }
                            >
                              <div>
                                <div
                                  className={
                                    styles[
                                      'user-search-container__unit-type-item__header-details_property-details-name'
                                    ]
                                  }
                                >
                                  property Name:
                                  <a
                                    href={`${process.env.PUBLIC_URL}/properties/edit/${unitType.propertyId}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {unitType.propertyName}
                                    <ArrowForward color="inherit" fontSize="inherit" />
                                  </a>
                                </div>
                                <div
                                  className={
                                    styles[
                                      'user-search-container__unit-type-item__header-details_property-details-name'
                                    ]
                                  }
                                >
                                  Rooms:{' '}
                                  {unitType.units && unitType.units.length > 0
                                    ? unitType.units?.map((unit) => (
                                        <div
                                          className={
                                            styles[
                                              'user-search-container__unit-type-item__room-name'
                                            ]
                                          }
                                        >
                                          {unit.shortName}
                                        </div>
                                      ))
                                    : ' No rooms'}
                                </div>
                              </div>
                              <div
                                className={
                                  styles[
                                    'user-search-container__unit-type-item__header-details_property-details-area'
                                  ]
                                }
                              >
                                area:
                                <a
                                  href={`${process.env.PUBLIC_URL}/properties/edit/${unitType.areaId}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {unitType.area}{' '}
                                  <ArrowForward color="inherit" fontSize="inherit" />
                                </a>
                              </div>
                            </div>
                            {unitType.availableFrom && (
                              <div
                                className={
                                  styles[
                                    'user-search-container__unit-type-item__header-details_property-availability'
                                  ]
                                }
                              >
                                <span>
                                  Available from:{' '}
                                  {unitType.availableFrom &&
                                    new Date(unitType.availableFrom).toDateString()}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className={styles['user-search-container__unit-type-item__content']}>
                          <div
                            className={
                              styles['user-search-container__unit-type-item__content__responses']
                            }
                          >
                            <AttachMoneyIcon
                              className={
                                styles[
                                  'user-search-container__unit-type-item__content__responses__icon'
                                ]
                              }
                            />
                            <span
                              className={
                                styles[
                                  'user-search-container__unit-type-item__content__responses__number'
                                ]
                              }
                            >
                              {unitType?.unitPrice || 0} &nbsp;
                            </span>
                            <span
                              className={
                                styles[
                                  'user-search-container__unit-type-item__content__responses__response-text'
                                ]
                              }
                            >
                              {unitType?.reservationMode === ReservationMode.MONTHLY
                                ? t('UNIT_TYPE_MODULE.LIST_CONTAINER.MONTHLY_PRICE')
                                : t('UNIT_TYPE_MODULE.LIST_CONTAINER.NIGHTLY_PRICE')}
                            </span>
                          </div>
                          <div
                            className={
                              styles['user-search-container__unit-type-item__content__responses']
                            }
                          >
                            <MeetingRoomIcon
                              className={
                                styles[
                                  'user-search-container__unit-type-item__content__responses__icon'
                                ]
                              }
                            />
                            <span
                              className={
                                styles[
                                  'user-search-container__unit-type-item__content__responses__number'
                                ]
                              }
                            >
                              {unitType?.units?.length || 0} &nbsp;
                            </span>
                            <span
                              className={
                                styles[
                                  'user-search-container__unit-type-item__content__responses__response-text'
                                ]
                              }
                            >
                              {t('UNIT_TYPE_MODULE.LIST_CONTAINER.UNITS_COUNT')}
                            </span>
                          </div>
                          <div
                            className={
                              styles['user-search-container__unit-type-item__content__responses']
                            }
                          >
                            <BathtubIcon
                              className={
                                styles[
                                  'user-search-container__unit-type-item__content__responses__icon'
                                ]
                              }
                            />
                            <span
                              className={
                                styles[
                                  'user-search-container__unit-type-item__content__responses__number'
                                ]
                              }
                            >
                              {unitType?.amenities?.length || 0} &nbsp;
                            </span>
                            <span
                              className={
                                styles[
                                  'user-search-container__unit-type-item__content__responses__response-text'
                                ]
                              }
                            >
                              {t('UNIT_TYPE_MODULE.LIST_CONTAINER.AMENITIES_COUNT')}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          )}
          <div className={styles['user-search-container__details-wrapper__user-details']}>
            <div className={styles['user-search-container__content__title']}>
              Reservations
              {` ${
                userReservations && userReservations.length
                  ? '(' + userReservations.length + ')'
                  : ''
              }`}
            </div>
            {isLoadingReservations ? (
              <div className={styles['user-search-container__loader']}>
                <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
              </div>
            ) : userReservations && userReservations.length ? (
              <div className={styles['user-search-container__table']}>
                <TableContainer component={Paper}>
                  <Table aria-label="table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Id #</TableCell>
                        <TableCell>Room Name</TableCell>
                        <TableCell>Tags</TableCell>
                        <TableCell>Check In</TableCell>
                        <TableCell>Check Out</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userReservations?.length > 0 &&
                        userReservations?.map((reservation: ReservationUiModel) => (
                          <ReservationRow key={reservation.id} reservation={reservation} />
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : (
              <div className={styles['user-search-container__content__illustration']}>
                <img
                  src={`${PUBLIC_PATH}/assets/jpeg/no-results-found.jpg`}
                  className={styles['user-search-container__content__illustration__image']}
                  alt="no-data"
                />
                No previous reservation.
              </div>
            )}
          </div>
          <div className={styles['user-search-container__details-wrapper__user-details']}>
            <div className={styles['user-search-container__content__title']}>
              Booking Requests
              {` ${
                userBookingRequests && userBookingRequests.length
                  ? '(' + userBookingRequests.length + ')'
                  : ''
              }`}
            </div>
            {isLoadingBookings ? (
              <div className={styles['user-search-container__loader']}>
                <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
              </div>
            ) : userBookingRequests && userBookingRequests.length > 0 ? (
              <div className={styles['user-search-container__table']}>
                <TableContainer component={Paper}>
                  <Table aria-label="table">
                    <TableHead>
                      <TableRow>
                        {/* <TableCell>Contact Name</TableCell> */}
                        <TableCell>Tags</TableCell>
                        <TableCell>Check In</TableCell>
                        <TableCell>Check Out</TableCell>
                        <TableCell>Id #</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userBookingRequests?.length > 0 &&
                        userBookingRequests?.map((reservation: BookingRequestUiModel) => (
                          <BookingRow key={reservation.id} reservation={reservation} />
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            ) : (
              <div className={styles['user-search-container__content__illustration']}>
                <img
                  src={`${PUBLIC_PATH}/assets/jpeg/no-results-found.jpg`}
                  className={styles['user-search-container__content__illustration__image']}
                  alt="no-data"
                />
                No previous requests.
              </div>
            )}
          </div>
        </div>
      )}
      <AddUserModal
        config={{ showModal: showAddModal }}
        eventHandlers={{
          cancelHandler: () => {
            setShowAddModal(false);
          },
          addUserHandler: (userModal: UserUIModel) => {
            setShowAddModal(false);
            // handleNewAreaSubmit(areaName, order);
          }
        }}
      />
    </div>
  );
};

export default connect(mapStateToProps)(UserSearchContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}
