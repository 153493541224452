import { ApiResponse } from 'apisauce';
import { ErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { ResponseModel } from '../../../shared/interfaces/response-model.interface';
import ReservationMapper from '../../../shared/mappers/reservation.mapper';
import ReservationDeatilsMapper from '../../../shared/mappers/reservation-details.mapper';
import ReservationActivityMapper from '../../../shared/mappers/reservation-activities.mapper';
import GuestMapper from '../../../shared/mappers/guest.mapper';
import PaymentMapper from '../../../shared/mappers/payment.mapper';
import api from '../../../shared/services/utils/api/api.service';
import errorHelper from '../../../shared/services/utils/api/error-helper.service';
import { getModuleName } from './reservation.service';
import {
  ReservationUiModel,
  ReservationDetailsUiModel,
  ReservationListUiModel
} from '../../../shared/ui-models/reservation.ui-model';
import {
  ReservationModel,
  CreateReservationRequestModel,
  ReservationModelData
} from '../../../shared/models/reservation.model';
import { CreateBlockDatesModel, BlockDatesModel } from '../../../shared/models/block-dates.model';
import { ReservationDetailsModel } from '../../../shared/models/reservation-details.model';
import { GuestUiModel } from '../../../shared/ui-models/guest.ui-model';
import { GuestModel } from '../../../shared/models/guest.model';
import { PaymentLinkModel, PaymentUIModel } from '../../../shared/ui-models/payment.ui-model';
import { PaymentModel } from '../../../shared/models/payment.model';
import {
  BookAvailabilityResponseModel,
  BookOfferResponseModel
} from '../../../shared/ui-models/book.ui-model';
import moment from 'moment';
import { PaymentStatus } from '../../../shared/enums/payment-status.enum';
import { ReservationActivityUiModel } from '../../../shared/ui-models/reservation-activity.ui-model';
import { ReservationActivityModel } from '../../../shared/models/reservation-activity.model';
import { BlockedDatesUiModel } from '../../../shared/ui-models/blocked-dates.ui-model';
import BlockedDatesMapper from '../../../shared/mappers/blocked-dates.mapper';
import { FeedbackPayload, FeedbackResponse } from '../../../shared/models/feedbacks.model';

export function getAllReservations(
  query: {},
  offset: number,
  limit: number
): Promise<ReservationListUiModel | ErrorInfo> {
  return api
    .getReservations(query, offset, limit)
    .then((response: ApiResponse<ResponseModel<ReservationModelData>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data?.data && data?.count) {
          return { reservationList: ReservationMapper.mapToUIList(data?.data), count: data?.count };
        } else {
          return errorHelper(0, '', getModuleName(), (api.getReservations as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.getReservations as any).displayName
        );
      }
    });
}
export function getOneReservations(id: string): Promise<ReservationDetailsUiModel | ErrorInfo> {
  return api
    .getOneReservation(id)
    .then((response: ApiResponse<ResponseModel<ReservationDetailsModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return ReservationDeatilsMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.getOneReservation as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.getOneReservation as any).displayName
        );
      }
    });
}

export function getReservationsActivities(
  reservationId: string
): Promise<ReservationActivityUiModel[] | ErrorInfo> {
  return api
    .getReservationActivities(reservationId)
    .then((response: ApiResponse<ResponseModel<ReservationActivityModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return ReservationActivityMapper.mapToUIList(data.data);
        } else {
          return errorHelper(
            0,
            '',
            getModuleName(),
            (api.getReservationActivities as any).displayName
          );
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.getReservationActivities as any).displayName
        );
      }
    });
}

export function updateOneReservations(
  reservation: Partial<ReservationDetailsUiModel>
): Promise<ReservationDetailsUiModel | ErrorInfo> {
  return api
    .updateOneReservation(reservation)
    .then((response: ApiResponse<ResponseModel<ReservationDetailsModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return ReservationDeatilsMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.getOneReservation as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.getOneReservation as any).displayName
        );
      }
    });
}

export function addOneComment(
  reservationId: string,
  message: string
): Promise<ReservationDetailsUiModel | ErrorInfo> {
  return api
    .postReservationMessage(reservationId, message)
    .then((response: ApiResponse<ResponseModel<ReservationDetailsModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return ReservationDeatilsMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.getOneReservation as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.getOneReservation as any).displayName
        );
      }
    });
}

export function reactivateOneReservations(
  reservationId: string,
  roomId: string
): Promise<{} | ErrorInfo> {
  return api
    .reactivateReservation(reservationId, roomId)
    .then((response: ApiResponse<ResponseModel<{}>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return {};
        } else {
          return errorHelper(
            0,
            '',
            getModuleName(),
            (api.reactivateReservation as any).displayName
          );
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.getOneReservation as any).displayName
        );
      }
    });
}
export function getOneGuest(id: string): Promise<GuestUiModel | ErrorInfo> {
  return api.getOneGuest(id).then((response: ApiResponse<ResponseModel<GuestModel>, ErrorInfo>) => {
    if (response.ok) {
      const { data } = response;
      if (data && data.data) {
        return GuestMapper.mapToUI(data.data);
      } else {
        return errorHelper(0, '', getModuleName(), (api.getOneReservation as any).displayName);
      }
    } else {
      return errorHelper(
        Number(response.status),
        response.data && response.data.errorCode
          ? String(response.data.errorCode || '')
          : response.problem,
        getModuleName(),
        (api.getOneReservation as any).displayName
      );
    }
  });
}
export function updateGuest(guest: GuestUiModel): Promise<GuestUiModel | ErrorInfo> {
  return api
    .updateGuest(guest)
    .then((response: ApiResponse<ResponseModel<GuestModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return GuestMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.getOneReservation as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.getOneReservation as any).displayName
        );
      }
    });
}
export function deleteGuest(guestId: string): Promise<GuestUiModel | ErrorInfo> {
  return api
    .deleteGuest(guestId)
    .then((response: ApiResponse<ResponseModel<GuestModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return data.data;
        } else {
          return errorHelper(0, '', getModuleName(), (api.deleteGuest as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.getOneReservation as any).displayName
        );
      }
    });
}
export function addGuest(guest: GuestUiModel): Promise<GuestUiModel | ErrorInfo> {
  return api.addGuest(guest).then((response: ApiResponse<ResponseModel<GuestModel>, ErrorInfo>) => {
    if (response.ok) {
      const { data } = response;
      if (data && data.data) {
        return GuestMapper.mapToUI(data.data);
      } else {
        return errorHelper(0, '', getModuleName(), (api.getOneReservation as any).displayName);
      }
    } else {
      return errorHelper(
        Number(response.status),
        response.data && response.data.errorCode
          ? String(response.data.errorCode || '')
          : response.problem,
        getModuleName(),
        (api.getOneReservation as any).displayName
      );
    }
  });
}
export function updateOnePayment(
  payment: Partial<PaymentUIModel>
): Promise<PaymentUIModel | ErrorInfo> {
  return api
    .updateOnePayment(payment)
    .then((response: ApiResponse<ResponseModel<PaymentModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return PaymentMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.updateOnePayment as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.updateOnePayment as any).displayName
        );
      }
    });
}
export function createOnePayment(payment: PaymentUIModel): Promise<PaymentUIModel | ErrorInfo> {
  return api
    .createOnePayment(payment)
    .then((response: ApiResponse<ResponseModel<PaymentModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return PaymentMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.createOnePayment as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.createOnePayment as any).displayName
        );
      }
    });
}

export function reservationSwitchRoom(
  reservationId: number,
  sourceUnitTypeId: number,
  destinationUnitTypeId: number,
  roomId: string
): Promise<ReservationDetailsUiModel | ErrorInfo> {
  return api
    .reservationRoomSwitch(reservationId, sourceUnitTypeId, destinationUnitTypeId, roomId)
    .then((response: ApiResponse<ResponseModel<ReservationDetailsModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return ReservationDeatilsMapper.mapToUI(data.data);
        } else {
          return errorHelper(
            0,
            '',
            getModuleName(),
            (api.reservationRoomSwitch as any).displayName
          );
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.reservationRoomSwitch as any).displayName
        );
      }
    });
}

export function sendPayment(paymentId: string, email: string): Promise<PaymentUIModel | ErrorInfo> {
  return api
    .sendPaymentLink(paymentId, email)
    .then((response: ApiResponse<ResponseModel<PaymentModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return PaymentMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.sendPaymentLink as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.sendPaymentLink as any).displayName
        );
      }
    });
}
export function generatePaymentLink(
  paymentLink: PaymentLinkModel
): Promise<PaymentUIModel | ErrorInfo> {
  return api
    .generatePaymentLink(paymentLink)
    .then((response: ApiResponse<ResponseModel<PaymentModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return PaymentMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.generatePaymentLink as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.generatePaymentLink as any).displayName
        );
      }
    });
}

/**
 * function to upload campaign images under user directory
 * @param file image file to upload
 */
export function uploadPaymentReceipt(
  file: File,
  collectedAtDate: Date,
  paymentID: string,
  reservationId: string,
  status: PaymentStatus,
  skipEmail: boolean
): Promise<ApiResponse<{ data: any }, ErrorInfo>> {
  const data = new FormData();
  data.append('asset', file);
  data.append('collectedAt', collectedAtDate.getTime().toString());
  data.append('paymentId', paymentID);
  data.append('reservationId', reservationId);
  data.append('status', status);
  data.append('skipEmail', skipEmail);
  return api.uploadPaymentReceipt(data).then((response) => {
    if (response.ok) {
      const { data } = response;
      if (data && data.data) {
        return (data.data as any).Location;
      } else {
        return errorHelper(0, '', paymentID, (api.updateOnePayment as any).displayName);
      }
    } else {
      return errorHelper(
        Number(response.status),
        response.data && response.data.errorCode
          ? String(response.data.errorCode || '')
          : response.problem,
        paymentID,
        (api.uploadPaymentReceipt as any).displayName
      );
    }
  });
}
/**
 * function to upload campaign images under user directory
 * @param file image file to upload
 */
export function uploadGuestDocument(
  file: File,
  guestId: string,
  reservationId: string,
  documentType: string
): Promise<ApiResponse<{ data: any }, ErrorInfo>> {
  const data = new FormData();
  data.append('asset', file);
  data.append('guestId', guestId);
  data.append('reservationId', reservationId);
  data.append('documentType', documentType);
  return api.uploadGuestDocument(data).then((response) => {
    if (response.ok) {
      const { data } = response;
      if (data && data.data) {
        return (data.data as any).Location;
      } else {
        return errorHelper(0, '', guestId, (api.updateOnePayment as any).displayName);
      }
    } else {
      return errorHelper(
        Number(response.status),
        response.data && response.data.errorCode
          ? String(response.data.errorCode || '')
          : response.problem,
        guestId,
        (api.uploadGuestDocument as any).displayName
      );
    }
  });
}
export const getBookAvailability = (
  unitTypeId: string
): Promise<BookAvailabilityResponseModel[] | ErrorInfo | {}> => {
  const query: { unitTypeId: string } = { unitTypeId: unitTypeId || '' };
  return api
    .getBookAvailability(query)
    .then((response: ApiResponse<ResponseModel<BookAvailabilityResponseModel[]>, ErrorInfo>) => {
      if (!response.ok) {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode)
            : response.problem,
          getModuleName(),
          (api.getBookAvailability as any).displayName
        );
        return error;
      } else {
        if (response.data && response.data.data) {
          // console.log(response.data.data);
          const bookAvailability: BookAvailabilityResponseModel[] = response.data.data;
          return bookAvailability;
        } else {
          return {};
        }
      }
    });
};
export const getBookOffer = (
  unitTypeId: string,
  checkInDate: string,
  checkOutDate: string
): Promise<BookOfferResponseModel | ErrorInfo | {}> => {
  const query: { unitTypeId: string; checkInDate: string; checkOutDate: string } = {
    unitTypeId: unitTypeId || '',
    checkInDate,
    checkOutDate
    // checkInDate: moment(checkInDate).format('DD/MM/YYYY'),
    // checkOutDate: moment(checkOutDate).format('DD/MM/YYYY')
  };
  return api
    .getBookOffer(query)
    .then((response: ApiResponse<ResponseModel<BookOfferResponseModel>, ErrorInfo>) => {
      if (!response.ok) {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode)
            : response.problem,
          getModuleName(),
          (api.getBookOffer as any).displayName
        );
        return error;
      } else {
        if (response.data && response.data.data) {
          // console.log(response.data.data);
          const bookOffer: BookOfferResponseModel = response.data.data;
          return bookOffer;
        } else {
          return {};
        }
      }
    });
};
export const createReservation = (
  reservation: CreateReservationRequestModel
): Promise<BookOfferResponseModel | ErrorInfo | {}> => {
  return api
    .createReservations(reservation)
    .then((response: ApiResponse<ResponseModel<ReservationModel>, ErrorInfo>) => {
      if (!response.ok) {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode)
            : response.problem,
          getModuleName(),
          (api.getBookOffer as any).displayName
        );
        return error;
      } else {
        if (response.data && response.data.data) {
          console.log(response.data.data);
          const reservation: ReservationModel = response.data.data;
          return reservation;
        } else {
          return {};
        }
      }
    });
};

export const creatBlockDates = (
  blockDates: CreateBlockDatesModel
): Promise<BookOfferResponseModel | ErrorInfo | {}> => {
  return api
    .createBlockDates(blockDates)
    .then((response: ApiResponse<ResponseModel<BlockDatesModel>, ErrorInfo>) => {
      if (!response.ok) {
        const error: ErrorInfo = errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode)
            : response.problem,
          getModuleName(),
          (api.getBookOffer as any).displayName
        );
        return error;
      } else {
        if (response.data && response.data.data) {
          console.log(response.data.data);
          const blockDates: BlockDatesModel = response.data.data;
          return blockDates;
        } else {
          return {};
        }
      }
    });
};

export function getDoubleBookings(): Promise<
  | {
      currentReservation: ReservationUiModel;
      otherReservation?: ReservationUiModel;
      blockedDate?: BlockedDatesUiModel;
    }[]
  | ErrorInfo
> {
  return api.getDoubleBookings().then(
    (
      response: ApiResponse<
        ResponseModel<
          {
            currentReservation: ReservationModel;
            otherReservation?: ReservationModel;
            blockedDate?: BlockDatesModel;
          }[]
        >,
        ErrorInfo
      >
    ) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          const mappedData: {
            currentReservation: ReservationUiModel;
            otherReservation?: ReservationUiModel;
            blockedDate?: BlockedDatesUiModel;
          }[] = data.data.map((doubleBooking) => ({
            currentReservation: ReservationMapper.mapToUI(doubleBooking.currentReservation),
            otherReservation: doubleBooking.otherReservation
              ? ReservationMapper.mapToUI(doubleBooking.otherReservation)
              : undefined,
            blockedDate: doubleBooking.blockedDate
              ? BlockedDatesMapper.mapToUI(doubleBooking.blockedDate)
              : undefined
          }));
          return mappedData;
        } else {
          return errorHelper(0, '', getModuleName(), (api.getDoubleBookings as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.getReservations as any).displayName
        );
      }
    }
  );
}
export function deletePropertyManagerToReservations(id: string, reservationId: string): Promise<ReservationUiModel | ErrorInfo> {
  return api
    .deletePropertyManagerToReservations(id, reservationId)
    .then((response: ApiResponse<ResponseModel<ReservationModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return ReservationMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.deletePropertyManagerToReservations as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.deletePropertyManagerToReservations as any).displayName
        );
      }
    });
}
export function addPropertyManagerToReservations(id: string, reservationId: string): Promise<ReservationUiModel | ErrorInfo> {
  return api
    .addPropertyManagerToReservations(id, String(reservationId))
    .then((response: ApiResponse<ResponseModel<ReservationModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return ReservationMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.addPropertyManagerToReservations as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.addPropertyManagerToReservations as any).displayName
        );
      }
    });
}

export function getAllFeeback(payload: FeedbackPayload): Promise<{ count:number, data:FeedbackResponse[]} | ErrorInfo> {
  return api
    .getAllReservationsFeebacks(payload)
    .then((response: ApiResponse<ResponseModel<{count:number, data:FeedbackResponse[]}>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return data as any;
        } else {
          return errorHelper(0, '', getModuleName(), (api.getAllReservationsFeebacks as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.getAllReservationsFeebacks as any).displayName
        );
      }
    });
  }
  export function getOneReservationsFeebacks(reservationID: string): Promise<FeedbackResponse | ErrorInfo> {
      return api
        .getOneReservationsFeebacks(reservationID)
        .then((response: ApiResponse<ResponseModel<FeedbackResponse>, ErrorInfo>) => {
          if (response.ok) {
            const { data } = response;
            if (data && data.data) {
              return data.data as FeedbackResponse;
            } else {
              return errorHelper(0, '', getModuleName(), (api.getOneReservationsFeebacks as any).displayName);
            }
          } else {
            return errorHelper(
              Number(response.status),
              response.data && response.data.errorCode
                ? String(response.data.errorCode || '')
                : response.problem,
              getModuleName(),
              (api.getOneReservationsFeebacks as any).displayName
            );
          }
        });
    }



