import React, { useState, useEffect } from 'react';
import styles from './history-thread.module.scss';
import { format } from 'timeago.js';
import moment from 'moment';
import { PUBLIC_PATH } from '../../../../../shared/config/routes-config';
import { ReservationActivityUiModel } from '../../../../../shared/ui-models/reservation-activity.ui-model';
import { getReservationsActivities } from '../../../../shared/services/data.service';
import { isErrorInfo } from '../../../../../shared/interfaces/error-info.interface';

export interface HistoryThreadProps {
  data: {
    reservationId: string;
  };
}

const isJson = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * functional component HistoryThreadProps
 * @param {HistoryThreadProps}
 */
export const HistoryThread: React.FC<HistoryThreadProps> = ({ data }) => {
  const [activitiesList, setActivitiesList] = useState<ReservationActivityUiModel>();

  useEffect(() => {
    if (data && data.reservationId) {
      getReservationActivities(data.reservationId);
    }
  }, [data.reservationId]);

  function getReservationActivities(id: string) {
    return getReservationsActivities(id).then(
      (response: ReservationActivityUiModel[] | ErrorInfo): any => {
        if (!isErrorInfo(response)) {
          setActivitiesList(response);
        }
      }
    );
  }
  return (
    <div className={styles['history-thread']}>
      <div className={styles['history-thread__group']}>
        {activitiesList && activitiesList.length > 0 ? (
          activitiesList.map((history) => (
            <div className={styles['history-thread__content']}>
              {/* <img className={styles['history-thread__image']} src={`${PUBLIC_PATH}/assets/pngs/male-avatar.png`} /> */}
              <div className={styles['history-thread__avatar']}>
                <div className={styles['history-thread__time-ago']}>
                  <div>[{format(history.date)}]</div>
                </div>
                <div className={styles['history-thread__message']}>
                  <b>{history.action ? history.action.replace('_', ' ') : 'UNKNOWN ACTION'}</b>
                  {' was done by '}
                  <b>{history.name || history.username}</b>
                </div>
                {history.extras && isJson(history.extra) && (
                  <div className={styles['history-thread__extras']}>
                    Changes Made:
                    <tbody>
                      <tr>
                        {JSON.parse(history.extra)
                          .keys()
                          .forEach((key) => (
                            <th>{key}</th>
                          ))}
                      </tr>
                      <tr>
                        {JSON.parse(history.extra)
                          .keys()
                          .forEach((key) => (
                            <td>{JSON.parse(history.extra)[key]}</td>
                          ))}
                      </tr>
                    </tbody>
                  </div>
                )}

                {/* <history.Actions>
            <history.Action>Reply</history.Action>
          </history.Actions> */}
              </div>
            </div>
          ))
        ) : (
          <div className={styles['history-thread__no-records']}>
            <img src={`${PUBLIC_PATH}/assets/svgs/no-data.svg`} alt="no-data" /> No previous
            messages
          </div>
        )}
      </div>
    </div>
  );
};
