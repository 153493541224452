import React, { useState } from 'react';
import { UserUIModel } from '../../../../../shared/ui-models/user.ui-model';
import SearchIcon from '@material-ui/icons/Search';
import { store } from 'react-notifications-component';

import { useTranslation } from 'react-i18next';
import styles from './assign-manager-modal.module.scss';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize
} from '../../../../../shared/components/core-ui/button/button.component';

import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { ErrorInfo, isErrorInfo } from '../../../../../shared/interfaces/error-info.interface';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import PhoneInput from 'react-phone-input-2';
import { searchUsers } from '../../../../../shared/services/data/user.data';
import { getModuleName } from '../../../../shared/services/property.service';
import { PropertyUiManagersListModel } from '../../../../../shared/ui-models/propertyManager.ui.model';
import { UserType } from '../../../../../shared/enums/user-type.enum';

const genderOption = [
  { label: 'Male', value: 'male' },
  { label: 'FeMale', value: 'female' }
];

export interface AssignPartnerModalProps {
  config: {
    showModal: boolean;
  };
  eventHandlers: {
    closeHandler: () => void;
    chooseUser: (propertyModel: PropertyUiManagersListModel) => void;
  };
}

/**
 * functional component AssignPartnerModal
 * @param {AssignPartnerModalProps}
 */
export const AssignManagerModal: React.FC<AssignPartnerModalProps> = ({
  config,
  eventHandlers
}) => {
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();
  const [user, setUser] = useState<UserUIModel>();

  const [searchNumber, setSearchNumber] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });

  const [showSearchResult, setShowSearchResult] = useState<boolean>(false);
  const [assignUser, setAssignUser] = useState<boolean>(false);

  const handleGetUserByPhoneNumber = (phoneNumber: string) => {
    searchUsers(
      {
        phone: [phoneNumber],
        role: [UserType.RESERVATION_AGENT, UserType.RESERVATION_MANAGER, UserType.ADMIN]
      },
      getModuleName()
    ).then((response: UserUIModel[] | ErrorInfo) => {
      setShowSearchResult(true);
      if (!isErrorInfo(response) && response.length > 0) {
        setUser(response[0]);
        setAssignUser(false);
      } else {
        setAssignUser(true);
        store.addNotification({
          id: 'get-user-failed',
          title: t(
            'UNIT_TYPE_MODULE.LIST_CONTAINER.ASSIGN_PARTNER_MODAL.NOTIFICATIONS.SEARCH_FAILED.TITLE'
          ),
          message: t(
            'UNIT_TYPE_MODULE.LIST_CONTAINER.ASSIGN_PARTNER_MODAL.NOTIFICATIONS.SEARCH_FAILED.MESSAGE'
          ),
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
      }
    });
  };
  return (
    <Modal
      config={{
        showModal: config.showModal,
        fullScreen: false,
        showClose: true,
        className: 'partner-Modal'
      }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.closeHandler();
        }
      }}
    >
      <div className={styles['assign-partner-container__add-user']}>
        <div className={styles['assign-partner-container__add-user__title']}>Add New User</div>
        <div className={styles['assign-partner-container__add-user__description']}>
          <div className={styles['assign-partner-container__add-user__description--search']}>
            search by phone number
            <div
              className={styles['assign-partner-container__add-user__description--search--input']}
            >
              <PhoneInput
                country={'eg'}
                inputProps={{
                  name: 'search by phone number',
                  required: true
                }}
                value={searchNumber.value}
                onChange={(phone) => {
                  phone
                    ? setSearchNumber({ value: `+${phone}`, isValid: true })
                    : setSearchNumber({ value: `${phone}`, isValid: false });
                }}
              />
              <Button
                onClick={() => {
                  handleGetUserByPhoneNumber(searchNumber.value);
                }}
                tracking={{
                  action: 'search',
                  category: 'guest',
                  label: 'add-guest'
                }}
                size={isMobileView ? ButtonSize.full : ButtonSize.medium}
              >
                <SearchIcon
                  fontSize={'large'}
                  color="inherit"
                  className={
                    styles['assign-partner-container__add-user__description--search-action']
                  }
                />
              </Button>
            </div>
          </div>
        </div>{' '}
        {user && !assignUser && showSearchResult && (
          <div>
            <div className={styles['partner-info-container-content']}>
              <div className={styles['partner-info-container-content-info']}>
                <label>id :</label>
                <p> {user?.id}</p>
              </div>
              <div className={styles['partner-info-container-content-info']}>
                <label>name :</label> <p>{user?.name}</p>
              </div>
              <div className={styles['partner-info-container-content-info']}>
                <label>email :</label> <p>{user?.email}</p>
              </div>
              <div className={styles['partner-info-container-content-info']}>
                <label>phone :</label> <p>{user?.phone}</p>
              </div>
            </div>
            <div className={styles['assign-partner-container__add-user--actions']}>
              <Button
                onClick={() => {
                  eventHandlers.chooseUser({
                    email: user?.email || '',
                    phone: user?.phone || '',
                    name: user?.name || '',
                    id: user?.id || '',
                    role: user?.role || ''
                  });
                }}
                tracking={{
                  action: 'assign',
                  category: 'guest',
                  label: 'assign-guest-modal'
                }}
                size={isMobileView ? ButtonSize.full : ButtonSize.medium}
              >
                confirm
              </Button>
            </div>
          </div>
        )}
        {assignUser && showSearchResult && (
          <>
            <div className={styles['assign-partner-container__add-user__assign']}>
              User Not Found
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
