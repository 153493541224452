import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './add-unit-type-modal.module.scss';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize
} from '../../../../../shared/components/core-ui/button/button.component';
import {
  InputText,
  InputTextErrorMode
} from '../../../../../shared/components/core-ui/input-text/input-text.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { UnitTypeUiModel } from '../../../../../shared/ui-models/unit-type.ui-model';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import { VALIDATION_REGEX } from '../../../../../shared/components/validation.config';

export interface AddUnitTypeModalProps {
  config: {
    showModal: boolean;
  };
  eventHandlers: {
    closeHandler: () => void;
    addUnitTypeHandler: (unitTypeData: Pick<UnitTypeUiModel, 'name'>) => void;
  };
}

/**
 * functional component AddUnitTypeModal
 * @param {AddUnitTypeModalProps}
 */
export const AddUnitTypeModal: React.FC<AddUnitTypeModalProps> = ({ config, eventHandlers }) => {
  const [newUnitTypeName, setNewUnitTypeName] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  const handleNewUnitTypeSubmit = () => {
    if (newUnitTypeName.value !== '' && newUnitTypeName.isValid) {
      eventHandlers.addUnitTypeHandler({
        name: newUnitTypeName.value
      });
    }
  };

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.closeHandler();
        }
      }}
    >
      <div className={styles['add-unit-type-modal']}>
        <div className={styles['add-unit-type-modal__title']}>
          {t('UNIT_TYPE_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.TITLE')}
        </div>
        <div className={styles['add-unit-type-modal__description']}>
          {t('UNIT_TYPE_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.NAME')}
          <InputText
            data={{
              defaultValue: '',
              errorMessage: t(
                'UNIT_TYPE_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.INVALID_PROPERTY_NAME'
              ),
              placeholder: t('UNIT_TYPE_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.NAME_PLACEHOLDER')
            }}
            config={{
              maxRows: 1,
              autoFocus: true,
              validationRegex: VALIDATION_REGEX.UNIT_TYPE_NAME,
              showErrorMode: InputTextErrorMode.instant
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setNewUnitTypeName({ value, isValid });
              },
              onEnterPressedHandler: handleNewUnitTypeSubmit
            }}
          />
        </div>
      </div>
      <div className={styles['add-unit-type-modal--actions']}>
        <Button
          onClick={() => {
            eventHandlers.closeHandler();
          }}
          tracking={{
            action: 'close',
            category: 'unit-types',
            label: 'add-unit-type-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          {t('UNIT_TYPE_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.CANCEL_BUTTON')}
        </Button>
        <Button
          disabled={!newUnitTypeName.isValid || newUnitTypeName.value === ''}
          onClick={handleNewUnitTypeSubmit}
          tracking={{
            action: 'submit',
            category: 'unit-type',
            label: 'add-unit-type-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          {t('UNIT_TYPE_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.SUBMIT_BUTTON')}
        </Button>
      </div>
    </Modal>
  );
};
