import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { APP_ROUTES } from '../shared/config/routes-config';
import { RouteConfig } from '../shared/interfaces/routes-config.interface';
import ReservationsListContainer from './containers/reservation-list/reservation-list.container';
import { ReservationsEditContainer } from './containers/reservation-edit/reservation-edit.container';
import { FeedbackList } from './containers/feedback-list/feedback-list.container';
import { FeedbackDetails } from './containers/feedback-details/feedback-details.container';

/**
 * function ReservationModule components
 */
export const ReservationModule: React.FC = () => {
  return (
    <>
      <Route exact path={APP_ROUTES.RESERVATION_MODULE.FULL_PATH}>
        <Redirect to={(APP_ROUTES.RESERVATION_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH} />
      </Route>
      <Route
        path={(APP_ROUTES.RESERVATION_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH}
        component={ReservationsListContainer}
      />
      <Route
        path={(APP_ROUTES.RESERVATION_MODULE.CHILDREN as RouteConfig).EDIT.FULL_PATH}
        component={ReservationsEditContainer}
      />
        <Route
        path={(APP_ROUTES.RESERVATION_MODULE.CHILDREN as RouteConfig).FEEDBACK_LIST.FULL_PATH}
        component={FeedbackList}
      />
      <Route
        path={(APP_ROUTES.RESERVATION_MODULE.CHILDREN as RouteConfig).FEEDBACK_DETAILS.FULL_PATH}
        component={FeedbackDetails}
      />
    </>
  );
};
