import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './amenities-list.module.scss';
import { store } from 'react-notifications-component';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import { Button as MIButton, Menu, MenuItem } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { ErrorInfo, isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { RootState } from '../../../shared/redux/reducers';
import { trackEvent, trackPage } from '../../../shared/services/utils/tracking/tracking.service';
import {
  getAllAmenities,
  getAllChannelAmenities,
  getAllChannelRoomAmenities
} from '../../../shared/services/data/lookups.data';
import { getModuleName } from '../../shared/services/amenity.service';
import { AmenityUiModel } from '../../../shared/ui-models/amenity.ui-model';
import {
  addNewAmenity,
  deleteAmenity,
  updateAmenity,
  uploadAmenityImage
} from '../../shared/services/data.service';
import { DeleteAmenityModal } from './components/delete-amenity-modal/delete-amenity-modal.component';
import { EditAmenityModal } from './components/edit-amenity-modal/edit-amenity-modal.component';
import { AddAmenityModal } from './components/add-amenity-modal/add-amenity-modal.component';
import useWindowResizer from '../../../shared/hooks/window-resizer/window-resizer.hook';
import { PUBLIC_PATH } from '../../../shared/config/routes-config';
import { ImageType } from 'react-images-uploading';
import { AmenityChannelUiModel } from '../../../shared/ui-models/amenity-channel.ui-model';

export type AmenitiesListContainerProps = ReturnType<typeof mapStateToProps>;

/**
 * functional component AmenitiesListContainer
 * @param {AmenitiesListContainerProps} holding question text
 */
export const AmenitiesListContainer: React.FC<AmenitiesListContainerProps> = () => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [amenitiesList, setAmenitiesList] = useState<AmenityUiModel[]>([]);
  const [amenitiesChannelList, setAmenitiesChannelList] = useState<AmenityChannelUiModel[]>([]);
  const [amenitiesChannelRoomList, setAmenitiesChannelRoomList] = useState<AmenityChannelUiModel[]>(
    []
  );
  const [amenityToUpdate, setAmenityUpdate] = useState<AmenityUiModel>();
  const [amenityToDelete, setAmenityDelete] = useState<AmenityUiModel>();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  useEffect(() => {
    trackPage('amenities-list');
    getAllAmenities(getModuleName()).then((response: AmenityUiModel[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setAmenitiesList(response);
        getAllChannelAmenities(getModuleName()).then(
          (response: AmenityChannelUiModel[] | ErrorInfo) => {
            if (!isErrorInfo(response)) {
              setAmenitiesChannelList(response);
            }
          }
        );
        getAllChannelRoomAmenities(getModuleName()).then(
          (response: AmenityChannelUiModel[] | ErrorInfo) => {
            if (!isErrorInfo(response)) {
              setAmenitiesChannelRoomList(response);
            }
          }
        );
      }
    });
    return () => {
      store.removeNotification('add-amenity-failed');
      store.removeNotification('add-amenity-success');
      store.removeNotification('edit-amenity-failed');
      store.removeNotification('edit-amenity-success');
      store.removeNotification('delete-amenity-failed');
      store.removeNotification('delete-amenity-success');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewAmenitySubmit = (newAmenityName: string) => {
    store.removeNotification('add-amenity-failed');
    store.removeNotification('add-amenity-success');
    addNewAmenity({ name: newAmenityName }).then((response: AmenityUiModel | ErrorInfo) => {
      if (isErrorInfo(response)) {
        if (response.status === 401 || response.status === 403) {
          store.addNotification({
            id: 'add-amenity-failed',
            title: t('AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_AMENITY_PERMISSION.TITLE'),
            message: t(
              'AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_AMENITY_PERMISSION.MESSAGE'
            ),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        } else {
          store.addNotification({
            id: 'add-amenity-failed',
            title: t('AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_AMENITY_FAILED.TITLE'),
            message: t('AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_AMENITY_FAILED.MESSAGE'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        }
      } else {
        store.addNotification({
          title: t('AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_AMENITY_SUCCESS.TITLE'),
          message: t('AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_AMENITY_SUCCESS.MESSAGE'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        setAmenitiesList([...amenitiesList, response]);
      }
    });
  };

  const uploadImageHandler = (amenityId: string, image: ImageType) => {
    if (image && image.file) {
      uploadAmenityImage(image.file, amenityId).then((response: AmenityUiModel | ErrorInfo) => {
        if (isErrorInfo(response)) {
          if (response.status === 401 || response.status === 403) {
            store.addNotification({
              id: 'edit-amenity-failed',
              title: t('AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AMENITY_PERMISSION.TITLE'),
              message: t(
                'AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AMENITY_PERMISSION.MESSAGE'
              ),
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut']
            });
          } else {
            store.addNotification({
              id: 'edit-amenity-failed',
              title: t('AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AMENITY_FAILED.TITLE'),
              message: t('AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AMENITY_FAILED.MESSAGE'),
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut']
            });
          }
        } else {
          store.addNotification({
            title: t('AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AMENITY_SUCCESS.TITLE'),
            message: t('AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AMENITY_SUCCESS.MESSAGE'),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          const updateAmenitiesList = amenitiesList.map((amenity: AmenityUiModel) => {
            if (amenity.id === response.id) {
              return response;
            } else {
              return amenity;
            }
          });
          setAmenitiesList(updateAmenitiesList);
        }
      });
    }
  };

  const handleEditAmenitySubmit = (amenityId: string, amenity: AmenityUiModel) => {
    store.removeNotification('edit-amenity-failed');
    store.removeNotification('edit-amenity-success');
    updateAmenity(amenityId, amenity).then((response: AmenityUiModel | ErrorInfo) => {
      if (isErrorInfo(response)) {
        if (response.status === 401 || response.status === 403) {
          store.addNotification({
            id: 'edit-amenity-failed',
            title: t('AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AMENITY_PERMISSION.TITLE'),
            message: t(
              'AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AMENITY_PERMISSION.MESSAGE'
            ),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        } else {
          store.addNotification({
            id: 'edit-amenity-failed',
            title: t('AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AMENITY_FAILED.TITLE'),
            message: t('AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AMENITY_FAILED.MESSAGE'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        }
      } else {
        store.addNotification({
          title: t('AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AMENITY_SUCCESS.TITLE'),
          message: t('AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_AMENITY_SUCCESS.MESSAGE'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        const updateAmenitiesList = amenitiesList.map((amenity: AmenityUiModel) => {
          if (amenity.id === response.id) {
            return response;
          } else {
            return amenity;
          }
        });
        setAmenitiesList(updateAmenitiesList);
      }
    });
  };
  /**
   * function to handle user menu clicked
   * @param event mouse click event
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    trackEvent('actions|open-menu', {
      category: 'amenities-list',
      label: 'amenities'
    });
  };

  /**
   * function to handle closing user menu
   */
  const handleClose = (event: any) => {
    if (event && event.stopPropagation) event.stopPropagation();
    if (event && event.preventDefault) event.preventDefault();
    setAnchorEl(null);
  };

  const handleDeleteAmenitySubmit = (amenityId: string) => {
    store.removeNotification('delete-amenity-failed');
    store.removeNotification('delete-amenity-success');
    deleteAmenity(amenityId).then((response: any | ErrorInfo) => {
      if (isErrorInfo(response)) {
        if (response.status === 401 || response.status === 403) {
          store.addNotification({
            id: 'delete-amenity-failed',
            title: t('AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_AMENITY_PERMISSION.TITLE'),
            message: t(
              'AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_AMENITY_PERMISSION.MESSAGE'
            ),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        } else {
          store.addNotification({
            id: 'delete-amenity-failed',
            title: t('AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_AMENITY_FAILED.TITLE'),
            message: t('AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_AMENITY_FAILED.MESSAGE'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        }
      } else {
        store.addNotification({
          title: t('AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_AMENITY_SUCCESS.TITLE'),
          message: t('AMENITY_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_AMENITY_SUCCESS.MESSAGE'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        const updateAmenitiesList = amenitiesList.filter(
          (amenity: AmenityUiModel) => amenity.id !== amenityId
        );
        setAmenitiesList(updateAmenitiesList);
      }
    });
  };

  return (
    <div className={styles['amenities-list-container']}>
      <div className={styles['amenities-list-container__title']}>
        {t('AMENITY_MODULE.LIST_CONTAINER.TITLE')}
      </div>
      <div
        className={styles['amenities-list-container__add-new']}
        onClick={() => {
          setShowAddModal(true);
        }}
      >
        <AddCircleIcon fontSize={'inherit'} color={'inherit'} />
      </div>
      <div className={styles['amenities-list-container__list-wrapper']}>
        {amenitiesList.map((amenity) => (
          <div className={styles['amenities-list-container__amenity-item']}>
            <div className={styles['amenities-list-container__amenity-item__image-wrapper']}>
              <img
                className={styles['amenities-list-container__amenity-item__image-wrapper__image']}
                alt="property"
                src={amenity?.image || `${PUBLIC_PATH}/assets/pngs/amenity-default.png`}
              />
            </div>
            <div className={styles['amenities-list-container__amenity-item__header']}>
              <div
                className={`${styles['amenities-list-container__amenity-item__header__title']} ${
                  !amenity.name
                    ? styles['amenities-list-container__amenity-item__header__title--untitled']
                    : ''
                } `}
              >
                {amenity.name || t('AMENITY_MODULE.LIST_CONTAINER.UNTITLED_AMENITY')}
                <p
                  className={
                    styles['amenities-list-container__amenity-item__header__title__created-by']
                  }
                >
                  {t('AMENITY_MODULE.LIST_CONTAINER.MODIFIED_BY')}
                  <span>{amenity.modifiedByUsername}</span>
                </p>
                <p
                  className={
                    styles['amenities-list-container__amenity-item__header__title__created-by']
                  }
                >
                  {t('AMENITY_MODULE.LIST_CONTAINER.MODIFIED_AT')}
                  <span>{amenity.modifiedAt}</span>
                </p>
              </div>
              {isMobileView && (
                <>
                  <MIButton aria-controls="amenity-menu" aria-haspopup="true" onClick={handleClick}>
                    <MoreVertIcon
                      className={styles['amenities-list-container__amenity-item__header__icon']}
                    />
                  </MIButton>
                  <Menu
                    id="amenity-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        width: '16ch'
                      }
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        setAmenityUpdate(amenity);
                        setShowEditModal(true);
                      }}
                    >
                      <span
                        className={
                          styles[
                            'amenities-list-container__amenity-item__content__responses__link-text'
                          ]
                        }
                      >
                        {t('AMENITY_MODULE.LIST_CONTAINER.EDIT_LINK')}
                      </span>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setAmenityDelete(amenity);
                        setShowDeleteModal(true);
                      }}
                    >
                      <span
                        className={
                          styles[
                            'amenities-list-container__amenity-item__content__responses__link-text'
                          ]
                        }
                      >
                        {t('AMENITY_MODULE.LIST_CONTAINER.DELETE_LINK')}
                      </span>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </div>

            {!isMobileView && (
              <div className={styles['amenities-list-container__amenity-item__actions']}>
                <MIButton
                  aria-controls="amenity-menu"
                  aria-haspopup="true"
                  onClick={() => {
                    setAmenityUpdate(amenity);
                    setShowEditModal(true);
                  }}
                >
                  <EditIcon fontSize={'large'} color="inherit" />
                </MIButton>
                <MIButton
                  aria-controls="amenity-menu"
                  aria-haspopup="true"
                  onClick={() => {
                    setAmenityDelete(amenity);
                    setShowDeleteModal(true);
                  }}
                >
                  <DeleteIcon fontSize="large" color={'error'} />
                </MIButton>
              </div>
            )}
          </div>
        ))}
        {/* <DataGrid apiRef={apiRef} className={classes.root} rows={amenitiesList} columns={columns} /> */}
      </div>
      <AddAmenityModal
        config={{ showModal: showAddModal }}
        eventHandlers={{
          cancelHandler: () => {
            setShowAddModal(false);
          },
          addAmenityHandler: (amenityName) => {
            setShowAddModal(false);
            handleNewAmenitySubmit(amenityName);
          }
        }}
      />
      <EditAmenityModal
        config={{ showModal: showEditModal }}
        data={{
          amenity: amenityToUpdate,
          cAmenityList: amenitiesChannelList,
          cAmenityRoomList: amenitiesChannelRoomList
        }}
        eventHandlers={{
          cancelHandler: () => {
            setShowEditModal(false);
            setAmenityUpdate(undefined);
          },
          editAmenityHandler: (amenityId: string, amenity: AmenityUiModel) => {
            setShowEditModal(false);
            setAmenityUpdate(undefined);
            handleEditAmenitySubmit(amenityId, amenity);
          },
          submitImageHandler: (id: string, file: ImageType) => {
            setShowEditModal(false);
            setAmenityUpdate(undefined);
            uploadImageHandler(id, file);
          }
        }}
      />
      <DeleteAmenityModal
        config={{ showModal: showDeleteModal }}
        data={{ amenity: amenityToDelete }}
        eventHandlers={{
          cancelHandler: () => {
            setShowDeleteModal(false);
            setAmenityDelete(undefined);
          },
          deleteAmenityHandler: (amenity: AmenityUiModel) => {
            setShowDeleteModal(false);
            setAmenityDelete(undefined);
            if (amenity && amenity.id) {
              handleDeleteAmenitySubmit(amenity.id);
            }
          }
        }}
      />
    </div>
  );
};

export default connect(mapStateToProps)(AmenitiesListContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}
