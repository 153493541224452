import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './add-showroom-image-modal.module.scss';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import { trackEvent } from '../../../../../shared/services/utils/tracking/tracking.service';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import FormControl from '@material-ui/core/FormControl';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import ImageUploader from '../../../../../shared/components/core-ui/image-uploader/image-uploader.component';
import { ImageType, ImageListType } from 'react-images-uploading';

export interface AddShowroomImageModalProps {
  config: {
    showModal: boolean;
  };
  eventHandlers: {
    cancelHandler: () => void;
    submitImageHandler: (roomName: string, image: ImageListType) => void;
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),

      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  })
);

/**
 * functional component AddShowroomImageModal
 * @param {AddShowroomImageModalProps}
 */
export const AddShowroomImageModal: React.FC<AddShowroomImageModalProps> = ({
  config,
  eventHandlers
}) => {
  const [newRoomName, setNewRoomName] = useState<FormElementData<string>>({
    value: '',
    isValid: false,
    changed: false
  });
  const [newShowroomImage, setNewShowroomImage] = useState<FormElementData<ImageListType | null>>({
    value: null,
    isValid: false,
    changed: false
  });
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  const handleNewImageSubmit = () => {
    if (
      newShowroomImage.changed &&
      newShowroomImage &&
      newShowroomImage.value &&
      newRoomName.value
    ) {
      eventHandlers.submitImageHandler(newRoomName.value, newShowroomImage.value);
      setNewShowroomImage({ value: null, isValid: false, changed: false });
      setNewRoomName({ value: '', isValid: false, changed: false });
    }
  };

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.cancelHandler();
        }
      }}
    >
      <div className={styles['showroom-modal']}>
        <div className={styles['showroom-modal__title']}>
          {t('UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.ADD_SHOWROOM_IMAGE_MODAL.TITLE')}
        </div>
        <div className={styles['showroom-modal__description']}>
          {t('UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.ADD_SHOWROOM_IMAGE_MODAL.NAME')}
        </div>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={newRoomName.value}
            onChange={(
              event: React.ChangeEvent<{
                name?: string | undefined;
                value: unknown;
              }>
            ) => {
              setNewRoomName({
                value: event.target.value as string,
                isValid: true,
                changed: true
              });
            }}
          >
            {/* <MenuItem value={''}>Select Room</MenuItem> */}
            <MenuItem value={'Living Room'}>Living Room</MenuItem>
            <MenuItem value={'Bedroom'}>Bedroom</MenuItem>
            <MenuItem value={'Kitchen'}>Kitchen</MenuItem>
            <MenuItem value={'Dining Room'}>Dining Room</MenuItem>
            <MenuItem value={'Family Room'}>Family Room</MenuItem>
            <MenuItem value={'Guest Room'}>Guest Room</MenuItem>
            <MenuItem value={'Bathroom'}>Bathroom</MenuItem>
            <MenuItem value={'Game Room'}>Game Room</MenuItem>
            <MenuItem value={'Basement'}>Basement</MenuItem>
            <MenuItem value={'Home Office'}>Home Office</MenuItem>
            <MenuItem value={'Nursery'}>Nursery</MenuItem>
            <MenuItem value={'Playroom'}>Playroom</MenuItem>
            <MenuItem value={'Library'}>Library</MenuItem>
            <MenuItem value={'Storage Room'}>Storage Room</MenuItem>
            <MenuItem value={'Home Gym'}>Home Gym</MenuItem>
            <MenuItem value={'Laundry Room'}>Laundry Room</MenuItem>
            <MenuItem value={'Garden'}>Garden</MenuItem>
            <MenuItem value={'View'}>View</MenuItem>
          </Select>
        </FormControl>
        <div className={styles['showroom-modal__description']}>
          {t('UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.ADD_SHOWROOM_IMAGE_MODAL.IMAGE')}
        </div>
        <ImageUploader
          config={{
            multiple: false,
            enableCrop: false,
            canvasSize: 'sm',
            cropAspectRatio: { width: 1, height: 1 }
          }}
          data={{
            image: newShowroomImage.value
          }}
          eventHandlers={{
            fileUploadHandler: (file, width, height) => {
              trackEvent('image-sm-uploaded', {
                category: 'showroom-image-upload',
                label: 'add-showroom-image'
              });
              setNewShowroomImage({ value: file || null, changed: true, isValid: true });
            },
            imageRemovedHandler: () => {
              setNewShowroomImage({
                value: null,
                isValid: false,
                changed: false
              });
            }
          }}
        />
      </div>
      <div className={styles['showroom-modal--actions']}>
        <Button
          onClick={() => {
            eventHandlers.cancelHandler();
            trackEvent('cancel', {
              category: 'showroom-cancel',
              label: 'add-showroom-image'
            });
          }}
          tracking={{
            action: 'cancel',
            category: 'showroom-submit',
            label: 'add-showroom-image'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          type={ButtonType.primaryInverted}
        >
          {t('UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.ADD_SHOWROOM_IMAGE_MODAL.CANCEL_BUTTON')}
        </Button>
        <Button
          disabled={!newRoomName.isValid || newRoomName.value === '' || !newShowroomImage.changed}
          onClick={handleNewImageSubmit}
          tracking={{
            action: 'submit',
            category: 'showroom-submit',
            label: 'add-showroom-image'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          {t('UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.ADD_SHOWROOM_IMAGE_MODAL.SUBMIT_BUTTON')}
        </Button>
      </div>
    </Modal>
  );
};
