import React, { useState } from 'react';
import styles from './add-payment-receipt-modal.module.scss';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ReactDatePicker from 'react-datepicker';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { PaymentStatus } from '../../../../../shared/enums/payment-status.enum';
import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';

export interface AddPaymentReceiptModalProps {
  config: {
    showModal: boolean;
  };
  data: {
    status?: PaymentStatus;
    paymentId?: string;
  };

  eventHandlers: {
    closeHandler: () => void;
    addPaymentReceiptHandler: (
      receipt: File,
      collectedAtDate: Date,
      status?: PaymentStatus,
      paymentId?: string,
      skipEmail?: boolean
    ) => void;
  };
}

/**
 * functional component AddPaymentReceiptModal
 * @param {AddPaymentReceiptModalProps}
 */
export const AddPaymentReceiptModal: React.FC<AddPaymentReceiptModalProps> = ({
  config,
  eventHandlers,
  data
}) => {
  const [newReceipt, setNewReceipt] = useState<File>();
  const [skipEmail, setSkipEmail] = useState<boolean>(false);
  const [collectedAtDate, setCollectedAtDate] = useState<Date>(new Date());

  const isMobileView = useWindowResizer();

  const handleNewPaymentReceiptSubmit = () => {
    if (newReceipt) {
      eventHandlers.addPaymentReceiptHandler(
        newReceipt,
        collectedAtDate,
        data.status,
        data.paymentId,
        skipEmail
      );
    }
  };

  const handleSkipEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSkipEmail(event.target.checked);
  };
  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.closeHandler();
        }
      }}
    >
      <div className={styles['add-payment-receipt-modal']}>
        <div className={styles['add-payment-receipt-modal__title']}>Add Payment Receipt</div>
        <div className={styles['add-payment-receipt-modal__description']}>
          Collected At:
          <ReactDatePicker
            selected={collectedAtDate}
            onChange={(date) => setCollectedAtDate(date as Date)}
          />
        </div>
        <Dropzone
          classNames={{ input: styles['add-payment-receipt-modal__dropzone'] }}
          inputContent={() => (
            <div className={styles['add-payment-receipt-modal__upload-container']}>
              <CloudUploadIcon fontSize={'large'} />
              Upload proof of payment
            </div>
          )}
          accept="*"
          maxFiles={1}
          multiple={false}
          onChangeStatus={(file) => {
            setNewReceipt(file.file);
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={skipEmail}
              onChange={handleSkipEmailChange}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          label="Do NOT send email to guest"
        />
        <div className={styles['add-payment-receipt-modal--actions']}>
          <Button
            onClick={() => {
              eventHandlers.closeHandler();
            }}
            type={ButtonType.primaryInverted}
            tracking={{
              action: 'close',
              category: 'payments',
              label: 'add-payment-modal'
            }}
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          >
            Cancel
          </Button>
          <Button
            disabled={!newReceipt}
            onClick={handleNewPaymentReceiptSubmit}
            tracking={{
              action: 'submit',
              category: 'payment',
              label: 'add-payment-receipt-modal'
            }}
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          >
            Add payment
          </Button>
        </div>
      </div>
    </Modal>
  );
};
