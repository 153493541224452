import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './properties-list.module.scss';
import { store } from 'react-notifications-component';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ApartmentIcon from '@material-ui/icons/Apartment';
import EditIcon from '@material-ui/icons/Edit';
import BathtubIcon from '@material-ui/icons/Bathtub';
import { Button as MIButton, Menu, MenuItem } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { ErrorInfo, isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { RootState } from '../../../shared/redux/reducers';
import { trackEvent, trackPage } from '../../../shared/services/utils/tracking/tracking.service';
import { AddPropertyModal } from './components/add-property-modal/add-property-modal.component';
import { DeletePropertyModal } from './components/delete-property-modal/delete-property-modal.component';
import { PropertyUiModel } from '../../../shared/ui-models/property.ui-model';
import {
  addNewProperty,
  deleteProperty,
  getAllProperties
} from '../../shared/services/data.service';
import { APP_ROUTES, PUBLIC_PATH } from '../../../shared/config/routes-config';
import { useHistory } from 'react-router';
import { RouteConfig } from '../../../shared/interfaces/routes-config.interface';
import useWindowResizer from '../../../shared/hooks/window-resizer/window-resizer.hook';

export type PropertiesListContainerProps = ReturnType<typeof mapStateToProps>;

/**
 * functional component PropertiesListContainer
 * @param {PropertiesListContainerProps} holding question text
 */
export const PropertiesListContainer: React.FC<PropertiesListContainerProps> = ({
  isAuthenticated
}) => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [propertiesList, setPropertyList] = useState<PropertyUiModel[]>([]);
  const [propertyToDelete, setPropertyToDelete] = useState<PropertyUiModel>();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const isMobileView = useWindowResizer();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    trackPage('properties-list');
    getAllProperties().then((response: PropertyUiModel[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setPropertyList(response);
      }
    });
    return () => {
      store.removeNotification('add-property-failed');
      store.removeNotification('add-property-success');
      store.removeNotification('delete-property-failed');
      store.removeNotification('delete-property-success');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewPropertySubmit = (propertyData: PropertyUiModel) => {
    store.removeNotification('add-property-failed');
    store.removeNotification('add-property-success');
    addNewProperty(propertyData).then((response: PropertyUiModel | ErrorInfo) => {
      if (isErrorInfo(response)) {
        if (response.status === 401 || response.status === 403) {
          store.addNotification({
            id: 'add-property-failed',
            title: t('PROPERTY_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_PROPERTY_PERMISSION.TITLE'),
            message: t(
              'PROPERTY_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_PROPERTY_PERMISSION.MESSAGE'
            ),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        } else {
          store.addNotification({
            id: 'add-property-failed',
            title: t('PROPERTY_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_PROPERTY_FAILED.TITLE'),
            message: t('PROPERTY_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_PROPERTY_FAILED.MESSAGE'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        }
      } else {
        store.addNotification({
          title: t('PROPERTY_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_PROPERTY_SUCCESS.TITLE'),
          message: t('PROPERTY_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_PROPERTY_SUCCESS.MESSAGE'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        setPropertyList([...propertiesList, response]);
        history.push(
          (APP_ROUTES.PROPERTY_MODULE.CHILDREN as RouteConfig).EDIT.FULL_PATH.replace(
            ':id',
            response.id as string
          )
        );
      }
    });
  };

  const handleDeletePropertySubmit = (propertyId: string) => {
    store.removeNotification('delete-property-failed');
    store.removeNotification('delete-property-success');
    deleteProperty(propertyId).then((response: {} | ErrorInfo) => {
      if (isErrorInfo(response)) {
        if (response.status === 401 || response.status === 403) {
          store.addNotification({
            id: 'delete-property-failed',
            title: t(
              'PROPERTY_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_PROPERTY_PERMISSION.TITLE'
            ),
            message: t(
              'PROPERTY_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_PROPERTY_PERMISSION.MESSAGE'
            ),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        } else {
          store.addNotification({
            id: 'delete-property-failed',
            title: t('PROPERTY_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_PROPERTY_FAILED.TITLE'),
            message: t(
              'PROPERTY_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_PROPERTY_FAILED.MESSAGE'
            ),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        }
      } else {
        store.addNotification({
          title: t('PROPERTY_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_PROPERTY_SUCCESS.TITLE'),
          message: t(
            'PROPERTY_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_PROPERTY_SUCCESS.MESSAGE'
          ),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        const updatePropertyList = propertiesList.filter(
          (property: PropertyUiModel) => property.id !== propertyId
        );
        setPropertyList(updatePropertyList);
      }
    });
  };

  /**
   * function to handle user menu clicked
   * @param event mouse click event
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    trackEvent('actions|open-menu', {
      category: 'properties-list',
      label: 'properties'
    });
  };

  /**
   * function to handle closing user menu
   */
  const handleClose = (event: any) => {
    if (event && event.stopPropagation) event.stopPropagation();
    if (event && event.preventDefault) event.preventDefault();
    setAnchorEl(null);
  };

  return (
    <div className={styles['properties-list-container']}>
      <div className={styles['properties-list-container__title']}>
        {t('PROPERTY_MODULE.LIST_CONTAINER.TITLE')}
      </div>
      <div
        className={styles['properties-list-container__add-new']}
        onClick={() => {
          setShowAddModal(true);
        }}
      >
        <AddCircleIcon fontSize={'inherit'} color={'inherit'} />
      </div>
      <div className={styles['properties-list-container__list-wrapper']}>
        {propertiesList.map((property) => (
          <div className={styles['properties-list-container__property-item']}>
            <div className={styles['properties-list-container__property-item__image-wrapper']}>
              <img
                className={styles['properties-list-container__property-item__image-wrapper__image']}
                alt="property"
                src={
                  property?.coverImage?.sm ||
                  property?.coverImage?.md ||
                  property?.coverImage?.lg ||
                  `${PUBLIC_PATH}/assets/pngs/property-default.png`
                }
              />
            </div>
            <div className={styles['properties-list-container__property-item__header']}>
              <div
                className={`${styles['properties-list-container__property-item__header__title']} ${
                  !property.name
                    ? styles['properties-list-container__property-item__header__title--untitled']
                    : ''
                } `}
              >
                {property.name || t('PROPERTY_MODULE.LIST_CONTAINER.UNTITLED_PROPERTY')}
                <p
                  className={
                    styles['properties-list-container__property-item__header__title__created-by']
                  }
                >
                  {t('PROPERTY_MODULE.LIST_CONTAINER.MODIFIED_BY')}
                  <span>{property.modifiedByUsername}</span>
                </p>
                {/* <p
                  className={
                    styles['properties-list-container__property-item__header__title__created-by']
                  }
                >
                  {t('PROPERTY_MODULE.LIST_CONTAINER.MODIFIED_AT')}
                  <span>{property.modifiedAt}</span>
                </p> */}
              </div>
              {isMobileView && (
                <>
                  <MIButton
                    aria-controls="property-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <MoreVertIcon
                      className={styles['properties-list-container__property-item__header__icon']}
                    />
                  </MIButton>
                  <Menu
                    id="property-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        width: '16ch'
                      }
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        history.push(
                          (
                            APP_ROUTES.PROPERTY_MODULE.CHILDREN as RouteConfig
                          ).EDIT.FULL_PATH.replace(':id', property.id as string)
                        );
                      }}
                    >
                      <span
                        className={
                          styles[
                            'properties-list-container__property-item__content__responses__link-text'
                          ]
                        }
                      >
                        {t('PROPERTY_MODULE.LIST_CONTAINER.EDIT_LINK')}
                      </span>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setPropertyToDelete(property);
                        setShowDeleteModal(true);
                      }}
                    >
                      <span
                        className={
                          styles[
                            'properties-list-container__property-item__content__responses__link-text'
                          ]
                        }
                      >
                        {t('PROPERTY_MODULE.LIST_CONTAINER.DELETE_LINK')}
                      </span>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </div>
            <div className={styles['properties-list-container__property-item__content']}>
              <div
                className={styles['properties-list-container__property-item__content__responses']}
              >
                <ApartmentIcon
                  className={
                    styles['properties-list-container__property-item__content__responses__icon']
                  }
                />
                <span
                  className={
                    styles['properties-list-container__property-item__content__responses__number']
                  }
                >
                  {property?.unitTypeIds?.length || 0} &nbsp;
                </span>
                <span
                  className={
                    styles[
                      'properties-list-container__property-item__content__responses__response-text'
                    ]
                  }
                >
                  {t('PROPERTY_MODULE.LIST_CONTAINER.UNIT_TYPES_COUNT')}
                </span>
              </div>
              <div
                className={styles['properties-list-container__property-item__content__responses']}
              >
                <BathtubIcon
                  className={
                    styles['properties-list-container__property-item__content__responses__icon']
                  }
                />
                <span
                  className={
                    styles['properties-list-container__property-item__content__responses__number']
                  }
                >
                  {property?.amenities?.length || 0} &nbsp;
                </span>
                <span
                  className={
                    styles[
                      'properties-list-container__property-item__content__responses__response-text'
                    ]
                  }
                >
                  {t('PROPERTY_MODULE.LIST_CONTAINER.AMENITIES_COUNT')}
                </span>
              </div>
            </div>

            {!isMobileView && (
              <div className={styles['properties-list-container__property-item__actions']}>
                <MIButton
                  aria-controls="property-menu"
                  aria-haspopup="true"
                  onClick={() => {
                    window.open(
                      PUBLIC_PATH +
                        (APP_ROUTES.PROPERTY_MODULE.CHILDREN as RouteConfig).EDIT.FULL_PATH.replace(
                          ':id',
                          property.id as string
                        ),
                      '_blank'
                    );
                  }}
                >
                  <EditIcon fontSize={'large'} color="inherit" />
                </MIButton>
                <MIButton
                  aria-controls="property-menu"
                  aria-haspopup="true"
                  onClick={() => {
                    setPropertyToDelete(property);
                    setShowDeleteModal(true);
                  }}
                >
                  <DeleteIcon fontSize="large" color={'error'} />
                </MIButton>
              </div>
            )}
          </div>
        ))}
      </div>
      <AddPropertyModal
        config={{ showModal: showAddModal }}
        eventHandlers={{
          cancelHandler: () => {
            setShowAddModal(false);
          },
          addPropertyHandler: (property) => {
            setShowAddModal(false);
            handleNewPropertySubmit(property);
          }
        }}
      />

      <DeletePropertyModal
        config={{ showModal: showDeleteModal }}
        data={{ property: propertyToDelete }}
        eventHandlers={{
          cancelHandler: () => {
            setShowDeleteModal(false);
            setPropertyToDelete(undefined);
          },
          deletePropertyHandler: (property: PropertyUiModel) => {
            setShowDeleteModal(false);
            setPropertyToDelete(undefined);
            if (property && property.id) {
              handleDeletePropertySubmit(property.id);
            }
          }
        }}
      />
    </div>
  );
};

export default connect(mapStateToProps)(PropertiesListContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}
