import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { APP_ROUTES } from '../shared/config/routes-config';
import { RouteConfig } from '../shared/interfaces/routes-config.interface';
import { ExpensesListContainer } from './containers/expenses-list/expenses-list.container';
/**
 * function UnitTypeModule components
 */
export const ExpensesModule: React.FC = () => {
  return (
    <>
      <Route
        exact
        path={APP_ROUTES.EXPENSES_MODULE.FULL_PATH}
        component={ExpensesListContainer}
      ></Route>
    </>
  );
};
