import { ApiResponse } from 'apisauce';
import { ErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { ResponseModel } from '../../../shared/interfaces/response-model.interface';
import api from '../../../shared/services/utils/api/api.service';
import errorHelper from '../../../shared/services/utils/api/error-helper.service';
import { getModuleName } from './reports.service';
import { FinancialReportQueryModel } from '../../../shared/models/financial-report-query.model';

export function getFinancialReport(query: FinancialReportQueryModel): Promise<any[] | ErrorInfo> {
  return api.financialReportSearch(query).then((response: ApiResponse<any[], ErrorInfo>) => {
    if (response.ok) {
      const { data } = response;
      if (data) {
        return data;
      } else {
        return errorHelper(0, '', getModuleName(), (api.paymentSearch as any).displayName);
      }
    } else {
      return errorHelper(
        Number(response.status),
        response.data && response.data.errorCode
          ? String(response.data.errorCode || '')
          : response.problem,
        getModuleName(),
        (api.paymentSearch as any).displayName
      );
    }
  });
}
