import React, { useEffect, useState } from 'react';
import styles from './feedback-list.container.module.scss';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { APP_ROUTES, PUBLIC_PATH } from '../../../shared/config/routes-config';
import { FeedbackPayload, FeedbackResponse } from '../../../shared/models/feedbacks.model';
import { getAllFeeback } from '../../shared/services/data.service';
import { isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { Pagination } from '../../../shared/components/core-ui/pagination/pagination.component';
import { RouteConfig } from '../../../shared/interfaces/routes-config.interface';
/**
 * function feedback-list Container components
 */
export const FeedbackList: React.FC = () => {
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [data, setData] = useState<FeedbackResponse[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);

  function getFeedbacks(query: FeedbackPayload) {
    setLoadingData(true);
    getAllFeeback(query)
      .then((response) => {
        if (!isErrorInfo(response)) {
          setData(response.data);
          setTotalCount(response.count);
        }
      })
      .finally(() => setLoadingData(false));
  }

  useEffect(() => {
    getFeedbacks({ offset: currentPage, limit });
  }, []);

  return (
    <div className={styles['feedback-list']}>
      <div className={styles['feedback-list__title']}>Feedbacks</div>
      {loadingData ? (
        <div className={styles['feedback-list__loader']}>
          <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
        </div>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Reservation ID</TableCell>
                  <TableCell>Unit name </TableCell>
                  <TableCell>Guest name</TableCell>
                  <TableCell>Checkin date</TableCell>
                  <TableCell>Checkout date</TableCell>
                  <TableCell>Overall Satisified?</TableCell>
                  <TableCell>Faced issues?</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item) => (
                  <TableRow>
                    <TableCell
                      className={styles['feedback-list__clickable-cell']}
                      onClick={() => {
                        window.open(
                          PUBLIC_PATH +
                          (
                            APP_ROUTES.RESERVATION_MODULE.CHILDREN as RouteConfig
                          ).FEEDBACK_DETAILS.FULL_PATH.replace(':id', item.id.toString()),
                          '_blank'
                        );
                      }}
                    >
                      {item.id}
                    </TableCell>
                    <TableCell>
                      {item.reservationId}
                    </TableCell>
                    <TableCell>{item.unit} </TableCell>
                    <TableCell>{item.guestName} </TableCell>
                    <TableCell>{new Date(item.checkInDate).toDateString()} </TableCell>
                    <TableCell>{new Date(item.checkOutDate).toDateString()} </TableCell>
                    {
                      item.feedback.filter(f => f.question.includes('satisfied were you with your overall experience'))
                        .map(f => <TableCell>{f.answer} </TableCell>)
                    }
                    {
                      item.feedback.filter(f => f.question.includes('experienced any problems'))
                        .map(f => <TableCell>{f.answer} </TableCell>)
                    }
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {data?.length === 0 && (
            <div className={styles['feedback-list__noData']}>
              <img src={`${PUBLIC_PATH}/assets/jpeg/no-records.jpg`} alt="no-data" /> No Data
              Available
            </div>
          )}
        </>
      )}
      {data.length > 0 && (
        <Pagination
          itemCounts={totalCount}
          itemsPerPage={limit}
          offset={currentPage}
          handlePageChange={(val) => {
            setCurrentPage(val);
            getFeedbacks({ offset: val, limit });
          }}
          handleItemsPerPage={(itemsPerPage) => {
            setLimit(itemsPerPage);
            getFeedbacks({ offset: currentPage, limit: itemsPerPage });
          }}
        />
      )}
    </div>
  );
};
