import React, { useState } from 'react';
import styles from './rooms.module.scss';
import EdiText from 'react-editext';
import { AddRoomsModal } from '../add-rooms-modal/add-rooms-modal.component';

export interface RoomsProps {
  data: {
    units: {
      unitId: string;
      shortName: string;
      isActive: boolean;
      roomNumber: string;
    }[];
  };

  eventHandlers: {
    submitHandler: (data: {
      unitId: string;
      shortName: string;
      isActive: boolean;
      roomNumber: string;
    }) => void;
    submitNewHandler: (data: {
      unitId: string;
      shortName: string;
      isActive: boolean;
      roomNumber: string;
    }) => void;
  };
}

/**
 * functional component Rooms
 * @param {RoomsProps} holding question text
 */
export const Rooms: React.FC<RoomsProps> = ({ data, eventHandlers }) => {
  const [showAddroomModal, setShowAddroomModal] = useState<boolean>(false);
  return (
    <>
      <AddRoomsModal
        config={{ showModal: showAddroomModal }}
        data={{
          id:
            data.units && data.units.length
              ? data.units[0].unitId.split('-')[0] +
                '-' +
                (Math.max.apply(
                  null,
                  data.units.map((item) => Number(item.unitId.split('-')[1]))
                ) +
                  1)
              : ''
        }}
        eventHandlers={{
          cancelHandler: () => {
            setShowAddroomModal(false);
          },
          submitRoomHandler: (data) => {
            setShowAddroomModal(false);
            eventHandlers.submitNewHandler(data);
          }
        }}
      />
      <div className={styles['rooms-container__sub-title']}>Rooms </div>

      <div className={styles['rooms-container']}>
        <div className={styles['rooms-container__card-container']}>
          {data.units.map((unit) => (
            <div className={styles['rooms-container__card-container__card']}>
              <>
                <div className={styles['rooms-container__card-container__card__label']}>id</div>
                <div className={styles['rooms-container__card-container__card__value']}>
                  {unit.unitId}
                </div>
              </>
              <>
                <div className={styles['rooms-container__card-container__card__label']}>
                  room Name
                </div>
                <div className={styles['rooms-container__card-container__card__value']}>
                  <EdiText
                    type="text"
                    value={unit.shortName}
                    onSave={(val) => {
                      eventHandlers.submitHandler({
                        ...unit,
                        shortName: val
                      });
                    }}
                  />
                </div>
              </>
              <>
                <div className={styles['rooms-container__card-container__card__label']}>Room #</div>
                <div className={styles['rooms-container__card-container__card__value']}>
                  <EdiText
                    type="text"
                    value={unit.roomNumber}
                    onSave={(val) => {
                      eventHandlers.submitHandler({
                        ...unit,
                        roomNumber: val
                      });
                    }}
                  />
                </div>
              </>
            </div>
          ))}
          <div
            className={`${styles['rooms-container__card-container__card']} ${styles['rooms-container__card-container__card--add-new']}`}
            onClick={() => setShowAddroomModal(true)}
          >
            <div>Add New</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rooms;
