import React from 'react';
import styles from './create-reservation-modal.module.scss';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import { ReservationsCreateContainer } from '../../../reservation-create/reservation-create.container';

export interface CreateReservationModalProps {
  config: {
    showModal: boolean;
    reservationType: string;
    className?: string;
  };
  eventHandlers: {
    closeHandler: () => void;
  };
}

/**
 * functional component CreateReservationModal
 * @param {CreateReservationModalProps}
 */
export const CreateReservationModal: React.FC<CreateReservationModalProps> = ({
  config,
  eventHandlers
}) => {
  return (
    <Modal
      config={{
        showModal: config.showModal,
        fullScreen: true,
        showClose: true,
        className: config.className
      }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.closeHandler();
        }
      }}
    >
      <div className={styles['create-reservation-modal']}>
        <ReservationsCreateContainer
          reservationType={config.reservationType}
          eventHandlers={{
            closeHandler: () => {
              eventHandlers.closeHandler();
            }
          }}
        ></ReservationsCreateContainer>
      </div>
    </Modal>
  );
};
