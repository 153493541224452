import React, { useContext, useEffect, useRef, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import { withVisibility } from './collapsible-button-chart';
import { KPIChartsSearch, OccupancyAndAverageDailyRate } from '../../shared/models/kpis.model';
import { getOccupancyAndAverageDailyRate } from '../shared/services/data.service';
import { isErrorInfo } from '../../shared/interfaces/error-info.interface';
import { OccupancyAndAverageDailyRateDataContext } from '../shared/services/OccupancyAndAverageDailyRate-provider';

const RevenuePerAvailableRoomChart: React.FC <{query: KPIChartsSearch }> = ({query}) => {
  const data = useContext<OccupancyAndAverageDailyRate[] | null>(OccupancyAndAverageDailyRateDataContext);

const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
  chart: {
    type: 'column',
    showCheckbox: true
  },
  title: {
      text: 'revenue per available room',
      align: 'left'
  },
  xAxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  },
  yAxis: [{
    title: {
      text: 'revenue per available room EGP'
    }
  }, {
    title: {
      text: 'revenue per available room USD'
    },
    opposite: true 
  }],
  plotOptions: {
    column: {
      borderWidth: 0,
      pointPadding: 0
    }
  },
  series: [{
      name: 'revenue per available room EGP',
      data: [],
      color: '#f38831',
      yAxis: 0
  },
  {
    name: 'revenue per available room USD',
    data: [],
    color: '#008343',
    yAxis: 1
}]
});

const chartComponentRef = useRef(null);

const getOccupancyAndAverageDailyRates = (response: OccupancyAndAverageDailyRate[]) => {
  chartComponentRef?.current?.chart?.hideLoading();
  const categories = response.map((revenue) => revenue.month.slice(0, 3).toUpperCase());
  const egpMonthsRevenue = response.map((revenue) => revenue.RPAR_EGP);
  const usdMonthsRevenue = response.map((revenue) => revenue.RPAR_USD);
  const chartUpdatedOptions = {
    ...chartOptions,
    xAxis: {
      ...chartOptions.xAxis,
      categories
    },
    series: [
      { ...chartOptions.series[0], data: egpMonthsRevenue },
      { ...chartOptions.series[1], data: usdMonthsRevenue },
    ]
  };
  setChartOptions(chartUpdatedOptions);
};

useEffect(() => {
  if(data) {
    getOccupancyAndAverageDailyRates(data);
  } else {
    chartComponentRef?.current?.chart?.showLoading();

  }
}, [data]);

return <HighchartsReact ref={chartComponentRef} highcharts={Highcharts} options={chartOptions} />;
};
export default withVisibility(RevenuePerAvailableRoomChart);
