import { ReservationMode } from '../enums/reservation-mode.enum';
import { UnitTypeRate } from '../models/unit-type-rate.model';
import { AmenityUiModel } from './amenity.ui-model';
import { UnitTypeFeeUiModel } from './unit-type-fee.model';
import { BookingMode } from '../enums/booking-mode.enum';
import { UnitTypeBedsUiModel } from './unit-type-beds.ui-model';

export interface UnitTypeUiModel {
  name?: string;
  description?: string;
  coverImage?: string;
  id?: string;
  shortName?: string;
  availableFrom?: number;
  roomTypePhotos?: { id: string; url: string; order?: number }[];
  amenities?: AmenityUiModel[];
  roomTypeUnits?: number;
  area?: string;
  isListed?: boolean;
  areaId?: string;
  maxGuests?: number;
  ratePlans?: UnitTypeRate[];
  fees?: UnitTypeFeeUiModel[];
  unitMeters?: string;
  currency?: string;
  bedrooms?: number;
  beds?: UnitTypeBedsUiModel[];
  bathrooms?: number;
  unitTypeLink?: string;
  bookingMode?: BookingMode;
  viewLink?: string;
  isFeatured?: boolean;
  houseRules?: string;
  onboardingMessage?: string;
  downPayment?: number;
  insurance?: number;
  cmId?: string;
  cmLastModifiedAt?: number;
  cmLastModifiedById?: string;
  cmLastModifiedByUsername?: string;
  reservationMode?: ReservationMode;
  unitPrice?: number;
  maxStayDuration?: number;
  minStayDuration?: number;
  propertyName?: string;
  propertyId?: string;
  urlLink?: string;
  units?: {
    unitId: string;
    shortName: string;
    isActive: boolean;
    roomNumber: string;
  }[];
  showRooms?: {
    roomName: string;
    roomId: string;
    imagesList: { id: string; url: string }[];
  }[];
  color?: string; // for UI use only
  unitTypeGroupId?: string;
  expenses?: Expenses[];
  landlordId?: string;
  landlordUsername?: string;
  landlordEmail?: string;
  landlordPhone?: string;
  landlordShare?: number;
  partnerId?: string;
  partnerUsername?: string;
  partnerEmail?: string;
  partnerPhone?: string;
  partnerShare?: number;
  payoutsToUpdate?: string[];
  customPrices?: CustomPriceModel[];
  checked?: boolean;
}
export interface CustomPriceModel {
  from: Date | any;
  to: Date | any;
  price: string;
}
export interface Expenses {
  id: string;
  description: string;
  roomId: string;
  unitTypeId: number;
  category: string;
  amount: number;
  paymentDate: number;
  paymentDateFrom?: number;
  paymentDateTo?: number;
  updateById: string;
  updateByUser: string;
  updatedAt: number;
  roomName: string;
  propertyId?: string;
  area?: string;
  areaId?: string;
  landlordId?: string;
}
export enum ExpensesCategoryType {
  MAINTENANCE = 'maintenance',
  OTHERS = 'others',
  HOUSE_KEEPING = 'house-keeping',
  LAUNDRY = 'laundry'
}
export interface UnitTypeUiModelResponse {
  unitList: UnitTypeUiModel[];
  count: number;
}
export interface RoomsUiModelResponse {
  value: string;
  label: string;
}
