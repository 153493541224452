import React, { useEffect, useRef, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import { withVisibility } from './collapsible-button-chart';
import { getMonthlyRevenueWithCurrency } from '../shared/services/data.service';
import { isErrorInfo } from '../../shared/interfaces/error-info.interface';
import { KPIChartsSearch } from '../../shared/models/kpis.model';

const SalesRevenueChart: React.FC <{query: KPIChartsSearch }>= ({query}) => {
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
    chart: {
      type: 'column',
      showCheckbox: true
    },
    title: {
      text: 'Sales Revenue',
      align: 'left'
    },
    xAxis: {
      categories: [
        'Jan',
        'Feb',
        'March',
        'Apr',
        'May',
        'June',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      crosshair: true,
      accessibility: {
        description: 'Months'
      }
    },
    yAxis: [{
      title: {
        text: 'EGP'
      }
    }, {
      title: {
        text: 'USD'
      },
      opposite: true 
    }],
    plotOptions: {
      column: {
        borderWidth: 0,
        pointPadding: 0
      }
    },
    series: [
      {
        name: 'EGP',
        data: [],
        color: '#f38831',
        yAxis: 0
      },
      {
        name: 'USD',
        data: [],
        color: '#008343',
        yAxis: 1
      }
    ]
  });
  const chartComponentRef = useRef(null);

  const getMonthlyRevenuesWithCurrency = (monthCount: number, payload: KPIChartsSearch) => {
    chartComponentRef?.current?.chart?.showLoading();
    getMonthlyRevenueWithCurrency(monthCount, payload).then((response) => {
      if (!isErrorInfo(response)) {
        chartComponentRef?.current?.chart?.hideLoading();
        const sortEntries =  response.reverse();
        const categories = sortEntries.map((revenue) => revenue.month.slice(0,3).toUpperCase());
        const egpMonthsRevenue = sortEntries.map((revenue) => revenue.egpRevenue);
        const usdMonthsRevenue = sortEntries.map((revenue) => revenue.usdRevenue);
        const chartUpdatedOptions = {
          ...chartOptions,
          xAxis:{
            ...chartOptions.xAxis,
            categories
          },
          series: [
            { ...chartOptions.series[0], data: egpMonthsRevenue },
            { ...chartOptions.series[1], data: usdMonthsRevenue }
          ]
        };
        setChartOptions(chartUpdatedOptions);
      }
    });
  };
  useEffect(() => {
    getMonthlyRevenuesWithCurrency(11, query);
  }, [query]);

  return <HighchartsReact ref={chartComponentRef} highcharts={Highcharts} options={chartOptions} />;
};

export default withVisibility(SalesRevenueChart);
