import { MODULE_NAME } from '../config/reservation-module.config';

export function getModuleName(): string {
  return MODULE_NAME;
}

const fileHeaders = [
  'source',
  'id',
  'status',
  'primaryGuestName',
  'checkInDate',
  'checkOutDate',
  'area',
  'propertyName',
  'createdAt',
  'reservationAdminUrl'
];

function convertJSONToCSV(jsonData:Array<any> , columnHeaders: string[]) {
    if (jsonData.length === 0) {
      return '';
    }

    const headers = columnHeaders.join(',') + '\n';

    const rows = jsonData
      .map((row) => {
        return columnHeaders.map((field) => field ==='checkInDate' || field=='checkOutDate'?new Date(row[field]): row[field] || '').join(',');
      })
      .join('\n');

    return headers + rows;
}

  // Function to initiate CSV download
export function downloadCSV(jsonData: Array<any>) {
    const csvData = convertJSONToCSV(jsonData, fileHeaders);

    // Check if CSV data is empty
    if (csvData === '') {
      alert('No data to export');
    } else {
      // Create CSV file and initiate download
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', 'reservation_data.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
