import { ApiResponse } from 'apisauce';
import { ErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { ResponseModel } from '../../../shared/interfaces/response-model.interface';
import AreaMapper from '../../../shared/mappers/area.mapper';
import { AreaModel } from '../../../shared/models/area.model';
import api from '../../../shared/services/utils/api/api.service';
import errorHelper from '../../../shared/services/utils/api/error-helper.service';
import { AreaUiModel } from '../../../shared/ui-models/area.ui-model';
import { getModuleName } from './area.service';

export function addNewArea(areaData: AreaUiModel): Promise<AreaUiModel | ErrorInfo> {
  const mappedArea = AreaMapper.mapToModel(areaData);
  return api
    .createArea(mappedArea)
    .then((response: ApiResponse<ResponseModel<AreaModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return AreaMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.createArea as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.createArea as any).displayName
        );
      }
    });
}

export function updateArea(
  areaId: string,
  areaData: AreaUiModel
): Promise<AreaUiModel | ErrorInfo> {
  const mappedArea = AreaMapper.mapToModel(areaData);
  return api
    .updateArea(areaId, mappedArea)
    .then((response: ApiResponse<ResponseModel<AreaModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return AreaMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.updateArea as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.updateArea as any).displayName
        );
      }
    });
}

export function deleteArea(areaId: string): Promise<{} | ErrorInfo> {
  return api.deleteArea(areaId).then((response: ApiResponse<ResponseModel<{}>, ErrorInfo>) => {
    if (response.ok) {
      const { data } = response;
      if (data && data.data) {
        return {};
      } else {
        return errorHelper(0, '', getModuleName(), (api.deleteArea as any).displayName);
      }
    } else {
      return errorHelper(
        Number(response.status),
        response.data && response.data.errorCode
          ? String(response.data.errorCode || '')
          : response.problem,
        getModuleName(),
        (api.deleteArea as any).displayName
      );
    }
  });
}
export function uploadDefaultImage(file: File, areaId: string): Promise<AreaUiModel | ErrorInfo> {
  const data = new FormData();
  data.append('asset', file);
  data.append('areaId', areaId);

  return api
    .uploadDefaultImage(data)
    .then((response: ApiResponse<ResponseModel<AreaModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return AreaMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.uploadDefaultImage as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.uploadDefaultImage as any).displayName
        );
      }
    });
}
export function uploadSponsoredImage(file: File, areaId: string): Promise<AreaUiModel | ErrorInfo> {
  const data = new FormData();
  data.append('asset', file);
  data.append('areaId', areaId);

  return api
    .uploadSponsoredImage(data)
    .then((response: ApiResponse<ResponseModel<AreaModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return AreaMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.uploadSponsoredImage as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.uploadSponsoredImage as any).displayName
        );
      }
    });
}
