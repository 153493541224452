import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './edit-plan-info.module.scss';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DeleteIcon from '@material-ui/icons/Delete';
import Slider from '@material-ui/core/Slider';
import { store } from 'react-notifications-component';
import MenuItem from '@material-ui/core/MenuItem';
import {
  InputText,
  InputTextType
} from '../../../../../shared/components/core-ui/input-text/input-text.component';
import Button, {
  ButtonSize
} from '../../../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { UnitTypeUiModel } from '../../../../../shared/ui-models/unit-type.ui-model';
import { ReservationMode } from '../../../../../shared/enums/reservation-mode.enum';
import { UnitTypeRate } from '../../../../../shared/models/unit-type-rate.model';
import { UnitTypeFeeUiModel } from '../../../../../shared/ui-models/unit-type-fee.model';
import { FeeType } from '../../../../../shared/enums/amount-type.enum';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import { CM_FEES_CONFIG } from '../../../../../shared/config/cm-fees.config';
import { PaymentItemType } from '../../../../../shared/enums/payment-item-type.enum';

export interface EditPlanInfoProps {
  data: {
    ratePlans: UnitTypeRate[];
    fees: UnitTypeFeeUiModel[];
    reservationMode: ReservationMode;
  };
  eventHandlers: {
    submitHandler: (data: Partial<UnitTypeUiModel>) => void;
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),

      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  })
);
/**
 * functional component EditPlanInfo
 * @param {EditPlanInfoProps} holding question text
 */
export const EditPlanInfo: React.FC<EditPlanInfoProps> = ({ data, eventHandlers }) => {
  const [newRatePlans, setNewRatePlans] = useState<UnitTypeRate[]>();
  const [ratePlanChanged, setRatePlanChanged] = useState<boolean>(false);
  const [newFees, setNewFees] = useState<UnitTypeFeeUiModel[]>();
  const [feesChanged, setFeesChanged] = useState<boolean>(false);

  const { t } = useTranslation();
  const classes = useStyles();
  const isMobileView = useWindowResizer();

  useEffect(() => {
    return () => {
      store.removeNotification('general-info-failed');
      store.removeNotification('general-info-success');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNewRatePlans(data.ratePlans);
    setRatePlanChanged(false);

    setNewFees(data.fees);
    setFeesChanged(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleGeneralInfoSubmit = () => {
    const updatedData: {
      ratePlans?: UnitTypeRate[];
      fees?: UnitTypeFeeUiModel[];
    } = {};

    if (ratePlanChanged) {
      const notCompleted =
        newRatePlans &&
        newRatePlans?.filter((item) => {
          if (item.amount === 0 || item.name === '' || item.minStay === 0 || item.maxStay === 0) {
            return true;
          } else {
            return false;
          }
        });

      if (notCompleted && notCompleted.length > 0) {
        store.addNotification({
          id: 'edit-unit-type-failed',
          title: 'Rate Plans Data is not complete',
          message: 'Please complete all missing fields in rate plans to be able to proceed',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut']
        });
        return;
      }
      updatedData['ratePlans'] = newRatePlans || [];
    }
    if (feesChanged) {
      const notCompleted =
        newFees &&
        newFees?.filter((item) => {
          if (
            item.amount === 0 ||
            item.name === '' ||
            (item.durationBased &&
              (!item.duration || item.duration.from === 0 || item.duration.to === 0))
          ) {
            return true;
          } else {
            return false;
          }
        });
      if (notCompleted && notCompleted.length > 0) {
        store.addNotification({
          id: 'edit-unit-type-failed',
          title: 'Fees Data is not complete',
          message: 'Please complete all missing fields in fees to be able to proceed',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut']
        });
        return;
      }
      updatedData['fees'] = newFees || [];
    }
    eventHandlers.submitHandler(updatedData);
  };

  return (
    <div className={styles['plan-info-container']}>
      <div className={styles['plan-info-container__sub-title']}>Rate Plans & Fees</div>
      <div className={styles['plan-info-container__wrapper']}>
        <div>
          {newRatePlans && newRatePlans.length > 0 ? (
            <>
              <Button
                onClick={() => {
                  if (newRatePlans) {
                    setNewRatePlans([
                      ...newRatePlans,
                      { name: '', amount: 0, minStay: 0, maxStay: 0 }
                    ]);
                  }
                }}
                size={isMobileView ? ButtonSize.full : ButtonSize.medium}
              >
                Add Rate Plan
              </Button>
              <br />
              {newRatePlans.map((item: UnitTypeRate, index: number) => (
                <div className={styles['plan-info-container__rate-plans']}>
                  <div
                    className={styles['plan-info-container__rate-plans__delete-wrapper']}
                    onClick={() => {
                      if (newRatePlans) {
                        const tempRatePlans = newRatePlans.filter((item, itemIndex) => {
                          return itemIndex !== index;
                        });
                        setNewRatePlans(tempRatePlans);
                        setRatePlanChanged(true);
                      }
                    }}
                  >
                    <DeleteIcon fontSize={'inherit'} color={'error'} />
                  </div>
                  <div className={styles['plan-info-container__field']}>
                    Rate plan name
                    <InputText
                      config={{
                        autoFocus: false,
                        type: InputTextType.text
                        // minLength: 6,
                        // required: true
                      }}
                      data={{
                        placeholder: 'Rate plan name e.g',
                        // errorMessage: t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.PROPERTY_NAME_ERROR_MESSAGE'),
                        value: item.name
                      }}
                      eventHandlers={{
                        onChangeHandler: (value, isValid) => {
                          if (isValid && newRatePlans) {
                            const tempRatePlans = [...newRatePlans];
                            tempRatePlans[index] = {
                              ...tempRatePlans[index],
                              name: value
                            };
                            setNewRatePlans(tempRatePlans);
                            setRatePlanChanged(true);
                          }
                        }
                      }}
                    />
                  </div>
                  <div className={styles['plan-info-container__field']}>
                    Rate plan discount in %
                    <InputText
                      config={{
                        autoFocus: false,
                        type: InputTextType.number
                        // minLength: 6,
                        // required: true
                      }}
                      data={{
                        placeholder: t(
                          'UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.DOWN_PAYMENT_DEPOSIT_PLACEHOLDER'
                        ),
                        // errorMessage: t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.PROPERTY_NAME_ERROR_MESSAGE'),
                        value: item.amount
                      }}
                      eventHandlers={{
                        onChangeHandler: (value, isValid) => {
                          if (isValid && newRatePlans) {
                            const tempRatePlans = [...newRatePlans];
                            tempRatePlans[index] = {
                              ...tempRatePlans[index],
                              amount: Number(value)
                            };
                            setNewRatePlans(tempRatePlans);
                            setRatePlanChanged(true);
                          }
                        }
                      }}
                    />
                    {/* {newReservationMode.value === ReservationMode.MONTHLY
                      ? t('UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.MONTHS')
                      : t('UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.DAYS')} */}
                  </div>
                  <div className={styles['plan-info-container__field']}>
                    Days Range
                    <Slider
                      max={365}
                      value={[item.minStay, item.maxStay]}
                      onChange={(event: any, newValue: number | number[]) => {
                        if (newValue && (newValue as number[]).length > 1) {
                          const tempRatePlans = [...newRatePlans];
                          tempRatePlans[index] = {
                            ...tempRatePlans[index],
                            minStay: (newValue as number[])[0],
                            maxStay: (newValue as number[])[1]
                          };
                          setNewRatePlans(tempRatePlans);
                          setRatePlanChanged(true);
                        }
                      }}
                      valueLabelDisplay="auto"
                      aria-labelledby="range-slider"
                      getAriaValueText={(value: number) => {
                        return `${value} days`;
                      }}
                    />
                  </div>
                  <div className={styles['plan-info-container__field']}>
                    <div className={styles['plan-info-container__field-input']}>
                      From:
                      <InputText
                        config={{
                          autoFocus: false,
                          type: InputTextType.number,
                          minLength: item.maxStay
                        }}
                        data={{
                          value: item.minStay
                        }}
                        eventHandlers={{
                          onChangeHandler: (value, isValid) => {
                            if (value) {
                              const tempRatePlans = [...newRatePlans];
                              tempRatePlans[index] = {
                                ...tempRatePlans[index],
                                minStay: Number(value)
                              };
                              setNewRatePlans(tempRatePlans);
                              setRatePlanChanged(true);
                            }
                          }
                        }}
                      />
                      {t('UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.DAYS')}
                    </div>
                    <div className={styles['plan-info-container__field-input']}>
                      to:
                      <InputText
                        config={{
                          autoFocus: false,
                          type: InputTextType.number
                        }}
                        data={{
                          value: item.maxStay
                        }}
                        eventHandlers={{
                          onChangeHandler: (value, isValid) => {
                            if (value) {
                              const tempRatePlans = [...newRatePlans];
                              tempRatePlans[index] = {
                                ...tempRatePlans[index],
                                maxStay: Number(value)
                              };
                              setNewRatePlans(tempRatePlans);
                              setRatePlanChanged(true);
                            }
                          }
                        }}
                      />
                      {t('UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.DAYS')}
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className={styles['plan-info-container__rate-plans']}>
              No rate plans defined.{' '}
              <Button
                onClick={() => {
                  if (newRatePlans) {
                    setNewRatePlans([
                      ...newRatePlans,
                      { name: '', amount: 0, minStay: 0, maxStay: 0 }
                    ]);
                  }
                }}
                size={isMobileView ? ButtonSize.full : ButtonSize.medium}
              >
                Add Rate Plan
              </Button>
            </div>
          )}
        </div>
        <hr className={styles['plan-info-container__separator']} />

        {t('UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.FEES')}

        <div>
          {newFees && newFees.length > 0 ? (
            <>
              <Button
                onClick={() => {
                  if (newFees) {
                    setNewFees([
                      ...newFees,
                      {
                        name: '',
                        amount: 0,
                        amountType: FeeType.PERCENTAGE,
                        itemType: PaymentItemType.rent
                      }
                    ]);
                  }
                }}
                size={isMobileView ? ButtonSize.full : ButtonSize.medium}
              >
                Add Other Fee (e.g. VAT)
              </Button>
              <br />
              {newFees.map((item: UnitTypeFeeUiModel, index: number) => (
                <div className={styles['plan-info-container__rate-plans']}>
                  <div
                    className={styles['plan-info-container__rate-plans__delete-wrapper']}
                    onClick={() => {
                      if (newFees) {
                        const tempFeesList = newFees.filter((item, itemIndex) => {
                          return itemIndex !== index;
                        });
                        setNewFees(tempFeesList);
                        setFeesChanged(true);
                      }
                    }}
                  >
                    <DeleteIcon fontSize={'inherit'} color={'error'} />
                  </div>
                  <div className={styles['plan-info-container__field']}>
                    <p className={styles['plan-info-container__field__label']}>Rate plan name</p>
                    <InputText
                      config={{
                        autoFocus: false,
                        type: InputTextType.text
                        // minLength: 6,
                        // required: true
                      }}
                      data={{
                        placeholder: 'Fee name e.g VAT',
                        // errorMessage: t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.PROPERTY_NAME_ERROR_MESSAGE'),
                        value: item.name
                      }}
                      eventHandlers={{
                        onChangeHandler: (value, isValid) => {
                          if (isValid && newFees) {
                            const tempFeesList = [...newFees];
                            tempFeesList[index] = {
                              ...tempFeesList[index],
                              name: value
                            };
                            setNewFees(tempFeesList);
                            setFeesChanged(true);
                          }
                        }
                      }}
                    />
                  </div>

                  <div className={styles['plan-info-container__field']}>
                    <p className={styles['plan-info-container__field__label']}>Payment Item Type</p>

                    <FormControl variant="outlined" className={classes.formControl}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={item.itemType}
                        onChange={(
                          event: React.ChangeEvent<{
                            name?: string | undefined;
                            value: unknown;
                          }>
                        ) => {
                          const tempFeesList = [...newFees];
                          tempFeesList[index] = {
                            ...tempFeesList[index],
                            itemType: event.target.value as PaymentItemType
                          };
                          setNewFees(tempFeesList);
                          setFeesChanged(true);
                        }}
                      >
                        <MenuItem value={PaymentItemType.rent}>Rent</MenuItem>
                        <MenuItem value={PaymentItemType.utilities}>Utilities</MenuItem>
                        <MenuItem value={PaymentItemType.cleaning}>Cleaning</MenuItem>
                        <MenuItem value={PaymentItemType.insurance}>Insurance</MenuItem>
                        <MenuItem value={PaymentItemType.others}>others</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div className={styles['plan-info-container__field']}>
                    <p className={styles['plan-info-container__field__label']}>Fee Amount</p>
                    <InputText
                      config={{
                        autoFocus: false,
                        type: InputTextType.number
                        // minLength: 6,
                        // required: true
                      }}
                      data={{
                        placeholder: t(
                          'UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.DOWN_PAYMENT_DEPOSIT_PLACEHOLDER'
                        ),
                        // errorMessage: t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.PROPERTY_NAME_ERROR_MESSAGE'),
                        value: item.amount
                      }}
                      eventHandlers={{
                        onChangeHandler: (value, isValid) => {
                          if (isValid && newFees) {
                            const tempFeesList = [...newFees];
                            tempFeesList[index] = {
                              ...tempFeesList[index],
                              amount: Number(value)
                            };
                            setNewFees(tempFeesList);
                            setFeesChanged(true);
                          }
                        }
                      }}
                    />
                    <FormControl variant="outlined" className={classes.formControl}>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={item.amountType}
                        onChange={(
                          event: React.ChangeEvent<{
                            name?: string | undefined;
                            value: unknown;
                          }>
                        ) => {
                          const tempFeesList = [...newFees];
                          tempFeesList[index] = {
                            ...tempFeesList[index],
                            amountType: event.target.value as FeeType
                          };
                          setNewFees(tempFeesList);
                          setFeesChanged(true);
                        }}
                      >
                        <MenuItem value={FeeType.PERCENTAGE}>%</MenuItem>
                        <MenuItem value={FeeType.AMOUNT}>EGP</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className={styles['plan-info-container__field']}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item.durationBased}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const tempFeesList = [...newFees];
                            tempFeesList[index] = {
                              ...tempFeesList[index],
                              durationBased: event.target.checked
                            };
                            setNewFees(tempFeesList);
                            setFeesChanged(true);
                          }}
                          inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                      }
                      label="Duration Based Fee"
                    />
                  </div>

                  {item.durationBased && (
                    <div className={styles['plan-info-container__field']}>
                      <div className={styles['plan-info-container__field-input']}>
                        From:
                        <InputText
                          config={{
                            autoFocus: false,
                            type: InputTextType.number
                          }}
                          data={{
                            value: item.duration ? item.duration.from : 0
                          }}
                          eventHandlers={{
                            onChangeHandler: (value, isValid) => {
                              if (value) {
                                const tempFeesList = [...newFees];
                                tempFeesList[index] = {
                                  ...tempFeesList[index],
                                  duration: {
                                    from: Number(value),
                                    to:
                                      tempFeesList[index] && tempFeesList[index].duration
                                        ? tempFeesList[index].duration.to
                                        : 0
                                  }
                                };
                                setNewFees(tempFeesList);
                                setFeesChanged(true);
                              }
                            }
                          }}
                        />
                        {t('UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.DAYS')}
                      </div>
                      <div className={styles['plan-info-container__field-input']}>
                        to:
                        <InputText
                          config={{
                            autoFocus: false,
                            type: InputTextType.number
                          }}
                          data={{
                            value: item.duration ? item.duration.to : 0
                          }}
                          eventHandlers={{
                            onChangeHandler: (value, isValid) => {
                              if (value) {
                                const tempFeesList = [...newFees];
                                tempFeesList[index] = {
                                  ...tempFeesList[index],
                                  duration: {
                                    from:
                                      tempFeesList[index] && tempFeesList[index].duration
                                        ? tempFeesList[index].duration.from
                                        : 0,
                                    to: Number(value)
                                  }
                                };
                                setNewFees(tempFeesList);
                                setFeesChanged(true);
                              }
                            }
                          }}
                        />
                        {t('UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.DAYS')}
                      </div>
                    </div>
                  )}

                  <div className={styles['plan-info-container__field']}>
                    <p className={styles['plan-info-container__field__label']}>
                      Channel Manager Fee
                    </p>
                    <FormControl variant="outlined" className={classes.formControl}>
                      <Select
                        labelId="fee-select-outlined-label"
                        id="fee-select-outlined"
                        value={item.cmFeeId}
                        onChange={(
                          event: React.ChangeEvent<{
                            name?: string | undefined;
                            value: unknown;
                          }>
                        ) => {
                          const tempFeesList = [...newFees];
                          tempFeesList[index] = {
                            ...tempFeesList[index],
                            cmFeeId: event.target.value as number
                          };
                          setNewFees(tempFeesList);
                          setFeesChanged(true);
                        }}
                      >
                        {CM_FEES_CONFIG.map((feeItem) => (
                          <MenuItem value={feeItem.id}>{feeItem.text}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className={styles['plan-info-container__rate-plans']}>
              No Fees defined.{' '}
              <Button
                onClick={() => {
                  if (newFees) {
                    setNewFees([
                      ...newFees,
                      {
                        name: '',
                        amount: 0,
                        amountType: FeeType.PERCENTAGE,
                        itemType: PaymentItemType.rent
                      }
                    ]);
                  }
                }}
                size={isMobileView ? ButtonSize.full : ButtonSize.medium}
              >
                Add Other Fee
              </Button>
            </div>
          )}
        </div>
        <hr className={styles['plan-info-container__separator']} />

        <div className={styles['plan-info-container__actions']}>
          <Button
            disabled={!ratePlanChanged && !feesChanged}
            onClick={handleGeneralInfoSubmit}
            tracking={{
              action: 'submit',
              category: 'unit-type',
              label: 'edit-unit-type-rates'
            }}
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          >
            {t('UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.UPDATE_GENERAL_INFO')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditPlanInfo;
