export const UNIT_TYPE_BEDS = [
  {
    label: 'double bed',
    value: '61'
  },
  {
    label: 'double sofa bed',
    value: '200'
  },
  {
    label: 'Extra Bed',
    value: '209'
  },
  {
    label: 'sofabed',
    value: '237'
  },
  {
    label: 'single bed',
    value: '323'
  },
  {
    label: 'king size bed',
    value: '324'
  },
  {
    label: 'Bunk beds (sgl on top, dbl on bottom)',
    value: '384'
  },
  {
    label: 'Armchair that can turn into a single bed ',
    value: '428'
  },
  {
    label: 'Pair of twin beds',
    value: '440'
  },
  {
    label: 'Bunk Bed',
    value: '444'
  },
  {
    label: 'Queen size bed',
    value: '485'
  },
  {
    label: 'Wallbed',
    value: '515'
  },
  {
    label: 'Pull-Out Bed',
    value: '624'
  },
  {
    label: 'Murphy Bed',
    value: '778'
  },
  {
    label: 'Rollaway Beds',
    value: '779'
  },
  {
    label: 'French bed',
    value: '939'
  }
];
