import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './delete-area-modal.module.scss';
import { trackEvent } from '../../../../../shared/services/utils/tracking/tracking.service';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { AreaUiModel } from '../../../../../shared/ui-models/area.ui-model';

export interface DeleteAreaModalProps {
  data: {
    area?: AreaUiModel;
  };
  config: {
    showModal: boolean;
  };
  eventHandlers: {
    cancelHandler: () => void;
    deleteAreaHandler: (area: AreaUiModel) => void;
  };
}

/**
 * functional component DeleteAreaModal
 * @param {DeleteAreaModalProps}
 */
export const DeleteAreaModal: React.FC<DeleteAreaModalProps> = ({
  data,
  config,
  eventHandlers
}) => {
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  const handleDeleteAreaSubmit = () => {
    if (data && data.area) {
      eventHandlers.deleteAreaHandler(data.area);
    }
  };

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.cancelHandler();
        }
      }}
    >
      <div className={styles['add-area-modal']}>
        <div className={styles['add-area-modal__title']}>
          {t('AREA_MODULE.LIST_CONTAINER.DELETE_AREA_MODAL.TITLE')}
        </div>
        <div className={styles['add-area-modal__description']}>
          {`${t('AREA_MODULE.LIST_CONTAINER.DELETE_AREA_MODAL.DESCRIPTION')} ${data.area?.name}`}
        </div>
      </div>
      <div className={styles['add-area-modal--actions']}>
        <Button
          onClick={() => {
            eventHandlers.cancelHandler();
            trackEvent('cancel', {
              category: 'area',
              label: 'delete-area-modal'
            });
          }}
          tracking={{
            action: 'cancel',
            category: 'area',
            label: 'delete-area-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          type={ButtonType.primaryInverted}
        >
          {t('AREA_MODULE.LIST_CONTAINER.DELETE_AREA_MODAL.CANCEL_BUTTON')}
        </Button>
        <Button
          onClick={handleDeleteAreaSubmit}
          tracking={{
            action: 'submit',
            category: 'areas',
            label: 'delete-area-modal'
          }}
          type={ButtonType.danger}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          {t('AREA_MODULE.LIST_CONTAINER.DELETE_AREA_MODAL.SUBMIT_BUTTON')}
        </Button>
      </div>
    </Modal>
  );
};
