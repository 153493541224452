import React, { useEffect, useRef, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import { withVisibility } from './collapsible-button-chart';
import { getInternationAndGuest } from '../shared/services/data.service';
import { isErrorInfo } from '../../shared/interfaces/error-info.interface';
import { KPIChartsSearch } from '../../shared/models/kpis.model';

const InternationalVsLocalGuestChart: React.FC <{query: KPIChartsSearch }> = ({query}) => {
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
    chart: {
      type: 'pie',
      showCheckbox: true,
      showInLegend: true
    },
    title: {
      text: 'international Vs local guest',
      align: 'left'
    },
    tooltip: {
      valueSuffix: '%'
    },
    plotOptions: {
      series: {
        showInLegend: true,
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          distance: -40,
          format: '{point.percentage:.1f}%',
          style: {
            fontSize: '1.2em',
            textOutline: 'none',
            opacity: 0.7
          },
          filter: {
            operator: '>',
            property: 'percentage',
            value: 10
          }
        }
      }
    },
    series: [
      {
        name: 'Percentage',
        colorByPoint: true,
        data: []
      }
    ]
  });
  const chartComponentRef = useRef(null);

  const getInternationAndGuests = (payload: KPIChartsSearch) => {
    chartComponentRef?.current?.chart?.showLoading();
    getInternationAndGuest(payload).then((response) => {
      if (!isErrorInfo(response)) {
        chartComponentRef?.current?.chart?.hideLoading();
        const data = [
          {
            name: 'International',
            y: response.foreigners
          },
          {
            name: 'Local',
            y: response.egyptians
          }
        ];
        const chartUpdatedOptions = {
          ...chartOptions,
          series: [{ ...chartOptions.series[0], data: data }]
        };
        setChartOptions(chartUpdatedOptions);
      }
    });
  };
  useEffect(() => {
    getInternationAndGuests(query);
  }, [query]);

  return <HighchartsReact ref={chartComponentRef} highcharts={Highcharts} options={chartOptions} />;
};

export default withVisibility(InternationalVsLocalGuestChart);
