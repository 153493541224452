import { ReservationActivityModel } from '../models/reservation-activity.model';
import { ReservationActivityUiModel } from '../ui-models/reservation-activity.ui-model';

function mapToUI(reservationActivity: ReservationActivityModel): ReservationActivityUiModel {
  return { ...reservationActivity };
}
function mapToUIList(reservationList: ReservationActivityModel[]): ReservationActivityUiModel[] {
  return reservationList.map(mapToUI);
}

export default {
  mapToUI,
  mapToUIList
};
