import { AmenityChannelModel } from '../models/amenity-channel.model';
import { AmenityChannelUiModel } from '../ui-models/amenity-channel.ui-model';

function mapToUI(amenityData: AmenityChannelModel): AmenityChannelUiModel {
  return {
    AmenityID: amenityData.AmenityID,
    value: amenityData.value
  };
}

function mapToModel(amenityData: AmenityChannelUiModel): AmenityChannelModel {
  return {
    AmenityID: amenityData.AmenityID,
    value: amenityData.value
  };
}

function mapToUIList(amenitiesList: AmenityChannelModel[]): AmenityChannelUiModel[] {
  return amenitiesList.map(mapToUI);
}

function mapToModelList(amenitiesList: AmenityChannelUiModel[]): AmenityChannelModel[] {
  return amenitiesList.map(mapToModel);
}

export default {
  mapToUI,
  mapToModel,
  mapToUIList,
  mapToModelList
};
