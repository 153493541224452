import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './reject-request.module.scss';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import { trackEvent } from '../../../../../shared/services/utils/tracking/tracking.service';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import FormControl from '@material-ui/core/FormControl';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
export enum RejectionReason {
  ALREADY_BOOKED = 'already_booking',
  REJECTED_SCREENING = 'rejected_screening'
}
export interface RejectRequestProps {
  config: {
    showModal: boolean;
  };
  eventHandlers: {
    cancelHandler: () => void;
    rejectReason: (reason: string) => void;
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),

      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  })
);

/**
 * functional component RejectRequest
 * @param {RejectRequestProps}
 */
export const RejectRequest: React.FC<RejectRequestProps> = ({ config, eventHandlers }) => {
  const [rejectReason, setrejectReason] = useState<FormElementData<string>>({
    value: '',
    isValid: false,
    changed: false
  });

  const classes = useStyles();
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  const handleSubmitRoomSelected = () => {
    eventHandlers.rejectReason(rejectReason.value);
    setrejectReason({ value: '', isValid: false, changed: false });
  };

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.cancelHandler();
        }
      }}
    >
      <div className={styles['reject-request']}>
        <div className={styles['reject-request__title']}>Reject booking</div>
        {/* <div className={styles['reject-request__description']}>
          {t('UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.ADD_SHOWROOM_IMAGE_MODAL.NAME')}
        </div> */}
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={rejectReason.value}
            onChange={(
              event: React.ChangeEvent<{
                name?: string | undefined;
                value: unknown;
              }>
            ) => {
              setrejectReason({
                value: event.target.value as string,
                isValid: true,
                changed: true
              });
            }}
          >
            <MenuItem value={''}>Select reason</MenuItem>
            <MenuItem value={RejectionReason.ALREADY_BOOKED}>Already Booked</MenuItem>
            <MenuItem value={RejectionReason.REJECTED_SCREENING}>Screening</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div className={styles['reject-request--actions']}>
        <Button
          onClick={() => {
            eventHandlers.cancelHandler();
            trackEvent('cancel', {
              category: 'cancel',
              label: 'select-available-room'
            });
          }}
          tracking={{
            action: 'cancel',
            category: 'showroom-submit',
            label: 'select-available-room'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          type={ButtonType.primaryInverted}
        >
          Cancel
        </Button>
        <Button
          disabled={!rejectReason.isValid || rejectReason.value === ''}
          onClick={handleSubmitRoomSelected}
          tracking={{
            action: 'submit',
            category: 'submit',
            label: 'select-available-room'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          Reject
        </Button>
      </div>
    </Modal>
  );
};
