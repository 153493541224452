import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Select, { ValueType } from 'react-select';
import styles from './pagination.module.scss';

export const PaginationDropDownList = [
  { value: 10, label: 10 },
  { value: 20, label: 20 },
  { value: 50, label: 50 },
  { value: 100, label: 100 }
];

export interface PaginatedItemsProps {
  itemsPerPage: number;
  itemCounts: number;
  offset: number;
  handlePageChange: (pageNumber: number) => void;
  handleItemsPerPage: (itemsNumber: number) => void;
}
export const Pagination: React.FC<PaginatedItemsProps> = ({
  itemsPerPage,
  itemCounts,
  handlePageChange,
  offset,
  handleItemsPerPage
}) => {
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    setPageCount(Math.ceil(itemCounts / itemsPerPage));
  }, [itemCounts, itemsPerPage]);
  const handlePageClick = (event: any) => {
    handlePageChange(event.selected + 1);
  };

  return (
    <div className={`${styles['pagination-container']}`}>
      <ReactPaginate
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        previousLabel="< previous"
        renderOnZeroPageCount={null}
        forcePage={offset - 1}
      />
      <div className={`${styles['pagination-container--range']}`}>
        {itemCounts > itemsPerPage * offset ? itemsPerPage * offset : itemCounts} /{itemCounts}
      </div>
      <div className={`${styles['pagination-container--range__select']}`}>
        view
        <Select
          value={{ value: itemsPerPage, label: itemsPerPage }}
          options={PaginationDropDownList}
          className={`${styles['pagination-container--range__select--input']}`}
          onChange={(value: ValueType<{ value: number; label: number }, false>) => {
            handleItemsPerPage(value ? value.value : 10);
          }}
        />
        per Page
      </div>
    </div>
  );
};
