import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './add-area-modal.module.scss';
import { VALIDATION_REGEX } from '../../../../../shared/components/validation.config';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import { trackEvent } from '../../../../../shared/services/utils/tracking/tracking.service';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import {
  InputText,
  InputTextErrorMode,
  InputTextType
} from '../../../../../shared/components/core-ui/input-text/input-text.component';

export interface AddAreaModalProps {
  config: {
    showModal: boolean;
  };
  eventHandlers: {
    cancelHandler: () => void;
    addAreaHandler: (areaName: string, order: number) => void;
  };
}

/**
 * functional component AddAreaModal
 * @param {AddAreaModalProps}
 */
export const AddAreaModal: React.FC<AddAreaModalProps> = ({ config, eventHandlers }) => {
  const [newAreaName, setNewAreaName] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [newAreaOrder, setNewAreaOrder] = useState<FormElementData<number>>({
    value: 0,
    isValid: false
  });
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  const handleNewAreaSubmit = () => {
    if (newAreaName.value !== '' && newAreaName.isValid) {
      eventHandlers.addAreaHandler(newAreaName.value, newAreaOrder.value);
    }
  };

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.cancelHandler();
        }
      }}
    >
      <div className={styles['add-area-modal']}>
        <div className={styles['add-area-modal__title']}>
          {t('AREA_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.TITLE')}
        </div>
        <div className={styles['add-area-modal__description']}>
          {t('AREA_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.DESCRIPTION')}
        </div>
        <InputText
          data={{
            defaultValue: '',
            errorMessage: t('AREA_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.INVALID_AREA_NAME'),
            placeholder: t('AREA_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.NAME_PLACEHOLDER')
          }}
          config={{
            maxRows: 1,
            autoFocus: true,
            validationRegex: VALIDATION_REGEX.AREA_NAME,
            showErrorMode: InputTextErrorMode.instant
          }}
          eventHandlers={{
            onChangeHandler: (value: string, isValid: boolean) => {
              setNewAreaName({ value, isValid });
            },
            onEnterPressedHandler: handleNewAreaSubmit
          }}
        />
        <div className={styles['add-area-modal__description']}>Order</div>
        <InputText
          data={{
            defaultValue: '',
            errorMessage: t('AREA_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.INVALID_AREA_NAME'),
            placeholder: 'order'
          }}
          config={{
            maxRows: 1,
            showErrorMode: InputTextErrorMode.instant,
            type: InputTextType.number
          }}
          eventHandlers={{
            onChangeHandler: (value: string, isValid: boolean) => {
              setNewAreaOrder({ value: Number(value), isValid });
            },
            onEnterPressedHandler: handleNewAreaSubmit
          }}
        />
      </div>
      <div className={styles['add-area-modal--actions']}>
        <Button
          onClick={() => {
            eventHandlers.cancelHandler();
            trackEvent('cancel', {
              category: 'area',
              label: 'add-area-modal'
            });
          }}
          tracking={{
            action: 'cancel',
            category: 'builder',
            label: 'builder-intro'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          type={ButtonType.primaryInverted}
        >
          {t('AREA_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.CANCEL_BUTTON')}
        </Button>
        <Button
          disabled={!newAreaName.isValid || newAreaName.value === ''}
          onClick={handleNewAreaSubmit}
          tracking={{
            action: 'submit',
            category: 'areas',
            label: 'add-area-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          {t('AREA_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.SUBMIT_BUTTON')}
        </Button>
      </div>
    </Modal>
  );
};
