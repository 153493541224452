import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './add-rooms-modal.module.scss';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import { trackEvent } from '../../../../../shared/services/utils/tracking/tracking.service';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import {
  InputText,
  InputTextType
} from '../../../../../shared/components/core-ui/input-text/input-text.component';

export interface AddRoomsModalProps {
  config: {
    showModal: boolean;
  };
  data: {
    id: string;
  };
  eventHandlers: {
    cancelHandler: () => void;
    submitRoomHandler: (data: {
      unitId: string;
      shortName: string;
      isActive: boolean;
      roomNumber: string;
    }) => void;
  };
}

/**
 * functional component AddRoomsModal
 * @param {AddRoomsModalProps}
 */
export const AddRoomsModal: React.FC<AddRoomsModalProps> = ({ config, eventHandlers, data }) => {
  const [newRoomName, setNewRoomName] = useState<FormElementData<string>>({
    value: '',
    isValid: false,
    changed: false
  });
  const [newRoomNumber, setNewRoomNumber] = useState<FormElementData<string>>({
    value: '',
    isValid: false,
    changed: false
  });

  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  const handleNewImageSubmit = () => {
    if (newRoomName.value && newRoomNumber.value) {
      eventHandlers.submitRoomHandler({
        roomNumber: newRoomNumber.value,
        shortName: newRoomName.value,
        unitId: data.id,
        isActive: true
      });
    }
  };

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.cancelHandler();
        }
      }}
    >
      <div className={styles['room-modal']}>
        <div className={styles['room-modal__title']}>Add Room</div>
        <div className={styles['room-modal__description']}>Room Id</div>
        <InputText
          config={{
            autoFocus: false,
            type: InputTextType.text,
            disabled: true
          }}
          data={{
            value: data.id
          }}
        />
        <div className={styles['room-modal__description']}>Room Name</div>
        <InputText
          config={{
            autoFocus: false,
            type: InputTextType.text,
            required: true
          }}
          data={{
            value: newRoomName.value
          }}
          eventHandlers={{
            onChangeHandler: (value, isValid) => {
              if (isValid) {
                setNewRoomName({
                  value,
                  isValid: true,
                  changed: true
                });
              }
            }
          }}
        />
        <div className={styles['room-modal__description']}>Room Number</div>
        <InputText
          config={{
            autoFocus: false,
            type: InputTextType.text,
            required: true
          }}
          data={{
            value: newRoomNumber.value
          }}
          eventHandlers={{
            onChangeHandler: (value, isValid) => {
              if (isValid) {
                setNewRoomNumber({
                  value,
                  isValid: true,
                  changed: true
                });
              }
            }
          }}
        />
      </div>
      <div className={styles['room-modal--actions']}>
        <Button
          onClick={() => {
            eventHandlers.cancelHandler();
            trackEvent('cancel', {
              category: 'add-room-cancel',
              label: 'add-room-image'
            });
          }}
          tracking={{
            action: 'cancel',
            category: 'room-submit',
            label: 'add-room-image'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          type={ButtonType.primaryInverted}
        >
          {t('UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.ADD_SHOWROOM_IMAGE_MODAL.CANCEL_BUTTON')}
        </Button>
        <Button
          disabled={
            !newRoomName.isValid ||
            newRoomName.value === '' ||
            !newRoomNumber.isValid ||
            newRoomNumber.value === ''
          }
          onClick={handleNewImageSubmit}
          tracking={{
            action: 'submit',
            category: 'room-submit',
            label: 'add-room-image'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          {t('UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.ADD_SHOWROOM_IMAGE_MODAL.SUBMIT_BUTTON')}
        </Button>
      </div>
    </Modal>
  );
};
