import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { Button as MIButton } from '@material-ui/core';
import { connect } from 'react-redux';
import styles from './payouts-list.module.scss';
import Tooltip from '@material-ui/core/Tooltip';

import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/CloudDownload';

import { useHistory } from 'react-router';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { CreatePayoutModal } from './components/create-payouts/create-payout-modal.component';
import moment from 'moment';
import { CreatePayOutDto, Payout, SearchPayOutDto } from '../../../shared/models/payout.model';
import { UnitTypeUiModel } from '../../../shared/ui-models/unit-type.ui-model';
import useWindowResizer from '../../../shared/hooks/window-resizer/window-resizer.hook';
import { trackPage } from '../../../shared/services/utils/tracking/tracking.service';
import Button, { ButtonSize } from '../../../shared/components/core-ui/button/button.component';
import { createPayouts, deletePayout, uploadPayouts } from '../../shared/services/data.service';
import { searchPayouts } from '../../../UnitTypeModule/shared/services/data.service';
import { isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { getPayouts } from '../../../ExpensesModule/shared/services/data.service';
import { RootState } from '../../../shared/redux/reducers';
import { ExpensesPayoutListFilterContainer } from '../../../shared/components/widgets/expenses-payout-list-filter/expenses-payout-list-filter.component';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
    }
  }
});
const useSelectedRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
      // backgroundColor: '#ffa358'
    }
  }
});
interface PayoutListOwnProps {
  unitType?: UnitTypeUiModel;
  addPayoutHandler: (unitPayout: CreatePayOutDto) => void;
  deletePayoutHandler: (unitPayout: Payout) => void;
}
export type PayoutsListCoContainerProps = ReturnType<typeof mapStateToProps>;

/**
 * functional component ReservationsListContainer
 * @param {PayoutsListCoContainerProps} holding question text
 */
export const PayoutsListContainer: React.FC<PayoutsListCoContainerProps> = ({
  isAuthenticated,
  unitType
}) => {
  const [showCreateReseration, setShowCreateReseration] = useState<boolean>(false);
  const [payouts, setPayouts] = useState<Payout[]>([]);

  useEffect(() => {
    trackPage('payouts-list');
    getPayoutList({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPayoutList = (query: object) => {
    getPayouts(query).then((response) => {
      if (!isErrorInfo(response)) {
        setPayouts(response);
      }
    });
  };

  const addPayoutHandler = (payout: Payout) => {
    createPayouts(payout).then((response) => {
      if (!isErrorInfo(response)) {
        console.log(response.data);
        uploadPayouts(response?.data?.id, payout?.file).then(() => {
          console.log(response.data);
          getPayoutList({ unitTypeId: Number(unitType?.id) || 0 });
        });
      }
    });
  };
  const deletePayoutHandler = (payout: Payout) => {
    deletePayout(payout.id).then(() => {
      getPayoutList({ unitTypeId: Number(unitType?.id) || 0 });
    });
  };

  const searchPayouts = (filteredData: {
    room: string;
    description: string;
    project: string;
    area: string;
    category: string;
    paymentDateFrom: Date;
    paymentDateTo: Date;
    amount: string;
  }) => {
    getPayoutList(filteredData);
  };
  function Row(props: { payout: Payout }) {
    const { payout } = props;
    const classes = useRowStyles();
    const selectedClasses = useSelectedRowStyles();
    return (
      <>
        <TableRow>
          <TableCell>{payout?.description}</TableCell>
          <TableCell>{payout?.roomName}</TableCell>
          <TableCell>{payout?.projectName}</TableCell>
          <TableCell>{payout?.areaName}</TableCell>
          <TableCell> {moment(payout.paymentDate).format('MM/DD/YYYY')}</TableCell>
          <TableCell>{payout?.category}</TableCell>
          <TableCell>{payout?.amount}</TableCell>
          <TableCell>{payout.updateByUser}</TableCell>
          <TableCell>{moment(payout.updatedAt).format('MM/DD/YYYY')}</TableCell>

          <TableCell>
            <div style={{ display: 'flex' }}>
              <MIButton
                aria-haspopup="true"
                onClick={() => {
                  deletePayoutHandler(payout);
                }}
              >
                <DeleteIcon fontSize={'large'} color="inherit" />
              </MIButton>
              {payout.paymentReceipt?.receiptUrl && (
                <MIButton aria-haspopup="true">
                  <a href={payout.paymentReceipt?.receiptUrl}>
                    <DownloadIcon fontSize={'large'} color="inherit" />
                  </a>
                </MIButton>
              )}
            </div>
          </TableCell>
        </TableRow>
        <TableRow></TableRow>
      </>
    );
  }
  return (
    <div className={styles['payout-list-container']}>
      <CreatePayoutModal
        config={{
          showModal: showCreateReseration,
          unitType
        }}
        eventHandlers={{
          closeHandler: () => {
            setShowCreateReseration(false);
          },
          addPayoutHandler: (payout) => {
            setShowCreateReseration(false);
            addPayoutHandler(payout);
          }
        }}
      ></CreatePayoutModal>
      <div className={styles['payout-list-container__title']}>
        <span>Payouts</span>
        <div className={styles['payout-list-container__title__actions']}>
          <Button
            onClick={() => {
              setShowCreateReseration(true);
            }}
            tracking={{
              action: 'open',
              category: 'create-payout',
              label: 'add-create-payout-modal'
            }}
            size={ButtonSize.medium}
          >
            Create Payouts
          </Button>
        </div>
      </div>

      <ExpensesPayoutListFilterContainer
        data={payouts}
        eventHandlers={{
          search: (data: {
            room: string;
            description: string;
            project: string;
            area: string;
            category: string;
            paymentDateFrom: Date;
            paymentDateTo: Date;
            amount: string;
          }) => searchPayouts(data),
          getInitialList: () => getPayoutList({})
        }}
      />

      <TableContainer component={Paper}>
        <Table aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell>description</TableCell>
              <TableCell>room Name</TableCell>
              <TableCell>project Name</TableCell>
              <TableCell>Area Name</TableCell>
              <TableCell>paymentDate</TableCell>
              <TableCell>category</TableCell>
              <TableCell>amount</TableCell>
              <TableCell>updated By</TableCell>
              <TableCell>updated At</TableCell>
              <TableCell>actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payouts?.map((payout) => (
              <Row key={payout.id} payout={payout} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default connect(mapStateToProps)(PayoutsListContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState, props: PayoutListOwnProps) {
  return {
    isAuthenticated: !!state.auth.accessToken,
    ...props
  };
}
