import React, { useState } from 'react';
import styles from './image-uploader.module.scss';
import 'react-image-crop/lib/ReactCrop.scss';
import ImageUploading, { ImageListType, ImageType } from 'react-images-uploading';
import { useTranslation } from 'react-i18next';
import ReactCrop, { Crop } from 'react-image-crop';
import { cropConfig } from './image-uploader.config';
import useWindowResizer from '../../../hooks/window-resizer/window-resizer.hook';
import {
  dataURLtoFile,
  getCroppedImg
} from '../../../services/helpers/image-helper/image-helper.service';
import { PUBLIC_PATH } from '../../../config/routes-config';
import Button, { ButtonSize, ButtonType } from '../button/button.component';
import Modal from '../modal/modal.component';
/**
 * ImageUploader interface
 * - fileUploadHandler:Function (callback function that triggers when image uploaded)
 */
export interface ImageUploaderProps {
  eventHandlers: {
    fileUploadHandler: (file: ImageType, width?: number, height?: number) => void;
    setImageAspectRatio?: (aspectRatio: number | null) => void;
    imageLoadSuccessHandler?: () => void;
    imageCroppedSuccessHandler?: () => void;
    imageRemovedHandler: () => void;
  };
  data: {
    image?: ImageListType | null;
    imageUrl?: string;
  };
  config: {
    multiple: boolean;
    enableCrop: boolean;
    canvasSize?: 'sm' | 'md' | 'lg';
    cropAspectRatio?: {
      width: number;
      height: number;
    };
  };
}

/**
 * ImageUploader component renders a image uploader
 * @param  fileUploadHandler:Function (callback function that triggers when image uploaded)
 */
const ImageUploader: React.FC<ImageUploaderProps> = ({ eventHandlers, data, config }) => {
  const [images, setImages] = useState([]);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [removeImage, setRemoveImage] = useState<boolean>(false);
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [crop, setCrop] = useState<Crop>({
    ...cropConfig,
    aspect: config.cropAspectRatio
      ? config.cropAspectRatio.width / config.cropAspectRatio.height
      : 16 / 9
  });
  const [completedCrop, setCompletedCrop] = useState<Crop>({
    unit: '%',
    aspect: config.cropAspectRatio
      ? config.cropAspectRatio.width / config.cropAspectRatio.height
      : 16 / 9
  });
  const [selectedImageToCrop, setSelectedImageToCrop] = useState<ImageType | null>(null);
  const [showNoSelectionMessage, setShowNoSelectionMessage] = useState(false);
  const [renderedImageDimensions, setRenderedImageDimensions] = useState<{
    height: number;
    width: number;
  }>();

  const maxNumber = 69;
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  const onChange = (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {
    // let nearestAspectRatio;
    // if (imageList[0]?.dataURL && !removeImage) {
    //   const img = new Image();

    //   img.src = imageList[0].dataURL;

    //   img.onload = () => {
    //     const imgWidth = img.naturalWidth;
    //     const imgHeight = img.naturalHeight;
    //     nearestAspectRatio = getNearestAspectRatio(imgWidth, imgHeight);
    //     if (eventHandlers && eventHandlers.setImageAspectRatio)
    //       eventHandlers.setImageAspectRatio(nearestAspectRatio);
    //   };
    // }
    if (config.enableCrop && !removeImage) {
      // setCrop({ ...cropConfig, aspect: nearestAspectRatio });
      setShowCropModal(true);
      setSelectedImageToCrop(imageList[0]);
    } else {
      // data for submit
      setImages(imageList as never[]);
      eventHandlers.fileUploadHandler(imageList || null);
      setRemoveImage(false);
    }
  };
  if (data.image && data.image && images.length === 0) {
    setImages([data.image] as never[]);
    setSelectedImageToCrop([data.image] as never[]);
  }
  /**
   * handle cropping the image and generating the file
   */
  const onImageCropConfirm = (): void => {
    setShowNoSelectionMessage(false);
    const croppedImage: string | null = getCroppedImg(
      selectedImageToCrop,
      renderedImageDimensions?.height || 0,
      renderedImageDimensions?.width || 0,
      completedCrop
    );
    const imageFile = dataURLtoFile(croppedImage || '', `${selectedImageToCrop?.file?.name}`);
    if (imageFile) {
      setImages([imageFile] as never[]);
      eventHandlers.fileUploadHandler(
        imageFile || null,
        renderedImageDimensions?.width || 0,
        renderedImageDimensions?.height || 0
      );
      if (eventHandlers && eventHandlers.imageCroppedSuccessHandler)
        eventHandlers.imageCroppedSuccessHandler();
      // setCrop(cropConfig);
      setRemoveImage(true);
      setSelectedImageToCrop([data.image] as never[]);
    } else {
      setShowNoSelectionMessage(true);
    }
  };
  const onImageCropCancel = () => {
    // setCrop(cropConfig);
    setSelectedImageToCrop([] as never[]);
    if (eventHandlers && eventHandlers.setImageAspectRatio) eventHandlers.setImageAspectRatio(null);
    setImages([] as never[]);
    if (eventHandlers && eventHandlers.imageCroppedSuccessHandler)
      eventHandlers.imageCroppedSuccessHandler();
  };
  return (
    <div>
      <ImageUploading
        multiple={config.multiple}
        // dataURLKey={imageUrl}
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        maxFileSize={10485760}
        onError={(error) => {
          if (error?.maxFileSize) {
            setShowErrorModal(true);
          }
        }}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps
        }) => (
          <div
            className={`${styles['upload-image-wrapper']} ${
              config.canvasSize
                ? styles['upload-image-wrapper--' + config.canvasSize]
                : styles['upload-image-wrapper--default']
            }`}
          >
            {!images.length && (
              <div
                className={`${styles['upload-image-wrapper__area']} ${
                  isDragging ? styles['upload-image-wrapper__area--drag'] : ''
                }`}
                onClick={onImageUpload}
                {...dragProps}
              >
                <p className={styles['upload-image-wrapper__area__header']}>
                  <b>{t('SHARED.IMAGE_UPLOADER.UPLOAD')} </b> {t('SHARED.IMAGE_UPLOADER.HEADER')}
                </p>
                <p className={styles['upload-image-wrapper__area__extension']}>
                  {t('SHARED.IMAGE_UPLOADER.EXTENSION')}
                </p>
              </div>
            )}
            {imageList.map((image, index) => (
              <div key={index} className={styles['upload-image-wrapper__preview']}>
                <img src={image.dataURL || (data.image ? data.imageUrl : '')} alt="upload-url" />
                <div
                  onClick={() => {
                    setRemoveImage(true);
                    // setCrop(cropConfig);
                    if (eventHandlers && eventHandlers.setImageAspectRatio)
                      eventHandlers.setImageAspectRatio(null);
                    onImageRemove(index);
                    eventHandlers.imageRemovedHandler();
                  }}
                  className={styles['upload-image-wrapper__preview__close']}
                >
                  <img src={`${PUBLIC_PATH}/assets/svgs/close.svg`} alt="uploaded-url" />
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>

      <Modal
        config={{ showModal: showErrorModal, fullScreen: false, showClose: true }}
        eventHandlers={{
          closeModalHandler: () => {
            setShowErrorModal(false);
          }
        }}
      >
        <div className={styles['error-modal-wrapper']}>
          <p className={styles['error-modal-wrapper__modal-title']}>
            {t('SHARED.IMAGE_UPLOADER.ERROR_MODAL.TITLE')}
          </p>

          <p className={styles['error-modal-wrapper__modal-description']}>
            {t('SHARED.IMAGE_UPLOADER.ERROR_MODAL.DESCRIPTION')}
          </p>

          <div className={styles['error-modal-wrapper__actions']}>
            <Button
              onClick={() => {
                setShowErrorModal(false);
              }}
              tracking={{
                action: 'image-uploader-error|submit',
                category: 'builder',
                label: 'builder-stimuli'
              }}
              size={isMobileView ? ButtonSize.full : ButtonSize.medium}
            >
              {t('SHARED.IMAGE_UPLOADER.ERROR_MODAL.SUBMIT_BUTTON')}
            </Button>
          </div>
        </div>
      </Modal>
      {selectedImageToCrop?.dataURL && config.enableCrop && (
        <Modal
          config={{ showModal: showCropModal, fullScreen: false, showClose: true }}
          eventHandlers={{
            closeModalHandler: () => {
              setShowCropModal(false);
              if (eventHandlers && eventHandlers.imageCroppedSuccessHandler)
                eventHandlers.imageCroppedSuccessHandler();
            }
          }}
        >
          <div className={styles['crop-image-modal-container']}>
            <div className={styles['crop-image-modal-container__modal-title']}>
              {t('SHARED.IMAGE_UPLOADER.CROP_IMAGE_MODAL.TITLE')}
            </div>
            <div className={styles['crop-image-modal-container__modal-description']}>
              {t('SHARED.IMAGE_UPLOADER.CROP_IMAGE_MODAL.DESCRIPTION')}
            </div>
            <ReactCrop
              keepSelection={false}
              onImageLoaded={(image) => {
                // const nearestAspectRatio = getNearestAspectRatio(image.width, image.height);
                setRenderedImageDimensions({ height: image.height, width: image.width });
                // setCrop({
                //   ...crop,
                //   unit: '%',
                //   aspect: nearestAspectRatio
                // });
                return false;
              }}
              imageStyle={{ maxHeight: '50vh' }}
              className={styles['crop-image-modal-container__crop-container']}
              src={selectedImageToCrop?.dataURL || ''}
              crop={crop}
              onChange={(cropObj: Crop) => {
                setCrop(cropObj);
              }}
              onComplete={(cropObj, percentCrop) => {
                setCompletedCrop(cropObj);
              }}
              ruleOfThirds={true}
            />
            {showNoSelectionMessage && (
              <div className={styles['crop-image-modal-container__no-selection']}>
                {t('SHARED.IMAGE_UPLOADER.CROP_IMAGE_MODAL.NO_SELECTION_MESSAGE')}
              </div>
            )}
            <div className={styles['crop-image-modal-container__actions']}>
              <Button
                onClick={onImageCropCancel}
                tracking={{
                  action: 'image-cropper|cancelled',
                  category: 'builder',
                  label: 'builder-stimuli'
                }}
                size={isMobileView ? ButtonSize.full : ButtonSize.medium}
                type={ButtonType.primaryInverted}
              >
                {t('SHARED.IMAGE_UPLOADER.CROP_IMAGE_MODAL.CANCEL')}
              </Button>
              <Button
                onClick={onImageCropConfirm}
                tracking={{
                  action: 'image-cropper|submit',
                  category: 'builder',
                  label: 'builder-stimuli'
                }}
                size={isMobileView ? ButtonSize.full : ButtonSize.medium}
              >
                {t('SHARED.IMAGE_UPLOADER.CROP_IMAGE_MODAL.CONFIRM')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ImageUploader;
