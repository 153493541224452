import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { Button as MIButton } from '@material-ui/core';
import { connect } from 'react-redux';
import styles from './reservation-list.module.scss';
import Tooltip from '@material-ui/core/Tooltip';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ErrorInfo, isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { RootState } from '../../../shared/redux/reducers';
import { trackPage } from '../../../shared/services/utils/tracking/tracking.service';
import useWindowResizer from '../../../shared/hooks/window-resizer/window-resizer.hook';
import { InputText } from '../../../shared/components/core-ui/input-text/input-text.component';
import Select, { ValueType } from 'react-select';
import { getAllAreas } from '../../../shared/services/data/lookups.data';
import { AreaUiModel } from '../../../shared/ui-models/area.ui-model';
import { getAllUnitTypes } from '../../../shared/services/data/unit-type.data';
import { UnitTypeUiModelResponse } from '../../../shared/ui-models/unit-type.ui-model';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../shared/components/core-ui/button/button.component';
import { downloadCSV, getModuleName } from '../../shared/services/reservation.service';
import { getAllReservations } from '../../shared/services/data.service';
import {
  ReservationListUiModel,
  ReservationUiModel
} from '../../../shared/ui-models/reservation.ui-model';
import { Tabs, TabsPosition } from '../../../shared/components/group-ui/tabs/tabs.component';
import { ReservationStatus } from '../../../shared/enums/reservation-status';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router';
import { APP_ROUTES, PUBLIC_PATH } from '../../../shared/config/routes-config';
import { RouteConfig } from '../../../shared/interfaces/routes-config.interface';
import { CreateReservationModal } from './components/create-reservation/create-reservation-modal.component';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Countdown from 'react-countdown';
import { ReservationChannel } from '../../../shared/enums/reservation-channel.enum';
import { ReservationSource } from '../../../shared/enums/reservation-souce.enum';
import { hasAccess } from '../../../shared/services/helpers/auth-helper/auth-helper.service';
import { PERMISSIONS_LIST } from '../../../shared/config/permissions.config';
import { Pagination } from '../../../shared/components/core-ui/pagination/pagination.component';
import ReactDatePicker from 'react-datepicker';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
    }
  }
});
const useSelectedRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
      // backgroundColor: '#ffa358'
    }
  }
});

export type ReservationsListContainerProps = ReturnType<typeof mapStateToProps>;

/**
 * functional component ReservationsListContainer
 * @param {ReservationsListContainerProps} holding question text
 */
export const ReservationsListContainer: React.FC<ReservationsListContainerProps> = ({
  isAuthenticated,
  userId
}) => {
  const isMobileView = useWindowResizer();
  const [guestName, setGuestName] = useState('');
  const [id, setId] = useState('');
  const [area, setArea] = useState('');
  const [reservations, setReservations] = useState<ReservationUiModel[]>([]);
  const [unitType, setUnitType] = useState<{
    value: string | undefined;
    label: string | undefined;
  }>({
    value: '',
    label: ''
  });
  const [areaOptions, setAreaOptions] = useState<{ value: string; label: string }[]>([]);
  const [unitTypeOptions, setUnitTypeOptions] = useState<
    { value: string | undefined; label: string }[]
  >([]);
  const [showCreateReseration, setShowCreateReseration] = useState<boolean>(false);
  const [reservationType, setReservationType] = useState<string>();
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [listCount, setListCount] = useState<number>(0);
  const [offset, setOffset] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [selectedStatus, setSelectedStatus] = useState<any>({
    status: ReservationStatus.CHECKED_IN_STARTED
  });
  const [searchQuery, setSearchQuery] = useState<any>({});
  const [startCreatedDate, setStartCreatedDate] = useState<Date>();
  const [endCreatedDate, setEndCreatedDate] = useState<Date>();
  const [CheckInDate, setCheckInDate] = useState<Date>();
  const [checkoutDate, setCheckoutDate] = useState<Date>();

  const reInitialize = () => {
    setOffset(1);
    setArea('');
    setGuestName('');
    setId('');
    setStartCreatedDate(undefined);
    setEndCreatedDate(undefined);
    setCheckInDate(undefined);
    setCheckoutDate(undefined);
    setSearchQuery({});
    setUnitType({ value: '', label: '' });
  };
  useEffect(() => {
    trackPage('reservations-list');
    reInitialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getAllAreas(getModuleName()).then((response: AreaUiModel[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setAreaOptions(response.map((area) => ({ value: area.name || '', label: area.name })));
      }
    });
    getAllUnitTypes(getModuleName(), 0, -1, {}).then(
      (response: UnitTypeUiModelResponse | ErrorInfo) => {
        if (!isErrorInfo(response)) {
          setUnitTypeOptions(
            response?.unitList?.map((unitType) => ({
              value: unitType.id,
              label: unitType.name || ''
            }))
          );
        }
      }
    );
    getReservations({ status: ReservationStatus.CHECKED_IN_STARTED }, offset, limit);
  }, []);
  useEffect(() => {
    getReservations({ ...selectedStatus, ...searchQuery }, offset, limit);
  }, [limit]);
  const getReservations = (query: object, offset: number, limit: number) => {
    setLoadingData(true);
    getAllReservations(query, offset, limit).then(
      (response: ReservationListUiModel | ErrorInfo) => {
        setLoadingData(false);
        if (!isErrorInfo(response)) {
          setListCount(response?.count);
          setReservations(response?.reservationList);
        } else {
          setReservations([]);
          setListCount(0);
          setReservations([]);
        }
      }
    );
  };

  const getNewReservation = (offsetNumber: number) => {
    setOffset(offsetNumber);
    getReservations({ ...selectedStatus, ...searchQuery }, offsetNumber, limit);
  };
  const counterRenderer = ({
    hours,
    minutes,
    seconds
  }: {
    hours: number;
    minutes: number;
    seconds: number;
  }) => {
    // Render a countdown
    return (
      <b className={styles['reservation-list-container__reservation-item__status--highlighted']}>
        {hours}:{minutes}:{seconds}
      </b>
    );
  };

  function Row(props: { reservation: ReservationUiModel }) {
    const { reservation } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const selectedClasses = useSelectedRowStyles();

    return (
      <>
        <TableRow
          className={
            reservation.status === ReservationStatus.CHECKED_IN_STARTED
              ? selectedClasses.root
              : classes.root
          }
        >
          <TableCell>
            <div
              className={
                styles['reservation-list-container__reservation-item__reservation-card__actions']
              }
            >
              <MIButton
                aria-controls="property-menu"
                aria-haspopup="true"
                onClick={() => {
                  window.open(
                    PUBLIC_PATH +
                      (
                        APP_ROUTES.RESERVATION_MODULE.CHILDREN as RouteConfig
                      ).EDIT.FULL_PATH.replace(':id', reservation.id as string),
                    '_blank'
                  );
                }}
              >
                <EditIcon fontSize={'large'} color="inherit" />
              </MIButton>
            </div>
          </TableCell>
          <TableCell component="th" scope="row">
            <div
              className={
                styles['reservation-list-container__reservation-item__reservation-card__channel']
              }
            >
              {reservation.source === ReservationSource.BN_ADMIN ? (
                <img
                  className={
                    styles[
                      'reservation-list-container__reservation-item__reservation-card__channel-image'
                    ]
                  }
                  src={`${PUBLIC_PATH}/assets/jpeg/admin.webp`}
                />
              ) : reservation.source === ReservationSource.BN_APP ? (
                <img
                  className={
                    styles[
                      'reservation-list-container__reservation-item__reservation-card__channel-image__bigger'
                    ]
                  }
                  src={`${PUBLIC_PATH}/assets/pngs/bn-app.png`}
                />
              ) : reservation.source === ReservationSource.BN_WEBSITE ? (
                <img
                  className={
                    styles[
                      'reservation-list-container__reservation-item__reservation-card__channel-image__bigger'
                    ]
                  }
                  src={`${PUBLIC_PATH}/assets/pngs/bn-website.png`}
                />
              ) : reservation.source === ReservationSource.CHANNEL_MANAGER ? (
                <img
                  className={
                    styles[
                      'reservation-list-container__reservation-item__reservation-card__channel-image'
                    ]
                  }
                  src={`${PUBLIC_PATH}/assets/jpeg/rentals-united.jpeg`}
                />
              ) : (
                <img
                  className={
                    styles[
                      'reservation-list-container__reservation-item__reservation-card__channel-image'
                    ]
                  }
                  src={`${PUBLIC_PATH}/assets/pngs/question-mark.png`}
                />
              )}
              <ChevronRightIcon fontSize="inherit" />
              {reservation.channel === ReservationChannel.AIRBNB ? (
                <img
                  className={
                    styles[
                      'reservation-list-container__reservation-item__reservation-card__channel-image'
                    ]
                  }
                  src={`${PUBLIC_PATH}/assets/pngs/airbnb.png`}
                />
              ) : reservation.channel === ReservationChannel.EXPEDIA ? (
                <img
                  className={
                    styles[
                      'reservation-list-container__reservation-item__reservation-card__channel-image'
                    ]
                  }
                  src={`${PUBLIC_PATH}/assets/pngs/expedia.png`}
                />
              ) : reservation.channel === ReservationChannel.BOOKING ? (
                <img
                  className={
                    styles[
                      'reservation-list-container__reservation-item__reservation-card__channel-image'
                    ]
                  }
                  src={`${PUBLIC_PATH}/assets/pngs/booking.png`}
                />
              ) : reservation.channel === ReservationChannel.VRBO ? (
                <img
                  className={
                    styles[
                      'reservation-list-container__reservation-item__reservation-card__channel-image'
                    ]
                  }
                  src={`${PUBLIC_PATH}/assets/pngs/vrbo.png`}
                />
              ) : (
                <img
                  className={
                    styles[
                      'reservation-list-container__reservation-item__reservation-card__channel-image'
                    ]
                  }
                  src={`${PUBLIC_PATH}/assets/pngs/birdnest-logo.png`}
                />
              )}
            </div>
          </TableCell>
          <TableCell component="th" scope="row">
            {reservation.id}
          </TableCell>
          <TableCell>
            <div
              className={`${styles['reservation-list-container__reservation-item__status']} ${
                styles[
                  `reservation-list-container__reservation-item__status--${reservation.status}`
                ]
              }`}
            >
              {reservation.status ? reservation.status.replace('_', ' ') : ''}{' '}
              {reservation.status === ReservationStatus.CONFIRMATION_PENDING ||
              reservation.status === ReservationStatus.CHECKED_IN_STARTED ? (
                <Countdown
                  date={
                    new Date(
                      new Date(Number(reservation.checkinExpiresAt)).getTime() + 60 * 60 * 24 * 1000
                    )
                  }
                  renderer={counterRenderer}
                />
              ) : (
                ''
              )}
            </div>
            {!reservation.guestIds ||
              (reservation.guestIds.length === 0 && (
                <div
                  className={`${styles['reservation-list-container__reservation-item__status']} ${
                    styles[`reservation-list-container__reservation-item__status--canceled`]
                  }`}
                >
                  {reservation.source !== ReservationSource.CHANNEL_MANAGER &&
                  (!reservation.guestIds || reservation.guestIds.length === 0)
                    ? 'Guest Data incomplete'
                    : ''}
                </div>
              ))}
          </TableCell>
          <TableCell>{reservation.primaryGuestName}</TableCell>
          <TableCell>{new Date(reservation.checkInDate).toDateString()}</TableCell>
          <TableCell>{new Date(reservation.checkOutDate).toDateString()}</TableCell>
          <TableCell>{reservation.area}</TableCell>
          <TableCell>{reservation.propertyName}</TableCell>
          <TableCell>
            <Tooltip
              title={
                <span
                  className={
                    styles[
                      'reservation-list-container__reservation-item__reservation-card__item__tooltip'
                    ]
                  }
                >
                  {reservation.unitName}
                </span>
              }
            >
              <div
                className={
                  styles[
                    'reservation-list-container__reservation-item__reservation-card__item__value'
                  ]
                }
              >
                {reservation.unitName.replace(/(^.*\(|\).*$)/g, '')}
              </div>
            </Tooltip>
          </TableCell>
        </TableRow>
        <TableRow></TableRow>
      </>
    );
  }

  return (
    <div className={styles['reservation-list-container']}>
      <CreateReservationModal
        config={{
          showModal: showCreateReseration,
          reservationType,
          className: styles['reservation-list-container__modal']
        }}
        eventHandlers={{
          closeHandler: () => {
            setShowCreateReseration(false);
          }
        }}
      ></CreateReservationModal>
      <div className={styles['reservation-list-container__title']}>
        <span>Reservations</span>
        {hasAccess([
          PERMISSIONS_LIST.reservationsFullAccess,
          PERMISSIONS_LIST.reservationsModifyAccess
        ]) && (
          <div className={styles['reservation-list-container__title__actions']}>
            <Button
              onClick={() => {
                setReservationType('NORMAL_RESERVATION');
                setShowCreateReseration(true);
              }}
              tracking={{
                action: 'open',
                category: 'create-reservation',
                label: 'add-create-reservation-modal'
              }}
              size={ButtonSize.medium}
            >
              Create Reservations
            </Button>

            <Button
              onClick={() => {
                setReservationType('BLOCK_DATES');
                setShowCreateReseration(true);
              }}
              tracking={{
                action: 'open',
                category: 'blocked-date',
                label: 'add-blocked-date-modal'
              }}
              type={ButtonType.primaryInverted}
              size={ButtonSize.medium}
            >
              Block Dates
            </Button>
          </div>
        )}
      </div>

      <Tabs
        data={{
          tabsList: [
            { component: <></>, key: 'All' },
            { component: <></>, key: 'Attention Required' },
            { component: <></>, key: 'Follow up Required' },
            { component: <></>, key: 'Currently Hosting' },
            { component: <></>, key: 'Upcoming' },
            { component: <></>, key: 'Checked Out' },
            { component: <></>, key: 'Cancelled' },
            { component: <></>, key: 'My assigned reservations' },
            { component: <></>, key: 'Today reservations' },
          ],
          defaultTab: 'Attention Required'
        }}
        style={{ position: TabsPosition.LEFT }}
        eventHandlers={{
          tabChangeHandler: (tabKey) => {
            reInitialize();
            setSelectedStatus({});
            if (tabKey === 'All') {
              getReservations({}, 1, limit);
            } else if (tabKey === 'Attention Required') {
              setSelectedStatus({ status: ReservationStatus.CHECKED_IN_STARTED });
              getReservations(
                {
                  status: ReservationStatus.CHECKED_IN_STARTED
                },
                1,
                limit
              );
            } else if (tabKey === 'Upcoming') {
              setSelectedStatus({ status: ReservationStatus.CHECKED_IN });
              getReservations({ status: ReservationStatus.CHECKED_IN }, 1, limit);
            } else if (tabKey === 'Currently Hosting') {
              setSelectedStatus({ status: ReservationStatus.IN_HOUSE });
              getReservations({ status: ReservationStatus.IN_HOUSE }, 1, limit);
            } else if (tabKey === 'Follow up Required') {
              setSelectedStatus({
                statusQuery: [ReservationStatus.CONFIRMATION_PENDING, ReservationStatus.EXPIRED]
              });
              getReservations(
                {
                  statusQuery: [ReservationStatus.CONFIRMATION_PENDING, ReservationStatus.EXPIRED]
                },
                1,
                limit
              );
            } else if (tabKey === 'Checked Out') {
              setSelectedStatus({ status: ReservationStatus.CHECKED_OUT });
              getReservations({ status: ReservationStatus.CHECKED_OUT }, 1, limit);
            } else if (tabKey === 'Cancelled') {
              setSelectedStatus({ status: ReservationStatus.CANCELED });
              getReservations({ status: ReservationStatus.CANCELED }, 1, limit);
            }
            else if (tabKey === 'My assigned reservations') {
              getReservations({ assignedReservations: { userId } }, 1, limit);
            }
            else if (tabKey === 'Today reservations') {
              setSearchQuery({ range: 'today' });
              getReservations({ range: 'today' }, 1, limit);
            }
          }
        }}
      />
      <div className={styles['reservation-list-container__reservation-item']}>
        <div className={styles['reservation-list-container__reservation-item__form-field']}>
          <div
            className={styles['reservation-list-container__reservation-item__form-field__label']}
          >
            Reservation number
          </div>
          <InputText
            data={{
              value: id
            }}
            config={{
              maxRows: 1,
              autoFocus: false
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setId(value);
              }
            }}
          />
        </div>
        <div className={styles['reservation-list-container__reservation-item__form-field']}>
          <div
            className={styles['reservation-list-container__reservation-item__form-field__label']}
          >
            Guest Name
          </div>
          <InputText
            data={{
              value: guestName
            }}
            config={{
              maxRows: 1,
              autoFocus: false
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setGuestName(value);
              }
            }}
          />
        </div>
        <div className={styles['reservation-list-container__reservation-item__form-field']}>
          <div
            className={styles['reservation-list-container__reservation-item__form-field__label']}
          >
            Area
          </div>
          <Select
            value={{ value: area, label: area }}
            options={areaOptions}
            className={styles['reservation-list-container__reservation-item__form-field__select']}
            onChange={(value: ValueType<{ value: string; label: string }, false>) => {
              setArea(value ? value.value : '');
            }}
          />
        </div>
        <div className={styles['reservation-list-container__reservation-item__form-field']}>
          <div
            className={styles['reservation-list-container__reservation-item__form-field__label']}
          >
            Unit Type
          </div>
          <Select
            value={{ value: unitType?.value, label: unitType?.label }}
            className={styles['reservation-list-container__reservation-item__form-field__select']}
            options={unitTypeOptions}
            onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
              setUnitType({ value: value?.value, label: value?.label });
            }}
          />
        </div>
        <div className={styles['reservation-list-container__reservation-item__form-field']}>
          <div
            className={styles['reservation-list-container__reservation-item__form-field__label']}
          >
            created at
          </div>
          <div className={styles['reservation-list-container__reservation-item__date-range']}>
            <ReactDatePicker
              selected={startCreatedDate}
              onChange={(date: Date) => {
                setStartCreatedDate(date);
                setEndCreatedDate(undefined);
              }}
              selectsStart
              startDate={startCreatedDate}
              endDate={endCreatedDate}
              placeholderText="Start Date"
            />
            <ReactDatePicker
              selected={endCreatedDate}
              onChange={(date: Date) => {
                setEndCreatedDate(date);
              }}
              selectsEnd
              startDate={startCreatedDate}
              endDate={endCreatedDate}
              minDate={startCreatedDate}
              placeholderText="End Date"
            />
          </div>
        </div>
        <div className={styles['reservation-list-container__reservation-item__form-field']}>
          <div
            className={styles['reservation-list-container__reservation-item__form-field__label']}
          >
            check in - checkout
          </div>
          <div className={styles['reservation-list-container__reservation-item__date-range']}>
            <ReactDatePicker
              selected={CheckInDate}
              onChange={(date: Date) => {
                setCheckInDate(date);
                setCheckoutDate(undefined);
              }}
              selectsStart
              startDate={CheckInDate}
              endDate={checkoutDate}
              placeholderText="check in Date"
            />
            <ReactDatePicker
              selected={checkoutDate}
              onChange={(date: Date) => {
                setCheckoutDate(date);
              }}
              selectsEnd
              startDate={CheckInDate}
              endDate={checkoutDate}
              minDate={CheckInDate}
              placeholderText="checkout Date"
            />
          </div>
        </div>
        <div className={styles['reservation-list-container__reservation-item__button-container']}>
          <Button
            onClick={() => {
              const query: any = {};
              if (unitType) {
                query.unitTypeId = unitType.value !== '' ? unitType.value : undefined;
              }
              if (guestName) {
                query.primaryGuestName = guestName;
              }
              if (area) {
                query.area = area;
              }
              if (id) {
                query.id = id;
              }
              if (startCreatedDate && endCreatedDate) {
                query.createdAtFrom = startCreatedDate.getTime();
                query.createdAtTo = endCreatedDate.getTime();
              }
              if (checkoutDate && CheckInDate) {
                query.checkInDate = CheckInDate.getTime();
                query.checkOutDate = checkoutDate.getTime();
              }


              setSearchQuery(query);
              setOffset(1);
              getReservations({ ...selectedStatus, ...query }, 1, limit);
            }}
            className={
              styles['reservation-list-container__reservation-item__button-container__btn']
            }
            tracking={{
              action: 'close',
              category: 'unit-types',
              label: 'add-unit-type-modal'
            }}
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          >
            search
          </Button>
          <Button
            onClick={() => {
              reInitialize();
              setOffset(1);
              getReservations(selectedStatus, 1, limit);
            }}
            className={
              styles['reservation-list-container__reservation-item__button-container__btn']
            }
            tracking={{
              action: 'clear',
              category: 'unit-types',
              label: 'add-unit-type-modal'
            }}
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
            type={ButtonType.gray}
          >
            clear
          </Button>
          <Button
            onClick={() => {
              downloadCSV(reservations);
            }}
            className={
              styles['reservation-list-container__reservation-item__button-container__btn']
            }
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
            type={ButtonType.success}
          >
            export
          </Button>
        </div>
      </div>
      {loadingData ? (
        <div className={styles['reservation-list-container__loader']}>
          <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
        </div>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Channel</TableCell>
                  <TableCell>Id #</TableCell>
                  <TableCell>Tags</TableCell>
                  <TableCell>Contact Name</TableCell>
                  <TableCell>Check In</TableCell>
                  <TableCell>Check Out</TableCell>
                  <TableCell>Area</TableCell>
                  <TableCell>Property</TableCell>
                  <TableCell>Unit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reservations?.map((reservation) => (
                  <Row key={reservation.id} reservation={reservation} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {reservations.length === 0 && (
            <div className={styles['reservation-list-container__noData']}>
              <img src={`${PUBLIC_PATH}/assets/jpeg/no-records.jpg`} alt="no-data" /> No Data
              Available
            </div>
          )}
        </>
      )}
      {listCount > 0 && (
        <Pagination
          itemCounts={listCount}
          itemsPerPage={limit}
          offset={offset}
          handlePageChange={(val) => {
            getNewReservation(val);
          }}
          handleItemsPerPage={(itemsPerPage) => {
            setLimit(itemsPerPage);
          }}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps)(ReservationsListContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  console.log(state);
  return {
    isAuthenticated: !!state.auth.accessToken,
    userId: state.auth.userId
  };
}
