import React, { useState } from 'react';
import styles from './confirm-modal.module.scss';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';

export interface ConfirmModalProps {
  config: {
    showModal: boolean;
    message: string;
  };

  eventHandlers: {
    denyAction: () => void;
    confirmAction: () => void;
  };
}

/**
 * functional component ConfirmModalProps
 * @param {ConfirmModalProps}
 */
export const ConfirmModal: React.FC<ConfirmModalProps> = ({ eventHandlers, config }) => {
  const [Comment, setComment] = useState<string>('');

  const isMobileView = useWindowResizer();
  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.denyAction();
        }
      }}
    >
      <div className={styles['confirm-modal']}>
        <div className={styles['confirm-modal__title']}>Confirmation</div>
        <div className={styles['confirm-modal__description']}>
          <p>{config.message}</p>
        </div>
        <div className={styles['confirm-modal--actions']}>
          <Button
            onClick={() => {
              eventHandlers.denyAction();
            }}
            type={ButtonType.primaryInverted}
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          >
            No
          </Button>
          <Button
            onClick={eventHandlers.confirmAction}
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          >
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};
