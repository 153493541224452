import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { APP_ROUTES } from '../shared/config/routes-config';
import { RouteConfig } from '../shared/interfaces/routes-config.interface';
import { PayoutsListContainer } from './containers/payouts-list/payouts-list.container';
/**
 * function UnitTypeModule components
 */
export const PayoutModule: React.FC = () => {
  return (
    <>
      <Route
        exact
        path={APP_ROUTES.PAYOUT_MODULE.FULL_PATH}
        component={PayoutsListContainer}
      ></Route>
    </>
  );
};
