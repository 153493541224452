import React, { useEffect, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styles from './text-area.module.scss';
import { XOR } from 'ts-xor';

interface ControlledInput {
  value?: string;
  placeholder?: string;
}

interface UncontrolledInput {
  defaultValue: string;
  placeholder?: string;
}
/**
 * interface TextAreaProps
 */
interface TextAreaProps {
  data: XOR<ControlledInput, UncontrolledInput>;
  config: {
    error?: boolean;
    maxRows?: number;
    autoFocus?: boolean;
  };
  eventHandlers: {
    onChangeHandler: (value: string) => void;
  };
}

/**
 * functional component TextArea
 * @param TextAreaProps holding data, config and eventHandlers props
 */
export const TextArea: React.FC<TextAreaProps> = ({ data, config, eventHandlers }) => {
  const [value, setValue] = useState<string | undefined>(data.value || data.defaultValue);
  useEffect(() => {
    setValue(data.value);
  }, [data.value]);
  return (
    <TextareaAutosize
      tabIndex={-1}
      maxRows={config.maxRows || 100}
      placeholder={data.placeholder || ''}
      className={styles['autosize-textarea']}
      autoFocus={config.autoFocus}
      value={value}
      onChange={(e) => {
        if (data.defaultValue || data.defaultValue === '') {
          setValue(e.target.value);
        }
        eventHandlers.onChangeHandler(e.target.value);
      }}
    />
  );
};
