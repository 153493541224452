import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './select-amenities.module.scss';
import { store } from 'react-notifications-component';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { PUBLIC_PATH } from '../../../../../shared/config/routes-config';
import { AmenityUiModel } from '../../../../../shared/ui-models/amenity.ui-model';

export interface SelectAmenitiesProps {
  data: {
    selectedAmenities: AmenityUiModel[];
    amenitiesList: AmenityUiModel[];
  };
  config: {
    isLoading: boolean;
  };
  eventHandlers: {
    selectAmenity: (amenity: AmenityUiModel) => void;
    unselectAmenity: (amenityId: string) => void;
  };
}

/**
 * functional component SelectAmenities
 * @param {SelectAmenitiesProps} holding question text
 */
export const SelectAmenities: React.FC<SelectAmenitiesProps> = ({
  data,
  config,
  eventHandlers
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      store.removeNotification('assign-unit-type-failed');
      store.removeNotification('assign-unit-type-success');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles['select-amenities-container__sub-title']}>
        {t('UNIT_TYPE_MODULE.SELECT_AMENITIES_CONTAINER.TITLE')}
      </div>

      <div className={styles['select-amenities-container']}>
        {config.isLoading ? (
          <div className={styles['select-amenities-container__cards-container__loader']}>
            <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
          </div>
        ) : (
          <>
            {t('UNIT_TYPE_MODULE.SELECT_AMENITIES_CONTAINER.SUB_TITLE')}
            <div className={styles['select-amenities-container__cards-container']}>
              {data.amenitiesList && data.amenitiesList.length > 0 ? (
                data.amenitiesList.map((amenity, index) => (
                  <div
                    key={index}
                    className={styles['select-amenities-container__cards-container__card']}
                    onClick={() => {
                      const selected =
                        data.selectedAmenities.findIndex((item) => item.id === amenity.id) > -1;
                      if (selected) {
                        if (amenity && amenity.id) {
                          eventHandlers.unselectAmenity(amenity.id);
                        }
                      } else {
                        eventHandlers.selectAmenity(amenity);
                      }
                    }}
                  >
                    <div
                      className={
                        styles['select-amenities-container__cards-container__card__checkbox']
                      }
                    >
                      {data.selectedAmenities.findIndex((item) => item.id === amenity.id) > -1 ? (
                        <CheckCircleIcon fontSize={'inherit'} color={'inherit'} />
                      ) : (
                        <RadioButtonUncheckedIcon fontSize={'inherit'} color={'inherit'} />
                      )}
                    </div>
                    <div
                      className={
                        styles['select-amenities-container__cards-container__card__image-container']
                      }
                    >
                      <img
                        alt="property-unit-type"
                        className={
                          styles[
                            'select-amenities-container__cards-container__card__image-container__image'
                          ]
                        }
                        src={amenity.image || `${PUBLIC_PATH}/assets/pngs/amenity-default.png`}
                      />
                    </div>
                    <div
                      className={styles['select-amenities-container__cards-container__card__name']}
                    >
                      {amenity.name}
                    </div>
                  </div>
                ))
              ) : (
                <div>{t('UNIT_TYPE_MODULE.SELECT_AMENITIES_CONTAINER.NO_AMENITIES')}</div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SelectAmenities;
