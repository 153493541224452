import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './create-expense-modal.module.scss';
import Select, { ValueType } from 'react-select';
import ReactDatePicker from 'react-datepicker';
import {
  ExpensesCategoryType,
  UnitTypeUiModel
} from '../../../../../shared/ui-models/unit-type.ui-model';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import {
  InputText,
  InputTextErrorMode,
  InputTextType
} from '../../../../../shared/components/core-ui/input-text/input-text.component';
import Button, {
  ButtonSize
} from '../../../../../shared/components/core-ui/button/button.component';
import { ChooseUnitType } from '../../../../../shared/components/widgets/choose-unit-type-modal/choose-unit-type.component';
import { trackPage } from '../../../../../shared/services/utils/tracking/tracking.service';
import { getAllUnitTypes } from '../../../../../shared/services/data/unit-type.data';
import { getModuleName } from '../../../../../UnitTypeModule/shared/services/unit-type.service';
import { UnitTypeGroupUiModel } from '../../../../../shared/ui-models/unit-type-group.ui-model';
import { ErrorInfo, isErrorInfo } from '../../../../../shared/interfaces/error-info.interface';
import { getAllUnitTypeGroups } from '../../../../../shared/services/data/lookups.data';
import { getBookAvailability } from '../../../../../ReservationModule/shared/services/data.service';
import { BookAvailabilityResponseModel } from '../../../../../shared/ui-models/book.ui-model';

export interface CreateExpenses {
  description: string;
  roomId: string;
  unitTypeId: number;
  category: string;
  paymentDate: number;
  amount: number;
  updateById?: string;
  updateByUser?: string;
  updatedAt?: number;
  id?: string;
}

export interface ExpenseModalProps {
  config: {
    showModal: boolean;
  };
  eventHandlers: {
    closeHandler: () => void;
    addExpenseHandler: (unitExpesne: CreateExpenses) => void;
  };
}

/**
 * functional component CreateExpenseModal
 * @param {ExpenseModalProps}
 */
export const CreateExpenseModal: React.FC<ExpenseModalProps> = ({ config, eventHandlers }) => {
  const [description, setDescription] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [unitTypeID, setUnitTypeID] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [room, setRoom] = useState<string | undefined>('');
  const [amount, setAmount] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [category, setCategory] = useState<string | undefined>('');
  const [payDate, setPayDate] = useState<FormElementData<Date | null>>({
    value: new Date(),
    isValid: true
  });
  const [roomOptions, setRoomOptions] = useState<{ value: string | undefined; label: string }[]>(
    []
  );
  const [expensesCategoryOptions, setExpensesCategoryOptions] = useState<
    { value: string | undefined; label: string }[]
  >([
    { label: ExpensesCategoryType.HOUSE_KEEPING, value: ExpensesCategoryType.HOUSE_KEEPING },
    { label: ExpensesCategoryType.LAUNDRY, value: ExpensesCategoryType.LAUNDRY },
    { label: ExpensesCategoryType.MAINTENANCE, value: ExpensesCategoryType.MAINTENANCE },
    { label: ExpensesCategoryType.OTHERS, value: ExpensesCategoryType.OTHERS }
  ]);
  const [unitTypeGroupsList, setUnitTypeGroupsList] = useState<UnitTypeGroupUiModel[]>([]);
  const [unitType, setUnitType] = useState<UnitTypeUiModel[]>([]);
  const [showSelectedUnit, setShowSelectedUnit] = useState<boolean>(false);
  const [selectedUnit, setSelectedUnit] = useState<UnitTypeUiModel>({});

  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  const reInitialize = () => {
    setAmount({ value: '', isValid: false });
    setRoom('');
    setCategory('');
  };
  const handleNewUnitTypeSubmit = () => {
    setShowSelectedUnit(false);
    reInitialize();
    eventHandlers.addExpenseHandler({
      description: description.value,
      unitTypeId: Number(unitTypeID.value),
      roomId: room || '',
      category: category || '',
      amount: Number(amount.value) || 0,
      paymentDate: payDate.value?.getTime() || Date.now()
    });
  };
  useEffect(() => {
    trackPage('choose-unit-type');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getAllUnitTypes(getModuleName(), 0, -1, {}).then((response) => {
      if (!isErrorInfo(response)) {
        setUnitType(response.unitList);
      }
    });

    getAllUnitTypeGroups(getModuleName()).then((response: UnitTypeGroupUiModel[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setUnitTypeGroupsList(response);
      }
    });
  }, []);

  useEffect(() => {
    const rooms = selectedUnit?.units
      ? selectedUnit?.units.map((room) => ({ label: room.shortName, value: room.unitId }))
      : [];
    setRoomOptions(rooms);
  }, [selectedUnit]);

  function getAvailability(unitTypeId: string) {
    return getBookAvailability(unitTypeId).then((resp) => {
      if (!isErrorInfo(resp)) {
        const bookAvailabilityResponse = resp as BookAvailabilityResponseModel[];
        let availabeDates: Date[] = [];
        bookAvailabilityResponse.forEach((availObj) => {
          const dates = getDaysArray(availObj.from, availObj.to);
          availabeDates = [...availabeDates, ...dates];
        });
        // setBookAvailability(availabeDates);
      }
    });
  }

  const getDaysArray = (s: any, e: any) => {
    const a: Date[] = [];
    for (const d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
      a.push(new Date(d));
    }
    return a;
  };

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.closeHandler();
        }
      }}
    >
      <div className={styles['create-expense-modal']}>
        <div className={styles['create-expense-modal__title']}>Create Expense</div>

        {!showSelectedUnit && (
          <ChooseUnitType
            data={{
              unitTypes: unitType,
              unitTypeGroups: unitTypeGroupsList
            }}
            eventHandlers={{
              selectedUnitType: (selectedUnit) => {
                getAvailability(selectedUnit.id || '').then(() => {
                  console.log(selectedUnit);
                  setSelectedUnit(selectedUnit);
                  setUnitTypeID({ value: selectedUnit?.id, isValid: true });
                  setShowSelectedUnit(true);
                });
              }
            }}
          ></ChooseUnitType>
        )}
        {unitTypeID.value && showSelectedUnit && (
          <>
            <div className={styles['create-expense-modal__description']}>
              Enter Description :
              <InputText
                data={{
                  defaultValue: '',
                  placeholder: 'description'
                }}
                config={{
                  maxRows: 1,
                  autoFocus: true,
                  showErrorMode: InputTextErrorMode.onFirstBlur
                }}
                eventHandlers={{
                  onChangeHandler: (value: string, isValid: boolean) => {
                    setDescription({ value, isValid });
                  }
                }}
              />
            </div>
            <div className={styles['create-expense-modal__description']}>
              Enter unit type :
              <InputText
                data={{
                  defaultValue: unitTypeID.value,
                  placeholder: 'unit'
                }}
                config={{
                  maxRows: 1,
                  disabled: true
                }}
                eventHandlers={{
                  onChangeHandler: (value: string, isValid: boolean) => {
                    setUnitTypeID({ value, isValid });
                  }
                }}
              />
            </div>
            <div className={styles['create-payout-modal__description']}>
              Enter amount :
              <InputText
                data={{
                  defaultValue: amount.value,
                  placeholder: 'amount'
                }}
                config={{
                  maxRows: 1,
                  type: InputTextType.number,
                  required: true
                }}
                eventHandlers={{
                  onChangeHandler: (value: string, isValid: boolean) => {
                    setAmount({ value, isValid });
                  }
                }}
              />
            </div>
            <div className={styles['create-expense-modal__description']}>
              select room:
              <Select
                className={styles['create-expense-modal__select']}
                options={roomOptions}
                onChange={(
                  value: ValueType<{ value: string | undefined; label: string }, false>
                ) => {
                  setRoom(value ? value.value : '');
                }}
              />
            </div>
            <div className={styles['create-expense-modal__description']}>
              select category:
              <Select
                className={styles['create-expense-modal__select']}
                options={expensesCategoryOptions}
                onChange={(
                  value: ValueType<{ value: string | undefined; label: string }, false>
                ) => {
                  setCategory(value ? value.value : '');
                }}
              />
            </div>
            <div className={styles['add-guest-modal__description']}>
              pay day:
              <ReactDatePicker
                selected={payDate.value}
                onChange={(date) => setPayDate({ value: date as Date, isValid: true })}
              />
            </div>
            <div className={styles['create-expense-modal--actions']}>
              <Button
                onClick={() => {
                  setShowSelectedUnit(false);
                  reInitialize();
                  eventHandlers.closeHandler();
                }}
                tracking={{
                  action: 'close',
                  category: 'unit-types',
                  label: 'create-expense-modal'
                }}
                size={isMobileView ? ButtonSize.full : ButtonSize.medium}
              >
                Close
              </Button>
              <Button
                disabled={
                  !description.isValid ||
                  description.value === '' ||
                  !room ||
                  !category ||
                  !payDate ||
                  !amount
                }
                onClick={handleNewUnitTypeSubmit}
                tracking={{
                  action: 'submit',
                  category: 'unit-type',
                  label: 'create-expense-modal'
                }}
                size={isMobileView ? ButtonSize.full : ButtonSize.medium}
              >
                submit
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
