import React, { useEffect, useRef, useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as Highcharts from 'highcharts';
import { withVisibility } from './collapsible-button-chart';
import { getSourceOfBooking } from '../shared/services/data.service';
import { isErrorInfo } from '../../shared/interfaces/error-info.interface';
import { KPIChartsSearch } from '../../shared/models/kpis.model';

const SourceOfBookingChart: React.FC <{query: KPIChartsSearch }> = ({query}) => {
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>({
    chart: {
      type: 'column',
      showCheckbox: true,
    },
    title: {
      text: 'source of booking',
      align: 'left'
    },
    xAxis: {
      categories: [
        'Jan',
        'Feb',
        'March',
        'Apr',
        'May',
        'June',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      crosshair: true,
      accessibility: {
        description: 'Months'
      }
    },
    yAxis: {
      min: 0,
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        pointPadding: 0,

      }
    },
    series: [
      {
        name: 'OTA\'S',
        data: [],
        color: '#d5cdf3'

      },
      {
        name: 'Direct',
        data: [],
        color: '#bba8ff'
      }
    ]
  });
  const chartComponentRef = useRef(null);

  const getSourceOfBookings = (monthCount: number, payload: KPIChartsSearch) => {
    chartComponentRef?.current?.chart?.showLoading();
    getSourceOfBooking(monthCount, payload).then((response) => {
      if (!isErrorInfo(response)) {
        chartComponentRef?.current?.chart?.hideLoading();
        const sortEntries =  response.reverse();
        const categories = sortEntries.map((revenue) => revenue.month.slice(0,3).toUpperCase());
        const otasSales = sortEntries.map((revenue) => revenue.salesChannels);
        const birdnestSales = sortEntries.map((revenue) => revenue.birdnest);
        const chartUpdatedOptions = {
          ...chartOptions,
          xAxis:{
            ...chartOptions.xAxis,
            categories
          },
          series: [
            { ...chartOptions.series[0], data: otasSales },
            { ...chartOptions.series[1], data: birdnestSales }
          ]
        };
        setChartOptions(chartUpdatedOptions);
      }
    });
  };
  useEffect(() => {
    console.log(query);
    getSourceOfBookings(11,query);
  }, [query]);

  return <HighchartsReact ref={chartComponentRef} highcharts={Highcharts} options={chartOptions} />;
  };
  
  export default withVisibility(SourceOfBookingChart);
  