import React, { useEffect, useState } from 'react';
import { Button as MIButton } from '@material-ui/core';
import { connect } from 'react-redux';
import styles from './expenses-list.module.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { Expenses } from '../../../shared/models/unit-types.model';
import { trackPage } from '../../../shared/services/utils/tracking/tracking.service';
import { CreateExpenseModal } from './components/create-reservation/create-expense-modal.component';
import Button, { ButtonSize } from '../../../shared/components/core-ui/button/button.component';
import { RootState } from '../../../shared/redux/reducers';
import { createExpenses, deleteExpense, getExpenses } from '../../shared/services/data.service';
import { isErrorInfo } from '../../../shared/interfaces/error-info.interface';

import _ from 'lodash';
import { ExpensesPayoutListFilterContainer } from '../../../shared/components/widgets/expenses-payout-list-filter/expenses-payout-list-filter.component';

export type ExpensesListCoContainerProps = ReturnType<typeof mapStateToProps>;

/**
 * functional component ReservationsListContainer
 * @param {ExpensesListCoContainerProps} holding question text
 */
export const ExpensesListContainer: React.FC<ExpensesListCoContainerProps> = () => {
  const [showCreateReseration, setShowCreateReseration] = useState<boolean>(false);
  const [expenses, setExpenses] = useState<Expenses[]>([]);

  const searchExpenses = (filteredData: {
    room: string;
    description: string;
    project: string;
    area: string;
    category: string;
    paymentDateFrom: Date;
    paymentDateTo: Date;
    amount: string;
  }) => {
    getExpensesList(filteredData);
  };

  useEffect(() => {
    trackPage('expenses-list');
    getExpensesList({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getExpensesList = (query: object) => {
    getExpenses(query).then((response) => {
      if (!isErrorInfo(response)) {
        setExpenses(response);
      }
    });
  };
  const addExpenseHandler = (expense: Expenses) => {
    createExpenses(expense).then(() => {
      getExpensesList({});
    });
  };
  const deleteExpenseHandler = (expense: Expenses) => {
    deleteExpense(expense.id).then((data) => {
      getExpensesList({});
    });
  };

  function Row(props: { expense: Expenses }) {
    const { expense } = props;
    return (
      <>
        <TableRow>
          <TableCell>
            <MIButton
              aria-haspopup="true"
              onClick={() => {
                deleteExpenseHandler(expense);
              }}
            >
              <DeleteIcon fontSize={'large'} color="inherit" />
            </MIButton>
          </TableCell>
          <TableCell>{expense?.description}</TableCell>
          <TableCell>{expense?.roomName}</TableCell>
          <TableCell>{expense?.projectName}</TableCell>
          <TableCell>{expense?.areaName}</TableCell>
          <TableCell> {moment(expense.paymentDate).format('MM/DD/YYYY')}</TableCell>
          <TableCell>{expense?.category}</TableCell>
          <TableCell>{expense?.amount}</TableCell>
        </TableRow>
        <TableRow></TableRow>
      </>
    );
  }
  return (
    <div className={styles['expense-list-container']}>
      <CreateExpenseModal
        config={{
          showModal: showCreateReseration
        }}
        eventHandlers={{
          closeHandler: () => {
            setShowCreateReseration(false);
          },
          addExpenseHandler: (expense) => {
            setShowCreateReseration(false);
            addExpenseHandler(expense);
          }
        }}
      ></CreateExpenseModal>
      <div className={styles['expense-list-container__title']}>
        <span>Expenses</span>
        <div className={styles['expense-list-container__title__actions']}>
          <Button
            onClick={() => {
              setShowCreateReseration(true);
            }}
            tracking={{
              action: 'open',
              category: 'create-expense',
              label: 'add-create-expense-modal'
            }}
            size={ButtonSize.medium}
          >
            Create Expenses
          </Button>
        </div>
      </div>
      <ExpensesPayoutListFilterContainer
        data={expenses}
        eventHandlers={{
          search: (data: {
            room: string;
            description: string;
            project: string;
            area: string;
            category: string;
            paymentDateFrom: Date;
            paymentDateTo: Date;
            amount: string;
          }) => searchExpenses(data),
          getInitialList: () => getExpensesList({})
        }}
      />

      <TableContainer component={Paper}>
        <Table aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>description</TableCell>
              <TableCell>room Name</TableCell>
              <TableCell>project Name</TableCell>
              <TableCell>Area Name</TableCell>
              <TableCell>paymentDate</TableCell>
              <TableCell>category</TableCell>
              <TableCell>amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {expenses?.map((expense) => (
              <Row key={expense.id} expense={expense} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default connect(mapStateToProps)(ExpensesListContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}
