import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, {
  ButtonSize
} from '../../../../../../shared/components/core-ui/button/button.component';
import {
  InputText,
  InputTextErrorMode,
  InputTextType
} from '../../../../../../shared/components/core-ui/input-text/input-text.component';
import Modal from '../../../../../../shared/components/core-ui/modal/modal.component';
import useWindowResizer from '../../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { FormElementData } from '../../../../../../shared/interfaces/form-element-data.interface';
import { Expenses } from '../../../../../../shared/models/unit-types.model';
import {
  ExpensesCategoryType,
  UnitTypeUiModel
} from '../../../../../../shared/ui-models/unit-type.ui-model';
import styles from './create-expense-modal.module.scss';
import Select, { ValueType } from 'react-select';
import ReactDatePicker from 'react-datepicker';

export interface CreateExpenses {
  description: string;
  roomId: string;
  unitTypeId: number;
  category: string;
  paymentDate: number;
  amount: number;
}

export interface ExpenseModalProps {
  config: {
    showModal: boolean;
    unitType?: UnitTypeUiModel;
  };
  eventHandlers: {
    closeHandler: () => void;
    addExpenseHandler: (unitExpesne: CreateExpenses) => void;
  };
}

/**
 * functional component CreateExpenseModal
 * @param {ExpenseModalProps}
 */
export const CreateExpenseModal: React.FC<ExpenseModalProps> = ({ config, eventHandlers }) => {
  const [description, setDescription] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [unitTypeID, setUnitTypeID] = useState<FormElementData<string>>({
    value: config.unitType?.id || '',
    isValid: false
  });
  const [room, setRoom] = useState<string | undefined>('');
  const [amount, setAmount] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [category, setCategory] = useState<string | undefined>('');
  const [payDate, setPayDate] = useState<FormElementData<Date | null>>({
    value: new Date(),
    isValid: true
  });
  const [roomOptions, setRoomOptions] = useState<{ value: string | undefined; label: string }[]>(
    []
  );
  const [expensesCategoryOptions, setExpensesCategoryOptions] = useState<
    { value: string | undefined; label: string }[]
  >([
    { label: ExpensesCategoryType.HOUSE_KEEPING, value: ExpensesCategoryType.HOUSE_KEEPING },
    { label: ExpensesCategoryType.LAUNDRY, value: ExpensesCategoryType.LAUNDRY },
    { label: ExpensesCategoryType.MAINTENANCE, value: ExpensesCategoryType.MAINTENANCE },
    { label: ExpensesCategoryType.OTHERS, value: ExpensesCategoryType.OTHERS }
  ]);
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  const handleNewUnitTypeSubmit = () => {
    console.log('yes');
    eventHandlers.addExpenseHandler({
      description: description.value,
      unitTypeId: Number(unitTypeID.value),
      roomId: room || '',
      category: category || '',
      amount: Number(amount.value) || 0,
      paymentDate: payDate.value?.getTime() || Date.now()
    });
  };
  useEffect(() => {
    const rooms = config?.unitType?.units
      ? config.unitType.units.map((room) => ({ label: room.shortName, value: room.unitId }))
      : [];
    setRoomOptions(rooms);
  }, []);

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.closeHandler();
        }
      }}
    >
      <div className={styles['create-expense-modal']}>
        <div className={styles['create-expense-modal__title']}>Create Expense</div>
        <div className={styles['create-expense-modal__description']}>
          Enter Description :
          <InputText
            data={{
              defaultValue: '',
              placeholder: 'description'
            }}
            config={{
              maxRows: 1,
              autoFocus: true,
              showErrorMode: InputTextErrorMode.onFirstBlur
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setDescription({ value, isValid });
              }
            }}
          />
        </div>
        <div className={styles['create-expense-modal__description']}>
          Enter unit type :
          <InputText
            data={{
              defaultValue: unitTypeID.value,
              placeholder: 'unit'
            }}
            config={{
              maxRows: 1,
              disabled: true
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setUnitTypeID({ value, isValid });
              }
            }}
          />
        </div>
        <div className={styles['create-payout-modal__description']}>
          Enter amount :
          <InputText
            data={{
              defaultValue: amount.value,
              placeholder: 'amount'
            }}
            config={{
              maxRows: 1,
              type: InputTextType.number,
              required: true
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setAmount({ value, isValid });
              }
            }}
          />
        </div>
        <div className={styles['create-expense-modal__description']}>
          select room:
          <Select
            className={styles['create-expense-modal__select']}
            options={roomOptions}
            onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
              setRoom(value ? value.value : '');
            }}
          />
        </div>
        <div className={styles['create-expense-modal__description']}>
          select category:
          <Select
            className={styles['create-expense-modal__select']}
            options={expensesCategoryOptions}
            onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
              setCategory(value ? value.value : '');
            }}
          />
        </div>
        <div className={styles['add-guest-modal__description']}>
          pay day:
          <ReactDatePicker
            selected={payDate.value}
            onChange={(date) => setPayDate({ value: date as Date, isValid: true })}
          />
        </div>
      </div>
      <div className={styles['create-expense-modal--actions']}>
        <Button
          onClick={() => {
            eventHandlers.closeHandler();
          }}
          tracking={{
            action: 'close',
            category: 'unit-types',
            label: 'create-expense-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          Close
        </Button>
        <Button
          disabled={
            !description.isValid ||
            description.value === '' ||
            !room ||
            !category ||
            !payDate ||
            !amount
          }
          onClick={handleNewUnitTypeSubmit}
          tracking={{
            action: 'submit',
            category: 'unit-type',
            label: 'create-expense-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          submit
        </Button>
      </div>
    </Modal>
  );
};
