import { PaymentModel } from '../models/payment.model';
import { PaymentUIModel } from '../ui-models/payment.ui-model';

function mapToUI(payment: PaymentModel): PaymentUIModel {
  return {
    id: payment.id || '',
    reservationId: payment.reservationId,
    unitTypeId: payment.unitTypeId,
    unitTypeName: payment.unitTypeName,
    status: payment.status,
    roomId: payment.roomId,
    dueDate: payment.dueDate,
    type: payment.type,
    paymentAmount: payment.paymentAmount,
    paymentItems: payment.paymentItems,
    collector: payment.collector,
    paymentType: payment.paymentType,
    attempts: payment.attempts,
    document: payment.document,
    partialPayment: payment.partialPayment,
    direction: payment.direction,
    userId: payment.userId,
    areaId: payment.areaId,
    birdnestPaymentLink: payment.birdnestPaymentLink
  };
}

export default {
  mapToUI
};
