// import { ApiResponse } from 'apisauce';
import { PUBLIC_PATH } from '../../../config/routes-config';
import { ApiResponse } from 'apisauce';
import { ErrorInfo } from '../../../interfaces/error-info.interface';
import { UserAuthModel } from '../../../models/user-auth.model';
import { ResponseModel } from '../../../interfaces/response-model.interface';
import { AreaModel } from '../../../models/area.model';
import { PropertyModel } from '../../../models/property.model';
import { BulkUpdate, Expenses, UnitTypeModel } from '../../../models/unit-types.model';
import { AmenityModel } from '../../../models/amenity.model';
import { UserModel } from '../../../models/user.model';
import { BookingRequestModel, BookingRequestModelData } from '../../../models/booking-request.model';
import {
  ReservationModel,
  CreateReservationRequestModel,
  ReservationModelData
} from '../../../models/reservation.model';
import {
  BlockDatesModel,
  CreateBlockDatesModel,
  UpdateBlockDatesModel
} from '../../../models/block-dates.model';
import { ReservationDetailsModel } from '../../../models/reservation-details.model';
import { GuestModel } from '../../../models/guest.model';
import { GuestUiModel } from '../../../ui-models/guest.ui-model';
import { ReservationDetailsUiModel } from '../../../ui-models/reservation.ui-model';
import { PaymentModel, PaymentModelData, PaymentSearch } from '../../../models/payment.model';
import { PaymentLinkModel, PaymentUIModel } from '../../../ui-models/payment.ui-model';
import { UserNotificationsModel } from '../../../models/user-notifications.model';
import {
  BookAvailabilityResponseModel,
  BookOfferResponseModel
} from '../../../ui-models/book.ui-model';
import { UnitTypeGroupModel } from '../../../models/unit-type-group..model';
import { ReservationUpdates } from '../../../ui-models/reservation-updates.ui-model';
import { OccupancyResponseModel } from '../../../ui-models/occupancy.ui-model';
import { CreateExpenses } from '../../../../UnitTypeModule/containers/edit-unit-type/expenses-list/components/create-reservation/create-expense-modal.component';
import { CreatePayOutDto, Payout, SearchPayOutDto } from '../../../models/payout.model';
import { ReservationActivityModel } from '../../../models/reservation-activity.model';
import { AmenityChannelModel } from '../../../models/amenity-channel.model';
import { ConfigurationModel } from '../../../models/configuration.model';
import { ConfigurationUiModel } from '../../../ui-models/configuration.ui.model';
import { FinancialReportQueryModel } from '../../../models/financial-report-query.model';
import { UserType } from '../../../enums/user-type.enum';
import {
  UnitTypeUiModelResponse,
  RoomsUiModelResponse
} from '../../../ui-models/unit-type.ui-model';
import { KPIChartsSearch } from '../../../models/kpis.model';
import { FeedbackPayload, FeedbackResponse } from '../../../models/feedbacks.model';

export default {
  // Functions return fixtures
  getRoot: (): any => {
    const responsePromise = fetch(PUBLIC_PATH + '/mocks/root.json');
    return responsePromise.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      };
    });
  },
  registerUser: (
    email: string,
    password: string
  ): Promise<ApiResponse<UserAuthModel, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/user/default-user.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<UserAuthModel, ErrorInfo>;
    });
  },
  loginUser: (
    email: string,
    password: string,
    token: string | null | undefined
  ): Promise<ApiResponse<UserAuthModel, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/user/default-user.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<UserAuthModel, ErrorInfo>;
    });
  },
  changePassword: (
    token: string,
    password: string
  ): Promise<ApiResponse<UserAuthModel, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/user/default-user.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<UserAuthModel, ErrorInfo>;
    });
  },
  forgotPassword: (password: string): Promise<ApiResponse<{}, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/user/default-user.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<{}, ErrorInfo>;
    });
  },
  validateToken: (): Promise<ApiResponse<{}, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/user/default-user.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<{}, ErrorInfo>;
    });
  },
  refreshToken: (refreshToken: string): Promise<ApiResponse<UserAuthModel, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/user/default-user.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();

      return {
        data: responseData,
        ok: true
      } as ApiResponse<UserAuthModel, ErrorInfo>;
    });
  },
  getAreas: (): Promise<ApiResponse<ResponseModel<AreaModel[]>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<AreaModel[]>, ErrorInfo>;
    });
  },
  getUnitTypeGroups: (): Promise<ApiResponse<ResponseModel<AreaModel[]>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<AreaModel[]>, ErrorInfo>;
    });
  },
  createArea: (
    amenityData: AreaModel
  ): Promise<ApiResponse<ResponseModel<AreaModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<AreaModel>, ErrorInfo>;
    });
  },
  updateArea: (
    areaId: string,
    areaData: AreaModel
  ): Promise<ApiResponse<ResponseModel<AreaModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<AreaModel>, ErrorInfo>;
    });
  },
  deleteArea: (areaId: string): Promise<ApiResponse<ResponseModel<{}>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<{}>, ErrorInfo>;
    });
  },

  getProperties: (): Promise<ApiResponse<ResponseModel<PropertyModel[]>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<PropertyModel[]>, ErrorInfo>;
    });
  },
  getProperty: (
    propertyId: string
  ): Promise<ApiResponse<ResponseModel<PropertyModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<PropertyModel>, ErrorInfo>;
    });
  },
  getPropertyUnitTypes: (
    propertyId: string
  ): Promise<ApiResponse<ResponseModel<UnitTypeModel[]>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<UnitTypeModel[]>, ErrorInfo>;
    });
  },
  getUnitTypes: (
    limit?: string,
    offset?: string,
    query?: {}
  ): Promise<ApiResponse<ResponseModel<UnitTypeUiModelResponse>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<UnitTypeModel[]>, ErrorInfo>;
    });
  },
  getAllRooms: (): Promise<ApiResponse<ResponseModel<RoomsUiModelResponse[]>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<RoomsUiModelResponse[]>, ErrorInfo>;
    });
  },
  searchInUnitTypes: (data: {
    checkInDate?: number;
    checkOutDate?: number;
    userId?: string;
  }): Promise<ApiResponse<ResponseModel<UnitTypeModel[]>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<UnitTypeModel[]>, ErrorInfo>;
    });
  },
  getUnitType: (
    unitTypeId: string
  ): Promise<ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>;
    });
  },
  updateUnitType: (
    unitTypeID: string,
    unitTypeData: Partial<UnitTypeModel>
  ): Promise<ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>;
    });
  },
  bulkUpdateUnitType: (
  ): Promise<ApiResponse<ResponseModel<BulkUpdate>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<BulkUpdate>, ErrorInfo>;
    });
  },
  getAllBulkUpdateUnitTypeData: (
  ): Promise<ApiResponse<ResponseModel<BulkUpdate[]>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<BulkUpdate[]>, ErrorInfo>;
    });
  },
  syncUnitTypeChannel: (
    unitTypeID: string
  ): Promise<ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>;
    });
  },
  deleteUnitTypeShowroomImage: (data: {
    unitTypeId: number;
    imageId: string;
    roomId: string;
  }): Promise<ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>;
    });
  },
  uploadUnitTypeShowRoomImage: (
    formData: FormData
  ): Promise<ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>;
    });
  },
  uploadUnitTypeImage: (
    formData: FormData
  ): Promise<ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>;
    });
  },
  deleteUnitTypeImage: (data: {
    unitTypeId: number;
    imageId: string;
  }): Promise<ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>;
    });
  },
  getUnassignedUnitTypes: (): Promise<ApiResponse<ResponseModel<UnitTypeModel[]>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<UnitTypeModel[]>, ErrorInfo>;
    });
  },
  createProperty: (
    propertyData: PropertyModel
  ): Promise<ApiResponse<ResponseModel<PropertyModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<PropertyModel>, ErrorInfo>;
    });
  },
  createUnitType: (
    unitTypeData: UnitTypeModel
  ): Promise<ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<UnitTypeModel>, ErrorInfo>;
    });
  },
  updateProperty: (
    propertyId: string,
    propertyData: Partial<PropertyModel>
  ): Promise<ApiResponse<ResponseModel<PropertyModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<PropertyModel>, ErrorInfo>;
    });
  },
  deleteProperty: (propertyId: string): Promise<ApiResponse<ResponseModel<{}>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<{}>, ErrorInfo>;
    });
  },
  uploadPropertyAsset: (
    formData: FormData,
    propertyId: string
  ): Promise<ApiResponse<{ data: any }, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<{ data: any }, ErrorInfo>;
    });
  },
  uploadPropertyCoverImage: (
    formData: FormData
  ): Promise<ApiResponse<ResponseModel<PropertyModel>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<PropertyModel>, ErrorInfo>;
    });
  },
  getAmenities: (): Promise<ApiResponse<ResponseModel<AmenityModel[]>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<AmenityModel[]>, ErrorInfo>;
    });
  },
  getChannelAmenities: (): Promise<
    ApiResponse<ResponseModel<AmenityChannelModel[]>, ErrorInfo>
  > => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<AmenityChannelModel[]>, ErrorInfo>;
    });
  },
  getChannelRoomAmenities: (): Promise<
    ApiResponse<ResponseModel<AmenityChannelModel[]>, ErrorInfo>
  > => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<AmenitChannelyModel[]>, ErrorInfo>;
    });
  },
  getAllUsers: (): Promise<ApiResponse<ResponseModel<UserModel[]>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<UserModel[]>, ErrorInfo>;
    });
  },
  addUser: (): Promise<ApiResponse<ResponseModel<UserModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<UserModel>, ErrorInfo>;
    });
  },
  // getUserByPhoneNumber: (
  //   phoneNumber: string
  // ): Promise<ApiResponse<ResponseModel<UserModel>, ErrorInfo>> => {
  //   // This fixture only supports gantman or else returns skellock
  //   const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
  //   return defaultUser.then(async (response) => {
  //     const responseData = await response.json();
  //     return {
  //       data: responseData,
  //       ok: true
  //     } as ApiResponse<ResponseModel<UserModel>, ErrorInfo>;
  //   });
  // },
  searchUser: (queryData: {
    role?: UserType[];
    phone?: string[];
    email?: string[];
  }): Promise<ApiResponse<ResponseModel<UserModel[]>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<UserModel[]>, ErrorInfo>;
    });
  },
  getUserLockStatus: (
    userId: string
  ): Promise<ApiResponse<ResponseModel<{data:{isEmailLocked: boolean}}>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<any>, ErrorInfo>;
    });
  },
  unlockUser: (
    userId: string
  ): Promise<ApiResponse<ResponseModel<any>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<any>, ErrorInfo>;
    });
  },
  createAmenity: (
    amenityData: AmenityModel
  ): Promise<ApiResponse<ResponseModel<AmenityModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<AmenityModel>, ErrorInfo>;
    });
  },
  updateAmenity: (
    amenityId: string,
    amenityData: AmenityModel
  ): Promise<ApiResponse<ResponseModel<AmenityModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<AmenityModel>, ErrorInfo>;
    });
  },
  deleteAmenity: (amenityId: string): Promise<ApiResponse<ResponseModel<{}>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<{}>, ErrorInfo>;
    });
  },
  uploadAmenityImage: (
    formData: FormData
  ): Promise<ApiResponse<ResponseModel<AmenityModel>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<AmenityModel>, ErrorInfo>;
    });
  },
  uploadDefaultImage: (
    formData: FormData
  ): Promise<ApiResponse<ResponseModel<AreaModel>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<AreaModel>, ErrorInfo>;
    });
  },
  uploadSponsoredImage: (
    formData: FormData
  ): Promise<ApiResponse<ResponseModel<AreaModel>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<AreaModel>, ErrorInfo>;
    });
  },
  getBookingRequests: (): Promise<ApiResponse<ResponseModel<BookingRequestModel[]>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<BookingRequestModel[]>, ErrorInfo>;
    });
  },
  getUserBookings: (
    userId: string
  ): Promise<ApiResponse<ResponseModel<BookingRequestModel[]>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<BookingRequestModel[]>, ErrorInfo>;
    });
  },
  getBookingRequestsByQuery: (
    query: {},
    offset: number,
    limit: number
  ): Promise<ApiResponse<ResponseModel<BookingRequestModelData>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<BookingRequestModelData>, ErrorInfo>;
    });
  },
  getBookingRequestDetails: (
    requestId: string
  ): Promise<ApiResponse<ResponseModel<BookingRequestModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<BookingRequestModel>, ErrorInfo>;
    });
  },
  getUserNotifications: (): Promise<
    ApiResponse<ResponseModel<UserNotificationsModel>, ErrorInfo>
  > => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<UserNotificationsModel>, ErrorInfo>;
    });
  },
  rejectBookingRequest: (
    requestId: string,
    rejectionReason: string
  ): Promise<ApiResponse<ResponseModel<BookingRequestModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<BookingRequestModel>, ErrorInfo>;
    });
  },
  acceptBookingRequest: (
    requestId: string,
    roomId: string
  ): Promise<ApiResponse<ResponseModel<BookingRequestModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<BookingRequestModel>, ErrorInfo>;
    });
  },
  getReservations: (
    query: {},
    offset: number,
    limit: number
  ): Promise<ApiResponse<ResponseModel<ReservationModelData>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<ReservationModelData>, ErrorInfo>;
    });
  },
  getDoubleBookings: (): Promise<
    ApiResponse<
      ResponseModel<
        {
          currentReservation: ReservationModel;
          otherReservation?: ReservationModel;
          blockedDate?: BlockDatesModel;
        }[]
      >,
      ErrorInfo
    >
  > => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<
        ResponseModel<
          {
            currentReservation: ReservationModel;
            otherReservation?: ReservationModel;
            blockedDate?: BlockDatesModel;
          }[]
        >,
        ErrorInfo
      >;
    });
  },
  getUserReservations: (
    userId: string
  ): Promise<ApiResponse<ResponseModel<ReservationModel[]>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<ReservationModel[]>, ErrorInfo>;
    });
  },
  getOneReservation: (
    id: string
  ): Promise<ApiResponse<ResponseModel<ReservationDetailsModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<ReservationDetailsModel>, ErrorInfo>;
    });
  },
  updateOneReservation: (
    reservation: Partial<ReservationDetailsUiModel>
  ): Promise<ApiResponse<ResponseModel<ReservationDetailsModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<ReservationDetailsModel>, ErrorInfo>;
    });
  },
  postReservationMessage: (
    reservationid: string,
    message: string
  ): Promise<ApiResponse<ResponseModel<ReservationDetailsModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<ReservationDetailsModel>, ErrorInfo>;
    });
  },
  getReservationActivities: (
    reservationid: string
  ): Promise<ApiResponse<ResponseModel<ReservationActivityModel[]>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<ReservationActivityModel[]>, ErrorInfo>;
    });
  },
  getOneGuest: (id: string): Promise<ApiResponse<ResponseModel<GuestModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<GuestModel>, ErrorInfo>;
    });
  },
  updateGuest: (
    guest: GuestUiModel
  ): Promise<ApiResponse<ResponseModel<GuestModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<GuestModel>, ErrorInfo>;
    });
  },
  deleteGuest: (
    guestId: string
  ): Promise<ApiResponse<ResponseModel<GuestModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<GuestModel>, ErrorInfo>;
    });
  },
  updateUser: (user: UserModel): Promise<ApiResponse<ResponseModel<GuestModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<GuestModel>, ErrorInfo>;
    });
  },
  addGuest: (guest: GuestUiModel): Promise<ApiResponse<ResponseModel<GuestModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<GuestModel>, ErrorInfo>;
    });
  },
  getUserPayments: (
    userId: string
  ): Promise<ApiResponse<ResponseModel<PaymentModel[]>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<PaymentModel[]>, ErrorInfo>;
    });
  },
  updateOnePayment: (
    payment: PaymentUIModel
  ): Promise<ApiResponse<ResponseModel<PaymentModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<PaymentModel>, ErrorInfo>;
    });
  },
  createOnePayment: (
    payment: PaymentUIModel
  ): Promise<ApiResponse<ResponseModel<PaymentModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<PaymentModel>, ErrorInfo>;
    });
  },
  reservationRoomSwitch: (
    reservationId: number,
    sourceUnitTypeId: number,
    destinationUnitTypeId: number,
    roomId: string
  ): Promise<ApiResponse<ResponseModel<ReservationDetailsModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<ReservationDetailsModel>, ErrorInfo>;
    });
  },

  generatePaymentLink: (
    paymentLink: PaymentLinkModel
  ): Promise<ApiResponse<ResponseModel<any>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<any>, ErrorInfo>;
    });
  },
  sendPaymentLink: (
    paymentId: string,
    email: string
  ): Promise<ApiResponse<ResponseModel<any>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<any>, ErrorInfo>;
    });
  },
  uploadPaymentReceipt: (formData: FormData): Promise<ApiResponse<{ data: any }, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<{ data: any }, ErrorInfo>;
    });
  },
  uploadGuestDocument: (formData: FormData): Promise<ApiResponse<{ data: any }, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<{ data: any }, ErrorInfo>;
    });
  },
  getBookAvailability: (query: {
    unitTypeId?: string;
  }): Promise<ApiResponse<ResponseModel<BookAvailabilityResponseModel[]>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(
      PUBLIC_PATH + '/mocks/post-book-availability/default-book-availability.json'
    );
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<BookAvailabilityResponseModel[]>, ErrorInfo>;
    });
  },
  getBookOffer: (query: {
    unitTypeId?: string;
    checkInDate: string;
    checkOutDate: string;
  }): Promise<ApiResponse<ResponseModel<BookOfferResponseModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(
      PUBLIC_PATH + '/mocks/post-book-availability/default-book-availability.json'
    );
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<BookOfferResponseModel>, ErrorInfo>;
    });
  },
  createReservations: (
    reservation: CreateReservationRequestModel
  ): Promise<ApiResponse<ResponseModel<ReservationModel>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<ReservationModel>, ErrorInfo>;
    });
  },
  createBlockDates: (
    blockDates: CreateBlockDatesModel
  ): Promise<ApiResponse<ResponseModel<BlockDatesModel>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<BlockDatesModel>, ErrorInfo>;
    });
  },
  updateBlockDates: (
    id: number,
    blockDates: UpdateBlockDatesModel
  ): Promise<ApiResponse<ResponseModel<BlockDatesModel>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<BlockDatesModel>, ErrorInfo>;
    });
  },
  getBlockDates: (query: {}): Promise<ApiResponse<ResponseModel<BlockDatesModel[]>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<BlockDatesModel[]>, ErrorInfo>;
    });
  },
  reactivateReservation: (
    reservationId: string,
    roomId: string
  ): Promise<ApiResponse<ResponseModel<{}>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<{}>, ErrorInfo>;
    });
  },
  createUnitTypeGroup: (
    areaData: UnitTypeGroupModel
  ): Promise<ApiResponse<ResponseModel<UnitTypeGroupModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<UnitTypeGroupModel>, ErrorInfo>;
    });
  },
  updateUnitTypeGroup: (
    unitTypeGroupModelId: string,
    unitTypeGroupModelData: UnitTypeGroupModel
  ): Promise<ApiResponse<ResponseModel<UnitTypeGroupModel>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<UnitTypeGroupModel>, ErrorInfo>;
    });
  },
  submitBookingMessage: (
    message: string,
    requestId: string
  ): Promise<ApiResponse<ResponseModel<{}>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/bookings/requests/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<{}>, ErrorInfo>;
    });
  },
  deleteUnitTypeGroup: (
    unitTypeGroupModelId: string
  ): Promise<ApiResponse<ResponseModel<{}>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<{}>, ErrorInfo>;
    });
  },
  getMonthlyRevenue: (monthCount: number,payload: KPIChartsSearch): Promise<ApiResponse<ResponseModel<{}>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<{}>, ErrorInfo>;
    });
  },
  getMonthlyRevenueWithCurrency: (monthCount: number, payload: KPIChartsSearch): Promise<ApiResponse<ResponseModel<{}>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<{}>, ErrorInfo>;
    });
  },
  getOccupancyAndAverageDailyRate: (monthCount: number, payload: KPIChartsSearch): Promise<ApiResponse<ResponseModel<{}>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<{}>, ErrorInfo>;
    });
  },
  getRevenuePerArea: (payload: KPIChartsSearch): Promise<ApiResponse<ResponseModel<{}>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<{}>, ErrorInfo>;
    });
  },
  getInternationAndGuest: (payload: KPIChartsSearch): Promise<ApiResponse<ResponseModel<{}>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<{}>, ErrorInfo>;
    });
  },
  getSourceOfBooking: (monthCount: number, payload: KPIChartsSearch): Promise<ApiResponse<ResponseModel<{}>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<{}>, ErrorInfo>;
    });
  },
  getReservationsUpdates: (): Promise<
    ApiResponse<ResponseModel<ReservationUpdates>, ErrorInfo>
  > => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<ReservationUpdates>, ErrorInfo>;
    });
  },
  getOccupancy: (): Promise<ApiResponse<ResponseModel<OccupancyResponseModel[]>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<OccupancyResponseModel[]>, ErrorInfo>;
    });
  },
  blockDates: (
    reservation: CreateReservationRequestModel
  ): Promise<ApiResponse<ResponseModel<ReservationModel>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<ReservationModel>, ErrorInfo>;
    });
  },
  paymentSearch: (
    query: Partial<PaymentSearch>,
    offset: number,
    limit: number
  ): Promise<ApiResponse<ResponseModel<PaymentModelData>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<PaymentModelData>, ErrorInfo>;
    });
  },
  financialReportSearch: (
    query: FinancialReportQueryModel
  ): Promise<ApiResponse<any[], ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<any[], ErrorInfo>;
    });
  },

  createExpenses: (
    expense: CreateExpenses
  ): Promise<ApiResponse<ResponseModel<Expenses>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<Expenses>, ErrorInfo>;
    });
  },
  deleteExpenses: (expenseId: string): Promise<ApiResponse<ResponseModel<Expenses>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<Expenses>, ErrorInfo>;
    });
  },
  createPayout: (
    payout: CreatePayOutDto
  ): Promise<ApiResponse<ResponseModel<Payout>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<Payout>, ErrorInfo>;
    });
  },
  uploadPayout: (payoutFile: FormData): Promise<ApiResponse<ResponseModel<Payout>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<Payout>, ErrorInfo>;
    });
  },
  deletePayout: (payoutId: string): Promise<ApiResponse<ResponseModel<Payout>, ErrorInfo>> => {
    // This fixture only supports gantman or else returns skellock
    const defaultUser = fetch(PUBLIC_PATH + '/mocks/get-areas/default-areas.json');
    return defaultUser.then(async (response) => {
      const responseData = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<Payout>, ErrorInfo>;
    });
  },
  searchPayouts: (
    payout: SearchPayOutDto
  ): Promise<ApiResponse<ResponseModel<Payout[]>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<Payout[]>, ErrorInfo>;
    });
  },
  getPayouts: (): Promise<ApiResponse<ResponseModel<Payout[]>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<Payout[]>, ErrorInfo>;
    });
  },
  getExpenses: (): Promise<ApiResponse<ResponseModel<Expenses[]>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<Payout[]>, ErrorInfo>;
    });
  },

  getConfiguration: (): Promise<ApiResponse<ResponseModel<ConfigurationModel[]>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<ConfigurationModel[]>, ErrorInfo>;
    });
  },
  updateConfiguration: (
    configuration: ConfigurationUiModel[]
  ): Promise<ApiResponse<ResponseModel<ConfigurationModel[]>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<ConfigurationModel[]>, ErrorInfo>;
    });
  },
  addPropertyManager: (
    id: string,
    propertyId: string
  ): Promise<ApiResponse<ResponseModel<PropertyModel>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<PropertyModel>, ErrorInfo>;
    });
  },

  deletePropertyManager: (
    id: string,
    propertyId: string
  ): Promise<ApiResponse<ResponseModel<PropertyModel>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<PropertyModel>, ErrorInfo>;
    });
  },
  addPropertyManagerToReservations: (
    id: string,
    reservationId: string
  ): Promise<ApiResponse<ResponseModel<ReservationModel>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<ReservationModel>, ErrorInfo>;
    });
  },
  deletePropertyManagerToReservations: (
    id: string,
    reservationId: string
  ): Promise<ApiResponse<ResponseModel<ReservationModel>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<ReservationModel>, ErrorInfo>;
    });
  },
  getAllReservationsFeebacks: (
    payload: FeedbackPayload,
  ): Promise<ApiResponse<ResponseModel<{count:number, data:FeedbackResponse[]}>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<{count:number, data:FeedbackResponse[]}>, ErrorInfo>;
    });
  },
  getOneReservationsFeebacks: (
    reservationId: string,
  ): Promise<ApiResponse<ResponseModel<FeedbackResponse>, ErrorInfo>> => {
    // This fixture will return a mock survey data
    const defaultSurvey = fetch(PUBLIC_PATH + '/mocks/survey/default-survey.json');
    return defaultSurvey.then(async (response: any) => {
      const responseData: any = await response.json();
      return {
        data: responseData,
        ok: true
      } as ApiResponse<ResponseModel<FeedbackResponse>, ErrorInfo>;
    });
  }
};
