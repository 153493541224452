import React, { useState } from 'react';
import styles from './add-comment-modal.module.scss';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { TextArea } from '../../../../../shared/components/core-ui/text-area/text-area.component';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export interface AddCommentModalProps {
  config: {
    showModal: boolean;
    showSkipEmail: boolean;
  };

  eventHandlers: {
    closeHandler: () => void;
    ChangeStatusWithComment: () => void;
    handelChange: (value: string) => void;
    handelSkipEmail: (value: boolean) => void;
  };
}

/**
 * functional component AddCommentModalProps
 * @param {AddCommentModalProps}
 */
export const AddCommentModal: React.FC<AddCommentModalProps> = ({ eventHandlers, config }) => {
  const [Comment, setComment] = useState<string>('');
  const [skipEmail, setSkipEmail] = useState<boolean>(false);

  const isMobileView = useWindowResizer();

  const handleSkipEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    eventHandlers.handelSkipEmail(event.target.checked);
    setSkipEmail(event.target.checked);
  };

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.closeHandler();
        }
      }}
    >
      <div className={styles['add-comment-modal']}>
        <div className={styles['add-comment-modal__title']}>Add Comment</div>
        <div className={styles['add-comment-modal__description']}>
          <TextArea
            data={{
              defaultValue: '',
              placeholder: 'add your comment'
            }}
            config={{
              autoFocus: true
            }}
            eventHandlers={{
              onChangeHandler: (value: string) => {
                setComment(value);
                eventHandlers.handelChange(value);
              }
            }}
          />
          {config.showSkipEmail && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={skipEmail}
                  onChange={handleSkipEmailChange}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label="Do NOT send email to guest"
            />
          )}
        </div>
        <div className={styles['add-comment-modal--actions']}>
          <Button
            onClick={() => {
              eventHandlers.closeHandler();
            }}
            type={ButtonType.primaryInverted}
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          >
            Cancel
          </Button>
          <Button
            onClick={eventHandlers.ChangeStatusWithComment}
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          >
            update status
          </Button>
        </div>
      </div>
    </Modal>
  );
};
