import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './unit-type-bulk-update-list.module.scss';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { PUBLIC_PATH } from '../../../../../shared/config/routes-config';
import { RootState } from '../../../../../shared/redux/reducers';
import { BulkUpdate } from '../../../../../shared/models/unit-types.model';
import { getAllBulkUpdateUnitTypeData } from '../../../../shared/services/data.service';
import { isErrorInfo } from '../../../../../shared/interfaces/error-info.interface';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
    }
  }
});
const useSelectedRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
    }
  }
});

export type UnitTypesBulkUpdatesListContainerProps = ReturnType<typeof mapStateToProps>;

/**
 * functional component UnitTypesBulkUpdatesListContainer
 * @param {UnitTypesBulkUpdatesListContainerProps} holding question text
 */
export const UnitTypesBulkUpdatesListContainer: React.FC<UnitTypesBulkUpdatesListContainerProps> = ({
  isAuthenticated
}) => {
  const [bulkUpdate, setBulkUpdate] = useState<BulkUpdate[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(false);


  useEffect(() => {
    setLoadingData(true);
    getAllBulkUpdateUnitTypeData()
      .then((resp) => {
        if (!isErrorInfo(resp)) {
          setBulkUpdate(resp.reverse());
        }
      })
      .finally(() => setLoadingData(false));
  }, []);


  function Row(props: { bulkUpdate: BulkUpdate }) {
    const { bulkUpdate } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const selectedClasses = useSelectedRowStyles();

    return (
      <>
        <TableRow>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </TableCell>

          <TableCell>{bulkUpdate.id}</TableCell>
          <TableCell>{bulkUpdate.updateByUser}</TableCell>
          <TableCell>{new Date(bulkUpdate.createdAt).toLocaleString()}</TableCell>
          <TableCell>{bulkUpdate.updatedItemsCount}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={2}>
                <Table
                  aria-label="payment-items"
                  size="small"
                  style={{ backgroundColor: '#F0F0F0' }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell> unit id</TableCell>
                      <TableCell>status</TableCell>
                      <TableCell>failed reason</TableCell>
                    </TableRow>
                  </TableHead>
                  {bulkUpdate.successRecords.map((record) => (
                    <TableBody>
                      <TableCell> {record.unitId} </TableCell>
                      <TableCell> succeed </TableCell>
                      <TableCell> - </TableCell>
                    </TableBody>
                  ))}
                    {bulkUpdate.errorRecords.map((record) => (
                      <TableBody>
                        <TableCell> {record.unitId} </TableCell>
                        <TableCell> failed </TableCell>
                        <TableCell> {record.errorMessage} </TableCell>
                      </TableBody>
                    ))}
                  
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }
  return (
    <div className={styles['unit-types-bulk-update-list']}>
      <div className={styles['unit-types-bulk-update-list__title']}>
        <span>unit types bulk updates status</span>
      </div>
      {loadingData ? (
        <div className={styles['unit-types-bulk-update-list__loader']}>
          <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
        </div>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Id</TableCell>
                  <TableCell>created by</TableCell>
                  <TableCell>created at</TableCell>
                  <TableCell>total count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bulkUpdate?.length > 0 &&
                  bulkUpdate?.map((record) => <Row key={record.id} bulkUpdate={record} />)}
              </TableBody>
            </Table>
          </TableContainer>
          {bulkUpdate?.length === 0 && (
            <div className={styles['unit-types-bulk-update-list__noData']}>
              <img src={`${PUBLIC_PATH}/assets/jpeg/no-records.jpg`} alt="no-data" /> No Data
              Available
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(UnitTypesBulkUpdatesListContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}
