import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './add-property-modal.module.scss';
import { VALIDATION_REGEX } from '../../../../../shared/components/validation.config';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import { trackEvent } from '../../../../../shared/services/utils/tracking/tracking.service';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import {
  InputText,
  InputTextErrorMode
} from '../../../../../shared/components/core-ui/input-text/input-text.component';
import { PropertyUiModel } from '../../../../../shared/ui-models/property.ui-model';

export interface AddPropertyModalProps {
  config: {
    showModal: boolean;
  };
  eventHandlers: {
    cancelHandler: () => void;
    addPropertyHandler: (propertyData: PropertyUiModel) => void;
  };
}

/**
 * functional component AddPropertyModal
 * @param {AddPropertyModalProps}
 */
export const AddPropertyModal: React.FC<AddPropertyModalProps> = ({ config, eventHandlers }) => {
  const [newPropertyName, setNewPropertyName] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [newPropertyDescription, setNewPropertyDescription] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  const handleNewPropertySubmit = () => {
    if (
      newPropertyName.value !== '' &&
      newPropertyName.isValid &&
      newPropertyDescription.value !== '' &&
      newPropertyDescription.isValid
    ) {
      eventHandlers.addPropertyHandler({
        name: newPropertyName.value,
        description: newPropertyDescription.value
      });
    }
  };

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.cancelHandler();
        }
      }}
    >
      <div className={styles['add-property-modal']}>
        <div className={styles['add-property-modal__title']}>
          {t('PROPERTY_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.TITLE')}
        </div>
        <div className={styles['add-property-modal__description']}>
          {t('PROPERTY_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.DESCRIPTION')}
        </div>
        <InputText
          data={{
            defaultValue: '',
            errorMessage: t('PROPERTY_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.INVALID_PROPERTY_NAME'),
            placeholder: t('PROPERTY_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.NAME_PLACEHOLDER')
          }}
          config={{
            maxRows: 1,
            autoFocus: true,
            validationRegex: VALIDATION_REGEX.PROPERTY_NAME,
            showErrorMode: InputTextErrorMode.instant
          }}
          eventHandlers={{
            onChangeHandler: (value: string, isValid: boolean) => {
              setNewPropertyName({ value, isValid });
            },
            onEnterPressedHandler: handleNewPropertySubmit
          }}
        />
        <InputText
          data={{
            defaultValue: '',
            // errorMessage: t(
            //   'PROPERTY_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.INVALID_PROPERTY_DESCRIPTION'
            // ),
            placeholder: t('PROPERTY_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.DESCRIPTION_PLACEHOLDER')
          }}
          config={{
            maxRows: 1,
            autoFocus: true,
            // validationRegex: VALIDATION_REGEX.PROPERTY_DESCRIPTION,
            showErrorMode: InputTextErrorMode.instant
          }}
          eventHandlers={{
            onChangeHandler: (value: string, isValid: boolean) => {
              setNewPropertyDescription({ value, isValid });
            },
            onEnterPressedHandler: handleNewPropertySubmit
          }}
        />
      </div>
      <div className={styles['add-property-modal--actions']}>
        <Button
          onClick={() => {
            eventHandlers.cancelHandler();
            trackEvent('cancel', {
              category: 'property',
              label: 'add-property-modal'
            });
          }}
          tracking={{
            action: 'cancel',
            category: 'builder',
            label: 'builder-intro'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          type={ButtonType.primaryInverted}
        >
          {t('PROPERTY_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.CANCEL_BUTTON')}
        </Button>
        <Button
          disabled={!newPropertyName.isValid || newPropertyName.value === ''}
          onClick={handleNewPropertySubmit}
          tracking={{
            action: 'submit',
            category: 'property',
            label: 'add-property-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          {t('PROPERTY_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.SUBMIT_BUTTON')}
        </Button>
      </div>
    </Modal>
  );
};
