import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './unit-type-group-list.module.scss';
import { store } from 'react-notifications-component';
import { createTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import {
  DataGrid,
  // getThemePaletteMode,
  GridCellParams,
  GridColumns,
  useGridApiRef
} from '@material-ui/data-grid';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { ErrorInfo, isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { RootState } from '../../../shared/redux/reducers';
import { trackPage } from '../../../shared/services/utils/tracking/tracking.service';
import {
  addNewUnitTypeGroup,
  updateUnitTypeGroup,
  deleteUnitTypeGroup
} from '../../shared/services/data.service';
import { UnitTypeGroupUiModel } from '../../../shared/ui-models/unit-type-group.ui-model';
import IconButton from '@material-ui/core/IconButton';
import { AddUnitTypeGroupModal } from './components/add-unit-type-group-modal/add-unit-type-group-modal.component';
import { EditUnitTypeGroupModal } from './components/edit-unit-type-group-modal/edit-unit-type-group-modal.component';
import { DeleteUnitTypeGroupModal } from './components/delete-unit-type-group-modal/delete-unit-type-group-modal.component';
import { getAllUnitTypeGroups } from '../../../shared/services/data/lookups.data';
import { getModuleName } from '../../shared/services/unit-type-group.service';

const defaultTheme = createTheme();
const useStyles = makeStyles(
  () => {
    const isDark = false; // getThemePaletteMode(theme.palette) === 'dark';

    const backgroundColor = 'rgb(217 243 190)';
    return {
      root: {
        '& .MuiDataGrid-cell': {
          fontSize: '1.6rem'
        },
        '& .MuiDataGrid-cell--editable': {
          backgroundColor
        },
        '& .Mui-error': {
          backgroundColor: `rgb(126,10,15, ${isDark ? 0 : 0.1})`,
          color: isDark ? '#ff4343' : '#750f0f'
        }
      }
    };
  },
  { defaultTheme }
);

export type UnitTypeGroupsListContainerProps = ReturnType<typeof mapStateToProps>;

/**
 * functional component UnitTypeGroupsListContainer
 * @param {UnitTypeGroupsListContainerProps} holding question text
 */
export const UnitTypeGroupsListContainer: React.FC<UnitTypeGroupsListContainerProps> = () => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [unitTypeGroupsList, setUnitTypeGroupsList] = useState<UnitTypeGroupUiModel[]>([]);
  const [unitTypeGroupToUpdate, setUnitTypeGroupToUpdate] = useState<UnitTypeGroupUiModel>();
  const [unitTypeGroupToDelete, setUnitTypeGroupToDelete] = useState<UnitTypeGroupUiModel>();
  const apiRef: any = useGridApiRef();
  const classes = useStyles();
  const { t } = useTranslation();
  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'Actions',
      headerClassName: styles['unitTypeGroups-list-container__grid-header'],
      width: 160,
      editable: false,
      renderCell: (params: GridCellParams) => (
        <strong>
          <IconButton
            aria-label="edit"
            onClick={() => {
              handleRowEdit(params);
            }}
          >
            <EditRoundedIcon fontSize={'large'} />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => {
              handleRowDelete(params);
            }}
          >
            <DeleteIcon color={'error'} fontSize={'large'} />
          </IconButton>
        </strong>
      )
    },
    {
      field: 'name',
      headerName: 'Name',
      headerClassName: styles['unitTypeGroups-list-container__grid-header'],
      width: 160,
      editable: false
    },
    // {
    //   field: 'createdAt',
    //   headerName: 'Created At',
    //   headerClassName: styles['unitTypeGroups-list-container__grid-header'],
    //   type: 'date',
    //   width: 140,
    //   editable: false
    // },
    // {
    //   field: 'createdBy',
    //   headerClassName: styles['unitTypeGroups-list-container__grid-header'],
    //   headerName: 'Created By',
    //   width: 140,
    //   editable: false
    // },
    {
      field: 'modifiedAt',
      headerName: 'Modified At',
      headerClassName: styles['unitTypeGroups-list-container__grid-header'],

      type: 'date',
      width: 140,
      editable: false
    },
    {
      field: 'mod',
      headerName: 'Modified At',
      headerClassName: styles['unitTypeGroups-list-container__grid-header'],
      width: 140,
      editable: false
    }
  ];

  useEffect(() => {
    trackPage('unitTypeGroups-list');
    getAllUnitTypeGroups(getModuleName()).then((response: UnitTypeGroupUiModel[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setUnitTypeGroupsList(response);
      }
    });
    return () => {
      store.removeNotification('add-unitTypeGroup-failed');
      store.removeNotification('add-unitTypeGroup-success');
      store.removeNotification('edit-unitTypeGroup-failed');
      store.removeNotification('edit-unitTypeGroup-success');
      store.removeNotification('delete-unitTypeGroup-failed');
      store.removeNotification('delete-unitTypeGroup-success');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRowEdit = (params: GridCellParams) => {
    const foundUnitTypeGroup = unitTypeGroupsList.find(
      (unitTypeGroup) => unitTypeGroup.id === params.id
    );
    if (foundUnitTypeGroup) {
      setUnitTypeGroupToUpdate(foundUnitTypeGroup);
      setShowEditModal(true);
    }
  };

  const handleRowDelete = (params: GridCellParams) => {
    const foundUnitTypeGroup = unitTypeGroupsList.find(
      (unitTypeGroup) => unitTypeGroup.id === params.id
    );
    if (foundUnitTypeGroup && foundUnitTypeGroup.id) {
      setUnitTypeGroupToDelete(foundUnitTypeGroup);
      setShowDeleteModal(true);
    }
  };

  const handleNewUnitTypeGroupSubmit = (newUnitTypeGroupName: string) => {
    store.removeNotification('add-unitTypeGroup-failed');
    store.removeNotification('add-unitTypeGroup-success');
    addNewUnitTypeGroup({ name: newUnitTypeGroupName }).then(
      (response: UnitTypeGroupUiModel | ErrorInfo) => {
        if (isErrorInfo(response)) {
          if (response.status === 401 || response.status === 403) {
            store.addNotification({
              id: 'add-unitTypeGroup-failed',
              title: t(
                'UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_UNIT_TYPE_GROUP_PERMISSION.TITLE'
              ),
              message: t(
                'UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_UNIT_TYPE_GROUP_PERMISSION.MESSAGE'
              ),
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut']
            });
          } else {
            store.addNotification({
              id: 'add-unitTypeGroup-failed',
              title: t(
                'UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_UNIT_TYPE_GROUP_FAILED.TITLE'
              ),
              message: t(
                'UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_UNIT_TYPE_GROUP_FAILED.MESSAGE'
              ),
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut']
            });
          }
        } else {
          store.addNotification({
            title: t(
              'UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_UNIT_TYPE_GROUP_SUCCESS.TITLE'
            ),
            message: t(
              'UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_UNIT_TYPE_GROUP_SUCCESS.MESSAGE'
            ),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          setUnitTypeGroupsList([...unitTypeGroupsList, response]);
        }
      }
    );
  };

  const handleEditUnitTypeGroupSubmit = (
    unitTypeGroupId: string,
    unitTypeGroup: UnitTypeGroupUiModel
  ) => {
    store.removeNotification('edit-unitTypeGroup-failed');
    store.removeNotification('edit-unitTypeGroup-success');
    updateUnitTypeGroup(unitTypeGroupId, unitTypeGroup).then(
      (response: UnitTypeGroupUiModel | ErrorInfo) => {
        if (isErrorInfo(response)) {
          if (response.status === 401 || response.status === 403) {
            store.addNotification({
              id: 'edit-unitTypeGroup-failed',
              title: t(
                'UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_UNIT_TYPE_GROUP_PERMISSION.TITLE'
              ),
              message: t(
                'UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_UNIT_TYPE_GROUP_PERMISSION.MESSAGE'
              ),
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut']
            });
          } else {
            store.addNotification({
              id: 'edit-unitTypeGroup-failed',
              title: t(
                'UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_UNIT_TYPE_GROUP_FAILED.TITLE'
              ),
              message: t(
                'UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_UNIT_TYPE_GROUP_FAILED.MESSAGE'
              ),
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut']
            });
          }
        } else {
          store.addNotification({
            title: t(
              'UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_UNIT_TYPE_GROUP_SUCCESS.TITLE'
            ),
            message: t(
              'UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.NOTIFICATIONS.EDIT_UNIT_TYPE_GROUP_SUCCESS.MESSAGE'
            ),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          const updateUnitTypeGroupsList = unitTypeGroupsList.map(
            (unitTypeGroup: UnitTypeGroupUiModel) => {
              if (unitTypeGroup.id === response.id) {
                return response;
              } else {
                return unitTypeGroup;
              }
            }
          );
          setUnitTypeGroupsList(updateUnitTypeGroupsList);
        }
      }
    );
  };

  const handleDeleteUnitTypeGroupSubmit = (unitTypeGroupId: string) => {
    store.removeNotification('delete-unitTypeGroup-failed');
    store.removeNotification('delete-unitTypeGroup-success');
    deleteUnitTypeGroup(unitTypeGroupId).then((response: any | ErrorInfo) => {
      if (isErrorInfo(response)) {
        if (response.status === 401 || response.status === 403) {
          store.addNotification({
            id: 'delete-unitTypeGroup-failed',
            title: t(
              'UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_UNIT_TYPE_GROUP_PERMISSION.TITLE'
            ),
            message: t(
              'UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_UNIT_TYPE_GROUP_PERMISSION.MESSAGE'
            ),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        } else {
          store.addNotification({
            id: 'delete-unitTypeGroup-failed',
            title: t(
              'UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_UNIT_TYPE_GROUP_FAILED.TITLE'
            ),
            message: t(
              'UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_UNIT_TYPE_GROUP_FAILED.MESSAGE'
            ),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        }
      } else {
        store.addNotification({
          title: t(
            'UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_UNIT_TYPE_GROUP_SUCCESS.TITLE'
          ),
          message: t(
            'UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.NOTIFICATIONS.DELETE_UNIT_TYPE_GROUP_SUCCESS.MESSAGE'
          ),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        const updateUnitTypeGroupsList = unitTypeGroupsList.filter(
          (unitTypeGroup: UnitTypeGroupUiModel) => unitTypeGroup.id !== unitTypeGroupId
        );
        setUnitTypeGroupsList(updateUnitTypeGroupsList);
      }
    });
  };

  return (
    <div className={styles['unitTypeGroups-list-container']}>
      <div className={styles['unitTypeGroups-list-container__title']}>
        {t('UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.TITLE')}
      </div>
      <div
        className={styles['unitTypeGroups-list-container__add-new']}
        onClick={() => {
          setShowAddModal(true);
        }}
      >
        <AddCircleIcon fontSize={'inherit'} color={'inherit'} />
      </div>
      <div className={styles['unitTypeGroups-list-container__list-wrapper']}>
        <DataGrid
          apiRef={apiRef}
          className={classes.root}
          rows={unitTypeGroupsList}
          columns={columns}
        />
      </div>
      <AddUnitTypeGroupModal
        config={{ showModal: showAddModal }}
        eventHandlers={{
          cancelHandler: () => {
            setShowAddModal(false);
          },
          addUnitTypeGroupHandler: (unitTypeGroupName) => {
            setShowAddModal(false);
            handleNewUnitTypeGroupSubmit(unitTypeGroupName);
          }
        }}
      />
      <EditUnitTypeGroupModal
        config={{ showModal: showEditModal }}
        data={{ unitTypeGroup: unitTypeGroupToUpdate }}
        eventHandlers={{
          cancelHandler: () => {
            setShowEditModal(false);
            setUnitTypeGroupToUpdate(undefined);
          },
          editUnitTypeGroupHandler: (
            unitTypeGroupId: string,
            unitTypeGroup: UnitTypeGroupUiModel
          ) => {
            setShowEditModal(false);
            setUnitTypeGroupToUpdate(undefined);
            handleEditUnitTypeGroupSubmit(unitTypeGroupId, unitTypeGroup);
          }
        }}
      />
      <DeleteUnitTypeGroupModal
        config={{ showModal: showDeleteModal }}
        data={{ unitTypeGroup: unitTypeGroupToDelete }}
        eventHandlers={{
          cancelHandler: () => {
            setShowDeleteModal(false);
            setUnitTypeGroupToDelete(undefined);
          },
          deleteUnitTypeGroupHandler: (unitTypeGroup: UnitTypeGroupUiModel) => {
            setShowDeleteModal(false);
            setUnitTypeGroupToDelete(undefined);
            if (unitTypeGroup && unitTypeGroup.id) {
              handleDeleteUnitTypeGroupSubmit(unitTypeGroup.id);
            }
          }
        }}
      />
    </div>
  );
};

export default connect(mapStateToProps)(UnitTypeGroupsListContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}
