import { ApiResponse } from 'apisauce';
import { ErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { ResponseModel } from '../../../shared/interfaces/response-model.interface';
import PropertyMapper from '../../../shared/mappers/property.mapper';
import UnitTypeMapper from '../../../shared/mappers/unit-type.mapper';
import { PropertyModel } from '../../../shared/models/property.model';
import { UnitTypeModel } from '../../../shared/models/unit-types.model';
import api from '../../../shared/services/utils/api/api.service';
import errorHelper from '../../../shared/services/utils/api/error-helper.service';
import { PropertyUiModel } from '../../../shared/ui-models/property.ui-model';
import { UnitTypeUiModel } from '../../../shared/ui-models/unit-type.ui-model';
import { getModuleName } from './property.service';

export function getAllProperties(): Promise<PropertyUiModel[] | ErrorInfo> {
  return api
    .getProperties()
    .then((response: ApiResponse<ResponseModel<PropertyModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return PropertyMapper.mapToUIList(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.getProperties as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.getProperties as any).displayName
        );
      }
    });
}

export function getPropertyDetails(propertyId: string): Promise<PropertyUiModel | ErrorInfo> {
  return api
    .getProperty(propertyId)
    .then((response: ApiResponse<ResponseModel<PropertyModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return PropertyMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.getProperty as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.getProperty as any).displayName
        );
      }
    });
}

export function getPropertyUnitTypes(propertyId: string): Promise<UnitTypeUiModel[] | ErrorInfo> {
  return api
    .getPropertyUnitTypes(propertyId)
    .then((response: ApiResponse<ResponseModel<UnitTypeModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return UnitTypeMapper.mapToUIList(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.getPropertyUnitTypes as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.getPropertyUnitTypes as any).displayName
        );
      }
    });
}

export function getUnassignedUnitTypes(): Promise<UnitTypeUiModel[] | ErrorInfo> {
  return api
    .getUnassignedUnitTypes()
    .then((response: ApiResponse<ResponseModel<UnitTypeModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return UnitTypeMapper.mapToUIList(data.data);
        } else {
          return errorHelper(
            0,
            '',
            getModuleName(),
            (api.getUnassignedUnitTypes as any).displayName
          );
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.getUnassignedUnitTypes as any).displayName
        );
      }
    });
}

export function addNewProperty(
  propertyData: PropertyUiModel
): Promise<PropertyUiModel | ErrorInfo> {
  const mappedProperty = PropertyMapper.mapToModel(propertyData);
  return api
    .createProperty(mappedProperty)
    .then((response: ApiResponse<ResponseModel<PropertyModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return PropertyMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.createProperty as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.createProperty as any).displayName
        );
      }
    });
}

export function updatePropertyData(
  propertyId: string,
  propertyData: Partial<PropertyUiModel>
): Promise<PropertyUiModel | ErrorInfo> {
  const mappedProperty = PropertyMapper.mapToModel(propertyData);
  return api
    .updateProperty(propertyId, mappedProperty)
    .then((response: ApiResponse<ResponseModel<PropertyModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return PropertyMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.updateProperty as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.updateProperty as any).displayName
        );
      }
    });
}

export function deleteProperty(areaId: string): Promise<{} | ErrorInfo> {
  return api.deleteProperty(areaId).then((response: ApiResponse<ResponseModel<{}>, ErrorInfo>) => {
    if (response.ok) {
      const { data } = response;
      if (data && data.data) {
        return {};
      } else {
        return errorHelper(0, '', getModuleName(), (api.deleteProperty as any).displayName);
      }
    } else {
      return errorHelper(
        Number(response.status),
        response.data && response.data.errorCode
          ? String(response.data.errorCode || '')
          : response.problem,
        getModuleName(),
        (api.deleteProperty as any).displayName
      );
    }
  });
}

export function uploadPropertyCoverImage(
  size: 'sm' | 'md' | 'lg',
  file: File,
  propertyId: string
): Promise<PropertyUiModel | ErrorInfo> {
  const data = new FormData();
  data.append('asset', file);
  data.append('size', size);
  data.append('propertyId', propertyId);

  return api
    .uploadPropertyCoverImage(data)
    .then((response: ApiResponse<ResponseModel<PropertyModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return PropertyMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.updateProperty as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.uploadPropertyCoverImage as any).displayName
        );
      }
    });
}
export function deletePropertyManager(id: string, propertyId: string): Promise<{} | ErrorInfo> {
  return api
    .deletePropertyManager(id, propertyId)
    .then((response: ApiResponse<ResponseModel<PropertyModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return PropertyMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.deleteProperty as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.deletePropertyManager as any).displayName
        );
      }
    });
}
export function addPropertyManager(id: string, propertyId: string): Promise<{} | ErrorInfo> {
  return api
    .addPropertyManager(id, propertyId)
    .then((response: ApiResponse<ResponseModel<PropertyModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return PropertyMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.deleteProperty as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.addPropertyManager as any).displayName
        );
      }
    });
}
