import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonSize } from '../../../shared/components/core-ui/button/button.component';
import { connect } from 'react-redux';
import styles from './login.module.scss';
import { store } from 'react-notifications-component';
import { useHistory, useLocation } from 'react-router';
import {
  InputText,
  InputTextType
} from '../../../shared/components/core-ui/input-text/input-text.component';
import { VALIDATION_REGEX } from '../../../shared/components/validation.config';
import { FormElementData } from '../../../shared/interfaces/form-element-data.interface';
import { APP_ROUTES, GOOGLE_CAPTCHA_KEY } from '../../../shared/config/routes-config';
import { RouteConfig } from '../../../shared/interfaces/routes-config.interface';
import { loginUser } from '../../../shared/services/data/auth.data';
import { getModuleName } from '../../shared/services/auth.service';
import { ErrorInfo, isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { setUserToken } from '../../../shared/services/helpers/auth-helper/auth-helper.service';
import { UserAuthUIModel } from '../../../shared/ui-models/user-auth.ui-model';
import { TARGET_URL_QUERY_PARAM } from '../../../shared/config/auth.config';
import { RootState } from '../../../shared/redux/reducers';
import { trackEvent, trackPage } from '../../../shared/services/utils/tracking/tracking.service';
import ReCAPTCHA from 'react-google-recaptcha';

export type LoginContainerProps = ReturnType<typeof mapStateToProps>;
const captchaKEy = GOOGLE_CAPTCHA_KEY;

/**
 * functional component LoginContainer
 * @param {LoginContainerProps} holding question text
 */
export const LoginContainer: React.FC<LoginContainerProps> = ({ isAuthenticated }) => {
  const [targetRoute, setTargetRoute] = useState<string>('');
  const [emailField, setEmailField] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [passwordField, setPasswordField] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [showContactError, setShowContactError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    trackPage('login');
    const targetUrl = new URLSearchParams(location.search).get(TARGET_URL_QUERY_PARAM);
    if (targetUrl) setTargetRoute(targetUrl);
    if (isAuthenticated) {
      trackEvent('authenticated-auto-redirect', {
        category: 'auth',
        label: 'login'
      });
      if (targetRoute) {
        history.push(targetRoute);
      } else {
        history.push(APP_ROUTES.HOME_MODULE.FULL_PATH);
      }
    }
    return () => {
      store.removeNotification('login-failed');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   * function to handle sign up submission
   */
  const submitHandler = async () => {
    setIsLoading(true);
    const token = await recaptchaRef?.current?.executeAsync();
    setIsLoading(false);
    if (emailField.value && emailField.isValid && passwordField.value && passwordField.isValid) {
      setShowContactError(false);
      setIsLoading(true);
      store.removeNotification('login-failed');
      loginUser(
        emailField.value as string,
        passwordField.value as string,
        getModuleName(),
        token
      ).then((response: UserAuthUIModel | ErrorInfo) => {
        if (isErrorInfo(response)) {
          setTimeout(() => {
            setIsLoading(false);
            store.addNotification({
              id: 'login-failed',
              title:
                response.status === 403
                  ? t('AUTH_MODULE.LOGIN_CONTAINER.UNAUTHORIZED_ERROR_TITLE')
                  : t('AUTH_MODULE.LOGIN_CONTAINER.CONTACT_US_ERROR_TITLE'),
              message:
                response.status === 403
                  ? t('AUTH_MODULE.LOGIN_CONTAINER.UNAUTHORIZED_ERROR_MESSAGE')
                  : t('AUTH_MODULE.LOGIN_CONTAINER.CONTACT_US_ERROR_MESSAGE'),
              type: 'danger',
              insert: 'top',
              container: 'top-center',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut']
            });
            trackEvent('failed', {
              category: 'auth',
              label: 'login'
            });
          }, 500);
        } else {
          setIsLoading(false);
          setUserToken(response);
          trackEvent('success', {
            category: 'auth',
            label: 'login'
          });
          window.scrollTo({
            behavior: 'smooth',
            top: 0 // header height
          });
          if (targetRoute) {
            history.push(targetRoute);
          } else {
            history.push(APP_ROUTES.HOME_MODULE.FULL_PATH);
          }
        }
      });
    }
  };

  return (
    <div className={styles['login-container']}>
      <div className={styles['login-container__wrapper']}>
        <h1 className={styles['login-container__wrapper__title']}>
          {t('AUTH_MODULE.LOGIN_CONTAINER.TITLE')}
        </h1>
        <p className={styles['login-container__wrapper__sub_title']}>
          {t('AUTH_MODULE.LOGIN_CONTAINER.SUB_TITLE')}
        </p>
        <p className={styles['login-container__wrapper__label']}>
          {t('AUTH_MODULE.LOGIN_CONTAINER.EMAIL_LABEL')}
        </p>
        <InputText
          config={{
            autoFocus: true,
            type: InputTextType.email,
            required: true,
            validationRegex: VALIDATION_REGEX.EMAIL
          }}
          data={{
            placeholder: t('AUTH_MODULE.LOGIN_CONTAINER.EMAIL_PLACEHOLDER'),
            errorMessage: t('AUTH_MODULE.LOGIN_CONTAINER.EMAIL_ERROR_MESSAGE'),
            defaultValue: ''
          }}
          eventHandlers={{
            onChangeHandler: (value, isValid) => {
              setEmailField({ value, isValid });
            },
            onEnterPressedHandler: submitHandler
          }}
        ></InputText>
        <p className={styles['login-container__wrapper__label']}>
          {t('AUTH_MODULE.LOGIN_CONTAINER.PASSWORD_LABEL')}
        </p>
        <InputText
          config={{
            type: InputTextType.password,
            // minLength: 6,
            required: true
          }}
          data={{
            placeholder: t('AUTH_MODULE.LOGIN_CONTAINER.PASSWORD_PLACEHOLDER'),
            // errorMessage: t('AUTH_MODULE.LOGIN_CONTAINER.PASSWORD_ERROR_MESSAGE'),
            defaultValue: ''
          }}
          eventHandlers={{
            onChangeHandler: (value, isValid) => {
              setPasswordField({ value, isValid });
            },
            onEnterPressedHandler: submitHandler
          }}
        ></InputText>

        <div className={styles['login-container__wrapper__forgot-password']}>
          <div className={styles['login-container__wrapper__forgot-password__message']}>
            {t('AUTH_MODULE.LOGIN_CONTAINER.FORGOT_PASSWORD_MESSAGE')}
          </div>
          <button
            className={styles['login-container__wrapper__forgot-password__link']}
            onClick={() => {
              trackEvent('forgot-password-clicked', {
                category: 'auth',
                label: 'login'
              });
              history.push({
                pathname: (APP_ROUTES.AUTH_MODULE.CHILDREN as RouteConfig).FORGOT_PASSWORD.FULL_PATH
              });
            }}
          >
            {t('AUTH_MODULE.LOGIN_CONTAINER.FORGOT_PASSWORD_LINK')}
          </button>
        </div>

        {showContactError && (
          <p className={styles['login-container__wrapper__error-message']}>
            {t('AUTH_MODULE.LOGIN_CONTAINER.CONTACT_US_ERROR_MESSAGE')}
          </p>
        )}
        <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={captchaKEy || ''} />
        <div className={styles['login-container__wrapper__submit-button']}>
          <Button
            onClick={submitHandler}
            tracking={{
              action: 'submit',
              category: 'auth',
              label: 'login'
            }}
            size={ButtonSize.full}
            disabled={!emailField.isValid || !passwordField.isValid}
            loading={isLoading}
          >
            {t('AUTH_MODULE.LOGIN_CONTAINER.SUBMIT_BUTTON')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(LoginContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}
