import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import styles from './reservation-create.module.scss';
import Checkbox from '@material-ui/core/Checkbox';
import { isErrorInfo, ErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { RootState } from '../../../shared/redux/reducers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { trackPage } from '../../../shared/services/utils/tracking/tracking.service';
// import useWindowResizer from '../../../shared/hooks/window-resizer/window-resizer.hook';
import { UnitTypeUiModel } from '../../../shared/ui-models/unit-type.ui-model';
import { getModuleName } from '../../shared/services/reservation.service';
import { getAllUnitTypes } from '../../../shared/services/data/unit-type.data';
import { ChooseUnitType } from './components/choose-unit-type-modal/choose-unit-type.component';
import { ChooseAppt } from './components/choose-appt/choose-appt.component';
import {
  BookAvailabilityResponseModel,
  BookOfferResponseModel
} from '../../../shared/ui-models/book.ui-model';
import {
  getBookAvailability,
  getBookOffer,
  createReservation,
  creatBlockDates
} from '../../shared/services/data.service';
import { PaymentDetails } from './components/payment-details/payment-details.component';
import { AddGuest } from './components/add-guest/add-guest.component';
import Select, { ValueType } from 'react-select';
import Button, { ButtonSize } from '../../../shared/components/core-ui/button/button.component';
import { CreateReservationRequestModel } from '../../../shared/models/reservation.model';
import moment from 'moment';
import { CreateBlockDatesModel } from '../../../shared/models/block-dates.model';
import { TextArea } from '../../../shared/components/core-ui/text-area/text-area.component';
import { ReservationType } from '../../../shared/enums/reservation-type';
import { ReservationMode } from '../../../shared/enums/reservation-mode.enum';
import {
  getAllAreas,
  getAllUsers,
  getAllUnitTypeGroups
} from '../../../shared/services/data/lookups.data';
import { AreaUiModel } from '../../../shared/ui-models/area.ui-model';
import { UnitTypeGroupUiModel } from '../../../shared/ui-models/unit-type-group.ui-model';
import { PropertyUiModel } from '../../../shared/ui-models/property.ui-model';
import { UserUIModel } from '../../../shared/ui-models/user.ui-model';
import { getAllProperties } from '../../../PropertyModule/shared/services/data.service';
import { ReservationChannel } from '../../../shared/enums/reservation-channel.enum';
import { store } from 'react-notifications-component';
import ReCAPTCHA from 'react-google-recaptcha';
import { GOOGLE_CAPTCHA_KEY } from '../../../shared/config/routes-config';

const captchaKEy = GOOGLE_CAPTCHA_KEY;

export interface ReservationsCreateModalProps {
  reservationType: string;
  eventHandlers: {
    closeHandler: () => void;
  };
}

export const ReservationsCreateContainer: React.FC<ReservationsCreateModalProps> = ({
  reservationType,
  eventHandlers
}) => {
  const [unitType, setUnitType] = useState<UnitTypeUiModel[]>([]);
  const [areaOptions, setAreaOptions] = useState<AreaUiModel[]>([]);
  const [usersOptions, setUsersOptions] = useState<UserUIModel[]>([]);
  const [unitTypeGroupsList, setUnitTypeGroupsList] = useState<UnitTypeGroupUiModel[]>([]);
  const [projectsList, setProjectsList] = useState<PropertyUiModel[]>([]);
  const [selectedUnitType, SetSelectedUniType] = useState<UnitTypeUiModel>();
  const [selectedDates, setSelectedDates] = useState<Date[]>();
  const [skipEmail, setSkipEmail] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const [proceedGuestStep, setProceedGuestStep] = useState<boolean>();
  const [blockDatesDescriptionStep, setBlockDatesDescriptionStep] = useState<boolean>();
  const [roomIdStep, setRoomIdStep] = useState<boolean>();
  const [bookAvailability, setBookAvailability] = useState<Date[]>([]);
  const [bookoffer, setBookoffer] = useState<BookOfferResponseModel>();
  const [selectedRoom, setSelectedRoom] = useState<string>();
  const [selectedRoomName, setSelectedRoomName] = useState<string>();
  const [selectedChannel, setSelectedChannel] = useState<ReservationChannel>(
    ReservationChannel.BIRDNEST
  );
  const [guestDetails, setGuestDetails] = useState<{
    name: string;
    phoneNumber: string;
    email: string;
    gender: string;
    id: string;
  }>();
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    trackPage('reservations-create');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getAllUnitTypes(getModuleName(), 0, -1, {}).then((response) => {
      if (!isErrorInfo(response)) {
        setUnitType(response?.unitList);
      }
    });

    getAllUnitTypeGroups(getModuleName()).then((response: UnitTypeGroupUiModel[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setUnitTypeGroupsList(response);
      }
    });
  }, []);

  const handleSkipEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSkipEmail(event.target.checked);
  };
  function getAvailability(unitTypeId: string) {
    return getBookAvailability(unitTypeId).then((resp) => {
      if (!isErrorInfo(resp)) {
        const bookAvailabilityResponse = resp as BookAvailabilityResponseModel[];
        let availabeDates: Date[] = [];
        bookAvailabilityResponse.forEach((availObj) => {
          const dates = getDaysArray(availObj.from, availObj.to);
          availabeDates = [...availabeDates, ...dates];
        });
        setBookAvailability(availabeDates);
      }
    });
  }

  return (
    <div className={styles['reservation-create-container']}>
      {!selectedUnitType && (
        <ChooseUnitType
          data={{
            unitTypes: unitType,
            unitTypeGroups: unitTypeGroupsList
          }}
          eventHandlers={{
            selectedUnitType: (selectedUnit) => {
              getAvailability(selectedUnit.id || '').then(() => SetSelectedUniType(selectedUnit));
            }
          }}
        ></ChooseUnitType>
      )}
      {selectedUnitType && reservationType === ReservationType.NORMAL_RESERVATION && (
        <div className={styles['reservation-create-container__title']}>Create Reservations</div>
      )}
      {selectedUnitType && reservationType === ReservationType.BLOCK_DATES && (
        <div className={styles['reservation-create-container__title']}>Block Dates</div>
      )}
      <div className={styles['reservation-create-container__details-container']}>
        <div>
          {selectedUnitType && !proceedGuestStep && !blockDatesDescriptionStep && (
            <>
              {selectedUnitType &&
              selectedUnitType.ratePlans &&
              selectedUnitType.ratePlans.length ? (
                <div className={styles['reservation-create-container__details-container__item']}>
                  <div
                    className={
                      styles['reservation-create-container__details-container__item__monthly-rates']
                    }
                  >
                    <div
                      className={
                        styles[
                          'reservation-create-container__details-container__item__monthly-rates__prices'
                        ]
                      }
                    >
                      <div
                        className={
                          styles[
                            'reservation-create-container__details-container__item__monthly-rates__prices__item'
                          ]
                        }
                      >
                        <div
                          className={
                            styles[
                              'reservation-create-container__details-container__item__monthly-rates__prices__item__duration'
                            ]
                          }
                        >
                          <div
                            className={
                              styles[
                                'reservation-create-container__details-container__item__monthly-rates__prices__item__duration__count'
                              ]
                            }
                          >
                            {selectedUnitType.reservationMode === 'monthly' ? '1' : 'Base'}
                          </div>
                          {selectedUnitType.reservationMode === 'monthly' ? 'month' : 'Rate'}
                        </div>
                        <div
                          className={
                            styles[
                              'reservation-create-container__details-container__item__monthly-rates__prices__item__amount'
                            ]
                          }
                        >
                          <div>
                            EGP{' '}
                            <b
                              className={
                                styles[
                                  'reservation-create-container__details-container__item__monthly-rates__prices__item__amount__count'
                                ]
                              }
                            >
                              {selectedUnitType.unitPrice}
                            </b>
                          </div>
                          {selectedUnitType.reservationMode === 'monthly' ? 'Monthly' : 'per Night'}
                        </div>
                      </div>
                      {selectedUnitType.ratePlans.map((ratePlan, index) => (
                        <div
                          className={
                            styles[
                              'reservation-create-container__details-container__item__monthly-rates__prices__item'
                            ]
                          }
                        >
                          <div
                            className={
                              styles[
                                'reservation-create-container__details-container__item__monthly-rates__prices__item__discount-label'
                              ]
                            }
                          >
                            {`${ratePlan.amount}% off`}
                          </div>
                          <div
                            className={
                              styles[
                                'reservation-create-container__details-container__item__monthly-rates__prices__item__duration'
                              ]
                            }
                          >
                            <div
                              className={
                                styles[
                                  'reservation-create-container__details-container__item__monthly-rates__prices__item__duration__count'
                                ]
                              }
                            >
                              {ratePlan.name}
                            </div>
                            {selectedUnitType.reservationMode === 'monthly' ? 'months' : 'nights'}
                          </div>
                          <div
                            className={
                              styles[
                                'reservation-create-container__details-container__item__monthly-rates__prices__item__amount'
                              ]
                            }
                          >
                            <div>
                              EGP{' '}
                              <b
                                className={
                                  styles[
                                    'reservation-create-container__details-container__item__monthly-rates__prices__item__amount__count'
                                  ]
                                }
                              >
                                {selectedUnitType
                                  ? // tslint:disable-next-line: radix
                                    parseInt(
                                      (
                                        (selectedUnitType.unitPrice as number) *
                                        (1 - ratePlan.amount / 100)
                                      ).toString()
                                    )
                                  : 0}
                              </b>
                            </div>
                            {selectedUnitType.reservationMode === 'monthly'
                              ? 'Monthly'
                              : 'per Night'}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              <ChooseAppt
                data={{
                  disbaleDate: bookAvailability,
                  basePrice:
                    selectedUnitType.reservationMode === ReservationMode.DAILY
                      ? selectedUnitType.unitPrice
                      : undefined,
                  customPrices:
                    selectedUnitType.reservationMode === ReservationMode.DAILY
                      ? selectedUnitType.customPrices
                      : undefined
                }}
                eventHandlers={{
                  setDates: (dates) => {
                    if (Array.isArray(dates) && dates.length === 2) {
                      setSelectedDates(dates);
                      getBookOffer(
                        selectedUnitType.id || '',
                        moment(dates[0]).format('DD/MM/YYYY'),
                        moment(dates[1]).format('DD/MM/YYYY')
                      ).then((res) => {
                        if (!isErrorInfo(res)) {
                          setBookoffer(res as BookOfferResponseModel);
                        }
                      });
                    }
                  },
                  proceedApptNextStep: (next: boolean) => {
                    if (reservationType === ReservationType.NORMAL_RESERVATION) {
                      setProceedGuestStep(next);
                    } else if (reservationType === ReservationType.BLOCK_DATES) {
                      setBlockDatesDescriptionStep(next);
                    }
                  },
                  cancelHandler: eventHandlers.closeHandler
                }}
              ></ChooseAppt>
            </>
          )}
          {proceedGuestStep && !guestDetails && (
            <div>
              <AddGuest
                eventHandlers={{
                  addGuestHandler: (guest) => {
                    setGuestDetails(guest);
                  },
                  closeHandler: () => {
                    eventHandlers.closeHandler();
                  }
                }}
              ></AddGuest>
            </div>
          )}

          {(guestDetails || blockDatesDescriptionStep) && (
            <div>
              Choose room
              <Select
                className={styles['reservation-create-container__details-container__select']}
                options={bookoffer?.availableRooms.map((room) => ({
                  label: room.shortName,
                  value: room.unitId
                }))}
                onChange={(
                  value: ValueType<{ value: string | undefined; label: string }, false>
                ) => {
                  setSelectedRoom(value?.value);
                  console.log(value?.label);
                  setSelectedRoomName(value?.label);
                }}
              />
              Select Channel
              <Select
                className={styles['reservation-create-container__details-container__select']}
                options={Object.keys(ReservationChannel).map((name) => ({
                  label: name,
                  value: ReservationChannel[name as keyof typeof ReservationChannel]
                }))}
                defaultValue={{ value: ReservationChannel.BIRDNEST, label: 'BIRDNEST' }}
                onChange={(
                  value: ValueType<{ value: string | undefined; label: string }, false>
                ) => {
                  console.log(value);
                  setSelectedChannel(value?.value);
                }}
              />
              {guestDetails && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={skipEmail}
                      onChange={handleSkipEmailChange}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                  label="Do NOT send email to guest"
                />
              )}
              {blockDatesDescriptionStep && (
                <div
                  className={styles['reservation-create-container__details-container__textarea']}
                >
                  Description
                  <TextArea
                    data={{
                      defaultValue: '',
                      placeholder: 'Add your comment'
                    }}
                    config={{
                      autoFocus: true
                    }}
                    eventHandlers={{
                      onChangeHandler: (value: string) => {
                        setDescription(value);
                      }
                    }}
                  />
                </div>
              )}
              <div className={styles['reservation-create-container__details-container--actions']}>
                <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={captchaKEy || ''} />
                <Button
                  onClick={() => {
                    eventHandlers.closeHandler();
                  }}
                  tracking={{
                    action: 'close',
                    category: 'appt',
                    label: 'choose-appt'
                  }}
                  size={ButtonSize.medium}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!selectedRoom}
                  onClick={async () => {
                    if (reservationType === ReservationType.NORMAL_RESERVATION) {
                      const reservations: CreateReservationRequestModel = {
                        primaryGuestName: guestDetails?.name || '',
                        phoneNumber: guestDetails?.phoneNumber || '',
                        gender: guestDetails?.gender || '',
                        email: guestDetails?.email || '',
                        skipEmail,
                        channel: selectedChannel,
                        unitTypeId: Number(selectedUnitType?.id),
                        roomId: selectedRoom || '',
                        recurringPayment: bookoffer?.recurringPayment,
                        oneTimePayment: bookoffer?.oneTimePayment,
                        rateCard: bookoffer?.rateCard,
                        checkInDate:
                          selectedDates && selectedDates[0]
                            ? moment(selectedDates[0]).format('DD/MM/YYYY')
                            : '',
                        checkOutDate:
                          selectedDates && selectedDates[1]
                            ? moment(selectedDates[1]).format('DD/MM/YYYY')
                            : ''
                      };
                      if (guestDetails?.id !== '') {
                        reservations['userId'] = guestDetails?.id || '';
                      }
                      const token = await recaptchaRef?.current?.executeAsync();
                      createReservation({ ...reservations, token }).then((response) => {
                        if (!isErrorInfo(response)) {
                          eventHandlers.closeHandler();
                        } else {
                          store.addNotification({
                            id: 'add-user-failed',
                            title: 'Create Reservation',
                            message: response?.errorMessage,
                            type: 'danger',
                            insert: 'top',
                            container: 'top-right',
                            animationIn: ['animate__animated', 'animate__fadeIn'],
                            animationOut: ['animate__animated', 'animate__fadeOut'],
                            dismiss: {
                              duration: 3000,
                              onScreen: true
                            }
                          });
                        }
                      });
                    } else if (reservationType === ReservationType.BLOCK_DATES) {
                      const blockDates: CreateBlockDatesModel = {
                        unitTypeId: Number(selectedUnitType?.id),
                        roomId: selectedRoom || '',
                        roomName: selectedRoomName || '',
                        fromDate:
                          (selectedDates && moment(selectedDates[0]).format('DD/MM/YYYY')) || '',
                        toDate:
                          (selectedDates && moment(selectedDates[1]).format('DD/MM/YYYY')) || '',
                        description
                      };
                      creatBlockDates(blockDates).then((response) => {
                        if (!isErrorInfo(response)) {
                          eventHandlers.closeHandler();
                        }
                      });
                    }
                  }}
                  tracking={{
                    action: 'submit',
                    category: 'appt',
                    label: 'create-Reservation'
                  }}
                  size={ButtonSize.medium}
                >
                  {reservationType === ReservationType.NORMAL_RESERVATION && 'Create Reservation'}
                  {reservationType === ReservationType.BLOCK_DATES && 'Block Dates'}
                </Button>
              </div>
            </div>
          )}
        </div>
        <div>
          {bookoffer &&
            selectedUnitType &&
            selectedDates &&
            reservationType === ReservationType.NORMAL_RESERVATION && (
              <PaymentDetails
                data={{
                  bookOffer: bookoffer,
                  unitType: selectedUnitType,
                  checkInDate: selectedDates[0],
                  checkOutDate: selectedDates[1],
                  guestDetails
                }}
              ></PaymentDetails>
            )}
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(ReservationsCreateContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}
const getDaysArray = (s: any, e: any) => {
  const a: Date[] = [];
  for (const d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
    a.push(new Date(d));
  }
  return a;
};
