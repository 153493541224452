import React, { useEffect, useState } from 'react';
import {
  FilterSelector,
  FilterSelectorOption
} from '../../../../../shared/components/group-ui/filter-selector/filter-selector.component';
import moment from 'moment';
import styles from './double-booking.module.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { RouteConfig } from '../../../../../shared/interfaces/routes-config.interface';
import { APP_ROUTES, PUBLIC_PATH } from '../../../../../shared/config/routes-config';
import { getDoubleBookings } from '../../../../../ReservationModule/shared/services/data.service';
import { ReservationStatus } from '../../../../../shared/enums/reservation-status';
import { isErrorInfo } from '../../../../../shared/interfaces/error-info.interface';
import { ReservationUiModel } from '../../../../../shared/ui-models/reservation.ui-model';
import { ReservationSource } from '../../../../../shared/enums/reservation-souce.enum';
import { ReservationChannel } from '../../../../../shared/enums/reservation-channel.enum';
import { BlockedDatesUiModel } from '../../../../../shared/ui-models/blocked-dates.ui-model';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
    }
  }
});
const useSelectedRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
      // backgroundColor: '#ffa358'
    }
  }
});
/**
 * functional component DoubleBookings
 * @param {DoubleBookingsProps}
 */
export const DoubleBookings: React.FC = () => {
  const [doubleBookings, setDoubleBookings] = useState<
    {
      currentReservation: ReservationUiModel;
      otherReservation?: ReservationUiModel;
      blockedDate?: BlockedDatesUiModel;
    }[]
  >([]);

  const classes = useRowStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getDoubleBookingsData = () => {
    getDoubleBookings().then((response) => {
      // setLoadingData(false);
      if (!isErrorInfo(response)) {
        setDoubleBookings(response);
      }
    });
  };
  useEffect(() => {
    getDoubleBookingsData();
    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDoubleBookings = (
    doubleBookings: {
      currentReservation: ReservationUiModel;
      otherReservation?: ReservationUiModel;
      blockedDate?: BlockedDatesUiModel;
    }[]
  ) => {
    return (
      <div className={styles['double-booking--table']}>
        <TableContainer component={Paper}>
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell>Room Name</TableCell>
                <TableCell>Channel</TableCell>
                <TableCell>#</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Check In</TableCell>
                <TableCell>Check Out</TableCell>
                <TableCell></TableCell>
                <TableCell>Channel</TableCell>
                <TableCell>#</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Check In</TableCell>
                <TableCell>Check Out</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {doubleBookings?.length > 0 &&
                doubleBookings?.map(
                  (
                    doubleBooking: {
                      currentReservation: ReservationUiModel;
                      otherReservation?: ReservationUiModel;
                      blockedDate?: BlockedDatesUiModel;
                    },
                    index
                  ) => <ReservationRow key={index} doubleBooking={doubleBooking} />
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  function ReservationRow(props: {
    doubleBooking: {
      currentReservation: ReservationUiModel;
      otherReservation?: ReservationUiModel;
      blockedDate?: BlockedDatesUiModel;
    };
  }) {
    const { doubleBooking } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const selectedClasses = useSelectedRowStyles();

    return (
      <>
        <TableRow
          className={
            doubleBooking.currentReservation.status === ReservationStatus.CHECKED_IN_STARTED
              ? selectedClasses.root
              : classes.root
          }
        >
          <TableCell>
            {doubleBooking.currentReservation.unitName.replace(/(^.*\(|\).*$)/g, '')}
          </TableCell>
          <TableCell>
            <div className={styles['double-booking__offer-info__dates__item__date']}>
              <div className={styles['double-booking__offer-info__channel']}>
                {doubleBooking.currentReservation.source === ReservationSource.BN_ADMIN ? (
                  <img
                    className={styles['double-booking__offer-info__channel-image']}
                    src={`${PUBLIC_PATH}/assets/jpeg/admin.webp`}
                  />
                ) : doubleBooking.currentReservation.source === ReservationSource.BN_APP ? (
                  <img
                    className={styles['double-booking__offer-info__channel-image__bigger']}
                    src={`${PUBLIC_PATH}/assets/pngs/bn-app.png`}
                  />
                ) : doubleBooking.currentReservation.source === ReservationSource.BN_WEBSITE ? (
                  <img
                    className={styles['double-booking__offer-info__channel-image__bigger']}
                    src={`${PUBLIC_PATH}/assets/pngs/bn-website.png`}
                  />
                ) : doubleBooking.currentReservation.source ===
                  ReservationSource.CHANNEL_MANAGER ? (
                  <img
                    className={styles['double-booking__offer-info__channel-image']}
                    src={`${PUBLIC_PATH}/assets/jpeg/rentals-united.jpeg`}
                  />
                ) : (
                  <img
                    className={styles['double-booking__offer-info__channel-image']}
                    src={`${PUBLIC_PATH}/assets/pngs/question-mark.png`}
                  />
                )}
                <ChevronRightIcon fontSize="inherit" />
                {doubleBooking.currentReservation.channel === ReservationChannel.AIRBNB ? (
                  <img
                    className={styles['double-booking__offer-info__channel-image']}
                    src={`${PUBLIC_PATH}/assets/pngs/airbnb.png`}
                  />
                ) : doubleBooking.currentReservation.channel === ReservationChannel.EXPEDIA ? (
                  <img
                    className={styles['double-booking__offer-info__channel-image']}
                    src={`${PUBLIC_PATH}/assets/pngs/expedia.png`}
                  />
                ) : doubleBooking.currentReservation.channel === ReservationChannel.BOOKING ? (
                  <img
                    className={styles['double-booking__offer-info__channel-image']}
                    src={`${PUBLIC_PATH}/assets/pngs/booking.png`}
                  />
                ) : doubleBooking.currentReservation.channel === ReservationChannel.VRBO ? (
                  <img
                    className={styles['double-booking__offer-info__channel-image']}
                    src={`${PUBLIC_PATH}/assets/pngs/vrbo.png`}
                  />
                ) : (
                  <img
                    className={styles['double-booking__offer-info__channel-image']}
                    src={`${PUBLIC_PATH}/assets/pngs/birdnest-logo.png`}
                  />
                )}
              </div>
            </div>
          </TableCell>
          <TableCell
            onClick={() => {
              window.open(
                PUBLIC_PATH +
                (APP_ROUTES.RESERVATION_MODULE.CHILDREN as RouteConfig).EDIT.FULL_PATH.replace(
                  ':id',
                  doubleBooking.currentReservation.id
                ),
                '_blank'
              );
            }}
          >
            {doubleBooking.currentReservation.id}
          </TableCell>
          <TableCell>{doubleBooking.currentReservation.primaryGuestName}</TableCell>

          <TableCell>
            {new Date(doubleBooking.currentReservation.checkInDate).toDateString()}
          </TableCell>
          <TableCell>
            {new Date(doubleBooking.currentReservation.checkOutDate).toDateString()}
          </TableCell>
          <TableCell>
            <div className={styles['double-booking__offer-info__dates__item__seperator']}></div>
          </TableCell>

          {doubleBooking.otherReservation ? (
            <>
              <TableCell>
                <div className={styles['double-booking__offer-info__dates__item__date']}>
                  <div className={styles['double-booking__offer-info__channel']}>
                    {doubleBooking.otherReservation.source === ReservationSource.BN_ADMIN ? (
                      <img
                        className={styles['double-booking__offer-info__channel-image']}
                        src={`${PUBLIC_PATH}/assets/jpeg/admin.webp`}
                      />
                    ) : doubleBooking.otherReservation.source === ReservationSource.BN_APP ? (
                      <img
                        className={styles['double-booking__offer-info__channel-image__bigger']}
                        src={`${PUBLIC_PATH}/assets/pngs/bn-app.png`}
                      />
                    ) : doubleBooking.otherReservation.source === ReservationSource.BN_WEBSITE ? (
                      <img
                        className={styles['double-booking__offer-info__channel-image__bigger']}
                        src={`${PUBLIC_PATH}/assets/pngs/bn-website.png`}
                      />
                    ) : doubleBooking.otherReservation.source ===
                      ReservationSource.CHANNEL_MANAGER ? (
                      <img
                        className={styles['double-booking__offer-info__channel-image']}
                        src={`${PUBLIC_PATH}/assets/jpeg/rentals-united.jpeg`}
                      />
                    ) : (
                      <img
                        className={styles['double-booking__offer-info__channel-image']}
                        src={`${PUBLIC_PATH}/assets/pngs/question-mark.png`}
                      />
                    )}
                    <ChevronRightIcon fontSize="inherit" />
                    {doubleBooking.otherReservation.channel === ReservationChannel.AIRBNB ? (
                      <img
                        className={styles['double-booking__offer-info__channel-image']}
                        src={`${PUBLIC_PATH}/assets/pngs/airbnb.png`}
                      />
                    ) : doubleBooking.otherReservation.channel === ReservationChannel.EXPEDIA ? (
                      <img
                        className={styles['double-booking__offer-info__channel-image']}
                        src={`${PUBLIC_PATH}/assets/pngs/expedia.png`}
                      />
                    ) : doubleBooking.otherReservation.channel === ReservationChannel.BOOKING ? (
                      <img
                        className={styles['double-booking__offer-info__channel-image']}
                        src={`${PUBLIC_PATH}/assets/pngs/booking.png`}
                      />
                    ) : doubleBooking.otherReservation.channel === ReservationChannel.VRBO ? (
                      <img
                        className={styles['double-booking__offer-info__channel-image']}
                        src={`${PUBLIC_PATH}/assets/pngs/vrbo.png`}
                      />
                    ) : (
                      <img
                        className={styles['double-booking__offer-info__channel-image']}
                        src={`${PUBLIC_PATH}/assets/pngs/birdnest-logo.png`}
                      />
                    )}
                  </div>
                </div>
              </TableCell>
              <TableCell
                onClick={() => {
                  window.open(
                    PUBLIC_PATH +
                    (
                      APP_ROUTES.RESERVATION_MODULE.CHILDREN as RouteConfig
                    ).EDIT.FULL_PATH.replace(':id', doubleBooking.otherReservation.id),
                    '_blank'
                  );
                }}
              >
                {doubleBooking.otherReservation.id}
              </TableCell>
              <TableCell>{doubleBooking.otherReservation.primaryGuestName}</TableCell>

              <TableCell>
                {new Date(doubleBooking.otherReservation.checkInDate).toDateString()}
              </TableCell>
              <TableCell>
                {new Date(doubleBooking.otherReservation.checkOutDate).toDateString()}
              </TableCell>
            </>
          ) : doubleBooking.blockedDate ? (
            <>
              <TableCell>
                <div className={styles['double-booking__offer-info__dates__item__date']}>
                  <div className={styles['double-booking__offer-info__channel']}>
                    <img
                      className={styles['double-booking__offer-info__channel-image']}
                      src={`${PUBLIC_PATH}/assets/pngs/blocked.png`}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell
                onClick={() => {
                  window.open(
                    PUBLIC_PATH +
                    (APP_ROUTES.BLOCK_DATES_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH,
                    '_blank'
                  );
                }}
              >
                {doubleBooking.blockedDate.id}
              </TableCell>
              <TableCell>{doubleBooking.blockedDate?.description}</TableCell>

              <TableCell>{new Date(doubleBooking.blockedDate?.fromDate).toDateString()}</TableCell>
              <TableCell>{new Date(doubleBooking.blockedDate?.toDate).toDateString()}</TableCell>
            </>
          ) : (
            <></>
          )}
        </TableRow>
      </>
    );
  }

  return (
    <div className={styles['double-booking']}>
      <div className={styles['double-booking--title']}>
        <WarningIcon className={styles['pending-actions--title_icon']} /> Double Booking Check
      </div>
      {isLoading ? (
        <div className={styles['double-booking__loader']}>
          <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
        </div>
      ) : (
        <>
          {doubleBookings.length ? (
            renderDoubleBookings(doubleBookings)
          ) : (
            <div className={styles['double-booking__no-records']}>
              <img src={`${PUBLIC_PATH}/assets/pngs/passed.png`} alt="no-data" /> Everything looks
              Great!
            </div>
          )}
        </>
      )}
    </div>
  );
};
