import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { APP_ROUTES } from '../shared/config/routes-config';
import { RouteConfig } from '../shared/interfaces/routes-config.interface';
import { RequestsListContainer } from './containers/requests-list/requests-list.container';
/**
 * function BookingModule components
 */
export const BookingModule: React.FC = () => {
  return (
    <>
      <Route exact path={APP_ROUTES.BOOKING_MODULE.FULL_PATH}>
        <Redirect to={(APP_ROUTES.BOOKING_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH} />
      </Route>
      <Route
        path={(APP_ROUTES.BOOKING_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH}
        component={RequestsListContainer}
      />
    </>
  );
};
