import { ApiResponse } from 'apisauce';
import { ErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { ResponseModel } from '../../../shared/interfaces/response-model.interface';
import UnitTypeGroupMapper from '../../../shared/mappers/unit-type-group.mapper';
import { UnitTypeGroupModel } from '../../../shared/models/unit-type-group..model';
import api from '../../../shared/services/utils/api/api.service';
import errorHelper from '../../../shared/services/utils/api/error-helper.service';
import { UnitTypeGroupUiModel } from '../../../shared/ui-models/unit-type-group.ui-model';
import { getModuleName } from './unit-type-group.service';

export function addNewUnitTypeGroup(
  areaData: UnitTypeGroupModel
): Promise<UnitTypeGroupUiModel | ErrorInfo> {
  const mappedUnitTypeGroup = UnitTypeGroupMapper.mapToModel(areaData);
  return api
    .createUnitTypeGroup(mappedUnitTypeGroup)
    .then((response: ApiResponse<ResponseModel<UnitTypeGroupModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return UnitTypeGroupMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.createUnitTypeGroup as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.createUnitTypeGroup as any).displayName
        );
      }
    });
}

export function updateUnitTypeGroup(
  areaId: string,
  areaData: UnitTypeGroupUiModel
): Promise<UnitTypeGroupUiModel | ErrorInfo> {
  const mappedUnitTypeGroup = UnitTypeGroupMapper.mapToModel(areaData);
  return api
    .updateUnitTypeGroup(areaId, mappedUnitTypeGroup)
    .then((response: ApiResponse<ResponseModel<UnitTypeGroupModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return UnitTypeGroupMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.updateUnitTypeGroup as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.updateUnitTypeGroup as any).displayName
        );
      }
    });
}

export function deleteUnitTypeGroup(areaId: string): Promise<{} | ErrorInfo> {
  return api
    .deleteUnitTypeGroup(areaId)
    .then((response: ApiResponse<ResponseModel<{}>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return {};
        } else {
          return errorHelper(0, '', getModuleName(), (api.deleteUnitTypeGroup as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.deleteUnitTypeGroup as any).displayName
        );
      }
    });
}
