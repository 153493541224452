import { ApiResponse } from 'apisauce';
import { ErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { ResponseModel } from '../../../shared/interfaces/response-model.interface';
import BookingRequestsMapper from '../../../shared/mappers/booking-requests.mapper';
import { BookingRequestModel, BookingRequestModelData } from '../../../shared/models/booking-request.model';
import api from '../../../shared/services/utils/api/api.service';
import errorHelper from '../../../shared/services/utils/api/error-helper.service';
import { BookingRequestUiModel, BookingRequestModelUiData } from '../../../shared/ui-models/booking-request.ui-model';
import { getModuleName } from './booking.service';

export function getAllBookingRequests(): Promise<BookingRequestUiModel[] | ErrorInfo> {
  return api
    .getBookingRequests()
    .then((response: ApiResponse<ResponseModel<BookingRequestModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return BookingRequestsMapper.mapToUIList(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.getBookingRequests as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.getBookingRequests as any).displayName
        );
      }
    });
}

export function acceptBookingRequest(
  requestId: string,
  roomId: string
): Promise<BookingRequestUiModel | ErrorInfo> {
  return api
    .acceptBookingRequest(requestId, roomId)
    .then((response: ApiResponse<ResponseModel<BookingRequestModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return BookingRequestsMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.acceptBookingRequest as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.acceptBookingRequest as any).displayName
        );
      }
    });
}
export function rejectBookingRequest(
  requestId: string,
  rejectionReason: string
): Promise<BookingRequestUiModel | ErrorInfo> {
  return api
    .rejectBookingRequest(requestId, rejectionReason)
    .then((response: ApiResponse<ResponseModel<BookingRequestModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return BookingRequestsMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.rejectBookingRequest as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.rejectBookingRequest as any).displayName
        );
      }
    });
}
export function submitBookingMessage(message: string, requestId: string): Promise<any | ErrorInfo> {
  return api
    .submitBookingMessage(message, requestId)
    .then((response: ApiResponse<ResponseModel<{}>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return data;
        } else {
          return errorHelper(0, '', getModuleName(), (api.acceptBookingRequest as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.acceptBookingRequest as any).displayName
        );
      }
    });
}
export function getBookingRequests(query: {}, offset: number,
  limit: number): Promise<BookingRequestModelUiData | ErrorInfo> {
  return api.getBookingRequestsByQuery(query, offset,
      limit)
    .then((response: ApiResponse<ResponseModel<BookingRequestModelData>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data&& data?.count) {
          return { BookingRequestList:BookingRequestsMapper.mapToUIList(data.data), count: data?.count };
        } else {
          return errorHelper(0, '', getModuleName(), (api.getBookingRequests as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.getBookingRequests as any).displayName
        );
      }
    });
}
