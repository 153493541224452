import React, { useEffect, useState } from 'react';
import styles from './split-payment-modal.module.scss';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import {
  InputText,
  InputTextType
} from '../../../../../shared/components/core-ui/input-text/input-text.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { SplitPaymentModel } from '../../../../../shared/ui-models/payment.ui-model';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';

import { VALIDATION_REGEX } from '../../../../../shared/components/validation.config';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { PaymentModel } from '../../../../../shared/models/payment.model';
import { ReservationDetailsUiModel } from '../../../../../shared/ui-models/reservation.ui-model';
import { CURRENCY } from '../../../../../shared/enums/currency.enum';
import NumberFormat from 'react-number-format';
import { PaymentItemModel } from '../../../../../shared/models/payment-item.model';
import { PaymentStatus } from '../../../../../shared/enums/payment-status.enum';

export interface SplitPaymentModalProps {
  config: {
    showModal: boolean;
  };
  data: {
    reservation: ReservationDetailsUiModel | undefined;
    sourcePayment: PaymentModel | undefined;
  };
  eventHandlers: {
    closeHandler: () => void;
    SplitPaymentHandler: (paymentSplitData: SplitPaymentModel) => void;
  };
}

/**
 * functional component AddPaymentModal
 * @param {SplitPaymentModalProps}
 */
export const SplitPaymentModal: React.FC<SplitPaymentModalProps> = ({
  config,
  eventHandlers,
  data
}) => {
  const [splitPercentage, setSplitPercentage] = useState<FormElementData<number>>({
    value: 0,
    isValid: false
  });

  const [sourcePayment, setSourcePayment] = useState<PaymentModel>(data?.sourcePayment);
  const [newPayment, setNewPayment] = useState<PaymentModel>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isMobileView = useWindowResizer();

  useEffect(() => {
    setSourcePayment(data?.sourcePayment);
    setNewPayment(undefined);
    setIsLoading(false);
    setSplitPercentage({
      value: 0,
      isValid: false
    });
  }, [data]);
  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          setSourcePayment(undefined);
          setNewPayment(undefined);
          setSplitPercentage({
            value: 0,
            isValid: false
          });
          eventHandlers.closeHandler();
        }
      }}
    >
      <div className={styles['split-payment-modal']}>
        <div className={styles['split-payment-modal__title']}>Split Payment </div>

        <div className={styles['split-payment-modal__description']}>
          Percentage Amount
          <InputText
            data={{
              value: splitPercentage.value || 0,
              placeholder: 'Split %'
            }}
            config={{
              autoFocus: true,
              type: InputTextType.number,
              required: true
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setSplitPercentage({ value: Number(value), isValid });
                if (Number(value) === 0) {
                  setSourcePayment(data.sourcePayment);
                  setNewPayment(undefined);
                }
                if (data && data.sourcePayment) {
                  const tempSourcePayment: PaymentModel = {
                    ...data.sourcePayment,
                    paymentItems: [
                      ...data.sourcePayment.paymentItems.map((item: PaymentItemModel) => ({
                        ...item,
                        feeName: item.feeName + ' (Split #1)',
                        feeValue: item.feeValue * (Number(value) / 100),
                        feeTotal: item.feeTotal
                          ? Number(item.feeTotal) * (Number(value) / 100)
                          : undefined
                      }))
                    ]
                  };
                  const tempNewPayment: PaymentModel = {
                    ...data.sourcePayment,
                    paymentItems: [
                      ...data.sourcePayment.paymentItems.map((item: PaymentItemModel) => ({
                        ...item,
                        feeName: item.feeName + ' (Split #2)',
                        feeValue: item.feeValue * ((100 - Number(value)) / 100),
                        feeTotal: item.feeTotal
                          ? Number(item.feeTotal) * ((100 - Number(value)) / 100)
                          : undefined
                      }))
                    ]
                  };

                  setSourcePayment(tempSourcePayment);
                  setNewPayment(tempNewPayment);
                }
              }
            }}
          />
        </div>
        <label>Payment 1</label>
        <div className={styles['split-payment-modal__items-wrapper']}>
          <div className={styles['split-payment-modal__items-wrapper__item']}>
            <div className={styles['split-payment-modal__description']}>
              <label>Fee Name</label>
            </div>
            <div className={styles['split-payment-modal__description']}>
              <label>Payment Type</label>
            </div>
            <div className={styles['split-payment-modal__description']}>
              <label>Amount</label>
            </div>
          </div>
          {sourcePayment && sourcePayment.paymentItems && sourcePayment.paymentItems.length > 0 && (
            <>
              {sourcePayment.paymentItems.map((paymentItem, index) => (
                <div className={styles['split-payment-modal__items-wrapper__item']} key={index}>
                  <div className={styles['split-payment-modal__description']}>
                    {paymentItem.feeName}
                  </div>
                  <div className={styles['split-payment-modal__description']}>
                    {paymentItem.itemType || 'N/A'}
                  </div>
                  <div className={styles['split-payment-modal__description']}>
                    <NumberFormat
                      value={paymentItem.feeValue}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={
                        sourcePayment.currency ? CURRENCY[sourcePayment.currency] + ' ' : 'EGP '
                      }
                    />
                  </div>
                </div>
              ))}
              <hr className={styles['split-payment-modal__separator']} />
              <div className={styles['split-payment-modal__items-wrapper__header']}>
                <label>Total</label>
                <span>
                  {sourcePayment.paymentItems.reduce((acc, item) => acc + Number(item.feeValue), 0)}
                </span>
              </div>
            </>
          )}
        </div>

        <label>Payment 2</label>
        <div className={styles['split-payment-modal__items-wrapper']}>
          <div className={styles['split-payment-modal__items-wrapper__item']}>
            <div className={styles['split-payment-modal__description']}>
              <label>Fee Name</label>
            </div>
            <div className={styles['split-payment-modal__description']}>
              <label>Payment Type</label>
            </div>
            <div className={styles['split-payment-modal__description']}>
              <label>Amount</label>
            </div>
          </div>
          {newPayment && newPayment.paymentItems && newPayment.paymentItems.length > 0 ? (
            <>
              {newPayment.paymentItems.map((paymentItem, index) => (
                <div className={styles['split-payment-modal__items-wrapper__item']} key={index}>
                  <div className={styles['split-payment-modal__description']}>
                    {paymentItem.feeName}
                  </div>
                  <div className={styles['split-payment-modal__description']}>
                    {paymentItem.itemType || 'N/A'}
                  </div>
                  <div className={styles['split-payment-modal__description']}>
                    <NumberFormat
                      value={paymentItem.feeValue}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={newPayment.currency ? CURRENCY[newPayment.currency] + ' ' : 'EGP '}
                    />
                  </div>
                </div>
              ))}
              <hr className={styles['split-payment-modal__separator']} />
              <div className={styles['split-payment-modal__items-wrapper__header']}>
                <label>Total</label>
                <span>
                  {newPayment.paymentItems.reduce((acc, item) => acc + Number(item.feeValue), 0)}
                </span>
              </div>
            </>
          ) : (
            <div className={styles['split-payment-modal__items-wrapper__item']}>
              <div className={styles['split-payment-modal__description']}>
                Please enter split percentage first.
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles['split-payment-modal--actions']}>
        <Button
          onClick={() => {
            eventHandlers.closeHandler();
          }}
          type={ButtonType.primaryInverted}
          tracking={{
            action: 'close',
            category: 'payments',
            label: 'split-payment-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          Cancel
        </Button>
        <Button
          loading={isLoading}
          disabled={!splitPercentage.isValid || splitPercentage.value === 0 || !newPayment}
          onClick={() => {
            setIsLoading(true);
            if (sourcePayment && newPayment) {
              eventHandlers.SplitPaymentHandler({
                paymentToUpdate: { id: sourcePayment.id, paymentItems: sourcePayment.paymentItems },
                paymentToAdd: {
                  reservationId: newPayment.reservationId,
                  unitTypeId: newPayment.unitTypeId || '',
                  roomId: newPayment.roomId || '',
                  dueDate: newPayment.dueDate,
                  areaId: newPayment.areaId,
                  userId: newPayment.userId,
                  document: newPayment.paymentItems,
                  direction: newPayment.direction,
                  paymentItems: newPayment.paymentItems,
                  status: PaymentStatus.PENDING,
                  collector: newPayment.collector,
                  paymentType: newPayment.paymentType,
                  checkInDate: newPayment.checkInDate,
                  checkOutDate: newPayment.checkOutDate,
                  landlordId: newPayment.landlordId || '',
                  unitTypeGroupId: newPayment.unitTypeGroupId || '',
                  propertyId: newPayment.propertyId || '',
                  source: newPayment.source
                }
              });
            }
          }}
          tracking={{
            action: 'submit',
            category: 'payment',
            label: 'split-payment-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          Generate
        </Button>
      </div>
    </Modal>
  );
};
