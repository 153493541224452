import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './edit-financial-info.module.scss';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { store } from 'react-notifications-component';
import MenuItem from '@material-ui/core/MenuItem';
import {
  InputText,
  InputTextType
} from '../../../../../shared/components/core-ui/input-text/input-text.component';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import Button, {
  ButtonSize
} from '../../../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { UnitTypeUiModel } from '../../../../../shared/ui-models/unit-type.ui-model';
import { ReservationMode } from '../../../../../shared/enums/reservation-mode.enum';
import { UnitTypeRate } from '../../../../../shared/models/unit-type-rate.model';
import { UnitTypeFeeUiModel } from '../../../../../shared/ui-models/unit-type-fee.model';

export interface EditFinancialInfoProps {
  data: {
    unitPrice: number;
    insurance: number;
    downPayment: number;
    minStayDuration: number;
    maxStayDuration: number;
    reservationMode: ReservationMode;
  };
  eventHandlers: {
    submitHandler: (data: Partial<UnitTypeUiModel>) => void;
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),

      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  })
);
/**
 * functional component EditFinancialInfo
 * @param {EditFinancialInfoProps} holding question text
 */
export const EditFinancialInfo: React.FC<EditFinancialInfoProps> = ({ data, eventHandlers }) => {
  const [newDownPayment, setNewDownPaymentByMonths] = useState<FormElementData<number>>({
    value: 0,
    isValid: false,
    changed: false
  });
  const [newInsurance, setNewSecurityByMonths] = useState<FormElementData<number>>({
    value: 0,
    isValid: false,
    changed: false
  });
  const [newUnitPrice, setNewUnitPrice] = useState<FormElementData<number>>({
    value: 0,
    isValid: false,
    changed: false
  });
  const [newMaxDuration, setNewMaxDuration] = useState<FormElementData<number>>({
    value: 0,
    isValid: false,
    changed: false
  });
  const [newMinDuration, setNewMinDuration] = useState<FormElementData<number>>({
    value: 0,
    isValid: false,
    changed: false
  });

  const [newReservationMode, setNewReservationMode] = useState<FormElementData<ReservationMode>>({
    value: ReservationMode.MONTHLY,
    isValid: false,
    changed: false
  });
  const [isDownPaymentValid, setIsDownPaymentValid] = useState<boolean>(true);

  const { t } = useTranslation();
  const classes = useStyles();
  const isMobileView = useWindowResizer();

  function validateDownPayment(
    minStay: number,
    downPayment: number,
    reservationMode: ReservationMode
  ) {
    if (reservationMode === ReservationMode.DAILY) {
      setIsDownPaymentValid(true);
    } else {
      if (downPayment > minStay) {
        setIsDownPaymentValid(false);
      } else {
        setIsDownPaymentValid(true);
      }
    }
  }
  useEffect(() => {
    return () => {
      store.removeNotification('general-info-failed');
      store.removeNotification('general-info-success');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNewDownPaymentByMonths({
      value: data.downPayment,
      isValid: false,
      changed: false
    });
    setNewSecurityByMonths({
      value: data.insurance,
      isValid: false,
      changed: false
    });
    setNewUnitPrice({
      value: data.unitPrice,
      isValid: false,
      changed: false
    });
    setNewReservationMode({
      value: data.reservationMode,
      isValid: false,
      changed: false
    });
    setNewMinDuration({
      value: data.minStayDuration,
      isValid: false,
      changed: false
    });
    setNewMaxDuration({
      value: data.maxStayDuration,
      isValid: false,
      changed: false
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleGeneralInfoSubmit = () => {
    const updatedData: {
      insurance?: number;
      downPayment?: number;
      reservationMode?: ReservationMode;
      unitPrice?: number;
      minStayDuration?: number;
      maxStayDuration?: number;
      ratePlans?: UnitTypeRate[];
      fees?: UnitTypeFeeUiModel[];
    } = {};
    if (newDownPayment.changed && newDownPayment.value) {
      updatedData['downPayment'] =
        newReservationMode.value === ReservationMode.MONTHLY ? newDownPayment.value : 0;
    }
    if (newInsurance.changed) {
      updatedData['insurance'] = newInsurance.value || 0;
    }
    if (newReservationMode.changed) {
      updatedData['reservationMode'] = newReservationMode.value || 0;
    }

    if (newUnitPrice.changed) {
      updatedData['unitPrice'] = newUnitPrice.value || 0;
    }
    if (newMaxDuration.changed) {
      updatedData['maxStayDuration'] = newMaxDuration.value || 0;
    }
    if (newMinDuration.changed) {
      updatedData['minStayDuration'] = newMinDuration.value || 0;
    }

    eventHandlers.submitHandler(updatedData);
  };

  return (
    <div className={styles['financial-info-container']}>
      <div className={styles['financial-info-container__sub-title']}>
        {t('UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.FINANCIAL_INFO')}
      </div>
      <div className={styles['financial-info-container__wrapper']}>
        {t('UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.RESERVATION_MODE')}
        <div className={styles['financial-info-container__field']}>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={newReservationMode.value}
              onChange={(
                event: React.ChangeEvent<{
                  name?: string | undefined;
                  value: unknown;
                }>
              ) => {
                validateDownPayment(
                  newMinDuration.value,
                  newDownPayment.value,
                  event.target.value as ReservationMode
                );
                setNewReservationMode({
                  value: event.target.value as ReservationMode,
                  isValid: true,
                  changed: true
                });
              }}
            >
              <MenuItem value={ReservationMode.MONTHLY}>{ReservationMode.MONTHLY}</MenuItem>
              <MenuItem value={ReservationMode.DAILY}>{ReservationMode.DAILY}</MenuItem>
            </Select>
          </FormControl>
        </div>
        {t('UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.MIN_DURATION')}
        <div className={styles['financial-info-container__field']}>
          <div className={styles['financial-info-container__field__item']}>
            <InputText
              config={{
                autoFocus: false,
                type: InputTextType.number
                // minLength: 6,
                // required: true
              }}
              data={{
                // placeholder: t(
                //   'UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.SECURITY_DEPOSIT_PLACEHOLDER'
                // ),
                // errorMessage: t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.PROPERTY_NAME_ERROR_MESSAGE'),
                value: newMinDuration.value
              }}
              eventHandlers={{
                onChangeHandler: (value, isValid) => {
                  if (isValid) {
                    validateDownPayment(
                      Number(value),
                      newDownPayment.value,
                      newReservationMode.value
                    );
                    setNewMinDuration({
                      value: Number(value),
                      isValid: true,
                      changed: true
                    });
                  }
                }
              }}
            />
          </div>
          <div className={styles['financial-info-container__field__item']}>
            {newReservationMode.value}
          </div>
        </div>
        {t('UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.MAX_DURATION')}
        <div className={styles['financial-info-container__field']}>
          <div className={styles['financial-info-container__field__item']}>
            <InputText
              config={{
                autoFocus: false,
                type: InputTextType.number
                // minLength: 6,
                // required: true
              }}
              data={{
                // placeholder: t(
                //   'UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.SECURITY_DEPOSIT_PLACEHOLDER'
                // ),
                // errorMessage: t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.PROPERTY_NAME_ERROR_MESSAGE'),
                value: newMaxDuration.value
              }}
              eventHandlers={{
                onChangeHandler: (value, isValid) => {
                  if (isValid) {
                    setNewMaxDuration({
                      value: Number(value),
                      isValid: true,
                      changed: true
                    });
                  }
                }
              }}
            />
          </div>
          <div className={styles['financial-info-container__field__item']}>
            {newReservationMode.value}
          </div>
        </div>
        {t('UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.UNIT_PRICE')}
        <div className={styles['financial-info-container__field']}>
          <div className={styles['financial-info-container__field__item']}>
            <InputText
              config={{
                autoFocus: false,
                type: InputTextType.number
                // minLength: 6,
                // required: true
              }}
              data={{
                // placeholder: t(
                //   'UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.SECURITY_DEPOSIT_PLACEHOLDER'
                // ),
                // errorMessage: t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.PROPERTY_NAME_ERROR_MESSAGE'),
                value: newUnitPrice.value
              }}
              eventHandlers={{
                onChangeHandler: (value, isValid) => {
                  if (isValid) {
                    setNewUnitPrice({
                      value: Number(value),
                      isValid: true,
                      changed: true
                    });
                  }
                }
              }}
            />
          </div>
          <div className={styles['financial-info-container__field__item']}>
            {newReservationMode.value === ReservationMode.MONTHLY
              ? t('UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.PER_MONTH')
              : t('UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.PER_NIGHT')}
          </div>
        </div>
        {t('UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.SECURITY_DEPOSIT')}
        <div className={styles['financial-info-container__field']}>
          <div className={styles['financial-info-container__field__item']}>
            <InputText
              config={{
                autoFocus: false,
                type: InputTextType.number
                // minLength: 6,
                // required: true
              }}
              data={{
                placeholder: t(
                  'UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.SECURITY_DEPOSIT_PLACEHOLDER'
                ),
                // errorMessage: t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.PROPERTY_NAME_ERROR_MESSAGE'),
                value: newInsurance.value
              }}
              eventHandlers={{
                onChangeHandler: (value, isValid) => {
                  if (isValid) {
                    setNewSecurityByMonths({
                      value: Number(value),
                      isValid: true,
                      changed: true
                    });
                  }
                }
              }}
            />
          </div>
          <div className={styles['financial-info-container__field__item']}>
            {newReservationMode.value === ReservationMode.MONTHLY
              ? t('UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.MONTHS')
              : t('UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.DAYS')}
          </div>
        </div>
        {newReservationMode.value === ReservationMode.MONTHLY ? (
          <>
            {t('UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.DOWN_PAYMENT_DEPOSIT')}
            <div className={styles['financial-info-container__field']}>
              <div className={styles['financial-info-container__field__item']}>
                <InputText
                  config={{
                    autoFocus: false,
                    type: InputTextType.number,
                    error: !isDownPaymentValid
                    // minLength: 6,
                    // required: true
                  }}
                  data={{
                    errorMessage: isDownPaymentValid
                      ? ''
                      : "down payment can't be greater than minmum duration",
                    placeholder: t(
                      'UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.DOWN_PAYMENT_DEPOSIT_PLACEHOLDER'
                    ),
                    // errorMessage: t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.PROPERTY_NAME_ERROR_MESSAGE'),
                    value: newDownPayment.value
                  }}
                  eventHandlers={{
                    onChangeHandler: (value, isValid) => {
                      if (isValid) {
                        validateDownPayment(
                          newMinDuration.value,
                          Number(value),
                          newReservationMode.value
                        );
                        setNewDownPaymentByMonths({
                          value: Number(value),
                          isValid: true,
                          changed: true
                        });
                      }
                    }
                  }}
                />
              </div>
              <div className={styles['financial-info-container__field__item']}>
                {newReservationMode.value === ReservationMode.MONTHLY
                  ? t('UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.MONTHS')
                  : t('UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.DAYS')}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        <hr className={styles['financial-info-container__separator']} />

        <div className={styles['financial-info-container__actions']}>
          <Button
            disabled={
              (!newInsurance.changed &&
                !newDownPayment.changed &&
                !newUnitPrice.changed &&
                !newMinDuration.changed &&
                !newMaxDuration.changed &&
                !newReservationMode.changed) ||
              !isDownPaymentValid
            }
            onClick={handleGeneralInfoSubmit}
            tracking={{
              action: 'submit',
              category: 'unit-type',
              label: 'edit-unit-type-financial'
            }}
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          >
            {t('UNIT_TYPE_MODULE.EDIT_FINANCIAL_CONTAINER.UPDATE_GENERAL_INFO')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditFinancialInfo;
