import React, { useState } from 'react';
import styles from './select-available-room-modal.module.scss';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Select, { ValueType } from 'react-select';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { TextArea } from '../../../../../shared/components/core-ui/text-area/text-area.component';

export interface SelectAvailableRoomModalProps {
  data: {
    availableRooms: { unitId: string; shortName: string }[];
  };
  config: {
    showModal: boolean;
  };

  eventHandlers: {
    closeHandler: () => void;
    submitRoomId: (value: string) => void;
  };
}

/**
 * functional component SelectAvailableRoomModalProps
 * @param {SelectAvailableRoomModalProps}
 */
export const SelectAvailableRoomModal: React.FC<SelectAvailableRoomModalProps> = ({
  eventHandlers,
  config,
  data
}) => {
  const [selectedRoom, setSelectedRoom] = useState<string>();

  const isMobileView = useWindowResizer();
  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.closeHandler();
        }
      }}
    >
      <div className={styles['select-available-room-modal']}>
        <div className={styles['select-available-room-modal__title']}>Check Availability</div>
        <div className={styles['select-available-room-modal__description']}>
          Choose room
          <Select
            className={styles['reservation-create-container__details-container__select']}
            options={data.availableRooms.map((room) => ({
              label: room.shortName,
              value: room.unitId
            }))}
            onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
              setSelectedRoom(value?.value);
            }}
          />
        </div>
        <div className={styles['select-available-room-modal--actions']}>
          <Button
            onClick={() => {
              eventHandlers.closeHandler();
            }}
            type={ButtonType.primaryInverted}
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          >
            Cancel
          </Button>

          <Button
            disabled={!selectedRoom}
            onClick={() => {
              eventHandlers.submitRoomId(selectedRoom || '');
            }}
            tracking={{
              action: 'reactivate',
              category: 'reservations',
              label: 'edit-reservation'
            }}
            size={ButtonSize.medium}
          >
            Confirm Reservation
          </Button>
        </div>
      </div>
    </Modal>
  );
};
