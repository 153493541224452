import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './delete-unit-type-modal.module.scss';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';

export interface DeleteUnitTypeModalProps {
  config: {
    showModal: boolean;
  };
  eventHandlers: {
    closeHandler: () => void;
  };
}

/**
 * functional component DeleteUnitTypeModal
 * @param {DeleteUnitTypeModalProps}
 */
export const DeleteUnitTypeModal: React.FC<DeleteUnitTypeModalProps> = ({
  config,
  eventHandlers
}) => {
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.closeHandler();
        }
      }}
    >
      <div className={styles['delete-unit-type-modal']}>
        <div className={styles['delete-unit-type-modal__title']}>
          {t('UNIT_TYPE_MODULE.LIST_CONTAINER.DELETE_UNIT_TYPE_MODAL.TITLE')}
        </div>
        <div className={styles['add-unit-type-modal__description']}>
          {t('UNIT_TYPE_MODULE.LIST_CONTAINER.DELETE_UNIT_TYPE_MODAL.DESCRIPTION')}
          <p>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={t('UNIT_TYPE_MODULE.LIST_CONTAINER.DELETE_UNIT_TYPE_MODAL.CLOUDBEDS_ADD_LINK')}
            >
              {t('UNIT_TYPE_MODULE.LIST_CONTAINER.DELETE_UNIT_TYPE_MODAL.CLOUDBEDS_ADD')}
            </a>
          </p>
        </div>
      </div>
      <div className={styles['delete-unit-type-modal--actions']}>
        <Button
          onClick={() => {
            eventHandlers.closeHandler();
          }}
          tracking={{
            action: 'close',
            category: 'unit-type',
            label: 'delete-unit-type-modal'
          }}
          type={ButtonType.danger}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          {t('UNIT_TYPE_MODULE.LIST_CONTAINER.DELETE_UNIT_TYPE_MODAL.CANCEL_BUTTON')}
        </Button>
      </div>
    </Modal>
  );
};
