import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './requests-list.module.scss';
import { store } from 'react-notifications-component';
import Accordion from '@material-ui/core/Accordion';
import InfoIcon from '@material-ui/icons/Info';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Countdown from 'react-countdown';
import { ErrorInfo, isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { RootState } from '../../../shared/redux/reducers';
import { trackPage } from '../../../shared/services/utils/tracking/tracking.service';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import useWindowResizer from '../../../shared/hooks/window-resizer/window-resizer.hook';
import {
  BookingRequestUiModel,
  BookingRequestModelUiData
} from '../../../shared/ui-models/booking-request.ui-model';
import {
  acceptBookingRequest,
  rejectBookingRequest,
  getAllBookingRequests,
  submitBookingMessage,
  getBookingRequests
} from '../../shared/services/data.service';
import { BookingRequestDetails } from './components/booking-request-details/booking-request-details.component';
import moment from 'moment';
import { BookingRequestStatus } from '../../../shared/enums/booking-request-status.enum';
import { Tabs, TabsPosition } from '../../../shared/components/group-ui/tabs/tabs.component';
import { SelectAvailableRoom } from './components/select-available-room/select-available-room.component';
import { TextArea } from '../../../shared/components/core-ui/text-area/text-area.component';
import Button from '../../../shared/components/core-ui/button/button.component';
import { MessagesList } from '../../../shared/models/booking-request.model';
import { RejectRequest } from './components/reject-request/reject-request.component';
import {
  FilterRequestList,
  FilterRequestListrModel
} from './components/filter-request-list/filter-request-list.component';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import { getBookingRequestDetails } from '../../../shared/services/data/booking.data';
import { getModuleName } from '../../shared/services/booking.service';
import { PUBLIC_PATH } from '../../../shared/config/routes-config';
import { Pagination } from '../../../shared/components/core-ui/pagination/pagination.component';

export type RequestsListContainerProps = ReturnType<typeof mapStateToProps>;

/**
 * functional component RequestsListContainer
 * @param {RequestsListContainerProps} holding question text
 */
export const RequestsListContainer: React.FC<RequestsListContainerProps> = ({
  isAuthenticated
}) => {
  const [bookingRequestsList, setBookingRequestsList] = useState<BookingRequestUiModel[]>([]);
  const [urgentList, setUrgentList] = useState<BookingRequestUiModel[]>([]);
  const [rejectedList, setRejectedList] = useState<BookingRequestUiModel[]>([]);
  const [showRoomsModal, setShowRoomsModal] = useState<boolean>(false);
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [selectedBookingRequest, setSelectedBookingRequest] = useState<BookingRequestUiModel>();
  const [bookingRequestMsg, setBookingRequestMsg] = useState<string>('');
  const [messagesListAfterSubmit, setMessagesListAfterSubmit] = useState<MessagesList[]>([]);
  const [listCount, setListCount] = useState<number>(0);
  const [offset, setOffset] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [selectedTabs, setSelectedTabs] = useState<any>({
    status: BookingRequestStatus.IN_SCREENING
  });
  const [loadingData, setLoadingData] = useState<boolean>(false);

  const { t } = useTranslation();
  useEffect(() => {
    if (messagesListAfterSubmit) getAllBookingRequestsData(selectedTabs, 1, limit);
  }, [messagesListAfterSubmit]);

  useEffect(() => {
    trackPage('booking-requests-list');
    // getAllBookingRequestsData(selectedTabs, 1, limit);
    return () => {
      store.removeNotification('edit-booking-request-failed');
      store.removeNotification('edit-booking-request-success');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllBookingRequestsData(selectedTabs, offset, limit);
  }, [limit]);
  
  const getAllBookingRequestsData = (query: object, offset: number, limit: number) => {
    setLoadingData(true);

    getBookingRequests(query, offset, limit).then(
      (response: BookingRequestModelUiData | ErrorInfo) => {
        if (!isErrorInfo(response)) {
          setLoadingData(false);
          setListCount(response.count);
          const res = response.BookingRequestList.sort((a, b) => {
            if (a.bookedAt && b.bookedAt && a.bookedAt > b.bookedAt) {
              return 1;
            } else {
              return -1;
            }
          });
          res.forEach((item) => {
            item.submitMessage = '';
          });
          setBookingRequestsList(res);
        } else {
          setLoadingData(false);
          setBookingRequestsList([]);
        }
      }
    );
  };
  const acceptRequest = (requestId: string, roomId: string) => {
    acceptBookingRequest(requestId, roomId).then((requestItem) => {
      if (!isErrorInfo(requestItem)) {
        store.addNotification({
          title: 'Booking request accepted successfully',
          message: 'Confirmation email will be sent to guest shortly',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        const bookRequestTemp = [...bookingRequestsList];
        const foundRequestIndex = bookRequestTemp.findIndex(
          (request) => request.requestId === requestId
        );
        if (foundRequestIndex > -1) {
          bookRequestTemp[foundRequestIndex] = {
            ...requestItem,
            ...bookRequestTemp[foundRequestIndex]
          };
          setBookingRequestsList(bookRequestTemp);
        }
        const urgentTempList = urgentList.filter((request) => request.requestId !== requestId);
        const rejectList = urgentList.filter(
          (request) => request.status === BookingRequestStatus.REJECTED
        );
        setUrgentList(urgentTempList);
        setRejectedList(rejectList);
      } else {
        store.addNotification({
          id: 'edit-booking-request-failed',
          title: requestItem.status === 403 ? 'Permission denied' : 'Booking conflict',
          message:
            requestItem.status === 403
              ? "You don't have the sufficient permissions to edit this book"
              : 'Room is already reserved for another guest',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut']
        });
      }
    });
  };
  const rejectRequest = (requestId: string, rejectionReason: string) => {
    rejectBookingRequest(requestId, rejectionReason).then((requestItem) => {
      if (!isErrorInfo(requestItem)) {
        store.addNotification({
          title: 'Booking request rejected successfully',
          message: 'Confirmation email will be sent to guest shortly',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
      }
    });
    getAllBookingRequestsData({}, 1, limit);
  };
  const submitRequestMsg = (requestId: string) => {
    submitBookingMessage(bookingRequestMsg, requestId).then((response: any) => {
      setMessagesListAfterSubmit([]);
      setBookingRequestMsg('');
      if (!isErrorInfo(response)) {
        setMessagesListAfterSubmit(response.messagesList);

        store.addNotification({
          title: 'Booking request message submit successfully',
          message: 'Confirmation email will be sent to guest shortly',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        setBookingRequestMsg('');
      } else {
        store.addNotification({
          id: 'edit-booking-request-msg-failed',
          title: 'Booking conflict',
          message: 'submit message failed',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut']
        });
      }
    });
  };
  const checkRoomAvailability = (requestId: string) => {
    getBookingRequestDetails(requestId, getModuleName()).then((requestItem) => {
      if (!isErrorInfo(requestItem)) {
        if (requestItem && requestItem.availableRooms && requestItem.availableRooms.length > 0) {
          setSelectedBookingRequest(requestItem);
          setShowRoomsModal(true);
        } else {
          store.addNotification({
            id: 'edit-booking-request-failed',
            title: 'Booking conflict',
            message: 'No available room for the selected dates',
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        }
      } else {
        store.addNotification({
          id: 'edit-booking-request-failed',
          title: 'Booking conflict',
          message: 'No available room for the selected dates',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut']
        });
      }
    });
  };
  const getNewBooking = (offsetNumber: number) => {
    setOffset(offsetNumber);
    getAllBookingRequestsData(selectedTabs, offsetNumber, limit);
  };

  const renderRequestsList = () => (
    <div className={styles['requests-list-container__list-wrapper']}>
      {/* <FilterRequestList
        eventHandlers={{
          search: (filterModel: FilterRequestListrModel) => {
            if (
              isEmpty(filterModel) ||
              (!filterModel.PropertyName &&
                !filterModel.areaName &&
                !filterModel.name &&
                !filterModel.unitTypeName)
            ) {
              setOffset(1);
              getAllBookingRequestsData({}, 1, limit);
            } else {
              const filterFun = (req: BookingRequestUiModel) => {
                let isGuestNameMatch = true;
                let isUnitTypeMatch = true;
                let isPropertyMatch = true;
                let isAreaMatch = true;

                if (filterModel.name) {
                  isGuestNameMatch = req.guestInfo.name
                    .toLowerCase()
                    .includes(filterModel.name.toLowerCase());
                }
                if (filterModel.areaName) {
                  isAreaMatch = req.area === filterModel.areaName;
                }
                if (filterModel.PropertyName) {
                  isPropertyMatch = req.propertyName === filterModel.PropertyName;
                }
                if (filterModel.unitTypeName) {
                  isUnitTypeMatch = req.bookingInfo.unitTypeId === filterModel.unitTypeName;
                }
                console.log(filterModel);
                return isGuestNameMatch && isUnitTypeMatch && isPropertyMatch && isAreaMatch;
              };
              getAllBookingRequestsData({ ...selectedTabs, ...filterModel }, 1, limit);

              // setListCount(bookingRequestsList.filter(filterFun).length);
              // setBookingRequestsList(bookingRequestsList.filter(filterFun));
            }
          }
        }}
      /> */}
      {bookingRequestsList && bookingRequestsList.length > 0 ? (
        bookingRequestsList.map((bookingRequest, index) => (
          <div
            key={index}
            className={`${styles['requests-list-container__request-item']} ${
              styles['requests-list-container__request-item__' + bookingRequest.status]
            }`}
          >
            <Accordion key={index} TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id={bookingRequest.requestId}
              >
                <div className={styles['requests-list-container__request-wrapper']}>
                  {bookingRequest.status === BookingRequestStatus.IN_SCREENING &&
                    bookingRequest.bookedAt && (
                      <div className={styles['requests-list-container__request-wrapper__header']}>
                        {moment().diff(moment(Number(bookingRequest.bookedAt)), 'hours') >= 24 ? (
                          <div
                            className={`${styles['requests-list-container__notification']} ${styles['requests-list-container__notification--error']}`}
                          >
                            <InfoIcon fontSize={'large'} />
                            <div
                              className={styles['requests-list-container__notification__message']}
                            >
                              Response was expected{' '}
                              <b>
                                {moment(
                                  bookingRequest.bookedAt
                                    ? Number(bookingRequest.bookedAt)
                                    : new Date().getTime()
                                ).fromNow()}
                              </b>
                            </div>
                          </div>
                        ) : (
                          <div className={styles['requests-list-container__time-left']}>
                            <b>Time Left:</b>{' '}
                            <Countdown
                              date={
                                new Date(
                                  new Date(Number(bookingRequest.bookedAt)).getTime() +
                                    60 * 60 * 24 * 1000
                                )
                              }
                            >
                              <div
                                className={`${styles['requests-list-container__notification']} ${styles['requests-list-container__notification--error']}`}
                              >
                                <InfoIcon />
                                Response was expected{' '}
                                {moment(Number(bookingRequest.bookedAt)).add(24, 'hours').fromNow()}
                              </div>
                            </Countdown>
                          </div>
                        )}
                      </div>
                    )}
                  {bookingRequest.bookedAt && (
                    <div>
                      <b>Requested At:</b>{' '}
                      {moment(Number(bookingRequest.bookedAt)).format('DD-MM-YYYY HH:mm a')}
                    </div>
                  )}
                  {bookingRequest.screenedAt && (
                    <div>
                      <b>Requested At:</b>{' '}
                      {moment(Number(bookingRequest.screenedAt)).format('DD-MM-YYYY HH:mm a')}
                    </div>
                  )}
                  <div>
                    <b>Status:</b> {bookingRequest.status}
                  </div>
                  <div>
                    <b>name:</b> {bookingRequest.guestInfo.name}
                  </div>
                  {bookingRequest.bookingInfo.checkOutDate &&
                    bookingRequest.bookingInfo.checkInDate && (
                      <div>
                        <b>Duration:</b>{' '}
                        {moment(Number(bookingRequest.bookingInfo.checkOutDate)).diff(
                          moment(Number(bookingRequest.bookingInfo.checkInDate)),
                          'days'
                        ) + 1}{' '}
                        Days
                      </div>
                    )}
                  {/* <div>name: {bookingRequest.bookingInfo.}</div> */}
                  {bookingRequest.status === BookingRequestStatus.IN_SCREENING && (
                    <div className={styles['requests-list-container__actions']}>
                      <div
                        className={styles['requests-list-container__actions__choice']}
                        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                          event.stopPropagation();
                          checkRoomAvailability(bookingRequest.requestId);
                        }}
                      >
                        Accept
                      </div>
                      <div
                        className={styles['requests-list-container__actions__choice']}
                        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                          event.stopPropagation();
                          setSelectedBookingRequest(bookingRequest);
                          setShowRejectModal(true);
                        }}
                      >
                        Reject
                      </div>
                    </div>
                  )}
                  <div
                    key={index}
                    className={styles['requests-list-container__actions__message']}
                    onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                      event.stopPropagation();
                    }}
                  >
                    <TextArea
                      key={index}
                      data={{
                        value: bookingRequest.submitMessage,
                        placeholder: t(
                          'AMENITY_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.NAME_PLACEHOLDER'
                        )
                      }}
                      config={{
                        maxRows: 1
                        // autoFocus: false
                      }}
                      eventHandlers={{
                        onChangeHandler: (value: string) => {
                          setBookingRequestMsg(value);
                          bookingRequest.submitMessage = value;
                        }
                      }}
                    />
                    <Button
                      className={styles['requests-list-container__actions__message-submit']}
                      onClick={() => submitRequestMsg(bookingRequest.requestId || '')}
                    >
                      submit
                    </Button>
                    <div
                      className={styles['requests-list-container__actions__message_view-container']}
                    >
                      {bookingRequest.messagesList.map(
                        (msg) =>
                          msg.message && (
                            <div
                              className={styles['requests-list-container__actions__message_view']}
                            >
                              <div
                                className={
                                  styles['requests-list-container__actions__message_view__details']
                                }
                              >
                                created At: <span>{new Date(msg.createdAt).toDateString()}</span>by:{' '}
                                <label>{msg.createdByUsername}</label>
                              </div>
                              <p
                                className={
                                  styles['requests-list-container__actions__message_view__msg']
                                }
                              >
                                {' '}
                                {msg.message}
                              </p>
                            </div>
                          )
                      )}
                    </div>
                  </div>
                </div>
              </AccordionSummary>

              <AccordionDetails>
                <div className={styles['requests-list-container__request-item__details']}>
                  <BookingRequestDetails data={bookingRequest} />
                </div>
              </AccordionDetails>
            </Accordion>{' '}
          </div>
        ))
      ) : (
        <div className={styles['requests-list-container__list-wrapper__no-records']}>
          <img src={`${PUBLIC_PATH}/assets/jpeg/no-records.jpg`} alt="no-data" /> No booking
          requests to screen
        </div>
      )}
      {listCount > 0 && (
        <Pagination
          itemCounts={listCount}
          itemsPerPage={limit}
          offset={offset}
          handlePageChange={(val) => {
            setOffset(1);
            getNewBooking(val);
          }}
          handleItemsPerPage={(itemsPerPage) => {
            setLimit(itemsPerPage);
          }}
        />
      )}
    </div>
  );

  return (
    <div className={styles['requests-list-container']}>
      <div className={styles['requests-list-container__title']}>
        {t('BOOKING_MODULE.REQUESTS_LIST.TITLE')}
      </div>
      <Tabs
        data={{
          tabsList: [
            { component: <></>, key: 'Rejected' },
            { component: <></>, key: 'Require Action' },
            { component: <></>, key: 'All' }
          ],
          defaultTab: 'Require Action'
        }}
        style={{ position: TabsPosition.LEFT }}
        eventHandlers={{
          tabChangeHandler: (tabKey) => {
            setOffset(1);
            if (tabKey === 'All') {
              setSelectedTabs({});
              getAllBookingRequestsData({}, 1, limit);
            } else if (tabKey === 'Require Action') {
              setSelectedTabs({ status: BookingRequestStatus.IN_SCREENING });

              getAllBookingRequestsData({ status: BookingRequestStatus.IN_SCREENING }, 1, limit);
            } else if (tabKey === 'Rejected') {
              setSelectedTabs({ status: BookingRequestStatus.REJECTED });

              getAllBookingRequestsData({ status: BookingRequestStatus.REJECTED }, 1, limit);
            }
            console.log(tabKey);
          }
        }}
      />
      {loadingData ? (
        <div className={styles['reservation-list-container__loader']}>
          <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
        </div>
      ) : (
        <>{renderRequestsList()}</>
      )}
      <SelectAvailableRoom
        data={{ availableRooms: selectedBookingRequest?.availableRooms || [] }}
        config={{ showModal: showRoomsModal }}
        eventHandlers={{
          cancelHandler: () => {
            setShowRoomsModal(false);
            setSelectedBookingRequest(undefined);
          },
          submitRoomSelected: (roomId: string) => {
            setShowRoomsModal(false);
            if (selectedBookingRequest && selectedBookingRequest.requestId) {
              acceptRequest(selectedBookingRequest?.requestId, roomId);
            }
            setSelectedBookingRequest(undefined);
          }
        }}
      />

      <RejectRequest
        config={{ showModal: showRejectModal }}
        eventHandlers={{
          cancelHandler: () => {
            setShowRejectModal(false);
            setSelectedBookingRequest(undefined);
          },
          rejectReason: (reason: string) => {
            rejectRequest(selectedBookingRequest?.requestId || '', reason);
            setShowRejectModal(false);
            setSelectedBookingRequest(undefined);
          }
        }}
      />
    </div>
  );
};

export default connect(mapStateToProps)(RequestsListContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}
