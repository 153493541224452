import { BlockDatesModel } from '../models/block-dates.model';
import { BlockedDatesUiModel } from '../ui-models/blocked-dates.ui-model';

function mapToUI(blockedDatesData: BlockDatesModel): BlockedDatesUiModel {
  return {
    id: blockedDatesData.id,
    description: blockedDatesData.description,
    fromDate: blockedDatesData.fromDate,
    toDate: blockedDatesData.toDate,
    unitTypeId: blockedDatesData.unitTypeId,
    roomId: blockedDatesData.roomId,
    roomName: blockedDatesData.roomName,
    isCancelled: blockedDatesData.isCancelled,
    unitName: blockedDatesData.unitName,
    area: blockedDatesData.area,
    propertyName: blockedDatesData.propertyName,
    modifiedByUsername: blockedDatesData.modifiedByUsername,
    createdByUsername: blockedDatesData.createdByUsername,
    createdAt: blockedDatesData.createdAt,
    updatedAt: blockedDatesData.updatedAt
  };
}
function mapToUIList(reservationList: BlockDatesModel[]): BlockedDatesUiModel[] {
  return reservationList.map(mapToUI);
}

export default {
  mapToUI,
  mapToUIList
};
