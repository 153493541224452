import { ApiResponse } from 'apisauce';
import { ErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { ResponseModel } from '../../../shared/interfaces/response-model.interface';
import AreaMapper from '../../../shared/mappers/area.mapper';
import { AreaModel } from '../../../shared/models/area.model';
import api from '../../../shared/services/utils/api/api.service';
import errorHelper from '../../../shared/services/utils/api/error-helper.service';
import { AreaUiModel } from '../../../shared/ui-models/area.ui-model';
import { getModuleName } from './user.service';
import { BookingRequestUiModel } from '../../../shared/ui-models/booking-request.ui-model';
import { ReservationUiModel } from '../../../shared/ui-models/reservation.ui-model';
import BookingRequestsMapper from '../../../shared/mappers/booking-requests.mapper';
import ReservationMapper from '../../../shared/mappers/reservation.mapper';

export function getUserBookingRequests(
  userId: string
): Promise<BookingRequestUiModel[] | ErrorInfo> {
  return api
    .getUserBookings(userId)
    .then((response: ApiResponse<ResponseModel<BookingRequestModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return BookingRequestsMapper.mapToUIList(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.getBookingRequests as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.getBookingRequests as any).displayName
        );
      }
    });
}

export function getUserReservations(userId: string): Promise<ReservationUiModel[] | ErrorInfo> {
  return api
    .getUserReservations(userId)
    .then((response: ApiResponse<ResponseModel<ReservationModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return ReservationMapper.mapToUIList(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.getReservations as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.getReservations as any).displayName
        );
      }
    });
  }

    export function getUserLockStatus(userId: string): Promise<{isEmailLocked: boolean} | ErrorInfo> {
      return api
        .getUserLockStatus(userId)
        .then((response: ApiResponse<ResponseModel<{data:{isEmailLocked: boolean}} >, ErrorInfo>) => {
          if (response.ok) {
            const { data } = response;
            if (data && data.data) {
              return data.data.data;
            } else {
              return errorHelper(0, '', getModuleName(), (api.getUserLockStatus as any).displayName);
            }
          } else {
            return errorHelper(
              Number(response.status),
              response.data && response.data.errorCode
                ? String(response.data.errorCode || '')
                : response.problem,
              getModuleName(),
              (api.getUserLockStatus as any).displayName
            );
          }
        });
      }

      export function unlockUser(userId: string): Promise<any | ErrorInfo> {
        return api
          .unlockUser(userId)
          .then((response: ApiResponse<ResponseModel<any>, ErrorInfo>) => {
            if (response.ok) {
              const { data } = response;
              if (data && data.data) {
                return data.data;
              } else {
                return errorHelper(0, '', getModuleName(), (api.unlockUser as any).displayName);
              }
            } else {
              return errorHelper(
                Number(response.status),
                response.data && response.data.errorCode
                  ? String(response.data.errorCode || '')
                  : response.problem,
                getModuleName(),
                (api.unlockUser as any).displayName
              );
            }
          });
        }
