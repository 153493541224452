import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './edit-unit-type-list-modal.module.scss';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize
} from '../../../../../shared/components/core-ui/button/button.component';
import {
  InputText,
  InputTextErrorMode,
  InputTextType
} from '../../../../../shared/components/core-ui/input-text/input-text.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { UnitTypeUiModel } from '../../../../../shared/ui-models/unit-type.ui-model';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { bulkUpdateUnitTypeData } from '../../../../shared/services/data.service';
import { BulkUpdate } from '../../../../../shared/models/unit-types.model';
import { ErrorInfo, isErrorInfo } from '../../../../../shared/interfaces/error-info.interface';
import { APP_ROUTES, PUBLIC_PATH } from '../../../../../shared/config/routes-config';
import { RouteConfig } from '../../../../../shared/interfaces/routes-config.interface';
import EditPlanInfo from '../../../edit-unit-type/components/edit-plans-info/edit-plan-info.container';
import { ReservationMode } from '../../../../../shared/enums/reservation-mode.enum';
import { UnitTypeRate } from '../../../../../shared/models/unit-type-rate.model';
import { UnitTypeFeeUiModel } from '../../../../../shared/ui-models/unit-type-fee.model';
import SelectAmenities from '../../../edit-unit-type/components/select-amenities/select-amenities.container';
import { AmenityUiModel } from '../../../../../shared/ui-models/amenity.ui-model';
import { getAllAmenities } from '../../../../../shared/services/data/lookups.data';
import { getModuleName } from '../../../../shared/services/unit-type.service';
import Tooltip from '@material-ui/core/Tooltip';

export interface EditUnitTypeListModalProps {
  config: {
    showModal: boolean;
  };
  eventHandlers: {
    closeHandler: () => void;
    addUnitTypeHandler: (unitTypeData: Pick<UnitTypeUiModel, 'name'>) => void;
  };
  data: {
    units: UnitTypeUiModel[];
  };
}

/**
 * functional component AddUnitTypeModal
 * @param {EditUnitTypeListModalProps}
 */
export const EditUnitTypeListModal: React.FC<EditUnitTypeListModalProps> = ({
  config,
  eventHandlers,
  data
}) => {
  const [minimumStay, setMinimumStay] = useState<FormElementData<number>>({
    value: 0,
    isValid: false
  });
  const [maximumStay, setMaximumStay] = useState<FormElementData<number>>({
    value: 0,
    isValid: false
  });
  const [unitPrice, setUnitPrice] = useState<FormElementData<number>>({
    value: 0,
    isValid: false
  });
  const [securityDeposit, setSecurityDeposit] = useState<FormElementData<number>>({
    value: 0,
    isValid: false
  });
  const [newHouseRules, setNewHouseRules] = useState<FormElementData<string | undefined>>({
    value: undefined,
    isValid: false,
    changed: false
  });
  const [showCopyView, setShowCopyView] = useState<boolean>();
  const [unitTypeRate, setUnitTypeRate] = useState<UnitTypeRate[]>([]);
  const [unitTypeFees, setUnitTypeFees] = useState<UnitTypeFeeUiModel[]>([]);
  const [amenitiesList, setAmenitiesList] = useState<AmenityUiModel[]>();
  const [selectedAmenitiesList, setSelectedAmenitiesList] = useState<AmenityUiModel[]>([]);
  const [loadingAmenities, setLoadingAmenities] = useState<boolean>(false);
  const [bulkUpdateResponse, setBulkUpdateResponse] = useState<BulkUpdate>();
  

  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  const handleBulkUpdateTypeSubmit = () => {
    const mappedUnitTypeToUpdate = data.units.map((unit) => {
      if (maximumStay.value) {
        unit.maxStayDuration = maximumStay.value;
      }
      if (minimumStay.value) {
        unit.minStayDuration = minimumStay.value;
      }
      if (unitPrice.value) {
        unit.unitPrice = unitPrice.value;
      }
      if (securityDeposit.value) {
        unit.insurance = securityDeposit.value;
      }
      if (newHouseRules.value) {
        unit.houseRules = newHouseRules.value;
      }
      if (unitTypeFees && unitTypeFees.length) {
        unit.fees = unitTypeFees;
      }
      if (unitTypeRate && unitTypeRate.length) {
        unit.ratePlans = unitTypeRate;
      }
      if (selectedAmenitiesList && selectedAmenitiesList.length) {
        unit.amenities = selectedAmenitiesList;
      }
      return unit;
    });
    bulkUpdateUnitTypeData(mappedUnitTypeToUpdate).then((resp) => {
      if (!isErrorInfo(resp)) {
        setBulkUpdateResponse(resp);
        setShowCopyView(true);
      }
    });
  };
  const fetchAmenitiesData = () => {
    setLoadingAmenities(true);
    getAllAmenities(getModuleName()).then((response: AmenityUiModel[] | ErrorInfo) => {
      setLoadingAmenities(false);
      if (!isErrorInfo(response)) {
        setAmenitiesList(response);
      }
    });
  };

  useEffect(()=>{
    fetchAmenitiesData();
  },[]);

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.closeHandler();
        }
      }}
    >
      {!showCopyView ? (
        <>
          <div className={styles['edit-unit-type-list-modal']}>
            <div className={styles['edit-unit-type-list-modal__title']}>Update units</div>
            <div className={styles['edit-unit-type-list-modal__description']}>
              Minimum stay
              <InputText
                data={{
                  defaultValue: '',
                  errorMessage: 'Please enter Minimum stay',
                  placeholder: 'enter Minimum stay'
                }}
                config={{
                  type: InputTextType.number,
                  maxRows: 1,
                  autoFocus: true,
                  showErrorMode: InputTextErrorMode.instant
                }}
                eventHandlers={{
                  onChangeHandler: (value: number, isValid: boolean) => {
                    setMinimumStay({ value, isValid });
                  },
                  onEnterPressedHandler: handleBulkUpdateTypeSubmit
                }}
              />
            </div>
            <div className={styles['edit-unit-type-list-modal__description']}>
              Maximum stay
              <InputText
                data={{
                  defaultValue: '',
                  errorMessage: 'Please enter Maximum stay',
                  placeholder: 'enter Maximum stay'
                }}
                config={{
                  type: InputTextType.number,
                  maxRows: 1,
                  autoFocus: true,
                  showErrorMode: InputTextErrorMode.instant
                }}
                eventHandlers={{
                  onChangeHandler: (value: number, isValid: boolean) => {
                    setMaximumStay({ value, isValid });
                  },
                  onEnterPressedHandler: handleBulkUpdateTypeSubmit
                }}
              />
            </div>
            <div className={styles['edit-unit-type-list-modal__description']}>
              Unit Price
              <InputText
                data={{
                  defaultValue: '',
                  errorMessage: 'Please enter Unit Price',
                  placeholder: 'enter Unit Price stay'
                }}
                config={{
                  type: InputTextType.number,
                  maxRows: 1,
                  autoFocus: true,
                  showErrorMode: InputTextErrorMode.instant
                }}
                eventHandlers={{
                  onChangeHandler: (value: number, isValid: boolean) => {
                    setUnitPrice({ value, isValid });
                  },
                  onEnterPressedHandler: handleBulkUpdateTypeSubmit
                }}
              />
            </div>
            <div className={styles['edit-unit-type-list-modal__description']}>
              Security Deposit
              <InputText
                data={{
                  defaultValue: '',
                  errorMessage: 'Please enter  Security Deposit',
                  placeholder: 'enter Security Deposit'
                }}
                config={{
                  type: InputTextType.number,
                  maxRows: 1,
                  autoFocus: true,
                  showErrorMode: InputTextErrorMode.instant
                }}
                eventHandlers={{
                  onChangeHandler: (value: number, isValid: boolean) => {
                    setSecurityDeposit({ value, isValid });
                  },
                  onEnterPressedHandler: handleBulkUpdateTypeSubmit
                }}
              />
            </div>
            <div className={styles['edit-unit-type-list-modal__description']}>
              {t('UNIT_TYPE_MODULE.EDIT_GENERAL_CONTAINER.HOUSE_RULES')}
              <div className={styles['general-info-container__field']}>
                <CKEditor
                  editor={ClassicEditor}
                  data={newHouseRules.value}
                  onReady={(editor: any) => {
                    // You can store the "editor" and use when it is needed.
                    // console.log('Editor is ready to use!', editor);
                  }}
                  onChange={(event: any, editor: any) => {
                    const newData: string = editor.getData();
                    setNewHouseRules({ value: newData, isValid: true, changed: true });
                  }}
                />
              </div>
            </div>
          </div>
          <EditPlanInfo
                data={{
                  ratePlans: unitTypeRate,
                  fees: unitTypeFees,
                  reservationMode: data.units[0]?.reservationMode || ReservationMode.MONTHLY
                }}
                eventHandlers={{
                  submitHandler: (updatedData) => {
                    setUnitTypeFees(updatedData.fees || []);
                    setUnitTypeRate(updatedData.ratePlans || []); 
                  }
                }}
              />
          <SelectAmenities
                data={{
                  amenitiesList: amenitiesList || [],
                  selectedAmenities: selectedAmenitiesList
                }}
                config={{ isLoading: loadingAmenities }}
                eventHandlers={{
                  selectAmenity: (amenity) => {
                    setSelectedAmenitiesList([...selectedAmenitiesList, amenity]);
                  },
                  unselectAmenity: (amenityId) => {
                      const filteredAmenitiesList = selectedAmenitiesList.filter(
                        (item) => item.id !== amenityId);
                      setSelectedAmenitiesList(filteredAmenitiesList);

                    }
                }}
              />
          <div className={styles['edit-unit-type-list-modal--actions']}>
            <Button
              onClick={() => {
                eventHandlers.closeHandler();
              }}
              tracking={{
                action: 'close',
                category: 'unit-types',
                label: 'edit-unit-type-list-modal'
              }}
              size={isMobileView ? ButtonSize.full : ButtonSize.medium}
            >
              {t('UNIT_TYPE_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.CANCEL_BUTTON')}
            </Button>
            <Button
              disabled={
                !(
                  maximumStay.value ||
                  minimumStay.value ||
                  unitPrice.value ||
                  securityDeposit.value ||
                  newHouseRules.value||
                  unitTypeRate?.length||
                  unitTypeFees?.length ||
                  selectedAmenitiesList?.length
                )
              }
              onClick={handleBulkUpdateTypeSubmit}
              tracking={{
                action: 'submit',
                category: 'unit-type',
                label: 'edit-unit-type-list-modal'
              }}
              size={isMobileView ? ButtonSize.full : ButtonSize.medium}
            >
              {t('UNIT_TYPE_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.SUBMIT_BUTTON')}
            </Button>
          </div>
        </>
      ) : (
        <div className={styles['edit-unit-type-list-modal']}>
          <div className={styles['edit-unit-type-list-modal__title']}>Update units</div>
          <div className={styles['edit-unit-type-list-modal__copy-view']}>
            <div>
              Use this id:{' '}
              <span className={styles['edit-unit-type-list-modal__title']}>
                {' '}
                {bulkUpdateResponse?.id}{' '}
              </span>{' '}
              to track th update status
            </div>
          </div>
          <div className={styles['edit-unit-type-list-modal--actions']}>
            <Button
              className={styles['unit-types-bulk-update-container__search__button-container__btn']}
              onClick={() => {
                window.open(
                  PUBLIC_PATH +
                    (APP_ROUTES.UNIT_TYPE_MODULE.CHILDREN as RouteConfig).BULK_EDIT_LIST.FULL_PATH,
                  '_blank'
                );
              }}
              size={isMobileView ? ButtonSize.full : ButtonSize.medium}
            >
              History
            </Button>
            <Button
              onClick={() => {
                eventHandlers.closeHandler();
              }}
              tracking={{
                action: 'close',
                category: 'unit-types',
                label: 'edit-unit-type-list-modal'
              }}
              size={isMobileView ? ButtonSize.full : ButtonSize.medium}
            >
              {t('UNIT_TYPE_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.CANCEL_BUTTON')}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
