import React, { useEffect, useState } from 'react';
import styles from './custom-price.module.scss';
import { PUBLIC_PATH } from '../../../../../shared/config/routes-config';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import { trackEvent } from '../../../../../shared/services/utils/tracking/tracking.service';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import EdiText from 'react-editext';
import { CustomPriceModel } from '../../../../../shared/ui-models/unit-type.ui-model';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { AddCustomPriceModal } from '../add-custom-price-modal/add-custom-price-modal.component';
import DeleteIcon from '@material-ui/icons/Delete';

export interface CustomPriceProps {
  data: {
    customPrice: CustomPriceModel[];
    basePrice: number;
  };
  eventHandlers: {
    updateCustomPrice: (customPrices: CustomPriceModel[]) => void;
  };
}
export const CustomPrice: React.FC<CustomPriceProps> = ({ data, eventHandlers }) => {
  const isMobileView = useWindowResizer();
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [customPrices, setCustomPrices] = useState<CustomPriceModel[]>([]);

  useEffect(() => {
    setCustomPrices(data?.customPrice);
  }, [data?.customPrice]);

  const DeleteCustomPrice = (index: any) => {
    const newCustomPrice = customPrices.filter((item, itemIndex) => {
      return itemIndex !== index;
    });
    setCustomPrices(newCustomPrice);
    eventHandlers.updateCustomPrice(newCustomPrice);
  };
  function CustomRow(data: { customPriceItems: CustomPriceModel; index: any }) {
    const { customPriceItems, index } = data;

    return (
      <>
        <TableRow>
          <TableCell>{new Date(Number(customPriceItems.from)).toDateString()}</TableCell>

          <TableCell>{new Date(Number(customPriceItems.to)).toDateString()}</TableCell>
          <TableCell>{customPriceItems.price}</TableCell>
          <TableCell>
            <DeleteIcon onClick={() => DeleteCustomPrice(index)} />
          </TableCell>
        </TableRow>
      </>
    );
  }
  return (
    <div className={styles['custom-price-container']}>
      {customPrices?.length > 0 ? (
        <>
          <div className={styles['custom-price-container-title']}>
            Dynamic Price
            <Button
              onClick={() => {
                setShowAddModal(true);
                trackEvent('add', {
                  category: '',
                  label: ''
                });
              }}
              tracking={{
                action: 'add Custom Price',
                category: '',
                label: ''
              }}
              size={isMobileView ? ButtonSize.full : ButtonSize.medium}
            >
              +
            </Button>
          </div>
          <div className={styles['custom-price-container--table']}>
            <TableContainer component={Paper}>
              <Table aria-label="table">
                <TableHead>
                  <TableRow>
                    <TableCell>From</TableCell>
                    <TableCell>To</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customPrices.map((item, index) => (
                    <CustomRow index={index} customPriceItems={item} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      ) : (
        <div>
          <div className={styles['custom-price-container-title']}>Dynamic Price</div>
          <img
            src={`${PUBLIC_PATH}/assets/pngs/add-custom-price.png`}
            alt="add custom price"
            className={styles['custom-price-container__content-image']}
          />

          <Button
            onClick={() => {
              setShowAddModal(true);
              trackEvent('add', {
                category: '',
                label: ''
              });
            }}
            tracking={{
              action: 'add Custom Price',
              category: '',
              label: ''
            }}
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          >
            {' '}
            Add Dynamic Price
          </Button>
        </div>
      )}
      <AddCustomPriceModal
        data={{ customPrice: customPrices, basePrice: data.basePrice }}
        config={{
          showModal: showAddModal
        }}
        eventHandlers={{
          denyAction: () => {
            setShowAddModal(false);
          },
          confirmAction: () => {
            setShowAddModal(false);
            // eventHandlers.deleteLandLord();
          },
          addCustomPrice: (date, price) => {
            setCustomPrices([
              ...customPrices,
              { from: date[0] as Date, to: date[1] as Date, price }
            ]);
            eventHandlers.updateCustomPrice([
              ...customPrices,
              { from: date[0] as Date, to: date[1] as Date, price }
            ]);
            console.log(date, price);
          }
        }}
      />
    </div>
  );
};
