import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { APP_ROUTES } from '../shared/config/routes-config';
import { RouteConfig } from '../shared/interfaces/routes-config.interface';
import { FinancialReportContainer } from './containers/financial-report/financial-report.container';
/**
 * function UnitTypeModule components
 */
export const ReportsModule: React.FC = () => {
  return (
    <>
      <Route exact path={APP_ROUTES.REPORTS_MODULE.FULL_PATH}>
        <Redirect to={(APP_ROUTES.REPORTS_MODULE.CHILDREN as RouteConfig).FINANCIAL.FULL_PATH} />
      </Route>
      <Route
        path={(APP_ROUTES.REPORTS_MODULE.CHILDREN as RouteConfig).FINANCIAL.FULL_PATH}
        component={FinancialReportContainer}
      />
    </>
  );
};
