import React, { useEffect, useState } from 'react';
import styles from './feedback-details.container.module.scss';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { PUBLIC_PATH } from '../../../shared/config/routes-config';
import { useParams } from 'react-router';
import { getOneReservationsFeebacks } from '../../shared/services/data.service';
import { isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { FeedbackResponse } from '../../../shared/models/feedbacks.model';

/**
 * function feedback-details Container components
 */

export const FeedbackDetails: React.FC = () => {
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [data, setData] = useState<FeedbackResponse>({} as FeedbackResponse);
  const params: any = useParams();

  useEffect(() => {
    getOneFeedbacks(params.id);
  }, []);

  function getOneFeedbacks(reservationId: string) {
    setLoadingData(true);
    getOneReservationsFeebacks(reservationId)
      .then((response) => {
        if (!isErrorInfo(response)) {
          setData(response);
        }
      })
      .finally(() => setLoadingData(false));
  }

  return (
    <div className={styles['feedback-details']}>
      <div className={styles['feedback-details__title']}>Feedback Details</div>
      {loadingData ? (
        <div className={styles['feedback-details__loader']}>
          <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
        </div>
      ) : (
        <>
          {data?.feedback?.map((item) => (
            <>
              <Accordion defaultExpanded>
                <AccordionSummary>
                  <Typography className={styles['feedback-details__question']}>
                    {item.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  {item.answer}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </>
          ))}
          {((!data.feedback) || data?.feedback?.length=== 0) && (
            <div className={styles['feedback-details__noData']}>
              <img src={`${PUBLIC_PATH}/assets/jpeg/no-records.jpg`} alt="no-data" /> No Data
              Available
            </div>
          )}
        </>
      )}
    </div>
  );
};
