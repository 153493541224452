import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './add-user-modal.module.scss';
import PhoneInput from 'react-phone-input-2';
import { VALIDATION_REGEX } from '../../../../../shared/components/validation.config';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import { trackEvent } from '../../../../../shared/services/utils/tracking/tracking.service';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import {
  InputText,
  InputTextErrorMode,
  InputTextType
} from '../../../../../shared/components/core-ui/input-text/input-text.component';
import { UserUIModel } from '../../../../../shared/ui-models/user.ui-model';

export interface AddUserModalProps {
  config: {
    showModal: boolean;
  };
  eventHandlers: {
    cancelHandler: () => void;
    addUserHandler: (userModel: UserUIModel) => void;
  };
}

/**
 * functional component AddUserModal
 * @param {AddUserModalProps}
 */
export const AddUserModal: React.FC<AddUserModalProps> = ({ config, eventHandlers }) => {
  const [userName, setUserName] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });

  const [userEmail, setUserEmail] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [userPhone, setUserPhone] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [userSocialLink, setUserSocialLink] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [gender, setGender] = useState<string>('male');
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  const handleNewUserSubmit = () => {
    const newUser: {
      name: string;
      phoneNumber: string;
      email: string;
      gender: string;
      socialLink: string;
    } = {
      name: userName.value,
      email: userEmail.value,
      phoneNumber: userPhone.value,
      socialLink: userSocialLink.value,
      gender
    };
    console.log(newUser);
    eventHandlers.addUserHandler(newUser);
  };

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.cancelHandler();
        }
      }}
    >
      <div className={styles['add-user-modal']}>
        <div className={styles['add-user-modal__title']}>
          {t('USER_MODULE.SEARCH_CONTAINER.ADD_NEW_MODAL.TITLE')}
        </div>
        <div className={styles['add-user-modal__description']}>
          {t('USER_MODULE.SEARCH_CONTAINER.ADD_NEW_MODAL.DESCRIPTION')}
        </div>
        <div className={styles['add-user-modal__description']}>
          Full Name
          <InputText
            data={{
              value: userName.value,
              errorMessage: 'Full name is required',
              placeholder: 'Full Name'
            }}
            config={{
              maxRows: 1,
              autoFocus: true,
              required: true,
              showErrorMode: InputTextErrorMode.instant
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setUserName({ value, isValid });
              }
            }}
          />
        </div>

        <div className={styles['add-user-modal__description']}>
          Email
          <InputText
            data={{
              value: userEmail.value,
              errorMessage: 'Email is required',
              placeholder: 'Email'
            }}
            config={{
              maxRows: 1,
              required: true,
              showErrorMode: InputTextErrorMode.instant
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setUserEmail({ value, isValid });
              }
            }}
          />
        </div>
        <div className={styles['add-user-modal__description']}>
          Phone Number
          <PhoneInput
            country={'eg'}
            inputProps={{
              name: 'phone number',
              required: true
            }}
            value={userPhone.value}
            onChange={(phone) => {
              phone
                ? setUserPhone({ value: `+${phone}`, isValid: true })
                : setUserPhone({ value: `${phone}`, isValid: false });
            }}
          />
        </div>
        <div className={styles['add-user-modal__gender-container']}>
          <div className={styles['add-user-modal__gender-container__label']}>Gender</div>
          <div className={styles['add-user-modal__gender-container__choices']}>
            <div className={styles['add-user-modal__gender-container__choices__item']}>
              <span
                className={`
                      ${styles['add-user-modal__gender-container__choices__item__label']}
                      ${
                        gender === 'male'
                          ? styles['add-user-modal__gender-container__choices__item__label--active']
                          : ''
                      }
                      `}
                onClick={() => setGender('male')}
              >
                Male
              </span>
            </div>
            <div className={styles['add-user-modal__gender-container__choices__item']}>
              <span
                className={`
                      ${styles['add-user-modal__gender-container__choices__item__label']}
                      ${
                        gender === 'female'
                          ? styles['add-user-modal__gender-container__choices__item__label--active']
                          : ''
                      }
                      `}
                onClick={() => setGender('female')}
              >
                Female
              </span>
            </div>
          </div>
        </div>
        <div className={styles['add-user-modal--actions']}>
          <Button
            onClick={() => {
              eventHandlers.cancelHandler();
            }}
            tracking={{
              action: 'close',
              category: 'guests',
              label: 'add-guest'
            }}
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          >
            Cancel
          </Button>
          <Button
            disabled={!userEmail.isValid || !userPhone.isValid || !userName.isValid}
            onClick={handleNewUserSubmit}
            tracking={{
              action: 'submit',
              category: 'guest',
              label: 'add-guest'
            }}
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          >
            Create Add
          </Button>
        </div>
      </div>
    </Modal>
  );
};
