import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './edit-visuals.module.scss';
import { store } from 'react-notifications-component';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
// import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import ImageUploader from '../../../../../shared/components/core-ui/image-uploader/image-uploader.component';
import { PropertyUiModel } from '../../../../../shared/ui-models/property.ui-model';
import { trackEvent } from '../../../../../shared/services/utils/tracking/tracking.service';
import { ImageType } from 'react-images-uploading';

export interface EditVisualsProps {
  data: Pick<PropertyUiModel, 'coverImage'>;
  eventHandlers: {
    submitCoverImageHandler: (size: 'sm' | 'md' | 'lg', coverImage: ImageType) => void;
  };
}
/**
 * functional component EditVisuals
 * @param {EditVisualsProps} holding question text
 */
export const EditVisuals: React.FC<EditVisualsProps> = ({ data, eventHandlers }) => {
  const [coverImageSm, setCoverImageSm] = useState<ImageType | null>();
  const [coverImageMd, setCoverImageMd] = useState<ImageType | null>();
  const [coverImageLg, setCoverImageLg] = useState<ImageType | null>();
  const { t } = useTranslation();
  // const isMobileView = useWindowResizer();

  useEffect(() => {
    return () => {
      store.removeNotification('visuals-info-failed');
      store.removeNotification('visuals-info-success');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.coverImage && data.coverImage.sm) {
      setCoverImageSm({ dataURL: data.coverImage.sm });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.coverImage?.sm]);

  useEffect(() => {
    if (data.coverImage && data.coverImage.md) {
      setCoverImageMd({ dataURL: data.coverImage.md });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.coverImage?.md]);

  useEffect(() => {
    if (data.coverImage && data.coverImage.lg) {
      setCoverImageLg({ dataURL: data.coverImage.lg });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.coverImage?.lg]);

  return (
    <div className={styles['visuals-container']}>
      <div className={styles['visuals-container__sub-title']}>
        {t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.VISUAL_ASSETS')}
      </div>
      <div className={styles['visuals-container']}>
        <div className={styles['visuals-container__section-title']}>
          {t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.COVER_IMAGE')}
        </div>
        <div>
          {/* <div className={styles['visuals-container__field-group']}>
            <div className={styles['visuals-container__field-group__icon']}>
              <SmartphoneIcon fontSize={'inherit'} color={'inherit'} />
            </div>
            <ImageUploader
              config={{
                enableCrop: true,
                canvasSize: 'sm',
                cropAspectRatio: { width: 75, height: 41 }
              }}
              data={{
                image: coverImageSm || null
              }}
              eventHandlers={{
                fileUploadHandler: (file, width, height) => {
                  trackEvent('cover-image-sm-uploaded', {
                    category: 'property',
                    label: 'property-visuals'
                  });
                  eventHandlers.submitCoverImageHandler('sm', file);
                },
                imageRemovedHandler: () => {
                  setCoverImageSm(null);
                }
              }}
            />
          </div>
          <div className={styles['visuals-container__field-group']}>
            <div className={styles['visuals-container__field-group__icon']}>
              <TabletMacIcon fontSize={'inherit'} color={'inherit'} />
            </div>
            <ImageUploader
              config={{
                enableCrop: true,
                canvasSize: 'md',
                cropAspectRatio: { width: 29, height: 32 }
              }}
              data={{ image: coverImageMd || null }}
              eventHandlers={{
                fileUploadHandler: (file, width, height) => {
                  trackEvent('cover-image-md-uploaded', {
                    category: 'property',
                    label: 'property-visuals'
                  });
                  eventHandlers.submitCoverImageHandler('md', file);
                },
                imageRemovedHandler: () => {
                  setCoverImageMd(null);
                }
              }}
            />
          </div> */}
          <div className={styles['visuals-container__field-group']}>
            <div className={styles['visuals-container__field-group__icon']}>
              <DesktopMacIcon fontSize={'inherit'} color={'inherit'} />
            </div>
            <ImageUploader
              config={{
                enableCrop: false,
                canvasSize: 'lg',
                multiple: false
                // cropAspectRatio: { width: 14, height: 15 }
              }}
              data={{ image: coverImageLg || null }}
              eventHandlers={{
                fileUploadHandler: (file, width, height) => {
                  trackEvent('cover-image-lg-uploaded', {
                    category: 'property',
                    label: 'property-visuals'
                  });
                  if (file && file[0]) {
                    eventHandlers.submitCoverImageHandler('lg', file[0]);
                  }
                },
                imageRemovedHandler: () => {
                  setCoverImageLg(null);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditVisuals;
