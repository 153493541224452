import React, { useState } from 'react';
import styles from './managers-list.module.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { PUBLIC_PATH } from '../../../../../shared/config/routes-config';
import { PropertyUiManagersListModel } from '../../../../../shared/ui-models/propertyManager.ui.model';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircle';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
    }
  }
});
export interface ManagersListProps {
  data: {
    propertyManagersList: PropertyUiManagersListModel[];
  };
  eventHandlers: {
    deletePropertyManager: (id: string) => void;
    addPropertyManager: (show: boolean) => void;
  };
}
/**
 * functional component DoubleBookings
 *
 * @param {ManagersListProps}
 */
export const ManagersList: React.FC<ManagersListProps> = ({ data, eventHandlers }) => {
  const classes = useRowStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const renderDoubleBookings = (data: { propertyManagersList: PropertyUiManagersListModel[] }) => {
    return (
      <div className={styles['double-booking--table']}>
        <TableContainer component={Paper}>
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell>name</TableCell>
                <TableCell>email</TableCell>
                <TableCell>actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.propertyManagersList?.length > 0 &&
                data?.propertyManagersList?.map(
                  (user: PropertyUiManagersListModel, index: number) => (
                    <ReservationRow key={index} userData={user} />
                  )
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  function ReservationRow(props: { userData: PropertyUiManagersListModel; key: number }) {
    return (
      <>
        <TableRow>
          <TableCell>{props?.userData?.name}</TableCell>
          <TableCell>{props?.userData.email}</TableCell>
          <TableCell onClick={() => eventHandlers.deletePropertyManager(props?.userData?.id)}>
            <DeleteIcon>X</DeleteIcon>
          </TableCell>
        </TableRow>
      </>
    );
  }

  return (
    <div className={styles['double-booking']}>
      <div
        className={styles['double-booking--title']}
        onClick={() => {
          eventHandlers.addPropertyManager(true);
        }}
      >
        <AddIcon className={styles['pending-actions--title_icon']} /> Assign property Manager
      </div>
      {isLoading ? (
        <div className={styles['double-booking__loader']}>
          <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
        </div>
      ) : (
        <>
          {data?.propertyManagersList.length ? (
            renderDoubleBookings(data)
          ) : (
            <div className={styles['double-booking__no-records']}>
              <img src={`${PUBLIC_PATH}/assets/pngs/passed.png`} alt="no-data" /> Everything looks
              Great!
            </div>
          )}
        </>
      )}
    </div>
  );
};
