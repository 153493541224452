import React from 'react';
import styles from './landlord-info.module.scss';

export interface LandlordInfoProps {
  data: {
    landlordName: string;
    landlordEmail: string;
    landlordPhone: string;
    landlordShare: number;
  };
}
export const LandLordInfo: React.FC<LandlordInfoProps> = ({ data }) => {
  return (
    <div className={styles['landlord-info-container']}>
      <div className={styles['landlord-info-container-title']}>Landlord Info</div>
      <div className={styles['landlord-info-container-content']}>
        <div className={styles['landlord-info-container-content-info']}>
          <label>Name :</label> <p>{data.landlordName}</p>
        </div>
        <div className={styles['landlord-info-container-content-info']}>
          <label>Email :</label> <p>{data.landlordEmail}</p>
        </div>
        <div className={styles['landlord-info-container-content-info']}>
          <label>Phone :</label> <p>{data.landlordPhone}</p>
        </div>
        <div className={styles['landlord-info-container-content-info']}>
          <label>Homeowner Share :</label>
          <p>{data.landlordShare + '%'}</p>
        </div>
      </div>
    </div>
  );
};
