import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './edit-visuals.module.scss';
import { store } from 'react-notifications-component';
import { ImageType } from 'react-images-uploading';
import { UnitTypeUiModel } from '../../../../../shared/ui-models/unit-type.ui-model';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import { Slider } from '../../../../shared/components/widgets/slider/slider.component';
import { AddShowroomImageModal } from '../add-showroom-image-modal/add-showroom-image-modal.component';
import { AddImageModal } from '../add-image-modal/add-image-modal.component';
import { SortableSlider } from '../../../../shared/components/widgets/sortable-slider/sortable-slider.component';
import SortableGalleryComp from '../../../../shared/components/widgets/sortable-Gallery/sortable-gallery.component';

export interface EditVisualsProps {
  data: Pick<UnitTypeUiModel, 'showRooms' | 'roomTypePhotos' | 'id'>;
  eventHandlers: {
    submitShowroomImageHandler: (roomName: string, image: ImageType) => void;
    submitImageHandler: (image: ImageType) => void;
    deleteImageHandler: (imageId: string) => void;
    deleteShowroomImageHandler: (imageId: string, roomId: string) => void;
    updateImageOrder: (images: { id: string; url: string; order: number }[]) => void;
  };
}
/**
 * functional component EditVisuals
 * @param {EditVisualsProps} holding question text
 */
export const EditVisuals: React.FC<EditVisualsProps> = ({ data, eventHandlers }) => {
  const [showAddShowroomImageModal, setShowAddShowroomModal] = useState<boolean>(false);
  const [showAddImageModal, setShowAddImageModal] = useState<boolean>(false);
  const { t } = useTranslation();
  // const isMobileView = useWindowResizer();

  useEffect(() => {
    return () => {
      store.removeNotification('visuals-info-failed');
      store.removeNotification('visuals-info-success');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles['visuals-container__sub-title']}>
        {t('UNIT_TYPE_MODULE.EDIT_VISUALS.TITLE')}
      </div>
      <div className={styles['visuals-container']}>
        <div className={styles['visuals-container__section-title']}>
          {t('UNIT_TYPE_MODULE.EDIT_VISUALS.UNIT_IMAGES')}
        </div>

        <div
          className={`${styles['visuals-container__add-new']} ${
            data && data.roomTypePhotos && data.roomTypePhotos.length > 0
              ? ''
              : styles['visuals-container__add-new--no-data']
          }`}
          onClick={() => {
            setShowAddImageModal(true);
          }}
        >
          {data && data.roomTypePhotos && data.roomTypePhotos.length > 0 ? (
            ''
          ) : (
            <span>{t('UNIT_TYPE_MODULE.EDIT_VISUALS.FIRST_IMAGE_UPLOAD')}</span>
          )}
          <AddCircleIcon fontSize={'inherit'} color={'inherit'} />
        </div>
        <div>
          {data && (
            <div className={styles['visuals-container__showroom-container']}>
              {/* {data && data.roomTypePhotos && (
                <SortableSlider
                  data={{
                    sliderItems: data.roomTypePhotos.map((item) => {
                      return { imgSrc: item.url, id: item.id };
                    })
                  }}
                  eventHandlers={{
                    deleteHandler: (imageId) => {
                      console.log(imageId);
                      eventHandlers.deleteImageHandler(imageId);
                    },
                    sortHandler: (items) => {
                      eventHandlers.updateImageOrder(
                        items.map((item, index) => ({
                          id: item.id,
                          url: item.imgSrc,
                          order: index + 1
                        }))
                      );
                      console.log(items);
                    }
                  }}
                />
              )} */}
              {data && data.roomTypePhotos && (
                <SortableGalleryComp
                  data={{
                    sliderItems: data.roomTypePhotos.map((item) => {
                      return { imgSrc: item.url, id: item.id };
                    })
                  }}
                  eventHandlers={{
                    deleteHandler: (imageId) => {
                      console.log(imageId);
                      eventHandlers.deleteImageHandler(imageId);
                    },
                    sortHandler: (items) => {
                      let changeDetected = false;
                      if (data && data.roomTypePhotos) {
                        items.forEach((item, index) => {
                          const indexCount = index + 1;
                          const foundRoomType = data.roomTypePhotos.find(
                            (roomType) => roomType.id === item.id
                          );
                          if (!foundRoomType) {
                            changeDetected = true;
                          } else if (
                            indexCount !== foundRoomType.order ||
                            item.id !== foundRoomType.id
                          ) {
                            changeDetected = true;
                          }
                        });
                        if (changeDetected) {
                          eventHandlers.updateImageOrder(
                            items.map((item, index) => ({
                              id: item.id,
                              url: item.imgSrc,
                              order: index + 1
                            }))
                          );
                        }
                      }
                    }
                  }}
                />
              )}
            </div>
          )}
        </div>
        <hr />
        <div className={styles['visuals-container__section-title']}>
          {t('UNIT_TYPE_MODULE.EDIT_VISUALS.SHOWROOM_TITLE')}
        </div>

        <div
          className={`${styles['visuals-container__add-new']} ${
            data && data.showRooms && data.showRooms.length > 0
              ? ''
              : styles['visuals-container__add-new--no-data']
          }`}
          onClick={() => {
            setShowAddShowroomModal(true);
          }}
        >
          {data && data.showRooms && data.showRooms.length > 0 ? (
            ''
          ) : (
            <span>{t('UNIT_TYPE_MODULE.EDIT_VISUALS.FIRST_SHOWROOM_UPLOAD')}</span>
          )}
          <AddCircleIcon fontSize={'inherit'} color={'inherit'} />
        </div>
        <div>
          {data && (
            <div className={styles['visuals-container__showroom-container']}>
              {data &&
                data.showRooms &&
                data.showRooms.map((showRoomObj) => (
                  <div className={styles['visuals-container__showroom-container__item']}>
                    <div className={styles['visuals-container__showroom-container__item__title']}>
                      {showRoomObj.roomName}
                    </div>
                    <Slider
                      data={{
                        sliderItems: showRoomObj.imagesList.map((item) => {
                          return { imgSrc: item.url, id: item.id };
                        })
                      }}
                      eventHandlers={{
                        deleteHandler: (imageId) => {
                          eventHandlers.deleteShowroomImageHandler(imageId, showRoomObj.roomId);
                        }
                      }}
                    />
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <AddShowroomImageModal
        config={{ showModal: showAddShowroomImageModal }}
        eventHandlers={{
          cancelHandler: () => {
            setShowAddShowroomModal(false);
          },
          submitImageHandler: (roomName: string, image: ImageType) => {
            setShowAddShowroomModal(false);
            eventHandlers.submitShowroomImageHandler(roomName, image);
          }
        }}
      />
      <AddImageModal
        config={{ showModal: showAddImageModal }}
        eventHandlers={{
          cancelHandler: () => {
            setShowAddImageModal(false);
          },
          submitImageHandler: (image: ImageType) => {
            setShowAddImageModal(false);
            eventHandlers.submitImageHandler(image);
          }
        }}
      />
    </>
  );
};

export default EditVisuals;
