import React, { useEffect, useState } from 'react';
import styles from './generate-payment-link-modal.module.scss';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import {
  InputText,
  InputTextType
} from '../../../../../shared/components/core-ui/input-text/input-text.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { PaymentLinkModel, PaymentUIModel } from '../../../../../shared/ui-models/payment.ui-model';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';

import { VALIDATION_REGEX } from '../../../../../shared/components/validation.config';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export interface GeneratePaymentLinkModalProps {
  config: {
    showModal: boolean;
  };
  data: {
    contactInfo: string;
  };
  eventHandlers: {
    closeHandler: () => void;
    generatePaymentLinkHandler: (paymentLinkData: PaymentLinkModel) => void;
  };
}

/**
 * functional component AddPaymentModal
 * @param {GeneratePaymentLinkModalProps}
 */
export const GeneratePaymentLinkModal: React.FC<GeneratePaymentLinkModalProps> = ({
  config,
  eventHandlers,
  data
}) => {
  const [email, setEmail] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [skipEmail, setSkipEmail] = useState<boolean>(false);

  const isMobileView = useWindowResizer();
  useEffect(() => {
    setEmail({ value: data?.contactInfo, isValid: true });
  }, [data]);
  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.closeHandler();
        }
      }}
    >
      <div className={styles['generate-payment-link-modal']}>
        <div className={styles['generate-payment-link-modal__title']}>Generate Payment Link </div>

        <div className={styles['generate-payment-link-modal__description']}>
          Email
          <InputText
            data={{
              value: email.value,
              placeholder: 'Email'
            }}
            config={{
              autoFocus: true,
              type: InputTextType.email,
              required: true,
              validationRegex: VALIDATION_REGEX.EMAIL
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setEmail({ value, isValid });
              }
            }}
          />
        </div>
        <div className={styles['generate-payment-link-modal__description']}>
          <FormControlLabel
            control={
              <Checkbox
                checked={skipEmail}
                onChange={(e) => setSkipEmail(e.target.checked)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Do NOT send email to guest"
          />
        </div>
      </div>
      <div className={styles['generate-payment-link-modal--actions']}>
        <Button
          onClick={() => {
            eventHandlers.closeHandler();
          }}
          type={ButtonType.primaryInverted}
          tracking={{
            action: 'close',
            category: 'payments',
            label: 'generate-payment-link-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          Cancel
        </Button>
        <Button
          disabled={!email.isValid}
          onClick={() =>
            eventHandlers.generatePaymentLinkHandler({
              paymentId: '',
              skipEmail,
              email: email.value
            })
          }
          tracking={{
            action: 'submit',
            category: 'payment',
            label: 'generate-payment-link-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          Generate
        </Button>
      </div>
    </Modal>
  );
};
