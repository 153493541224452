import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { Button as MIButton } from '@material-ui/core';
import { connect } from 'react-redux';
import styles from './expenses-list.module.scss';
import Tooltip from '@material-ui/core/Tooltip';

import { RootState } from '../../../../shared/redux/reducers';
import { trackPage } from '../../../../shared/services/utils/tracking/tracking.service';
import useWindowResizer from '../../../../shared/hooks/window-resizer/window-resizer.hook';
import Button, { ButtonSize } from '../../../../shared/components/core-ui/button/button.component';
import { ReservationUiModel } from '../../../../shared/ui-models/reservation.ui-model';
import { Tabs, TabsPosition } from '../../../../shared/components/group-ui/tabs/tabs.component';
import DeleteIcon from '@material-ui/icons/Delete';
import { useHistory } from 'react-router';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Expenses } from '../../../../shared/models/unit-types.model';
import {
  CreateExpenseModal,
  CreateExpenses
} from './components/create-reservation/create-expense-modal.component';
import { UnitTypeUiModel } from '../../../../shared/ui-models/unit-type.ui-model';
import moment from 'moment';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
    }
  }
});
const useSelectedRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
      // backgroundColor: '#ffa358'
    }
  }
});
interface ExpenseListOwnProps {
  expenses?: Expenses[];
  unitType?: UnitTypeUiModel;
  addExpenseHandler: (unitExpesne: CreateExpenses) => void;
  deleteExpenseHandler: (unitExpesne: Expenses) => void;
}
export type ExpensesListCoContainerProps = ReturnType<typeof mapStateToProps>;

/**
 * functional component ReservationsListContainer
 * @param {ExpensesListCoContainerProps} holding question text
 */
export const ExpensesListContainer: React.FC<ExpensesListCoContainerProps> = ({
  isAuthenticated,
  expenses,
  unitType,
  addExpenseHandler,
  deleteExpenseHandler
}) => {
  const isMobileView = useWindowResizer();
  // const { t } = useTranslation();
  const [reservations, setReservations] = useState<ReservationUiModel[]>([]);
  const [showCreateReseration, setShowCreateReseration] = useState<boolean>(false);
  const [reservationType, setReservationType] = useState<string>();

  const history = useHistory();

  useEffect(() => {
    trackPage('expenses-list');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const counterRenderer = ({
    hours,
    minutes,
    seconds
  }: {
    hours: number;
    minutes: number;
    seconds: number;
  }) => {
    // Render a countdown
    return (
      <b className={styles['expense-list-container__expense-item__status--highlighted']}>
        {hours}:{minutes}:{seconds}
      </b>
    );
  };

  function Row(props: { expense: Expenses }) {
    const { expense } = props;
    const classes = useRowStyles();
    const selectedClasses = useSelectedRowStyles();
    return (
      <>
        <TableRow>
          <TableCell>{expense?.description}</TableCell>
          <TableCell>{expense?.roomName}</TableCell>
          <TableCell>{expense?.projectName}</TableCell>
          <TableCell>{expense?.areaName}</TableCell>
          <TableCell> {moment(expense.paymentDate).format('MM/DD/YYYY')}</TableCell>
          <TableCell>{expense?.category}</TableCell>
          <TableCell>{expense?.amount}</TableCell>
          <TableCell>
            <MIButton
              aria-haspopup="true"
              onClick={() => {
                deleteExpenseHandler(expense);
              }}
            >
              <DeleteIcon fontSize={'large'} color="inherit" />
            </MIButton>
          </TableCell>
        </TableRow>
        <TableRow></TableRow>
      </>
    );
  }
  return (
    <div className={styles['expense-list-container']}>
      <CreateExpenseModal
        config={{
          showModal: showCreateReseration,
          unitType
        }}
        eventHandlers={{
          closeHandler: () => {
            setShowCreateReseration(false);
          },
          addExpenseHandler: (expense) => {
            setShowCreateReseration(false);
            addExpenseHandler(expense);
          }
        }}
      ></CreateExpenseModal>
      <div className={styles['expense-list-container__title']}>
        <span>Expenses</span>
        <div className={styles['expense-list-container__title__actions']}>
          <Button
            onClick={() => {
              setReservationType('NORMAL_RESERVATION');
              setShowCreateReseration(true);
            }}
            tracking={{
              action: 'open',
              category: 'create-expense',
              label: 'add-create-expense-modal'
            }}
            size={ButtonSize.medium}
          >
            Create Expenses
          </Button>
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell>description</TableCell>
              <TableCell>room Name</TableCell>
              <TableCell>project Name</TableCell>
              <TableCell>Area Name</TableCell>
              <TableCell>paymentDate</TableCell>
              <TableCell>category</TableCell>
              <TableCell>amount</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {expenses?.map((expense) => (
              <Row key={expense.id} expense={expense} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default connect(mapStateToProps)(ExpensesListContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState, props: ExpenseListOwnProps) {
  return {
    isAuthenticated: !!state.auth.accessToken,
    ...props
  };
}
