import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './assign-unit-types.module.scss';
import { store } from 'react-notifications-component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import { UnitTypeUiModel } from '../../../../../shared/ui-models/unit-type.ui-model';
import { PUBLIC_PATH } from '../../../../../shared/config/routes-config';

export interface AssignUnitTypesProps {
  data: {
    propertyUnitTypes: UnitTypeUiModel[];
    unassignedUnitTypes: UnitTypeUiModel[];
  };
  config: {
    isLoading: boolean;
  };
  eventHandlers: {
    assignUnitTypeHandler: (unitTypeId: string) => void;
    unAssignUnitTypeHandler: (unitTypeId: string) => void;
  };
}

/**
 * functional component AssignUnitTypes
 * @param {AssignUnitTypesProps} holding question text
 */
export const AssignUnitTypes: React.FC<AssignUnitTypesProps> = ({
  data,
  config,
  eventHandlers
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      store.removeNotification('assign-unit-type-failed');
      store.removeNotification('assign-unit-type-success');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles['assign-unit-container__sub-title']}>
        {t('PROPERTY_MODULE.ASSIGN_UNIT_TYPES_CONTAINER.ASSIGN_UNIT_TYPES')}
      </div>

      <div className={styles['assign-unit-container']}>
        {config.isLoading ? (
          <div className={styles['assign-unit-container__cards-container__loader']}>
            <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
          </div>
        ) : (
          <>
            {t('PROPERTY_MODULE.ASSIGN_UNIT_TYPES_CONTAINER.ASSIGNED_UNIT_TYPES')}
            <div className={styles['assign-unit-container__cards-container']}>
              {data.propertyUnitTypes && data.propertyUnitTypes.length > 0 ? (
                data.propertyUnitTypes.map((unitType) => (
                  <div className={styles['assign-unit-container__cards-container__card']}>
                    <div
                      className={
                        styles['assign-unit-container__cards-container__card__image-container']
                      }
                    >
                      <img
                        alt="property-unit-type"
                        className={
                          styles[
                            'assign-unit-container__cards-container__card__image-container__image'
                          ]
                        }
                        src={
                          unitType.coverImage || `${PUBLIC_PATH}/assets/pngs/unit-type-default.png`
                        }
                      />
                    </div>
                    <div className={styles['assign-unit-container__cards-container__card__name']}>
                      {unitType.name}
                    </div>
                    <Button
                      onClick={() => {
                        if (unitType && unitType.id) {
                          eventHandlers.unAssignUnitTypeHandler(unitType.id);
                        }
                      }}
                      tracking={{
                        action: 'submit',
                        category: 'property',
                        label: 'unassign-unit-type'
                      }}
                      type={ButtonType.danger}
                      size={ButtonSize.full}
                    >
                      {t('PROPERTY_MODULE.ASSIGN_UNIT_TYPES_CONTAINER.UNASSIGN')}
                    </Button>
                  </div>
                ))
              ) : (
                <div className={styles['assign-unit-container__cards-container__message']}>
                  {t('PROPERTY_MODULE.ASSIGN_UNIT_TYPES_CONTAINER.NO_ASSIGNED_UNIT_TYPES')}
                </div>
              )}
            </div>
            <hr />
            {t('PROPERTY_MODULE.ASSIGN_UNIT_TYPES_CONTAINER.UNASSIGNED_UNIT_TYPES')}
            <div className={styles['assign-unit-container__cards-container']}>
              {data.unassignedUnitTypes && data.unassignedUnitTypes.length > 0 ? (
                data.unassignedUnitTypes.map((unitType) => (
                  <div className={styles['assign-unit-container__cards-container__card']}>
                    <div
                      className={
                        styles['assign-unit-container__cards-container__card__image-container']
                      }
                    >
                      <img
                        className={
                          styles[
                            'assign-unit-container__cards-container__card__image-container__image'
                          ]
                        }
                        alt="available-unit-type"
                        src={
                          unitType.coverImage || `${PUBLIC_PATH}/assets/pngs/unit-type-default.png`
                        }
                      />
                    </div>
                    <div className={styles['assign-unit-container__cards-container__card__name']}>
                      {unitType.name}
                    </div>
                    <Button
                      onClick={() => {
                        if (unitType && unitType.id) {
                          eventHandlers.assignUnitTypeHandler(unitType.id);
                        }
                      }}
                      tracking={{
                        action: 'submit',
                        category: 'property',
                        label: 'assign-unit-type'
                      }}
                      size={ButtonSize.full}
                    >
                      {t('PROPERTY_MODULE.ASSIGN_UNIT_TYPES_CONTAINER.ASSIGN')}
                    </Button>
                  </div>
                ))
              ) : (
                <div className={styles['assign-unit-container__cards-container__message']}>
                  {t('PROPERTY_MODULE.ASSIGN_UNIT_TYPES_CONTAINER.NO_UNASSIGNED_UNIT_TYPES')}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AssignUnitTypes;
