import React, { useEffect, useState } from 'react';
import { store } from 'react-notifications-component';
import { connect } from 'react-redux';
import styles from './reservation-edit.module.scss';
import Tooltip from '@material-ui/core/Tooltip';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import MoneyIcon from '@material-ui/icons/Money';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Accordion from '@material-ui/core/Accordion';
import ReplayIcon from '@material-ui/icons/Replay';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { ErrorInfo, isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { RootState } from '../../../shared/redux/reducers';
import { trackPage, trackPurchase } from '../../../shared/services/utils/tracking/tracking.service';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getUnitTypeDetails } from '../../../shared/services/data/unit-type.data';
import { UnitTypeUiModel } from '../../../shared/ui-models/unit-type.ui-model';
import { getModuleName } from '../../shared/services/reservation.service';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import OtpInput from 'react-otp-input';
import {
  getOneReservations,
  getOneGuest,
  updateGuest,
  addGuest,
  updateOneReservations,
  updateOnePayment,
  createOnePayment,
  reservationSwitchRoom,
  uploadPaymentReceipt,
  uploadGuestDocument,
  getBookOffer,
  reactivateOneReservations,
  addOneComment,
  generatePaymentLink,
  sendPayment,
  addPropertyManagerToReservations,
  deletePropertyManagerToReservations,
  deleteGuest
} from '../../shared/services/data.service';
import { ReservationDetailsUiModel, ReservationUiModel } from '../../../shared/ui-models/reservation.ui-model';
import { RouteComponentProps } from 'react-router';
import Select, { ValueType } from 'react-select';
import { ReservationStatus } from '../../../shared/enums/reservation-status';
import ArrowForward from '@material-ui/icons/ArrowForward';
import CallForward from '@material-ui/icons/CallSplit';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Tabs, TabsPosition } from '../../../shared/components/group-ui/tabs/tabs.component';
import EdiText from 'react-editext';
import { GuestUiModel } from '../../../shared/ui-models/guest.ui-model';
import moment from 'moment';
import { AddGuestModal } from './components/add-guest-modal/add-guest-modal.component';
import { PUBLIC_PATH } from '../../../shared/config/routes-config';
import { PERMISSIONS_LIST } from '../../../shared/config/permissions.config';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../shared/components/core-ui/button/button.component';
import { PaymentType } from '../../../shared/enums/payment-type.enum';
import { PaymentCollector } from '../../../shared/enums/payment-collector.enum';
import { PaymentStatus } from '../../../shared/enums/payment-status.enum';
import { PaymentModel } from '../../../shared/models/payment.model';
import { AddPaymentModal } from './components/add-payment-modal/add-payment-modal.component';
import { SwitchRoomModal } from './components/switch-room-modal/switch-room-modal.component';
import { AddPaymentReceiptModal } from './components/add-payment-receipt-modal/add-payment-receipt-modal.component';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import NumberFormat from 'react-number-format';
import { PaymentUIModel, SplitPaymentModel } from '../../../shared/ui-models/payment.ui-model';
import Dropzone from 'react-dropzone-uploader';
import { AddCommentModal } from './components/add-comment-modal/add-comment-modal.component';
import { makeStyles } from '@material-ui/core';
import { hasAccess } from '../../../shared/services/helpers/auth-helper/auth-helper.service';
import { BookOfferResponseModel } from '../../../shared/ui-models/book.ui-model';
import { SelectAvailableRoomModal } from './components/select-available-room-modal/select-available-room-modal.component';
import { BookingRequestUiModel } from '../../../shared/ui-models/booking-request.ui-model';
import { getBookingRequestDetails } from '../../../shared/services/data/booking.data';
import { GuestDocumentType } from '../../../shared/models/guest.model';
import { FILE_TYPES } from '../../../shared/config/files-types.config';
import { PaymentOccurrenceMode } from '../../../shared/enums/payment-occurrence-mode.enum';
import { CommentsThread } from './components/comments-thread/comments-thread.component';
import { HistoryThread } from './components/history-thread/history-thread.component';
import { GeneratePaymentLinkModal } from './components/generate-payment-link-modal/generate-payment-link-modal.component';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EmailIcon from '@material-ui/icons/Email';
import { BookingMode } from '../../../shared/enums/booking-mode.enum';
import { SendPaymentLinkModal } from './components/send-payment-link-modal/send-payment-link-modal.component';
import { ReservationChannel } from '../../../shared/enums/reservation-channel.enum';
import { CURRENCY } from '../../../shared/enums/currency.enum';
import { ReservationSource } from '../../../shared/enums/reservation-souce.enum';
import { SplitPaymentModal } from './components/split-payment-modal/split-payment-modal.component';
import { LandLordInfo } from '../landlord-info/landlord-info.container';
import { Loader } from '../../../shared/components/core-ui/loader/loader.component';
import { ManagerInfo } from '../../../PropertyModule/containers/edit-property/components/manager-info/manager-info.container';
import { AssignManagerModal } from '../../../PropertyModule/containers/edit-property/components/assign-Manager-modal/assign-manager-modal.component';
import { PropertyUiManagersListModel } from '../../../shared/ui-models/propertyManager.ui.model';

const collectorOption = [
  { label: 'Air bnb', value: PaymentCollector.AIRBNB },
  { label: 'Birdnest', value: PaymentCollector.BIRDNEST },
  { label: 'Booking', value: PaymentCollector.BOOKING },
  { label: 'Paymob', value: PaymentCollector.PAYMOB }
];
const paymentStatusOptions = [
  { label: 'Paid', value: PaymentStatus.COLLECTED },
  { label: 'Overdue', value: PaymentStatus.OVERDUE, isDisabled: true },
  { label: 'Not yet', value: PaymentStatus.PENDING, isDisabled: true },
  { label: 'Refunded', value: PaymentStatus.REFUNDED, isDisabled: true },
  { label: 'Canceled', value: PaymentStatus.CANCELED, isDisabled: true }
];
const paymentTypeOptions = [
  { label: 'Cash', value: PaymentType.CASH },
  { label: 'Online Payment', value: PaymentType.ONLINE_PAYMENT },
  { label: 'Bank Transfer', value: PaymentType.BANK_TRANSFER }
];

const useStyles = makeStyles({
  defaultRoot: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 2px 4px 0 rgb(187 186 186 / 50%)'
  },
  refundRoot: {
    backgroundColor: '#fedfa5',
    borderRadius: '8px',
    boxShadow: '0 2px 4px 0 rgb(187 186 186 / 50%)'
  },
  disabledRoot: {
    backgroundColor: 'lightgrey',
    borderRadius: '8px',
    boxShadow: '0 2px 4px 0 rgb(187 186 186 / 50%)'
  }
});
interface EditPropertyRouteParams {
  id: string;
}

export type ReservationsEditContainerProps = ReturnType<typeof mapStateToProps> &
  RouteComponentProps<EditPropertyRouteParams>;

/**
 * functional component ReservationsEditContainer
 * @param {ReservationsEditContainerProps} holding question text
 */

export const ReservationsEditContainer: React.FC<ReservationsEditContainerProps> = ({
  match,
  isAuthenticated
}) => {
  // const isMobileView = useWindowResizer();
  // const { t } = useTranslation();
  const [reservation, setReservation] = useState<ReservationDetailsUiModel>();
  const [unitType, setUnitType] = useState<UnitTypeUiModel>();
  const [guest, setGuest] = useState<GuestUiModel>();
  const [bookoffer, setBookoffer] = useState<BookOfferResponseModel>();
  const [bookofferLoading, setBookofferLoading] = useState<boolean>(false);
  const [reservationStatus, setReservationStatus] = useState<string>();
  const [showAddGuestModal, setShowAddGuestModal] = useState<boolean>(false);
  const [showSwitchRoomModal, setShowSwitchRoomModal] = useState<boolean>(false);
  const [showAddPaymentModal, setShowAddPaymentModal] = useState<boolean>(false);
  const [showGeneratePaymentLinkModal, setShowGeneratePaymentLinkModal] = useState<boolean>(false);
  const [showSplitPaymentModal, setShowSplitPaymentModal] = useState<boolean>(false);
  const [showSendPaymentLinkModal, setShowSendPaymentLinkModal] = useState<boolean>(false);
  const [showAddPaymentRecipetModal, setShowAddPaymentRecipetModal] = useState<boolean>(false);
  const [showAddCommentModal, setShowAddCommentModal] = useState<boolean>(false);
  const [showReactivateModal, setShowReactivateModal] = useState<boolean>(false);
  const [activePayment, setActivePayment] = useState<PaymentModel>();
  const [commentValue, setCommentValue] = useState<string>('');
  const [skipEmail, setSkipEmail] = useState<boolean>(false);
  const [tabKey, setTabKey] = useState('Comments');
  const [passKey, setPassKey] = useState<string>('∗∗∗∗∗∗');
  const [isPassKeyDisabled, setIsPassKeyDisabled] = useState<boolean>(true);
  const [editPassKey, setEditPassKey] = useState<boolean>(false);
  const [passKeyLoading, setPassKeyLoading] = useState<boolean>(false);
  const [contactInfo, setContactInfo] = useState<BookingRequestUiModel>();
  const [isPassportUploadLoading, setIsPassportUploadLoading] = useState<boolean>(false);
  const [isNationalIdUploadLoading, setIsNationalIdUploadLoading] = useState<boolean>(false);
  const [isMarriageUploadLoading, setIsMarriageUploadLoading] = useState<boolean>(false);
  const [isLoadingDeleteGuest, setIsLoadingDeleteGuest] = useState<boolean>(false);
  const [activePaymentId, setActivePaymentId] = useState<string>('');
  const [switchRoomLoading, setSwitchRoomLoading] = useState<boolean>(false);
  const [isCopy, setIsCopy] = useState(false);
  const [showUpdateLoader, setShowUpdateLoader] = useState(false);
  const [showAssignManagerModal, setShowAssignManagerModal] = useState<boolean>(false);

      const classes = useStyles();

  const reservationStatusColourStyles = (stateValue: any) => {
    return {
      singleValue: (styles: any) => ({ ...styles, color: 'inherit' }),
      control: (styles: any) => {
        const colorObj = getReservationStatusColor(stateValue);
        return {
          ...styles,
          ...colorObj
        };
      },
      option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
        const colorObj = getReservationStatusColor(data.value);
        return {
          ...styles,
          ...colorObj
        };
      }
    };
  };
  const paymentStatusColourStyles = (stateValue: any) => {
    return {
      singleValue: (styles: any) => ({ ...styles, color: 'inherit' }),
      control: (styles: any) => {
        const colorObj = getPaymentStatusColor(stateValue);
        return {
          ...styles,
          ...colorObj
        };
      },
      option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
        const colorObj = getPaymentStatusColor(data.value);
        return {
          ...styles,
          ...colorObj
        };
      }
    };
  };
  useEffect(() => {
    const id = match.params.id;
    trackPage('reservations-edit');
    getOneReservation(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function getOneGuestObject(id: string) {
    getOneGuest(id).then((response: GuestUiModel | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setGuest(response);
      }
    });
  }
  function updateOneGuest(guest: GuestUiModel) {
    setShowUpdateLoader(true);
    updateGuest(guest).then((response: GuestUiModel | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setGuest(response);
        setShowUpdateLoader(false);
      }
    });
  }
  function deleteOneGuest(guestId: string) {
    setIsLoadingDeleteGuest(true);
    return deleteGuest(guestId).then((response: GuestUiModel | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setGuest(response);
        return updateOneReservations({...reservation, guestIds: reservation?.guestIds.filter((id)=>guest.id != id)})
        .then(() => getOneReservation(reservation?.id || ''));
      }
    }).finally(()=>setIsLoadingDeleteGuest(false));
  }
  function getBookingDetails(requestId: string) {
    getBookingRequestDetails(requestId, getModuleName()).then((requestItem) => {
      if (!isErrorInfo(requestItem)) {
        setContactInfo(requestItem);
      } else {
        store.addNotification({
          id: 'edit-booking-request-failed',
          title: 'Doubled booking alert',
          message: 'No available room for the selected dates',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut']
        });
      }
    });
  }
  function getOneReservation(id: string) {
    return getOneReservations(id)
      .then((response: ReservationDetailsUiModel | ErrorInfo): any => {
        if (!isErrorInfo(response)) {
          if (response && response.paymentList && response.paymentList.length > 0) {
            response = {
              ...response,
              paymentList: response.paymentList
                .filter(Boolean)
                .sort((a, b) => b.paymentAmount - a.paymentAmount)
                .sort(
                  (a, b) =>
                    moment(a.dueDate).hours(0).seconds(0).milliseconds(0).unix() -
                    moment(b.dueDate).hours(0).seconds(0).milliseconds(0).unix()
                )
            };
          }
          setReservation(response);
          setReservationStatus(response.status);
          getBookingDetails(response.bookingRequestId);
          if (response.guestIds && response.guestIds.length) {
            getOneGuestObject(response.guestIds[0]);
          }

          return getUnitTypeDetails(response.unitTypeId, getModuleName());
        }
      })
      .then((responseUnit: UnitTypeUiModel | ErrorInfo) => {
        if (!isErrorInfo(responseUnit)) {
          setUnitType(responseUnit);
        }
      });
  }
  function updatePassKey(newPassKey: string) {
    setPassKey(newPassKey);
  }
  function submitNewPassKey() {
    if (reservation) {
      setPassKeyLoading(true);
      updateOneReservations({
        id: reservation.id,
        passKey
      }).then(() =>
        getOneReservation(reservation.id).then(() => {
          store.addNotification({
            title: 'Success Update',
            message: 'Pass key has been updated successfully.',
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          setPassKeyLoading(false);
          setPassKey('∗∗∗∗∗∗');
          setEditPassKey(false);
        })
      );
    }
  }
  
  const addPropertyManagement = (show: boolean) => {
    setShowAssignManagerModal(show);
  };
  const handleAddPropertyManager = (id: string) => {
    console.log(id, reservation?.id || '');
    addPropertyManagerToReservations(id, reservation?.id || '').then((response: ReservationUiModel | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        store.addNotification({
          id: 'add-reservation-manager-success',
          title:'success',
          message: 'property manager added successfully',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        if(reservation){
          setReservation({ ...reservation, propertyManagersList: response.propertyManagersList });
        }
      } else {
        store.addNotification({
          id: 'edit-property-manager-failed',
          title:'failed',
          message: 'failed to add property manager',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut']
        });
      }
    });
  };
  const deletePropertyManager = (id: string) => {
    deletePropertyManagerToReservations(id, reservation?.id || '').then((response: ReservationUiModel | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        store.addNotification({
          id: 'add-property-manager-success',
          title:'success',
          message:'property manager deleted successfully',
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        if(reservation){
          setReservation({ ...reservation, propertyManagersList: response.propertyManagersList });
        }
      } else {
        store.addNotification({
          id: 'edit-property-manager-failed',
          title:'failed',
          message: 'failed to add delete manager',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut']
        });
      }
    });
  };

  return (
    <div className={styles['reservation-edit-container']}>
      <AddGuestModal
        config={{ showModal: showAddGuestModal }}
        eventHandlers={{
          closeHandler: () => {
            setShowAddGuestModal(false);
          },
          addGuestHandler: (guest) => {
            addGuest(guest)
              .then((response: GuestUiModel | ErrorInfo) => {
                if (!isErrorInfo(response)) {
                  return response;
                }
              })
              .then((newGuest: GuestUiModel | undefined) => {
                if (reservation && newGuest) {
                  let updatedReservation: Partial<ReservationDetailsUiModel> = {
                    id: reservation.id
                  };
                  if (Array.isArray(reservation.guestIds)) {
                    updatedReservation = {
                      ...updatedReservation,
                      guestIds: [...reservation.guestIds, newGuest.id || '']
                    };
                  } else {
                    updatedReservation = {
                      ...updatedReservation,
                      guestIds: [newGuest.id || '']
                    };
                  }
                  updateOneReservations(updatedReservation).then(() =>
                    getOneReservation(reservation.id).then(() => setShowAddGuestModal(false))
                  );
                }
              });
          }
        }}
      />
      <AddPaymentModal
        config={{ showModal: showAddPaymentModal }}
        data={{ reservation }}
        eventHandlers={{
          closeHandler: () => {
            setShowAddPaymentModal(false);
          },
          addPaymentHandler: (payment) => {
            createOnePayment(payment)
              .then((response: PaymentUIModel | ErrorInfo) => {
                if (!isErrorInfo(response)) {
                  return response;
                }
              })
              .then((newPayment: PaymentUIModel | undefined) => {
                if (reservation && newPayment) {
                  let updatedReservation: Partial<ReservationDetailsUiModel> = {
                    id: reservation.id
                  };
                  if (Array.isArray(reservation.paymentIds)) {
                    updatedReservation = {
                      ...updatedReservation,
                      paymentIds: [...reservation.paymentIds, newPayment.id || '']
                    };
                  } else {
                    updatedReservation = {
                      ...updatedReservation,
                      paymentIds: [newPayment.id || '']
                    };
                  }
                  updateOneReservations(updatedReservation).then(() =>
                    getOneReservation(reservation.id).then(() => setShowAddPaymentModal(false))
                  );
                }
              });
          }
        }}
      />

      <SwitchRoomModal
        config={{ showModal: showSwitchRoomModal }}
        data={{ reservation, switchRoomLoading }}
        eventHandlers={{
          closeHandler: () => {
            setShowSwitchRoomModal(false);
          },
          switchRoomHandler: (unitTypeId: number, roomId: string) => {
            setSwitchRoomLoading(true);
            reservationSwitchRoom(
              reservation?.id || 0,
              reservation?.unitTypeId,
              unitTypeId,
              roomId
            ).then((response: ReservationDetailsUiModel | ErrorInfo) => {
              setSwitchRoomLoading(false);
              if (!isErrorInfo(response)) {
                store.addNotification({
                  title: 'Room Switched Successfully',
                  message: 'Reservation room switched, and an email is sent to Property Manager(s)',
                  type: 'success',
                  insert: 'top',
                  container: 'top-right',
                  animationIn: ['animate__animated', 'animate__fadeIn'],
                  animationOut: ['animate__animated', 'animate__fadeOut'],
                  dismiss: {
                    duration: 3000,
                    onScreen: true
                  }
                });
                getOneReservation(reservation?.id).then(() => setShowSwitchRoomModal(false));
                // if (response) {
                //   setReservation(response);
                // }
              } else {
                if (response.status === 409) {
                  store.addNotification({
                    id: 'double-booking',
                    title: 'Double Booking Alert',
                    message:
                      'Switch room operation failed, as destination room is not available at the same dates, please contact support for taking further actions',
                    type: 'danger',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animate__animated', 'animate__fadeIn'],
                    animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                      duration: 3000,
                      onScreen: true
                    }
                  });
                } else {
                  store.addNotification({
                    id: 'switch-room-failed',
                    title: 'Switch room operation failed',
                    message: 'Switch room operation failed, Please contact support.',
                    type: 'danger',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animate__animated', 'animate__fadeIn'],
                    animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                      duration: 3000,
                      onScreen: true
                    }
                  });
                }
              }
            });
          }
        }}
      />
      <SplitPaymentModal
        config={{ showModal: showSplitPaymentModal }}
        data={{
          reservation,
          sourcePayment: reservation?.paymentList?.find((payment) => payment.id === activePaymentId)
        }}
        eventHandlers={{
          closeHandler: () => {
            setActivePaymentId('');
            setShowSplitPaymentModal(false);
          },
          SplitPaymentHandler: (paymentSplitData: SplitPaymentModel) => {
            updateOnePayment(paymentSplitData.paymentToUpdate).then(() => {
              createOnePayment(paymentSplitData.paymentToAdd)
                .then((response: PaymentUIModel | ErrorInfo) => {
                  if (!isErrorInfo(response)) {
                    return response;
                  }
                })
                .then((newPayment: PaymentUIModel | undefined) => {
                  setShowSplitPaymentModal(false);
                  if (reservation && newPayment) {
                    let updatedReservation: Partial<ReservationDetailsUiModel> = {
                      id: reservation.id
                    };
                    if (Array.isArray(reservation.paymentIds)) {
                      updatedReservation = {
                        ...updatedReservation,
                        paymentIds: [...reservation.paymentIds, newPayment.id || '']
                      };
                    } else {
                      updatedReservation = {
                        ...updatedReservation,
                        paymentIds: [newPayment.id || '']
                      };
                    }
                    updateOneReservations(updatedReservation).then(() =>
                      getOneReservation(reservation.id).then(() => setShowAddPaymentModal(false))
                    );
                  }
                });
            });
          }
        }}
      />
      <GeneratePaymentLinkModal
        config={{ showModal: showGeneratePaymentLinkModal }}
        data={{ contactInfo: contactInfo?.guestInfo?.email || '' }}
        eventHandlers={{
          closeHandler: () => {
            setActivePaymentId('');
            setShowGeneratePaymentLinkModal(false);
          },
          generatePaymentLinkHandler: ({ skipEmail, email }) => {
            generatePaymentLink({ paymentId: activePaymentId.toString(), skipEmail, email }).then(
              (response: any | ErrorInfo) => {
                if (!isErrorInfo(response)) {
                  store.addNotification({
                    id: 'Success Update',
                    title: 'Link Generated',
                    message: 'Payment Link Generated Successfully',
                    type: 'success',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animate__animated', 'animate__fadeIn'],
                    animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                      duration: 3000,
                      onScreen: true
                    }
                  });
                  if (reservation) {
                    getOneReservation(reservation.id).then(() => {
                      setActivePaymentId('');
                      setShowGeneratePaymentLinkModal(false);
                    });
                  }

                  return response;
                  // getOneReservation(response.id);
                } else {
                  store.addNotification({
                    id: 'Failed Update',
                    title: 'Generation Failed',
                    message: 'Payment Link failed To Generated',
                    type: 'danger',
                    insert: 'top',
                    container: 'top-right',
                    animationIn: ['animate__animated', 'animate__fadeIn'],
                    animationOut: ['animate__animated', 'animate__fadeOut'],
                    dismiss: {
                      duration: 3000,
                      onScreen: true
                    }
                  });
                }
              }
            );
          }
        }}
      />
      <SendPaymentLinkModal
        config={{ showModal: showSendPaymentLinkModal }}
        data={{ contactInfo: contactInfo?.guestInfo?.email || '' }}
        eventHandlers={{
          closeHandler: () => {
            setActivePaymentId('');
            setShowSendPaymentLinkModal(false);
          },
          sendPaymentLinkHandler: (email: string) => {
            sendPayment(activePaymentId, email).then((response: any | ErrorInfo) => {
              if (!isErrorInfo(response)) {
                store.addNotification({
                  id: 'Email Sent Success',
                  title: 'No request details',
                  message: 'Payment Link Sent Successfully',
                  type: 'success',
                  insert: 'top',
                  container: 'top-right',
                  animationIn: ['animate__animated', 'animate__fadeIn'],
                  animationOut: ['animate__animated', 'animate__fadeOut'],
                  dismiss: {
                    duration: 3000,
                    onScreen: true
                  }
                });
                setShowSendPaymentLinkModal(false);
                return response;
              } else {
                store.addNotification({
                  id: 'Failed to send email',
                  title: 'Email sending failed',
                  message: 'Payment Link failed To be sent',
                  type: 'danger',
                  insert: 'top',
                  container: 'top-right',
                  animationIn: ['animate__animated', 'animate__fadeIn'],
                  animationOut: ['animate__animated', 'animate__fadeOut'],
                  dismiss: {
                    duration: 3000,
                    onScreen: true
                  }
                });
              }
            });
          }
        }}
      />
      <AddPaymentReceiptModal
        config={{ showModal: showAddPaymentRecipetModal }}
        data={{ status: activePayment?.status, paymentId: activePayment?.id }}
        eventHandlers={{
          closeHandler: () => {
            setShowAddPaymentRecipetModal(false);
          },
          addPaymentReceiptHandler: (
            asset: File,
            collectedAtDate: Date,
            status?: PaymentStatus,
            id?: string,
            skipEmail?: boolean
          ) => {
            if (asset && id && reservation) {
              uploadPaymentReceipt(
                asset,
                collectedAtDate,
                id,
                reservation.id,
                status || PaymentStatus.COLLECTED,
                skipEmail || false
              ).then(() => {
                if (activePayment?.type === PaymentOccurrenceMode.ONE_TIME) {
                  trackPurchase(
                    reservation && reservation.paymentList
                      ? reservation.paymentList
                          .filter((payment) => payment.status === PaymentStatus.COLLECTED)
                          .reduce(
                            (prev, current) =>
                              current.direction === 'out'
                                ? prev - ((current && current.paymentAmount) || 0)
                                : prev + ((current && current.paymentAmount) || 0),
                            0
                          )
                      : 0,
                    'EGP '
                  );
                }
                getOneReservation(reservation.id);
                setShowAddPaymentRecipetModal(false);
              });
            }
          }
        }}
      />
      {reservation && unitType && (
        <>
          <div className={styles['reservation-edit-container__title']}>
            <div className={styles['reservation-edit-container__title__header']}>
              Reservations #{reservation.id}{' '}
              {(reservation?.channel || reservation?.source) && (
                <Tooltip
                  title={
                    <span
                      className={styles['reservation-edit-container__title__booking-mode__tooltip']}
                    >
                      {reservation.source} - {reservation.channel || ReservationChannel.BIRDNEST}
                    </span>
                  }
                >
                  <div className={styles['reservation-edit-container__title__channel-wrapper']}>
                    <div className={styles['reservation-edit-container__title__channel']}>
                      {reservation.source === ReservationSource.BN_ADMIN ? (
                        <img
                          className={styles['reservation-edit-container__title__channel__image']}
                          src={`${PUBLIC_PATH}/assets/jpeg/admin.webp`}
                        />
                      ) : reservation.source === ReservationSource.BN_APP ? (
                        <img
                          className={styles['reservation-edit-container__title__channel__image']}
                          src={`${PUBLIC_PATH}/assets/pngs/bn-app.png`}
                        />
                      ) : reservation.source === ReservationSource.BN_WEBSITE ? (
                        <img
                          className={styles['reservation-edit-container__title__channel__image']}
                          src={`${PUBLIC_PATH}/assets/pngs/bn-website.png`}
                        />
                      ) : reservation.source === ReservationSource.CHANNEL_MANAGER ? (
                        <img
                          className={styles['reservation-edit-container__title__channel__image']}
                          src={`${PUBLIC_PATH}/assets/jpeg/rentals-united.jpeg`}
                        />
                      ) : null}
                    </div>
                    <div className={styles['reservation-edit-container__title__channel']}>
                      {reservation.channel === ReservationChannel.AIRBNB ? (
                        <img
                          className={styles['reservation-edit-container__title__channel__image']}
                          src={`${PUBLIC_PATH}/assets/pngs/airbnb.png`}
                        />
                      ) : reservation.channel === ReservationChannel.EXPEDIA ? (
                        <img
                          className={styles['reservation-edit-container__title__channel__image']}
                          src={`${PUBLIC_PATH}/assets/pngs/expedia.png`}
                        />
                      ) : reservation.channel === ReservationChannel.BOOKING ? (
                        <img
                          className={styles['reservation-edit-container__title__channel__image']}
                          src={`${PUBLIC_PATH}/assets/pngs/booking.png`}
                        />
                      ) : reservation.channel === ReservationChannel.VRBO ? (
                        <img
                          className={styles['reservation-edit-container__title__channel__image']}
                          src={`${PUBLIC_PATH}/assets/pngs/vrbo.png`}
                        />
                      ) : (
                        <img
                          className={styles['reservation-edit-container__title__channel__image']}
                          src={`${PUBLIC_PATH}/assets/pngs/birdnest-logo.png`}
                        />
                      )}
                    </div>
                  </div>
                </Tooltip>
              )}
              {reservation?.bookingMode === BookingMode.INSTANT && (
                <Tooltip
                  title={
                    <span
                      className={styles['reservation-edit-container__title__booking-mode__tooltip']}
                    >
                      Instant Booking
                    </span>
                  }
                >
                  <div className={styles['reservation-edit-container__title__booking-mode']}>
                    <OfflineBoltIcon fontSize="inherit" color="inherit" />
                    <div
                      className={styles['reservation-edit-container__title__booking-mode__text']}
                    >
                      Instant
                    </div>
                  </div>
                </Tooltip>
              )}
              {reservation &&
                reservation.paymentList &&
                reservation.paymentList.length > 0 &&
                reservation.paymentList[0].currency && (
                  <Tooltip
                    title={
                      <span
                        className={styles['reservation-edit-container__title__currency__tooltip']}
                      >
                        {reservation.paymentList[0].currency}
                      </span>
                    }
                  >
                    <div className={styles['reservation-edit-container__title__currency']}>
                      {reservation.paymentList[0].currency === 'usd' ? (
                        <AttachMoneyIcon fontSize="inherit" color="inherit" />
                      ) : (
                        <MoneyIcon fontSize="inherit" color="inherit" />
                      )}
                      <div className={styles['reservation-edit-container__title__currency__text']}>
                        {reservation.paymentList[0].currency.toUpperCase()}
                      </div>
                    </div>
                  </Tooltip>
                )}
            </div>
            {reservation.status === ReservationStatus.EXPIRED && (
              <div className={styles['reservation-edit-container__title__reactivate']}>
                <Button
                  type={ButtonType.danger}
                  className={styles['reservation-edit-container__title__reactivate__btn']}
                  onClick={() => {
                    setBookofferLoading(true);
                    getBookOffer(
                      reservation.unitTypeId || '',
                      moment(reservation.checkInDate).format('DD/MM/YYYY'),
                      moment(reservation.checkOutDate).format('DD/MM/YYYY')
                    ).then((res) => {
                      setBookofferLoading(false);
                      if (!isErrorInfo(res)) {
                        if (
                          (res as BookOfferResponseModel).availableRooms &&
                          (res as BookOfferResponseModel).availableRooms.length > 0
                        ) {
                          setBookoffer(res as BookOfferResponseModel);
                          setShowReactivateModal(true);
                        } else {
                          store.addNotification({
                            id: 'get-available-room',
                            title: 'No rooms avialable',
                            message: 'No available rooms to reactivate reservation',
                            type: 'danger',
                            insert: 'top',
                            container: 'top-right',
                            animationIn: ['animate__animated', 'animate__fadeIn'],
                            animationOut: ['animate__animated', 'animate__fadeOut']
                          });
                        }
                      } else {
                        store.addNotification({
                          id: 'get-available-room',
                          title: 'No rooms avialable',
                          message: 'No available rooms to reactivate reservation',
                          type: 'danger',
                          insert: 'top',
                          container: 'top-right',
                          animationIn: ['animate__animated', 'animate__fadeIn'],
                          animationOut: ['animate__animated', 'animate__fadeOut']
                        });
                      }
                    });
                  }}
                  loading={bookofferLoading}
                  disabled={reservation.status !== ReservationStatus.EXPIRED}
                  size={ButtonSize.medium}
                >
                  <ReplayIcon color="inherit" fontSize="inherit" />
                  Reactivate Reservation
                </Button>
              </div>
            )}
            <div className={styles['reservation-edit-container__title__select']}>
              <Select
                isDisabled={
                  reservation.source === ReservationSource.CHANNEL_MANAGER ||
                  !hasAccess([
                    PERMISSIONS_LIST.reservationsFullAccess,
                    PERMISSIONS_LIST.reservationsModifyAccess
                  ])
                }
                styles={reservationStatusColourStyles(reservationStatus)}
                options={dropdownItemStatus[reservation.status]}
                defaultValue={dropdownItemStatus[reservation.status][0]}
                onChange={(
                  value: ValueType<{ value: ReservationStatus; label: string }, false>
                ) => {
                  setShowAddCommentModal(true);
                  setReservationStatus(value ? value.value : '');
                }}
              />
              <p className={styles['reservation-edit-container__reservation-item--out']}>
                {reservation && reservation.source === ReservationSource.CHANNEL_MANAGER
                  ? `Status updates only allowed on ${
                      reservation.channel ? reservation.channel.toUpperCase() : 'OTA directly'
                    }`
                  : ''}
              </p>
            </div>
          </div>
          <div className={styles['reservation-edit-container__status-details']}>
            <div className={styles['reservation-edit-container__status-details__dates']}>
              <div>
                <div className={styles['reservation-edit-container__status-details__dates__label']}>
                  Check in date
                </div>
                <div className={styles['reservation-edit-container__status-details__dates__value']}>
                  {new Date(reservation.checkInDate).toDateString()}
                </div>
              </div>
              <ArrowForward color="inherit" fontSize="large" />
              <div>
                <div className={styles['reservation-edit-container__status-details__dates__label']}>
                  Check out date
                </div>
                <div className={styles['reservation-edit-container__status-details__dates__value']}>
                  {new Date(reservation.checkOutDate).toDateString()}
                </div>
              </div>
            </div>
            <div className={styles['reservation-edit-container__status-details__payments']}>
              <div>
                <div
                  className={styles['reservation-edit-container__status-details__payments__label']}
                >
                  Preferred Method
                </div>
                <div
                  className={styles['reservation-edit-container__status-details__payments__value']}
                  style={{
                    color: reservation.paymentMethod ? '' : '#E00707'
                  }}
                >
                  {reservation.paymentMethod ? (
                    <>
                      <div
                        className={
                          styles['reservation-edit-container__status-details__payments__icon']
                        }
                      >
                        {reservation.paymentMethod === PaymentType.BANK_TRANSFER ? (
                          <AccountBalanceIcon fontSize="large" />
                        ) : reservation.paymentMethod === PaymentType.CASH ? (
                          <MoneyIcon fontSize="large" />
                        ) : (
                          <CreditCardIcon fontSize="large" />
                        )}
                      </div>
                      {reservation.paymentMethod}
                    </>
                  ) : (
                    'NOT SET'
                  )}
                </div>
              </div>
              <div>
                <div
                  className={styles['reservation-edit-container__status-details__payments__label']}
                >
                  Payments Status
                </div>
                <div
                  className={styles['reservation-edit-container__status-details__payments__value']}
                  style={{
                    color: getPaymentStatusColor(getPaymentStatus(reservation.paymentList || []))
                      .color
                  }}
                >
                  {getPaymentStatus(reservation.paymentList || []) === PaymentStatus.COLLECTED
                    ? 'On Time'
                    : PaymentStatus.OVERDUE}
                </div>
              </div>
              <div>
                <div
                  className={styles['reservation-edit-container__status-details__payments__label']}
                >
                  Payment Overview
                </div>
                <div
                  className={styles['reservation-edit-container__status-details__payments__value']}
                >
                  {reservation.paymentList && (
                    <NumberFormat
                      value={reservation.paymentList
                        .filter((payment) => payment.status === PaymentStatus.COLLECTED)
                        .reduce(
                          (prev, current) =>
                            current.direction === 'out'
                              ? prev - ((current && current.paymentAmount) || 0)
                              : prev + ((current && current.paymentAmount) || 0),
                          0
                        )}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={
                        reservation.paymentList.length > 0 && reservation.paymentList[0].currency
                          ? CURRENCY[reservation.paymentList[0].currency] + ' '
                          : 'EGP '
                      }
                    />
                  )}
                  /
                  {reservation.paymentList && (
                    <NumberFormat
                      value={reservation.paymentList.reduce(
                        (prev, current) =>
                          current.direction === 'out'
                            ? prev - ((current && current.paymentAmount) || 0)
                            : prev + ((current && current.paymentAmount) || 0),
                        0
                      )}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={
                        reservation.paymentList.length > 0 && reservation.paymentList[0].currency
                          ? CURRENCY[reservation.paymentList[0].currency] + ' '
                          : 'EGP '
                      }
                    />
                  )}
                </div>
              </div>
              <div>
                <div
                  className={styles['reservation-edit-container__status-details__payments__label']}
                >
                  Late payments
                </div>
                <div
                  className={styles['reservation-edit-container__status-details__payments__value']}
                >
                  {getPaymentStatus(reservation.paymentList || []) === PaymentStatus.COLLECTED
                    ? '-'
                    : reservation.paymentList && (
                        <NumberFormat
                          value={reservation.paymentList
                            .filter((payment) => payment.status === PaymentStatus.OVERDUE)
                            .reduce(
                              (prev, current) => prev + ((current && current.paymentAmount) || 0),
                              0
                            )}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={
                            reservation.paymentList.length > 0 &&
                            reservation.paymentList[0].currency
                              ? CURRENCY[reservation.paymentList[0].currency] + ' '
                              : 'EGP '
                          }
                          style={{ color: '#FF0000' }}
                        />
                      )}
                </div>
              </div>
            </div>
            <div className={styles['reservation-edit-container__status-details__duration']}>
              <div>
                <div
                  className={styles['reservation-edit-container__status-details__duration__label']}
                >
                  Duration
                </div>
                <div
                  className={styles['reservation-edit-container__status-details__duration__value']}
                >
                  {moment(Number(reservation.checkOutDate)).diff(
                    moment(Number(reservation.checkInDate)),
                    'days'
                  ) + 1}{' '}
                  Days
                </div>
              </div>
              <div>
                <div
                  className={styles['reservation-edit-container__status-details__duration__label']}
                >
                  Guests
                </div>
                <div
                  className={styles['reservation-edit-container__status-details__duration__value']}
                >
                  {reservation.guestIds ? reservation.guestIds.length : 0}
                </div>
              </div>
            </div>
          </div>
          <div className={styles['reservation-edit-container__details-container']}>
            <div className={styles['reservation-edit-container__reservation-details']}>
              <div className={styles['reservation-edit-container__reservation-details__unit']}>
                <img
                  className={styles['reservation-edit-container__reservation-details__unit__image']}
                  src={`${unitType.coverImage}`}
                  alt="unit cover"
                />
                <div
                  className={
                    styles['reservation-edit-container__reservation-details__unit__area-container']
                  }
                >
                  <div
                    className={
                      styles[
                        'reservation-edit-container__reservation-details__unit__area-container__area'
                      ]
                    }
                  >
                    {reservation.area}
                  </div>
                </div>
                <div
                  className={
                    styles['reservation-edit-container__reservation-details__unit__details']
                  }
                >
                  <div>
                    <div
                      className={
                        styles[
                          'reservation-edit-container__reservation-details__unit__details__label'
                        ]
                      }
                    >
                      Unit type
                    </div>
                    <a
                      href={`${process.env.PUBLIC_URL}/unit-types/edit/${unitType.id}`}
                      className={`
                      ${styles['reservation-edit-container__reservation-details__unit__details__value']} ${styles['reservation-edit-container__reservation-details__unit__details__value__link']}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {unitType.name}
                      {'  '} <ArrowForward color="inherit" fontSize="inherit" />
                    </a>
                  </div>
                  <div>
                    <div
                      className={
                        styles[
                          'reservation-edit-container__reservation-details__unit__details__label'
                        ]
                      }
                    >
                      Property
                    </div>
                    <a
                      href={`${process.env.PUBLIC_URL}/properties/edit/${reservation.propertyId}`}
                      className={`
                      ${styles['reservation-edit-container__reservation-details__unit__details__value']} ${styles['reservation-edit-container__reservation-details__unit__details__value__link']}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {reservation.propertyName}
                      {'  '} <ArrowForward color="inherit" fontSize="inherit" />
                    </a>
                  </div>
                  <div>
                    <div
                      className={
                        styles[
                          'reservation-edit-container__reservation-details__unit__details__label'
                        ]
                      }
                    >
                      Room Name
                    </div>
                    <div
                      className={
                        styles[
                          'reservation-edit-container__reservation-details__unit__details__value'
                        ]
                      }
                    >
                      {unitType && unitType.units && unitType.units.length > 0 && reservation.roomId
                        ? unitType.units.find((unit) => unit.unitId === reservation.roomId)
                            ?.shortName
                        : 'UNASSIGNED'}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    styles['reservation-edit-container__reservation-details__unit__pass-key']
                  }
                >
                  <Button
                    onClick={() => {
                      setShowSwitchRoomModal(true);
                    }}
                    type={ButtonType.danger}
                    size={ButtonSize.medium}
                  >
                    Switch Room
                  </Button>
                </div>
                {unitType?.landlordId && (
                  <LandLordInfo
                    data={{
                      landlordName: unitType?.landlordUsername || '',
                      landlordEmail: unitType?.landlordEmail || '',
                      landlordPhone: unitType?.landlordPhone || '',
                      landlordShare: unitType?.landlordShare || 0
                    }}
                  />
                )}
                {hasAccess([
                  PERMISSIONS_LIST.reservationsFullAccess,
                  PERMISSIONS_LIST.reservationsModifyAccess
                ]) && (
                  <div
                    className={
                      styles['reservation-edit-container__reservation-details__unit__pass-key']
                    }
                  >
                    {/* <div
                  className={
                    styles[
                      'reservation-edit-container__reservation-details__unit__pass-key__header'
                    ]
                  }
                >
                  Pass Key
                </div> */}
                    <img
                      className={
                        styles[
                          'reservation-edit-container__reservation-details__unit__pass-key__image'
                        ]
                      }
                      src={`${PUBLIC_PATH}/assets/pngs/pass-key.png`}
                      alt="pass-key"
                    />
                    <OtpInput
                      shouldAutoFocus={false}
                      value={passKey}
                      onChange={updatePassKey}
                      numInputs={6}
                      isDisabled={isPassKeyDisabled}
                      inputStyle={'otp-component__input'}
                      focusStyle={'otp-component__input--focused'}
                      disabledStyle={'otp-component__input--disabled'}
                      separator={
                        <span
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__unit__pass-key__separator'
                            ]
                          }
                        >
                          .
                        </span>
                      }
                    />
                    <div
                      className={
                        styles[
                          'reservation-edit-container__reservation-details__unit__pass-key__actions'
                        ]
                      }
                    >
                      {editPassKey ? (
                        <>
                          <Button
                            onClick={() => {
                              submitNewPassKey();
                            }}
                            loading={passKeyLoading}
                            disabled={passKey.length < 6}
                            size={ButtonSize.medium}
                          >
                            Save
                          </Button>
                          <Button
                            onClick={() => {
                              setPassKey('∗∗∗∗∗∗');
                              setEditPassKey(false);
                            }}
                            disabled={passKeyLoading}
                            size={ButtonSize.medium}
                            type={ButtonType.primaryInverted}
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            onMouseDown={() => {
                              setPassKey(reservation.passKey);
                            }}
                            onMouseUp={() => {
                              setPassKey('∗∗∗∗∗∗');
                            }}
                            disabled={!reservation.passKey}
                            size={ButtonSize.medium}
                          >
                            Show Pass Key
                          </Button>
                          <Button
                            onClick={() => {
                              setEditPassKey(true);
                              setIsPassKeyDisabled(false);
                              setPassKey('');
                            }}
                            size={ButtonSize.medium}
                            type={ButtonType.primaryInverted}
                          >
                            Edit
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className={styles['reservation-edit-container__reservation-details__contacts']}>
                <div
                  className={
                    styles['reservation-edit-container__reservation-details__contact-container']
                  }
                >
                  <div
                    className={
                      styles[
                        'reservation-edit-container__reservation-details__contact-container__header'
                      ]
                    }
                  >
                    Contact Info{' '}
                    <span
                      className={
                        styles[
                          'reservation-edit-container__reservation-details__contact-container__sub-header'
                        ]
                      }
                    >
                      -from booking request
                    </span>
                  </div>
                  {contactInfo && (
                    <div
                      className={
                        styles[
                          'reservation-edit-container__reservation-details__guest-container__guest-wrapper'
                        ]
                      }
                    >
                      <div
                        className={
                          styles[
                            'reservation-edit-container__reservation-details__guest-container__guest'
                          ]
                        }
                      >
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest__label'
                            ]
                          }
                        >
                          User #
                        </div>
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest__value'
                            ]
                          }
                        >
                          {reservation.userId || '--'}
                        </div>
                      </div>
                      <div
                        className={
                          styles[
                            'reservation-edit-container__reservation-details__guest-container__guest'
                          ]
                        }
                      >
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest__label'
                            ]
                          }
                        >
                          Contact Name
                        </div>
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest__value'
                            ]
                          }
                        >
                          {contactInfo.guestInfo.name}
                          {/* <EdiText
                          type="text"
                          value={contactInfo.guestInfo.name}
                          onSave={(val) => {
                            console.log('name changed');
                          }}
                        /> */}
                        </div>
                      </div>

                      <div
                        className={
                          styles[
                            'reservation-edit-container__reservation-details__guest-container__guest'
                          ]
                        }
                      >
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest__label'
                            ]
                          }
                        >
                          Phone number
                        </div>
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest__value'
                            ]
                          }
                        >
                          {contactInfo.guestInfo.phoneNumber}
                          {/* <EdiText
                          type="text"
                          value={String(contactInfo.guestInfo.phoneNumber)}
                          onSave={(val) => {
                            // updateOneGuest({ ...contactInfo, phoneNumber: val });
                          }}
                        /> */}
                        </div>
                      </div>
                      <div
                        className={
                          styles[
                            'reservation-edit-container__reservation-details__guest-container__guest'
                          ]
                        }
                      >
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest__label'
                            ]
                          }
                        >
                          Email
                        </div>
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest__value'
                            ]
                          }
                        >
                          {contactInfo.guestInfo.email}
                          {/* <EdiText
                          type="text"
                          value={contactInfo.guestInfo.email}
                          onSave={(val) => {
                            // updateOneGuest({ ...contactInfo, email: val });
                          }}
                        /> */}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {reservation && reservation.source === ReservationSource.CHANNEL_MANAGER && (
                  <div
                    className={
                      styles['reservation-edit-container__reservation-details__contact-container']
                    }
                  >
                    <div
                      className={
                        styles[
                          'reservation-edit-container__reservation-details__contact-container__header'
                        ]
                      }
                    >
                      Channel Manager Info{' '}
                      <span
                        className={
                          styles[
                            'reservation-edit-container__reservation-details__contact-container__sub-header'
                          ]
                        }
                      >
                        -from Rentals United
                      </span>
                    </div>
                    {contactInfo && (
                      <div
                        className={
                          styles[
                            'reservation-edit-container__reservation-details__guest-container__guest-wrapper'
                          ]
                        }
                      >
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest'
                            ]
                          }
                        >
                          <div
                            className={
                              styles[
                                'reservation-edit-container__reservation-details__guest-container__guest__label'
                              ]
                            }
                          >
                            Channel
                          </div>
                          <div
                            className={
                              styles[
                                'reservation-edit-container__reservation-details__guest-container__guest__value'
                              ]
                            }
                          >
                            {reservation.channel ? reservation.channel.toUpperCase() : '--'}
                          </div>
                        </div>
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest'
                            ]
                          }
                        >
                          <div
                            className={
                              styles[
                                'reservation-edit-container__reservation-details__guest-container__guest__label'
                              ]
                            }
                          >
                            Currency
                          </div>
                          <div
                            className={
                              styles[
                                'reservation-edit-container__reservation-details__guest-container__guest__value'
                              ]
                            }
                          >
                            {reservation.paymentList &&
                            reservation.paymentList.length > 0 &&
                            reservation.paymentList[0].currency
                              ? reservation.paymentList[0].currency.toUpperCase()
                              : 'EGP'}
                            {/* <EdiText
          type="text"
          value={contactInfo.guestInfo.name}
          onSave={(val) => {
            console.log('name changed');
          }}
        /> */}
                          </div>
                        </div>

                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest'
                            ]
                          }
                        >
                          {reservation.channelCommission && reservation.channelCommission.amount && (
                            <>
                              {' '}
                              <div
                                className={
                                  styles[
                                    'reservation-edit-container__reservation-details__guest-container__guest__label'
                                  ]
                                }
                              >
                                Channel Commission
                              </div>
                              <div
                                className={
                                  styles[
                                    'reservation-edit-container__reservation-details__guest-container__guest__value'
                                  ]
                                }
                              >
                                <NumberFormat
                                  value={reservation.channelCommission.amount}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={reservation.channelCommission.currency || 'EGP '}
                                />
                                {/* <EdiText
          type="text"
          value={String(contactInfo.guestInfo.phoneNumber)}
          onSave={(val) => {
            // updateOneGuest({ ...contactInfo, phoneNumber: val });
          }}
        /> */}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div
                  className={
                    styles['reservation-edit-container__reservation-details__guest-container']
                  }
                >
                  {!reservation.guestIds || reservation.guestIds.length === 0 ? (
                    <div
                      className={
                        styles[
                          'reservation-edit-container__reservation-details__guest-container__add-guest'
                        ]
                      }
                    >
                      <img src={`${PUBLIC_PATH}/assets/jpeg/add-guest.jpeg`} alt="add-user" />
                      <p>No Guests added yet, click below to add first guest</p>
                      <Button onClick={() => setShowAddGuestModal(true)} size={ButtonSize.medium}>
                        Add Guest
                      </Button>
                    </div>
                  ) : (
                    <></>
                  )}
                  {reservation.guestIds && reservation.guestIds.length ? (
                    <div
                      className={
                        styles[
                          'reservation-edit-container__reservation-details__guest-container__tabs-container'
                        ]
                      }
                    >
                      <div
                        className={
                          styles[
                            'reservation-edit-container__reservation-details__guest-container__tabs-container__tabs'
                          ]
                        }
                      >
                        <Tabs
                          data={{
                            tabsList: reservation
                              ? reservation.guestIds &&
                                reservation.guestIds.map((guest, index) => ({
                                  component: <></>,
                                  key: `Guest ${index + 1}`,
                                  id: guest
                                }))
                              : [],
                            defaultTab: 'Guest 1'
                          }}
                          style={{ position: TabsPosition.LEFT }}
                          eventHandlers={{
                            tabChangeHandler: (tabKey, id) => {
                              if (id) {
                                getOneGuestObject(id);
                              }
                            }
                          }}
                        />
                      </div>
                      {hasAccess([
                        PERMISSIONS_LIST.reservationsFullAccess,
                        PERMISSIONS_LIST.reservationsModifyAccess
                      ]) && (
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__tabs-container__add'
                            ]
                          }
                          onClick={() => {
                            setShowAddGuestModal(true);
                          }}
                        >
                          <AddBoxIcon color="primary" fontSize="inherit" />
                        </div>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  {guest && !showUpdateLoader ? (
                    <div
                      className={
                        styles[
                          'reservation-edit-container__reservation-details__guest-container__guest-wrapper'
                        ]
                      }
                    >
                      <div
                        className={
                          styles[
                            'reservation-edit-container__reservation-details__guest-container__guest'
                          ]
                        }
                      >
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest__label'
                            ]
                          }
                        >
                          First Name
                        </div>
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest__value'
                            ]
                          }
                        >
                          {hasAccess([
                            PERMISSIONS_LIST.reservationsFullAccess,
                            PERMISSIONS_LIST.reservationsModifyAccess
                          ]) ? (
                            <EdiText
                              type="text"
                              value={guest.firstName}
                              onSave={(val) => {
                                updateOneGuest({
                                  ...guest,
                                  firstName: val
                                });
                              }}
                            />
                          ) : (
                            <span>{guest.firstName}</span>
                          )}
                        </div>
                      </div>
                      <div
                        className={
                          styles[
                            'reservation-edit-container__reservation-details__guest-container__guest'
                          ]
                        }
                      >
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest__label'
                            ]
                          }
                        >
                          Last Name
                        </div>
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest__value'
                            ]
                          }
                        >
                          {hasAccess([
                            PERMISSIONS_LIST.reservationsFullAccess,
                            PERMISSIONS_LIST.reservationsModifyAccess
                          ]) ? (
                            <EdiText
                              type="text"
                              value={guest.lastName}
                              onSave={(val) => {
                                updateOneGuest({ ...guest, lastName: val });
                              }}
                            />
                          ) : (
                            <span>{guest.lastName}</span>
                          )}
                        </div>
                      </div>
                      <div
                        className={
                          styles[
                            'reservation-edit-container__reservation-details__guest-container__guest'
                          ]
                        }
                      >
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest__label'
                            ]
                          }
                        >
                          Phone number
                        </div>
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest__value'
                            ]
                          }
                        >
                          {hasAccess([
                            PERMISSIONS_LIST.reservationsFullAccess,
                            PERMISSIONS_LIST.reservationsModifyAccess
                          ]) ? (
                            <EdiText
                              type="text"
                              value={String(guest.phoneNumber)}
                              onSave={(val) => {
                                updateOneGuest({ ...guest, phoneNumber: val });
                              }}
                            />
                          ) : (
                            <span>{guest.phoneNumber}</span>
                          )}
                        </div>
                      </div>
                      <div
                        className={
                          styles[
                            'reservation-edit-container__reservation-details__guest-container__guest'
                          ]
                        }
                      >
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest__label'
                            ]
                          }
                        >
                          Email
                        </div>
                        <div
                          className={
                            styles[
                              'reservation-edit-container__reservation-details__guest-container__guest__value'
                            ]
                          }
                        >
                          {hasAccess([
                            PERMISSIONS_LIST.reservationsFullAccess,
                            PERMISSIONS_LIST.reservationsModifyAccess
                          ]) ? (
                            <EdiText
                              type="text"
                              value={guest.email}
                              onSave={(val) => {
                                updateOneGuest({ ...guest, email: val });
                              }}
                            />
                          ) : (
                            <span>{guest.email}</span>
                          )}
                        </div>
                      </div>
                      {hasAccess([
                        PERMISSIONS_LIST.reservationsFullAccess,
                        PERMISSIONS_LIST.reservationsModifyAccess
                      ]) && (
                        <div
                          className={`
                        ${styles['reservation-edit-container__reservation-details__guest-container__guest']} ${styles['reservation-edit-container__reservation-details__guest-container__guest--full-row']}`}
                        >
                          <div
                            className={
                              styles[
                                'reservation-edit-container__reservation-details__guest-container__guest__label'
                              ]
                            }
                          >
                            National ID:
                          </div>
                          {guest.document ? (
                            guest.document
                              .filter(
                                (doc) => !doc.type || doc.type === GuestDocumentType.NATIONAL_ID
                              )
                              .map((doc, index) => (
                                <a
                                  href={doc.documentUrl}
                                  // className={
                                  //   styles[
                                  //     'reservation-edit-container__reservation-details__guest-container__guest__link'
                                  //   ]
                                  // }
                                >
                                  <div
                                    className={
                                      styles[
                                        'reservation-edit-container__reservation-details__guest-container__guest__link-container'
                                      ]
                                    }
                                  >
                                    {doc.documentUrl &&
                                    doc.documentUrl.split('.').length > -1 &&
                                    FILE_TYPES[
                                      `${doc.documentUrl.split('.').pop()?.toUpperCase()}`
                                    ] ? (
                                      <img
                                        className={
                                          styles[
                                            'reservation-edit-container__reservation-details__guest-container__guest__link-container__image'
                                          ]
                                        }
                                        src={
                                          FILE_TYPES[doc.documentUrl.split('.').pop().toUpperCase()]
                                            .image
                                        }
                                      ></img>
                                    ) : (
                                      <img
                                        className={
                                          styles[
                                            'reservation-edit-container__reservation-details__guest-container__guest__link-container__image'
                                          ]
                                        }
                                        src={`${PUBLIC_PATH}/assets/pngs/file-icon.png`}
                                      ></img>
                                    )}
                                    <div
                                      className={
                                        styles[
                                          'reservation-edit-container__reservation-details__guest-container__guest__link-container__details'
                                        ]
                                      }
                                    >
                                      <div
                                        className={
                                          styles[
                                            'reservation-edit-container__reservation-details__guest-container__guest__link-container__details__filename'
                                          ]
                                        }
                                      >
                                        {`National ID ${index + 1}`}
                                      </div>
                                      <div
                                        className={
                                          styles[
                                            'reservation-edit-container__reservation-details__guest-container__guest__link-container__details__file-id'
                                          ]
                                        }
                                      >
                                        {doc.documentId}
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              ))
                          ) : (
                            <></>
                          )}
                          <Dropzone
                            classNames={{
                              input:
                                styles[
                                  'reservation-edit-container__reservation-details__guest-container__guest__dropzone'
                                ]
                            }}
                            inputContent={() => (
                              <div
                                className={
                                  styles[
                                    'reservation-edit-container__reservation-details__guest-container__guest__upload-container'
                                  ]
                                }
                              >
                                {isNationalIdUploadLoading ? (
                                  <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
                                ) : (
                                  <>
                                    <CloudUploadIcon fontSize={'large'} />
                                    Upload Identification Document
                                  </>
                                )}
                              </div>
                            )}
                            accept="*"
                            maxFiles={1}
                            multiple={false}
                            onSubmit={(files, allFiles) => {
                              setIsNationalIdUploadLoading(true);
                              files.forEach((file) => {
                                uploadGuestDocument(
                                  file.file,
                                  guest.id || '',
                                  reservation.id,
                                  GuestDocumentType.NATIONAL_ID
                                ).then(() => {
                                  setIsNationalIdUploadLoading(false);
                                  getOneReservation(reservation.id);
                                });
                                file.remove();
                              });
                            }}
                          />
                          <div
                            className={
                              styles[
                                'reservation-edit-container__reservation-details__guest-container__guest__label'
                              ]
                            }
                          >
                            Passport:
                          </div>
                          {guest.document ? (
                            guest.document
                              .filter((doc) => doc.type === GuestDocumentType.PASSPORT)
                              .map((doc, index) => (
                                <a
                                  href={doc.documentUrl}
                                  // className={
                                  //   styles[
                                  //     'reservation-edit-container__reservation-details__guest-container__guest__link'
                                  //   ]
                                  // }
                                >
                                  <div
                                    className={
                                      styles[
                                        'reservation-edit-container__reservation-details__guest-container__guest__link-container'
                                      ]
                                    }
                                  >
                                    {doc.documentUrl &&
                                    doc.documentUrl.split('.').length > -1 &&
                                    FILE_TYPES[
                                      `${doc.documentUrl.split('.').pop()?.toUpperCase()}`
                                    ] ? (
                                      <img
                                        className={
                                          styles[
                                            'reservation-edit-container__reservation-details__guest-container__guest__link-container__image'
                                          ]
                                        }
                                        src={
                                          FILE_TYPES[doc.documentUrl.split('.').pop().toUpperCase()]
                                            .image
                                        }
                                      ></img>
                                    ) : (
                                      <img
                                        className={
                                          styles[
                                            'reservation-edit-container__reservation-details__guest-container__guest__link-container__image'
                                          ]
                                        }
                                        src={`${PUBLIC_PATH}/assets/pngs/file-icon.png`}
                                      ></img>
                                    )}
                                    <div
                                      className={
                                        styles[
                                          'reservation-edit-container__reservation-details__guest-container__guest__link-container__details'
                                        ]
                                      }
                                    >
                                      <div
                                        className={
                                          styles[
                                            'reservation-edit-container__reservation-details__guest-container__guest__link-container__details__filename'
                                          ]
                                        }
                                      >
                                        {`Passport Document ${index + 1}`}
                                      </div>
                                      <div
                                        className={
                                          styles[
                                            'reservation-edit-container__reservation-details__guest-container__guest__link-container__details__file-id'
                                          ]
                                        }
                                      >
                                        {doc.documentId}
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              ))
                          ) : (
                            <></>
                          )}
                          <Dropzone
                            classNames={{
                              input:
                                styles[
                                  'reservation-edit-container__reservation-details__guest-container__guest__dropzone'
                                ]
                            }}
                            inputContent={() => (
                              <div
                                className={
                                  styles[
                                    'reservation-edit-container__reservation-details__guest-container__guest__upload-container'
                                  ]
                                }
                              >
                                {isPassportUploadLoading ? (
                                  <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
                                ) : (
                                  <>
                                    <CloudUploadIcon fontSize={'large'} />
                                    Upload Passport Document
                                  </>
                                )}
                              </div>
                            )}
                            accept="*"
                            maxFiles={1}
                            multiple={false}
                            onSubmit={(files) => {
                              setIsPassportUploadLoading(true);
                              files.forEach((file) => {
                                uploadGuestDocument(
                                  file.file,
                                  guest.id || '',
                                  reservation.id,
                                  GuestDocumentType.PASSPORT
                                ).then(() => {
                                  getOneReservation(reservation.id);
                                  setIsPassportUploadLoading(false);
                                });
                                file.remove();
                              });
                            }}
                          />
                          <div
                            className={
                              styles[
                                'reservation-edit-container__reservation-details__guest-container__guest__label'
                              ]
                            }
                          >
                            Marriage Certificate:
                          </div>
                          {guest.document ? (
                            guest.document
                              .filter((doc) => doc.type === GuestDocumentType.MARRIAGE_DOC)
                              .map((doc, index) => (
                                <a
                                  href={doc.documentUrl}
                                  // className={
                                  //   styles[
                                  //     'reservation-edit-container__reservation-details__guest-container__guest__link'
                                  //   ]
                                  // }
                                >
                                  <div
                                    className={
                                      styles[
                                        'reservation-edit-container__reservation-details__guest-container__guest__link-container'
                                      ]
                                    }
                                  >
                                    {doc.documentUrl &&
                                    doc.documentUrl.split('.').length > -1 &&
                                    FILE_TYPES[
                                      `${doc.documentUrl.split('.').pop()?.toUpperCase()}`
                                    ] ? (
                                      <img
                                        className={
                                          styles[
                                            'reservation-edit-container__reservation-details__guest-container__guest__link-container__image'
                                          ]
                                        }
                                        src={
                                          FILE_TYPES[doc.documentUrl.split('.').pop().toUpperCase()]
                                            .image
                                        }
                                      ></img>
                                    ) : (
                                      <img
                                        className={
                                          styles[
                                            'reservation-edit-container__reservation-details__guest-container__guest__link-container__image'
                                          ]
                                        }
                                        src={`${PUBLIC_PATH}/assets/pngs/file-icon.png`}
                                      ></img>
                                    )}
                                    <div
                                      className={
                                        styles[
                                          'reservation-edit-container__reservation-details__guest-container__guest__link-container__details'
                                        ]
                                      }
                                    >
                                      <div
                                        className={
                                          styles[
                                            'reservation-edit-container__reservation-details__guest-container__guest__link-container__details__filename'
                                          ]
                                        }
                                      >
                                        {`Marriage Document ${index + 1}`}
                                      </div>
                                      <div
                                        className={
                                          styles[
                                            'reservation-edit-container__reservation-details__guest-container__guest__link-container__details__file-id'
                                          ]
                                        }
                                      >
                                        {doc.documentId}
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              ))
                          ) : (
                            <></>
                          )}

                          <Dropzone
                            classNames={{
                              input:
                                styles[
                                  'reservation-edit-container__reservation-details__guest-container__guest__dropzone'
                                ]
                            }}
                            inputContent={() => (
                              <div
                                className={
                                  styles[
                                    'reservation-edit-container__reservation-details__guest-container__guest__upload-container'
                                  ]
                                }
                              >
                                {isMarriageUploadLoading ? (
                                  <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
                                ) : (
                                  <>
                                    <CloudUploadIcon fontSize={'large'} />
                                    Upload Marriage Certifcate
                                  </>
                                )}
                              </div>
                            )}
                            accept="*"
                            maxFiles={1}
                            multiple={false}
                            onSubmit={(files) => {
                              setIsMarriageUploadLoading(true);
                              files.forEach((file) => {
                                uploadGuestDocument(
                                  file.file,
                                  guest.id || '',
                                  reservation.id,
                                  GuestDocumentType.MARRIAGE_DOC
                                ).then(() => {
                                  setIsMarriageUploadLoading(false);
                                  getOneReservation(reservation.id);
                                });
                                file.remove();
                              });
                            }}
                          />
                        </div>
                      )}
                      <Button
                        type={ButtonType.danger}
                        className={styles['reservation-edit-container__title__reactivate__btn']}
                        onClick={() => {
                          deleteOneGuest(guest.id||'').then(()=>store.addNotification({
                            id: 'delete-guest',
                            title: 'delete guest ',
                            message: `successfully delete ${guest.firstName}.`,
                            type: 'success',
                            insert: 'top',
                            container: 'top-right',
                            animationIn: ['animate__animated', 'animate__fadeIn'],
                            animationOut: ['animate__animated', 'animate__fadeOut']
                          }));
                        }}
                        loading={isLoadingDeleteGuest}
                        size={ButtonSize.medium}
                      >
                        delete guest
                      </Button>
                    </div>
                  ) : showUpdateLoader &&  (
                    <div className={styles['reservation-edit-container-loader']}>
                      <Loader />
                    </div>
                  )}
                </div>
              </div>
              <div className={styles['reservation-edit-container__payments__header']}>
                <span>Payments</span>

                {hasAccess([
                  PERMISSIONS_LIST.reservationsFullAccess,
                  PERMISSIONS_LIST.reservationsModifyAccess
                ]) && (
                  <div
                    className={styles['reservation-edit-container__payments__header__add']}
                    onClick={() => {
                      setShowAddPaymentModal(true);
                    }}
                  >
                    <AddBoxIcon color="primary" fontSize="inherit" />
                  </div>
                )}
              </div>
              {reservation && reservation.paymentList && reservation.paymentList.length ? (
                reservation.paymentList.map((payment, index) => (
                  <div className={styles['reservation-edit-container__payments__list']}>
                    {payment.status !== PaymentStatus.REFUNDED &&
                      payment.status !== PaymentStatus.COLLECTED &&
                      payment.direction !== 'out' &&
                      payment.paymentAmount > 0 && (
                        <div className={styles['reservation-edit-container__payments__link']}>
                          {payment.partialPayment ? (
                            <div
                              className={
                                styles['reservation-edit-container__payments__copy-container']
                              }
                            >
                              OTA collected partial payment{' '}
                              <NumberFormat
                                value={payment.partialPayment?.amount || 0}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={
                                  payment.partialPayment?.currency
                                    ? CURRENCY[payment.partialPayment?.currency] + ' '
                                    : 'EGP '
                                }
                              />
                            </div>
                          ) : payment.birdnestPaymentLink ? (
                            <div
                              className={
                                styles['reservation-edit-container__payments__copy-container']
                              }
                            >
                              <span>{payment.birdnestPaymentLink}</span>
                              <button
                                className={
                                  styles[
                                    'reservation-edit-container__payments__copy-container-copy'
                                  ]
                                }
                                onClick={() => {
                                  navigator.clipboard.writeText(`${payment.birdnestPaymentLink}`),
                                    setIsCopy(!isCopy);
                                }}
                              >
                                <span>
                                  {' '}
                                  <FileCopyIcon />
                                  {isCopy && (
                                    <span
                                      className={
                                        styles[
                                          'reservation-edit-container__payments__copy-container-talkbubble'
                                        ]
                                      }
                                    >
                                      {isCopy ? 'copied' : 'copy'}
                                    </span>
                                  )}
                                </span>
                              </button>
                              <div
                                className={
                                  styles['reservation-edit-container__payments__link__send']
                                }
                              >
                                <button
                                  className={
                                    styles['reservation-edit-container__payments__link__send__btn']
                                  }
                                  onClick={() => {
                                    setActivePaymentId(payment.id);
                                    setShowSendPaymentLinkModal(true);
                                  }}
                                >
                                  Send <EmailIcon />
                                </button>
                              </div>
                            </div>
                          ) : (
                            <button
                              onClick={() => {
                                setActivePaymentId(payment.id);
                                setShowGeneratePaymentLinkModal(true);
                              }}
                              className={`
                      ${styles['reservation-edit-container__reservation-details__unit__details__value']} ${styles['reservation-edit-container__reservation-details__unit__details__value__link']}`}
                            >
                              Click here to generate payment link
                              {'  '} <ArrowForward color="inherit" fontSize="inherit" />
                            </button>
                          )}
                        </div>
                      )}
                    <Accordion
                      key={index}
                      TransitionProps={{ unmountOnExit: true, mountOnEnter: true }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        classes={{
                          root:
                            payment.direction === 'out'
                              ? classes.refundRoot
                              : payment.paymentAmount <= 0
                              ? classes.disabledRoot
                              : classes.defaultRoot
                        }}
                        aria-label="Expand"
                        aria-controls="additional-actions1-content"
                        id={payment.id}
                      >
                        <div className={styles['reservation-edit-container__payments']}>
                          <div className={styles['reservation-edit-container__payments__item']}>
                            <div>
                              <div
                                className={
                                  styles['reservation-edit-container__payments__item__label']
                                }
                              >
                                ID
                              </div>
                              <div
                                className={
                                  styles['reservation-edit-container__payments__item__value']
                                }
                              >
                                {payment.id}
                              </div>
                            </div>
                            <div>
                              <div
                                className={`${
                                  styles['reservation-edit-container__payments__item__label']
                                } ${
                                  payment && payment.paymentAmount <= 0
                                    ? styles['reservation-edit-container__reservation-item--out']
                                    : ''
                                }`}
                              >
                                Amount
                              </div>
                              <div
                                className={`${
                                  styles['reservation-edit-container__payments__item__value']
                                } ${
                                  payment && payment.paymentAmount <= 0
                                    ? styles['reservation-edit-container__reservation-item--out']
                                    : ''
                                }`}
                              >
                                <NumberFormat
                                  value={payment.paymentAmount || 0}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                  prefix={
                                    payment && payment.currency
                                      ? CURRENCY[payment.currency] + ' '
                                      : 'EGP '
                                  }
                                />
                                {reservation.source !== 'channel-manager' &&
                                  payment.status !== PaymentStatus.COLLECTED &&
                                  payment.status !== PaymentStatus.REFUNDED &&
                                  payment.paymentAmount > 0 &&
                                  payment.direction !== 'out' && (
                                    <p
                                      onClick={(event) => {
                                        setActivePaymentId(payment.id);
                                        setShowSplitPaymentModal(true);
                                        event.stopPropagation();
                                      }}
                                      className={`
                      ${styles['reservation-edit-container__reservation-details__unit__details__value__link']}`}
                                    >
                                      {'  '} <CallForward color="inherit" fontSize="inherit" />
                                    </p>
                                  )}
                              </div>
                            </div>
                            <div>
                              <div
                                className={
                                  styles['reservation-edit-container__payments__item__label']
                                }
                              >
                                Direction
                              </div>
                              <div
                                className={
                                  styles['reservation-edit-container__payments__item__value']
                                }
                              >
                                {`${payment.direction === 'out' ? 'PAY-OUT' : 'PAY-IN'}`}
                              </div>
                            </div>
                            <div>
                              <div
                                className={
                                  styles['reservation-edit-container__payments__item__label']
                                }
                              >
                                due date
                              </div>
                              <div
                                className={
                                  styles['reservation-edit-container__payments__item__value']
                                }
                              >
                                {new Date(payment.dueDate).toDateString()}
                              </div>
                            </div>
                            <div>
                              <div
                                className={
                                  styles['reservation-edit-container__payments__item__label']
                                }
                              >
                                type
                              </div>
                              <div
                                className={
                                  styles['reservation-edit-container__payments__item__value']
                                }
                                onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                  event.stopPropagation();
                                }}
                              >
                                <Select
                                  className={
                                    styles[
                                      'reservation-edit-container__payments__item__value__select'
                                    ]
                                  }
                                  isDisabled={payment ? payment.paymentAmount <= 0 : false}
                                  options={paymentTypeOptions}
                                  defaultValue={paymentTypeOptions.find(
                                    (val) => val.value === payment.paymentType
                                  )}
                                  onChange={(
                                    value: ValueType<
                                      { value: string | undefined; label: string },
                                      false
                                    >
                                  ) => {
                                    updateOnePayment({
                                      id: payment.id,
                                      paymentType: value
                                        ? (value.value as PaymentType)
                                        : payment.paymentType
                                    }).then(() => getOneReservation(reservation.id));
                                  }}
                                />
                              </div>
                            </div>
                            <div>
                              <div
                                className={
                                  styles['reservation-edit-container__payments__item__label']
                                }
                              >
                                collector
                              </div>
                              <div
                                className={
                                  styles['reservation-edit-container__payments__item__value']
                                }
                                onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                  event.stopPropagation();
                                }}
                              >
                                <Select
                                  className={
                                    styles[
                                      'reservation-edit-container__payments__item__value__select'
                                    ]
                                  }
                                  isDisabled={payment ? payment.paymentAmount <= 0 : false}
                                  options={collectorOption}
                                  defaultValue={collectorOption.find(
                                    (val) => val.value === payment.collector
                                  )}
                                  onChange={(
                                    value: ValueType<
                                      { value: string | undefined; label: string },
                                      false
                                    >
                                  ) => {
                                    updateOnePayment({
                                      id: payment.id,
                                      collector: value
                                        ? (value.value as PaymentCollector)
                                        : payment.collector
                                    }).then(() => getOneReservation(reservation.id));
                                  }}
                                />
                              </div>
                            </div>

                            <div>
                              <div
                                className={
                                  styles['reservation-edit-container__payments__item__label']
                                }
                              >
                                status
                              </div>
                              <div
                                className={
                                  styles['reservation-edit-container__payments__item__value']
                                }
                                onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                  event.stopPropagation();
                                }}
                              >
                                <Select
                                  isDisabled={payment ? payment.paymentAmount <= 0 : false}
                                  className={
                                    styles[
                                      'reservation-edit-container__payments__item__value__select'
                                    ]
                                  }
                                  dis
                                  styles={paymentStatusColourStyles(payment.status)}
                                  options={
                                    payment.direction === 'out'
                                      ? [{ label: 'Refunded', value: PaymentStatus.REFUNDED }]
                                      : [{ label: 'Paid', value: PaymentStatus.COLLECTED }]
                                  }
                                  defaultValue={paymentStatusOptions.find(
                                    (val) => val.value === payment.status
                                  )}
                                  onChange={(
                                    value: ValueType<
                                      { value: string | undefined; label: string },
                                      false
                                    >
                                  ) => {
                                    setActivePayment({
                                      ...payment,
                                      status:
                                        (value && (value.value as PaymentStatus)) ||
                                        PaymentStatus.COLLECTED
                                    });
                                    setShowAddPaymentRecipetModal(true);
                                  }}
                                />
                              </div>
                            </div>
                            {payment.attempts && payment.attempts.length > 0 && (
                              <>
                                <hr />
                                <div
                                  className={
                                    styles[
                                      'reservation-edit-container__reservation-details__contact-container__header'
                                    ]
                                  }
                                >
                                  Online Attempts
                                </div>
                                <div
                                  className={
                                    styles['reservation-edit-container__payments__attempt']
                                  }
                                >
                                  <div
                                    className={
                                      styles['reservation-edit-container__payments__item__label']
                                    }
                                  >
                                    Status
                                  </div>
                                  <div
                                    className={
                                      styles['reservation-edit-container__payments__item__label']
                                    }
                                  >
                                    Card Number
                                  </div>
                                  <div
                                    className={
                                      styles['reservation-edit-container__payments__item__label']
                                    }
                                  >
                                    Attempt Date
                                  </div>
                                  <div
                                    className={
                                      styles['reservation-edit-container__payments__item__label']
                                    }
                                  >
                                    Paymob Order Id
                                  </div>
                                  <div
                                    className={
                                      styles['reservation-edit-container__payments__item__label']
                                    }
                                  >
                                    Reason
                                  </div>
                                </div>
                              </>
                            )}
                            {payment.attempts &&
                              payment.attempts.map((attempt) => (
                                <div
                                  className={
                                    styles['reservation-edit-container__payments__attempt']
                                  }
                                >
                                  <div
                                    className={
                                      styles['reservation-edit-container__payments__item__value']
                                    }
                                  >
                                    <img
                                      className={
                                        styles[
                                          'reservation-edit-container__payments__item__value__image'
                                        ]
                                      }
                                      src={
                                        attempt.attemptStatus === 'completed'
                                          ? `${PUBLIC_PATH}/assets/jpeg/payment-card-success.jpeg`
                                          : attempt.attemptStatus === 'fraud'
                                          ? `${PUBLIC_PATH}/assets/jpeg/danger.jpeg`
                                          : `${PUBLIC_PATH}/assets/jpeg/failed-payment.webp`
                                      }
                                    />
                                    {attempt.attemptStatus}
                                  </div>

                                  <div
                                    className={
                                      styles['reservation-edit-container__payments__item__value']
                                    }
                                  >
                                    {attempt.cardNumber}
                                  </div>

                                  <div
                                    className={
                                      styles['reservation-edit-container__payments__item__value']
                                    }
                                  >
                                    {new Date(attempt.attemptDate).toDateString()}
                                  </div>

                                  <div
                                    className={
                                      styles['reservation-edit-container__payments__item__value']
                                    }
                                  >
                                    {attempt.paymobOrderId}
                                  </div>

                                  <div
                                    className={
                                      styles['reservation-edit-container__payments__item__value']
                                    }
                                  >
                                    {attempt.reason}
                                  </div>
                                </div>
                              ))}
                          </div>
                          {(payment.status === PaymentStatus.COLLECTED ||
                            payment.status === PaymentStatus.REFUNDED) && (
                            <div
                              className={
                                styles['reservation-edit-container__payments__item__paid-details']
                              }
                            >
                              <hr />
                              <div>
                                <div
                                  className={
                                    styles[
                                      'reservation-edit-container__payments__item__paid-details__label'
                                    ]
                                  }
                                >
                                  Updated by:
                                </div>
                                <div
                                  className={
                                    styles[
                                      'reservation-edit-container__payments__item__paid-details__value'
                                    ]
                                  }
                                >
                                  {payment.updatedBy}
                                </div>
                              </div>
                              <div>
                                <div
                                  className={
                                    styles[
                                      'reservation-edit-container__payments__item__paid-details__label'
                                    ]
                                  }
                                >
                                  Updated At:
                                </div>
                                <div
                                  className={
                                    styles[
                                      'reservation-edit-container__payments__item__paid-details__value'
                                    ]
                                  }
                                >
                                  {moment(payment.updatedAt).format('DD/MM/YYYY, h:mm:ss a')}
                                </div>
                              </div>
                              <div>
                                <div
                                  className={
                                    styles[
                                      'reservation-edit-container__payments__item__paid-details__label'
                                    ]
                                  }
                                >
                                  Proof of Payment:
                                </div>
                                <a
                                  href={payment.receipt?.receiptUrl}
                                  className={
                                    styles[
                                      'reservation-edit-container__payments__item__paid-details__link'
                                    ]
                                  }
                                >
                                  {payment.receipt?.receiptId} {'  '}{' '}
                                  <ArrowForward color="inherit" fontSize="inherit" />
                                </a>
                              </div>
                            </div>
                          )}
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div
                          className={styles['reservation-edit-container__payments__item__details']}
                        >
                          <div
                            className={
                              styles['reservation-edit-container__payments__item__details__item']
                            }
                          >
                            <div
                              className={
                                styles[
                                  'reservation-edit-container__payments__item__details__item__title'
                                ]
                              }
                            >
                              Fee name
                            </div>
                            <div
                              className={
                                styles[
                                  'reservation-edit-container__payments__item__details__item__title'
                                ]
                              }
                            >
                              Type
                            </div>
                            {/* <div
                      className={
                        styles['reservation-edit-container__payments__item__details__item__title']
                      }
                    >
                      Type
                    </div> */}
                            <div
                              className={
                                styles[
                                  'reservation-edit-container__payments__item__details__item__title'
                                ]
                              }
                            >
                              Amount
                            </div>
                          </div>
                          {payment &&
                            payment.paymentItems &&
                            payment.paymentItems.length > 0 &&
                            payment.paymentItems.map((paymentItem, index) => (
                              <div
                                className={
                                  styles[
                                    'reservation-edit-container__payments__item__details__item'
                                  ]
                                }
                              >
                                <div
                                  className={
                                    styles[
                                      'reservation-edit-container__payments__item__details__item__content'
                                    ]
                                  }
                                >
                                  {paymentItem.feeName === 'Unit Price' &&
                                  paymentItem.feeValue &&
                                  paymentItem.duration
                                    ? `${paymentItem.feeValue / paymentItem.duration}x${
                                        paymentItem.duration
                                      }`
                                    : paymentItem.feeName}
                                </div>
                                <div
                                  className={
                                    styles[
                                      'reservation-edit-container__payments__item__details__item__content'
                                    ]
                                  }
                                >
                                  {paymentItem.itemType || 'N/A'}
                                </div>
                                {/* <div
                          className={
                            styles[
                              'reservation-edit-container__payments__item__details__item__content'
                            ]
                          }
                        >
                          {paymentItem.feeType === FeeType.AMOUNT ? 'EGP ' : '%'}
                        </div> */}
                                <div
                                  className={
                                    styles[
                                      'reservation-edit-container__payments__item__details__item__content'
                                    ]
                                  }
                                >
                                  {(payment.status === PaymentStatus.OVERDUE ||
                                    payment.status === PaymentStatus.PENDING ||
                                    payment.status === PaymentStatus.COLLECTED) &&
                                  hasAccess([PERMISSIONS_LIST.paymentsFullAccess]) ? (
                                    <EdiText
                                      type="number"
                                      value={`${paymentItem.feeValue}`}
                                      onSave={(val) => {
                                        const updatedPaymentItems = [...payment.paymentItems];
                                        updatedPaymentItems[index] = {
                                          ...updatedPaymentItems[index],
                                          feeValue: val
                                        };
                                        updateOnePayment({
                                          id: payment.id,
                                          document: updatedPaymentItems
                                        }).then(() => getOneReservation(reservation.id));
                                      }}
                                    />
                                  ) : (
                                    <NumberFormat
                                      value={paymentItem.feeValue}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={
                                        paymentItem.currency
                                          ? CURRENCY[paymentItem.currency] + ' '
                                          : 'EGP '
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
            <div className={styles['reservation-edit-container__log-details-wrapper']}>
              <div className={styles['reservation-edit-container__log-details']}>
                <Tabs
                  data={{
                    tabsList: [
                      {
                        component: (
                          <CommentsThread
                            data={{ commentsList: reservation.messages }}
                            eventHandlers={{
                              handleCommentSubmit: (value) => {
                                addOneComment(reservation.id, value).then((response) => {
                                  getOneReservation(reservation.id).then(() => {
                                    store.addNotification({
                                      title: 'Success Update',
                                      message: 'New message added successfully.',
                                      type: 'success',
                                      insert: 'top',
                                      container: 'top-right',
                                      animationIn: ['animate__animated', 'animate__fadeIn'],
                                      animationOut: ['animate__animated', 'animate__fadeOut'],
                                      dismiss: {
                                        duration: 3000,
                                        onScreen: true
                                      }
                                    });
                                  });
                                });
                              }
                            }}
                          />
                        ),
                        key: 'Comments'
                      },
                      {
                        component: (
                          <HistoryThread
                            data={{
                              reservationId: reservation.id
                            }}
                          />
                        ),
                        key: 'History'
                      }
                    ],
                    defaultTab: 'Comments'
                  }}
                  style={{ position: TabsPosition.LEFT }}
                  eventHandlers={{
                    tabChangeHandler: (tabKeyValue) => {
                      setTabKey(tabKeyValue);
                    }
                  }}
                />
              </div>
              <div>
                <ManagerInfo
                  data={{
                    propertyManagersList: reservation.propertyManagersList || [],
                    reservationId: reservation?.id || '',
                    title: 'Property Managers Assigned'
                  }}
                  eventHandlers={{
                    addPropertyManager: (show) => addPropertyManagement(show),
                    deletePropertyManager: (id: string) => deletePropertyManager(id)
                  }}
                />
              </div>
            </div>
          </div>

          <AddCommentModal
            config={{
              showModal: showAddCommentModal,
              showSkipEmail:
                reservationStatus === ReservationStatus.CHECKED_IN ||
                reservationStatus === ReservationStatus.IN_HOUSE
            }}
            eventHandlers={{
              handelChange: (value) => {
                setCommentValue(value);
              },
              handelSkipEmail: (value) => {
                setSkipEmail(value);
              },
              closeHandler: () => {
                setShowAddCommentModal(false);
              },
              ChangeStatusWithComment: () => {
                setShowAddCommentModal(false);
                updateOneReservations({
                  id: reservation.id,
                  unitTypeId: reservation.unitTypeId,
                  bookingRequestId: reservation.bookingRequestId,
                  status: reservationStatus ? reservationStatus : reservation.status,
                  comment: commentValue ? commentValue : '',
                  skipEmail: skipEmail ? skipEmail : false
                }).then(() =>
                  getOneReservation(reservation.id).then(() => setShowAddGuestModal(false))
                );
              }
            }}
          />
          <SelectAvailableRoomModal
            config={{ showModal: showReactivateModal }}
            data={{ availableRooms: bookoffer?.availableRooms || [] }}
            eventHandlers={{
              submitRoomId: (value) => {
                reactivateOneReservations(reservation.id, value).then(() =>
                  getOneReservation(reservation.id).then(() => setShowReactivateModal(false))
                );
              },
              closeHandler: () => {
                setShowReactivateModal(false);
              }
            }}
          />
        </>
      )}
      {showAssignManagerModal && (
        <AssignManagerModal
          config={{ showModal: showAssignManagerModal }}
          eventHandlers={{
            closeHandler: () => {
              setShowAssignManagerModal(false);
            },
            chooseUser: (user: PropertyUiManagersListModel) => {
              handleAddPropertyManager(user?.id);
              setShowAssignManagerModal(false);
            }
          }}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps)(ReservationsEditContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken,
    permissions: state.auth.permissions
  };
}
function getPaymentStatus(paymentList: PaymentModel[]): PaymentStatus {
  const isOverDue = paymentList.find((payment) => payment.status === PaymentStatus.OVERDUE);
  return isOverDue ? PaymentStatus.OVERDUE : PaymentStatus.COLLECTED;
}
function getReservationStatusColor(value: ReservationStatus) {
  const colorObj = {
    backgroundColor: '',
    color: ''
  };
  if (value === ReservationStatus.CONFIRMATION_PENDING) {
    colorObj.backgroundColor = '#F5F7FC';
    colorObj.color = '#F38831';
  }
  if (value === ReservationStatus.CHECKED_IN || value === ReservationStatus.IN_HOUSE) {
    colorObj.backgroundColor = '#c8ffe4';
    colorObj.color = '#008000';
  }
  if (value === ReservationStatus.CHECKED_OUT) {
    colorObj.backgroundColor = '#f5f7fc';
    colorObj.color = '#a6a6a6';
  }
  if (value === ReservationStatus.CHECKED_IN_STARTED) {
    colorObj.backgroundColor = '#ffe1c9';
    colorObj.color = '#f38831';
  }
  if (value === ReservationStatus.PENDING_CHECK_IN) {
    colorObj.backgroundColor = '#f5f7fc';
    colorObj.color = '#a6a6a6';
  }
  if (value === ReservationStatus.CANCELED) {
    colorObj.backgroundColor = '#ffacac';
    colorObj.color = '#ff0000';
  }
  if (value === ReservationStatus.EXPIRED) {
    colorObj.backgroundColor = '#e5e5e5';
    colorObj.color = '#ff0000';
  }
  return colorObj;
}
function getPaymentStatusColor(value: PaymentStatus) {
  const colorObj = {
    backgroundColor: '',
    color: ''
  };
  if (value === PaymentStatus.OVERDUE) {
    colorObj.backgroundColor = '#FFACAC';
    colorObj.color = '#E00707';
  }
  if (value === PaymentStatus.COLLECTED) {
    colorObj.backgroundColor = '#C8FFE4';
    colorObj.color = '#06A458';
  }
  if (value === PaymentStatus.PENDING) {
    colorObj.backgroundColor = '#F5F7FC';
    colorObj.color = '#000000';
  }
  if (value === PaymentStatus.CANCELED) {
    colorObj.backgroundColor = '#F5F7FC';
    colorObj.color = '#FF0000';
  }

  return colorObj;
}
const dropdownItemStatus: {
  [key: string]: {
    label: string;
    value: ReservationStatus;
    isDisabled?: boolean;
  }[];
} = {
  [ReservationStatus.CONFIRMATION_PENDING as string]: [
    {
      label: 'confirmation pending',
      value: ReservationStatus.CONFIRMATION_PENDING,
      isDisabled: true
    },
    { label: 'Check-in started', value: ReservationStatus.CHECKED_IN_STARTED },
    { label: 'Canceled', value: ReservationStatus.CANCELED }
  ],
  [ReservationStatus.PENDING_CHECK_IN as string]: [
    {
      label: 'Pending Check-in',
      value: ReservationStatus.PENDING_CHECK_IN,
      isDisabled: true
    },
    { label: 'Check-in started', value: ReservationStatus.CHECKED_IN_STARTED },
    { label: 'Canceled', value: ReservationStatus.CANCELED }
  ],
  [ReservationStatus.CHECKED_IN_STARTED as string]: [
    {
      label: 'Check-in started',
      value: ReservationStatus.CHECKED_IN_STARTED,
      isDisabled: true
    },
    { label: 'Check in', value: ReservationStatus.CHECKED_IN },
    { label: 'Canceled', value: ReservationStatus.CANCELED }
  ],
  [ReservationStatus.CHECKED_IN as string]: [
    { label: 'Check in', value: ReservationStatus.CHECKED_IN, isDisabled: true },

    { label: 'Check out', value: ReservationStatus.CHECKED_OUT },
    { label: 'Canceled', value: ReservationStatus.CANCELED }
  ],
  [ReservationStatus.IN_HOUSE as string]: [
    { label: 'In House', value: ReservationStatus.IN_HOUSE, isDisabled: true },

    { label: 'Check out', value: ReservationStatus.CHECKED_OUT },
    { label: 'Canceled', value: ReservationStatus.CANCELED }
  ],
  [ReservationStatus.CHECKED_OUT as string]: [
    {
      label: 'Check out',
      value: ReservationStatus.CHECKED_OUT,
      isDisabled: true
    },
    { label: 'Canceled', value: ReservationStatus.CANCELED }
  ],
  [ReservationStatus.CANCELED as string]: [
    {
      label: 'Canceled',
      value: ReservationStatus.CANCELED,
      isDisabled: true
    }
  ],
  [ReservationStatus.EXPIRED as string]: [
    {
      label: 'Expired',
      value: ReservationStatus.EXPIRED,
      isDisabled: true
    }
  ]
};
