import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { APP_ROUTES } from '../shared/config/routes-config';
import { RouteConfig } from '../shared/interfaces/routes-config.interface';
import { BlockDatesListContainer } from './containers/block-dates-list/block-dates-list.container';
/**
 * function BlockDatesModule components
 */
export const BlockDatesModule: React.FC = () => {
  return (
    <>
      <Route exact path={APP_ROUTES.BLOCK_DATES_MODULE.FULL_PATH}>
        <Redirect to={(APP_ROUTES.BLOCK_DATES_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH} />
      </Route>
      <Route
        path={(APP_ROUTES.BLOCK_DATES_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH}
        component={BlockDatesListContainer}
      />
    </>
  );
};
