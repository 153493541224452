import { useState, useEffect, useRef } from 'react';
import { KPIChartsSearch, OccupancyAndAverageDailyRate } from '../../../shared/models/kpis.model';
import { getOccupancyAndAverageDailyRate } from './data.service';
import { isErrorInfo } from '../../../shared/interfaces/error-info.interface';

export function useOccupancyAndAverageDailyRateData(monthsCount: number, payload: KPIChartsSearch) {
  const [data, setData] = useState<OccupancyAndAverageDailyRate[] | null>(null);


  useEffect(() => {
      setData(null);
      getOccupancyAndAverageDailyRate(monthsCount, payload)
        .then(responseData => {
          if(!isErrorInfo(responseData)){
            responseData.reverse();
            setData(responseData);
          }
        })
        .catch(error => console.error(error));
  }, [monthsCount, payload]);

  return data;
}