import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './add-image-modal.module.scss';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import { trackEvent } from '../../../../../shared/services/utils/tracking/tracking.service';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import ImageUploader from '../../../../../shared/components/core-ui/image-uploader/image-uploader.component';
import { ImageType, ImageListType } from 'react-images-uploading';

export interface AddImageModalProps {
  config: {
    showModal: boolean;
  };
  eventHandlers: {
    cancelHandler: () => void;
    submitImageHandler: (image: ImageListType) => void;
  };
}

/**
 * functional component AddImageModal
 * @param {AddImageModalProps}
 */
export const AddImageModal: React.FC<AddImageModalProps> = ({ config, eventHandlers }) => {
  const [newImage, setNewImage] = useState<FormElementData<ImageListType | null>>({
    value: null,
    isValid: false,
    changed: false
  });
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  const handleNewImageSubmit = () => {
    if (newImage.changed && newImage && newImage.value && newImage.value) {
      eventHandlers.submitImageHandler(newImage.value);
      setNewImage({ value: null, isValid: false, changed: false });
    }
  };

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.cancelHandler();
        }
      }}
    >
      <div className={styles['image-modal']}>
        <div className={styles['image-modal__title']}>
          {t('UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.ADD_SHOWROOM_IMAGE_MODAL.TITLE')}
        </div>

        <div className={styles['image-modal__description']}>
          {t('UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.ADD_SHOWROOM_IMAGE_MODAL.IMAGE')}
        </div>
        <ImageUploader
          config={{
            multiple: true,
            enableCrop: false,
            canvasSize: 'lg'
            // canvasSize: 'lg',
            // cropAspectRatio: { width: 10, height: 7 }
          }}
          data={{
            image: newImage.value
          }}
          eventHandlers={{
            fileUploadHandler: (file, width, height) => {
              trackEvent('image-sm-uploaded', {
                category: 'image-image-upload',
                label: 'add-unit-type-image'
              });
              setNewImage({ value: file || null, changed: true, isValid: true });
            },
            imageRemovedHandler: () => {
              setNewImage({
                value: null,
                isValid: false,
                changed: false
              });
            }
          }}
        />
      </div>
      <div className={styles['image-modal--actions']}>
        <Button
          onClick={() => {
            eventHandlers.cancelHandler();
            trackEvent('cancel', {
              category: 'image-cancel',
              label: 'add-unit-type-image'
            });
          }}
          tracking={{
            action: 'cancel',
            category: 'image-submit',
            label: 'add-unit-type-image'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          type={ButtonType.primaryInverted}
        >
          {t('UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.ADD_SHOWROOM_IMAGE_MODAL.CANCEL_BUTTON')}
        </Button>
        <Button
          onClick={handleNewImageSubmit}
          tracking={{
            action: 'submit',
            category: 'image-submit',
            label: 'add-unit-type-image'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          {t('UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.ADD_SHOWROOM_IMAGE_MODAL.SUBMIT_BUTTON')}
        </Button>
      </div>
    </Modal>
  );
};
