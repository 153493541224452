import React, { useState } from 'react';
import styles from './choose-appt.module.scss';

import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { Calendar } from '../../../../../shared/components/widgets/calendar/calendar.component';
import Button, {
  ButtonSize
} from '../../../../../shared/components/core-ui/button/button.component';
import { CustomPriceModel } from '../../../../../shared/ui-models/unit-type.ui-model';

export interface ChooseApptProps {
  data: {
    disbaleDate: Date[];
    basePrice?: number;
    customPrices?: CustomPriceModel[];
  };
  eventHandlers: {
    setDates: (unitType: Date | Date[]) => void;
    proceedApptNextStep: (next: boolean) => void;
    cancelHandler: () => void;
  };
}

/**
 * functional component  ChooseAppt
 * @param { ChooseApptProps}
 */
export const ChooseAppt: React.FC<ChooseApptProps> = ({ data, eventHandlers }) => {
  const isMobileView = useWindowResizer();
  const [selectedDates, setSelectedDates] = useState<Date | Date[]>();

  return (
    <div className={styles['choose-appt']}>
      <Calendar
        data={{
          calenderView: true,
          disbaleDate: data.disbaleDate,
          showDateInput: false,
          basePriceTag: data.basePrice,
          customPriceTags: data.customPrices
        }}
        eventHandlers={{
          onChange: (val) => {
            eventHandlers.setDates(val);
            setSelectedDates(val);
          }
        }}
      />
      <div className={styles['choose-appt--actions']}>
        <Button
          onClick={() => {
            eventHandlers.cancelHandler();
          }}
          tracking={{
            action: 'close',
            category: 'appt',
            label: 'choose-appt'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          Cancel
        </Button>
        <Button
          disabled={!Array.isArray(selectedDates) || !selectedDates.length}
          onClick={() => eventHandlers.proceedApptNextStep(true)}
          tracking={{
            action: 'submit',
            category: 'appt',
            label: 'choose-appt'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          proceed
        </Button>
      </div>
    </div>
  );
};
