import React, { useEffect, useState } from 'react';
import { Button as MIButton } from '@material-ui/core';
import { connect } from 'react-redux';
import styles from './block-dates-list.module.scss';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircle';
import { ErrorInfo, isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { RootState } from '../../../shared/redux/reducers';
import { trackPage } from '../../../shared/services/utils/tracking/tracking.service';
import Button, { ButtonSize } from '../../../shared/components/core-ui/button/button.component';
import { getAllBlockingDates, updateBlockDate } from '../../shared/services/data.service';
import { BlockedDatesUiModel } from '../../../shared/ui-models/blocked-dates.ui-model';
import { Tabs, TabsPosition } from '../../../shared/components/group-ui/tabs/tabs.component';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { ConfirmModal } from './components/confirm-modal/confirm-modal.component';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
    }
  }
});
const useSelectedRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
      // backgroundColor: '#ffa358'
    }
  }
});

export type BlockDatesListContainerProps = ReturnType<typeof mapStateToProps>;

/**
 * functional component BlockDatesListContainer
 * @param {BlockDatesListContainerProps} holding question text
 */
export const BlockDatesListContainer: React.FC<BlockDatesListContainerProps> = ({
  isAuthenticated
}) => {
  const [blockDates, setBlockDates] = useState<BlockedDatesUiModel[]>([]);

  const [currentBlockedDate, setCurrentBlockedDate] = useState<BlockedDatesUiModel>();
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [currentTab, setCurrentTab] = useState<string>('');
  const [confirmModalMessage, setConfirmModalMessage] = useState<string>('');

  useEffect(() => {
    trackPage('block-dates-list');
    // eslint-disable-next-line react-hooks/exhaustive-deps

    getBlockingDates({ query: { isCancelled: false, toDate: true } });
  }, []);

  const getBlockingDates = (query: {}) => {
    getAllBlockingDates(query).then((response: BlockedDatesUiModel[] | ErrorInfo) => {
      // setLoadingData(false);
      if (!isErrorInfo(response)) {
        setBlockDates(response);
      }
    });
  };

  function Row(props: { blockDate: BlockedDatesUiModel }) {
    const { blockDate } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const selectedClasses = useSelectedRowStyles();

    return (
      <>
        <TableRow className={classes.root}>
          <TableCell>
            <div
              className={
                styles['reservation-list-container__reservation-item__reservation-card__actions']
              }
            >
              <MIButton
                aria-controls="property-menu"
                aria-haspopup="true"
                onClick={() => {
                  setShowConfirmModal(true);
                  setCurrentBlockedDate(blockDate);
                  if (blockDate.isCancelled) {
                    setConfirmModalMessage('Are you sure you want to add again this blocked date?');
                  } else {
                    setConfirmModalMessage('Are you sure you want to cancel this blocked date?');
                  }
                }}
              >
                {blockDate.isCancelled ? (
                  <AddIcon fontSize={'large'} color="inherit" />
                ) : (
                  <DeleteIcon fontSize={'large'} color="inherit" />
                )}
              </MIButton>
            </div>
          </TableCell>
          <TableCell component="th" scope="row">
            {blockDate.id}
          </TableCell>
          <TableCell>{blockDate.roomName}</TableCell>
          <TableCell>{blockDate.description}</TableCell>
          <TableCell>{new Date(blockDate.fromDate).toDateString()}</TableCell>
          <TableCell>{new Date(blockDate.toDate).toDateString()}</TableCell>
          <TableCell>
            <a
              target="_blank"
              href={`${process.env.PUBLIC_URL}/unit-types/edit/${blockDate.unitTypeId}`}
            >
              {blockDate.unitName}
            </a>
          </TableCell>
          {/* <TableCell>{blockDate.area}</TableCell>
          <TableCell>{blockDate.propertyName}</TableCell> */}
          <TableCell>{blockDate.roomId}</TableCell>
          {blockDate.isCancelled ?
            <TableCell>{new Date(blockDate.updatedAt).toDateString()}</TableCell>
            :
            <TableCell>{new Date(blockDate.createdAt).toDateString()}</TableCell>
          }
          {blockDate.isCancelled ?
            <TableCell>{blockDate.modifiedByUsername}</TableCell>
            :
            <TableCell>{blockDate.createdByUsername}</TableCell>
          }
        </TableRow>
      </>
    );
  }
  return (
    <div className={styles['reservation-list-container']}>
      <div className={styles['reservation-list-container__title']}>
        <span>Block Dates</span>
      </div>

      <Tabs
        data={{
          tabsList: [
            { component: <></>, key: 'Active' },
            { component: <></>, key: 'Cancelled' }
          ],
          defaultTab: 'Active'
        }}
        style={{ position: TabsPosition.LEFT }}
        eventHandlers={{
          tabChangeHandler: (tabKey) => {
            // if (tabKey === 'All') {
            //   setCurrentTab('all');
            //   getBlockingDates({ query: {} });
            // } else 
            if (tabKey === 'Active') {
              setCurrentTab('active');
              getBlockingDates({ query: { isCancelled: false, toDate: true } });
            } else if (tabKey === 'Cancelled') {
              setCurrentTab('cancelled');
              getBlockingDates({ query: { isCancelled: true } });
            }
            console.log(tabKey);
          }
        }}
      />
      <TableContainer component={Paper}>
        <Table aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Id #</TableCell>
              <TableCell>Room Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Check In</TableCell>
              <TableCell>Check Out</TableCell>
              <TableCell>Unit</TableCell>
              {/* <TableCell>Area</TableCell>
              <TableCell>Project</TableCell> */}
              <TableCell>Room ID</TableCell>
              {currentTab != 'cancelled' ? <TableCell>Created At</TableCell> : <TableCell>Cancelled At</TableCell>}
              {currentTab != 'cancelled' ? <TableCell>Created By</TableCell> : <TableCell>Cancelled By</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {blockDates.map((blockDate) => (
              <Row key={blockDate.id} blockDate={blockDate} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ConfirmModal
        config={{ showModal: showConfirmModal, message: confirmModalMessage }}
        eventHandlers={{
          closeHandler: () => {
            setShowConfirmModal(false);
          },
          confirmAction: () => {
            setShowConfirmModal(false);
            updateBlockDate(currentBlockedDate.id, {
              isCancelled: !currentBlockedDate.isCancelled,
              unitTypeId: currentBlockedDate.unitTypeId
            }).then((response) => {
              getBlockingDates({ query: { isCancelled: currentBlockedDate.isCancelled } });
            });
          }
        }}
      />
    </div>
  );
};

export default connect(mapStateToProps)(BlockDatesListContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}
