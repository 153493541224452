/**
 * UserRole enum to define survey types
 */
export enum UserRole {
  ADMIN = 'admin',
  SUPER_ADMIN = 'superAdmin',
  PROPERTY_MANAGER = 'propertyManager',
  E_COMMERCE = 'eCommerce',
  RESERVATION_AGENT = 'reservationAgent',
  RESERVATION_MANAGER = 'reservationManager',
  FINANCE_MANAGER = 'financeManager',
  LANDLORD = 'landlord',
  PARTNER_MANAGER = 'partnerManager',
  HOUSE_KEEPING_MANAGER = 'houseKeepingManager'
}
