import { ReservationDetailsUiModel } from '../ui-models/reservation.ui-model';
import { ReservationDetailsModel } from '../models/reservation-details.model';

function mapToUI(reservationData: ReservationDetailsModel): ReservationDetailsUiModel {
  return {
    id: reservationData.id,
    userId: reservationData.userId,
    checkInDate: reservationData.checkInDate,
    checkOutDate: reservationData.checkOutDate,
    status: reservationData.status,
    primaryGuestName: reservationData.primaryGuestName,
    unitTypeId: reservationData.unitTypeId,
    paymentMethod: reservationData.defaultPaymentMethod,
    roomId: reservationData.roomId,
    propertyName: reservationData.propertyName,
    propertyId: reservationData.propertyId,
    unitName: reservationData.unitName,
    area: reservationData.area,
    guestIds: reservationData.guestIds,
    paymentIds: reservationData.paymentIds,
    bookingMode: reservationData.bookingMode,
    bookingRequestId: reservationData.bookingRequestId,
    checkinToken: reservationData.checkinToken,
    passKey: reservationData.passKey || '',
    checkinExpiresAt: reservationData.checkinExpiresAt,
    reservationUrl: reservationData.reservationUrl,
    paymentList: reservationData.paymentList,
    channel: reservationData.channel,
    source: reservationData.source,
    unitTypeGroupId: reservationData.unitTypeGroupId,
    channelCommission: reservationData.channelCommission,
    landlordId: reservationData.landlordId,
    landlordUsername: reservationData.landlordUsername,
    landlordEmail: reservationData.landlordEmail,
    landlordPhone: reservationData.landlordPhone,
    landlordShare: reservationData.landlordShare,
    propertyManagersList: reservationData.propertyManagersList,
    messages:
      reservationData.messages && reservationData.messages.length > 0
        ? reservationData.messages.map((message) => ({
            id: message.id,
            email: message.email,
            message: message.message,
            createdAt: new Date(message.createdAt),
            name: message.name
          }))
        : []
  };
}

export default {
  mapToUI
};
