import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './select-available-room.module.scss';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import { trackEvent } from '../../../../../shared/services/utils/tracking/tracking.service';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import FormControl from '@material-ui/core/FormControl';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';

export interface SelectAvailableRoomProps {
  config: {
    showModal: boolean;
  };
  data: {
    availableRooms: { unitId: string; shortName: string }[];
  };
  eventHandlers: {
    cancelHandler: () => void;
    submitRoomSelected: (roomId: string) => void;
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),

      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  })
);

/**
 * functional component SelectAvailableRoom
 * @param {SelectAvailableRoomProps}
 */
export const SelectAvailableRoom: React.FC<SelectAvailableRoomProps> = ({
  config,
  data,
  eventHandlers
}) => {
  const [selectedRoomId, setSelectedRoomId] = useState<FormElementData<string>>({
    value: '',
    isValid: false,
    changed: false
  });

  const classes = useStyles();
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  const handleSubmitRoomSelected = () => {
    eventHandlers.submitRoomSelected(selectedRoomId.value);
    setSelectedRoomId({ value: '', isValid: false, changed: false });
  };

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.cancelHandler();
        }
      }}
    >
      <div className={styles['select-room']}>
        <div className={styles['select-room__title']}>
          {t('UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.ADD_SHOWROOM_IMAGE_MODAL.TITLE')}
        </div>
        <div className={styles['select-room__description']}>
          {t('UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.ADD_SHOWROOM_IMAGE_MODAL.NAME')}
        </div>
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={selectedRoomId.value}
            onChange={(
              event: React.ChangeEvent<{
                name?: string | undefined;
                value: unknown;
              }>
            ) => {
              setSelectedRoomId({
                value: event.target.value as string,
                isValid: true,
                changed: true
              });
            }}
          >
            <MenuItem value={''}>Select Room</MenuItem>
            {data.availableRooms.map((item) => (
              <MenuItem value={item.unitId}>{item.shortName}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={styles['select-room--actions']}>
        <Button
          onClick={() => {
            eventHandlers.cancelHandler();
            trackEvent('cancel', {
              category: 'cancel',
              label: 'select-available-room'
            });
          }}
          tracking={{
            action: 'cancel',
            category: 'showroom-submit',
            label: 'select-available-room'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          type={ButtonType.primaryInverted}
        >
          Cancel
        </Button>
        <Button
          disabled={!selectedRoomId.isValid || selectedRoomId.value === ''}
          onClick={handleSubmitRoomSelected}
          tracking={{
            action: 'submit',
            category: 'submit',
            label: 'select-available-room'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          Assign Room and Accept
        </Button>
      </div>
    </Modal>
  );
};
