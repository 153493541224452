import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './edit-property.module.scss';
import { store } from 'react-notifications-component';
import { ErrorInfo, isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { RootState } from '../../../shared/redux/reducers';
import { trackPage } from '../../../shared/services/utils/tracking/tracking.service';
import { PropertyUiModel } from '../../../shared/ui-models/property.ui-model';
import {
  getPropertyDetails,
  getPropertyUnitTypes,
  getUnassignedUnitTypes,
  updatePropertyData,
  uploadPropertyCoverImage,
  addPropertyManager,
  deletePropertyManager
} from '../../shared/services/data.service';
import { RouteComponentProps } from 'react-router';
import EditGeneralInfo from './components/edit-general-info/edit-general-info.container';
import EditVisuals from './components/edit-visuals/edit-visuals.container';
import { ImageType } from 'react-images-uploading';
import AssignUnitTypes from './components/assign-unit-types/assign-unit-types.container';
import { UnitTypeUiModel } from '../../../shared/ui-models/unit-type.ui-model';
import { getAllAmenities } from '../../../shared/services/data/lookups.data';
import { getModuleName } from '../../shared/services/property.service';
import { AmenityUiModel } from '../../../shared/ui-models/amenity.ui-model';
import SelectAmenities from './components/select-amenities/select-amenities.container';
import { FormElementData } from '../../../shared/interfaces/form-element-data.interface';
// import SelectAmenities from './components/select-amenities/select-amenities.container';
import GoogleMapReact from 'google-map-react';
import Autocomplete from 'react-google-autocomplete';
import {
  InputText,
  InputTextType
} from '../../../shared/components/core-ui/input-text/input-text.component';
import Button, { ButtonSize } from '../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../shared/hooks/window-resizer/window-resizer.hook';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { ManagerInfo } from './components/manager-info/manager-info.container';
import { AssignManagerModal } from './components/assign-Manager-modal/assign-manager-modal.component';
import { PropertyUiManagersListModel } from '../../../shared/ui-models/propertyManager.ui.model';

interface EditPropertyRouteParams {
  id: string;
}

export type EditPropertyProps = ReturnType<typeof mapStateToProps> &
  RouteComponentProps<EditPropertyRouteParams>;

/**
 * functional component EditProperty
 * @param {EditPropertyProps} holding question text
 */
export const EditProperty: React.FC<EditPropertyProps> = ({ match, isAuthenticated }) => {
  const [property, setProperty] = useState<PropertyUiModel>();
  const [propertyUnitTypes, setPropertyUnitTypes] = useState<UnitTypeUiModel[]>();
  const [unassignedUnitTypes, setUnassignedUnitTypes] = useState<UnitTypeUiModel[]>();
  const [loadingPropertyUnitTypes, setLoadingPropertyUnitTypes] = useState<boolean>(false);
  const [loadingUnassignedUnitTypes, setLoadingUnassignedUnitTypes] = useState<boolean>(false);
  const [loadingAmenities, setLoadingAmenities] = useState<boolean>(false);
  const [amenitiesList, setAmenitiesList] = useState<AmenityUiModel[]>();
  const [map, setMap] = useState<any>();
  const [maps, setMaps] = useState<any>();
  const [markers, setMarkers] = useState<any>([]);
  const [postion, setPostion] = useState<{ lat: number; lng: number }>({
    lat: 30.0444,
    lng: 31.2357
  });
  const [newAddress, setNewAddress] = useState<FormElementData<string>>({
    value: '',
    isValid: false,
    changed: false
  });
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();
  const [isCopy, setIsCopy] = useState(false);
  const [showAssignManagerModal, setShowAssignManagerModal] = useState<boolean>(false);

  useEffect(() => {
    trackPage('edit-property');
    const { id: propertyId } = match.params;
    getPropertyDetails(propertyId).then((response: PropertyUiModel | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setProperty(response);
        fetchUnitTypesData(response);
        fetchAmenitiesData();
        if (response.location) {
          setNewAddress({ value: response.location.address, isValid: true, changed: false });
          setPostion({
            lng: Number(response.location.longitude),
            lat: Number(response.location.latitude)
          });
        }
      }
    });
    return () => {
      store.removeNotification('edit-property-failed');
      store.removeNotification('edit-property-success');
      store.removeNotification('delete-property-failed');
      store.removeNotification('delete-property-success');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addPropertyManagement = (show: boolean) => {
    setShowAssignManagerModal(show);
  };

  const fetchAmenitiesData = () => {
    setLoadingAmenities(true);
    getAllAmenities(getModuleName()).then((response: AmenityUiModel[] | ErrorInfo) => {
      setLoadingAmenities(false);
      if (!isErrorInfo(response)) {
        setAmenitiesList(response);
      }
    });
  };

  const fetchUnitTypesData = (property: PropertyUiModel) => {
    setLoadingUnassignedUnitTypes(true);
    getUnassignedUnitTypes().then((unitTypesResponse: UnitTypeUiModel[] | ErrorInfo) => {
      setLoadingUnassignedUnitTypes(false);
      if (!isErrorInfo(unitTypesResponse)) {
        setUnassignedUnitTypes(unitTypesResponse);
      }
    });
    if (property.id && property.unitTypeIds && property.unitTypeIds.length > 0) {
      setLoadingPropertyUnitTypes(true);
      getPropertyUnitTypes(property.id).then((unitTypesResponse: UnitTypeUiModel[] | ErrorInfo) => {
        setLoadingPropertyUnitTypes(false);
        if (!isErrorInfo(unitTypesResponse)) {
          setPropertyUnitTypes(unitTypesResponse);
        }
      });
    } else {
      setPropertyUnitTypes([]);
    }
  };

  const handleUpdateProperty = (data: Partial<PropertyUiModel>) => {
    if (property && property.id) {
      updatePropertyData(property.id, data).then((response: PropertyUiModel | ErrorInfo) => {
        if (!isErrorInfo(response)) {
          store.addNotification({
            id: 'edit-property-success',
            title: t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_PROPERTY.TITLE'),
            message: t(
              'PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_PROPERTY.MESSAGE'
            ),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          setProperty(response);

          if (data.unitTypeIds) {
            fetchUnitTypesData(response);
          }
        } else {
          store.addNotification({
            id: 'edit-property-failed',
            title: t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_FAILED.TITLE'),
            message: t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_FAILED.TITLE'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        }
      });
    }
  };

  const uploadCoverImageHandler = (size: 'sm' | 'md' | 'lg', coverImage: ImageType) => {
    if (property && property.id && coverImage && coverImage.file) {
      uploadPropertyCoverImage(size, coverImage.file, property.id).then(
        (response: PropertyUiModel | ErrorInfo) => {
          if (!isErrorInfo(response)) {
            store.addNotification({
              id: 'edit-property-success',
              title: t(
                'PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_PROPERTY.TITLE'
              ),
              message: t(
                'PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_PROPERTY.MESSAGE'
              ),
              type: 'success',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut'],
              dismiss: {
                duration: 3000,
                onScreen: true
              }
            });
            setProperty(response);
          } else {
            store.addNotification({
              id: 'edit-property-failed',
              title: t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_FAILED.TITLE'),
              message: t(
                'PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_FAILED.TITLE'
              ),
              type: 'danger',
              insert: 'top',
              container: 'top-right',
              animationIn: ['animate__animated', 'animate__fadeIn'],
              animationOut: ['animate__animated', 'animate__fadeOut']
            });
          }
        }
      );
    }
  };
  const handleAddPropertyManager = (id: string) => {
    console.log(id, property?.id || '');
    addPropertyManager(id, property?.id || '').then((response: PropertyUiModel | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        store.addNotification({
          id: 'add-property-manager-success',
          title: t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_PROPERTY.TITLE'),
          message: t(
            'PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_PROPERTY.MESSAGE'
          ),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        setProperty({ ...property, propertyManagersList: response.propertyManagersList });
      } else {
        store.addNotification({
          id: 'edit-property-manager-failed',
          title: t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_FAILED.TITLE'),
          message: t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_FAILED.TITLE'),
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut']
        });
      }
    });
  };
  const handleDeletePropertyManager = (id: string) => {
    console.log(id, property?.id || '');
    deletePropertyManager(id, property?.id || '').then((response: PropertyUiModel | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        store.addNotification({
          id: 'add-property-manager-success',
          title: t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_PROPERTY.TITLE'),
          message: t(
            'PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_PROPERTY.MESSAGE'
          ),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        setProperty({ ...property, propertyManagersList: response.propertyManagersList });
      } else {
        store.addNotification({
          id: 'edit-property-manager-failed',
          title: t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_FAILED.TITLE'),
          message: t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.NOTIFICATIONS.UPDATE_FAILED.TITLE'),
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut']
        });
      }
    });
  };
  return (
    <div className={styles['edit-property-container']}>
      <h1>{t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.TITLE')}</h1>

      <div className={styles['edit-property-container__copy-container']}>
        <a href={`https://${property?.urlLink}`} target="_blank" rel="noopener noreferrer">
          {`https://${property?.urlLink}`}
        </a>
        <button
          className={styles['edit-property-container__copy-container-copy']}
          onClick={() => {
            navigator.clipboard.writeText(`${property?.urlLink}`), setIsCopy(!isCopy);
          }}
        >
          <span>
            <FileCopyIcon />
            {isCopy && (
              <span className={styles['edit-property-container__copy-container-talkbubble']}>
                {isCopy ? 'copied' : 'copy'}
              </span>
            )}
          </span>
        </button>
      </div>
      <div className={styles['edit-property-container__wrapper']}>
        <div className={styles['edit-property-container__wrapper__row']}>
          <div className={styles['edit-property-container__wrapper__row__item']}>
            <EditGeneralInfo
              data={{
                propertyName: property?.name || '',
                propertyDescription: property?.description || '',
                areaName: property?.areaName || '',
                areaId: property?.areaId || '',
                gender: property?.gender
              }}
              eventHandlers={{
                submitHandler: (updatedData) => {
                  handleUpdateProperty(updatedData);
                }
              }}
            />
          </div>
          <div className={styles['edit-property-container__wrapper__row__item']}>
            <div>
              <EditVisuals
                data={{
                  coverImage: property?.coverImage
                }}
                eventHandlers={{
                  submitCoverImageHandler: uploadCoverImageHandler
                }}
              />
            </div>
            <div>
              <ManagerInfo
                data={{
                  propertyManagersList: property?.propertyManagersList || [],
                  propertyId: property?.id || ''
                }}
                eventHandlers={{
                  addPropertyManager: (show) => addPropertyManagement(show),
                  deletePropertyManager: (id: string) => handleDeletePropertyManager(id)
                }}
              />
            </div>
          </div>
        </div>
        <SelectAmenities
          data={{
            amenitiesList: amenitiesList || [],
            selectedAmenities: property?.amenities || []
          }}
          config={{ isLoading: loadingAmenities }}
          eventHandlers={{
            selectAmenity: (amenity) => {
              if (property && property.amenities) {
                handleUpdateProperty({ amenities: [...property.amenities, amenity] });
              }
            },
            unselectAmenity: (amenityId) => {
              if (property && property.amenities) {
                const filteredAmenitiesList = property.amenities.filter(
                  (item) => item.id !== amenityId
                );
                handleUpdateProperty({
                  amenities: filteredAmenitiesList
                });
              }
            }
          }}
        />
        <AssignUnitTypes
          data={{
            propertyUnitTypes: propertyUnitTypes || [],
            unassignedUnitTypes: unassignedUnitTypes || []
          }}
          config={{ isLoading: loadingPropertyUnitTypes || loadingUnassignedUnitTypes }}
          eventHandlers={{
            assignUnitTypeHandler: (unitTypeId) => {
              if (property && property.unitTypeIds) {
                const unitTypesToUpdate = [...property.unitTypeIds];
                unitTypesToUpdate.push(unitTypeId);
                handleUpdateProperty({ unitTypeIds: unitTypesToUpdate });
              }
            },
            unAssignUnitTypeHandler: (unitTypeId) => {
              if (property && property.unitTypeIds && property.unitTypeIds.length > 0) {
                const unitTypesToUpdate = property.unitTypeIds.filter(
                  (item) => item !== unitTypeId
                );
                handleUpdateProperty({ unitTypeIds: unitTypesToUpdate });
              }
            }
          }}
        />
        <div className={styles['edit-property-container__location__header']}>
          {t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.LOCATION')}
        </div>
        {property && (
          <div className={styles['edit-property-container__location']}>
            <div className={styles['edit-property-container__field']}>
              <InputText
                config={{
                  autoFocus: false,
                  type: InputTextType.text,
                  // minLength: 6,
                  required: true
                }}
                data={{
                  placeholder: t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.ADDRESS_PLACEHOLDER'),
                  value: newAddress.value
                }}
                eventHandlers={{
                  onChangeHandler: (value, isValid) => {
                    setNewAddress({ value, isValid, changed: true });
                  }
                }}
              />
            </div>
            {maps && (
              <Autocomplete
                apiKey="AIzaSyDupSdcAt0KVY9z-TktpLai9N5mJpMM6T0"
                className={styles['edit-property-container__location__field']}
                onPlaceSelected={(place: any) => {
                  const lat = place.geometry.location.lat();
                  const lng = place.geometry.location.lng();
                  for (const marker of markers) {
                    marker.setMap(null);
                  }
                  const marker = new maps.Marker({
                    position: {
                      lat,
                      lng
                    },
                    map
                  });
                  setMarkers([...markers, marker]);
                  setPostion({ lat, lng });
                }}
                placeholder={'Search Location'}
                options={{
                  types: ['geocode', 'establishment']
                }}
              ></Autocomplete>
            )}
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyDupSdcAt0KVY9z-TktpLai9N5mJpMM6T0',
                libraries: 'places'
              }}
              defaultCenter={{
                lat: postion.lat,
                lng: postion.lng
              }}
              center={postion}
              onGoogleApiLoaded={({ map, maps }) => {
                setMap(map);
                setMaps(maps);
                const marker = new maps.Marker({
                  position: {
                    lat: postion.lat,
                    lng: postion.lng
                  },
                  map
                });
                setMarkers([...markers, marker]);
                return marker;
              }}
              onClick={(event) => {
                for (const marker of markers) {
                  marker.setMap(null);
                }
                const marker = new maps.Marker({
                  position: {
                    lat: event.lat,
                    lng: event.lng
                  },
                  map
                });
                setMarkers([...markers, marker]);
                setPostion({ lat: event.lat, lng: event.lng });
              }}
              defaultZoom={15}
            ></GoogleMapReact>
            <div className={styles['edit-property-container__actions']}>
              <Button
                onClick={() =>
                  handleUpdateProperty({
                    location: {
                      address: newAddress.value,
                      latitude: String(postion.lat),
                      longitude: String(postion.lng)
                    }
                  })
                }
                tracking={{
                  action: 'submit',
                  category: 'location',
                  label: 'edit-location'
                }}
                size={isMobileView ? ButtonSize.full : ButtonSize.medium}
              >
                {t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.UPDATE_GENERAL_INFO')}
              </Button>
            </div>
          </div>
        )}
        {showAssignManagerModal && (
          <AssignManagerModal
            config={{ showModal: showAssignManagerModal }}
            eventHandlers={{
              closeHandler: () => {
                setShowAssignManagerModal(false);
              },
              chooseUser: (user: PropertyUiManagersListModel) => {
                handleAddPropertyManager(user?.id);
                setShowAssignManagerModal(false);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(EditProperty);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}
