export const CM_FEES_CONFIG = [
  {
    id: '1',
    text: 'local tax'
  },
  {
    id: '2',
    text: 'VAT'
  },
  {
    id: '3',
    text: 'tourist tax'
  },
  {
    id: '4',
    text: 'goods and services tax'
  },
  {
    id: '5',
    text: 'city tax'
  },
  {
    id: '6',
    text: 'Towels fee'
  },
  {
    id: '7',
    text: 'Electricity fee'
  },
  {
    id: '8',
    text: 'Bed linen fee'
  },
  {
    id: '9',
    text: 'Gas fee'
  },
  {
    id: '10',
    text: 'Oil fee'
  },
  {
    id: '11',
    text: 'Wood fee'
  },
  {
    id: '12',
    text: 'Water usage fee'
  },
  {
    id: '13',
    text: 'Transfer fee'
  },
  {
    id: '14',
    text: 'Linen package fee'
  },
  {
    id: '15',
    text: 'Heating fee'
  },
  {
    id: '16',
    text: 'Air conditioning fee'
  },
  {
    id: '17',
    text: 'Kitchen linen fee'
  },
  {
    id: '18',
    text: 'Housekeeping fee'
  },
  {
    id: '19',
    text: 'Airport shuttle fee'
  },
  {
    id: '20',
    text: 'Shuttle boat fee'
  },
  {
    id: '21',
    text: 'Sea plane fee'
  },
  {
    id: '22',
    text: 'Ski pass'
  },
  {
    id: '23',
    text: 'Wristband fee'
  },
  {
    id: '24',
    text: 'Visa support fee'
  },
  {
    id: '25',
    text: 'Water park fee'
  },
  {
    id: '26',
    text: 'Club card fee'
  },
  {
    id: '27',
    text: 'Conservation fee'
  },
  {
    id: '28',
    text: 'Credit card fee'
  },
  {
    id: '29',
    text: 'Pet fee'
  },
  {
    id: '30',
    text: 'Internet fee'
  },
  {
    id: '0',
    text: 'unknown'
  },
  {
    id: '31',
    text: 'Parking fee'
  },
  {
    id: '32',
    text: 'Insurance'
  },
  {
    id: '33',
    text: 'Service fee'
  },
  {
    id: '34',
    text: 'Resort fee'
  },
  {
    id: '35',
    text: 'Booking fee'
  },
  {
    id: '36',
    text: 'Tourism fee'
  },
  {
    id: '37',
    text: 'Destination fee'
  },
  {
    id: '38',
    text: 'Extra bed fee'
  },
  {
    id: '39',
    text: 'Environment fee'
  },
  {
    id: '40',
    text: 'Children extra fee'
  },
  {
    id: '41',
    text: 'Cleaning fee'
  },
  {
    id: '42',
    text: 'Book Direct service fee'
  }
];
