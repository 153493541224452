import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './edit-unit-type-group-modal.module.scss';
import { VALIDATION_REGEX } from '../../../../../shared/components/validation.config';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import { trackEvent } from '../../../../../shared/services/utils/tracking/tracking.service';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import {
  InputText,
  InputTextErrorMode
} from '../../../../../shared/components/core-ui/input-text/input-text.component';
import { UnitTypeGroupUiModel } from '../../../../../shared/ui-models/unit-type-group.ui-model';

export interface EditUnitTypeGroupModalProps {
  data: {
    unitTypeGroup: UnitTypeGroupUiModel | undefined;
  };
  config: {
    showModal: boolean;
  };
  eventHandlers: {
    cancelHandler: () => void;
    editUnitTypeGroupHandler: (
      unitTypeGroupId: string,
      unitTypeGroup: UnitTypeGroupUiModel
    ) => void;
  };
}

/**
 * functional component EditUnitTypeGroupModal
 * @param {EditUnitTypeGroupModalProps}
 */
export const EditUnitTypeGroupModal: React.FC<EditUnitTypeGroupModalProps> = ({
  data,
  config,
  eventHandlers
}) => {
  const [newUnitTypeGroupName, setNewUnitTypeGroupName] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  useEffect(() => {
    setNewUnitTypeGroupName({ value: data?.unitTypeGroup?.name || '', isValid: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.unitTypeGroup]);

  const handleNewUnitTypeGroupSubmit = () => {
    if (data && data.unitTypeGroup && data.unitTypeGroup.id) {
      if (
        newUnitTypeGroupName.value !== '' &&
        newUnitTypeGroupName.isValid &&
        newUnitTypeGroupName.value !== data?.unitTypeGroup?.name
      ) {
        eventHandlers.editUnitTypeGroupHandler(data.unitTypeGroup.id, {
          ...data.unitTypeGroup,
          name: newUnitTypeGroupName.value
        });
      }
    }
  };

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.cancelHandler();
        }
      }}
    >
      <div className={styles['add-unitTypeGroup-modal']}>
        <div className={styles['add-unitTypeGroup-modal__title']}>
          {t('AREA_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.TITLE')}
        </div>
        <div className={styles['add-unitTypeGroup-modal__description']}>
          {t('AREA_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.DESCRIPTION')}
        </div>
        <InputText
          data={{
            value: newUnitTypeGroupName.value,
            errorMessage: t('AREA_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.INVALID_AREA_NAME'),
            placeholder: t('AREA_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.NAME_PLACEHOLDER')
          }}
          config={{
            maxRows: 1,
            autoFocus: true,
            validationRegex: VALIDATION_REGEX.AREA_NAME,
            showErrorMode: InputTextErrorMode.instant
          }}
          eventHandlers={{
            onChangeHandler: (value: string, isValid: boolean) => {
              setNewUnitTypeGroupName({ value, isValid });
            },
            onEnterPressedHandler: handleNewUnitTypeGroupSubmit
          }}
        />
      </div>
      <div className={styles['add-unitTypeGroup-modal--actions']}>
        <Button
          onClick={() => {
            eventHandlers.cancelHandler();
            trackEvent('cancel', {
              category: 'unitTypeGroup',
              label: 'edit-unitTypeGroup-modal'
            });
          }}
          tracking={{
            action: 'cancel',
            category: 'unitTypeGroup',
            label: 'edit-unitTypeGroup-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          type={ButtonType.primaryInverted}
        >
          {t('AREA_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.CANCEL_BUTTON')}
        </Button>
        <Button
          disabled={
            !newUnitTypeGroupName.isValid ||
            newUnitTypeGroupName.value === '' ||
            newUnitTypeGroupName.value === data?.unitTypeGroup?.name
          }
          onClick={handleNewUnitTypeGroupSubmit}
          tracking={{
            action: 'submit',
            category: 'unitTypeGroups',
            label: 'edit-unitTypeGroup-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          {t('AREA_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.SUBMIT_BUTTON')}
        </Button>
      </div>
    </Modal>
  );
};
