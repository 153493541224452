
import React, { useState } from 'react';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';

export const withVisibility = (WrappedComponent: React.FC<any>) => {
  return (props: any) => {
    const [isVisible, setIsVisible] = useState(true);

    const iconStyle = {position: 'absolute', zIndex:3, right:0, fontSize:'3rem'};
    const containerStyle = isVisible ? { transition: 'height 0.3s ease-in-out', height: 'auto' } : { transition: 'height 0.3s ease-in-out', height: '30px', overflow: 'hidden' };

    return (
      <div style={{position: 'relative'}}>
        {isVisible ? 
          <IndeterminateCheckBoxOutlinedIcon style={iconStyle} onClick={() => setIsVisible(!isVisible)}/> :
          <AddBoxOutlinedIcon style={iconStyle} onClick={() => setIsVisible(!isVisible)}/>
        }
        <div style={containerStyle}>
          <WrappedComponent {...props} />
        </div>
      </div>
    );
  };
};