import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { APP_ROUTES } from '../shared/config/routes-config';
import { RouteConfig } from '../shared/interfaces/routes-config.interface';
import UserSearchContainer from './containers/user-search/user-search.container';
/**
 * function UserModule components
 */
export const UserModule: React.FC = () => {
  return (
    <>
      <Route exact path={APP_ROUTES.USER_MODULE.FULL_PATH}>
        <Redirect to={(APP_ROUTES.USER_MODULE.CHILDREN as RouteConfig).SEARCH.FULL_PATH} />
      </Route>
      <Route
        path={(APP_ROUTES.USER_MODULE.CHILDREN as RouteConfig).SEARCH.FULL_PATH}
        component={UserSearchContainer}
      />
    </>
  );
};
