import React, { useState, useEffect } from 'react';
import styles from './switch-room-modal.module.scss';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import Select, { ValueType } from 'react-select';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { PaymentUIModel } from '../../../../../shared/ui-models/payment.ui-model';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import { PaymentStatus } from '../../../../../shared/enums/payment-status.enum';
import { PaymentCollector } from '../../../../../shared/enums/payment-collector.enum';
import { PaymentType } from '../../../../../shared/enums/payment-type.enum';
import { ReservationDetailsUiModel } from '../../../../../shared/ui-models/reservation.ui-model';
import { FeeType } from '../../../../../shared/enums/amount-type.enum';
import { getAllUnitTypes } from '../../../../../shared/services/data/unit-type.data';
import {
  UnitTypeUiModel,
  UnitTypeUiModelResponse
} from '../../../../../shared/ui-models/unit-type.ui-model';
import { ErrorInfo, isErrorInfo } from '../../../../../shared/interfaces/error-info.interface';
import { getModuleName } from '../../../../shared/services/reservation.service';

export interface SwitchRoomModalProps {
  config: {
    showModal: boolean;
  };
  data: {
    reservation: ReservationDetailsUiModel | undefined;
    switchRoomLoading: boolean;
  };
  eventHandlers: {
    closeHandler: () => void;
    switchRoomHandler: (unitTypeId: number, roomId: string) => void;
  };
}

/**
 * functional component SwitchRoomModal
 * @param {SwitchRoomModalProps}
 */
export const SwitchRoomModal: React.FC<SwitchRoomModalProps> = ({
  config,
  eventHandlers,
  data
}) => {
  const [newUnitTypeId, setNewUnitTypeId] = useState<FormElementData<number>>({
    value: 0,
    isValid: true
  });
  const [newRoomId, setNewRoomId] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });

  const [unitTypes, setUnitTypes] = useState<
    { unitTypeId: number; unitTypeName: string; roomName: string; roomId: string }[]
  >([]);

  const isMobileView = useWindowResizer();

  useEffect(() => {
    if (data.reservation && data.reservation.id) {
      getAllUnitTypes(getModuleName(), 0, -1, {}).then(
        (response: UnitTypeUiModelResponse | ErrorInfo) => {
          if (!isErrorInfo(response)) {
            const propertyUnitType = response.unitList.filter(
              (unitType) => unitType.propertyId === data.reservation?.propertyId
            );
            let unitTypesSelectList: {
              unitTypeId: number;
              unitTypeName: string;
              roomName: string;
              roomId: string;
            }[] = [];
            propertyUnitType?.forEach((unitType) => {
              if (unitType.units && unitType.units.length > 0) {
                const unitTypeMap = unitType.units.map((unit) => {
                  return {
                    unitTypeId: unitType.id || 0,
                    unitTypeName: unitType.name || '',
                    roomName: unit.shortName || '',
                    roomId: unit.unitId
                  };
                });
                unitTypesSelectList = [...unitTypesSelectList, ...unitTypeMap];
              }
            });
            setUnitTypes(unitTypesSelectList);
          }
        }
      );
    }
  }, [data.reservation]);

  const handleReservationRoomSWitch = () => {
    eventHandlers.switchRoomHandler(newUnitTypeId.value, newRoomId.value);
  };

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.closeHandler();
        }
      }}
    >
      <div className={styles['switch-room-modal']}>
        <div className={styles['switch-room-modal__title']}>Switch Reservation Room</div>
        <div className={styles['switch-room-modal__description']}>
          Destination Room
          {/* {unitTypes && unitTypes.length > 0 && ( */}
          <Select
            options={
              unitTypes &&
              unitTypes.map((unitType) => ({
                value: unitType.roomId,
                label: `${unitType.roomName} (${unitType.unitTypeId})`
              }))
            }
            defaultValue={{ label: 'Select Room', value: '' }}
            onChange={(value: ValueType<{ value: string; label: string }, false>) => {
              setNewRoomId({
                value: value ? (value.value as string) : '',
                isValid: true
              });
              setNewUnitTypeId({
                value: unitTypes.find((unitType) => unitType.roomId === (value?.value as string))
                  ?.unitTypeId,
                isValid: true
              });
            }}
          />
          {/* )} */}
        </div>
      </div>
      <div className={styles['switch-room-modal--actions']}>
        <Button
          onClick={() => {
            eventHandlers.closeHandler();
          }}
          type={ButtonType.primaryInverted}
          tracking={{
            action: 'close',
            category: 'reservation',
            label: 'switch-room-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          Cancel
        </Button>
        <Button
          loading={data.switchRoomLoading}
          disabled={
            !newRoomId.isValid ||
            !newUnitTypeId.isValid ||
            newRoomId.value === '' ||
            newUnitTypeId.value === 0
          }
          onClick={handleReservationRoomSWitch}
          tracking={{
            action: 'submit',
            category: 'reseravation',
            label: 'switch-room-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          Switch Rooms
        </Button>
      </div>
    </Modal>
  );
};
