import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './unit-types-list.module.scss';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import Tooltip from '@material-ui/core/Tooltip';
import { store } from 'react-notifications-component';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Select, { ValueType } from 'react-select';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import EditIcon from '@material-ui/icons/Edit';
import BathtubIcon from '@material-ui/icons/Bathtub';
import { Button as MIButton, Menu, MenuItem } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { orange } from '@material-ui/core/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import { ErrorInfo, isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { RootState } from '../../../shared/redux/reducers';
import { trackEvent, trackPage } from '../../../shared/services/utils/tracking/tracking.service';
import { AddUnitTypeModal } from './components/add-unit-type-modal/add-unit-type-modal.component';
import { DeleteUnitTypeModal } from './components/delete-unit-type-modal/delete-unit-type-modal.component';
import {
  UnitTypeUiModel,
  UnitTypeUiModelResponse,
  RoomsUiModelResponse
} from '../../../shared/ui-models/unit-type.ui-model';
import { APP_ROUTES, PUBLIC_PATH } from '../../../shared/config/routes-config';
import { useHistory } from 'react-router';
import { RouteConfig } from '../../../shared/interfaces/routes-config.interface';
import useWindowResizer from '../../../shared/hooks/window-resizer/window-resizer.hook';
import { getModuleName } from '../../shared/services/unit-type.service';
import {
  addNewUnitType,
  getAllUnitTypes,
  getUnitTypeRooms
} from '../../../shared/services/data/unit-type.data';
import { InputText } from '../../../shared/components/core-ui/input-text/input-text.component';
import { FormElementData } from '../../../shared/interfaces/form-element-data.interface';
import Button, { ButtonSize } from '../../../shared/components/core-ui/button/button.component';
import { withStyles } from '@material-ui/core/styles';
import StarBorderOutlined from '@material-ui/icons/StarBorderOutlined';
import Star from '@material-ui/icons/Star';
import { updateUnitTypeData } from '../../shared/services/data.service';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { ReservationMode } from '../../../shared/enums/reservation-mode.enum';
import WarningIcon from '@material-ui/icons/Warning';
import { BookingMode } from '../../../shared/enums/booking-mode.enum';
import { Pagination } from '../../../shared/components/core-ui/pagination/pagination.component';

const OrangeSwitch = withStyles({
  switchBase: {
    // color: orange[300],
    '&$checked': {
      color: orange[500]
    },
    '&$checked + $track': {
      backgroundColor: orange[500]
    }
  },
  checked: {},
  track: {}
})(Switch);

export type UnitTypesListContainerProps = ReturnType<typeof mapStateToProps>;

/**
 * functional component UnitTypesListContainer
 * @param {UnitTypesListContainerProps} holding question text
 */
export const UnitTypesListContainer: React.FC<UnitTypesListContainerProps> = ({
  isAuthenticated
}) => {
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showAssignUserModal, setShowAssignUserModal] = useState<boolean>(false);
  const [unitTypesList, setUnitTypesList] = useState<UnitTypeUiModel[]>([]);
  const [unitTypeToUpdate, setUnitTypeToUpdate] = useState<UnitTypeUiModel>([]);
  const [intialUnitTypesList, setIntialUnitTypesList] = useState<UnitTypeUiModel[]>([]);
  const [roomsOptions, setRoomsOptions] = useState<{ value: string; label: string }[]>([]);
  const [publishedOptions] = useState<{ value: boolean; label: string }[]>([
    {
      value: true,
      label: 'Published'
    },
    {
      value: false,
      label: 'Unpublished'
    }
  ]);
  const [featuredOptions] = useState<{ value: boolean; label: string }[]>([
    {
      value: true,
      label: 'Featured'
    },
    {
      value: false,
      label: 'Unfeatured'
    }
  ]);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [selectedUnitId, SetSelectedUnitId] = useState<string>('');
  const isMobileView = useWindowResizer();
  const [roomId, setRoomId] = useState<string>();
  const [publishedSelected, setPublishedSelected] = useState<undefined>();
  const [featuredSelected, setFeaturedSelected] = useState<undefined>();
  const [unitTypeSearch, setUnitTypeSearch] = useState<FormElementData<string>>({
    value: '',
    isValid: true
  });
  const [updatingUnit, setUpdatingUnit] = useState<boolean>(false);
  const history = useHistory();
  const { t } = useTranslation();
  const [listCount, setListCount] = useState<number>(0);
  const [offset, setOffset] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [searchQuery, setSearchQuery] = useState<any>({});

  const reInitialize = () => {
    setOffset(1);
    setRoomId('');
    setPublishedSelected(undefined);
    setFeaturedSelected(undefined);
    setSearchQuery({});
  };

  const getAllRooms = () => {
    getUnitTypeRooms(getModuleName()).then((response: RoomsUiModelResponse[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setRoomsOptions(response);
        setLoadingData(false);
      } else {
        store.addNotification({
          id: 'get-all-rooms-failed',
          title: 'error while getting rooms',
          message: 'failed loading unit type rooms',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut']
        });
      }
    });
  };
  useEffect(() => {
    trackPage('unit-types-list');
    reInitialize();
    setLoadingData(true);

    getAllUnitTypes(getModuleName(), offset, limit, searchQuery).then(
      (response: UnitTypeUiModelResponse | ErrorInfo) => {
        if (!isErrorInfo(response)) {
          setListCount(response?.count);
          setUnitTypesList(response?.unitList);
          setIntialUnitTypesList(response?.unitList);

          getAllRooms();
          setLoadingData(false);
        }
      }
    );
    return () => {
      store.removeNotification('add-unit-type-failed');
      store.removeNotification('add-unit-type-success');
      store.removeNotification('delete-unit-type-failed');
      store.removeNotification('delete-unit-type-success');
      store.removeNotification('edit-unit-type-failed');
      store.removeNotification('edit-unit-type-success');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * function to handle user menu clicked
   * @param event mouse click event
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    trackEvent('actions|open-menu', {
      category: 'unit-types-list',
      label: 'unit-type'
    });
  };
  const getAlUnitTypes = (query: object, offset: number, limit: number) => {
    setLoadingData(true);
    getAllUnitTypes(getModuleName(), offset, limit, query).then(
      (response: UnitTypeUiModelResponse | ErrorInfo) => {
        if (!isErrorInfo(response)) {
          setListCount(response?.count);
          setUnitTypesList(response?.unitList);
          setIntialUnitTypesList(response?.unitList);
          setLoadingData(false);
        }
      }
    );
  };
  const handleUpdateUnitType = (id: string, data: Partial<UnitTypeUiModel>) => {
    if (id) {
      setUpdatingUnit(true);
      updateUnitTypeData(id, data).then((response: UnitTypeUiModel | ErrorInfo) => {
        setUpdatingUnit(false);
        if (!isErrorInfo(response)) {
          store.addNotification({
            id: 'edit-unit-type-success',
            title: t(
              'UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.NOTIFICATIONS.UPDATE_UNIT_TYPE.TITLE'
            ),
            message: t(
              'UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.NOTIFICATIONS.UPDATE_UNIT_TYPE.MESSAGE'
            ),
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
              duration: 3000,
              onScreen: true
            }
          });
          getAlUnitTypes(searchQuery, offset, limit);
        } else {
          store.addNotification({
            id: 'edit-unit-type-failed',
            title: t('UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.NOTIFICATIONS.UPDATE_FAILED.TITLE'),
            message: t(
              'UNIT_TYPE_MODULE.EDIT_UNIT_TYPE_CONTAINER.NOTIFICATIONS.UPDATE_FAILED.TITLE'
            ),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        }
      });
    }
  };
  const handleNewUnitTypeSubmit = (unitTypeData: UnitTypeUiModel) => {
    store.removeNotification('add-property-failed');
    store.removeNotification('add-property-success');
    addNewUnitType(unitTypeData).then((response: UnitTypeUiModel | ErrorInfo) => {
      if (isErrorInfo(response)) {
        if (response.status === 401 || response.status === 403) {
          store.addNotification({
            id: 'add-property-failed',
            title: t('PROPERTY_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_PROPERTY_PERMISSION.TITLE'),
            message: t(
              'PROPERTY_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_PROPERTY_PERMISSION.MESSAGE'
            ),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        } else {
          store.addNotification({
            id: 'add-property-failed',
            title: t('PROPERTY_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_PROPERTY_FAILED.TITLE'),
            message: t('PROPERTY_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_PROPERTY_FAILED.MESSAGE'),
            type: 'danger',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut']
          });
        }
      } else {
        store.addNotification({
          title: t('PROPERTY_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_PROPERTY_SUCCESS.TITLE'),
          message: t('PROPERTY_MODULE.LIST_CONTAINER.NOTIFICATIONS.ADD_PROPERTY_SUCCESS.MESSAGE'),
          type: 'success',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        setIntialUnitTypesList([...intialUnitTypesList, response]);
        setUnitTypesList([...intialUnitTypesList, response]);
        history.push(
          (APP_ROUTES.UNIT_TYPE_MODULE.CHILDREN as RouteConfig).EDIT.FULL_PATH.replace(
            ':id',
            response.id as string
          )
        );
      }
    });
  };

  /**
   * function to handle closing user menu
   */
  const handleClose = (event: any) => {
    if (event && event.stopPropagation) event.stopPropagation();
    if (event && event.preventDefault) event.preventDefault();
    setAnchorEl(null);
  };

  const getNewUnits = (offsetNumber: number) => {
    setOffset(offsetNumber);
    getAlUnitTypes(searchQuery, offsetNumber, limit);
  };
  return (
    <div className={styles['unit-types-list-container']}>
      <div className={styles['unit-types-list-container__title']}>
        {t('UNIT_TYPE_MODULE.LIST_CONTAINER.TITLE')}
        <AddCircleIcon
          onClick={() => {
            setShowAddModal(true);
          }}
          fontSize={'inherit'}
          color={'inherit'}
        />
      </div>
      <div className={styles['edit-unit-type-container__add-unit']}></div>
      <div className={styles['unit-types-list-container__add-new']}>
        <div className={styles['unit-types-list-container__search']}>
          <div className={styles['unit-types-list-container__search__form-field']}>
            <div className={styles['unit-types-list-container__search__form-field__label']}>
              Search All
            </div>
            <InputText
              data={{
                defaultValue: unitTypeSearch.value,
                placeholder: 'search Units Name & Id'
              }}
              config={{
                maxRows: 1,
                autoFocus: false
              }}
              eventHandlers={{
                onChangeHandler: (value: string, isValid: boolean) => {
                  setUnitTypeSearch({ value, isValid });
                }
              }}
            />
          </div>
          <div className={styles['unit-types-list-container__search__form-field']}>
            <div className={styles['unit-types-list-container__search__form-field__label']}>
              Room Name
            </div>
            <Select
              options={roomsOptions}
              className={styles['unit-types-list-container__search__form-field__select']}
              onChange={(value: ValueType<{ value: string; label: string }, false>) => {
                setRoomId(value ? value.value : '');
              }}
            />
          </div>
          <div className={styles['unit-types-list-container__search__form-field']}>
            <div className={styles['unit-types-list-container__search__form-field__label']}>
              Published
            </div>
            <Select
              options={publishedOptions}
              className={styles['unit-types-list-container__search__form-field__select']}
              onChange={(value: ValueType<{ value: string; label: string }, false>) => {
                setPublishedSelected(value ? value.value : '');
              }}
            />
          </div>
          <div className={styles['unit-types-list-container__search__form-field']}>
            <div className={styles['unit-types-list-container__search__form-field__label']}>
              Featured
            </div>
            <Select
              options={featuredOptions}
              className={styles['unit-types-list-container__search__form-field__select']}
              onChange={(value: ValueType<{ value: string; label: string }, false>) => {
                setPublishedSelected(value ? value.value : '');
              }}
            />
          </div>
          {/* <div className={styles['unit-types-list-container__search__form-field']}>
            <div className={styles['unit-types-list-container__search__form-field__label']}>Unit Type</div>
            <Select
              className={styles['unit-types-list-container__search__form-field__select']}
              options={unitTypeOptions}
              onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
                setUnitType(value ? value.value : '');
              }}
            />
          </div> */}
          <div className={styles['unit-types-list-container__search__button-container']}>
            <Button
              className={styles['unit-types-list-container__search__button-container__btn']}
              onClick={() => {
                if (
                  unitTypeSearch.value ||
                  roomId ||
                  featuredSelected !== undefined ||
                  publishedSelected !== undefined
                ) {
                  const searchValue = {} as any;
                  if (unitTypeSearch.value) {
                    searchValue.freeText = unitTypeSearch.value;
                  }
                  if (featuredSelected !== undefined) {
                    searchValue.isFeatured = featuredSelected;
                  }
                  if (publishedSelected !== undefined) {
                    searchValue.isListed = publishedSelected;
                  }
                  if (roomId) {
                    searchValue.unitId = roomId;
                  }
                  getAlUnitTypes(searchValue, offset, limit);
                }
              }}
              tracking={{
                action: 'search',
                category: 'unittypes',
                label: 'search-unitypes-create-reservation'
              }}
              size={isMobileView ? ButtonSize.full : ButtonSize.medium}
            >
              search
            </Button>
          </div>
        </div>
      </div>

      {loadingData ? (
        <div className={styles['edit-unit-type-container__loader']}>
          <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
        </div>
      ) : (
        <div className={styles['unit-types-list-container__list-wrapper']}>
          {unitTypesList.length > 0 ? (
            unitTypesList?.map((unitType) => (
              <div
                className={`${styles['unit-types-list-container__unit-type-item']} ${unitType.isListed
                  ? styles['unit-types-list-container__unit-type-item--published']
                  : ''
                  }`}
              >
                <div className={styles['unit-types-list-container__unit-type-item__image-wrapper']}>
                  <img
                    className={`${styles['unit-types-list-container__unit-type-item__image-wrapper__image']
                      } ${unitType.isListed
                        ? ''
                        : styles[
                        'unit-types-list-container__unit-type-item__image-wrapper__image--unpublished'
                        ]
                      }`}
                    alt="unit-type"
                    src={unitType?.coverImage || `${PUBLIC_PATH}/assets/pngs/unit-type-default.png`}
                  />
                  {unitType?.bookingMode === BookingMode.INSTANT && (
                    <Tooltip
                      title={
                        <span
                          className={
                            styles[
                            'unit-types-list-container__unit-type-item__booking-mode__tooltip'
                            ]
                          }
                        >
                          Instant Booking
                        </span>
                      }
                    >
                      <div
                        className={
                          styles['unit-types-list-container__unit-type-item__booking-mode']
                        }
                      >
                        <OfflineBoltIcon fontSize="inherit" color="inherit" />
                      </div>
                    </Tooltip>
                  )}
                  <div
                    className={`${styles['edit-unit-type-container__header__listed']} ${unitType && unitType.isListed
                      ? styles['edit-unit-type-container__header__listed--set']
                      : ''
                      }`}
                  >
                    <FormControlLabel
                      control={
                        <OrangeSwitch
                          disabled={updatingUnit}
                          checked={unitType.isListed}
                          value={unitType.isListed}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            handleUpdateUnitType(unitType?.id, {
                              isListed: event.target.checked
                            });
                          }}
                          name="isListed"
                          color="primary"
                        />
                      }
                      label={''}
                    />
                  </div>
                </div>
                <div className={styles['unit-types-list-container__unit-type-item__header']}>
                  <div
                    className={`${styles['unit-types-list-container__unit-type-item__header__title']
                      } ${!unitType.name
                        ? styles[
                        'unit-types-list-container__unit-type-item__header__title--untitled'
                        ]
                        : ''
                      } `}
                  >
                    {unitType.name || t('UNIT_TYPE_MODULE.LIST_CONTAINER.UNTITLED_UNIT_TYPE')}
                    {/* <p
                  className={
                    styles['unit-types-list-container__unit-type-item__header__title__created-by']
                  }
                >
                  {t('UNIT_TYPE_MODULE.LIST_CONTAINER.MODIFIED_BY')}
                  <span>{unitType.modifiedByUsername}</span>
                </p> */}
                  </div>
                  {!unitType.propertyName && (
                    <div
                      className={
                        styles['unit-types-list-container__unit-type-item__header-details--error']
                      }
                    >
                      <WarningIcon
                        className={
                          styles[
                          'unit-types-list-container__unit-type-item__header-details--error_icon'
                          ]
                        }
                      />
                      Unit Type is not assigned to any Projects
                    </div>
                  )}
                  {isMobileView && (
                    <>
                      <MIButton
                        aria-controls="unit-type-menu"
                        aria-haspopup="true"
                        onClick={(e) => {
                          SetSelectedUnitId(unitType?.id as string);
                          handleClick(e);
                        }}
                      >
                        <MoreVertIcon
                          className={
                            styles['unit-types-list-container__unit-type-item__header__icon']
                          }
                        />
                      </MIButton>
                      <Menu
                        id="unit-type-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            width: '16ch'
                          }
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            history.push(
                              (
                                APP_ROUTES.UNIT_TYPE_MODULE.CHILDREN as RouteConfig
                              ).EDIT.FULL_PATH.replace(':id', selectedUnitId as string)
                            );
                          }}
                        >
                          <span
                            className={
                              styles[
                              'unit-types-list-container__unit-type-item__content__responses__link-text'
                              ]
                            }
                          >
                            {t('UNIT_TYPE_MODULE.LIST_CONTAINER.EDIT_LINK')}
                          </span>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setShowDeleteModal(true);
                          }}
                        >
                          <span
                            className={
                              styles[
                              'unit-types-list-container__unit-type-item__content__responses__link-text'
                              ]
                            }
                          >
                            {t('UNIT_TYPE_MODULE.LIST_CONTAINER.DELETE_LINK')}
                          </span>
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                  <div
                    className={styles['unit-types-list-container__unit-type-item__header-details']}
                  >
                    <div
                      className={
                        styles[
                        'unit-types-list-container__unit-type-item__header-details_property-details'
                        ]
                      }
                    >
                      <div>
                        <div
                          className={
                            styles[
                            'unit-types-list-container__unit-type-item__header-details_property-details-name'
                            ]
                          }
                        >
                          property Name:
                          <a
                            href={`${process.env.PUBLIC_URL}/properties/edit/${unitType.propertyId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {unitType.propertyName}
                            <ArrowForward color="inherit" fontSize="inherit" />
                          </a>
                        </div>
                        <div
                          className={
                            styles[
                            'unit-types-list-container__unit-type-item__header-details_property-details-name'
                            ]
                          }
                        >
                          Homeowner Name:{' '}
                          {unitType.landlordUsername ? unitType.landlordUsername : ' Not assigned'}
                        </div>
                        <div
                          className={
                            styles[
                            'unit-types-list-container__unit-type-item__header-details_property-details-name'
                            ]
                          }
                        >
                          Rooms:{' '}
                          {unitType.units && unitType.units.length > 0
                            ? unitType.units?.map((unit) => (
                              <div
                                className={
                                  styles['unit-types-list-container__unit-type-item__room-name']
                                }
                              >
                                {unit.shortName}
                              </div>
                            ))
                            : ' No rooms'}
                        </div>
                      </div>
                      <div
                        className={
                          styles[
                          'unit-types-list-container__unit-type-item__header-details_property-details-area'
                          ]
                        }
                      >
                        area:
                        <a
                          href={`${process.env.PUBLIC_URL}/properties/edit/${unitType.areaId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {unitType.area} <ArrowForward color="inherit" fontSize="inherit" />
                        </a>
                      </div>
                    </div>
                    {unitType.availableFrom && (
                      <div
                        className={
                          styles[
                          'unit-types-list-container__unit-type-item__header-details_property-availability'
                          ]
                        }
                      >
                        <span>
                          Available from:{' '}
                          {unitType.availableFrom &&
                            new Date(unitType.availableFrom).toDateString()}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles['unit-types-list-container__unit-type-item__content']}>
                  <div
                    className={
                      styles['unit-types-list-container__unit-type-item__content__responses']
                    }
                  >
                    <AttachMoneyIcon
                      className={
                        styles[
                        'unit-types-list-container__unit-type-item__content__responses__icon'
                        ]
                      }
                    />
                    <span
                      className={
                        styles[
                        'unit-types-list-container__unit-type-item__content__responses__number'
                        ]
                      }
                    >
                      {unitType?.unitPrice || 0} &nbsp;
                    </span>
                    <span
                      className={
                        styles[
                        'unit-types-list-container__unit-type-item__content__responses__response-text'
                        ]
                      }
                    >
                      {unitType?.reservationMode === ReservationMode.MONTHLY
                        ? t('UNIT_TYPE_MODULE.LIST_CONTAINER.MONTHLY_PRICE')
                        : t('UNIT_TYPE_MODULE.LIST_CONTAINER.NIGHTLY_PRICE')}
                    </span>
                  </div>
                  <div
                    className={
                      styles['unit-types-list-container__unit-type-item__content__responses']
                    }
                  >
                    <MeetingRoomIcon
                      className={
                        styles[
                        'unit-types-list-container__unit-type-item__content__responses__icon'
                        ]
                      }
                    />
                    <span
                      className={
                        styles[
                        'unit-types-list-container__unit-type-item__content__responses__number'
                        ]
                      }
                    >
                      {unitType?.units?.length || 0} &nbsp;
                    </span>
                    <span
                      className={
                        styles[
                        'unit-types-list-container__unit-type-item__content__responses__response-text'
                        ]
                      }
                    >
                      {t('UNIT_TYPE_MODULE.LIST_CONTAINER.UNITS_COUNT')}
                    </span>
                  </div>
                  <div
                    className={
                      styles['unit-types-list-container__unit-type-item__content__responses']
                    }
                  >
                    <BathtubIcon
                      className={
                        styles[
                        'unit-types-list-container__unit-type-item__content__responses__icon'
                        ]
                      }
                    />
                    <span
                      className={
                        styles[
                        'unit-types-list-container__unit-type-item__content__responses__number'
                        ]
                      }
                    >
                      {unitType?.amenities?.length || 0} &nbsp;
                    </span>
                    <span
                      className={
                        styles[
                        'unit-types-list-container__unit-type-item__content__responses__response-text'
                        ]
                      }
                    >
                      {t('UNIT_TYPE_MODULE.LIST_CONTAINER.AMENITIES_COUNT')}
                    </span>
                  </div>
                </div>
                <div
                  className={styles['unit-types-list-container__unit-type-item__featured']}
                  onClick={(e) => {
                    unitType.propertyName
                      ? handleUpdateUnitType(unitType.id || '', {
                        isFeatured: unitType.isFeatured ? false : true
                      })
                      : e.preventDefault();
                  }}
                >
                  {!unitType.isFeatured && unitType.propertyName && (
                    <StarBorderOutlined fontSize="large"></StarBorderOutlined>
                  )}
                  {unitType.isFeatured && unitType.propertyName && <Star fontSize="large"></Star>}
                  {!unitType.propertyName && <Star fontSize="large" color="disabled"></Star>}
                </div>
                {!isMobileView && (
                  <div className={styles['unit-types-list-container__unit-type-item__actions']}>
                    <MIButton
                      aria-controls="unit-type-menu"
                      aria-haspopup="true"
                      onClick={() => {
                        window.open(
                          PUBLIC_PATH +
                          (
                            APP_ROUTES.UNIT_TYPE_MODULE.CHILDREN as RouteConfig
                          ).EDIT.FULL_PATH.replace(':id', unitType.id as string),
                          '_blank'
                        );
                      }}
                    >
                      <EditIcon fontSize={'large'} color="inherit" />
                    </MIButton>
                    <MIButton
                      aria-controls="unit-type-menu"
                      aria-haspopup="true"
                      onClick={() => {
                        setShowDeleteModal(true);
                      }}
                    >
                      <DeleteIcon fontSize="large" color={'error'} />
                    </MIButton>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className={styles['unit-types-list-container__noData']}>
              <img src={`${PUBLIC_PATH}/assets/jpeg/no-records.jpg`} alt="no-data" /> No Data
              Available
            </div>
          )}
        </div>
      )}

      <AddUnitTypeModal
        config={{ showModal: showAddModal }}
        eventHandlers={{
          closeHandler: () => {
            setShowAddModal(false);
          },
          addUnitTypeHandler: (unitType) => {
            setShowAddModal(false);
            handleNewUnitTypeSubmit(unitType);
          }
        }}
      />

      <DeleteUnitTypeModal
        config={{ showModal: showDeleteModal }}
        eventHandlers={{
          closeHandler: () => {
            setShowDeleteModal(false);
          }
        }}
      />
      {listCount > 0 && (
        <Pagination
          itemCounts={listCount}
          itemsPerPage={limit}
          offset={offset}
          handlePageChange={(val) => {
            getNewUnits(val);
          }}
          handleItemsPerPage={(itemsPerPage) => {
            setLimit(itemsPerPage);
          }}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps)(UnitTypesListContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}
