import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import styles from './modal.module.scss';

export interface ModalComponentProps {
  config: {
    showModal: boolean;
    fullScreen: boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    showClose?: boolean;
    className?: string;
  };
  eventHandlers: {
    closeModalHandler?: () => void;
  };
  children: JSX.Element[] | JSX.Element;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Modal({
  config,
  eventHandlers,
  children
}: ModalComponentProps): JSX.Element {
  return (
    <>
      <Dialog
        className={config.className || ''}
        fullScreen={config.fullScreen}
        open={config.showModal}
        onClose={eventHandlers.closeModalHandler}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth={config.maxWidth || 'sm'}
        onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          // to prevent click propagations to parents
          event.stopPropagation();
          return;
        }}
      >
        <div
          className={`${styles['modal-container']} ${
            config.showClose ? styles['modal-container__with-close'] : ''
          }`}
        >
          {config.showClose && (
            <div className={styles['modal-container__close-button']}>
              <IconButton onClick={eventHandlers.closeModalHandler}>
                <CloseIcon fontSize="large" />
              </IconButton>
            </div>
          )}
          {children}
        </div>
      </Dialog>
    </>
  );
}
