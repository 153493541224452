import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './edit-area-modal.module.scss';
import { VALIDATION_REGEX } from '../../../../../shared/components/validation.config';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import { trackEvent } from '../../../../../shared/services/utils/tracking/tracking.service';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import {
  InputText,
  InputTextErrorMode,
  InputTextType
} from '../../../../../shared/components/core-ui/input-text/input-text.component';
import { AreaUiModel } from '../../../../../shared/ui-models/area.ui-model';
import ImageUploader from '../../../../../shared/components/core-ui/image-uploader/image-uploader.component';
import { ImageType } from 'react-images-uploading';

export interface EditAreaModalProps {
  data: {
    area: AreaUiModel | undefined;
  };
  config: {
    showModal: boolean;
  };
  eventHandlers: {
    cancelHandler: () => void;
    editAreaHandler: (areaId: string, area: AreaUiModel) => void;
    submitDefaultImageHandler: (amenityId: string, image: ImageType) => void;
    submitSponsoredImageHandler: (amenityId: string, image: ImageType) => void;
  };
}

/**
 * functional component EditAreaModal
 * @param {EditAreaModalProps}
 */
export const EditAreaModal: React.FC<EditAreaModalProps> = ({ data, config, eventHandlers }) => {
  const [newAreaName, setNewAreaName] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [newAreaDefaultImage, setNewAreaDefaultImage] = useState<FormElementData<ImageType | null>>(
    {
      value: null,
      isValid: false,
      changed: false
    }
  );
  const [newAreaSponsoredImageImage, setNewAreaSponsoredImage] = useState<
    FormElementData<ImageType | null>
  >({
    value: null,
    isValid: false,
    changed: false
  });
  const [newAreaOrder, setNewAreaOrder] = useState<FormElementData<number>>({
    value: 0,
    isValid: false,
    changed: false
  });
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  useEffect(() => {
    setNewAreaName({ value: data?.area?.name || '', isValid: false });
    setNewAreaOrder({ value: data.area?.order || 0, isValid: true, changed: false });
    setNewAreaDefaultImage({
      value: { dataURL: data?.area?.defaultImage },
      isValid: false,
      changed: false
    });
    setNewAreaSponsoredImage({
      value: { dataURL: data?.area?.sponsoredImage },
      isValid: false,
      changed: false
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.area]);

  const handleNewAreaSubmit = () => {
    if (data && data.area && data.area.id) {
      if (
        newAreaName.value !== '' &&
        ((newAreaName.isValid && newAreaName.value !== data?.area?.name) ||
          newAreaOrder.changed ||
          (newAreaDefaultImage.changed && newAreaDefaultImage.value == null) ||
          (newAreaSponsoredImageImage.changed && newAreaSponsoredImageImage.value == null))
      ) {
        eventHandlers.editAreaHandler(data.area.id, {
          ...data.area,
          name: newAreaName.value,
          order: newAreaOrder.value,
          defaultImage:
            newAreaDefaultImage.changed && newAreaDefaultImage.value == null ? '' : undefined,
          sponsoredImage:
            newAreaSponsoredImageImage.changed && newAreaSponsoredImageImage.value == null
              ? ''
              : undefined
        });
      }
      if (
        newAreaDefaultImage.changed &&
        newAreaDefaultImage &&
        newAreaDefaultImage.value &&
        newAreaDefaultImage.value.file
      ) {
        if (newAreaDefaultImage.value) {
          eventHandlers.submitDefaultImageHandler(data.area?.id, newAreaDefaultImage.value);
        }
      }
      if (
        newAreaSponsoredImageImage.changed &&
        newAreaSponsoredImageImage &&
        newAreaSponsoredImageImage.value &&
        newAreaSponsoredImageImage.value.file
      ) {
        if (newAreaSponsoredImageImage.value) {
          eventHandlers.submitSponsoredImageHandler(
            data.area?.id,
            newAreaSponsoredImageImage.value
          );
        }
      }
    }
  };

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.cancelHandler();
        }
      }}
    >
      <div className={styles['add-area-modal']}>
        <div className={styles['add-area-modal__title']}>
          {t('AREA_MODULE.LIST_CONTAINER.EDIT_AREA_MODAL.TITLE')}
        </div>
        <div className={styles['add-area-modal__description']}>
          {t('AREA_MODULE.LIST_CONTAINER.EDIT_AREA_MODAL.DESCRIPTION')}
        </div>
        <InputText
          data={{
            value: newAreaName.value,
            errorMessage: t('AREA_MODULE.LIST_CONTAINER.EDIT_AREA_MODAL.INVALID_AREA_NAME'),
            placeholder: t('AREA_MODULE.LIST_CONTAINER.EDIT_AREA_MODAL.NAME_PLACEHOLDER')
          }}
          config={{
            maxRows: 1,
            autoFocus: true,
            validationRegex: VALIDATION_REGEX.AREA_NAME,
            showErrorMode: InputTextErrorMode.instant
          }}
          eventHandlers={{
            onChangeHandler: (value: string, isValid: boolean) => {
              setNewAreaName({ value, isValid });
            },
            onEnterPressedHandler: handleNewAreaSubmit
          }}
        />
        <div className={styles['add-area-modal__description']}>Order</div>
        <InputText
          data={{
            defaultValue: newAreaOrder.value as any,
            errorMessage: t('AREA_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.INVALID_AREA_NAME'),
            placeholder: 'order'
          }}
          config={{
            maxRows: 1,
            autoFocus: true,
            showErrorMode: InputTextErrorMode.instant,
            type: InputTextType.number
          }}
          eventHandlers={{
            onChangeHandler: (value: string, isValid: boolean) => {
              setNewAreaOrder({ value: Number(value), isValid, changed: true });
            },
            onEnterPressedHandler: handleNewAreaSubmit
          }}
        />
        <div className={styles['add-area-modal__description']}>upload default image</div>
        <ImageUploader
          config={{
            enableCrop: false,
            canvasSize: 'sm',
            cropAspectRatio: { width: 150, height: 100 }
          }}
          data={{
            image: newAreaDefaultImage.value
          }}
          eventHandlers={{
            fileUploadHandler: (file, width, height) => {
              trackEvent('image-sm-uploaded', {
                category: 'area',
                label: 'edit-area'
              });
              setNewAreaDefaultImage({
                value: Array.isArray(file) ? file[0] : file || null,
                changed: true,
                isValid: true
              });
            },
            imageRemovedHandler: () => {
              setNewAreaDefaultImage({
                value: null,
                isValid: false,
                changed: true
              });
            }
          }}
        />
        <div className={styles['add-area-modal__description']}>upload sponsored image</div>
        <ImageUploader
          config={{
            enableCrop: false,
            canvasSize: 'sm',
            cropAspectRatio: { width: 150, height: 100 }
          }}
          data={{
            image: newAreaSponsoredImageImage.value
          }}
          eventHandlers={{
            fileUploadHandler: (file, width, height) => {
              trackEvent('image-sm-uploaded', {
                category: 'area',
                label: 'edit-area'
              });
              setNewAreaSponsoredImage({
                value: Array.isArray(file) ? file[0] : file || null,
                changed: true,
                isValid: true
              });
            },
            imageRemovedHandler: () => {
              setNewAreaSponsoredImage({
                value: null,
                isValid: false,
                changed: true
              });
            }
          }}
        />
      </div>
      <div className={styles['add-area-modal--actions']}>
        <Button
          onClick={() => {
            eventHandlers.cancelHandler();
            trackEvent('cancel', {
              category: 'area',
              label: 'edit-area-modal'
            });
          }}
          tracking={{
            action: 'cancel',
            category: 'area',
            label: 'edit-area-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          type={ButtonType.primaryInverted}
        >
          {t('AREA_MODULE.LIST_CONTAINER.EDIT_AREA_MODAL.CANCEL_BUTTON')}
        </Button>
        <Button
          disabled={
            (!newAreaName.isValid ||
              newAreaName.value === '' ||
              newAreaName.value === data?.area?.name) &&
            !newAreaOrder.changed &&
            !newAreaSponsoredImageImage.changed &&
            !newAreaDefaultImage.changed
          }
          onClick={handleNewAreaSubmit}
          tracking={{
            action: 'submit',
            category: 'areas',
            label: 'edit-area-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          {t('AREA_MODULE.LIST_CONTAINER.EDIT_AREA_MODAL.SUBMIT_BUTTON')}
        </Button>
      </div>
    </Modal>
  );
};
