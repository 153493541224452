import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './edit-general-info.module.scss';
import { store } from 'react-notifications-component';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  InputText,
  InputTextType
} from '../../../../../shared/components/core-ui/input-text/input-text.component';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import Button, {
  ButtonSize
} from '../../../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { getAllAreas } from '../../../../../shared/services/data/lookups.data';
import { getModuleName } from '../../../../shared/services/property.service';
import { AreaUiModel } from '../../../../../shared/ui-models/area.ui-model';
import { ErrorInfo, isErrorInfo } from '../../../../../shared/interfaces/error-info.interface';
import { PropertyUiModel } from '../../../../../shared/ui-models/property.ui-model';
import { GenderPickerComponent } from '../../../../../shared/components/core-ui/gender-picker/gender-picker.component';
import { PropertyGender } from '../../../../../shared/enums/property-gender.enum';

export interface EditGeneralInfoProps {
  data: {
    propertyName: string;
    propertyDescription: string;
    areaName: string;
    areaId: string;
    gender: PropertyGender | undefined;
  };
  eventHandlers: {
    submitHandler: (data: Partial<PropertyUiModel>) => void;
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      // margin: theme.spacing(1),

      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    }
  })
);
/**
 * functional component EditGeneralInfo
 * @param {EditGeneralInfoProps} holding question text
 */
export const EditGeneralInfo: React.FC<EditGeneralInfoProps> = ({ data, eventHandlers }) => {
  const classes = useStyles();

  const [newName, setNewName] = useState<FormElementData<string>>({
    value: '',
    isValid: false,
    changed: false
  });
  const [newDescription, setNewDescription] = useState<FormElementData<string>>({
    value: '',
    isValid: false,
    changed: false
  });
  const [newArea, setNewArea] = useState<FormElementData<string>>({
    value: '',
    isValid: false,
    changed: false
  });
  const [newGender, setNewGender] = useState<FormElementData<PropertyGender | undefined>>({
    value: undefined,
    isValid: false,
    changed: false
  });
  const [areasList, setAreasList] = useState<AreaUiModel[]>([]);

  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  useEffect(() => {
    getAllAreas(getModuleName()).then((response: AreaUiModel[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setAreasList(response);
      }
    });
    return () => {
      store.removeNotification('general-info-failed');
      store.removeNotification('general-info-success');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNewName({ value: data.propertyName, isValid: false, changed: false });
    setNewDescription({ value: data.propertyDescription, isValid: false, changed: false });
    setNewArea({ value: data.areaId, isValid: false, changed: false });
    setNewGender({ value: data.gender, isValid: false, changed: false });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleGeneralInfoSubmit = () => {
    const updatedData: {
      name?: string;
      description?: string;
      areaId?: string;
      gender?: PropertyGender;
    } = {};
    if (newName.changed && newName.value) {
      updatedData['name'] = newName.value;
    }
    if (newDescription.changed && newDescription.value) {
      updatedData['description'] = newDescription.value;
    }
    if (newArea.changed && newArea.value) {
      updatedData['areaId'] = newArea.value;
    }
    if (newGender.changed && newGender.value) {
      updatedData['gender'] = newGender.value;
    }
    eventHandlers.submitHandler(updatedData);
  };

  return (
    <div className={styles['general-info-container']}>
      <div className={styles['general-info-container__sub-title']}>
        {t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.GENERAL_INFO')}
      </div>
      <div className={styles['general-info-container']}>
        {t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.PROPERTY_NAME')}
        <div className={styles['general-info-container__field']}>
          <InputText
            config={{
              autoFocus: false,
              type: InputTextType.text,
              // minLength: 6,
              required: true
            }}
            data={{
              placeholder: t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.PROPERTY_NAME_PLACEHOLDER'),
              errorMessage: t('AUTH_MODULE.RESET_PASSWORD_CONTAINER.PROPERTY_NAME_ERROR_MESSAGE'),
              value: newName.value
            }}
            eventHandlers={{
              onChangeHandler: (value, isValid) => {
                if (isValid) {
                  setNewName({ value, isValid: true, changed: true });
                }
              }
            }}
          />
        </div>
        {t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.PROPERTY_DESCRIPTION')}
        <div className={styles['general-info-container__field']}>
          <CKEditor
            editor={ClassicEditor}
            data={newDescription.value}
            onReady={(editor: any) => {
              // You can store the "editor" and use when it is needed.
              // console.log('Editor is ready to use!', editor);
            }}
            onChange={(event: any, editor: any) => {
              const newData: string = editor.getData();
              setNewDescription({ value: newData, isValid: true, changed: true });
            }}
            // onBlur={(event, editor) => {
            //   console.log('Blur.', editor);
            // }}
            // onFocus={(event, editor) => {
            //   console.log('Focus.', editor);
            // }}
          />
        </div>
        {t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.PROPERTY_AREA')}
        <div className={styles['general-info-container__field']}>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={newArea.value}
              onChange={(
                event: React.ChangeEvent<{
                  name?: string | undefined;
                  value: unknown;
                }>
              ) => {
                setNewArea({ value: event.target.value as string, isValid: true, changed: true });
              }}
            >
              {areasList.map((area, index) => (
                <MenuItem key={index} value={area.id}>
                  {area.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.PROPERTY_GENDER')}
        <div className={styles['general-info-container__field']}>
          <GenderPickerComponent
            data={{ selectedItemValue: newGender.value }}
            eventHandlers={{
              itemSelectedHandler: (genderChanged) => {
                setNewGender({ value: genderChanged, isValid: true, changed: true });
              }
            }}
          />
        </div>
        <div className={styles['general-info-container__actions']}>
          <Button
            disabled={
              (newArea.value === '' || !newArea.changed) &&
              (newDescription.value === '' || !newDescription.changed) &&
              (newName.value === '' || !newName.changed) &&
              (newGender.value === undefined || !newGender.changed)
            }
            onClick={handleGeneralInfoSubmit}
            tracking={{
              action: 'submit',
              category: 'areas',
              label: 'edit-area-modal'
            }}
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          >
            {t('PROPERTY_MODULE.EDIT_GENERAL_CONTAINER.UPDATE_GENERAL_INFO')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditGeneralInfo;
