import { PropertyModel } from '../models/property.model';
import { PropertyUiModel } from '../ui-models/property.ui-model';

function mapToUI(propertyData: PropertyModel): PropertyUiModel {
  return {
    id: propertyData.id || '',
    name: propertyData.name,
    description: propertyData.description,
    areaName: propertyData.areaName,
    areaId: propertyData.areaId,
    coverImage: propertyData.coverImage,
    gender: propertyData.gender,
    images: propertyData.images,
    location: propertyData.location,
    unitTypeIds: propertyData.unitTypeIds || [],
    modifiedByUsername: propertyData.modifiedByUsername,
    amenities: propertyData.amenities,
    modifiedById: propertyData.modifiedById,
    urlLink: propertyData.urlLink,
    propertyManagersList: propertyData.propertyManagersList
  };
}

function mapToModel(propertyData: PropertyUiModel): PropertyModel {
  return {
    id: propertyData.id,
    name: propertyData.name,
    description: propertyData.description,
    areaName: propertyData.areaName,
    areaId: propertyData.areaId,
    coverImage: propertyData.coverImage,
    gender: propertyData.gender,
    images: propertyData.images,
    location: propertyData.location,
    unitTypeIds: propertyData.unitTypeIds,
    amenitiesIds:
      propertyData.amenities && propertyData.amenities.length > 0
        ? propertyData.amenities.map((item) => item.id as string)
        : undefined,
    urlLink: propertyData.urlLink,
    propertyManagersList: propertyData.propertyManagersList
  };
}

function mapToUIList(propertiesList: PropertyModel[]): PropertyUiModel[] {
  return propertiesList.map(mapToUI);
}

export default {
  mapToUI,
  mapToModel,
  mapToUIList
};
