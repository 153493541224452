import React, { useState } from 'react';
import styles from './manager-info.module.scss';
import { PUBLIC_PATH } from '../../../../../shared/config/routes-config';
import Button, {
  ButtonSize
} from '../../../../../shared/components/core-ui/button/button.component';
import { trackEvent } from '../../../../../shared/services/utils/tracking/tracking.service';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { ConfirmModal } from '../../components/confirm-modal/confirm-modal.component';
import { ManagersList } from '../managers-list/managers-list.container';

export interface ManagerInfoProps {
  data: {
    propertyManagersList: {
      id: string;
      name: string;
      email: string;
      phone: string;
      role: string;
    }[];
    propertyId?: string;
    reservationId?: string;
    title?: string;
  };
  eventHandlers: {
    addPropertyManager: (show: boolean) => void;
    updatePropertyManager?: (id: string) => void;
    deletePropertyManager: (id: string) => void;
  };
}
export const ManagerInfo: React.FC<ManagerInfoProps> = ({ data, eventHandlers }) => {
  const isMobileView = useWindowResizer();
  const [disabledActions, setDisabledActions] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [confirmModalMessage, setConfirmModalMessage] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<string>('');

  return (
    <div className={styles['landlord-info-container']}>
      {data?.propertyManagersList?.length > 0 ? (
        <>
          <div className={styles['landlord-info-container-title']}>
           { data.title || 'Internal Emails Subscription List'}
          </div>
          <div className={styles['landlord-info-container-content']}>
            <ManagersList
              data={{ propertyManagersList: data?.propertyManagersList }}
              eventHandlers={{
                deletePropertyManager: (id: string) => {
                  setSelectedUser(id);
                  setShowDeleteModal(true);
                },
                addPropertyManager: () => eventHandlers.addPropertyManager(true)
              }}
            />
          </div>
        </>
      ) : (
        <div>
          <img
            src={`${PUBLIC_PATH}/assets/jpeg/add-guest.jpeg`}
            alt="assign Homeowner"
            className={styles['landlord-info-container-content-image']}
          />

          <Button
            onClick={() => {
              eventHandlers.addPropertyManager(true);
              trackEvent('add', {
                category: '',
                label: ''
              });
            }}
            tracking={{
              action: 'cancel',
              category: '',
              label: ''
            }}
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
            disabled={(data.propertyId === '' && data.reservationId === '') || disabledActions}
          >
            {' '}
            Assign Property Manager
          </Button>
        </div>
      )}

      <ConfirmModal
        config={{ showModal: showConfirmModal, message: confirmModalMessage }}
        eventHandlers={{
          denyAction: () => {
            setShowConfirmModal(false);
            eventHandlers.addPropertyManager(false);
          },
          confirmAction: () => {
            setShowConfirmModal(false);
            eventHandlers.addPropertyManager(true);
            trackEvent('add', {
              category: '',
              label: ''
            });
          }
        }}
      />

      <ConfirmModal
        config={{
          showModal: showDeleteModal,
          message: 'Are you sure you want to delete this manager?'
        }}
        eventHandlers={{
          denyAction: () => {
            setShowDeleteModal(false);
          },
          confirmAction: () => {
            setShowDeleteModal(false);
            eventHandlers.deletePropertyManager(selectedUser);
          }
        }}
      />
    </div>
  );
};
