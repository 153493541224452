import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './unit-types-bulk-update.module.scss';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import Tooltip from '@material-ui/core/Tooltip';
import { store } from 'react-notifications-component';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Select, { ValueType } from 'react-select';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import EditIcon from '@material-ui/icons/Edit';
import BathtubIcon from '@material-ui/icons/Bathtub';
import { Button as MIButton, Menu, MenuItem ,Checkbox} from '@material-ui/core';
import { ErrorInfo, isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { RootState } from '../../../shared/redux/reducers';
import { trackEvent, trackPage } from '../../../shared/services/utils/tracking/tracking.service';
import { EditUnitTypeListModal } from './components/add-unit-type-modal/edit-unit-type-list-modal.component';
import { UnitTypeUiModel, UnitTypeUiModelResponse } from '../../../shared/ui-models/unit-type.ui-model';
import { APP_ROUTES, PUBLIC_PATH } from '../../../shared/config/routes-config';
import { useHistory } from 'react-router';
import { RouteConfig } from '../../../shared/interfaces/routes-config.interface';
import useWindowResizer from '../../../shared/hooks/window-resizer/window-resizer.hook';
import { getModuleName } from '../../shared/services/unit-type.service';
import { getAllUnitTypes } from '../../../shared/services/data/unit-type.data';
import Button, { ButtonSize } from '../../../shared/components/core-ui/button/button.component';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { ReservationMode } from '../../../shared/enums/reservation-mode.enum';
import WarningIcon from '@material-ui/icons/Warning';
import { BookingMode } from '../../../shared/enums/booking-mode.enum';
import { getAllProperties } from '../../../PropertyModule/shared/services/data.service';
import { PropertyUiModel } from '../../../shared/ui-models/property.ui-model';
import { getAllUnitTypeGroups } from '../../../shared/services/data/lookups.data';
import { UnitTypeGroupUiModel } from '../../../shared/ui-models/unit-type-group.ui-model';

export type UnitTypesBulkUpdateContainerProps = ReturnType<typeof mapStateToProps>;

/**
 * functional component UnitTypesBulkUpdateContainer
 * @param {UnitTypesBulkUpdateContainerProps} holding question text
 */
export const UnitTypesBulkUpdateContainer: React.FC<UnitTypesBulkUpdateContainerProps> = ({
  isAuthenticated
}) => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [unitTypesList, setUnitTypesList] = useState<UnitTypeUiModel[]>([]);
  const [intialUnitTypesList, setIntialUnitTypesList] = useState<UnitTypeUiModel[]>([]);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [selectedUnitId, SetSelectedUnitId] = useState<string>('');
  const isMobileView = useWindowResizer();
  const [propertyId, setPropertyId] = useState<string>();
  const [unitTypeGroupId, setUnitTypeGroupId] = useState<string>();
  const [allPropertiesList, setAllPropertyList] = useState<{ value: string; label: string }[]>([]);
  const [unitTypeGroupsList, setUnitTypeGroupsList] = useState<{ value: string; label: string }[]>([]);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    trackPage('unit-types-bulk-update');
    getAllProperties().then((response: PropertyUiModel[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setAllPropertyList(response.map((property)=>({value: property.id || '', label:property.name || ''})));
      }
    });
    getAllUnitTypeGroups(getModuleName()).then((response: UnitTypeGroupUiModel[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setUnitTypeGroupsList(response.map((property)=>({value: property.id || '', label:property.name || ''})));
      }
    });
    return () => {
      store.removeNotification('add-unit-type-failed');
      store.removeNotification('add-unit-type-success');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const getAlUnitTypes = (query: object) => {
    setLoadingData(true);
    getAllUnitTypes(getModuleName(), 0, 0, query).then(
      (response: UnitTypeUiModelResponse | ErrorInfo) => {
        if (!isErrorInfo(response)) {
          setUnitTypesList(response?.unitList);
          setIntialUnitTypesList(response?.unitList);
        } else {
          setUnitTypesList([]);
        }
        
      }
    ).finally(()=>setLoadingData(false));
  };

  /**
   * function to handle user menu clicked
   * @param event mouse click event
   */
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    trackEvent('actions|open-menu', {
      category: 'unit-types-bulk-update',
      label: 'unit-type'
    });
  };

  /**
   * function to handle closing user menu
   */
  const handleClose = (event: any) => {
    if (event && event.stopPropagation) event.stopPropagation();
    if (event && event.preventDefault) event.preventDefault();
    setAnchorEl(null);
  };
  return (
    <div className={styles['unit-types-bulk-update-container']}>
      <div className={styles['unit-types-bulk-update-container__title']}>
        Units Bulk Update
        <EditIcon
          onClick={() => {
            setShowEditModal(true);
          }}
          fontSize={'inherit'}
          color={'inherit'}
        />
      </div>
      <div className={styles['edit-unit-type-container__add-unit']}></div>
      <div className={styles['unit-types-bulk-update-container__add-new']}>
        <div className={styles['unit-types-bulk-update-container__search']}>
          <div className={styles['unit-types-bulk-update-container__search__form-field']}>
            <div className={styles['unit-types-bulk-update-container__search__form-field__label']}>
              Property Name
            </div>
            <Select
              options={allPropertiesList}
              className={styles['unit-types-bulk-update-container__search__form-field__select']}
              onChange={(value: ValueType<{ value: string; label: string }, false>) => {
                setPropertyId(value ? value.value : '');
              }}
            />
          </div>
          <div className={styles['unit-types-bulk-update-container__search__form-field']}>
            <div className={styles['unit-types-bulk-update-container__search__form-field__label']}>
              Unit Type Group
            </div>
            <Select
              options={unitTypeGroupsList}
              className={styles['unit-types-bulk-update-container__search__form-field__select']}
              onChange={(value: ValueType<{ value: string; label: string }, false>) => {
                setUnitTypeGroupId(value ? value.value : '');
              }}
            />
          </div>
          <div className={styles['unit-types-bulk-update-container__search__button-container']}>
            <Button
              className={styles['unit-types-bulk-update-container__search__button-container__btn']}
              onClick={() => {
                if (
                  propertyId ||
                  unitTypeGroupId
                ) {
                  const searchValue = {} as any;
                  if (propertyId) {
                    searchValue.propertyId = propertyId;
                  }
                  if (unitTypeGroupId) {
                    searchValue.unitTypeGroupId = unitTypeGroupId;
                  }
                  getAlUnitTypes(searchValue);
                }
              }}
              tracking={{
                action: 'search',
                category: 'unittypes',
                label: 'search-unitypes-create-reservation'
              }}
              size={isMobileView ? ButtonSize.full : ButtonSize.medium}
            >
              search
            </Button>
            <Button
              className={styles['unit-types-bulk-update-container__search__button-container__btn']}
              onClick={() => {
                window.open(
                  PUBLIC_PATH +
                    (
                      APP_ROUTES.UNIT_TYPE_MODULE.CHILDREN as RouteConfig
                    ).BULK_EDIT_LIST.FULL_PATH,
                  '_blank'
                );
              }}
              size={isMobileView ? ButtonSize.full : ButtonSize.medium}
            >
              history
            </Button>
          </div>
        </div>
      </div>

      {loadingData ? (
        <div className={styles['edit-unit-type-container__loader']}>
          <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
        </div>
      ) : (
        <div className={styles['unit-types-bulk-update-container__list-wrapper']}>
          {unitTypesList.map((unitType) => (
            <div
              className={`${styles['unit-types-bulk-update-container__unit-type-item']} ${
                unitType.isListed
                  ? styles['unit-types-bulk-update-container__unit-type-item--published']
                  : ''
              }`}
            >
              <div className={styles['unit-types-bulk-update-container__unit-type-item__image-wrapper']}>
                <img
                  className={`${
                    styles['unit-types-bulk-update-container__unit-type-item__image-wrapper__image']
                  } ${
                    unitType.isListed
                      ? ''
                      : styles[
                          'unit-types-bulk-update-container__unit-type-item__image-wrapper__image--unpublished'
                        ]
                  }`}
                  alt="unit-type"
                  src={unitType?.coverImage || `${PUBLIC_PATH}/assets/pngs/unit-type-default.png`}
                />
                {unitType?.bookingMode === BookingMode.INSTANT && (
                  <Tooltip
                    title={
                      <span
                        className={
                          styles['unit-types-bulk-update-container__unit-type-item__booking-mode__tooltip']
                        }
                      >
                        Instant Booking
                      </span>
                    }
                  >
                    <div
                      className={styles['unit-types-bulk-update-container__unit-type-item__booking-mode']}
                    >
                      <OfflineBoltIcon fontSize="inherit" color="inherit" />
                    </div>
                  </Tooltip>
                )}
              </div>
              <div className={styles['unit-types-bulk-update-container__unit-type-item__header']}>
                <div
                  className={`${
                    styles['unit-types-bulk-update-container__unit-type-item__header__title']
                  } ${
                    !unitType.name
                      ? styles['unit-types-bulk-update-container__unit-type-item__header__title--untitled']
                      : ''
                  } `}
                >
                  {unitType.name || t('UNIT_TYPE_MODULE.LIST_CONTAINER.UNTITLED_UNIT_TYPE')}
                </div>
                {!unitType.propertyName && (
                  <div
                    className={
                      styles['unit-types-bulk-update-container__unit-type-item__header-details--error']
                    }
                  >
                    <WarningIcon
                      className={
                        styles[
                          'unit-types-bulk-update-container__unit-type-item__header-details--error_icon'
                        ]
                      }
                    />
                    Unit Type is not assigned to any Projects
                  </div>
                )}
                {isMobileView && (
                  <>
                    <MIButton
                      aria-controls="unit-type-menu"
                      aria-haspopup="true"
                      onClick={(e) => {
                        SetSelectedUnitId(unitType?.id as string);
                        handleClick(e);
                      }}
                    >
                      <MoreVertIcon
                        className={
                          styles['unit-types-bulk-update-container__unit-type-item__header__icon']
                        }
                      />
                    </MIButton>
                    <Menu
                      id="unit-type-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          width: '16ch'
                        }
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          history.push(
                            (
                              APP_ROUTES.UNIT_TYPE_MODULE.CHILDREN as RouteConfig
                            ).EDIT.FULL_PATH.replace(':id', selectedUnitId as string)
                          );
                        }}
                      >
                        <span
                          className={
                            styles[
                              'unit-types-bulk-update-container__unit-type-item__content__responses__link-text'
                            ]
                          }
                        >
                          {t('UNIT_TYPE_MODULE.LIST_CONTAINER.EDIT_LINK') + 'd'}
                        </span>
                      </MenuItem>
                    </Menu>
                  </>
                )}
                <div
                  className={styles['unit-types-bulk-update-container__unit-type-item__header-details']}
                >
                  <div
                    className={
                      styles[
                        'unit-types-bulk-update-container__unit-type-item__header-details_property-details'
                      ]
                    }
                  >
                    <div>
                      <div
                        className={
                          styles[
                            'unit-types-bulk-update-container__unit-type-item__header-details_property-details-name'
                          ]
                        }
                      >
                        property Name:
                        <a
                          href={`${process.env.PUBLIC_URL}/properties/edit/${unitType.propertyId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {unitType.propertyName}
                          <ArrowForward color="inherit" fontSize="inherit" />
                        </a>
                      </div>
                      <div
                        className={
                          styles[
                            'unit-types-bulk-update-container__unit-type-item__header-details_property-details-name'
                          ]
                        }
                      >
                        Homeowner Name:{' '}
                        {unitType.landlordUsername ? unitType.landlordUsername : ' Not assigned'}
                      </div>
                      <div
                        className={
                          styles[
                            'unit-types-bulk-update-container__unit-type-item__header-details_property-details-name'
                          ]
                        }
                      >
                        Rooms:{' '}
                        {unitType.units && unitType.units.length > 0
                          ? unitType.units?.map((unit) => (
                              <div
                                className={
                                  styles['unit-types-bulk-update-container__unit-type-item__room-name']
                                }
                              >
                                {unit.shortName}
                              </div>
                            ))
                          : ' No rooms'}
                      </div>
                    </div>
                    <div
                      className={
                        styles[
                          'unit-types-bulk-update-container__unit-type-item__header-details_property-details-area'
                        ]
                      }
                    >
                      area:
                      <a
                        href={`${process.env.PUBLIC_URL}/properties/edit/${unitType.areaId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {unitType.area} <ArrowForward color="inherit" fontSize="inherit" />
                      </a>
                    </div>
                  </div>
                  {unitType.availableFrom && (
                    <div
                      className={
                        styles[
                          'unit-types-bulk-update-container__unit-type-item__header-details_property-availability'
                        ]
                      }
                    >
                      <span>
                        Available from:{' '}
                        {unitType.availableFrom && new Date(unitType.availableFrom).toDateString()}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles['unit-types-bulk-update-container__unit-type-item__content']}>
                <div
                  className={
                    styles['unit-types-bulk-update-container__unit-type-item__content__responses']
                  }
                >
                  <AttachMoneyIcon
                    className={
                      styles['unit-types-bulk-update-container__unit-type-item__content__responses__icon']
                    }
                  />
                  <span
                    className={
                      styles[
                        'unit-types-bulk-update-container__unit-type-item__content__responses__number'
                      ]
                    }
                  >
                    {unitType?.unitPrice || 0} &nbsp;
                  </span>
                  <span
                    className={
                      styles[
                        'unit-types-bulk-update-container__unit-type-item__content__responses__response-text'
                      ]
                    }
                  >
                    {unitType?.reservationMode === ReservationMode.MONTHLY
                      ? t('UNIT_TYPE_MODULE.LIST_CONTAINER.MONTHLY_PRICE')
                      : t('UNIT_TYPE_MODULE.LIST_CONTAINER.NIGHTLY_PRICE')}
                  </span>
                </div>
                <div
                  className={
                    styles['unit-types-bulk-update-container__unit-type-item__content__responses']
                  }
                >
                  <MeetingRoomIcon
                    className={
                      styles['unit-types-bulk-update-container__unit-type-item__content__responses__icon']
                    }
                  />
                  <span
                    className={
                      styles[
                        'unit-types-bulk-update-container__unit-type-item__content__responses__number'
                      ]
                    }
                  >
                    {unitType?.units?.length || 0} &nbsp;
                  </span>
                  <span
                    className={
                      styles[
                        'unit-types-bulk-update-container__unit-type-item__content__responses__response-text'
                      ]
                    }
                  >
                    {t('UNIT_TYPE_MODULE.LIST_CONTAINER.UNITS_COUNT')}
                  </span>
                </div>
                <div
                  className={
                    styles['unit-types-bulk-update-container__unit-type-item__content__responses']
                  }
                >
                  <BathtubIcon
                    className={
                      styles['unit-types-bulk-update-container__unit-type-item__content__responses__icon']
                    }
                  />
                  <span
                    className={
                      styles[
                        'unit-types-bulk-update-container__unit-type-item__content__responses__number'
                      ]
                    }
                  >
                    {unitType?.amenities?.length || 0} &nbsp;
                  </span>
                  <span
                    className={
                      styles[
                        'unit-types-bulk-update-container__unit-type-item__content__responses__response-text'
                      ]
                    }
                  >
                    {t('UNIT_TYPE_MODULE.LIST_CONTAINER.AMENITIES_COUNT')}
                  </span>
                </div>
              </div>
              {!isMobileView && (
                <div className={styles['unit-types-bulk-update-container__unit-type-item__actions']}>
                  <Checkbox onChange={(event: any)=>{
                    unitType.checked =event.target?.checked as boolean;
                  }} checked={unitType.checked}/>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      <EditUnitTypeListModal
        config={{ showModal: showEditModal }}
        data={{units:unitTypesList.filter((unit)=>unit.checked)}}
        eventHandlers={{
          closeHandler: () => {
            setShowEditModal(false);
          },
          addUnitTypeHandler: (unitType) => {
            setShowEditModal(false);
          }
        }}
      />
    </div>
  );
};

export default connect(mapStateToProps)(UnitTypesBulkUpdateContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}
