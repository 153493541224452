import { ApiResponse } from 'apisauce';
import { ErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { ResponseModel } from '../../../shared/interfaces/response-model.interface';
import api from '../../../shared/services/utils/api/api.service';
import errorHelper from '../../../shared/services/utils/api/error-helper.service';
import { getModuleName } from './block-dates.service';
import { BlockDatesModel, UpdateBlockDatesModel } from '../../../shared/models/block-dates.model';
import BlockedDatesMapper from '../../../shared/mappers/blocked-dates.mapper';

export function getAllBlockingDates(query: {}): Promise<BlockDatesModel[] | ErrorInfo> {
  return api
    .getBlockDates(query)
    .then((response: ApiResponse<ResponseModel<BlockDatesModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return BlockedDatesMapper.mapToUIList(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.getBlockDates as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.getBlockDates as any).displayName
        );
      }
    });
}

export function updateBlockDate(
  id: number,
  updateBlockDatesModel: UpdateBlockDatesModel
): Promise<BlockDatesModel | ErrorInfo> {
  return api
    .updateBlockDates(id, updateBlockDatesModel)
    .then((response: ApiResponse<ResponseModel<BlockDatesModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return BlockedDatesMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.getBlockDates as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.getBlockDates as any).displayName
        );
      }
    });
}
