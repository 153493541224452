import { Crop } from 'react-image-crop';
import { ImageType } from 'react-images-uploading';
// @ts-ignore
import nearestNormalAspectRatio from 'nearest-normal-aspect-ratio';

/**
 * @param {HTMLImageElement} image - Image File Object
 * @param height image height
 * @param width image width
 * @param {Object} crop - crop Object
 * @return base64 image data string
 */
export function getCroppedImg(
  image: ImageType | null,
  height: number,
  width: number,
  crop: Crop
): string | null {
  if (image) {
    const canvas = document.createElement('canvas');
    let scaleX;
    let scaleY;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = (crop.width || 0) * pixelRatio;

    canvas.height = (crop.height || 0) * pixelRatio;
    if (ctx) {
      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);

      ctx.imageSmoothingQuality = 'high';

      const img = new Image();
      img.src = image.dataURL || '';
      img.onload = () => {
        ctx.drawImage(img, 0, 0);
      };

      scaleX = img.naturalWidth / width;
      scaleY = img.naturalHeight / height;

      ctx.drawImage(
        img,
        (crop.x || 1) * scaleX,
        (crop.y || 1) * scaleY,
        (crop.width || 1) * scaleX,
        (crop.height || 1) * scaleY,
        0,
        0,
        crop.width || 1,
        crop.height || 1
      );
    }

    // in case of png images, if the image type is not set to png, the background becomes black
    // setting the file type to the correct one prevents this issue
    const imageName: string = image.file?.name || '';
    const imageType = imageName.substring(imageName.lastIndexOf('.') + 1);

    // As Base64 string
    const base64Image = canvas.toDataURL(`image/${imageType}`);
    return base64Image;
  } else {
    return null;
  }
}

/**
 * convert dataURL to image file
 * @param dataUrl
 * @param filename
 */
export function dataURLtoFile(dataUrl: string, filename: string): ImageType | null {
  const arr = dataUrl.split(',');
  if (arr.length > 0 && arr[0]) {
    // @ts-ignore
    const mimeMatch = arr[0].match(/:(.*?);/);
    if (mimeMatch && mimeMatch[1]) {
      const mime = mimeMatch[1];
      const bStr = atob(arr[1]);
      let n = bStr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bStr.charCodeAt(n);
      }

      return { file: new File([u8arr], filename, { type: mime }), dataURL: dataUrl };
    } else {
      return null;
    }
  } else {
    return null;
  }
}

/**
 * get the nearest normal aspect ratio
 * @param width
 * @param height
 */
export function getNearestAspectRatio(width: number, height: number): number {
  const aspectRatio: string[] = nearestNormalAspectRatio(width, height).split(':');
  return Number(aspectRatio[0]) / Number(aspectRatio[1]);
}

export function extractFilename(path: string) {
  const pathArray = path.split('/');
  const lastIndex = pathArray.length - 1;
  return pathArray[lastIndex];
}

export function getImageFileFromUrl(imageUrl: string) {
  return fetch(imageUrl, { mode: 'no-cors' })
    .then((res) => res.blob())
    .then((blob) => {
      const filename = extractFilename(imageUrl);
      const r = /.+\.(.+)$/.exec(filename);
      const extensionType = r ? r[1] : 'png';
      const file = new File([blob], filename, { type: 'image/' + extensionType });
      return file;
    });
}
