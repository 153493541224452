import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './add-unit-type-group-modal.module.scss';
import { VALIDATION_REGEX } from '../../../../../shared/components/validation.config';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import { trackEvent } from '../../../../../shared/services/utils/tracking/tracking.service';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import {
  InputText,
  InputTextErrorMode
} from '../../../../../shared/components/core-ui/input-text/input-text.component';

export interface AddUnitTypeGroupModalProps {
  config: {
    showModal: boolean;
  };
  eventHandlers: {
    cancelHandler: () => void;
    addUnitTypeGroupHandler: (unitTypeGroupName: string) => void;
  };
}

/**
 * functional component AddUnitTypeGroupModal
 * @param {AddUnitTypeGroupModalProps}
 */
export const AddUnitTypeGroupModal: React.FC<AddUnitTypeGroupModalProps> = ({
  config,
  eventHandlers
}) => {
  const [newUnitTypeGroupName, setNewUnitTypeGroupName] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  const handleNewUnitTypeGroupSubmit = () => {
    if (newUnitTypeGroupName.value !== '' && newUnitTypeGroupName.isValid) {
      eventHandlers.addUnitTypeGroupHandler(newUnitTypeGroupName.value);
    }
  };

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.cancelHandler();
        }
      }}
    >
      <div className={styles['add-unitTypeGroup-modal']}>
        <div className={styles['add-unitTypeGroup-modal__title']}>
          {t('UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.TITLE')}
        </div>
        <div className={styles['add-unitTypeGroup-modal__description']}>
          {t('UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.DESCRIPTION')}
        </div>
        <InputText
          data={{
            defaultValue: '',
            errorMessage: t(
              'UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.INVALID_UNIT_TYPE_GROUP_NAME'
            ),
            placeholder: t('UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.NAME_PLACEHOLDER')
          }}
          config={{
            maxRows: 1,
            autoFocus: true,
            validationRegex: VALIDATION_REGEX.AREA_NAME,
            showErrorMode: InputTextErrorMode.instant
          }}
          eventHandlers={{
            onChangeHandler: (value: string, isValid: boolean) => {
              setNewUnitTypeGroupName({ value, isValid });
            },
            onEnterPressedHandler: handleNewUnitTypeGroupSubmit
          }}
        />
      </div>
      <div className={styles['add-unitTypeGroup-modal--actions']}>
        <Button
          onClick={() => {
            eventHandlers.cancelHandler();
            trackEvent('cancel', {
              category: 'unitTypeGroup',
              label: 'add-unitTypeGroup-modal'
            });
          }}
          tracking={{
            action: 'cancel',
            category: 'builder',
            label: 'builder-intro'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          type={ButtonType.primaryInverted}
        >
          {t('UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.CANCEL_BUTTON')}
        </Button>
        <Button
          disabled={!newUnitTypeGroupName.isValid || newUnitTypeGroupName.value === ''}
          onClick={handleNewUnitTypeGroupSubmit}
          tracking={{
            action: 'submit',
            category: 'unitTypeGroups',
            label: 'add-unitTypeGroup-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          {t('UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.SUBMIT_BUTTON')}
        </Button>
      </div>
    </Modal>
  );
};
