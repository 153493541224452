import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styles from './payments-list.module.scss';

import { ErrorInfo, isErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { RootState } from '../../../shared/redux/reducers';
import { trackPage } from '../../../shared/services/utils/tracking/tracking.service';
import useWindowResizer from '../../../shared/hooks/window-resizer/window-resizer.hook';
import {
  InputText,
  InputTextType
} from '../../../shared/components/core-ui/input-text/input-text.component';
import Select, { ValueType } from 'react-select';
import { getAllUnitTypes } from '../../../shared/services/data/unit-type.data';
import {
  UnitTypeUiModel,
  UnitTypeUiModelResponse
} from '../../../shared/ui-models/unit-type.ui-model';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../shared/components/core-ui/button/button.component';
import { getModuleName } from '../../shared/services/payments.service';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { paymentSearch } from '../../shared/services/data.service';
import { PaymentSearch } from '../../../shared/models/payment.model';
import { PaymentCollector } from '../../../shared/enums/payment-collector.enum';
import { PaymentType } from '../../../shared/enums/payment-type.enum';
import { PaymentStatus } from '../../../shared/enums/payment-status.enum';
import { PUBLIC_PATH } from '../../../shared/config/routes-config';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import NumberFormat from 'react-number-format';
import { CURRENCY } from '../../../shared/enums/currency.enum';
import { PaymentListUiModel, PaymentUIModel } from '../../../shared/ui-models/payment.ui-model';
import { Pagination } from '../../../shared/components/core-ui/pagination/pagination.component';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
    }
  }
});
const useSelectedRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
    }
  }
});

export type PaymentsListContainerProps = ReturnType<typeof mapStateToProps>;

/**
 * functional component PaymentsListContainer
 * @param {PaymentsListContainerProps} holding question text
 */
export const PaymentsListContainer: React.FC<PaymentsListContainerProps> = ({
  isAuthenticated
}) => {
  const isMobileView = useWindowResizer();
  const [payments, setPayments] = useState<PaymentUIModel[]>([]);
  const [dueDateDay, setDueDateDay] = useState('');
  const [dueDateMonth, setDueDateMonth] = useState({ value: '', label: '' });
  const [dueDateYear, setDueDateYear] = useState('');
  const [amount, setAmount] = useState('');
  const [status, setStatus] = useState({ value: '', label: '' });
  const [paymobOrderId, setPaymobOrderId] = useState('');
  const [collectedBy, setCollectedBy] = useState({ value: '', label: '' });
  const [paymentType, setPaymentType] = useState({ value: '', label: '' });
  const [unitType, setUnitType] = useState({ value: '', label: '' });
  const [unitTypeOptions, setUnitTypeOptions] = useState<
    { value: string | undefined; label: string }[]
  >([]);
  const [collectedByOptions] = useState<{ value: string | undefined; label: string }[]>([
    { value: '', label: '' },
    { value: PaymentCollector.AIRBNB, label: PaymentCollector.AIRBNB || '' },
    { value: PaymentCollector.BIRDNEST, label: PaymentCollector.BIRDNEST || '' },
    { value: PaymentCollector.PAYMOB, label: PaymentCollector.PAYMOB || '' },
    { value: PaymentCollector.BOOKING, label: PaymentCollector.BOOKING || '' }
  ]);
  const [paymentTypeOptions] = useState<{ value: string | undefined; label: string }[]>([
    { value: '', label: '' },
    { value: PaymentType.ONLINE_PAYMENT, label: PaymentType.ONLINE_PAYMENT },
    { value: PaymentType.CASH, label: PaymentType.CASH },
    { value: PaymentType.BANK_TRANSFER, label: PaymentType.BANK_TRANSFER }
  ]);
  const [paymentStatusOptions] = useState<{ value: string | undefined; label: string }[]>([
    { value: '', label: '' },
    { value: PaymentStatus.CANCELED, label: PaymentStatus.CANCELED },
    { value: PaymentStatus.COLLECTED, label: PaymentStatus.COLLECTED },
    { value: PaymentStatus.OVERDUE, label: PaymentStatus.OVERDUE },
    { value: PaymentStatus.PENDING, label: PaymentStatus.PENDING },
    { value: PaymentStatus.REFUNDED, label: PaymentStatus.REFUNDED }
  ]);
  const [monthOptions] = useState<{ value: string | undefined; label: string }[]>([
    { value: '', label: '' },
    { value: '0', label: 'Jan' },
    { value: '1', label: 'Feb' },
    { value: '2', label: 'Mar' },
    { value: '3', label: 'Apr' },
    { value: '4', label: 'May' },
    { value: '5', label: 'June' },
    { value: '6', label: 'July' },
    { value: '7', label: 'Aug' },
    { value: '8', label: 'Sep' },
    { value: '9', label: 'Oct' },
    { value: '10', label: 'Nov' },
    { value: '11', label: 'Dec' }
  ]);
  const [listCount, setListCount] = useState<number>(0);
  const [offset, setOffset] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<any>({});

  useEffect(() => {
    trackPage('payments-list');
    reInitialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getAllUnitTypes(getModuleName(), 0, -1, {}).then(
      (response: UnitTypeUiModelResponse | ErrorInfo) => {
        if (!isErrorInfo(response)) {
          setUnitTypeOptions(
            response?.unitList?.map((unitType) => ({
              value: unitType.id,
              label: unitType.name || ''
            }))
          );
        }
      }
    );

    getPayments({}, 1, limit);
  }, []);

  useEffect(() => {
    getPayments(searchQuery, offset, limit);
  }, [limit]);

  const getPayments = (query: Partial<PaymentSearch>, offset: number, limit: number) => {
    setLoadingData(true);
    paymentSearch(query, offset, limit).then((response: PaymentListUiModel | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setListCount(+response?.count);
        setPayments(response?.paymentList);
        setLoadingData(false);
      } else {
        setListCount(0);
        setPayments([]);
        setLoadingData(false);
      }
    });
  };
  const getNewPayment = (offsetNumber: number) => {
    setOffset(offsetNumber);
    getPayments(searchQuery, offsetNumber, limit);
  };
  const counterRenderer = ({
    hours,
    minutes,
    seconds
  }: {
    hours: number;
    minutes: number;
    seconds: number;
  }) => {
    // Render a countdown
    return (
      <b className={styles['reservation-list-container__reservation-item__status--highlighted']}>
        {hours}:{minutes}:{seconds}
      </b>
    );
  };

  const reInitialize = () => {
    setOffset(1);
    setDueDateDay('');
    setDueDateMonth({ value: '', label: '' });
    setDueDateYear('');
    setPaymobOrderId('');
    setCollectedBy({ value: '', label: '' });
    setPaymentType({ value: '', label: '' });
    setUnitType({ value: '', label: '' });
    setStatus({ value: '', label: '' });
  };
  function Row(props: { payment: PaymentUIModel }) {
    const { payment } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const selectedClasses = useSelectedRowStyles();

    return (
      <>
        <TableRow>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </TableCell>

          <TableCell>{payment.id}</TableCell>
          <TableCell>
            <a
              target="_blank"
              className="link"
              href={`${process.env.PUBLIC_URL}/reservations/edit/${payment.reservationId}`}
            >
              {payment.reservationId}
              <ArrowForward color="inherit" fontSize="inherit" />
            </a>
          </TableCell>
          <TableCell>
            {payment.direction === 'in' ? (
              <div
                className={styles['payments-list-container__payment-item__pay-direction__wrapper']}
              >
                <img
                  className={styles['payments-list-container__payment-item__pay-direction__image']}
                  src={`${PUBLIC_PATH}/assets/pngs/pay-in.png`}
                />
                IN
              </div>
            ) : (
              <div
                className={styles['payments-list-container__payment-item__pay-direction__wrapper']}
              >
                <img
                  className={styles['payments-list-container__payment-item__pay-direction__image']}
                  src={`${PUBLIC_PATH}/assets/pngs/pay-out.png`}
                />
                OUT
              </div>
            )}
          </TableCell>
          <TableCell>
            <div
              className={`${styles['payments-list-container__payment-item__status']} ${
                styles[`payments-list-container__payment-item__status--${payment.status}`]
              }`}
            >
              {payment.status}
            </div>
          </TableCell>
          <TableCell>{payment.paymentAmount}</TableCell>
          <TableCell>{payment.collectedAt}</TableCell>
          <TableCell>{payment.collector}</TableCell>
          <TableCell>{payment.paymentType}</TableCell>
          <TableCell>{payment.unitTypeName}</TableCell>
          <TableCell>{payment.roomId}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={2}>
                <Table
                  aria-label="payment-items"
                  size="small"
                  style={{ backgroundColor: '#F0F0F0' }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell> Fee name</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {payment &&
                      payment.paymentItems &&
                      payment.paymentItems.length > 0 &&
                      payment.paymentItems.map((paymentItem) => (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {paymentItem.feeName === 'Unit Price' &&
                            paymentItem.feeValue &&
                            paymentItem.duration
                              ? `${paymentItem.feeValue / paymentItem.duration}x${
                                  paymentItem.duration
                                }`
                              : paymentItem.feeName}
                          </TableCell>
                          <TableCell> {paymentItem.itemType || 'N/A'}</TableCell>
                          <TableCell>
                            {' '}
                            <NumberFormat
                              value={paymentItem.feeValue}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={
                                paymentItem.currency ? CURRENCY[paymentItem.currency] + ' ' : 'EGP '
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }
  return (
    <div className={styles['payments-list-container']}>
      <div className={styles['payments-list-container__title']}>
        <span>Payments</span>
      </div>
      <div className={styles['payments-list-container__payment-item']}>
        <div className={styles['payments-list-container__payment-item__form-field']}>
          <div className={styles['payments-list-container__payment-item__form-field__label']}>
            Day
          </div>
          <InputText
            data={{
              value: dueDateDay
            }}
            config={{
              maxRows: 1,
              autoFocus: false,
              type: InputTextType.number
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setDueDateDay(value);
              }
            }}
          />
        </div>
        <div className={styles['payments-list-container__payment-item__form-field']}>
          <div className={styles['payments-list-container__payment-item__form-field__label']}>
            month
          </div>
          <Select
            className={styles['payments-list-container__payment-item__form-field__select']}
            options={monthOptions}
            value={{ value: dueDateMonth?.value, label: dueDateMonth?.label }}
            onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
              setDueDateMonth({ value: value?.value || '', label: value?.label || '' });
            }}
          />
        </div>
        <div className={styles['payments-list-container__payment-item__form-field']}>
          <div className={styles['payments-list-container__payment-item__form-field__label']}>
            Year
          </div>
          <InputText
            data={{
              value: dueDateYear
            }}
            config={{
              maxRows: 1,
              autoFocus: false,
              type: InputTextType.number
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setDueDateYear(value);
              }
            }}
          />
        </div>
        <div className={styles['payments-list-container__payment-item__form-field']}>
          <div className={styles['payments-list-container__payment-item__form-field__label']}>
            Amount
          </div>
          <InputText
            data={{
              value: amount
            }}
            config={{
              maxRows: 1,
              autoFocus: false,
              type: InputTextType.number
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setAmount(value);
              }
            }}
          />
        </div>
        <div className={styles['payments-list-container__payment-item__form-field']}>
          <div className={styles['payments-list-container__payment-item__form-field__label']}>
            Order Id
          </div>
          <InputText
            data={{
              value: paymobOrderId
            }}
            config={{
              maxRows: 1,
              autoFocus: false,
              type: InputTextType.number
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setPaymobOrderId(value);
              }
            }}
          />
        </div>
        <div className={styles['payments-list-container__payment-item__form-field']}>
          <div className={styles['payments-list-container__payment-item__form-field__label']}>
            Collected by
          </div>
          <Select
            options={collectedByOptions}
            className={styles['payments-list-container__payment-item__form-field__select']}
            value={{ value: collectedBy?.value, label: collectedBy?.label }}
            onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
              setCollectedBy({ value: value?.value || '', label: value?.label || '' });
            }}
          />
        </div>
        <div className={styles['payments-list-container__payment-item__form-field']}>
          <div className={styles['payments-list-container__payment-item__form-field__label']}>
            Type
          </div>
          <Select
            options={paymentTypeOptions}
            className={styles['payments-list-container__payment-item__form-field__select']}
            value={{ value: paymentType?.value, label: paymentType?.label }}
            onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
              setPaymentType({ value: value?.value || '', label: value?.label || '' });
            }}
          />
        </div>
        <div className={styles['payments-list-container__payment-item__form-field']}>
          <div className={styles['payments-list-container__payment-item__form-field__label']}>
            Unit Type
          </div>
          <Select
            className={styles['payments-list-container__payment-item__form-field__select']}
            options={unitTypeOptions}
            value={{ value: unitType?.value, label: unitType?.label }}
            onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
              setUnitType({ value: value?.value || '', label: value?.label || '' });
            }}
          />
        </div>
        <div className={styles['payments-list-container__payment-item__form-field']}>
          <div className={styles['payments-list-container__payment-item__form-field__label']}>
            Tags
          </div>
          <Select
            className={styles['payments-list-container__payment-item__form-field__select']}
            options={paymentStatusOptions}
            value={{ value: status?.value, label: status?.label }}
            onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
              setStatus({ value: value?.value || '', label: value?.label || '' });
            }}
          />
        </div>
        <div className={styles['payments-list-container__payment-item__button-container']}>
          <Button
            onClick={() => {
              const query: Partial<PaymentSearch> = {};
              if (dueDateDay) {
                query.dueDateDay = Number(dueDateDay);
              }
              if (dueDateMonth) {
                query.dueDateMonth =
                  dueDateMonth?.value !== '' ? Number(dueDateMonth?.value) : undefined;
              }
              if (dueDateYear) {
                query.dueDateYear = Number(dueDateYear);
              }
              if (unitType) {
                query.unitTypeId = unitType?.value !== '' ? Number(unitType?.value) : undefined;
              }
              if (amount) {
                query.amount = Number(amount);
              }
              if (paymobOrderId) {
                query.paymobOrderId = paymobOrderId;
              }
              if (collectedBy) {
                query.collectedBy =
                  collectedBy?.value !== '' ? (collectedBy?.value as PaymentCollector) : undefined;
              }
              if (paymentType) {
                query.paymentType =
                  paymentType?.value !== '' ? (paymentType?.value as PaymentType) : undefined;
              }
              if (status) {
                query.status = status?.value !== '' ? (status?.value as PaymentStatus) : undefined;
              }
              setOffset(1);
              setSearchQuery(query);
              getPayments(query, 1, limit);
            }}
            className={styles['payments-list-container__payment-item__button-container__btn']}
            tracking={{
              action: 'close',
              category: 'unit-types',
              label: 'add-unit-type-modal'
            }}
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          >
            search
          </Button>
          <Button
            onClick={() => {
              reInitialize();
              setOffset(1);
              getPayments({}, 1, limit);
            }}
            className={styles['payments-list-container__payment-item__button-container__btn']}
            tracking={{
              action: 'clear',
              category: 'unit-types',
              label: 'add-unit-type-modal'
            }}
            size={isMobileView ? ButtonSize.full : ButtonSize.medium}
            type={ButtonType.gray}
          >
            clear
          </Button>
        </div>
      </div>
      {loadingData ? (
        <div className={styles['payments-list-container__loader']}>
          <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
        </div>
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell>Id #</TableCell>
                  <TableCell>Reservation</TableCell>
                  <TableCell>Direction</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>collectedAt</TableCell>
                  <TableCell>Collector</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>unit</TableCell>
                  <TableCell>room</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments?.length > 0 &&
                  payments?.map((payment) => <Row key={payment.id} payment={payment} />)}
              </TableBody>
            </Table>
          </TableContainer>
          {payments?.length === 0 && (
            <div className={styles['payments-list-container__noData']}>
              <img src={`${PUBLIC_PATH}/assets/jpeg/no-records.jpg`} alt="no-data" /> No Data
              Available
            </div>
          )}
        </>
      )}
      {listCount > 0 && (
        <Pagination
          itemCounts={listCount}
          itemsPerPage={limit}
          offset={offset}
          handlePageChange={(val) => {
            getNewPayment(val);
          }}
          handleItemsPerPage={(itemsPerPage) => {
            setLimit(itemsPerPage);
          }}
        />
      )}
    </div>
  );
};

export default connect(mapStateToProps)(PaymentsListContainer);

/**
 * function to to map redux state to component props
 * @param state root state or redux
 */
function mapStateToProps(state: RootState) {
  return {
    isAuthenticated: !!state.auth.accessToken
  };
}
