import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './delete-unit-type-group-modal.module.scss';
import { trackEvent } from '../../../../../shared/services/utils/tracking/tracking.service';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { UnitTypeGroupUiModel } from '../../../../../shared/ui-models/unit-type-group.ui-model';

export interface DeleteUnitTypeGroupModalProps {
  data: {
    unitTypeGroup?: UnitTypeGroupUiModel;
  };
  config: {
    showModal: boolean;
  };
  eventHandlers: {
    cancelHandler: () => void;
    deleteUnitTypeGroupHandler: (unitTypeGroup: UnitTypeGroupUiModel) => void;
  };
}

/**
 * functional component DeleteUnitTypeGroupModal
 * @param {DeleteUnitTypeGroupModalProps}
 */
export const DeleteUnitTypeGroupModal: React.FC<DeleteUnitTypeGroupModalProps> = ({
  data,
  config,
  eventHandlers
}) => {
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  const handleDeleteUnitTypeGroupSubmit = () => {
    if (data && data.unitTypeGroup) {
      eventHandlers.deleteUnitTypeGroupHandler(data.unitTypeGroup);
    }
  };

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.cancelHandler();
        }
      }}
    >
      <div className={styles['add-unitTypeGroup-modal']}>
        <div className={styles['add-unitTypeGroup-modal__title']}>
          {t('UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.DELETE_UNIT_TYPE_GROUP_MODAL.TITLE')}
        </div>
        <div className={styles['add-unitTypeGroup-modal__description']}>
          {`${t(
            'UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.DELETE_UNIT_TYPE_GROUP_MODAL.DESCRIPTION'
          )} ${data.unitTypeGroup?.name}`}
        </div>
      </div>
      <div className={styles['add-unitTypeGroup-modal--actions']}>
        <Button
          onClick={() => {
            eventHandlers.cancelHandler();
            trackEvent('cancel', {
              category: 'unitTypeGroup',
              label: 'delete-unitTypeGroup-modal'
            });
          }}
          tracking={{
            action: 'cancel',
            category: 'unitTypeGroup',
            label: 'delete-unitTypeGroup-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          type={ButtonType.primaryInverted}
        >
          {t('UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.DELETE_UNIT_TYPE_GROUP_MODAL.CANCEL_BUTTON')}
        </Button>
        <Button
          onClick={handleDeleteUnitTypeGroupSubmit}
          tracking={{
            action: 'submit',
            category: 'unitTypeGroups',
            label: 'delete-unitTypeGroup-modal'
          }}
          type={ButtonType.danger}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          {t('UNIT_TYPE_GROUP_MODULE.LIST_CONTAINER.DELETE_UNIT_TYPE_GROUP_MODAL.SUBMIT_BUTTON')}
        </Button>
      </div>
    </Modal>
  );
};
