import React, { useEffect, useState } from 'react';
import {
  ReservationListUiModel,
  ReservationUiModel
} from '../../../../../shared/ui-models/reservation.ui-model';
import { useHistory } from 'react-router';
import { APP_ROUTES } from '../../../../../shared/config/routes-config';
import { RouteConfig } from '../../../../../shared/interfaces/routes-config.interface';
import styles from './pending-actions.module.scss';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Countdown from 'react-countdown';
import { isErrorInfo, ErrorInfo } from '../../../../../shared/interfaces/error-info.interface';
import WarningIcon from '@material-ui/icons/Warning';
import { getBookingRequests } from '../../../../../BookingModule/shared/services/data.service';
import { BookingRequestUiModel } from '../../../../../shared/ui-models/booking-request.ui-model';
import { BookingRequestStatus } from '../../../../../shared/enums/booking-request-status.enum';
import { getAllReservations } from '../../../../../ReservationModule/shared/services/data.service';
import { ReservationStatus } from '../../../../../shared/enums/reservation-status';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
    }
  }
});
const useSelectedRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
      fontSize: '1.5rem'
    }
  }
});

export const PendingActionsComponent: React.FC = () => {
  const [pendingReservations, setPendingReservations] = useState<ReservationUiModel[]>();
  const [pendingBooking, setPendingBooking] = useState<BookingRequestUiModel[]>();
  const [activeTab, setActiveTab] = useState<string>('reservation');
  const history = useHistory();

  useEffect(() => {
    getPendingReservation({ status: ReservationStatus.CHECKED_IN_STARTED });
  }, []);

  const counterRenderer = ({
    hours,
    minutes,
    seconds
  }: {
    hours: number;
    minutes: number;
    seconds: number;
  }) => {
    // Render a countdown
    return (
      <b className={styles['pending-actions__reservation-item__status--highlighted']}>
        {hours}:{minutes}:{seconds}
      </b>
    );
  };
  const getPendingReservation = (query: object) => {
    getAllReservations(query, 1, 0).then((response: ReservationListUiModel | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setPendingBooking([]);
        setPendingReservations(response.reservationList);
      }
    });
  };
  const getPendingBooking = (query: object) => {
    getBookingRequests(query).then((response: BookingRequestUiModel[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setPendingReservations([]);
        setPendingBooking(response as BookingRequestUiModel[]);
      }
    });
  };
  function ReservationRow(props: { reservation: ReservationUiModel }) {
    const { reservation } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const selectedClasses = useSelectedRowStyles();

    return (
      <>
        <TableRow
          className={
            reservation.status === ReservationStatus.CHECKED_IN_STARTED
              ? selectedClasses.root
              : classes.root
          }
          onClick={() => {
            history.push(
              (APP_ROUTES.RESERVATION_MODULE.CHILDREN as RouteConfig).EDIT.FULL_PATH.replace(
                ':id',
                reservation?.id as string
              )
            );
          }}
        >
          <TableCell>{reservation.primaryGuestName}</TableCell>
          <TableCell>
            <div
              className={`${styles['pending-actions__reservation-item__status']} ${
                styles[`pending-actions__reservation-item__status--${reservation?.status}`]
              }`}
            >
              {reservation.status ? reservation.status.replace('_', ' ') : ''}{' '}
              {reservation.status === ReservationStatus.CONFIRMATION_PENDING ||
              reservation.status === ReservationStatus.CHECKED_IN_STARTED ? (
                <Countdown
                  date={
                    new Date(
                      new Date(Number(reservation.checkinExpiresAt)).getTime() + 60 * 60 * 24 * 1000
                    )
                  }
                  renderer={counterRenderer}
                />
              ) : (
                ''
              )}
            </div>
            {reservation.guestIds.length === 0 && (
              <div
                className={`${styles['pending-actions__reservation-item__status']} ${
                  styles[`pending-actions__reservation-item__status--canceled`]
                }`}
              >
                {reservation.guestIds.length === 0 ? 'Guest Data incomplete' : ''}
              </div>
            )}
          </TableCell>
          <TableCell>{new Date(reservation.checkInDate).toDateString()}</TableCell>
          <TableCell>{new Date(reservation.checkOutDate).toDateString()}</TableCell>
          <TableCell component="th" scope="row">
            {reservation.id}
          </TableCell>
        </TableRow>
        <TableRow></TableRow>
      </>
    );
  }
  function BookingRow(props: { reservation: BookingRequestUiModel }) {
    const { reservation } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
    const selectedClasses = useSelectedRowStyles();

    return (
      <>
        <TableRow
          className={
            reservation.status === BookingRequestStatus.IN_SCREENING
              ? selectedClasses.root
              : classes.root
          }
        >
          <TableCell>{reservation.guestInfo.name}</TableCell>

          <TableCell>
            <div
              className={`${styles['pending-actions__reservation-item__status']} ${
                styles[`pending-actions__reservation-item__status--${reservation?.status}`]
              }`}
            >
              {reservation.status ? reservation.status.replace('_', ' ') : ''}{' '}
              {reservation.status === BookingRequestStatus.IN_SCREENING ? (
                <Countdown
                  date={
                    new Date(new Date(Number(reservation.bookedAt)).getTime() + 60 * 60 * 24 * 1000)
                  }
                  renderer={counterRenderer}
                />
              ) : (
                ''
              )}
            </div>
          </TableCell>
          <TableCell>
            {new Date(Number(reservation.bookingInfo.checkInDate)).toDateString()}
          </TableCell>
          <TableCell>
            {new Date(Number(reservation.bookingInfo.checkOutDate)).toDateString()}
          </TableCell>
          <TableCell component="th" scope="row">
            {reservation.requestId}
          </TableCell>
        </TableRow>
        <TableRow></TableRow>
      </>
    );
  }

  return (
    <div className={styles['pending-actions']}>
      <div className={styles['pending-actions--title']}>
        <WarningIcon className={styles['pending-actions--title_icon']} /> Attention Required
      </div>
      <div className={styles['pending-actions--header']}>
        <span
          className={`${styles['pending-actions--header_left']}
       ${activeTab && activeTab === 'reservation' ? styles['pending-actions--header_active'] : ''}`}
          onClick={() => {
            getPendingReservation({ status: ReservationStatus.CHECKED_IN_STARTED });
            setActiveTab('reservation');
          }}
        >
          Pending Reservations
        </span>
        <span
          className={`${styles['pending-actions--header_right']}
          ${activeTab && activeTab === 'booking' ? styles['pending-actions--header_active'] : ''}`}
          onClick={() => {
            setActiveTab('booking');
            getPendingBooking({ status: BookingRequestStatus.IN_SCREENING });
          }}
        >
          Pending Booking Requests
        </span>
      </div>
      <div className={styles['pending-actions--table']}>
        <TableContainer component={Paper}>
          <Table aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell>Contact Name</TableCell>
                <TableCell>Tags</TableCell>
                <TableCell>Check In</TableCell>
                <TableCell>Check Out</TableCell>
                <TableCell>Id #</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pendingReservations &&
                pendingReservations?.length > 0 &&
                pendingReservations?.map((reservation: ReservationUiModel) => (
                  <ReservationRow key={reservation.id} reservation={reservation} />
                ))}
              {pendingBooking &&
                pendingBooking?.length > 0 &&
                pendingBooking?.map((reservation: BookingRequestUiModel) => (
                  <BookingRow key={reservation?.id} reservation={reservation} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};
