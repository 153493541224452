import { ApiResponse } from 'apisauce';
import { ErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { ResponseModel } from '../../../shared/interfaces/response-model.interface';
import configurationMapper from '../../../shared/mappers/configuration.mapper';
import { ConfigurationModel } from '../../../shared/models/configuration.model';
import api from '../../../shared/services/utils/api/api.service';
import errorHelper from '../../../shared/services/utils/api/error-helper.service';
import { ConfigurationUiModel } from '../../../shared/ui-models/configuration.ui.model';
import { getModuleName } from './config.service';

export function getConfigurationRequest(): Promise<ConfigurationModel[] | ErrorInfo> {
  return api
    .getConfiguration()
    .then((response: ApiResponse<ResponseModel<ConfigurationModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return configurationMapper.mapToUIList(data?.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.getConfiguration as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.getConfiguration as any).displayName
        );
      }
    });
}

export function updateConfigurationRequest(
  configuration: ConfigurationUiModel[]
): Promise<ConfigurationModel[] | ErrorInfo> {
  return api
    .updateConfiguration(configuration)
    .then((response: ApiResponse<ResponseModel<ConfigurationModel[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return configurationMapper.mapToUIList(data?.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.getConfiguration as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.getConfiguration as any).displayName
        );
      }
    });
}
