import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './booking-request-details.module.scss';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import WcIcon from '@material-ui/icons/Wc';
import LinkIcon from '@material-ui/icons/Link';
import InfoIcon from '@material-ui/icons/Info';
import BathtubIcon from '@material-ui/icons/Bathtub';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import { BookingRequestUiModel } from '../../../../../shared/ui-models/booking-request.ui-model';
import { BookingRequestStatus } from '../../../../../shared/enums/booking-request-status.enum';
import { getUnitTypeDetails } from '../../../../../shared/services/data/unit-type.data';
import PersonIcon from '@material-ui/icons/Person';
import moment from 'moment';
import { getModuleName } from '../../../../shared/services/booking.service';
import { UnitTypeUiModel } from '../../../../../shared/ui-models/unit-type.ui-model';
import { ErrorInfo, isErrorInfo } from '../../../../../shared/interfaces/error-info.interface';
import { PUBLIC_PATH } from '../../../../../shared/config/routes-config';
import NumberFormat from 'react-number-format';
import { FeeType } from '../../../../../shared/enums/amount-type.enum';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { CURRENCY } from '../../../../../shared/enums/currency.enum';

export interface BookingRequestDetailsProps {
  data: BookingRequestUiModel;
  //   config: {
  //   };
  //   eventHandlers: {
  //     cancelHandler: () => void;
  //     deleteAmenityHandler: (amenity: AmenityUiModel) => void;
  //   };
}

/**
 * functional component BookingRequestDetails
 * @param {BookingRequestDetailsProps}
 */
export const BookingRequestDetails: React.FC<BookingRequestDetailsProps> = ({ data }) => {
  const [unitType, setUnitType] = useState<UnitTypeUiModel>();
  const [loadingUnitType, setLoadingUnitType] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    getUnitTypeDetails(data.bookingInfo.unitTypeId, getModuleName()).then(
      (response: UnitTypeUiModel | ErrorInfo) => {
        setLoadingUnitType(false);
        if (!isErrorInfo(response)) {
          setUnitType(response);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles['request-details']}>
      <div className={styles['request-details__info-wrapper']}>
        {data.guestInfo && (
          <div className={styles['request-details__guest-info']}>
            <div className={styles['request-details__guest-info__header']}>Guest details</div>
            <div className={styles['request-details__guest-info__wrapper']}>
              <PersonIcon color={'inherit'} fontSize={'inherit'} />
              <div className={styles['request-details__guest-info__data']}>
                {data.guestInfo.name}
              </div>
            </div>
            <div className={styles['request-details__guest-info__wrapper']}>
              <EmailIcon color={'inherit'} fontSize={'inherit'} />
              <div className={styles['request-details__guest-info__data']}>
                {data.guestInfo.email}
              </div>
            </div>
            <div className={styles['request-details__guest-info__wrapper']}>
              <PhoneIcon color={'inherit'} fontSize={'inherit'} />
              <div className={styles['request-details__guest-info__data']}>
                {data.guestInfo.phoneNumber}
              </div>
            </div>
            <div className={styles['request-details__guest-info__wrapper']}>
              <WcIcon color={'inherit'} fontSize={'inherit'} />
              <div className={styles['request-details__guest-info__data']}>
                {data.guestInfo.gender}
              </div>
            </div>
            <div className={styles['request-details__guest-info__wrapper']}>
              <LinkIcon color={'inherit'} fontSize={'inherit'} />
              <div className={styles['request-details__guest-info__data']}>
                {data.guestInfo.socialLink}
              </div>
            </div>
            {data.guestInfo.moreInfo && (
              <div className={styles['request-details__guest-info__wrapper']}>
                <InfoIcon color={'inherit'} fontSize={'inherit'} />
                <div className={styles['request-details__guest-info__data']}>
                  {data.guestInfo.moreInfo}
                </div>
              </div>
            )}
          </div>
        )}
        <div className={styles['request-details__unit-info']}>
          <div className={styles['request-details__unit-info__header']}>Unit details</div>
          {!loadingUnitType && unitType ? (
            <div className={styles['request-details__unit-info__item']}>
              <div className={styles['request-details__unit-info__item__image-wrapper']}>
                <img
                  className={styles['request-details__unit-info__item__image-wrapper__image']}
                  alt="unit-type"
                  src={unitType?.coverImage || `${PUBLIC_PATH}/assets/pngs/unit-type-default.png`}
                />
              </div>
              <div className={styles['request-details__unit-info__item__header']}>
                <div
                  className={`${styles['request-details__unit-info__item__header__title']} ${
                    !unitType.name
                      ? styles['request-details__unit-info__item__header__title--untitled']
                      : ''
                  } `}
                >
                  {unitType.name || t('UNIT_TYPE_MODULE.LIST_CONTAINER.UNTITLED_AMENITY')}
                  {/* <p
              className={
                styles['request-details__unit-info__item__header__title__created-by']
              }
            >
              {t('UNIT_TYPE_MODULE.LIST_CONTAINER.MODIFIED_BY')}
              <span>{unitType.modifiedByUsername}</span>
            </p> */}
                </div>
                <div
                  className={
                    styles['request-details__unit-info__item__header-details_property-details']
                  }
                >
                  <div
                    className={
                      styles[
                        'request-details__unit-info__item__header-details_property-details-name'
                      ]
                    }
                  >
                    property Name:
                    <a
                      href={`${process.env.PUBLIC_URL}/properties/edit/${unitType.propertyId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {unitType.propertyName}
                      <ArrowForward color="inherit" fontSize="inherit" />
                    </a>
                  </div>
                  <div
                    className={
                      styles[
                        'request-details__unit-info__item__header-details_property-details-area'
                      ]
                    }
                  >
                    area:
                    <a
                      href={`${process.env.PUBLIC_URL}/properties/edit/${unitType.areaId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {unitType.area} <ArrowForward color="inherit" fontSize="inherit" />
                    </a>
                  </div>
                </div>
                {unitType.availableFrom && (
                  <div
                    className={
                      styles[
                        'request-details__unit-info__item__header-details_property-availability'
                      ]
                    }
                  >
                    <span>
                      Available from{' '}
                      {unitType.availableFrom && new Date(unitType.availableFrom).toDateString()}
                    </span>
                  </div>
                )}
              </div>
              <div className={styles['request-details__unit-info__item__content']}>
                <div className={styles['request-details__unit-info__item__content__responses']}>
                  <AttachMoneyIcon
                    className={styles['request-details__unit-info__item__content__responses__icon']}
                  />
                  <span
                    className={
                      styles['request-details__unit-info__item__content__responses__number']
                    }
                  >
                    {unitType?.unitPrice || 0} &nbsp;
                  </span>
                  <span
                    className={
                      styles['request-details__unit-info__item__content__responses__response-text']
                    }
                  >
                    {t('UNIT_TYPE_MODULE.LIST_CONTAINER.MONTHLY_PRICE')}
                  </span>
                </div>
                <div className={styles['request-details__unit-info__item__content__responses']}>
                  <MeetingRoomIcon
                    className={styles['request-details__unit-info__item__content__responses__icon']}
                  />
                  <span
                    className={
                      styles['request-details__unit-info__item__content__responses__number']
                    }
                  >
                    {unitType?.units?.length || 0} &nbsp;
                  </span>
                  <span
                    className={
                      styles['request-details__unit-info__item__content__responses__response-text']
                    }
                  >
                    {t('UNIT_TYPE_MODULE.LIST_CONTAINER.UNITS_COUNT')}
                  </span>
                </div>
                <div className={styles['request-details__unit-info__item__content__responses']}>
                  <BathtubIcon
                    className={styles['request-details__unit-info__item__content__responses__icon']}
                  />
                  <span
                    className={
                      styles['request-details__unit-info__item__content__responses__number']
                    }
                  >
                    {unitType?.amenities?.length || 0} &nbsp;
                  </span>
                  <span
                    className={
                      styles['request-details__unit-info__item__content__responses__response-text']
                    }
                  >
                    {t('UNIT_TYPE_MODULE.LIST_CONTAINER.AMENITIES_COUNT')}
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles['request-details__loader']}>
              <img src={`${PUBLIC_PATH}/assets/svgs/loader.svg`} alt="loader" />
            </div>
          )}
        </div>
      </div>

      {data.status !== BookingRequestStatus.NOT_VERIFIED && (
        <div className={styles['request-details__offer-info']}>
          <div className={styles['request-details__offer-info__header']}>Offer details</div>
          {data.bookingInfo && (
            <div className={styles['request-details__offer-info__dates']}>
              <div className={styles['request-details__offer-info__dates__item']}>
                <div className={styles['request-details__offer-info__dates__item__header']}>
                  CHECK IN DATE
                </div>
                <div className={styles['request-details__offer-info__dates__item__date']}>
                  {data.bookingInfo.checkInDate && typeof data.bookingInfo.checkInDate === 'string'
                    ? moment(Number(data.bookingInfo.checkInDate)).format('DD-MM-YYYY')
                    : typeof data.bookingInfo.checkInDate === 'number'
                    ? moment(data.bookingInfo.checkInDate).format('DD-MM-YYYY')
                    : 'NOT_AVAILABLE'}
                </div>
              </div>
              <div className={styles['request-details__offer-info__dates__item']}>
                <div className={styles['request-details__offer-info__dates__item__header']}>
                  CHECK OUT DATE
                </div>
                <div className={styles['request-details__offer-info__dates__item__date']}>
                  {data.bookingInfo.checkOutDate &&
                  typeof data.bookingInfo.checkOutDate === 'string'
                    ? moment(Number(data.bookingInfo.checkOutDate)).format('DD-MM-YYYY')
                    : typeof data.bookingInfo.checkOutDate === 'number'
                    ? moment(data.bookingInfo.checkOutDate).format('DD-MM-YYYY')
                    : 'NOT_AVAILABLE'}
                </div>
              </div>
            </div>
          )}
          {data.offerInfo && data.offerInfo.oneTime && (
            <div className={styles['request-details__offer-info__prices']}>
              <div className={styles['request-details__offer-info__prices__header']}>
                {data.offerInfo.recurring && data.offerInfo.recurring.length > 0
                  ? 'One Time Payment'
                  : 'Payments'}
              </div>
              {data.offerInfo.oneTime.map((item) => (
                <div className={styles['request-details__offer-info__prices__item']}>
                  <div className={styles['request-details__offer-info__prices__item__name']}>
                    {item.feeName === 'Unit Price' && item.originalPrice && item.duration
                      ? item.originalPrice / item.duration
                      : item.feeName}{' '}
                    {item.feeType === FeeType.PERCENTAGE
                      ? `(${item.percentValue}%)`
                      : item.duration
                      ? item.mode === 'daily'
                        ? ` x ${item.duration} night(s)`
                        : item.mode === 'monthly'
                        ? ` x ${item.duration} month(s)`
                        : unitType?.reservationMode === 'daily'
                        ? ` x ${item.duration} night(s)`
                        : ` x ${item.duration} month(s)`
                      : ''}
                  </div>
                  {item.feeType !== FeeType.PERCENTAGE &&
                    item.originalPrice &&
                    item.originalPrice !== item.feeValue && (
                      <div
                        className={styles['request-details__offer-info__prices__item__discount']}
                      >
                        <NumberFormat
                          value={item.originalPrice}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={
                            item.currency
                              ? CURRENCY[item.currency] + ' '
                              : unitType?.currency
                              ? CURRENCY[unitType?.currency] + ' '
                              : 'EGP '
                          }
                        />
                      </div>
                    )}
                  <div className={styles['request-details__offer-info__prices__item__total']}>
                    <NumberFormat
                      value={item.feeValue}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={
                        item.currency
                          ? CURRENCY[item.currency] + ' '
                          : unitType?.currency
                          ? CURRENCY[unitType?.currency] + ' '
                          : 'EGP '
                      }
                    />
                  </div>
                </div>
              ))}
              {data.offerInfo.oneTime.length ? (
                <div className={styles['request-details__offer-info__prices__item']}>
                  <div className={styles['request-details__offer-info__prices__item__name']}>
                    <b>Total</b>
                  </div>
                  <div className={styles['request-details__offer-info__prices__item__sum']}>
                    <NumberFormat
                      value={data.offerInfo.oneTime.reduce(
                        (prev, cur) => prev + Number(cur.feeValue),
                        0
                      )}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={
                        data.offerInfo.oneTime.length > 0 && data.offerInfo.oneTime[0].currency
                          ? CURRENCY[data.offerInfo.oneTime[0].currency] + ' '
                          : unitType?.currency
                          ? CURRENCY[unitType?.currency] + ' '
                          : 'EGP '
                      }
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
          {data.offerInfo && data.offerInfo.recurring && (
            <div className={styles['request-details__offer-info__prices']}>
              <div className={styles['request-details__offer-info__prices__header']}>
                Monthly Payment
              </div>
              {data.offerInfo.recurring.map((item) => (
                <div className={styles['request-details__offer-info__prices__item']}>
                  <div className={styles['request-details__offer-info__prices__item__name']}>
                    {item.feeName}{' '}
                    {item.feeType === FeeType.PERCENTAGE ? `(${item.percentValue}%)` : ''}
                    {item.startAtMonth ? `(starting from month ${item.startAtMonth})` : ''}
                  </div>
                  {item.feeType !== FeeType.PERCENTAGE &&
                    item.originalPrice &&
                    item.originalPrice !== item.feeValue && (
                      <div
                        className={styles['request-details__offer-info__prices__item__discount']}
                      >
                        <NumberFormat
                          value={item.originalPrice}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={
                            item.currency
                              ? CURRENCY[item.currency] + ' '
                              : unitType?.currency
                              ? CURRENCY[unitType?.currency] + ' '
                              : 'EGP '
                          }
                        />
                      </div>
                    )}
                  <div className={styles['request-details__offer-info__prices__item__total']}>
                    <NumberFormat
                      value={item.feeValue}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={
                        item.currency
                          ? CURRENCY[item.currency] + ' '
                          : unitType?.currency
                          ? CURRENCY[unitType?.currency] + ' '
                          : 'EGP '
                      }
                    />
                  </div>
                </div>
              ))}
              {data.offerInfo.recurring.length ? (
                <div className={styles['request-details__offer-info__prices__item']}>
                  <div className={styles['request-details__offer-info__prices__item__name']}>
                    <b>Total</b>
                  </div>
                  <div className={styles['request-details__offer-info__prices__item__sum']}>
                    <NumberFormat
                      value={data.offerInfo.recurring.reduce(
                        (prev, cur) => prev + Number(cur.feeValue),
                        0
                      )}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={
                        data.offerInfo.recurring.length > 0 && data.offerInfo.recurring[0].currency
                          ? CURRENCY[data.offerInfo.recurring[0].currency] + ' '
                          : unitType?.currency
                          ? CURRENCY[unitType?.currency] + ' '
                          : 'EGP '
                      }
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
