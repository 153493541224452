import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './create-payout-modal.module.scss';
import Select, { ValueType } from 'react-select';
import ReactDatePicker from 'react-datepicker';
import Dropzone from 'react-dropzone-uploader';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { UnitTypeUiModel } from '../../../../../shared/ui-models/unit-type.ui-model';
import { CreatePayOutDto } from '../../../../../shared/models/payout.model';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize
} from '../../../../../shared/components/core-ui/button/button.component';
import {
  InputText,
  InputTextErrorMode,
  InputTextType
} from '../../../../../shared/components/core-ui/input-text/input-text.component';
import { getBookAvailability } from '../../../../../ReservationModule/shared/services/data.service';
import { ErrorInfo, isErrorInfo } from '../../../../../shared/interfaces/error-info.interface';
import { BookAvailabilityResponseModel } from '../../../../../shared/ui-models/book.ui-model';
import { ChooseUnitType } from '../../../../../shared/components/widgets/choose-unit-type-modal/choose-unit-type.component';
import { UnitTypeGroupUiModel } from '../../../../../shared/ui-models/unit-type-group.ui-model';
import { trackPage } from '../../../../../shared/services/utils/tracking/tracking.service';
import { getAllUnitTypes } from '../../../../../shared/services/data/unit-type.data';
import { getAllUnitTypeGroups } from '../../../../../shared/services/data/lookups.data';
import { getModuleName } from '../../../../../UnitTypeModule/shared/services/unit-type.service';

export interface PayoutModalProps {
  config: {
    showModal: boolean;
    unitType?: UnitTypeUiModel;
  };
  eventHandlers: {
    closeHandler: () => void;
    addPayoutHandler: (unitPayout: CreatePayOutDto) => void;
  };
}

/**
 * functional component CreatePayoutModal
 * @param {PayoutModalProps}
 */
export const CreatePayoutModal: React.FC<PayoutModalProps> = ({ config, eventHandlers }) => {
  const [description, setDescription] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [unitTypeID, setUnitTypeID] = useState<FormElementData<string>>({
    value: config.unitType?.id || '',
    isValid: false
  });
  const [amount, setAmount] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [room, setRoom] = useState<string | undefined>('');
  const [file, setFile] = useState<File>();

  const [payDate, setPayDate] = useState<FormElementData<Date | null>>({
    value: new Date(),
    isValid: true
  });
  const [roomOptions, setRoomOptions] = useState<{ value: string | undefined; label: string }[]>(
    []
  );

  const [unitTypeGroupsList, setUnitTypeGroupsList] = useState<UnitTypeGroupUiModel[]>([]);
  const [unitType, setUnitType] = useState<UnitTypeUiModel[]>([]);
  const [showSelectedUnit, setShowSelectedUnit] = useState<boolean>(false);
  const [selectedUnit, setSelectedUnit] = useState<UnitTypeUiModel>({});

  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  useEffect(() => {
    trackPage('choose-unit-type');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    getAllUnitTypes(getModuleName(), 0, -1, {}).then((response) => {
      if (!isErrorInfo(response)) {
        setUnitType(response.unitList);
      }
    });

    getAllUnitTypeGroups(getModuleName()).then((response: UnitTypeGroupUiModel[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        setUnitTypeGroupsList(response);
      }
    });
  }, []);

  const reInitialize = () => {
    setAmount({ value: '', isValid: false });
    setRoom('');
  };
  const handleNewUnitTypeSubmit = () => {
    setShowSelectedUnit(false);
    reInitialize();
    eventHandlers.addPayoutHandler({
      description: description.value,
      unitTypeId: Number(unitTypeID.value),
      roomId: room || '',
      paymentDate: payDate.value?.getTime() || Date.now(),
      landlordId: config.unitType?.landlordId || '',
      amount: Number(amount.value) || 0,
      file
    });
  };

  useEffect(() => {
    const rooms = config?.unitType?.units
      ? config.unitType.units.map((room) => ({ label: room.shortName, value: room.unitId }))
      : [];
    setRoomOptions(rooms);
  }, []);

  useEffect(() => {
    const rooms = selectedUnit?.units
      ? selectedUnit?.units.map((room) => ({ label: room.shortName, value: room.unitId }))
      : [];
    setRoomOptions(rooms);
  }, [selectedUnit]);

  function getAvailability(unitTypeId: string) {
    return getBookAvailability(unitTypeId).then((resp) => {
      if (!isErrorInfo(resp)) {
        const bookAvailabilityResponse = resp as BookAvailabilityResponseModel[];
        let availabeDates: Date[] = [];
        bookAvailabilityResponse.forEach((availObj) => {
          const dates = getDaysArray(availObj.from, availObj.to);
          availabeDates = [...availabeDates, ...dates];
        });
        // setBookAvailability(availabeDates);
      }
    });
  }

  const getDaysArray = (s: any, e: any) => {
    const a: Date[] = [];
    for (const d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) {
      a.push(new Date(d));
    }
    return a;
  };

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.closeHandler();
        }
      }}
    >
      <div className={styles['create-payout-modal']}>
        <div className={styles['create-payout-modal__title']}>Create Payout</div>

        {!showSelectedUnit && (
          <ChooseUnitType
            data={{
              unitTypes: unitType,
              unitTypeGroups: unitTypeGroupsList
            }}
            eventHandlers={{
              selectedUnitType: (selectedUnit) => {
                getAvailability(selectedUnit.id || '').then(() => {
                  console.log(selectedUnit);
                  setSelectedUnit(selectedUnit);
                  setUnitTypeID({ value: selectedUnit?.id, isValid: true });
                  setShowSelectedUnit(true);
                });
              }
            }}
          ></ChooseUnitType>
        )}

        {unitTypeID.value && showSelectedUnit && (
          <>
            <div className={styles['create-payout-modal__description']}>
              Enter Description :
              <InputText
                data={{
                  defaultValue: '',
                  placeholder: 'description'
                }}
                config={{
                  maxRows: 1,
                  autoFocus: true,
                  showErrorMode: InputTextErrorMode.onFirstBlur
                }}
                eventHandlers={{
                  onChangeHandler: (value: string, isValid: boolean) => {
                    setDescription({ value, isValid });
                  }
                }}
              />
            </div>
            <div className={styles['create-payout-modal__description']}>
              Enter unit type :
              <InputText
                data={{
                  defaultValue: unitTypeID.value,
                  placeholder: 'unit'
                }}
                config={{
                  maxRows: 1,
                  disabled: true
                }}
                eventHandlers={{
                  onChangeHandler: (value: string, isValid: boolean) => {
                    setUnitTypeID({ value, isValid });
                  }
                }}
              />
            </div>
            <div className={styles['create-payout-modal__description']}>
              Enter amount :
              <InputText
                data={{
                  defaultValue: amount.value,
                  placeholder: 'amount'
                }}
                config={{
                  maxRows: 1,
                  type: InputTextType.number,
                  required: true
                }}
                eventHandlers={{
                  onChangeHandler: (value: string, isValid: boolean) => {
                    setAmount({ value, isValid });
                  }
                }}
              />
            </div>
            <div className={styles['create-payout-modal__description']}>
              select room:
              <Select
                className={styles['create-payout-modal__select']}
                options={roomOptions}
                onChange={(
                  value: ValueType<{ value: string | undefined; label: string }, false>
                ) => {
                  setRoom(value ? value.value : '');
                }}
              />
            </div>
            <div className={styles['add-guest-modal__description']}>
              pay day:
              <ReactDatePicker
                selected={payDate.value}
                onChange={(date) => setPayDate({ value: date as Date, isValid: true })}
              />
            </div>
            <Dropzone
              classNames={{
                input: styles['create-payout-modal__dropzone']
              }}
              inputContent={() => (
                <div className={styles['create-payout-modal__dropzone__upload-container']}>
                  <CloudUploadIcon fontSize={'large'} />
                  Upload Identification Document
                </div>
              )}
              accept="*"
              maxFiles={1}
              multiple={false}
              onChangeStatus={(file) => {
                if (file.meta.status !== 'removed') {
                  setFile(file.file);
                }
              }}
            />
            <div className={styles['create-payout-modal--actions']}>
              <Button
                onClick={() => {
                  setShowSelectedUnit(false);
                  eventHandlers.closeHandler();
                }}
                tracking={{
                  action: 'close',
                  category: 'unit-types',
                  label: 'create-payout-modal'
                }}
                size={isMobileView ? ButtonSize.full : ButtonSize.medium}
              >
                Close
              </Button>
              <Button
                disabled={
                  !description.isValid || description.value === '' || !room || !payDate || !amount
                }
                onClick={handleNewUnitTypeSubmit}
                tracking={{
                  action: 'submit',
                  category: 'unit-type',
                  label: 'create-payout-modal'
                }}
                size={isMobileView ? ButtonSize.full : ButtonSize.medium}
              >
                submit
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
