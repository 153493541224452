import { ApiResponse } from 'apisauce';
import { ErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { ResponseModel } from '../../../shared/interfaces/response-model.interface';
import api from '../../../shared/services/utils/api/api.service';
import errorHelper from '../../../shared/services/utils/api/error-helper.service';
import { getModuleName } from './Kpis.service';
import { KPIChartsSearch } from '../../../shared/models/kpis.model';

export function getMonthlyRevenueWithCurrency(
  monthsCount: number,
  payload: KPIChartsSearch = {}
): Promise<{ month: string; egpRevenue: number, usdRevenue: number }[] | ErrorInfo> {
  return api
    .getMonthlyRevenueWithCurrency(monthsCount, payload)
    .then((response: ApiResponse<ResponseModel<{}>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return data.data as { month: string; egpRevenue: number, usdRevenue: number }[];
        } else {
          return errorHelper(0, '', getModuleName(), (api.deleteArea as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.deleteArea as any).displayName
        );
      }
    });
}

export function getOccupancyAndAverageDailyRate(
  monthsCount: number,
  payload: KPIChartsSearch = {}
): Promise<{ month: string; rate: number, ADR_EGP: number,ADR_USD: number,
   RPAR_EGP: number, RPAR_USD: number,
   MonthlyRevenueEGP: number, MonthlyRevenueUSD: number }[] | ErrorInfo> {
  return api
    .getOccupancyAndAverageDailyRate(monthsCount, payload)
    .then((response: ApiResponse<ResponseModel<{}>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return data.data as { month: string; rate: number, ADR_EGP: number,ADR_USD: number, RPAR_EGP: number,
             RPAR_USD: number, MonthlyRevenueEGP: number, MonthlyRevenueUSD: number }[];
        } else {
          return errorHelper(0, '', getModuleName(), (api.deleteArea as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.deleteArea as any).displayName
        );
      }
    });
}

export function getRevenuePerArea(payload: KPIChartsSearch = {}): Promise<{ area: string; share: number}[] | ErrorInfo> {
  return api
    .getRevenuePerArea(payload)
    .then((response: ApiResponse<ResponseModel<{}>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return data.data as { area: string; share: number}[];
        } else {
          return errorHelper(0, '', getModuleName(), (api.deleteArea as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.deleteArea as any).displayName
        );
      }
    });
}

export function getInternationAndGuest(payload: KPIChartsSearch = {}): Promise<{ egyptians: number; foreigners: number } | ErrorInfo> {
  return api
    .getInternationAndGuest(payload)
    .then((response: ApiResponse<ResponseModel<{}>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return data.data as { egyptians: number; foreigners: number };
        } else {
          return errorHelper(0, '', getModuleName(), (api.deleteArea as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.deleteArea as any).displayName
        );
      }
    });
}


export function getSourceOfBooking(
  monthsCount: number,
  payload: KPIChartsSearch = {}
): Promise<{ month: string; salesChannels: number, birdnest: number }[] | ErrorInfo> {
  return api
    .getSourceOfBooking(monthsCount, payload)
    .then((response: ApiResponse<ResponseModel<{}>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return data.data as { month: string; salesChannels: number, birdnest: number }[];
        } else {
          return errorHelper(0, '', getModuleName(), (api.deleteArea as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.deleteArea as any).displayName
        );
      }
    });
}
