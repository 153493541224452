import React from 'react';
import { Route } from 'react-router-dom';
import { SETTINGS_ROUTES } from '../shared/config/routes-config';
import { ConfigurationContainer } from './containers/configuration.container';
/**
 * function BookingModule components
 */
export const ConfigurationModule: React.FC = () => {
  return (
    <>
      <Route
        exact
        path={SETTINGS_ROUTES.ACCOUNT_CONFIGURATION_MODULE.FULL_PATH}
        component={ConfigurationContainer}
      />
    </>
  );
};
