import { ApiResponse } from 'apisauce';
import { ErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { ResponseModel } from '../../../shared/interfaces/response-model.interface';
import {
  PaymentModel,
  PaymentModelData,
  PaymentSearch
} from '../../../shared/models/payment.model';
import api from '../../../shared/services/utils/api/api.service';
import errorHelper from '../../../shared/services/utils/api/error-helper.service';
import { getModuleName } from './payments.service';
import paymentMapper from '../../../shared/mappers/payment.mapper';
import { PaymentListUiModel } from '../../../shared/ui-models/payment.ui-model';

export function paymentSearch(
  query: Partial<PaymentSearch>,
  offset: number,
  limit: number
): Promise<PaymentListUiModel | ErrorInfo> {
  return api
    .paymentSearch(query, offset, limit)
    .then((response: ApiResponse<ResponseModel<PaymentModelData>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data?.data && data?.count) {
          return { paymentList: data?.data, count: data?.count };
        } else {
          return errorHelper(0, '', getModuleName(), (api.paymentSearch as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.paymentSearch as any).displayName
        );
      }
    });
}
