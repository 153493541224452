import React, { useState, useEffect } from 'react';
import styles from './choose-unit-type.module.scss';
import SearchIcon from '@material-ui/icons/Search';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ArrowForward from '@material-ui/icons/ArrowForward';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import BathtubIcon from '@material-ui/icons/Bathtub';
import { useTranslation } from 'react-i18next';
import Select, { ValueType } from 'react-select';
import _ from 'lodash';
import { UnitTypeUiModel } from '../../../ui-models/unit-type.ui-model';
import { UnitTypeGroupUiModel } from '../../../ui-models/unit-type-group.ui-model';
import { FormElementData } from '../../../interfaces/form-element-data.interface';
import { getModuleName } from '../../../../UnitTypeModule/shared/services/unit-type.service';
import { searchInUnitTypes } from '../../../services/data/unit-type.data';
import { ErrorInfo, isErrorInfo } from '../../../interfaces/error-info.interface';
import { InputText } from '../../core-ui/input-text/input-text.component';
import { Calendar } from '../calendar/calendar.component';
import Button, { ButtonSize, ButtonType } from '../../core-ui/button/button.component';
import { ReservationMode } from '../../../enums/reservation-mode.enum';

export interface ChooseUnitTypeProps {
  data: {
    unitTypes: UnitTypeUiModel[];
    unitTypeGroups: UnitTypeGroupUiModel[];
  };
  eventHandlers: {
    selectedUnitType: (unitType: UnitTypeUiModel) => void;
  };
}

/**
 * functional component ChooseUnitType
 * @param {ChooseUnitTypeProps}
 */
export const ChooseUnitType: React.FC<ChooseUnitTypeProps> = ({ data, eventHandlers }) => {
  const [unitTypeSearch, setUnitTypeSearch] = useState<FormElementData<string>>({
    value: '',
    isValid: true
  });
  const [unitTypes, setUnitTypes] = useState<UnitTypeUiModel[]>([]);
  const [initialUnitTypes, setInitialUnitTypes] = useState<
    {
      value: string;
      label: string;
      propertyId: string;
      userId: string;
      areaId: string;
      groupId: string;
    }[]
  >([]);
  const { t } = useTranslation();
  const [areaOptions, setAreaOptions] = useState<
    {
      value: string;
      label: string;
      propertyId: string;
      userId: string;
      roomId: [];
      groupId: string;
    }[]
  >([]);
  const [InitialAreaOptions, setInitialAreaOptions] = useState<
    {
      value: string;
      label: string;
      propertyId: string;
      userId: string;
      roomId: [];
      groupId: string;
    }[]
  >([]);
  const [usersOptions, setUsersOptions] = useState<
    {
      value: string;
      label: string;
      roomId: [];
      propertyId: string;
      areaId: string;
      groupId: string;
    }[]
  >([]);
  const [initialUsersOptions, setInitialUsersOptions] = useState<
    {
      value: string;
      label: string;
      roomId: [];
      propertyId: string;
      areaId: string;
      groupId: string;
    }[]
  >([]);
  const [groupOptions, setGroupOptions] = useState<
    {
      value: string;
      label: string;
      roomId: [];
      propertyId: string;
      areaId: string;
      userId: string;
    }[]
  >([]);
  const [initialGroupIdOptions, setInitialGroupIdOptions] = useState<
    {
      value: string;
      label: string;
      roomId: [];
      propertyId: string;
      areaId: string;
      userId: string;
    }[]
  >([]);
  const [unitTypeOptions, setUnitTypeOptions] = useState<
    {
      value: string;
      label: string;
      propertyId: string;
      userId: string;
      areaId: string;
      groupId: string;
    }[]
  >([]);
  const [unitTypeGroupsList, setUnitTypeGroupsList] = useState<{ value: string; label: string }[]>(
    []
  );
  const [projectsList, setProjectsList] = useState<
    {
      value: string;
      label: string;
      roomId: [];
      userId: string;
      areaId: string;
      groupId: string;
    }[]
  >([]);
  const [initialProjectsList, setInitialProjectsList] = useState<
    {
      value: string;
      label: string;
      roomId: [];
      userId: string;
      areaId: string;
      groupId: string;
    }[]
  >([]);

  const [area, setArea] = useState<{ value: string; label: string }>({ value: '', label: '' });
  const [project, setProject] = useState<{ value: string; label: string }>({
    value: '',
    label: ''
  });
  const [unitType, setUnitType] = useState<{ value: string; label: string }>({
    value: '',
    label: ''
  });
  const [unitTypeGroup, setUnitTypeGroup] = useState<{ value: string; label: string }>({
    value: '',
    label: ''
  });
  const [user, setUser] = useState<{ value: string; label: string }>({
    value: '',
    label: ''
  });
  const [date, setDate] = useState<Date[]>([]);

  useEffect(() => {
    if (data?.unitTypes?.length > 0) {
      setUnitTypes(data.unitTypes);
      filterDataWithoutDuplicate(data?.unitTypes, data?.unitTypeGroups);
    }
  }, [data.unitTypes]);

  // ***************************************************************//
  const filterDataWithoutDuplicate = (
    data: UnitTypeUiModel[],
    unitGroups: UnitTypeGroupUiModel[]
  ) => {
    const groupId: {
      value: string;
      label: string;
      areaId: string;
      propertyId: string;
      userId: string;
      roomId: [];
    }[] = [];
    const area: {
      value: string;
      label: string;
      propertyId: string;
      userId: string;
      roomId: [];
      groupId: string;
    }[] = [];
    const rooms: {
      value: string;
      label: string;
      propertyId: string;
      userId: string;
      areaId: string;
      groupId: string;
    }[] = [];
    const projects: {
      value: string;
      label: string;
      roomId: [];
      userId: string;
      areaId: string;
      groupId: string;
    }[] = [];
    const users: {
      value: string;
      label: string;
      roomId: [];
      propertyId: string;
      areaId: string;
      groupId: string;
    }[] = [];
    data?.map((unit) => {
      if (unit?.areaId !== undefined && unit?.area !== undefined) {
        area.push({
          value: unit?.areaId,
          label: unit?.area,
          propertyId: unit?.propertyId,
          userId: unit?.landlordId,
          roomId: unit?.units,
          groupId: unit?.unitTypeGroupId
        });
      }
      if (unit?.units?.length > 0) {
        unit?.units?.map((room) =>
          rooms.push({
            value: room.unitId,
            label: room.shortName,
            propertyId: unit?.propertyId,
            userId: unit?.landlordId,
            areaId: unit.areaId,
            groupId: unit?.unitTypeGroupId
          })
        );
      }
      if (unit?.propertyId !== undefined && unit?.propertyName !== undefined) {
        projects.push({
          value: unit?.propertyId,
          label: unit?.propertyName,
          userId: unit?.landlordId,
          roomId: unit.units,
          areaId: unit.areaId,
          groupId: unit?.unitTypeGroupId
        });
      }
      if (unit?.landlordId !== undefined && unit?.landlordUsername !== undefined) {
        users.push({
          value: unit?.landlordId,
          label: unit?.landlordUsername,
          roomId: unit?.units,
          areaId: unit?.areaId,
          propertyId: unit?.propertyId,
          groupId: unit?.unitTypeGroupId
        });
      }
      if (unit?.unitTypeGroupId !== undefined) {
        groupId.push({
          value: unit?.unitTypeGroupId,
          label: unitGroups?.find((unitGroup) => unitGroup?.id === unit?.unitTypeGroupId)?.name,
          roomId: unit?.units,
          areaId: unit?.areaId,
          propertyId: unit?.propertyId,
          userId: unit?.landlordId
        });
      }
    });

    if (area.length > 0) {
      setAreaOptions(_.uniqBy(area, 'value'));
      setInitialAreaOptions(_.uniqBy(area, 'value'));
    }
    if (rooms.length > 0) {
      setUnitTypeOptions(_.uniqBy(rooms, 'value'));
      setInitialUnitTypes(_.uniqBy(rooms, 'value'));
    }
    if (projects.length > 0) {
      setProjectsList(_.uniqBy(projects, 'value'));
      setInitialProjectsList(_.uniqBy(projects, 'value'));
    }
    if (users.length > 0) {
      setUsersOptions(_.uniqBy(users, 'value'));
      setInitialUsersOptions(_.uniqBy(users, 'value'));
    }
    if (groupId.length > 0) {
      setGroupOptions(_.uniqBy(groupId, 'value'));
      setInitialGroupIdOptions(_.uniqBy(groupId, 'value'));
    }
  };
  const searchUnitTypeByDate = (date: Date[]) => {
    searchInUnitTypes(getModuleName(), {
      checkInDate: date[0].getTime(),
      checkOutDate: date[1].getTime()
    }).then((response: UnitTypeUiModel[] | ErrorInfo) => {
      if (!isErrorInfo(response)) {
        console.log('data', response);

        setUnitTypes(response);
        filterDataWithoutDuplicate(unitTypes, data.unitTypeGroups);
        setUnitTypes(
          response?.filter(
            (unit) =>
              unit.areaId?.includes(area.value !== '' ? area.value : unit.areaId) &&
              unit.propertyId?.includes(project.value !== '' ? project.value : unit.propertyId) &&
              (unitType.value === '' ||
                unit?.units?.reduce((acc, room) => {
                  if (room?.unitId?.includes(unitType.value !== '' ? unitType.value : unit?.id)) {
                    return true;
                  } else {
                    return acc || false;
                  }
                }, false)) &&
              unit.landlordId?.includes(user.value !== '' ? user.value : unit.landlordId) &&
              (unit.name?.toLocaleLowerCase().includes(unitTypeSearch.value.toLocaleLowerCase()) ||
                unit.area?.toLocaleLowerCase().includes(unitTypeSearch.value.toLocaleLowerCase()) ||
                unit.landlordUsername
                  ?.toLocaleLowerCase()
                  .includes(unitTypeSearch.value.toLocaleLowerCase()) ||
                unit.propertyName
                  ?.toLocaleLowerCase()
                  .includes(unitTypeSearch.value.toLocaleLowerCase()))
          )
        );
      }
    });
  };

  return (
    <div className={styles['choose-unit-type']}>
      <div className={styles['choose-unit-type__title']}>Choose Unit Type</div>
      <div className={styles['choose-unit-type__search-container']}>
        <div className={styles['choose-unit-type__date--container']}>
          <div className={styles['choose-unit-type__date--container-form-field']}>
            <div className={styles['choose-unit-type__form-field__label']}>Search By</div>
            <div className={styles['choose-unit-type__search-input']}>
              <InputText
                data={{
                  value: unitTypeSearch.value,
                  placeholder: 'search by unit or project or unit type groups or area or user'
                }}
                config={{
                  maxRows: 1,
                  autoFocus: true
                }}
                eventHandlers={{
                  onChangeHandler: (value: string, isValid: boolean) => {
                    setUnitTypeSearch({ value, isValid });
                  }
                }}
              />
            </div>
          </div>
          <div className={styles['choose-unit-type__date--container-form-field']}>
            <div className={styles['choose-unit-type__form-field__label']}>Choose Date</div>

            <Calendar
              data={{
                showDateInput: true,
                selectedDate: date
              }}
              eventHandlers={{
                onChange: (date) => {
                  setDate(date as Date[]);
                }
              }}
            />
          </div>
        </div>
        <div className={styles['choose-unit-type__form-field--dropdown--container']}>
          <div className={styles['choose-unit-type__form-field']}>
            <div className={styles['choose-unit-type__form-field__label']}>Area</div>
            <Select
              value={{ value: area.value, label: area.label }}
              options={areaOptions?.map((user) => ({
                value: user?.value,
                label: user?.label
              }))}
              className={styles['choose-unit-type__form-field__select']}
              onChange={(value: ValueType<{ value: string; label: string }, false>) => {
                console.log(value);

                setArea(value ? value : { value: '', label: '' });
                setProjectsList(
                  initialProjectsList?.filter((project) => project?.areaId?.includes(value?.value))
                );
                setUsersOptions(
                  initialUsersOptions?.filter((unit) => unit?.areaId?.includes(value?.value))
                );
                setUnitTypeOptions(
                  initialUnitTypes?.filter((unit) => unit?.areaId?.includes(value?.value))
                );
                setProject({ value: '', label: '' });
                setUnitType({ value: '', label: '' });
                setUser({ value: '', label: '' });
                setUnitTypeGroup({ value: '', label: '' });
              }}
            />
          </div>
          <div className={styles['choose-unit-type__form-field']}>
            <div className={styles['choose-unit-type__form-field__label']}>Project</div>
            <Select
              value={{ value: project.value, label: project.label }}
              className={styles['choose-unit-type__form-field__select']}
              options={projectsList.map((unitType) => ({
                value: unitType.value,
                label: unitType.label
              }))}
              onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
                setProject(
                  value ? { value: value.value, label: value.label } : { value: '', label: '' }
                );
                setUnitType({ value: '', label: '' });
                setUsersOptions(
                  initialUsersOptions?.filter((unit) => unit?.propertyId?.includes(value?.value))
                );
                setUnitTypeOptions(
                  initialUnitTypes?.filter((unit) => unit?.propertyId?.includes(value?.value))
                );
                setUser({ value: '', label: '' });
                setUnitTypeGroup({ value: '', label: '' });
              }}
            />
          </div>

          <div className={styles['choose-unit-type__form-field']}>
            <div className={styles['choose-unit-type__form-field__label']}>landlord</div>
            <Select
              value={{ value: user.value, label: user.label }}
              className={styles['choose-unit-type__form-field__select']}
              options={usersOptions.map((user) => ({
                value: user.value,
                label: user.label
              }))}
              onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
                setUser(
                  value ? { value: value.value, label: value.label } : { value: '', label: '' }
                );
                setUnitTypeOptions(
                  initialUnitTypes?.filter((unit) => unit?.userId?.includes(value?.value))
                );
                setUnitTypeGroup({ value: '', label: '' });
                setUnitType({ value: '', label: '' });
              }}
            />
          </div>
          <div className={styles['choose-unit-type__form-field']}>
            <div className={styles['choose-unit-type__form-field__label']}>Rooms</div>
            <Select
              value={{ value: unitType.value, label: unitType.label }}
              className={styles['choose-unit-type__form-field__select']}
              options={unitTypeOptions.map((room) => ({
                value: room.value,
                label: room.label
              }))}
              onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
                console.log(value);

                setUnitType(
                  value
                    ? { value: value.value || '', label: value.label }
                    : { value: '', label: '' }
                );

                setGroupOptions(
                  initialGroupIdOptions?.filter((unit) =>
                    unit?.roomId?.reduce((acc, room) => {
                      if (room?.unitId?.includes(value?.value)) {
                        return true;
                      } else {
                        return acc || false;
                      }
                    }, false)
                  )
                );

                setUnitTypeGroup({ value: '', label: '' });
              }}
            />
          </div>
          <div className={styles['choose-unit-type__form-field']}>
            <div className={styles['choose-unit-type__form-field__label']}>Unit Type</div>
            <Select
              value={{ value: unitTypeGroup.value, label: unitTypeGroup.label }}
              className={styles['choose-unit-type__form-field__select']}
              options={groupOptions.map((group) => ({
                value: group.value,
                label: group.label
              }))}
              onChange={(value: ValueType<{ value: string | undefined; label: string }, false>) => {
                setUnitTypeGroup(
                  value
                    ? { value: value.value || '', label: value.label }
                    : { value: '', label: '' }
                );
              }}
            />
          </div>
        </div>
        <div className={styles['choose-unit-type__actions']}>
          <Button
            className={styles['choose-unit-type__search-input__btn']}
            onClick={() => {
              if (date.length > 0) {
                searchUnitTypeByDate(date);
              } else {
                setUnitTypes(
                  data?.unitTypes?.filter(
                    (unit) =>
                      unit.areaId?.includes(area.value !== '' ? area.value : unit.areaId) &&
                      unit.propertyId?.includes(
                        project.value !== '' ? project.value : unit.propertyId
                      ) &&
                      (unitType.value === '' ||
                        unit?.units?.reduce((acc, room) => {
                          if (
                            room?.unitId?.includes(
                              unitType.value !== '' ? unitType.value : unit?.id
                            )
                          ) {
                            return true;
                          } else {
                            return acc || false;
                          }
                        }, false)) &&
                      unit.landlordId?.includes(user.value !== '' ? user.value : unit.landlordId) &&
                      unit.unitTypeGroupId?.includes(
                        unitTypeGroup.value !== '' ? unitTypeGroup.value : unit.unitTypeGroupId
                      ) &&
                      unit.landlordId?.includes(user.value !== '' ? user.value : unit.landlordId) &&
                      (unitTypeSearch.value === '' ||
                        unit.name?.toLowerCase().includes(unitTypeSearch.value.toLowerCase()) ||
                        unit.area?.toLowerCase().includes(unitTypeSearch.value.toLowerCase()) ||
                        unit.landlordUsername
                          ?.toLowerCase()
                          .includes(unitTypeSearch.value.toLowerCase()) ||
                        unit.propertyName
                          ?.toLowerCase()
                          .includes(unitTypeSearch.value.toLowerCase()) ||
                        unit?.units.reduce((acc, room) => {
                          if (
                            (room.shortName &&
                              room.shortName.toLowerCase().includes(unitTypeSearch.value)) ||
                            (room.roomNumber &&
                              room.roomNumber.toLowerCase().includes(unitTypeSearch.value)) ||
                            (room.unitId &&
                              room.unitId.toLowerCase().includes(unitTypeSearch.value))
                          ) {
                            return true;
                          } else {
                            return acc;
                          }
                        }, false))
                  )
                );
              }
            }}
            tracking={{
              action: 'search',
              category: 'unittypes',
              label: 'search-unitypes-create-reservation'
            }}
            size={ButtonSize.medium}
          >
            <SearchIcon></SearchIcon>
          </Button>
          <Button
            className={styles['choose-unit-type__search-input__btn']}
            onClick={() => {
              filterDataWithoutDuplicate(data.unitTypes, data.unitTypeGroups);
              setProject({ value: '', label: '' });
              setUnitType({ value: '', label: '' });
              setUser({ value: '', label: '' });
              setArea({ value: '', label: '' });
              setUnitTypeSearch({ value: '', isValid: true });
              setUnitTypes(data.unitTypes);
              setDate([]);
              setUnitTypeGroup({ value: '', label: '' });
            }}
            tracking={{
              action: 'clearSearch',
              category: 'unittypes',
              label: 'search-unitypes-create-reservation'
            }}
            size={ButtonSize.medium}
            type={ButtonType.gray}
          >
            clear
          </Button>
        </div>
      </div>
      <div className={styles['choose-unit-type__unit-card-container']}>
        {unitTypes?.map((unit) => (
          <div
            className={styles['choose-unit-type__unit-card-container__unit-card']}
            onClick={() => eventHandlers.selectedUnitType(unit)}
          >
            <div
              className={styles['choose-unit-type__unit-card-container__unit-card__image-wrapper']}
            >
              <img
                className={
                  styles['choose-unit-type__unit-card-container__unit-card__image-wrapper__image']
                }
                alt="unit"
                src={unit.coverImage}
              />
            </div>
            <div className={styles['choose-unit-type__unit-card-container__unit-card__header']}>
              <div
                className={`${
                  styles['choose-unit-type__unit-card-container__unit-card__header__title']
                } ${
                  !unit.name
                    ? styles[
                        'choose-unit-type__unit-card-container__unit-card__header__title--untitled'
                      ]
                    : ''
                } `}
              >
                {unit.name}
              </div>
              <div
                className={styles['choose-unit-type__unit-card-container__unit-card__description']}
              >
                <div
                  className={
                    styles[
                      'choose-unit-type__unit-card-container__unit-card__header__title__description'
                    ]
                  }
                >
                  <div
                    className={styles['choose-unit-type__unit-card-container__unit-card__details']}
                  >
                    <span>
                      <label>propertyName:</label>
                      <a
                        href={`${process.env.PUBLIC_URL}/properties/edit/${unit.propertyId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {unit.propertyName}
                        <ArrowForward color="inherit" fontSize="inherit" />
                      </a>
                    </span>
                    <span>
                      <label>Homeowner:</label>
                      {unit?.landlordUsername}
                    </span>
                    <span>
                      <label>area:</label>
                      <a
                        href={`${process.env.PUBLIC_URL}/properties/edit/${unit.areaId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {unit.area} <ArrowForward color="inherit" fontSize="inherit" />
                      </a>
                    </span>
                    <span>
                      <label>Rooms: </label>
                      {unit.units && unit.units.length > 0
                        ? unit.units?.map((room) => (
                            <div
                              className={
                                styles[
                                  'choose-unit-type__unit-card-container__unit-card__details__room-name'
                                ]
                              }
                            >
                              {room.shortName}
                            </div>
                          ))
                        : ' No rooms'}
                    </span>
                  </div>
                  <span
                    className={
                      styles[
                        'choose-unit-type__unit-card-container__unit-card__details-availability'
                      ]
                    }
                  >
                    Available from:
                    {unit.availableFrom && new Date(unit.availableFrom).toDateString()}
                  </span>
                </div>
                <div
                  className={
                    styles['choose-unit-type__unit-card-container__unit-card__room-details']
                  }
                >
                  <ul>
                    <li>
                      <AttachMoneyIcon
                        className={
                          styles[
                            'choose-unit-type__unit-card-container__unit-card__room-details-responses__icon'
                          ]
                        }
                      />
                      <span
                        className={
                          styles[
                            'choose-unit-type__unit-card-container__unit-card__room-details-responses__number'
                          ]
                        }
                      >
                        {unit?.unitPrice || 0} &nbsp;
                      </span>
                      <span
                        className={
                          styles[
                            'choose-unit-type__unit-card-container__unit-card__room-details-response-text'
                          ]
                        }
                      >
                        {unit.reservationMode === ReservationMode.MONTHLY
                          ? t('UNIT_TYPE_MODULE.LIST_CONTAINER.MONTHLY_PRICE')
                          : t('UNIT_TYPE_MODULE.LIST_CONTAINER.NIGHTLY_PRICE')}
                      </span>
                    </li>
                    <li>
                      <MeetingRoomIcon
                        className={
                          styles[
                            'choose-unit-type__unit-card-container__unit-card__room-details-responses__icon'
                          ]
                        }
                      />
                      <span
                        className={
                          styles[
                            'choose-unit-type__unit-card-container__unit-card__room-details-responses__number'
                          ]
                        }
                      >
                        {unit?.units.length || 0} Rooms
                      </span>
                    </li>
                    <li>
                      <BathtubIcon
                        className={
                          styles[
                            'choose-unit-type__unit-card-container__unit-card__room-details-responses__icon'
                          ]
                        }
                      />
                      <span
                        className={
                          styles[
                            'choose-unit-type__unit-card-container__unit-card__room-details-responses__number'
                          ]
                        }
                      >
                        {unit?.amenities?.length || 0} &nbsp;
                      </span>
                      <span
                        className={
                          styles[
                            'choose-unit-type__unit-card-container__unit-card__room-details-responses__response-text'
                          ]
                        }
                      >
                        {t('UNIT_TYPE_MODULE.LIST_CONTAINER.AMENITIES_COUNT')}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
