import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './add-amenity-modal.module.scss';
import { VALIDATION_REGEX } from '../../../../../shared/components/validation.config';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import { trackEvent } from '../../../../../shared/services/utils/tracking/tracking.service';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize,
  ButtonType
} from '../../../../../shared/components/core-ui/button/button.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import {
  InputText,
  InputTextErrorMode
} from '../../../../../shared/components/core-ui/input-text/input-text.component';

export interface AddAmenityModalProps {
  config: {
    showModal: boolean;
  };
  eventHandlers: {
    cancelHandler: () => void;
    addAmenityHandler: (amenityName: string) => void;
  };
}

/**
 * functional component AddAmenityModal
 * @param {AddAmenityModalProps}
 */
export const AddAmenityModal: React.FC<AddAmenityModalProps> = ({ config, eventHandlers }) => {
  const [newAmenityName, setNewAmenityName] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const { t } = useTranslation();
  const isMobileView = useWindowResizer();

  const handleNewAmenitySubmit = () => {
    if (newAmenityName.value !== '' && newAmenityName.isValid) {
      eventHandlers.addAmenityHandler(newAmenityName.value);
    }
  };

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.cancelHandler();
        }
      }}
    >
      <div className={styles['add-amenity-modal']}>
        <div className={styles['add-amenity-modal__title']}>
          {t('AMENITY_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.TITLE')}
        </div>
        <div className={styles['add-amenity-modal__description']}>
          {t('AMENITY_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.DESCRIPTION')}
        </div>
        <InputText
          data={{
            defaultValue: '',
            errorMessage: t('AMENITY_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.INVALID_AMENITY_NAME'),
            placeholder: t('AMENITY_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.NAME_PLACEHOLDER')
          }}
          config={{
            maxRows: 1,
            autoFocus: true,
            validationRegex: VALIDATION_REGEX.AMENITY_NAME,
            showErrorMode: InputTextErrorMode.instant
          }}
          eventHandlers={{
            onChangeHandler: (value: string, isValid: boolean) => {
              setNewAmenityName({ value, isValid });
            },
            onEnterPressedHandler: handleNewAmenitySubmit
          }}
        />
      </div>
      <div className={styles['add-amenity-modal--actions']}>
        <Button
          onClick={() => {
            eventHandlers.cancelHandler();
            trackEvent('cancel', {
              category: 'amenity',
              label: 'add-amenity-modal'
            });
          }}
          tracking={{
            action: 'cancel',
            category: 'builder',
            label: 'builder-intro'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
          type={ButtonType.primaryInverted}
        >
          {t('AMENITY_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.CANCEL_BUTTON')}
        </Button>
        <Button
          disabled={!newAmenityName.isValid || newAmenityName.value === ''}
          onClick={handleNewAmenitySubmit}
          tracking={{
            action: 'submit',
            category: 'amenities',
            label: 'add-amenity-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          {t('AMENITY_MODULE.LIST_CONTAINER.ADD_NEW_MODAL.SUBMIT_BUTTON')}
        </Button>
      </div>
    </Modal>
  );
};
