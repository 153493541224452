import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { APP_ROUTES } from '../shared/config/routes-config';
import { RouteConfig } from '../shared/interfaces/routes-config.interface';
import EditUnitTypeContainer from './containers/edit-unit-type/edit-unit-type.container';
import UnitTypesListContainer from './containers/unit-types-list/unit-types-list.container';
import { UnitTypesBulkUpdateContainer } from './containers/unit-types-bulk-update/unit-types-bulk-update.container';
import { UnitTypesBulkUpdatesListContainer } from './containers/unit-types-bulk-update/components/unit-type-bulk-update-list/unit-type-bulk-update-list.container';
/**
 * function UnitTypeModule components
 */
export const UnitTypeModule: React.FC = () => {
  return (
    <>
      <Route exact path={APP_ROUTES.UNIT_TYPE_MODULE.FULL_PATH}>
        <Redirect to={(APP_ROUTES.UNIT_TYPE_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH} />
      </Route>
      <Route
        path={(APP_ROUTES.UNIT_TYPE_MODULE.CHILDREN as RouteConfig).LIST.FULL_PATH}
        component={UnitTypesListContainer}
      />
      <Route
        path={(APP_ROUTES.UNIT_TYPE_MODULE.CHILDREN as RouteConfig).EDIT.FULL_PATH}
        component={EditUnitTypeContainer}
      />
      <Route
        path={(APP_ROUTES.UNIT_TYPE_MODULE.CHILDREN as RouteConfig).BULK_EDIT.FULL_PATH}
        component={UnitTypesBulkUpdateContainer}
      />
      <Route
        path={(APP_ROUTES.UNIT_TYPE_MODULE.CHILDREN as RouteConfig).BULK_EDIT_LIST.FULL_PATH}
        component={UnitTypesBulkUpdatesListContainer}
      />
    </>
  );
};
