import { ApiResponse } from 'apisauce';
import { ErrorInfo } from '../../../shared/interfaces/error-info.interface';
import { ResponseModel } from '../../../shared/interfaces/response-model.interface';
import {
  CreatePayOutDto,
  Payout,
  SearchPayOutDto,
  UploadPayout
} from '../../../shared/models/payout.model';
import { Expenses } from '../../../shared/models/unit-types.model';
import UserMapper from '../../../shared/mappers/user.mapper';
import { UserModel } from '../../../shared/models/user.model';
import { UserUIModel } from '../../../shared/ui-models/user.ui-model';
import api from '../../../shared/services/utils/api/api.service';
import errorHelper from '../../../shared/services/utils/api/error-helper.service';
import { getModuleName } from './expenses.service';
import { CreateExpenses } from '../../../ExpensesModule/containers/expenses-list/components/create-reservation/create-expense-modal.component';

export function createExpenses(expense: CreateExpenses): Promise<Expenses | ErrorInfo> {
  return api
    .createExpenses(expense)
    .then((response: ApiResponse<ResponseModel<Expenses>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return data.data;
        } else {
          return errorHelper(0, '', getModuleName(), (api.createExpenses as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.createExpenses as any).displayName
        );
      }
    });
}
export function deleteExpense(expenseId: string): Promise<Expenses | ErrorInfo> {
  return api
    .deleteExpenses(expenseId)
    .then((response: ApiResponse<ResponseModel<Expenses>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return data.data;
        } else {
          return errorHelper(0, '', getModuleName(), (api.deleteExpenses as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.deleteExpenses as any).displayName
        );
      }
    });
}
export function createPayouts(payout: CreatePayOutDto): Promise<Payout | ErrorInfo> {
  // const data = new FormData();

  // data.append('asset', file);
  // data.append('unitTypeId', payout.unitTypeId ? String(payout.unitTypeId) : '');
  // data.append('HomeownerId', payout.landlordId ? String(payout.landlordId) : '');
  // data.append('roomId', payout.roomId);
  // data.append('amount', payout.amount ? String(payout.amount) : '');
  // data.append('paymentDate', payout.paymentDate ? String(payout.paymentDate) : '');
  // data.append('description', payout.description ? String(payout.description) : '');

  return api
    .createPayout(payout)
    .then((response: ApiResponse<ResponseModel<Payout>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return data.data;
        } else {
          return errorHelper(0, '', getModuleName(), (api.createPayout as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.createPayout as any).displayName
        );
      }
    });
}
export function uploadPayouts(payoutId: string, file: File): Promise<Payout | ErrorInfo> {
  const data = new FormData();

  data.append('asset', file);
  data.append('payoutId', payoutId ? String(payoutId) : '');

  return api.uploadPayout(data).then((response: ApiResponse<ResponseModel<Payout>, ErrorInfo>) => {
    if (response.ok) {
      const { data } = response;
      if (data && data.data) {
        return data.data;
      } else {
        return errorHelper(0, '', getModuleName(), (api.createPayout as any).displayName);
      }
    } else {
      return errorHelper(
        Number(response.status),
        response.data && response.data.errorCode
          ? String(response.data.errorCode || '')
          : response.problem,
        getModuleName(),
        (api.createPayout as any).displayName
      );
    }
  });
}

export function deletePayout(payoutId: string): Promise<Payout | ErrorInfo> {
  return api
    .deletePayout(payoutId)
    .then((response: ApiResponse<ResponseModel<Payout>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return data.data;
        } else {
          return errorHelper(0, '', getModuleName(), (api.deletePayout as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.deletePayout as any).displayName
        );
      }
    });
}

export function addUser(userUIModel: UserUIModel): Promise<UserUIModel | ErrorInfo> {
  const mappedUser = UserMapper.mapToModel(userUIModel);
  return api
    .addUser(mappedUser)
    .then((response: ApiResponse<ResponseModel<UserModel>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return UserMapper.mapToUI(data.data);
        } else {
          return errorHelper(0, '', getModuleName(), (api.addUser as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.addUser as any).displayName
        );
      }
    });
}
export function getExpenses(query: {}): Promise<Payout[] | ErrorInfo> {
  return api
    .getExpenses(query)
    .then((response: ApiResponse<ResponseModel<Payout[]>, ErrorInfo>) => {
      if (response.ok) {
        const { data } = response;
        if (data && data.data) {
          return data.data;
        } else {
          return errorHelper(0, '', getModuleName(), (api.getPayouts as any).displayName);
        }
      } else {
        return errorHelper(
          Number(response.status),
          response.data && response.data.errorCode
            ? String(response.data.errorCode || '')
            : response.problem,
          getModuleName(),
          (api.getPayouts as any).displayName
        );
      }
    });
}
