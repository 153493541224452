import React, { useState } from 'react';
import styles from './add-guest-modal.module.scss';
import Modal from '../../../../../shared/components/core-ui/modal/modal.component';
import Button, {
  ButtonSize
} from '../../../../../shared/components/core-ui/button/button.component';
import {
  InputText,
  InputTextErrorMode,
  InputTextType
} from '../../../../../shared/components/core-ui/input-text/input-text.component';
import useWindowResizer from '../../../../../shared/hooks/window-resizer/window-resizer.hook';
import { GuestUiModel } from '../../../../../shared/ui-models/guest.ui-model';
import { FormElementData } from '../../../../../shared/interfaces/form-element-data.interface';
import DatePicker from 'react-datepicker';
import Select, { ValueType } from 'react-select';

const genderOption = [
  { label: 'Male', value: 'male' },
  { label: 'FeMale', value: 'female' }
];

export interface AddGuestModalProps {
  config: {
    showModal: boolean;
  };
  eventHandlers: {
    closeHandler: () => void;
    addGuestHandler: (guestData: GuestUiModel) => void;
  };
}

/**
 * functional component AddGuestModal
 * @param {AddGuestModalProps}
 */
export const AddGuestModal: React.FC<AddGuestModalProps> = ({ config, eventHandlers }) => {
  const [newGuestFirstName, setNewGuestFirstName] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [newGuestLastName, setNewGuestLastName] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [newGuestEmail, setNewGuestEmail] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [newGuestPhone, setNewGuestPhone] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [newGuestAddress, setNewGuestAddress] = useState<FormElementData<string>>({
    value: '',
    isValid: false
  });
  const [newGuestGender, setNewGuestGender] = useState<FormElementData<string>>({
    value: 'male',
    isValid: true
  });
  const [newGuestDob, setNewGuestDob] = useState<FormElementData<Date | null>>({
    value: new Date(),
    isValid: true
  });
  const isMobileView = useWindowResizer();

  const handleNewGuestSubmit = () => {
    const newGuest: GuestUiModel = {
      firstName: newGuestFirstName.value,
      lastName: newGuestLastName.value,
      email: newGuestEmail.value,
      phoneNumber: newGuestPhone.value,
      address: newGuestAddress.value,
      dob: newGuestDob.value?.toString(),
      gender: newGuestGender.value as any
    };
    eventHandlers.addGuestHandler(newGuest);
  };

  return (
    <Modal
      config={{ showModal: config.showModal, fullScreen: false, showClose: true }}
      eventHandlers={{
        closeModalHandler: () => {
          eventHandlers.closeHandler();
        }
      }}
    >
      <div className={styles['add-guest-modal']}>
        <div className={styles['add-guest-modal__title']}>Add guest</div>
        <div className={styles['add-guest-modal__description']}>
          First Name
          <InputText
            data={{
              defaultValue: '',
              errorMessage: 'First name is required',
              placeholder: 'First Name'
            }}
            config={{
              maxRows: 1,
              autoFocus: true,
              required: true,
              showErrorMode: InputTextErrorMode.instant
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setNewGuestFirstName({ value, isValid });
              }
            }}
          />
        </div>
        <div className={styles['add-guest-modal__description']}>
          Last Name
          <InputText
            data={{
              defaultValue: '',
              errorMessage: 'Last name is required',
              placeholder: 'Last Name'
            }}
            config={{
              maxRows: 1,
              required: true,
              showErrorMode: InputTextErrorMode.instant
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setNewGuestLastName({ value, isValid });
              }
            }}
          />
        </div>
        <div className={styles['add-guest-modal__description']}>
          Email
          <InputText
            data={{
              defaultValue: '',
              errorMessage: 'Email is required',
              placeholder: 'Email'
            }}
            config={{
              maxRows: 1,
              required: true,
              showErrorMode: InputTextErrorMode.instant
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setNewGuestEmail({ value, isValid });
              }
            }}
          />
        </div>
        <div className={styles['add-guest-modal__description']}>
          Phone Number
          <InputText
            data={{
              defaultValue: '',
              errorMessage: 'Phone number is required',
              placeholder: 'Phone Number'
            }}
            config={{
              maxRows: 1,
              required: true,
              type: InputTextType.text,
              showErrorMode: InputTextErrorMode.instant
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setNewGuestPhone({ value, isValid });
              }
            }}
          />
        </div>
        <div className={styles['add-guest-modal__description']}>
          Address
          <InputText
            data={{
              defaultValue: '',
              errorMessage: 'Address is required',
              placeholder: 'Address'
            }}
            config={{
              maxRows: 1,
              required: true,
              showErrorMode: InputTextErrorMode.instant
            }}
            eventHandlers={{
              onChangeHandler: (value: string, isValid: boolean) => {
                setNewGuestAddress({ value, isValid });
              }
            }}
          />
        </div>
        <div className={styles['add-guest-modal__description']}>
          Date of Birth
          <DatePicker
            selected={newGuestDob.value}
            onChange={(date) => setNewGuestDob({ value: date as Date, isValid: true })}
          />
        </div>
        <div className={styles['add-guest-modal__description']}>
          gender
          <Select
            options={genderOption}
            defaultValue={genderOption.find((val) => val.value === newGuestGender.value)}
            onChange={(value: ValueType<{ value: string; label: string }, false>) => {
              setNewGuestGender({ value: value?.value || 'male', isValid: true });
            }}
          />
        </div>
      </div>
      <div className={styles['add-guest-modal--actions']}>
        <Button
          onClick={() => {
            eventHandlers.closeHandler();
          }}
          tracking={{
            action: 'close',
            category: 'guests',
            label: 'add-guest-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          Cancel
        </Button>
        <Button
          disabled={
            !newGuestFirstName.isValid ||
            !newGuestLastName.isValid ||
            !newGuestEmail.isValid ||
            !newGuestPhone.isValid ||
            !newGuestAddress.isValid ||
            !newGuestGender.isValid ||
            !newGuestDob.isValid
          }
          onClick={handleNewGuestSubmit}
          tracking={{
            action: 'submit',
            category: 'guest',
            label: 'add-guest-modal'
          }}
          size={isMobileView ? ButtonSize.full : ButtonSize.medium}
        >
          Add guest
        </Button>
      </div>
    </Modal>
  );
};
